import { ENetwork } from 'types';
import { isTestnet } from 'types/web3';

export const calculateKattanaTradePairLink = ({
  network,
  pairAddress,
}: {
  network: ENetwork;
  pairAddress: string;
}) => {
  const baseUrl = process.env.REACT_APP_KATTANA_URL;

  const BSC_SLUG = isTestnet ? '/BSC_TESTNET/' : '/BSC_MAINNET/';
  const ETH_SLUG = isTestnet ? '/ETH_TESTNET/' : '/ETH_MAINNET/';
  const POLYGON_SLUG = isTestnet ? '/POLYGON_TESTNET/' : '/POLYGON_MAINNET/';
  const ARBITRUM_SLUG = isTestnet ? '/ARBITRUM_SEPOLIA_TESTNET/' : '/ARBITRUM/';

  let slug = '';
  if (network === ENetwork.bsc) {
    slug = BSC_SLUG;
  }
  if (network === ENetwork.eth) {
    slug = ETH_SLUG;
  }
  if (network === ENetwork.polygon) {
    slug = POLYGON_SLUG;
  }
  if (network === ENetwork.arbitrum) {
    slug = ARBITRUM_SLUG;
  }

  return baseUrl + slug + pairAddress;
};
