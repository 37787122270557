import React, { useContext, useCallback, useState } from 'react';
import { useTypedSelector } from 'store';

import { ChangePairNetwork } from 'ui';
import {
  ConnectNewWalletsToPairModal,
  DisconnectWalletsFromPairModal,
  DepositWithdrawModal,
  WithdrawWhitelistModal,
  ManualTxsModal,
} from 'modals';
import { PairWalletsContext } from 'context/PairWalletsContext/PairWalletsContext';
import { WithdrawalStatusModal } from 'modals/WithdrawalStatusModal';
import { ITransfer } from 'types/transfers';

const Modals: React.FC = () => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const {
    handleRemount,
    rowSelection,
    selectedRecords,
    modals: {
      connectWalletsOpened,
      disconnectWalletsOpened,
      depositOpened,
      withdrawOpened,
      whitelistWithdrawOpened,
      manualTxsOpened,
      changeNetworkOpened,
      withdrawStatusModalOpened,
    },
  } = useContext(PairWalletsContext);

  const [withdrawTransfers, setWithdrawTransfers] = useState<ITransfer[]>([]);

  const onSuccessDeactivateWallets = useCallback(() => {
    handleRemount();
    rowSelection.set({});

    //eslint-disable-next-line
  }, [handleRemount]);

  const onWithdrawResult = useCallback((transfers: ITransfer[]) => {
    setWithdrawTransfers(transfers);
    withdrawStatusModalOpened.set(true);

    //eslint-disable-next-line
  }, []);

  return (
    <>
      {connectWalletsOpened.get && (
        <ConnectNewWalletsToPairModal
          onClose={() => connectWalletsOpened.set(false)}
          onOpen={() => connectWalletsOpened.set(true)}
          onSuccess={handleRemount}
          pairId={pair.id}
        />
      )}
      {disconnectWalletsOpened.get && (
        <DisconnectWalletsFromPairModal
          onClose={() => disconnectWalletsOpened.set(false)}
          onOpen={() => disconnectWalletsOpened.set(true)}
          onSuccess={onSuccessDeactivateWallets}
          wallets={selectedRecords}
        />
      )}
      {depositOpened.get && (
        <DepositWithdrawModal
          onClose={() => depositOpened.set(false)}
          onRemountTable={handleRemount}
          wallets={selectedRecords}
          pair={pair}
          type={'deposit'}
          setManualTxsModalOpened={manualTxsOpened.set}
        />
      )}
      {withdrawOpened.get && (
        <DepositWithdrawModal
          onClose={() => withdrawOpened.set(false)}
          onRemountTable={handleRemount}
          wallets={selectedRecords}
          pair={pair}
          type={'withdraw'}
          setManualTxsModalOpened={manualTxsOpened.set}
          onWithdrawResult={onWithdrawResult}
        />
      )}
      {whitelistWithdrawOpened.get && (
        <WithdrawWhitelistModal onClose={() => whitelistWithdrawOpened.set(false)} />
      )}
      {manualTxsOpened.get && <ManualTxsModal onClose={() => manualTxsOpened.set(false)} />}
      {changeNetworkOpened.get && (
        <ChangePairNetwork onClose={() => changeNetworkOpened.set(false)} />
      )}
      {withdrawStatusModalOpened.get && (
        <WithdrawalStatusModal
          transfers={withdrawTransfers}
          onClose={() => withdrawStatusModalOpened.set(false)}
        />
      )}
    </>
  );
};

export default Modals;
