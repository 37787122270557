import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { AnimatePresence, motion } from 'framer-motion';

import { Button } from 'ui';
import { shortenAddress } from 'utils';
import { useMetamaskAuth } from 'hooks';

import { Metamask } from 'assets/images/wallets/metamask';
import { ArrowDropDown, ExitIcon } from 'assets/icons';
import './walletButton.scss';

const WalletButton: React.FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { account } = useWeb3React();
  const { disconnect } = useMetamaskAuth();

  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    const onCloseMenu = (event: MouseEvent) => {
      if (!event.target) return;

      if (
        !buttonRef ||
        !buttonRef.current ||
        buttonRef.current.contains(event.target as HTMLElement)
      )
        return;

      if (
        !menuRef ||
        !menuRef.current ||
        menuRef.current.contains(event.target as HTMLElement)
      )
        return;

      setOpened(false);
    };

    if (opened) {
      document.addEventListener('click', onCloseMenu);
    }

    return () => {
      document.removeEventListener('click', onCloseMenu);
    };
  }, [opened]);

  const handleDisconnect = useCallback(() => {
    setOpened(false);
    disconnect();
  }, [setOpened, disconnect]);

  if (!account) return null;

  return (
    <div className="mm-wallet-button">
      <Button
        ref={buttonRef}
        color="primary"
        onClick={() => setOpened((v) => !v)}
        nodeLeft={
          <div className="mm-wallet-button-icon">
            <Metamask />
          </div>
        }
        nodeRight={
          <motion.div
            animate={{ rotate: opened ? 180 : 0 }}
            className="mm-wallet-button-select-icon"
          >
            <ArrowDropDown color="#ffffff" />
          </motion.div>
        }
      >
        <span className="mm-wallet-button-wallet-address">
          {shortenAddress(account)}
        </span>
      </Button>
      <AnimatePresence>
        {opened && (
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            ref={menuRef}
            className="mm-wallet-button-menu"
          >
            <Button
              color="secondary"
              size="small"
              nodeRight={<ExitIcon />}
              onClick={handleDisconnect}
            >
              Disconnect
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { WalletButton };
