import { BigNumber } from '@ethersproject/bignumber';

import { IPairWallet, IDexToken } from 'api/apiPairs/models';
import { IWalletToken } from 'api/apiWallets/models';
import { addBignumbers } from 'utils/formulas';

export const calculateWalletsTokenBalance = ({
  wallets,
  token,
}: {
  wallets: IPairWallet[];
  token: IDexToken;
}): BigNumber => {
  const balance: BigNumber = wallets.reduce((acc, wallet) => {
    if (
      !wallet.tokens ||
      (wallet.tokens && !wallet.tokens.find(walletToken => walletToken.token.id === token.id))
    )
      return acc;

    const walletToken: IWalletToken | undefined = wallet.tokens.find(
      walletToken => walletToken.token.id === token.id,
    );

    const walletTokenBalanceBigNumber = walletToken
      ? BigNumber.from(walletToken.balance ?? '0')
      : BigNumber.from('0');

    return addBignumbers([walletTokenBalanceBigNumber, token.decimals], [acc, token.decimals]);
  }, BigNumber.from('0'));

  return balance;
};
