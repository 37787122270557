import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';

import { Button } from 'ui';
import { ICexWTOrganicTask } from 'types/bots';
import { CexWTOrganicTaskModal } from 'modals/CexWTOrganicTaskModal';

import './style.scss';

interface OrganicRowInfoProps {
  onDeleteTask: () => void;
  task: ICexWTOrganicTask;
  rowIdx: number;
}

export const OrganicRowInfo: React.FC<OrganicRowInfoProps> = ({ onDeleteTask, rowIdx, task }) => {
  const [taskModalOpened, setTaskModalOpened] = useState<boolean>(false);

  const handleEditTask = useCallback(() => {
    setTaskModalOpened(true);
  }, []);

  return (
    <motion.div
      key={`extended-row-${rowIdx}`}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      className="mm-wash-algorithm-bot-row-extended-info"
    >
      <div className="line-separator" />
      <div className="sections">
        <span className="section-title">Settings</span>
        <div className="section w-[50%]">
          <div className="grid content-start gap-2">
            <div>
              <span className="thin">Indicator [Per, Dev]: </span>
              <span className="bold">
                {task.options.indicator_period} / {task.options.base_volume_deviation}
              </span>
            </div>
            <div>
              <span className="thin">Amount dev per order:</span>
              <span className="bold">
                {task.options.per_order_amount_dev_min} / {task.options.per_order_amount_dev_max}
              </span>
            </div>
          </div>
          <div className="grid gap-2">
            <div>
              <span className="thin">Pause before cancel: </span>
              <span className="bold">
                {task.options.cancel_delay_min} / {task.options.cancel_delay_max}
              </span>
            </div>
            <div>
              <span className="thin">Pause between series: </span>
              <span className="bold">
                {task.options.pause_between_series_min} / {task.options.pause_between_series_max}
              </span>
            </div>
          </div>
          <div className="grid gap-2">
            <div>
              <span className="thin">Ignore percent amount: </span>
              <span className="bold">{task.options.ignore_volume_percent * 100}%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="line-separator" />
      <div className="extended-row__footer">
        <Button color="secondary" className="delete-task-button" onClick={onDeleteTask}>
          Delete
        </Button>
        <Button color="secondary" className="edit-task-button" onClick={handleEditTask}>
          Edit task
        </Button>
      </div>
      {taskModalOpened && (
        <CexWTOrganicTaskModal mode="edit" task={task} onClose={() => setTaskModalOpened(false)} />
      )}
    </motion.div>
  );
};
