import React, { useCallback, useContext, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import axios from 'axios';

import { Button, ButtonLoading } from 'ui';

import { ApiBot } from 'api';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { EDexBot } from 'types/bots';

import './actionButtons.scss';

const ActionButtons: React.FC = () => {
  const dispatch = useTypedDispatch();
  const {
    triggerLoadChart,
    CancelTokenRef,
    chartLoading,
    currentConfig,
    botSettings,
    handleLoadBotSettings,
    selectedTab,
    tradeSizeCoefficient,
    volatilityCoefficient,
    tradeFrequencyCoefficient,
  } = useContext(PairWashTradingContext);
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeWTConfig = useCallback(async () => {
    if (!currentConfig.get) return;

    try {
      setLoading(true);

      const { isSuccess, errorMessage } = await ApiBot.saveDexBotConfig({
        pairId: dexPair.id,
        bot: EDexBot.wash_trading,
        body: {
          is_enabled: botSettings?.is_enabled ?? false,
          send_private_transactions: botSettings?.send_private_transactions ?? false,
          slippage_percent: botSettings?.slippage_percent ?? '0.3',
          wt_options: {
            ...currentConfig.get?.config,
            client_options:
              selectedTab.get === 'advanced'
                ? undefined
                : {
                    trade_size_coefficient: tradeSizeCoefficient.get,
                    volatility_coefficient: volatilityCoefficient.get,
                    trade_frequency_coefficient: tradeFrequencyCoefficient.get,
                  },
          },
        },
      });

      if (isSuccess) {
        dispatch(
          setAlertState({
            type: 'success',
            text: 'Successfully changed wash trding bot config!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        await handleLoadBotSettings();
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage ?? 'Error',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Failed while trying to update wash trading config...',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [
    dispatch,
    botSettings,
    currentConfig.get,
    dexPair.id,
    handleLoadBotSettings,
    selectedTab.get,
    tradeFrequencyCoefficient.get,
    tradeSizeCoefficient.get,
    volatilityCoefficient.get,
  ]);

  const onChangeWTConfig = useCallback(() => {
    dispatch(
      setAlertState({
        type: 'sure',
        text: `Are you sure you want to apply the new settings to the market ${dexPair.token_base.symbol}/${dexPair.token_quote.symbol}?`,
        onClose: () => {
          dispatch(dropAlertState());
        },
        onSubmit: async () => {
          dispatch(dropAlertState());
          await handleChangeWTConfig();
        },
      }),
    );
  }, [handleChangeWTConfig, dexPair, dispatch]);

  const triggerSimulate = useCallback(() => {
    CancelTokenRef.current = axios.CancelToken.source();

    triggerLoadChart.set(v => !v);
  }, [triggerLoadChart.set]);

  const handleCancel = useCallback(() => {
    CancelTokenRef.current?.cancel('Stop chart loading.');
  }, [CancelTokenRef]);

  return (
    <div className="mm-wt-bot-action-buttons">
      {loading && (
        <>
          <ButtonLoading size="small" />
          <Button disabled color="secondary" size="small">
            Simulate
          </Button>
        </>
      )}
      {!loading && (
        <>
          <Button disabled={!!chartLoading.get} size="small" onClick={onChangeWTConfig}>
            Save
          </Button>
          {chartLoading.get && (
            <Button color="error-secondary" size="small" onClick={handleCancel}>
              Cancel
            </Button>
          )}
          {!chartLoading.get && (
            <Button color="secondary" size="small" onClick={triggerSimulate}>
              Simulate
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ActionButtons;
