import React, { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { Mask } from 'react-text-mask';

import {
  fieldLabelFocused,
  fieldLabelNotFocusedFilled,
  fieldLabelNotFocusedNotFilled,
} from 'constants/fields';

import { EyeHidden, EyeOpen } from 'assets/icons';
import { FieldHints } from '../../components';
import { Input } from './Inputs';
import './style.scss';

export type IInputTypeProp = 'password' | 'decimal-number' | 'text' | 'natural-number';

export type IInputTypeInternal = 'text' | 'password';

interface IInputFieldProps<V extends string> extends HTMLAttributes<HTMLInputElement> {
  comment?: string;
  errorMessage?: string;
  value: V;
  type: IInputTypeProp;
  setValue?: (v: V) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  readonly?: boolean;
  nodeLeft?: React.ReactNode;
  nodeRight?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  name?: string;
}

export interface IMaskProps {
  mask?: Mask | ((value: string) => Mask);
  maskPlaceholderChar?: string;
  maskGuide?: boolean;
  maskKeepCharPositions?: boolean;
}

export interface IInputFieldInternalProps<V extends string>
  extends IInputFieldProps<V>,
    IMaskProps {}

function InputField<V extends string>({
  comment,
  errorMessage,
  name,
  value,
  nodeLeft,
  nodeRight,
  type,
  setValue,
  placeholder,
  label,
  disabled = false,
  readonly = false,
  onInput,
  onChange,
  onFocus,
  onBlur,
  onClick,
  className,
  id,
  mask,
  maskPlaceholderChar,
  maskGuide,
  maskKeepCharPositions,
  ...rest
}: IInputFieldInternalProps<V>) {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const [inputType, setInputType] = useState<IInputTypeInternal>('text');

  const inputId = useMemo(() => {
    return id ?? `mm-input-${uuid()}`;
  }, [id]);

  const lineStyles = useMemo(() => {
    let borderColor = 'rgba(127, 145, 187, 0.2)';

    if (errorMessage !== undefined) {
      borderColor = '#CE4966';
    } else if (isFocused) {
      borderColor = '#5932ea';
    }

    return { borderBottom: `2px solid ${borderColor}` };
  }, [isFocused, errorMessage]);

  const labelStyles = useMemo(() => {
    const fieldFilled = value !== undefined && value !== null && value !== '';
    const fieldFocused = isFocused;

    if (fieldFocused) return fieldLabelFocused(!!errorMessage);

    if (!fieldFocused && fieldFilled) return fieldLabelNotFocusedFilled(!!errorMessage);

    return fieldLabelNotFocusedNotFilled;
  }, [value, isFocused, errorMessage]);

  useEffect(() => {
    if (['natural-number', 'decimal-number'].includes(type)) {
      setInputType('text');
    } else {
      setInputType(type as IInputTypeInternal);
    }
  }, [type]);

  const normalizeDecimalNumber = useCallback(
    (_value: string) => {
      return isNaN(Number(_value)) ? value : _value;
    },
    [value],
  );

  const normalizeNaturalNumber = useCallback(
    (_value: string) => {
      const result = isNaN(Number(_value)) ? value : _value;

      return result.split('.')?.[0] ?? '';
    },
    [value],
  );

  const handleInput = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      let resultValue = event.currentTarget.value;

      if (type === 'decimal-number') {
        resultValue = normalizeDecimalNumber(resultValue);
      }
      if (type === 'natural-number') {
        resultValue = normalizeNaturalNumber(resultValue);
      }

      if (setValue) {
        setValue(resultValue as V);
      }

      if (onInput) {
        onInput(event);
      }
    },
    [setValue, type, normalizeDecimalNumber, normalizeNaturalNumber, onInput],
  );

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (readonly) return;

      setIsFocused(true);

      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus, readonly],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (readonly) return;

      setIsFocused(false);

      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur, readonly],
  );

  const handleTogglePasswordInputType = useCallback(() => {
    setInputType(v => (v === 'text' ? 'password' : 'text'));
  }, []);

  return (
    <div className={cn({ [className ?? '']: className }, 'mm-input-field')} {...rest}>
      <div className="mm-input-field__container">
        <div className="mm-input-field__inner">
          {nodeLeft ? nodeLeft : null}
          <div className="mm-input-field__inner__input-holder">
            <Input
              name={name}
              id={inputId}
              inputMode={
                type === 'decimal-number'
                  ? 'decimal'
                  : type === 'natural-number'
                  ? 'numeric'
                  : undefined
              }
              onBlur={handleBlur}
              onChange={handleChange}
              onClick={onClick}
              onFocus={handleFocus}
              onInput={handleInput}
              type={inputType}
              className={cn('mm-input-field__inner__input', {
                _focused: isFocused,
                '_no-label': !label || label === '',
              })}
              disabled={disabled}
              readOnly={readonly}
              value={value}
              placeholder={placeholder}
              mask={mask}
              maskGuide={maskGuide}
              maskKeepCharPositions={maskKeepCharPositions}
              maskPlaceholderChar={maskPlaceholderChar}
            />
            {label ? (
              <motion.label
                initial={fieldLabelNotFocusedNotFilled}
                animate={labelStyles}
                className={cn('mm-input-field__inner__label', { _disabled: !!disabled })}
                htmlFor={inputId}
              >
                {label}
              </motion.label>
            ) : null}
          </div>
          {type === 'password' && (
            <button
              className="mm-input-field__password-eye"
              onClick={handleTogglePasswordInputType}
            >
              {inputType === 'password' && <EyeHidden />}
              {inputType === 'text' && <EyeOpen />}
            </button>
          )}
          {nodeRight ? nodeRight : null}
        </div>
        <motion.div
          animate={lineStyles}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
          className={'mm-input-field__line'}
        />
      </div>
      <FieldHints comment={comment} errorMessage={errorMessage} />
    </div>
  );
}

export { InputField };
