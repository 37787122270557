import React from 'react';

const Metamask: React.FC = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2091 2.5L12.957 7.13838L14.1182 4.40185L19.2091 2.5Z"
        fill="#E17726"
        stroke="#E17726"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.79102 2.5L10.9862 7.18227L9.88198 4.40185L4.79102 2.5Z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.962 13.2539L15.2988 15.8025L18.8577 16.7812L19.8748 13.3102L16.962 13.2539Z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.13184 13.3102L5.14273 16.7812L8.70158 15.8025L7.04458 13.2539L4.13184 13.3102Z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50638 8.94519L7.51465 10.4458L11.0426 10.6028L10.9233 6.80469L8.50638 8.94519Z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4932 8.94612L13.0386 6.76172L12.957 10.6037L16.485 10.4467L15.4932 8.94612Z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70117 15.8029L10.8293 14.7667L8.9961 13.3359L8.70117 15.8029Z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1709 14.7667L15.299 15.8029L15.0041 13.3359L13.1709 14.7667Z"
        fill="#E27625"
        stroke="#E27625"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2981 15.8019L13.1699 14.7656L13.3393 16.1531L13.3208 16.7367L15.2981 15.8019Z"
        fill="#D5BFB2"
        stroke="#D5BFB2"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70117 15.8019L10.6784 16.7367L10.6661 16.1531L10.8293 14.7656L8.70117 15.8019Z"
        fill="#D5BFB2"
        stroke="#D5BFB2"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7164 12.4161L8.94629 11.8955L10.1952 11.3242L10.7164 12.4161Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2832 12.4161L13.8044 11.3242L15.0595 11.8955L13.2832 12.4161Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70193 15.8025L9.00921 13.2539L7.04492 13.3102L8.70193 15.8025Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9912 13.2539L15.2985 15.8025L16.9617 13.3102L14.9912 13.2539Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.485 10.4453L12.957 10.6024L13.2835 12.4158L13.8047 11.3239L15.0598 11.8952L16.485 10.4453Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.94598 11.8952L10.1949 11.3239L10.7161 12.4158L11.0426 10.6024L7.51465 10.4453L8.94598 11.8952Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.51465 10.4453L8.99606 13.3327L8.94598 11.8952L7.51465 10.4453Z"
        fill="#E27525"
        stroke="#E27525"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0602 11.8952L15.0039 13.3327L16.4853 10.4453L15.0602 11.8952Z"
        fill="#E27525"
        stroke="#E27525"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0433 10.6016L10.7168 12.415L11.131 14.5555L11.2188 11.7374L11.0433 10.6016Z"
        fill="#E27525"
        stroke="#E27525"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9575 10.6016L12.7881 11.7312L12.8697 14.5555L13.284 12.415L12.9575 10.6016Z"
        fill="#E27525"
        stroke="#E27525"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2834 12.419L12.8691 14.5595L13.1702 14.7667L15.0035 13.336L15.0597 11.8984L13.2834 12.419Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.94629 11.8984L8.99637 13.336L10.8296 14.7667L11.1307 14.5595L10.7164 12.419L8.94629 11.8984Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.321 16.7356L13.3395 16.152L13.1831 16.0141H10.8163L10.6661 16.152L10.6784 16.7356L8.70117 15.8008L9.3918 16.3653L10.7978 17.3385H13.2017L14.6077 16.3653L15.2983 15.8008L13.321 16.7356Z"
        fill="#C0AC9D"
        stroke="#C0AC9D"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1701 14.7657L12.869 14.5586H11.1303L10.8292 14.7657L10.666 16.1532L10.8163 16.0153H13.1831L13.3395 16.1532L13.1701 14.7657Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4731 7.4401L20.0005 4.87916L19.2097 2.5L13.1709 6.98195L15.4938 8.94624L18.7763 9.90706L19.4984 9.05939L19.1843 8.83372L19.6864 8.37495L19.3037 8.07384L19.8057 7.69112L19.4731 7.4401Z"
        fill="#763E1A"
        stroke="#763E1A"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 4.87916L4.53358 7.4401L4.19476 7.69112L4.69681 8.07384L4.31409 8.37495L4.81614 8.83372L4.50205 9.05939L5.22421 9.90706L8.50668 8.94624L10.8296 6.98195L4.79079 2.5L4 4.87916Z"
        fill="#763E1A"
        stroke="#763E1A"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7761 9.90613L15.4936 8.94531L16.4853 10.4459L15.0039 13.3333L16.962 13.3079H19.8748L18.7761 9.90613Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50682 8.94531L5.22435 9.90613L4.13184 13.3079H7.04458L8.99651 13.3333L7.51509 10.4459L8.50682 8.94531Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9572 10.6044L13.1705 6.98245L14.1183 4.40234H9.88184L10.8297 6.98245L11.043 10.6044L11.1246 11.7463L11.1308 14.5583H12.8694L12.8756 11.7463L12.9572 10.6044Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeWidth="0.712817"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { Metamask };
