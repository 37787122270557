import { CancelToken } from 'axios';
import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import {
  IDexWTBotRandomStrategy,
  IDexWTSimulationOptions,
  IDexWTBotVolumesStrategy,
} from 'types/bots';
import { IWTShortPreset } from 'types/wash-trading-bot';
import { IWTSimulatorResponse } from './models';

interface IWTGetPresetsResponse {
  items: IWTShortPreset[];
  total_items: number;
}

interface IWTGetPresetResponse {
  created_at: string;
  updated_at: string;
  id: number;
  name: string;
  options: {
    random_strategy: IDexWTBotRandomStrategy;
    volumes_strategy: IDexWTBotVolumesStrategy;
    send_private_transactions: boolean;
    slippage_percent: string;
  };
}

class ApiWTBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiWTBot | undefined;
  private static instance = (() => {
    if (ApiWTBot.__instance === undefined) {
      ApiWTBot.__instance = new ApiWTBot();
    }
    return ApiWTBot.__instance;
  })();

  public static getWTSimulationResult = async (
    {
      options,
    }: {
      options: IDexWTSimulationOptions;
    },
    { cancelToken }: { cancelToken?: CancelToken | undefined },
  ): Promise<Response<IWTSimulatorResponse>> => {
    let mappedResponseOrError: Response<IWTSimulatorResponse>;

    try {
      const response = await ApiWTBot.instance.post<IBaseResponse<IWTSimulatorResponse>>(
        '/simulations/wtbot/random',
        options,
        { cancelToken },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IWTSimulatorResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static getWTPresets = async (): Promise<Response<IWTGetPresetsResponse>> => {
    let mappedResponseOrError: Response<IWTGetPresetsResponse>;

    const urlParams = new URLSearchParams();

    urlParams.append('limit', '10');
    urlParams.append('offset', '0');

    try {
      const response = await ApiWTBot.instance.get<IBaseResponse<IWTGetPresetsResponse>>(
        '/wtpresets' + '?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IWTGetPresetsResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static getWTPreset = async (id: number): Promise<Response<IWTGetPresetResponse>> => {
    let mappedResponseOrError: Response<IWTGetPresetResponse>;

    try {
      const response = await ApiWTBot.instance.get<IBaseResponse<IWTGetPresetResponse>>(
        `/wtpresets/${id}`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IWTGetPresetResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static updateWTPreset = async ({
    config,
    id,
  }: {
    config: {
      name: string;
      random_strategy: IDexWTBotRandomStrategy;
      volumes_strategy: IDexWTBotVolumesStrategy | undefined;
      send_private_transactions: boolean;
      slippage_percent: string;
    };
    id: number;
  }): Promise<Response<IWTGetPresetResponse>> => {
    let mappedResponseOrError: Response<IWTGetPresetResponse>;

    try {
      const response = await ApiWTBot.instance.patch<IBaseResponse<IWTGetPresetResponse>>(
        `/wtpresets/${id}`,
        {
          name: config.name,
          options: {
            random_strategy: config.random_strategy,
            volumes_strategy: config.volumes_strategy,
            send_private_transactions: config.send_private_transactions,
            slippage_percent: config.slippage_percent,
          },
        },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IWTGetPresetResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static deleteWTPreset = async (id: number): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiWTBot.instance.delete<IBaseResponse<any>>(`/wtpresets/${id}`);

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static createWTPreset = async ({
    name,
    config,
  }: {
    name: string;
    config: {
      random_strategy: IDexWTBotRandomStrategy;
      volumes_strategy: IDexWTBotVolumesStrategy | undefined;
      send_private_transactions: boolean;
      slippage_percent: string;
    };
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiWTBot.instance.post<IBaseResponse<any>>(`/wtpresets`, {
        name: name,
        options: { ...config },
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiWTBot };
