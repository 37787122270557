import React from 'react';
import { Formik } from 'formik';
import { isNil } from 'lodash';

import { Modal, Button, ButtonLoading, ErrorText } from 'ui';
import { InputField } from 'fields';
import { CreateWalletsForm } from 'ui/forms';

import { useCreateWalletsModal } from './useCreateWalletsModal/useCreateWalletsModal';

interface ICreateWalletsModal {
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
}

const CreateWalletsModal: React.FC<ICreateWalletsModal> = ({ onClose, onOpen, onSuccess }) => {
  const { initialValues, validationSchema, loading, handleCreateWallets } = useCreateWalletsModal({
    onClose,
    onOpen,
    onSuccess,
  });

  return (
    <Modal onClose={onClose} title="Create new wallets">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleCreateWallets}
      >
        {({ values, handleSubmit, errors, touched, setFieldValue }) => {
          let error;
          if (touched.amount && errors.amount) {
            error = errors.amount;
          }

          return (
            <CreateWalletsForm handleSubmit={handleSubmit}>
              <InputField
                label="Amount"
                type="natural-number"
                value={!isNil(values.amount) ? values.amount.toString() : ''}
                setValue={newAmount => setFieldValue('amount', newAmount ? newAmount : undefined)}
              />
              <ErrorText>{error}</ErrorText>
              {!loading && <Button type="submit">Confirm</Button>}
              {loading && <ButtonLoading />}
            </CreateWalletsForm>
          );
        }}
      </Formik>
    </Modal>
  );
};

export { CreateWalletsModal };
