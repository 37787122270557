import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import { trimDecimalZeroes } from './trimDecimalZeroes';
import { formatNumberToLocale } from './formatNumberToLocale';

const calculateFix = (amount: string): number => {
  const integer = Number(trimDecimalZeroes(amount).split('.')[0]);

  if (isNaN(integer)) return 6;

  if (Math.abs(integer) < 5) return 6;

  if (Math.abs(integer) < 50) return 4;

  if (Math.abs(integer) < 500) return 3;

  if (Math.abs(integer) < 5000) return 2;

  return 0;
};

export const formatToken = (bn: BigNumber, decimals = 18): string => {
  const formattedBN = formatUnits(bn, decimals);
  const stringAmount = trimDecimalZeroes(formattedBN);

  const fix = calculateFix(stringAmount);

  return formatNumberToLocale(stringAmount, fix);
};
