import React from 'react';
import { Row } from '@tanstack/react-table';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface INotesAccountCellProps {
  row: Row<ICexAccounts>;
}

const NotesAccountCell: React.FC<INotesAccountCellProps> = React.memo(({ row }) => {
  return <div>{row.original.notes}</div>;
});

export default NotesAccountCell;
