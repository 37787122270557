import React from 'react';
import { Table } from '@tanstack/react-table';

import { TableCheckbox } from 'ui';
import { IWallet } from 'api/apiWallets/models';

interface IWalletAddressHeadProps {
  table: Table<IWallet>;
}

const WalletAddressHead: React.FC<IWalletAddressHeadProps> = ({ table }) => {
  return (
    <div className="checkbox-area">
      <TableCheckbox
        id="select-all"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
      <span>Wallets</span>
    </div>
  );
};

export default WalletAddressHead;
