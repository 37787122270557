import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { ICexPair } from 'types/pairs';

class ApiCexPairs extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiCexPairs | undefined;
  private static instance = (() => {
    if (ApiCexPairs.__instance === undefined) {
      ApiCexPairs.__instance = new ApiCexPairs();
    }
    return ApiCexPairs.__instance;
  })();

  public static getCexPairById = async (id: number): Promise<Response<ICexPair>> => {
    let mappedResponseOrError: Response<ICexPair>;

    try {
      const response = await ApiCexPairs.instance.get<IBaseResponse<ICexPair>>(`/cex-pairs/${id}`);
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<ICexPair>({ error });
    }

    return mappedResponseOrError;
  };

  public static updateCexPair = async ({ id, notes }: { id: number; notes: string }) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiCexPairs.instance.patch<IBaseResponse<any>>(`/cex-pairs/${id}`, {
        notes,
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static deleteCexPair = async (id: number) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiCexPairs.instance.delete<IBaseResponse<any>>(`/cex-pairs/${id}`);

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiCexPairs };
