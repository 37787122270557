import React, { useCallback, useState, useRef, useEffect, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';

import { SelectArrow, IIconProps } from 'assets/icons';
import PanelErrorBoundary from './PanelErrorBoundary';

import './panel.scss';

export interface IPanelProps {
  children: React.ReactNode;
  initialOpened?: boolean;
  icon?: React.FC<IIconProps>;
  title: string;
  switcher?: ReactNode;
  className?: string | undefined;
  headerNode?: React.ReactNode;
  onChange?: (opened: boolean) => void;
  subTitle?: string;
}

const Panel: React.FC<IPanelProps> = ({
  children,
  initialOpened,
  icon: Icon,
  title,
  switcher,
  className,
  headerNode,
  onChange,
  subTitle,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(initialOpened ?? false);
  const panelContentRef = useRef<HTMLDivElement>(null);

  const togglePanel = useCallback(() => {
    const newOpened = !isOpened;

    setIsOpened(newOpened);

    if (onChange) {
      onChange(newOpened);
    }
  }, [isOpened, onChange]);

  useEffect(() => {
    if (isOpened && panelContentRef && panelContentRef.current) {
      setTimeout(() => {
        panelContentRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [isOpened]);

  return (
    <motion.section
      initial={{ x: -200, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      className={cn('mm-panel', { [className ?? '']: !!className })}
      ref={panelContentRef}
    >
      <div className={cn('mm-panel__toolbar', { _opened: isOpened })} onClick={togglePanel}>
        <div className={'mm-panel__toolbar__title'}>
          <div className={'mm-panel__toolbar__title__icon'}>
            {Icon && <Icon color={!isOpened ? '#7F91BB' : '#5932EA'} />}
          </div>
          <span className={cn('mm-panel__toolbar__title__inner', { _opened: isOpened })}>
            {title}
          </span>
          {subTitle && <span className="mm-panel__toolbar__subTitle__inner">{subTitle}</span>}
        </div>
        {headerNode && <div className="mm-panel__toolbar__custom-header">{headerNode}</div>}
        <div className={'switcher-arrow-container'}>
          {switcher && <span onClick={event => event.stopPropagation()}>{switcher}</span>}
          <motion.div
            animate={{ rotate: isOpened ? 180 : 0 }}
            className="mm-panel__toolbar__open-icon"
          >
            <SelectArrow color={!isOpened ? '#7F91BB' : '#5932EA'} />
          </motion.div>
        </div>
      </div>
      <AnimatePresence>
        {isOpened && (
          <PanelErrorBoundary>
            <motion.div
              className={cn('mm-panel__content', { _opened: isOpened })}
              transition={{ easings: ['easeInOut'], duration: 0.3 }}
              exit={{ height: 0, opacity: 0 }}
            >
              {children}
            </motion.div>
          </PanelErrorBoundary>
        )}
      </AnimatePresence>
    </motion.section>
  );
};

export { Panel };
