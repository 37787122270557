class LocalStorageService {
  static get<T>(key: string) {
    return (localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)!) : {}) as T;
  }

  static set(key: string, value: unknown) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log('error: ', error);
    }
  }

  static remove(key: string) {
    return localStorage.removeItem(key);
  }
}

export { LocalStorageService as LocalStorage };
