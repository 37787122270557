import React from 'react';
import { Table } from '@tanstack/react-table';

import { IPairWallet } from 'api/apiPairs/models';
import { TableCheckbox } from 'ui';

interface IWalletAddressHeadProps {
  table: Table<IPairWallet>;
}

const WalletAddressHead: React.FC<IWalletAddressHeadProps> = ({ table }) => {
  return (
    <div className="checkbox-area">
      <TableCheckbox
        id="select-all-to-connect-to-dex-pair"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
      <span>Wallet address</span>
    </div>
  );
};

export default WalletAddressHead;
