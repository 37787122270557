import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

import {
  BSC_CHAIN_ID,
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
  BSC_MAIN_ASSET,
  ETH_MAIN_ASSET,
  POLYGON_MAIN_ASSET,
  ARBITRUM_MAIN_ASSET,
} from 'types/web3';

// function that decides which method to use to get a balanceOf
// possible cases:
// 1. main asset of network (e.g. ETH, BNB) - use getBalance
// 2. ERC20 - use balanceOf

export async function getBalanceOf({
  tokenAddress,
  library,
  contract,
  account,
  chainId,
}: {
  tokenAddress: string;
  library: any;
  contract: Contract;
  account: string | undefined | null;
  chainId: number | undefined;
}): Promise<BigNumber> {
  if (!account || !chainId) throw new Error('Connect metamask to watch your balances in wallet');

  if (
    chainId !== BSC_CHAIN_ID &&
    chainId !== ETH_CHAIN_ID &&
    chainId !== POLYGON_CHAIN_ID &&
    chainId !== ARBITRUM_CHAIN_ID
  )
    throw new Error('Change your network to appropriate');

  const isERC20Mapper = {
    [BSC_CHAIN_ID]: tokenAddress.toLocaleLowerCase() !== BSC_MAIN_ASSET,
    [ETH_CHAIN_ID]: tokenAddress.toLocaleLowerCase() !== ETH_MAIN_ASSET,
    [POLYGON_CHAIN_ID]: tokenAddress.toLocaleLowerCase() !== POLYGON_MAIN_ASSET,
    [ARBITRUM_CHAIN_ID]: tokenAddress.toLocaleLowerCase() !== ARBITRUM_MAIN_ASSET,
  };

  const isERC20 = isERC20Mapper[chainId];

  return await (isERC20 ? contract.balanceOf(account) : library.getBalance(account));
}
