import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { dayjs_locales } from 'constants/locales';

dayjs.extend(UTC);
dayjs.extend(localizedFormat);

const loadTimeLocale = () => {
  const nav_lang =
    navigator.languages.length !== 0 ? navigator.languages[0] : navigator.language ?? false;

  if (!nav_lang) return;

  const lang = nav_lang.toLowerCase();

  const checkLocale = (locale: string) => {
    if (['en', 'en-us'].includes(locale)) return true;
    if (locale === 'zn') return 'zh-cn';
    if (locale === 'no') return 'nb';
    if (dayjs_locales.includes(locale)) return locale;

    return locale.includes('-') ? locale.split('-')[0] : true;
  };

  const localeStatus = checkLocale(lang);

  if (localeStatus === true) return;

  import(/* webpackChunkName: "user-defined" */ `dayjs/locale/${localeStatus}.js`).then(() => {
    dayjs.locale(localeStatus);
  });
};

export default loadTimeLocale;
