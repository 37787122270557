import React from 'react';
import { useTypedDispatch } from 'store';
import { useWeb3React } from '@web3-react/core';

import { Button, ConnectWalletButton, WalletButton } from 'ui';
import { useEagerConnect, useInactiveListener } from 'hooks';
import { onLogout } from 'store/slices/auth';
import { PageHeaderPiece } from '../PageHeaderPiece/PageHeaderPiece';

import './header.scss';

const Header: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { account } = useWeb3React();

  const { tried } = useEagerConnect();
  useInactiveListener(tried);

  return (
    <header className="mm-header">
      <div className="mm-header__left">
        <PageHeaderPiece />
      </div>
      <div className="mm-header__right">
        {!account && <ConnectWalletButton />}
        {account && <WalletButton />}
        <Button onClick={() => dispatch(onLogout({}))}>logout</Button>
      </div>
    </header>
  );
};

export { Header };
