import { useCallback, useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { ApiCharts } from 'api';
import { durationToMs } from 'utils/duration';
import { IDexPair } from 'types/pairs';
import { IKattanaCexChartResponse } from 'api/apiCharts/models';

interface IProps {
  timeframes: string[];
  period: number;
  dexPair: IDexPair;
}

const timeframeToRange = ({
  timeframe,
  candles,
  period,
}: {
  timeframe: string;
  candles: number;
  period: number;
}) => {
  const ms = durationToMs(timeframe);

  return {
    end: Date.now(),
    start: Date.now() - ms * candles + period,
  };
};

export const useDexCandlesByTimeframe = ({ timeframes, period, dexPair }: IProps) => {
  const [resultCandles, setResultCandles] = useState<Record<string, IKattanaCexChartResponse>>({});

  const setupKattanaTimeframes = useCallback(async () => {
    try {
      if (timeframes.length === 0) return;

      const ranges = timeframes.map(timeframe => {
        const tf = durationToMs(timeframe);

        const tfsCount = Math.ceil((24 * 60 * 60 * 1000) / tf);
        const limit = tfsCount + period;

        return {
          timeframe,
          ...timeframeToRange({ timeframe, candles: limit, period }),
          limit,
        };
      });

      const isFlipped = BigNumber.from(dexPair.token_base.address).gt(
        BigNumber.from(dexPair.token_quote.address),
      );

      const results = await Promise.all(
        ranges.map(el =>
          ApiCharts.getKattanaDexPairChart({
            startTime: Math.floor(el.start / 1000),
            endTime: Math.floor(el.end / 1000),
            limit: el.limit,
            timeframe: el.timeframe,
            converted: undefined,
            flipped: isFlipped,
            precision: dexPair.token_base.decimals,
            network: dexPair.network,
            pair: dexPair.address,
          }),
        ),
      );

      const _candles = ranges.reduce(
        (acc, val, idx) => ({
          ...acc,
          [val.timeframe]: results[idx].data ?? [],
        }),
        {},
      );

      setResultCandles(_candles);
    } catch (error) {
      console.log('error: ', error);
    }
  }, [timeframes, period, dexPair]);

  useEffect(() => {
    setupKattanaTimeframes();
  }, [setupKattanaTimeframes]);

  return {
    candles: resultCandles,
  };
};
