import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ApiStatistic } from 'api';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { IStatisticToken, IStatisticTransaction } from 'api/apiStatistic/models';
import { IChartRange } from 'types/charts';
import { Bus } from 'tools';
import { PROJECT_STATISTIC_RELOAD, STOP_RELOAD_PREFIX } from 'constants/reload';

interface IProjectStatisticContext {
  statisticTokens: IStatisticToken[] | undefined;
  statisticTransactions: IStatisticTransaction[] | undefined;
  firstTradeTime: string | undefined;
  generalLoading: boolean;
  selectedRange: {
    get: IChartRange | undefined;
    set: Dispatch<SetStateAction<IChartRange | undefined>>;
  };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  rangeLoading: boolean;
}

export const ProjectStatisticContext = createContext<IProjectStatisticContext>({
  statisticTokens: undefined,
  statisticTransactions: undefined,
  firstTradeTime: undefined,
  generalLoading: false,
  selectedRange: { get: '1M', set: () => {} },
  startDate: { get: undefined, set: () => {} },
  endDate: { get: undefined, set: () => {} },
  rangeLoading: false,
});

interface ProjectStatisticContextProviderProps {
  children?: React.ReactNode;
}

const ProjectStatisticContextProvider: React.FC<ProjectStatisticContextProviderProps> = ({
  children,
}) => {
  const dispatch = useTypedDispatch();
  const project = useTypedSelector(store => store.projects.selectedProjectInPage);
  const [generalLoading, setGeneralLoading] = useState<boolean>(true);
  const [selectedRange, setSelectedRange] = useState<IChartRange | undefined>('1M');
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [rangeLoading, setRangeLoading] = useState<boolean>(false);

  const [statisticTokens, setStatisticTokens] = useState<IStatisticToken[] | undefined>(undefined);
  const [statisticTransactions, setStatisticTransactions] = useState<
    IStatisticTransaction[] | undefined
  >(undefined);
  const [firstTradeTime, setFirstTradeTime] = useState<string | undefined>(undefined);

  const setupAllStatisticGetRequests = useCallback(
    async (options: { silent: boolean } = { silent: false }) => {
      if (!project) {
        setGeneralLoading(true);
        return;
      }

      try {
        if (!options.silent) {
          if (!statisticTokens || statisticTokens?.length === 0) {
            setGeneralLoading(true);
          } else {
            setRangeLoading(true);
          }
        }

        const { isSuccess, data, errorMessage } = await ApiStatistic.getStatistic({
          projects_ids: [project.id],
          ranges: [
            selectedRange
              ? selectedRange
              : startDate && endDate
              ? `${Math.floor(startDate / 1000)}-${Math.floor(endDate / 1000)}`
              : '1D',
          ],
        });

        if (isSuccess && data) {
          setStatisticTokens(data.tokens);
          setStatisticTransactions(data.transactions);
          setFirstTradeTime(data.first_trade_time);
        } else if (!isSuccess && errorMessage) {
          dispatch(
            setAlertState({
              text: errorMessage,
              type: 'failed',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setGeneralLoading(false);
        setRangeLoading(false);
      }
    },
    [project, selectedRange, startDate, endDate, statisticTokens, dispatch],
  );

  useEffect(() => {
    setupAllStatisticGetRequests();
  }, [project, selectedRange, startDate, endDate]);

  const handleReload = useCallback(async () => {
    if (!generalLoading) {
      const timeStart = Date.now();

      await setupAllStatisticGetRequests({ silent: true });

      const timeEnd = Date.now();

      if (timeEnd - timeStart >= 1500) {
        Bus.emit(STOP_RELOAD_PREFIX + PROJECT_STATISTIC_RELOAD);
      } else {
        setTimeout(() => {
          Bus.emit(STOP_RELOAD_PREFIX + PROJECT_STATISTIC_RELOAD);
        }, 1500 - (timeEnd - timeStart));
      }
    }
  }, [generalLoading, setupAllStatisticGetRequests]);

  useEffect(() => {
    Bus.on(PROJECT_STATISTIC_RELOAD, handleReload);

    return () => {
      Bus.off(PROJECT_STATISTIC_RELOAD, handleReload);
    };
  }, [handleReload]);

  const value = useMemo(
    () => ({
      statisticTokens,
      statisticTransactions,
      firstTradeTime,
      generalLoading,
      selectedRange: { get: selectedRange, set: setSelectedRange },
      startDate: { get: startDate, set: setStartDate },
      endDate: { get: endDate, set: setEndDate },
      rangeLoading,
    }),
    [
      statisticTokens,
      statisticTransactions,
      firstTradeTime,
      generalLoading,
      selectedRange,
      startDate,
      endDate,
      rangeLoading,
    ],
  );

  return (
    <ProjectStatisticContext.Provider value={value}>{children}</ProjectStatisticContext.Provider>
  );
};

export default ProjectStatisticContextProvider;
