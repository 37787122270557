import React, { useMemo } from 'react';

import { Switcher } from 'ui';
import { IDextWTOrganicVolumeTask } from 'types/bots';
import { OpenIcon } from 'assets/icons/OpenIcon';

interface IStatusCellProps {
  idx: number;
  openedRowIdx: number | undefined;
  task: IDextWTOrganicVolumeTask;
  onTriggerEnable: (s: boolean) => void;
}

const StatusCell: React.FC<IStatusCellProps> = ({ idx, openedRowIdx, onTriggerEnable, task }) => {
  const isOpened = useMemo(() => idx === openedRowIdx, [idx, openedRowIdx]);

  return (
    <div className="switcher-cell">
      <div onClick={e => e.stopPropagation()}>
        <Switcher onChange={onTriggerEnable} value={task.enabled} />
      </div>
      <OpenIcon opened={isOpened} />
    </div>
  );
};

export { StatusCell };
