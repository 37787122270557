import React from 'react';
import { capitalize } from 'lodash';
import cn from 'classnames';

import { ICexOrder, ICexOrderSide } from 'types/orders';

interface ISideProps {
  side: ICexOrderSide;
}

const Side = React.memo(({ side }: ISideProps) => {
  return (
    <div style={{ textAlign: 'left' }} className="direction">
      <span className={cn({ green: side === 'buy', red: side === 'sell' })}>
        {capitalize(side)}
      </span>
    </div>
  );
});

const sideAccessor = (row: ICexOrder) => row.side;

Side.displayName = 'Side';

export { Side, sideAccessor };
