import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useTypedSelector } from 'store';
import { toFixed, formatToken } from 'utils/formats';

export const Progress: React.FC<{ progress: { amount: string; amountPercent: string } }> = ({
  progress,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const percentString = useMemo(() => toFixed(Number(progress.amountPercent), 2), [progress]);

  const pairTokenBaseSymbol = useMemo(() => pair?.token_base.symbol ?? '-', [pair]);

  const progressAmount = useMemo(
    () => (pair ? formatToken(BigNumber.from(progress.amount), pair.token_base.decimals) : ''),
    [progress, pair],
  );

  return (
    <>
      {progressAmount} {pairTokenBaseSymbol} / {percentString}%
    </>
  );
};
