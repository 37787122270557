import { useCallback, useMemo, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import { validationSchema } from './validationSchema';
import { IDexWTBotVolumeStrategyTask } from 'types/bots';
import { durationToMs, msToDuration } from 'utils/duration';
import dayjs from 'dayjs';
import { trimDecimalZeroes } from 'utils/formats';
import { useTypedSelector } from 'store';

interface IForm {
  condition_type: 'price_change' | 'start_at';
  //conditional
  start_at: number | null;
  duration: number | undefined;
  direction: 'any' | 'rise' | 'fall';
  threshold_percentage: string;

  //common
  min_volume: string;
  max_volume: string;
  min_serie_duration: number | undefined;
  max_serie_duration: number | undefined;
  min_pause_between_series: number | undefined;
  max_pause_between_series: number | undefined;
  min_pause_between_groups: number | undefined;
  max_pause_between_groups: number | undefined;
  min_transactions_in_group: string;
  max_transactions_in_group: string;
  slippage_percent: string;
  repeatability: string;
}

interface IUseRegularTaskProps {
  onSubmit: (task: IDexWTBotVolumeStrategyTask) => void;
  onClose: () => void;
  task?: IDexWTBotVolumeStrategyTask;
  mode: 'create' | 'edit';
}

export const useRegularTask = ({ onSubmit: submit, onClose, task, mode }: IUseRegularTaskProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [edited, setEdited] = useState<boolean>(false);

  const initialValues = useMemo<IForm>(() => {
    if (mode === 'edit' && task) {
      return {
        condition_type: task.condition.type,
        direction:
          task.condition.type === 'price_change'
            ? task.condition.condition_price_change?.direction ?? 'any'
            : 'any',
        duration:
          task.condition.type === 'price_change'
            ? durationToMs(task.condition.condition_price_change?.duration ?? '0')
            : undefined,
        threshold_percentage:
          task.condition.type === 'price_change'
            ? String(task.condition.condition_price_change?.threshold_percentage ?? 0)
            : '',
        start_at:
          task.condition.type === 'start_at'
            ? dayjs(task.condition.condition_start_at?.start_at ?? 0).valueOf()
            : null,
        min_volume: trimDecimalZeroes(
          formatUnits(BigNumber.from(task.min_volume), dexPair.token_base.decimals),
        ),
        max_volume: trimDecimalZeroes(
          formatUnits(BigNumber.from(task.max_volume), dexPair.token_base.decimals),
        ),
        min_serie_duration: durationToMs(task.min_series_duration),
        max_serie_duration: durationToMs(task.max_series_duration),
        min_pause_between_series: durationToMs(task.min_pause_between_series),
        max_pause_between_series: durationToMs(task.max_pause_between_series),
        min_pause_between_groups: durationToMs(task.min_pause_between_groups),
        max_pause_between_groups: durationToMs(task.max_pause_between_groups),
        min_transactions_in_group: String(task.min_transactions_in_group),
        max_transactions_in_group: String(task.max_transactions_in_group),
        slippage_percent: String(task.slippage_percent),
        repeatability: task.repeatability ? String(task.repeatability) : '',
      };
    }

    return {
      condition_type: 'start_at',
      start_at: null,
      duration: undefined,
      direction: 'any',
      threshold_percentage: '',
      min_volume: '',
      max_volume: '',
      min_serie_duration: undefined,
      max_serie_duration: undefined,
      min_pause_between_series: undefined,
      max_pause_between_series: undefined,
      min_pause_between_groups: undefined,
      max_pause_between_groups: undefined,
      min_transactions_in_group: '',
      max_transactions_in_group: '',
      slippage_percent: '',
      repeatability: '',
    };
  }, [mode, task, dexPair]);

  const onSubmit = useCallback(
    ({
      condition_type,
      direction,
      duration,
      max_pause_between_groups,
      max_pause_between_series,
      max_serie_duration,
      max_transactions_in_group,
      max_volume,
      min_pause_between_groups,
      min_pause_between_series,
      min_serie_duration,
      min_transactions_in_group,
      min_volume,
      repeatability,
      slippage_percent,
      start_at,
      threshold_percentage,
    }: IForm) => {
      submit({
        condition: {
          type: condition_type,
          condition_price_change:
            condition_type === 'price_change'
              ? {
                  direction: direction,
                  duration: msToDuration(duration ?? 0),
                  threshold_percentage: Number(threshold_percentage),
                }
              : undefined,
          condition_start_at:
            condition_type === 'start_at'
              ? {
                  start_at: dayjs(start_at).toISOString(),
                }
              : undefined,
        },
        repeatability: isNaN(Number(repeatability)) ? undefined : Number(repeatability),
        slippage_percent: slippage_percent,
        max_volume: parseUnits(max_volume, dexPair.token_base.decimals).toString(),
        min_volume: parseUnits(min_volume, dexPair.token_base.decimals).toString(),
        max_pause_between_groups: msToDuration(max_pause_between_groups),
        min_pause_between_groups: msToDuration(min_pause_between_groups),
        max_pause_between_series: msToDuration(max_pause_between_series),
        min_pause_between_series: msToDuration(min_pause_between_series),
        max_series_duration: msToDuration(max_serie_duration),
        min_series_duration: msToDuration(min_serie_duration),
        max_transactions_in_group: Number(max_transactions_in_group),
        min_transactions_in_group: Number(min_transactions_in_group),
      } as IDexWTBotVolumeStrategyTask);
      onClose();
    },
    [onClose, submit, dexPair],
  );

  const { handleSubmit, setFieldValue, values, errors, touched }: FormikProps<IForm> =
    useFormik<IForm>({
      initialValues,
      onSubmit: onSubmit,
      validationSchema,
    });

  const handleSetFieldValue = useCallback(
    (field: keyof IForm, value: any) => {
      setEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  return {
    edited,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    errors,
    touched,
  };
};
