import { useState, useCallback, useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ApiOrders } from 'api';
import { Bus } from 'tools';
import { useScrollPagination } from 'hooks';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { STOP_RELOAD_PREFIX, CEX_ORDERS_RELOAD } from 'constants/reload';
import { ICexOrder, ICexOrderAgregatedTrades } from 'types/orders';
import { groupBy } from 'lodash';

const useRecords = () => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const [records, setRecords] = useState<ICexOrder[] | undefined>(undefined);

  const [agregatedRecords, setAgregatedRecords] = useState<ICexOrderAgregatedTrades[] | undefined>(
    undefined,
  );

  const handleAgregateRecords = useCallback((rawRecords: ICexOrder[] | undefined) => {
    if (!rawRecords) return setAgregatedRecords(undefined);

    const _agregatedRecords = rawRecords.map(el => {
      const transfersRaw = groupBy(el.trades, 'cex_trade_id');

      const transfers = Object.values(transfersRaw)
        .filter(el => el.length !== 0)
        .map(el => ({
          tokens: el,
          cex_trade_id: el[0].cex_trade_id,
          created_at: el[0].created_at,
        }));

      return {
        ...el,
        transfers: transfers,
      };
    });

    setAgregatedRecords(_agregatedRecords);
  }, []);

  useEffect(() => {
    handleAgregateRecords(records);
  }, [handleAgregateRecords, records]);

  const getRecords = useCallback(
    async ({ limit, lastSeenId }: { limit: number; lastSeenId: number }) => {
      try {
        const { errorMessage, isSuccess, data } = await ApiOrders.getOrders({
          limit,
          lastSeenId,
          pair_ids: cexPair ? [cexPair.id] : undefined,
        });

        if (!isSuccess) {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        } else if (data) {
          return {
            has_more: data.has_next,
            records: data.items,
          };
        }
      } catch (error) {
        console.log(error);
      }

      return undefined;
    },
    [cexPair, dispatch],
  );

  const {
    hasMore,
    loading,
    records: paginationRecords,
    loadMore,
  } = useScrollPagination({
    limit: 40,
    getRecords,
  });

  const handleLoadMore = useCallback(async () => {
    const newRecordsSlice = await loadMore();

    if (newRecordsSlice) {
      setRecords(v => (v ? [...v].concat([...newRecordsSlice]) : newRecordsSlice));
    }
  }, [loadMore]);

  useEffect(() => {
    if (!records && !!paginationRecords && paginationRecords.length !== 0) {
      setRecords(paginationRecords);
    }
  }, [paginationRecords, records]);

  const updateVisibleRecords = useCallback(async () => {
    try {
      const startTime = Date.now();

      const { isSuccess, data, errorMessage } = await ApiOrders.getOrders({
        lastSeenId: 0,
        limit: 40,
        pair_ids: cexPair ? [cexPair.id] : undefined,
      });

      const endTime = Date.now();

      if (isSuccess) {
        const newRecords = records ? [...records] : [];

        for (let i = 0; i < data.items.length; i++) {
          const newRecord = data.items[i];

          const newExistRecordIndex = newRecords.findIndex(el => el.id === newRecord.id);

          if (newExistRecordIndex < 0) {
            newRecords.unshift(newRecord);
          } else {
            newRecords[newExistRecordIndex] = newRecord;
          }
        }

        setRecords(newRecords);

        if (endTime - startTime >= 1500) {
          Bus.emit(STOP_RELOAD_PREFIX + CEX_ORDERS_RELOAD);
        } else {
          setTimeout(() => {
            Bus.emit(STOP_RELOAD_PREFIX + CEX_ORDERS_RELOAD);
          }, 1500 - (endTime - startTime));
        }
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        Bus.emit(STOP_RELOAD_PREFIX + CEX_ORDERS_RELOAD);
      }
    } catch (error) {
      console.log(error);
      Bus.emit(STOP_RELOAD_PREFIX + CEX_ORDERS_RELOAD);
    }
  }, [cexPair, records, dispatch]);

  useEffect(() => {
    Bus.on(CEX_ORDERS_RELOAD, updateVisibleRecords);

    return () => {
      Bus.off(CEX_ORDERS_RELOAD, updateVisibleRecords);
    };
  }, [updateVisibleRecords]);

  return {
    records: agregatedRecords,
    hasMore,
    loading,
    handleLoadMore,
  };
};

export { useRecords };
