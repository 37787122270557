import { IDexBalanceBotTask } from 'types/bots';

interface ReverseSwapProps {
  original: IDexBalanceBotTask;
}

export const ReverseSwap: React.FC<ReverseSwapProps> = ({ original }) => {
  const { add_holder_options } = original;
  return <div>{add_holder_options?.enabled ? 'Enabled' : 'Disabled'}</div>;
};
