import React, { useContext, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { DepositWithdrawContext } from 'context/DepositWithdrawContext/DepositWithdrawContext';

import './style.scss';

const Warning: React.FC = () => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const { type, innerWallets, selectedToken } = useContext(DepositWithdrawContext);

  const warningExists = useMemo(
    () =>
      type === 'withdraw' &&
      pair &&
      !!innerWallets.get.find(el => !!el.max_amount) &&
      selectedToken.get.id === pair.token_fee.id,
    [type, innerWallets.get, selectedToken.get, pair],
  );

  if (!warningExists) return null;

  return (
    <div className="mm-withdraw-modal-warning">
      <span className="warning-title">Warning!</span>
      <span className="warning-text">Withdrawal amount will be less due to transaction fee</span>
    </div>
  );
};

export default Warning;
