import { IChartRange } from 'types/charts';

export const chartVolumeBarColor = '#adb5bd';
export const internalVolumeBarColor = '#940000';

export const CHARTS_COLORS = [
  '#FFB800',
  '#9152FF',
  '#03A356',
  '#1E008D',
  '#5932EA',
  '#FFDE89',
  '#0047FF',
  '#00A3FF',
  '#A2D2FF',
  '#02FB83',
  '#A7FFD5',
  '#DDCDFC',
  '#2B1E59',
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
];

export const CHART_RANGES: { label: IChartRange; milliseconds: number }[] = [
  { label: '1D', milliseconds: 24 * 60 * 60 * 1000 },
  { label: '7D', milliseconds: 7 * 24 * 60 * 60 * 1000 },
  { label: '1M', milliseconds: 30 * 24 * 60 * 60 * 1000 },
  { label: '3M', milliseconds: 3 * 30 * 24 * 60 * 60 * 1000 },
  { label: '1Y', milliseconds: 12 * 30 * 24 * 60 * 60 * 1000 },
  { label: 'ALL', milliseconds: Date.now() },
];
