import React, { useCallback, useContext, useState } from 'react';
import { TableAction, TableActionIcon } from 'ui';

import { PlusIcon } from 'assets/icons';
import { HolderTaskBalanceModal } from 'modals/AddHolderModal/HolderTaskBalanceModal';
import { ConnectWalletsToBoostHoldersModal } from 'modals/ConnectWalletsToBoostHoldersModal';
import { PairBalanceBotContext } from 'context/PairBalanceBotContext';
import { IDexBalanceBotTask } from 'types/bots';
import { normalizeBigNumber } from 'utils/formulas';
import { durationToMs } from 'utils/duration';

import './addHolderBalanceToolbar.scss';

const HolderBalanceTaskToolbar: React.FC = () => {
  const [isOpenCreateAddTaskModal, setIsOpenCreateAddTaskModal] = useState(false);
  const [activeWalletsStep, setActiveWalletsStep] = useState<number>(0);
  const [newlyCreatedHolderTask, setNewlyCreatedHolderTask] = useState<any>(null);
  const { handleLoadRecords } = useContext(PairBalanceBotContext);

  const handelOpenConnectWalletsToNewTaskModal = useCallback((dataTaskById: IDexBalanceBotTask) => {
    setNewlyCreatedHolderTask({
      ...dataTaskById.add_holder_options,
      id: dataTaskById.id,
      min_amount: normalizeBigNumber(
        dataTaskById?.add_holder_options?.min_amount || 0,
        18,
        6,
        false,
      ),
      max_amount: normalizeBigNumber(
        dataTaskById?.add_holder_options?.max_amount || 0,
        18,
        6,
        false,
      ),
      min_pause: durationToMs(dataTaskById.add_holder_options?.min_pause),
      max_pause: durationToMs(dataTaskById.add_holder_options?.max_pause),

      buyback_enabled: dataTaskById.add_holder_options?.buyback_enabled,
      buyback_min_pause: durationToMs(dataTaskById.add_holder_options?.buyback_options?.min_pause),
      buyback_max_pause: durationToMs(dataTaskById.add_holder_options?.buyback_options?.max_pause),
      buyback_min_swaps: dataTaskById.add_holder_options?.buyback_options?.min_swaps,
      buyback_max_swaps: dataTaskById.add_holder_options?.buyback_options?.max_swaps,
    });
    setActiveWalletsStep(1);
  }, []);

  const handleCloseTaskModal = useCallback(
    (dataTaskById: IDexBalanceBotTask | undefined) => {
      setIsOpenCreateAddTaskModal(false);
      if (dataTaskById) {
        handelOpenConnectWalletsToNewTaskModal(dataTaskById);
      }
    },
    [handelOpenConnectWalletsToNewTaskModal],
  );

  return (
    <div className="add-holder-toolbar-header">
      <div className="add-holder-toolbar-header__tab-title">All tasks</div>
      <TableAction
        iconLeft={<TableActionIcon icon={PlusIcon} />}
        text={'Create new task'}
        onClick={() => setIsOpenCreateAddTaskModal(true)}
      />
      {isOpenCreateAddTaskModal && (
        <HolderTaskBalanceModal mode="create" onClose={handleCloseTaskModal} />
      )}

      {activeWalletsStep === 1 && (
        <ConnectWalletsToBoostHoldersModal
          walletType="source"
          not_connected="true"
          taskType="add_holder"
          onClose={() => {
            setActiveWalletsStep(0);
          }}
          taskId={newlyCreatedHolderTask.id || 0}
          currentTaskValue={newlyCreatedHolderTask}
          setActiveWalletsStep={setActiveWalletsStep}
          activeWalletsStep={activeWalletsStep}
          includeSteps
        />
      )}

      {activeWalletsStep === 2 && (
        <ConnectWalletsToBoostHoldersModal
          walletType="destination"
          not_connected="true"
          taskType="add_holder"
          onClose={() => {
            handleLoadRecords();
            setActiveWalletsStep(0);
          }}
          taskId={newlyCreatedHolderTask.id || 0}
          currentTaskValue={newlyCreatedHolderTask}
          activeWalletsStep={activeWalletsStep}
          setActiveWalletsStep={setActiveWalletsStep}
          includeSteps
        />
      )}
    </div>
  );
};

export { HolderBalanceTaskToolbar };
