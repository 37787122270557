import React, { useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';
import { parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';

import {
  StatisticTable,
  StatisticHeader,
  StatisticCheckboxHeader,
  StatisticCell,
  StatisticTotalCell,
} from 'common/statistic';

import { formatFiat, formatNatural, formatToken } from 'utils/formats';
import { IStatisticToken, IStatisticTransaction } from 'api/apiStatistic/models';
import { ETransactionAction } from 'api/apiTransactions/models';
import {
  addBignumbers,
  divideBignumbers,
  multiplyBignumbers,
  subtractBignumbers,
} from 'utils/formulas';
import { DEXES_FEES } from 'api/apiDictionary/models/IDex';
import { IDexName } from 'types';
import { IChartRange } from 'types/charts';

import './style.scss';
import { STATISTIC_RANGES } from 'constants/tables';

interface IGeneralTableProps {
  statisticTokens: IStatisticToken[];
  statisticTransactions: IStatisticTransaction[];
  firstTradeTime: string | undefined;
  endDate: number | undefined;
  startDate: number | undefined;
  selectedRange: IChartRange | undefined;
}

const GeneralTable: React.FC<IGeneralTableProps> = ({
  statisticTokens,
  statisticTransactions,
  firstTradeTime,
  endDate,
  startDate,
  selectedRange,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const [includeActionsDepWith, setIncludeActionsDepWith] = useState<boolean>(false);

  const innerStatisticTokens = useMemo(() => {
    return statisticTokens.map(el => {
      const initialBalance = BigNumber.from(
        selectedRange === 'ALL' ? el.initial_balance ?? '0' : el.start_balance,
      );
      const initialBalanceUsd = BigNumber.from(
        selectedRange === 'ALL' ? el.initial_balance_usd ?? '0' : el.start_balance_usd,
      );

      let depositedAmount = BigNumber.from(0);
      let depositedAmountUsd = BigNumber.from(0);

      const actionDeposit = el.volumes.find(el => el.action === ETransactionAction.ActionDeposit);
      if (actionDeposit) {
        depositedAmount = BigNumber.from(actionDeposit.amount);
        depositedAmountUsd = BigNumber.from(actionDeposit.amount_usd);
      }

      let withdrawalAmount = BigNumber.from(0);
      let withdrawalAmountUsd = BigNumber.from(0);

      const actionWithdrawal = el.volumes.find(
        el => el.action === ETransactionAction.ActionWithdrawal,
      );
      if (actionWithdrawal) {
        withdrawalAmount = BigNumber.from(actionWithdrawal.amount);
        withdrawalAmountUsd = BigNumber.from(actionWithdrawal.amount_usd);
      }

      const finishBalance = BigNumber.from(el.finish_balance);
      const finishBalanceUsd = BigNumber.from(el.finish_balance_usd);

      return {
        tokenSymbol: el.token.symbol,
        decimals: el.token.decimals,
        initialBalance,
        initialBalanceUsd,
        finishBalance,
        finishBalanceUsd,
        depositedAmount,
        depositedAmountUsd,
        withdrawalAmount,
        withdrawalAmountUsd,
      };
    });
  }, [statisticTokens, selectedRange]);

  const PNLS = useMemo(() => {
    return innerStatisticTokens.map(el => {
      const balancesDiff = subtractBignumbers(
        [el.finishBalance, el.decimals],
        [selectedRange === 'ALL' ? BigNumber.from('0') : el.initialBalance, el.decimals],
      );
      const balancesDiffUsd = subtractBignumbers(
        [el.finishBalanceUsd, 6],
        [selectedRange === 'ALL' ? BigNumber.from('0') : el.initialBalanceUsd, 6],
      );

      if (includeActionsDepWith) {
        return {
          pnlTokens: balancesDiff,
          pnlUsd: balancesDiffUsd,
        };
      } else {
        const depositInfluence = subtractBignumbers(
          [el.depositedAmount, el.decimals],
          [el.withdrawalAmount, el.decimals],
        );
        const depositInfluenceUsd = subtractBignumbers(
          [el.depositedAmountUsd, 6],
          [el.withdrawalAmountUsd, 6],
        );

        const pnlTokens = subtractBignumbers([balancesDiff, 18], [depositInfluence, 18]);
        const pnlUsd = subtractBignumbers([balancesDiffUsd, 18], [depositInfluenceUsd, 18]);

        return {
          pnlTokens,
          pnlUsd,
        };
      }
    });
  }, [innerStatisticTokens, includeActionsDepWith, selectedRange]);

  const ROIS = useMemo(() => {
    return innerStatisticTokens.map((el, index) => {
      const { pnlTokens, pnlUsd } = PNLS[index];

      return {
        roiTokens: el.initialBalance.gt(BigNumber.from(0))
          ? multiplyBignumbers(
              [divideBignumbers([pnlTokens, 18], [el.initialBalance, el.decimals]), 18],
              [parseUnits('100', 18), 18],
            )
          : BigNumber.from(0),
        roiUsd: el.initialBalanceUsd.gt(BigNumber.from(0))
          ? multiplyBignumbers(
              [divideBignumbers([pnlUsd, 18], [el.initialBalanceUsd, 6]), 18],
              [parseUnits('100', 18), 18],
            )
          : BigNumber.from(0),
      };
    });
  }, [innerStatisticTokens, PNLS]);

  const TOTALS = useMemo(() => {
    const initialBalanceTotal = innerStatisticTokens.reduce((acc, val) => {
      return addBignumbers([acc, 18], [val.initialBalanceUsd, 6]);
    }, BigNumber.from(0));

    const depositedAmountsTotal = innerStatisticTokens.reduce((acc, val) => {
      return addBignumbers([acc, 18], [val.depositedAmountUsd, 6]);
    }, BigNumber.from(0));

    const withdrawalAmountsTotal = innerStatisticTokens.reduce((acc, val) => {
      return addBignumbers([acc, 18], [val.withdrawalAmountUsd, 6]);
    }, BigNumber.from(0));

    const finishBalanceTotal = innerStatisticTokens.reduce((acc, val) => {
      return addBignumbers([acc, 18], [val.finishBalanceUsd, 6]);
    }, BigNumber.from(0));

    const pnlTotal = PNLS.reduce((acc, val) => {
      return addBignumbers([acc, 18], [val.pnlUsd, 18]);
    }, BigNumber.from(0));

    const roiTotal = initialBalanceTotal.gt(BigNumber.from(0))
      ? multiplyBignumbers(
          [divideBignumbers([pnlTotal, 18], [initialBalanceTotal, 18]), 18],
          [parseUnits('100', 18), 18],
        )
      : BigNumber.from(0);

    return {
      initialBalanceTotal,
      depositedAmountsTotal,
      withdrawalAmountsTotal,
      finishBalanceTotal,
      pnlTotal,
      roiTotal,
    };
  }, [innerStatisticTokens, PNLS]);

  const intermediateTransactionTypes = useMemo(
    () => [ETransactionAction.ActionDeposit, ETransactionAction.ActionWithdrawal],
    [],
  );

  const totalVolume = useMemo(() => {
    const baseToken = statisticTokens.find(el => el.token.address === pair?.token_base.address);

    if (!baseToken)
      return {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      };

    return baseToken.volumes.reduce(
      (acc, val) => {
        if (intermediateTransactionTypes.includes(val.action)) return acc;

        return {
          volumeTokens: addBignumbers(
            [acc.volumeTokens, 18],
            [BigNumber.from(val.amount), baseToken.token.decimals],
          ),
          volumeUsd: addBignumbers([acc.volumeUsd, 18], [BigNumber.from(val.amount_usd), 6]),
        };
      },
      { volumeTokens: BigNumber.from(0), volumeUsd: BigNumber.from(0) },
    );
  }, [statisticTokens, intermediateTransactionTypes, pair]);

  const avgDailyVolume = useMemo(() => {
    if (!firstTradeTime)
      return {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      };

    let daysCount = '0';
    if (endDate && startDate) {
      const startTime = Math.max(Math.floor(dayjs(firstTradeTime).valueOf()), startDate);

      const diffInMilliseconds = endDate - startTime;
      daysCount = (diffInMilliseconds / (24 * 60 * 60 * 1000)).toFixed(4);
    } else if (selectedRange) {
      const selectedRangeMilliseconds = STATISTIC_RANGES.find(
        el => el.label === selectedRange,
      )!.milliseconds;
      const rangeStartTime = Math.floor(Date.now() - selectedRangeMilliseconds);

      const startTime = Math.max(Math.floor(dayjs(firstTradeTime).valueOf()), rangeStartTime);

      const diffInMilliseconds = dayjs().valueOf() - startTime;
      daysCount = (diffInMilliseconds / (24 * 60 * 60 * 1000)).toFixed(4);
    }

    if (Number(daysCount) === 0)
      return {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      };

    return {
      volumeTokens: divideBignumbers(
        [totalVolume.volumeTokens, 18],
        [parseUnits(daysCount, 18), 18],
      ),
      volumeUsd: divideBignumbers([totalVolume.volumeUsd, 18], [parseUnits(daysCount, 18), 18]),
    };
  }, [totalVolume, firstTradeTime, startDate, endDate, selectedRange]);

  const totalTransactions = useMemo(() => {
    return statisticTransactions.reduce(
      (acc, val) => {
        if (intermediateTransactionTypes.includes(val.action)) return acc;

        return {
          total: acc.total + val.total,
          failed: acc.failed + val.failed,
        };
      },
      { total: 0, failed: 0 },
    );
  }, [statisticTransactions, intermediateTransactionTypes]);

  const feeSpent = useMemo(() => {
    const feeToken = statisticTokens.find(el => el.token.address === pair?.token_fee.address);

    if (!feeToken || !feeToken.spent_fees)
      return {
        feeTokens: BigNumber.from(0),
        feeUsd: BigNumber.from(0),
      };

    return feeToken.spent_fees
      .filter(el => !intermediateTransactionTypes.includes(el.action))
      .reduce(
        (acc, val) => {
          return {
            feeTokens: addBignumbers(
              [BigNumber.from(val.amount), feeToken.token.decimals],
              [acc.feeTokens, 18],
            ),
            feeUsd: addBignumbers([BigNumber.from(val.amount_usd), 6], [acc.feeUsd, 18]),
          };
        },
        {
          feeTokens: BigNumber.from(0),
          feeUsd: BigNumber.from(0),
        },
      );
  }, [statisticTokens, pair, intermediateTransactionTypes]);

  const dexFees = useMemo(() => {
    const dexPercentage = ((DEXES_FEES[pair?.dex as IDexName]?.fee ?? 0) / 100).toString();

    const feeTokens = multiplyBignumbers(
      [totalVolume.volumeTokens, 18],
      [parseUnits(dexPercentage, 18), 18],
    );

    return {
      feeTokens,
      feeUsd: multiplyBignumbers([totalVolume.volumeUsd, 18], [parseUnits(dexPercentage, 18), 18]),
    };
  }, [totalVolume, pair]);

  const totalFees = useMemo(() => {
    return {
      feeUsd: addBignumbers([feeSpent.feeUsd, 18], [dexFees.feeUsd, 18]),
    };
  }, [feeSpent, dexFees]);

  return (
    <div className="mm-statistic-general-tables">
      <StatisticTable>
        <colgroup>
          <col span={6} style={{ width: '16.66%' }} />
        </colgroup>
        <thead>
          <tr>
            <StatisticHeader
              title={selectedRange === 'ALL' ? 'Initial balance' : 'Start balance'}
            />
            <StatisticHeader title={'Deposits'} />
            <StatisticHeader title={'Withdrawals'} />
            <StatisticHeader title={'Balance'} />
            <StatisticCheckboxHeader
              title={'PnL'}
              checked={includeActionsDepWith}
              onChange={() => setIncludeActionsDepWith(v => !v)}
              checkboxCaption="Deposits+Withdrawals"
            />
            <StatisticHeader title={'ROI'} />
          </tr>
        </thead>
        <tbody>
          {innerStatisticTokens.map((el, index) => {
            const initialBalance = formatToken(el.initialBalance, el.decimals);
            const initialBalanceUsd = formatFiat(el.initialBalanceUsd, 6);

            const depositedAmount = formatToken(el.depositedAmount, el.decimals);
            const depositedAmountUsd = formatFiat(el.depositedAmountUsd, 6);

            const withdrawalAmount = formatToken(el.withdrawalAmount, el.decimals);
            const withdrawalAmountUsd = formatFiat(el.withdrawalAmountUsd, 6);

            const finishBalance = formatToken(el.finishBalance, el.decimals);
            const finishBalanceUsd = formatFiat(el.finishBalanceUsd, 6);

            const pnlTokens = formatToken(PNLS[index].pnlTokens, 18);
            const pnlUsd = formatFiat(PNLS[index].pnlUsd, 18);

            const roiTokens = formatFiat(ROIS[index].roiTokens, 18, '', { zeroWithoutMinus: true });
            const roiUsd = formatFiat(ROIS[index].roiUsd, 18, '', { zeroWithoutMinus: true });

            return (
              <tr key={index}>
                <StatisticCell
                  title={initialBalance + ' ' + el.tokenSymbol}
                  subtitle={`(${initialBalanceUsd})`}
                />
                <StatisticCell
                  title={depositedAmount + ' ' + el.tokenSymbol}
                  subtitle={`(${depositedAmountUsd})`}
                />
                <StatisticCell
                  title={withdrawalAmount + ' ' + el.tokenSymbol}
                  subtitle={`(${withdrawalAmountUsd})`}
                />
                <StatisticCell
                  title={finishBalance + ' ' + el.tokenSymbol}
                  subtitle={`(${finishBalanceUsd})`}
                />
                <StatisticCell title={pnlTokens + ' ' + el.tokenSymbol} subtitle={`(${pnlUsd})`} />
                <StatisticCell title={roiTokens + '%'} subtitle={`/ ${roiUsd}%`} />
              </tr>
            );
          })}
          <tr>
            <StatisticTotalCell title="Total" subtitle={formatFiat(TOTALS.initialBalanceTotal)} />
            <StatisticTotalCell title="Total" subtitle={formatFiat(TOTALS.depositedAmountsTotal)} />
            <StatisticTotalCell
              title="Total"
              subtitle={formatFiat(TOTALS.withdrawalAmountsTotal)}
            />
            <StatisticTotalCell title="Total" subtitle={formatFiat(TOTALS.finishBalanceTotal)} />
            <StatisticTotalCell title="Total" subtitle={formatFiat(TOTALS.pnlTotal)} />
            <StatisticTotalCell
              title="Total"
              subtitle={formatFiat(TOTALS.roiTotal, 18, '', { zeroWithoutMinus: true }) + '%'}
            />
          </tr>
        </tbody>
      </StatisticTable>
      {totalVolume.volumeTokens.gt(BigNumber.from(0)) && (
        <StatisticTable>
          <colgroup>
            <col span={6} style={{ width: '16.66%' }} />
          </colgroup>
          <thead>
            <tr>
              <StatisticHeader title={'Volume'} />
              <StatisticHeader title={'Avg daily volume'} />
              <StatisticHeader title={'Transactions'} />
              <StatisticHeader title={'Fee spent'} />
              <StatisticHeader
                title={`Dex fee (${DEXES_FEES[pair?.dex as IDexName]?.fee ?? 0}%)`}
              />
              <StatisticHeader title={'Total fees'} />
            </tr>
          </thead>
          <tbody>
            <tr>
              <StatisticCell
                title={formatToken(totalVolume.volumeTokens) + ' ' + pair?.token_base.symbol}
                subtitle={`(${formatFiat(totalVolume.volumeUsd, 18)})`}
              />
              <StatisticCell
                title={formatToken(avgDailyVolume.volumeTokens) + ' ' + pair?.token_base.symbol}
                subtitle={`(${formatFiat(avgDailyVolume.volumeUsd, 18)})`}
              />
              <StatisticCell
                title={formatNatural(totalTransactions.total.toString())}
                subtitle={`(${formatNatural(totalTransactions.failed.toString())} failed)`}
              />
              <StatisticCell
                title={formatToken(feeSpent.feeTokens) + ' ' + pair?.token_fee.symbol}
                subtitle={`(${formatFiat(feeSpent.feeUsd, 18)})`}
              />
              <StatisticCell
                title={formatToken(dexFees.feeTokens) + ' ' + pair?.token_base.symbol}
                subtitle={`(${formatFiat(dexFees.feeUsd, 18)})`}
              />
              <StatisticCell title={formatFiat(totalFees.feeUsd, 18)} subtitle="" />
            </tr>
          </tbody>
        </StatisticTable>
      )}
    </div>
  );
};

export default GeneralTable;
