import React from 'react';
import { motion } from 'framer-motion';

import './tableToolbar.scss';

interface ITableToolbarProps {
  customFilters?: JSX.Element;
  customActions?: JSX.Element;
  additional?: JSX.Element;
}

const TableToolbar: React.FC<ITableToolbarProps> = ({
  customFilters,
  customActions: CustomActions,
  additional: Additional,
}) => {
  return (
    <motion.div
      animate={{ height: 'auto', overflow: 'hidden' }}
      className="mm-common-table-toolbar"
    >
      <div className="mm-common-table-toolbar__custom-filters">{customFilters}</div>
      <div className="mm-common-table-toolbar__custom-actions">
        {Additional && Additional}
        {CustomActions}
      </div>
    </motion.div>
  );
};

export { TableToolbar };
