import { useMemo } from 'react';
import { isNil } from 'lodash';

import {
  EFilterBooleanConnection,
  EFilterEntity,
  EFilterNumberConnection,
  IDexFilterValue,
} from 'types/filters/common';

import { FilterInputField, FilterSelectField } from '../fields';

const BOOLEAN_ITEMS = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];

interface IFilterValueProps {
  filter: IDexFilterValue;
  type: EFilterEntity | undefined;
  updateFilter: (id: string, filter?: IDexFilterValue) => void;
}

function FilterValue({ filter, type, updateFilter }: IFilterValueProps) {
  const content = useMemo(() => {
    switch (type) {
      case EFilterEntity.natural_number:
        return (
          <FilterInputField
            placeholder="Enter number"
            type="natural-number"
            value={(filter.value?.value as string) ?? ''}
            setValue={value => {
              //@ts-ignore
              updateFilter(filter.id, {
                ...filter,
                value: {
                  connection: filter.value?.connection as EFilterNumberConnection,
                  value,
                },
              });
            }}
          />
        );
      case EFilterEntity.number:
        return (
          <FilterInputField
            placeholder="Enter number"
            type="decimal-number"
            value={(filter.value?.value as string) ?? ''}
            setValue={value => {
              //@ts-ignore
              updateFilter(filter.id, {
                ...filter,
                value: {
                  connection: filter.value?.connection as EFilterNumberConnection,
                  value,
                },
              });
            }}
          />
        );
      case EFilterEntity.boolean:
        return (
          <FilterSelectField
            items={BOOLEAN_ITEMS}
            itemToString={v => v.label}
            placeholder="Select"
            selected={
              !isNil(filter.value?.value)
                ? BOOLEAN_ITEMS.find(el => el.value === filter.value?.value)
                : undefined
            }
            onSelectItem={({ value }) => {
              //@ts-ignore
              updateFilter(filter.id, {
                ...filter,
                value: {
                  connection: filter.value?.connection as EFilterBooleanConnection,
                  value,
                },
              });
            }}
          />
        );
      default:
        return null;
    }
  }, [type, filter, updateFilter]);

  return content === null ? null : <div className="value-container">{content}</div>;
}

export { FilterValue };
