import { AxiosRequestHeaders } from 'axios';

import { axios, AxiosResponse, axiosToken } from './axios';

export abstract class AApiBase {
  protected abstract baseUrl: string;
  protected abstract withHeader: boolean;

  protected post<T>(relevantUrl: string, body: unknown, config?: any): Promise<AxiosResponse<T>> {
    return (this.withHeader ? axiosToken : axios).post<T>(
      `${this.baseUrl}${relevantUrl}`,
      body,
      config,
    );
  }

  protected get<T>(
    relevantUrl: string,
    config?: { headers: AxiosRequestHeaders },
  ): Promise<AxiosResponse<T>> {
    return (this.withHeader ? axiosToken : axios).get<T>(`${this.baseUrl}${relevantUrl}`, {
      headers: config?.headers ?? undefined,
    });
  }

  protected put<T>(relevantUrl: string, body: unknown): Promise<AxiosResponse<T>> {
    return (this.withHeader ? axiosToken : axios).put<T>(`${this.baseUrl}${relevantUrl}`, body);
  }

  protected patch<T>(relevantUrl: string, body: unknown): Promise<AxiosResponse<T>> {
    return (this.withHeader ? axiosToken : axios).patch<T>(`${this.baseUrl}${relevantUrl}`, body);
  }

  protected delete<T>(
    relevantUrl: string,
    config?: { manualWithHeader?: boolean; data?: any },
  ): Promise<AxiosResponse<T>> {
    return (this.withHeader || (config && config.manualWithHeader) ? axiosToken : axios).delete<T>(
      `${this.baseUrl}${relevantUrl}`,
      config && config.data ? { data: config.data } : undefined,
    );
  }

  protected options<T>(relevantUrl: string, body: unknown): Promise<AxiosResponse<T>> {
    return (this.withHeader ? axiosToken : axios).options<T>(`${this.baseUrl}${relevantUrl}`);
  }
}
