import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IProjectShort } from 'types/project';
import { IGetByIdProject } from './models';
import { IUserRole } from '../apiUsers/models';

class ApiProjects extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiProjects | undefined;
  private static instance = (() => {
    if (ApiProjects.__instance === undefined) {
      ApiProjects.__instance = new ApiProjects();
    }
    return ApiProjects.__instance;
  })();

  public static getAllProjects = async ({
    limit = 1000,
    offset = 0,
    sort_by,
  }: {
    limit?: number;
    offset?: number;
    sort_by?: string;
  }): Promise<Response<{ items: IProjectShort[] }>> => {
    let mappedResponseOrError: Response<{ items: IProjectShort[] }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('offset', offset.toString());
    if (sort_by) {
      urlParams.append('sort_by', sort_by);
    }

    try {
      const response = await ApiProjects.instance.get<IBaseResponse<{ items: IProjectShort[] }>>(
        '/projects' + '?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ items: IProjectShort[] }>({ error });
    }

    return mappedResponseOrError;
  };

  public static getProjectById = async (id: number): Promise<Response<IGetByIdProject>> => {
    let mappedResponseOrError: Response<IGetByIdProject>;

    try {
      const response = await ApiProjects.instance.get<IBaseResponse<IGetByIdProject>>(
        `/projects/${id}`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IGetByIdProject>({ error });
    }

    return mappedResponseOrError;
  };

  public static deleteProject = async (id: number): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiProjects.instance.delete<IBaseResponse<any>>(`/projects/${id}`);

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static createProject = async ({
    name,
    users,
    notes,
  }: {
    name: string;
    users: { id: number; role: IUserRole }[];
    notes: string;
  }): Promise<Response<{ id: number }>> => {
    let mappedResponseOrError: Response<{ id: number }>;

    try {
      const response = await ApiProjects.instance.post<IBaseResponse<{ id: number }>>('/projects', {
        name,
        users,
        notes,
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ id: number }>({ error });
    }

    return mappedResponseOrError;
  };

  public static updateProject = async ({
    id,
    name,
    users,
    notes,
  }: {
    id: number;
    name: string;
    users: { id: number; role: IUserRole }[];
    notes: string;
  }): Promise<Response<{ id: number }>> => {
    let mappedResponseOrError: Response<{ id: number }>;

    try {
      const response = await ApiProjects.instance.patch<IBaseResponse<{ id: number }>>(
        `/projects/${id}`,
        {
          name,
          users,
          notes,
        },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ id: number }>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiProjects };
