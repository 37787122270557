import React, { useCallback, useContext, useState } from 'react';
import { useTypedDispatch } from 'store';

import { Button } from 'ui';
import { DexWTRegularTaskModal } from 'modals/DexWTRegularTaskModal';
import { IDexWTBotVolumeStrategyTask } from 'types/bots';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import './style.scss';

const RegularVolumesToolbar: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { currentConfig } = useContext(PairWashTradingContext);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);

  const onCreateNewTask = useCallback(
    (task: IDexWTBotVolumeStrategyTask) => {
      const newTasks = currentConfig.get?.config.volumes_strategy?.tasks ?? [];
      newTasks.push(task);

      currentConfig.set(
        currentConfig.get
          ? {
              ...currentConfig.get,
              config: {
                ...currentConfig.get.config,
                volumes_strategy: {
                  tasks: newTasks,
                },
              },
            }
          : undefined,
      );
      dispatch(
        setAlertState({
          type: 'success',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
          text: 'You have successfully created a new task!',
        }),
      );
    },
    [currentConfig, dispatch],
  );

  return (
    <div className="mm-wt-dex-bot-regular-volumes-toolbar">
      <h4>Regular volumes</h4>
      <Button color="transparent" onClick={() => setCreateModalOpened(true)}>
        + Create new task
      </Button>
      {createModalOpened && (
        <DexWTRegularTaskModal
          mode="create"
          onClose={() => setCreateModalOpened(false)}
          onSubmit={onCreateNewTask}
        />
      )}
    </div>
  );
};

export { RegularVolumesToolbar };
