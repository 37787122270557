import React from 'react';

import { TableWrapper, ActionPanel } from 'ui';
import { ColumnChartIcon } from 'assets/icons';
import { AccountsTable } from './AccountsTable/AccountsTable';

const AccountsList: React.FC = () => {
  return (
    <div className="wash-algorithm">
      <ActionPanel title="Accounts" icon={ColumnChartIcon} actions={<></>}>
        <TableWrapper>
          <div className="wash-algorithm__all-tasks">
            <AccountsTable />
          </div>
        </TableWrapper>
      </ActionPanel>
    </div>
  );
};

export { AccountsList };
