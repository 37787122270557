import { useState, useCallback, useMemo, Dispatch, SetStateAction } from 'react';

interface IUseScrollPaginationArgs<T> {
  limit: number;
  getRecords: ({
    offset,
    limit,
    lastSeenId,
  }: {
    offset: number;
    limit: number;
    lastSeenId: number;
  }) => Promise<{ records: T[]; has_more: boolean } | undefined>;
}

interface IUseScrollPaginationReturn<T> {
  loading: boolean;
  setRecords: Dispatch<SetStateAction<T[] | undefined>>;
  records: undefined | T[];
  hasMore: boolean;
  loadMore: () => Promise<T[] | undefined>;
  remount: () => void;
}

function useScrollPagination<T extends { id: number }>({
  limit,
  getRecords,
}: IUseScrollPaginationArgs<T>): IUseScrollPaginationReturn<T> {
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [lastSeenId, setLastSeenId] = useState<number>(0);
  const [records, setRecords] = useState<undefined | T[]>(undefined);
  const [hasMoreFromAPI, setHasMoreFromAPI] = useState<boolean>(true);

  const hasMore = useMemo(
    () => !loading && (records === undefined || hasMoreFromAPI),
    [records, hasMoreFromAPI, loading],
  );

  const handleLoadMore = useCallback(
    async (data?: {
      loading: boolean;
      offset: number;
      lastSeenId: number;
      records: undefined | T[];
      hasMoreFromAPI: boolean;
    }) => {
      if (loading) return undefined;

      const hasMoreFromData =
        data && Boolean(!data.loading) && (data.records === undefined || data.hasMoreFromAPI);
      const _records = data ? data.records : records;

      if (data ? !hasMoreFromData : !hasMore) return [];

      try {
        setLoading(true);

        const result = await getRecords({
          limit,
          offset: data ? data.offset : offset,
          lastSeenId: data ? data.lastSeenId : lastSeenId,
        });

        let newRecordsSlice: T[] = [];

        if (result) {
          newRecordsSlice = result.records ?? [];
          setHasMoreFromAPI(result.has_more ?? false);
          setRecords(_records ? [..._records].concat(newRecordsSlice) : [...newRecordsSlice]);
          setOffset(offset => offset + limit);
          setLastSeenId(
            newRecordsSlice?.length !== 0 ? newRecordsSlice[newRecordsSlice.length - 1].id : 0,
          );
          return newRecordsSlice;
        } else {
          setHasMoreFromAPI(false);
        }
      } catch (error) {
        console.log('useScrollPagination error: ', error);
      } finally {
        setLoading(false);
      }
    },
    [loading, hasMore, offset, lastSeenId, records, getRecords, limit],
  );

  const loadMore = useCallback(() => {
    return handleLoadMore();
  }, [handleLoadMore]);

  const remount = useCallback(() => {
    setLoading(false);
    setOffset(0);
    setLastSeenId(0);
    setHasMoreFromAPI(true);

    handleLoadMore({
      loading: false,
      offset: 0,
      lastSeenId: 0,
      records: undefined,
      hasMoreFromAPI: true,
    });
  }, [handleLoadMore]);

  return {
    loading,
    records,
    hasMore,
    loadMore,
    setRecords,
    remount,
  };
}

export { useScrollPagination };
