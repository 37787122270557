import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IAccountsResponse } from 'api/apiCexAccount/models/ICexAccount';

class ApiAccounts extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiAccounts | undefined;
  private static instance = (() => {
    if (ApiAccounts.__instance === undefined) {
      ApiAccounts.__instance = new ApiAccounts();
    }
    return ApiAccounts.__instance;
  })();

  public static getAccounts = async ({
    limit,
    lastSeenId,
  }: {
    limit: number;
    lastSeenId: number;
  }): Promise<Response<IAccountsResponse>> => {
    let mappedResponseOrError: Response<IAccountsResponse>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('last_seen_id', lastSeenId.toString());

    try {
      const response = await ApiAccounts.instance.get<IBaseResponse<IAccountsResponse>>(
        '/accounts?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IAccountsResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static addAccount = async ({
    cex,
    expire_at,
    credentials,
    notes,
    proxy,
  }: {
    cex: string;
    expire_at: string;
    credentials: { api_key: string; secret: string };
    notes: string;
    proxy: string;
  }) => {
    let mappedResponseOrError: Response<IAccountsResponse>;

    try {
      const response = await ApiAccounts.instance.post<IBaseResponse<IAccountsResponse>>(
        '/accounts',
        {
          cex,
          expire_at,
          credentials,
          notes,
          proxy,
        },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IAccountsResponse>({ error });
    }

    return mappedResponseOrError;
  };
  public static deleteAccounts = async ({ accountId }: { accountId: number }) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiAccounts.instance.delete<IBaseResponse<IAccountsResponse>>(
        `/accounts/${accountId}`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static updateAccount = async ({
    cex,
    expire_at,
    credentials,
    notes,
    proxy,
    accountId,
  }: {
    cex: string;
    expire_at: string;
    credentials: { api_key: string; secret: string };
    notes: string;
    proxy: string;
    accountId: number;
  }) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiAccounts.instance.patch<IBaseResponse<IAccountsResponse>>(
        `/accounts/${accountId}`,
        {
          cex,
          expire_at,
          credentials,
          notes,
          proxy,
        },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IAccountsResponse>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiAccounts };
