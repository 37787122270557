import React, { useCallback, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';

import { ArrowDropDown } from 'assets/icons';

import './style.scss';

interface IStatisticTabProps {
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  element?: React.ReactNode;
}

const StatisticTab: React.FC<IStatisticTabProps> = ({ title, children, subTitle, element }) => {
  const [isOpened, setIsOpened] = useState<boolean>(true);

  const handleToggleIsOpened = useCallback(() => {
    setIsOpened(v => !v);
  }, []);

  return (
    <div className="statistic-tab-container">
      <div className="toolbar" onClick={handleToggleIsOpened}>
        <div className="tab-title-conteiner">
          <span className="tab-title">{title}</span>
          {subTitle && <span className="tab-sub-title">{subTitle}</span>}
        </div>
        {element && element}
        <motion.div
          animate={{ rotate: isOpened ? 180 : 0 }}
          className={cn('toggler', { 'is-selected': !!isOpened })}
        >
          <ArrowDropDown />
        </motion.div>
      </div>
      <div className="separator" />
      <AnimatePresence>
        {isOpened && (
          <motion.div
            transition={{ easings: ['easeInOut'], duration: 0.3 }}
            initial={{ height: 0, paddingTop: 0 }}
            animate={{ height: 'auto', paddingTop: 12 }}
            exit={{ height: 0, opacity: 0, paddingTop: 0 }}
            className={cn('content')}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default StatisticTab;
