import React from 'react';

import './connectNewWalletsToPairForm.scss';

interface IConnectNewWalletsToPairFormProps {
  handleSubmit: () => void;
  children: React.ReactNode;
}

const ConnectNewWalletsToPairForm: React.FC<IConnectNewWalletsToPairFormProps> = ({
  handleSubmit,
  children,
}) => {
  return (
    <form
      className="mm-connect-new-wallets-to-pair-form"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {children}
    </form>
  );
};

export { ConnectNewWalletsToPairForm };
