import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { IBuySellBotTaskItemInner } from 'types/buy-sell-bot';
import { multiplyBignumbers } from 'utils/formulas';
import { formatFiat, formatToken } from 'utils/formats';

interface IQuoteAmountProps {
  task: IBuySellBotTaskItemInner;
}

export const QuoteAmount: React.FC<IQuoteAmountProps> = ({ task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const amount = useMemo(() => task.total.amount, [task]);

  const avgPrice = useMemo(() => task.total.avg_price, [task]);
  const avgPriceUsd = useMemo(() => task.total.avg_price_usd, [task]);

  const quoteAmount = useMemo(() => {
    const result = multiplyBignumbers(
      [BigNumber.from(amount), dexPair.token_base.decimals],
      [BigNumber.from(avgPrice), dexPair.token_quote.decimals],
    );

    return result;
  }, [dexPair, amount, avgPrice]);

  const quoteAmountUsd = useMemo(() => {
    const result = multiplyBignumbers(
      [BigNumber.from(amount), dexPair.token_base.decimals],
      [BigNumber.from(avgPriceUsd), 6],
    );

    return result;
  }, [dexPair, amount, avgPriceUsd]);

  return (
    <>
      {formatToken(quoteAmount, 18) + ' ' + dexPair.token_quote.symbol} /{' '}
      {formatFiat(quoteAmountUsd, 18)}
    </>
  );
};
