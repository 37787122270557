import { AxiosError } from 'axios';

import { CORS_ERROR_CODE } from 'constants/numbers';

import { Response } from './Response';

const getErrorCodeFromAxiosError = (error: AxiosError<{ code: number; error: string }>): number => {
  if (error?.response?.data?.code) {
    return Number(error.response.data.code);
  }

  return error?.status ? Number(error.status) : CORS_ERROR_CODE;
};

const getErrorMessageFromAxiosError = (
  error: AxiosError<{ code: number; error: string }>,
): string => {
  if (error?.response?.data?.error) {
    return error.response.data.error.toString();
  }

  return error?.message ?? 'Unknown Error';
};

export const mapError = function <T>({ error }: { error: unknown }): Response<T> {
  if (error instanceof AxiosError<{ code: number; error: string }>) {
    const errorCode = getErrorCodeFromAxiosError(error);
    const errorMessage = getErrorMessageFromAxiosError(error);

    return {
      isSuccess: false,
      errorCode,
      errorMessage: errorMessage,
    };
  }

  return { isSuccess: false, errorCode: 0, errorMessage: 'Unknown Error' };
};
