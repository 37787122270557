import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { TableAddressChip } from 'ui';
import { ITransfer } from 'api/apiTransactions/models';
import { ENetwork } from 'types';
import { ArrowToIcon, ArrowFromIcon } from 'assets/icons';

const ExtendedTr: React.FC<{ transfer: ITransfer; network: ENetwork }> = memo(
  ({ transfer, network }) => {
    return (
      <motion.tr
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
        className="_is-extended-children"
      >
        <motion.td layout></motion.td>
        <motion.td layout></motion.td>
        <motion.td layout>
          <TableAddressChip
            address={transfer.wallet.address}
            addressType={'address'}
            network={network}
            format={'short'}
          />
        </motion.td>
        <motion.td layout></motion.td>
        <motion.td layout></motion.td>
        <motion.td layout>
          {!transfer.is_fee && (
            <>
              <div className="transfer-amount">
                <span data-tooltip-id={transfer.id + '-transfer-amount-usd'}>
                  {formatUnits(BigNumber.from(transfer.amount), transfer.token.decimals)}
                </span>
                <ReactTooltip
                  place="top"
                  id={transfer.id + '-transfer-amount-usd'}
                  className="transfer-amount__usd-tooltip"
                >
                  {'$ ' + formatUnits(BigNumber.from(transfer.amount_usd), transfer.token.decimals)}
                </ReactTooltip>
                {BigNumber.from(transfer.amount).gte(BigNumber.from('0')) && (
                  <ArrowToIcon color="#64E0A5" />
                )}
                {BigNumber.from(transfer.amount).lt(BigNumber.from('0')) && (
                  <ArrowFromIcon color="#CE4966" />
                )}
              </div>
            </>
          )}
        </motion.td>
        <motion.td layout>{transfer.token.symbol}</motion.td>
        <motion.td layout></motion.td>
        <motion.td layout style={{ textAlign: 'right' }}>
          {transfer.is_fee
            ? formatUnits(BigNumber.from(transfer.amount), transfer.token.decimals) +
              ' ' +
              transfer.token.symbol
            : ''}
        </motion.td>
      </motion.tr>
    );
  },
);

export { ExtendedTr };
