import { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { boolean, string, object } from 'yup';
import { useTypedDispatch } from 'store';

import { ApiBot } from 'api';
import { IDexPair } from 'types/pairs';
import { IAddPairArgs } from 'api/apiPairs/models';
import { IDexBotSettings, EDexBot } from 'types/bots';
import { setAlertState, dropAlertState } from 'store/slices/ui';

interface IUseBotSettingsModalProps {
  pair: (IAddPairArgs & IDexPair) | null;
  botSettings?: IDexBotSettings;
  onSave: () => void;
  onClose: () => void;
  bot: EDexBot;
}

export const useBotSettingsModal = ({
  pair,
  botSettings,
  onSave,
  onClose,
  bot,
}: IUseBotSettingsModalProps) => {
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [edited, setEdited] = useState<boolean>(false);

  const validationSchema = object({
    slipage: string()
      .required('"Slippage" field is required')
      .test(
        'slipage',
        '"Slippage" must pe percent value',
        (slipage: string | undefined) => !isNaN(Number(slipage)) && Number(slipage) <= 100,
      ),
    privateTx: boolean(),
    enableBot: boolean(),
  });

  const handleEditSettings = async ({
    slipage,
    privateTx,
    enableBot,
  }: {
    slipage: string;
    privateTx: boolean;
    enableBot: boolean;
  }) => {
    setLoading(true);

    try {
      const { isSuccess, errorMessage } = await ApiBot.saveDexBotConfig({
        pairId: pair?.id,
        bot: bot,
        body: {
          ...botSettings,
          is_enabled: enableBot,
          slippage_percent: slipage,
          send_private_transactions: privateTx,
        },
      });

      if (!isSuccess) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
        return;
      }

      if (isSuccess) {
        onClose();
        onSave();
        dispatch(
          setAlertState({
            type: 'success-img',
            text: 'Successfully changed bot settings!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      slipage: botSettings?.slipage ? botSettings.slipage : '0.3',
      privateTx: botSettings?.privateTx! ?? false,
      enableBot: botSettings?.enableBot! ?? false,
    },
    onSubmit: handleEditSettings,
    validationSchema,
  });

  const handleSetFieldValue = useCallback(
    (
      field: string,
      value: any,
      options: { triggerEdit?: boolean; shouldValidate?: boolean } = {
        triggerEdit: false,
        shouldValidate: undefined,
      },
    ) => {
      if (options.triggerEdit) {
        setEdited(true);
      }

      setFieldValue(field, value, options.shouldValidate);
    },
    [setFieldValue],
  );

  useEffect(() => {
    const handleSetFormError = () => {
      const keys = Object.keys(errors);

      for (const key of keys) {
        const keyWithType = key as keyof typeof errors;

        if (errors[keyWithType] && touched[keyWithType]) {
          setFormError(errors[keyWithType]);
          return;
        }
      }

      setFormError(undefined);
    };

    handleSetFormError();
  }, [errors, touched]);

  return {
    validationSchema,
    loading,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    formError,
    edited,
  };
};
