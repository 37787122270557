import { useMemo, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useTypedDispatch } from 'store';

import { ApiProjects, ApiUsers } from 'api';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import {
  addProject,
  deleteProject,
  setSelectedProjectInPage,
  updateProject,
} from 'store/slices/projects';
import { IUser } from './models';
import { useFormik } from 'formik';
import { IUserRole } from 'api/apiUsers/models';

interface IUseProjectModalArgs {
  title: string | undefined;
  onClose: () => void;
  onOpen: () => void;
  projectId?: number;
  users: { id: number }[];
  notes: string | undefined;
  type: 'create' | 'update';
}

const useProjectModal = ({
  title,
  onClose,
  onOpen,
  projectId,
  users: initialUsers,
  notes,
  type,
}: IUseProjectModalArgs) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState<undefined | IUser[]>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const initialValues = { title, users: [], notes };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        title: Yup.string()
          .required('Project name is required')
          .max(60, 'Project name is too long'),
        notes: Yup.string().max(128, 'Notes field is too long'),
      }),
    [],
  );
  const allUsers = users ? [...users] : [];

  const onSubmitForm = async ({
    title,
    users,
    notes,
  }: {
    title: string | undefined;
    users: IUser[];
    notes: string | undefined;
  }) => {
    if (!title) return;
    setLoading(true);

    try {
      type TRequest = typeof type extends 'create'
        ? typeof ApiProjects.createProject
        : typeof ApiProjects.updateProject;
      const request: TRequest =
        type === 'create' ? ApiProjects.createProject : ApiProjects.updateProject;

      const finalUsers = allUsers.map(user => {
        const selectedUser = users.find(selected => selected.id === user.id);
        return { id: user.id, role: selectedUser ? 'user' : ('none' as IUserRole) };
      });
      const { isSuccess, errorMessage, data } = await request({
        id: projectId ?? 0,
        name: title,
        users: finalUsers,
        notes: notes ?? '',
      });

      if (!isSuccess) {
        setLoading(false);
        onClose();
        dispatch(
          setAlertState({
            type: 'failed-img',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              onOpen();
              dispatch(dropAlertState());
            },
            text: errorMessage,
          }),
        );
        return;
      }

      if (isSuccess) {
        const innerProjectId = type === 'create' ? data.id : projectId ?? 0;
        const getProjectResponse = await ApiProjects.getProjectById(innerProjectId);

        setLoading(false);
        onClose();

        if (!getProjectResponse.isSuccess) {
          dispatch(
            setAlertState({
              type: 'failed-img',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                onOpen();
                dispatch(dropAlertState());
              },
              text: getProjectResponse.errorMessage || 'Unknown error',
            }),
          );
          return;
        }

        if (getProjectResponse.isSuccess && getProjectResponse.data) {
          if (type === 'create') {
            dispatch(
              addProject({
                created_at: getProjectResponse.data.created_at,
                id: getProjectResponse.data.id,
                name: getProjectResponse.data.name,
                updated_at: '',
                notes: getProjectResponse.data.notes ?? '',
                cexPairs: [],
                dexPairs: [],
              }),
            );
            navigate(`/project/${getProjectResponse.data.id}`);
          }

          if (type === 'update') {
            dispatch(
              updateProject({
                id: getProjectResponse.data.id,
                newProjectName: getProjectResponse.data.name,
                newProjectNotes: getProjectResponse.data.notes ?? '',
              }),
            );
            dispatch(
              setSelectedProjectInPage({
                name: getProjectResponse.data.name,
                notes: getProjectResponse.data.notes ?? '',
                users: getProjectResponse.data.users
                  ? getProjectResponse.data.users.map(user => ({ id: user.id, role: user.role }))
                  : [],
                id: getProjectResponse.data.id,
                created_at: getProjectResponse.data.created_at,
              }),
            );
          }

          const successText =
            type === 'create' ? `Created new project: ${title}` : `Update project - ${title}`;
          const successType = type === 'create' ? 'success-img' : 'success';

          dispatch(
            setAlertState({
              type: successType,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
              text: successText,
            }),
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteProject = useCallback(async () => {
    if (!projectId) return;

    try {
      setLoading(true);

      const { isSuccess, errorMessage } = await ApiProjects.deleteProject(projectId);

      setLoading(false);
      onClose();

      if (isSuccess) {
        navigate('/wallets');
        dispatch(deleteProject(projectId));
        dispatch(
          setAlertState({
            type: 'success',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
            text: `Deleted project: ${title}`,
          }),
        );
        return;
      }

      if (!isSuccess) {
        dispatch(
          setAlertState({
            type: 'failed',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
              onOpen();
            },
            text: errorMessage,
          }),
        );
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }, [projectId]);

  const { values, handleSubmit, touched, errors, setFieldValue } = useFormik<{
    title: string | undefined;
    users: IUser[];
    notes: string | undefined;
  }>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
  });

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const { isSuccess, data } = await ApiUsers.getAllUsers({ limit: 100, offset: 0 });
        if (isSuccess && data) {
          const removeAdminRoles = data.items.filter(user => user.role !== 'admin');

          setUsers(removeAdminRoles);

          if (initialUsers) {
            await setFieldValue(
              'users',
              [...removeAdminRoles].filter(
                user => !!initialUsers.find(iUser => iUser.id === user.id),
              ),
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllUsers();
  }, []);

  return {
    users,
    loading,
    initialValues,
    validationSchema,
    onSubmitForm,
    onDeleteProject,
    values,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
  };
};

export { useProjectModal };
