import React, { useCallback, useContext, useMemo } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { PairWalletsContext } from 'context/PairWalletsContext/PairWalletsContext';
import { useWithdrawalWhitelistStatus } from 'hooks';

import { CogSolidIcon, CogIcon } from 'assets/icons';

const WithdrawWhitelistButton: React.FC = () => {
  const {
    modals: { whitelistWithdrawOpened },
  } = useContext(PairWalletsContext);

  const whitelistStatus = useWithdrawalWhitelistStatus();

  const handleOpenWithdrawalWhitelistModal = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();

      whitelistWithdrawOpened.set(true);
    },
    []
  );

  const isPending = useMemo(
    () => whitelistStatus?.pendingCount !== undefined && whitelistStatus?.pendingCount !== 0,
    [whitelistStatus]
  );

  return (
    <motion.div
      className={cn(
        'ww-status-button',
        whitelistStatus?.status === 'pending' ? '_pending' : '_success'
      )}
      onClick={handleOpenWithdrawalWhitelistModal}
    >
      {isPending ? <CogSolidIcon color="white" /> : <CogIcon color="#5932EA" />}
      {isPending && <span className="pending-count">{whitelistStatus!.pendingCount}</span>}
    </motion.div>
  );
};

export { WithdrawWhitelistButton };
