import React from 'react';

import { ICexOrder } from 'types/orders';

interface IAccountProps {
  account: string;
}

const Account = React.memo(({ account }: IAccountProps) => {
  return (
    <div
      style={{
        textAlign: 'left',
        textOverflow: 'ellipsis',
        minWidth: 0,
        overflow: 'hidden',
      }}
    >
      <span>{account}</span>
    </div>
  );
});

const accountAccessor = (row: ICexOrder) => row.account.notes;

Account.displayName = 'Account';

export { Account, accountAccessor };
