import React, { useCallback } from 'react';

import { withFormatter } from 'tools/hoc/withFormatter/withFormatter';

import './textarea.scss';

interface ITextareaProps {
  value: string | undefined;
  placeholder: string;
  onChange: (value: string | undefined) => void;
  disabled?: boolean;
  id: string;
}

const Textarea: React.FC<ITextareaProps> = ({ value, placeholder, onChange, disabled = false, id }) => {
  const textareaRef = React.createRef<HTMLTextAreaElement>();

  const handleChange = useCallback((event: React.SyntheticEvent<HTMLTextAreaElement>): void => {
    const value = event.currentTarget.value;
    onChange(value && value.length > 0 ? value : undefined);
  }, []);

  return (
    <div className="mm-textarea-container">
      <div className="mm-textarea-container__content">
        <textarea
          className="mm-textarea-container__content__textarea"
          ref={textareaRef}
          id={id}
          value={value ?? ''}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const textareaWithFormatter = withFormatter<ITextareaProps>(Textarea);

export { textareaWithFormatter as Textarea };
