import { FC, useCallback, useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from 'ui';
import { CexWTRegularTaskModal } from 'modals/CexWTRegularTaskModal';
import { ICexWTRegularTask } from 'types/bots';

import './style.scss';

interface RegularRowInfoProps {
  onDeleteTask: () => void;
  task: ICexWTRegularTask;
  rowIdx: number;
}

export const RegularRowInfo: FC<RegularRowInfoProps> = ({ onDeleteTask, task, rowIdx }) => {
  const [createNewTaskModalOpened, setCreateNewTaskModalOpened] = useState(false);

  const handleEditTask = useCallback(() => {
    setCreateNewTaskModalOpened(true);
  }, []);

  return (
    <motion.div
      key={`extended-row-${rowIdx}`}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      className="mm-wash-algorithm-bot-row-extended-info"
    >
      <div className="line-separator" />
      <div className="sections">
        <span className="section-title">Settings</span>
        <div className="section w-[50%]">
          <div className="grid content-start gap-2">
            <div>
              <span className="thin">Min/Max delay: </span>
              <span className="bold">
                {task?.options.pause_between_groups_min} /{task.options.pause_between_groups_max}
              </span>
            </div>
            <div>
              <span className="thin">Takers != makers amount probability: </span>
              <span className="bold">{task?.options.not_equal_amount_probability * 100}%</span>
            </div>
          </div>
          <div className="grid gap-2">
            <div>
              <span className="thin">Round amount probability: </span>
              <span className="bold">{task.options.rounding_probability * 100}%</span>
            </div>
            <div>
              <span className="thin">Takers != makers amount percent: </span>
              <span className="bold">
                {task.options.not_equal_amount_percent_min * 100}% /{' '}
                {task.options.not_equal_amount_percent_max * 100}%
              </span>
            </div>
          </div>
          <div className="grid gap-2">
            <div>
              <span className="thin">Ignore percent: </span>
              <span className="bold">{task.options.ignore_volume_percent * 100}%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="line-separator" />
      <div className="extended-row__footer">
        <Button color="secondary" className="delete-task-button" onClick={onDeleteTask}>
          Delete
        </Button>
        <Button color="secondary" className="edit-task-button" onClick={handleEditTask}>
          Edit task
        </Button>
      </div>
      {createNewTaskModalOpened && (
        <CexWTRegularTaskModal
          mode="edit"
          task={task}
          onClose={() => setCreateNewTaskModalOpened(false)}
        />
      )}
    </motion.div>
  );
};
