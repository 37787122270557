import React, { useCallback, useState } from 'react';
import { useTypedDispatch } from 'store';

import { Modal, Button, ErrorText, ButtonLoading } from 'ui';
import { ConnectNewWalletsToPairForm } from 'ui/forms';
import { ApiPairs } from 'api';
import { IPairWallet } from 'api/apiPairs/models';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { EDexBot } from 'types/bots';
import { ConnectWalletToPairTable } from 'tables';

import './connectNewWalletsToPairModal.scss';

interface IConnectNewWalletsToPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
  pairId: number;
}

const ConnectNewWalletsToPairModal: React.FC<IConnectNewWalletsToPairModalProps> = ({
  onClose,
  onOpen,
  onSuccess,
  pairId,
}) => {
  const dispatch = useTypedDispatch();
  const [validateError, setValidateError] = useState<string | null>(null);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const [selectedRecords, setSelectedRecords] = useState<IPairWallet[]>([]);

  const handleConnectNewWallets = useCallback(async () => {
    if (selectedRecords.length === 0) {
      setValidateError('Select at least one wallet');
      return;
    }

    try {
      setRequestLoading(true);

      const { errorMessage, isSuccess } = await ApiPairs.addWalletsToPair({
        pairId,
        wallets: selectedRecords.map(selectedRow => ({
          id: Number(selectedRow.id),
          bots_ids: [
            EDexBot.anti_font_run,
            EDexBot.wash_trading,
            EDexBot.balance_bot,
            EDexBot.buy_sell_bot,
            EDexBot.liquidity_bot,
          ],
        })),
      });

      onClose();

      if (isSuccess) {
        dispatch(
          setAlertState({
            type: 'success',
            text: `You successfuly added ${selectedRecords.length} wallets to pair`,
            onClose: () => {
              dispatch(dropAlertState());
              onSuccess();
            },
            onSubmit: () => {
              dispatch(dropAlertState());
              onSuccess();
            },
          }),
        );
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => {
              dispatch(dropAlertState());
            },
            onSubmit: () => {
              dispatch(dropAlertState());
              onOpen();
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestLoading(false);
    }

    setValidateError(null);
  }, [pairId, selectedRecords, dispatch, onClose, onOpen, onSuccess]);

  return (
    <Modal onClose={onClose} title={`Connect new wallets: ${selectedRecords.length}`}>
      <ConnectNewWalletsToPairForm handleSubmit={handleConnectNewWallets}>
        <div className="connect-new-wallets-to-pair-modal-content">
          <ConnectWalletToPairTable setSelectedRecords={setSelectedRecords} />
        </div>
        <ErrorText>{validateError ?? undefined}</ErrorText>
        {!requestLoading && (
          <Button type="submit" disabled={selectedRecords.length === 0}>
            Connect
          </Button>
        )}
        {requestLoading && <ButtonLoading />}
      </ConnectNewWalletsToPairForm>
    </Modal>
  );
};

export { ConnectNewWalletsToPairModal };
