import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTypedDispatch, useTypedSelector } from 'store';

import { Button, ButtonLoading, Modal } from 'ui';
import { isTxAddress, shortenAddress } from 'utils';
import { readFromClipboard } from 'utils/clipboard';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { ApiPairs } from 'api';

import './manualTxsModal.scss';
import { TrashIcon } from 'assets/icons';

interface IManualTxsModalProps {
  onClose: () => void;
}

const ManualTxsModal: React.FC<IManualTxsModalProps> = ({ onClose }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const dispatch = useTypedDispatch();
  const [transactions, setTransactions] = useState<{ tx: string; id: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);

  const handlePasteTransactions = useCallback(async () => {
    try {
      const text = await readFromClipboard();

      if (text) {
        setEdited(true);

        if (text.includes(',')) {
          const newTransactions = text
            .replace('\r\n', '')
            .replace('\r', '')
            .replace('\n', '')
            .replace(/ /g, '')
            .split(',')
            .filter(el => isTxAddress(el))
            .map(el => ({ tx: el.replace(' ', ''), id: uuid() }));

          return setTransactions(v => [...v].concat(newTransactions));
        }

        if (!text.includes(',')) {
          const newTransactions = text
            .replace(' ', '')
            .split(' ')
            .filter(el => isTxAddress(el))
            .map(el => ({ tx: el, id: uuid() }));

          return setTransactions(v => [...v].concat(newTransactions));
        }

        if (!text.includes(' ')) {
          const newTransactions = text
            .replace(' ', '')
            .split(',')
            .filter(el => isTxAddress(el))
            .map(el => ({ tx: el, id: uuid() }));

          return setTransactions(v => [...v].concat(newTransactions));
        }

        if (isTxAddress(text)) {
          return setTransactions(v => [...v].concat([{ tx: text, id: uuid() }]));
        }
      } else {
        setEdited(false);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }, []);

  const handleDeleteTransaction = useCallback((id: string) => {
    setTransactions(v => [...v].filter(el => el.id !== id));
  }, []);

  const handleDeposit = useCallback(async () => {
    if (!pair) return;

    try {
      setLoading(true);
      const { errorMessage, isSuccess } = await ApiPairs.deposit({
        pairId: pair.id,
        transactionHashes: transactions.map(el => el.tx),
      });

      if (isSuccess) {
        onClose();
        dispatch(
          setAlertState({
            type: 'success-img',
            text: `Successfully activate deposit. Watch for pair transactions to see deposit status`,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage ?? 'Failed to deposit...',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Failed to deposit...',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, transactions]);

  return (
    <Modal onClose={onClose} title="Manual transactions" edited={edited}>
      <div className="mm-manual-txs-modal-container">
        {transactions.length === 0 && (
          <div className="no-transactions">
            <span>Add transactions from which you want to confirm the failed deposit</span>
          </div>
        )}
        {transactions.length !== 0 && (
          <div className="transactions-list scrollable">
            {transactions.map((el, index) => (
              <div className="transaction-item" key={el.id}>
                <div className="transaction-item__left">
                  <div className="index">#{index + 1}</div>
                  <span className="tx-address">{shortenAddress(el.tx)}</span>
                </div>
                <button
                  className="delete-transaction-item"
                  onClick={() => handleDeleteTransaction(el.id)}
                >
                  <TrashIcon color={'#5932EA'} />
                </button>
              </div>
            ))}
          </div>
        )}
        <button className="button-paste-txs" onClick={handlePasteTransactions}>
          + Paste transactions
        </button>
        {loading && <ButtonLoading />}
        {!loading && (
          <Button disabled={transactions.length === 0} onClick={handleDeposit}>
            Check deposit
          </Button>
        )}
      </div>
    </Modal>
  );
};

export { ManualTxsModal };
