import { useCallback, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';
import { flushSync } from 'react-dom';
import { v4 as uuid } from 'uuid';
import {
  useFloating,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingPortal,
  size,
} from '@floating-ui/react';

import { Bus } from 'tools';
import { useDexPairFilters } from 'store/slices/dex_pair_filters/hooks';
import { saveDexPairFilters } from 'store/slices/dex_pair_filters';
import { EExchange } from 'types';
import { EDexPairFilters } from 'types/filters';
import FiltersList from '../FiltersList';

import { FilterIcon } from 'assets/icons';
import './style.scss';

interface IFilterPopupProps<V> {
  exchange: V;
  //TODO for cex pairs
  type: V extends EExchange.dex ? EDexPairFilters : any;
}

function FiltersPopup<V extends EExchange>({ exchange, type }: IFilterPopupProps<V>) {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const id = useMemo(() => uuid(), []);

  const { isFilterSetted, clearFilters, saveFilters } = useDexPairFilters({
    dexPair: dexPair ?? undefined,
    type,
  });

  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  const { x, y, refs, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: 'end', padding: 5 }),
      shift({ padding: 5 }),
      size({
        apply({ availableHeight }) {
          flushSync(() => {
            setMaxHeight(availableHeight);
          });
        },
      }),
    ],
    placement: 'bottom-start',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const handleAddFilter = useCallback(() => {
    Bus.emit(`${id}-FILTERS-ADD`);
  }, [id]);

  const handleFilter = useCallback(() => {
    setIsOpen(false);
    saveFilters();
  }, [saveFilters]);

  const handleClearFilters = useCallback(() => {
    setIsOpen(false);
    clearFilters();
  }, [clearFilters]);

  return (
    <>
      <motion.div
        whileHover={{ color: 'rgb(86, 97, 123)' }}
        className={cn('mm-filter-popup-trigger-button', { is_setted: isFilterSetted })}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <FilterIcon />
        <span>Filters</span>
      </motion.div>
      {isOpen && (
        <FloatingPortal>
          <AnimatePresence>
            <motion.div
              key={`${id}-filters-popup`}
              className="mm-filters-popup-container"
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                maxHeight,
                maxWidth: 820,
                zIndex: 102,
              }}
              {...getFloatingProps()}
              transition={{ duration: 0.1 }}
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
            >
              <div className="filters-header">
                <span className="filters-title">Filters</span>
                <div className="filter-header-right">
                  <motion.button
                    whileHover={{ backgroundColor: '#f0f1f3' }}
                    className="clear-filters"
                    onClick={handleClearFilters}
                  >
                    Clear all
                  </motion.button>
                </div>
              </div>
              <FiltersList id={id} exchange={exchange} type={type} />
              <div className="filters-footer">
                <motion.button
                  whileHover={{ backgroundColor: '#f0f1f3' }}
                  className="filters-add-filter"
                  onClick={handleAddFilter}
                >
                  <span>+ Add filter</span>
                </motion.button>
                <motion.button
                  whileHover={{ backgroundColor: '#4620d0' }}
                  className="filters-start-filter"
                  onClick={handleFilter}
                >
                  <span>Filter</span>
                </motion.button>
              </div>
            </motion.div>
          </AnimatePresence>
        </FloatingPortal>
      )}
    </>
  );
}

export default FiltersPopup;
