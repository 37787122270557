import React from 'react';

import holoTopLeft from 'assets/images/login/holographic-left-top.png';
import holoTopRight from 'assets/images/login/holographic-right-top.png';
import holoBotttomLeft from 'assets/images/login/holographic-left-bottom.png';
import holoBotttomRight from 'assets/images/login/holographic-right-bottom.png';

import './authPageWrapper.scss';

interface IAuthPageWrapperProps {
  children: React.ReactNode;
}

const AuthPageWrapper: React.FC<IAuthPageWrapperProps> = ({ children }) => {
  return (
    <div className="mm-auth-page-wrapper">
      <img className="mm-auth-page-wrapper__holo-top-left" src={holoTopLeft} alt="" />
      <img className="mm-auth-page-wrapper__holo-top-right" src={holoTopRight} alt="" />
      <img className="mm-auth-page-wrapper__holo-bottom-left" src={holoBotttomLeft} alt="" />
      <img className="mm-auth-page-wrapper__holo-bottom-right" src={holoBotttomRight} alt="" />
      <section className="mm-auth-page-wrapper__content">
        <h1 className="mm-auth-page-wrapper__content__title">{'Welcome to XXXX Market Maker'}</h1>
        <div className="mm-auth-page-wrapper__content__inner">{children}</div>
      </section>
    </div>
  );
};

export { AuthPageWrapper };
