import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IStatisticToken, IStatisticTransaction } from './models';
import { IRange } from 'types/web3';
import { ICexStatisticToken, ICexOrder } from './models/IStatisticToken';

interface IStatisticResponse {
  first_trade_time: string;
  transactions: IStatisticTransaction[];
  tokens: IStatisticToken[];
}

export interface ICexStatisticResponse {
  first_trade_time?: string;
  orders?: ICexOrder[];
  tokens: ICexStatisticToken[];
}
class ApiStatistic extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiStatistic | undefined;
  private static instance = (() => {
    if (ApiStatistic.__instance === undefined) {
      ApiStatistic.__instance = new ApiStatistic();
    }
    return ApiStatistic.__instance;
  })();

  public static getStatistic = async ({
    projects_ids,
    pair_ids,
    token_ids,
    ranges,
  }: {
    projects_ids?: number[];
    pair_ids?: number[];
    token_ids?: number[];
    ranges: (IRange | string)[];
  }): Promise<Response<IStatisticResponse>> => {
    let mappedResponseOrError: Response<IStatisticResponse>;

    const urlParams = new URLSearchParams();
    if (projects_ids && projects_ids.length !== 0) {
      for (const projectId of projects_ids) {
        urlParams.append('project_ids', projectId.toString());
      }
    }

    if (pair_ids && pair_ids.length !== 0) {
      for (const pairId of pair_ids) {
        urlParams.append('pair_ids', pairId.toString());
      }
    }
    if (token_ids && token_ids.length !== 0) {
      for (const tokenId of token_ids) {
        urlParams.append('token_ids', tokenId.toString());
      }
    }

    for (const range of ranges) {
      urlParams.append('range', range.toString());
    }

    try {
      const response = await ApiStatistic.instance.get<IBaseResponse<IStatisticResponse>>(
        '/statistics' + '?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<IStatisticResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static getCexStatistic = async ({
    projectId,
    ranges,
  }: {
    projectId?: number;
    ranges: (IRange | string)[];
  }): Promise<Response<ICexStatisticResponse>> => {
    let mappedResponseOrError: Response<ICexStatisticResponse>;
    const urlParams = new URLSearchParams();

    for (const range of ranges) {
      urlParams.append('range', range.toString());
    }

    try {
      const response = await ApiStatistic.instance.get<IBaseResponse<ICexStatisticResponse>>(
        `/cex-pairs/${projectId}/statistics` + '?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<ICexStatisticResponse>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiStatistic };
