import React from 'react';

import { WalletsPageWrapper } from 'ui';
import {
  WalletsPanel,
  WalletsChartsContainer,
  WalletsChart,
  WalletsRoundChart,
} from 'common/wallets';

const Wallets: React.FC = () => {
  return (
    <WalletsPageWrapper>
      {/* <WalletsChartsContainer>
        <WalletsChart />
        <WalletsRoundChart />
      </WalletsChartsContainer> */}
      <WalletsPanel />
    </WalletsPageWrapper>
  );
};

export { Wallets };
