import { BigNumber } from '@ethersproject/bignumber';
import { IPairWallet, IDexToken } from 'api/apiPairs/models';

export const calculateWalletTokenBalance = (wallet?: IPairWallet, token?: IDexToken) => {
  try {
    if (!wallet || !wallet.tokens || !token) return BigNumber.from('0');

    const walletToken = wallet.tokens.find(walletToken => walletToken.token.id === token.id);

    if (!walletToken) return BigNumber.from('0');

    return BigNumber.from(walletToken.balance);
  } catch (error) {
    return BigNumber.from('0');
  }
};
