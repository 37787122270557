import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { useTypedDispatch, useTypedSelector } from 'store';

import { Spinner } from 'ui';
import { useScrollPagination } from 'hooks';
import { ICexNotConnectedAccounts } from 'api/apiCexAccount/models/ICexAccount';
import { ApiCexAccount } from 'api/apiCexAccount';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import { ApiCell, NotesCell, ProxyCell } from './columns';
import { ApiHead, NotesHead, ProxyHead } from './headers';
import { StaticTable, TBody } from '../../common';

import './style.scss';

interface IConnectAccountToPairTableProps {
  setSelectedRecords: (v: ICexNotConnectedAccounts[]) => void;
}

const ConnectAccountToPairTable: React.FC<IConnectAccountToPairTableProps> = ({
  setSelectedRecords,
}) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const dispatch = useTypedDispatch();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const pairId = useMemo(() => cexPair.id, [cexPair]);

  const getRecords = useCallback(
    async ({ lastSeenId, limit }: { lastSeenId: number; limit: number }) => {
      try {
        const { isSuccess, errorMessage, data } = await ApiCexAccount.getNotConnectedCexAccounts({
          limit,
          lastSeenId,
          cexId: pairId,
        });

        if (!isSuccess) {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          return undefined;
        } else {
          return {
            records: data.items,
            has_more: data.has_next,
          };
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [dispatch, pairId],
  );

  const { hasMore, loading, records, loadMore } = useScrollPagination({
    limit: 20,
    getRecords,
  });

  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});

  const columns = useMemo<ColumnDef<ICexNotConnectedAccounts>[]>(
    () => [
      {
        id: 'select',
        accessorFn: row => row,
        header: ({ table }) => <ApiHead table={table} />,
        cell: ({ row }) => {
          return <ApiCell row={row} />;
        },
      },
      {
        id: 'notes',
        accessorFn: row => row,
        header: () => <NotesHead />,
        cell: ({ row }) => {
          return <NotesCell row={row} />;
        },
      },
      {
        id: 'proxy',
        accessorFn: row => row,
        header: () => <ProxyHead />,
        cell: ({ row }) => {
          return <ProxyCell row={row} />;
        },
      },
    ],
    [],
  );

  const _selectedRecords = useMemo(() => {
    const selectedRows: ICexNotConnectedAccounts[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, records]);

  useEffect(() => {
    setSelectedRecords(_selectedRecords);
  }, [_selectedRecords, setSelectedRecords]);

  const table = useReactTable({
    data: useMemo(() => records ?? [], [records]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection: rowSelection,
    },
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      loadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      loadMore();
    }
  }, [rowVirtualizer.virtualItems, hasMore, loading, loadMore, rows.length]);

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  return (
    <>
      {!!loading && records === undefined && (
        <div className="mm-connect-cex-accounts-table-loading">
          <Spinner size="medium" />
        </div>
      )}
      {records === undefined && !loading && !hasMore && (
        <div className="mm-common-table-no-info">
          {'You get some error while loading accounts. Try to refresh the page'}
        </div>
      )}
      {records && records.length === 0 && <div className="mm-common-table-no-info">Not found</div>}
      {records && records.length !== 0 && (
        <div className="mm-connect-cex-accounts-table-container scrollable" ref={tableContainerRef}>
          <StaticTable className="mm-common-table">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <TBody paddingTop={paddingTop} paddingBottom={paddingBottom}>
              {virtualRows.map(virtualRow => {
                const row = rows[virtualRow.index];

                return (
                  <tr key={virtualRow.index}>
                    {row ? (
                      row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id} style={{ height: virtualRow.size }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })
                    ) : (
                      <td style={{ height: virtualRow.size }}>-</td>
                    )}
                  </tr>
                );
              })}
            </TBody>
          </StaticTable>
        </div>
      )}
    </>
  );
};

export { ConnectAccountToPairTable };
