import React from 'react';

import { IIconProps } from './types';

const PendingIcon: React.FC<IIconProps> = ({ className, onClick, color = '#EDA249' }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        fill={color}
      />
      <path
        d="M8 4.66406V7.9974H11.3333"
        stroke="#F7F7F7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { PendingIcon };
