import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ColumnDef, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { useTypedDispatch, useTypedSelector } from 'store';
import { motion } from 'framer-motion';
import { useWeb3React } from '@web3-react/core';

import { Modal, Spinner, Button, ButtonLoading, ChangePairNetwork } from 'ui';
import { AddWithdrawWhitelistModal } from 'modals';
import { ApiWithdrawWhitelist } from 'api';
import { IWithdrawWhitelistItem } from 'api/apiWithdrawWhitelist/models';
import { EmptyIcon } from 'ui/Alert/icons';
import { setCopyPopupShow } from 'store/slices/ui';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { useSignWithdrawWalletApprove } from 'hooks';
import { ENetwork } from 'types';
import { BSC_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID, ARBITRUM_CHAIN_ID } from 'types/web3';
import { Bus } from 'tools';
import { RELOAD_WITHDRAWAL_WHTELIST } from 'constants/reload';
import { TBody, StaticTable } from 'tables/common';
import { WalletCell } from './WalletCell';

import { CopyIcon } from 'assets/icons/CopyIcon';
import { CloseIcon } from 'assets/icons';
import './style.scss';

interface IWithdrawWhitelistModalProps {
  onClose: () => void;
}

const WithdrawWhitelistModal: React.FC<IWithdrawWhitelistModalProps> = ({ onClose }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const userId = useTypedSelector(store => store.user.user?.id);
  const { account, chainId } = useWeb3React();
  const dispatch = useTypedDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [whitelist, setWhitelist] = useState<IWithdrawWhitelistItem[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [addWhitelistModalOpened, setAddWhitelistModalOpened] = useState<boolean>(false);
  const [invalidMetamaskModalOpened, setInvalidMetamaskModalOpened] = useState<boolean>(false);

  const handleCopyAllWhitelist = useCallback(() => {
    const preparedString = whitelist.map(el => el.wallet_address).join('\n');
    navigator.clipboard.writeText(preparedString);
    dispatch(setCopyPopupShow(true));
  }, [whitelist, dispatch]);

  const handleCopySingleWallet = useCallback(
    (wallet: string) => {
      navigator.clipboard.writeText(wallet);
      dispatch(setCopyPopupShow(true));
    },
    [dispatch],
  );

  const handleDeleteWalletFromWhitelist = useCallback(
    async (id: number) => {
      try {
        const { isSuccess, errorMessage } = await ApiWithdrawWhitelist.deleteWithdrawWhitelist({
          id,
        });

        if (isSuccess) {
          Bus.emit(RELOAD_WITHDRAWAL_WHTELIST);
          setWhitelist(v => v.filter(el => el.id !== id));
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        }
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      }
    },
    [dispatch, setWhitelist],
  );

  const showDeletingSureModal = useCallback(
    (id: number) => {
      dispatch(
        setAlertState({
          type: 'sure',
          text: 'Are you sure that you want to delete wallet from whitelist?',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
            handleDeleteWalletFromWhitelist(id);
          },
        }),
      );
    },
    [dispatch, handleDeleteWalletFromWhitelist],
  );

  const onSignError = useCallback(
    (message: string) => {
      dispatch(
        setAlertState({
          type: 'failed',
          text: message,
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    },
    [dispatch],
  );

  const onSignSuccess = useCallback(
    async (walletId: number) => {
      try {
        const {
          isSuccess,
          errorMessage,
          data: updatedWallet,
        } = await ApiWithdrawWhitelist.getWithdrawWhitelistItem({ id: walletId });

        if (isSuccess && updatedWallet) {
          Bus.emit(RELOAD_WITHDRAWAL_WHTELIST);
          setWhitelist(v => {
            const newWhitelist = [...v];
            const findInex = newWhitelist.findIndex(el => el.id === walletId);

            newWhitelist[findInex] = updatedWallet;

            return newWhitelist;
          });
        } else if (errorMessage) {
          dispatch(
            setAlertState({
              type: 'failed',
              text: 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed',
            text: 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    },
    [dispatch],
  );

  const signMessage = useSignWithdrawWalletApprove({
    onError: onSignError,
    onSuccess: onSignSuccess,
  });

  const metamaskConnectionIsValid = useMemo<boolean>(() => {
    if (!account) return false;

    if (dexPair.network === ENetwork.bsc && chainId && chainId !== BSC_CHAIN_ID) return false;

    if (dexPair.network === ENetwork.eth && chainId && chainId !== ETH_CHAIN_ID) return false;

    if (dexPair.network === ENetwork.polygon && chainId && chainId !== POLYGON_CHAIN_ID)
      return false;

    if (dexPair.network === ENetwork.arbitrum && chainId && chainId !== ARBITRUM_CHAIN_ID)
      return false;

    return true;
  }, [account, dexPair, chainId]);

  const handleAccept = useCallback(
    async (id: number) => {
      if (!metamaskConnectionIsValid) {
        setInvalidMetamaskModalOpened(true);
        return;
      }

      signMessage({ id });
    },
    [signMessage, metamaskConnectionIsValid],
  );

  const showAcceptSureModal = useCallback(
    (wallet: IWithdrawWhitelistItem) => {
      dispatch(
        setAlertState({
          type: 'sure',
          text: `Are you sure that you want to accept ${wallet.wallet_address} wallet to whitelist?`,
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
            handleAccept(wallet.id);
          },
        }),
      );
    },
    [dispatch, handleAccept],
  );

  const checkCanAccept = useCallback(
    (wallet: IWithdrawWhitelistItem) => {
      return (
        wallet.can_confirm &&
        (!wallet.confirmations || !wallet.confirmations.find(el => el.user.id === userId))
      );
    },
    [userId],
  );

  const columns = useMemo<ColumnDef<IWithdrawWhitelistItem>[]>(
    () => [
      {
        id: 'wallets',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Wallets: {totalItems}</div>,
        cell: ({ row }) => <WalletCell wallet={row.original} />,
      },
      {
        id: 'actions',
        accessorFn: row => row,
        header: () => (
          <motion.div
            onClick={handleCopyAllWhitelist}
            style={{ textAlign: 'right', color: '#5932EA', fontWeight: 600, cursor: 'pointer' }}
          >
            Copy all
          </motion.div>
        ),
        cell: ({ row }) => {
          return (
            <div className="table-actions">
              {checkCanAccept(row.original) && (
                <div className="accept-action" onClick={() => showAcceptSureModal(row.original)}>
                  Accept
                </div>
              )}
              <motion.div
                className="copy-icon"
                onClick={() => handleCopySingleWallet(row.original.wallet_address)}
              >
                <CopyIcon />
              </motion.div>
              <motion.div
                className="close-icon"
                onClick={() => showDeletingSureModal(row.original.id)}
              >
                <CloseIcon />
              </motion.div>
            </div>
          );
        },
      },
    ],
    [
      totalItems,
      handleCopySingleWallet,
      handleCopyAllWhitelist,
      showDeletingSureModal,
      showAcceptSureModal,
      checkCanAccept,
    ],
  );

  const table = useReactTable({
    columns,
    data: whitelist,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const handleLoadWhitelist = useCallback(async () => {
    try {
      setLoading(true);

      const result = await ApiWithdrawWhitelist.getWithdrawWhitelist({
        limit: 100,
        offset: 0,
        pairsIds: [dexPair.id.toString()],
        projectIds: [dexPair.project_id.toString()],
      });

      if (result && result.data) {
        setWhitelist(result.data.items ?? []);
        setTotalItems(result.data.total_items);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [dexPair]);

  useEffect(() => {
    handleLoadWhitelist();
  }, [handleLoadWhitelist]);

  return (
    <>
      {!addWhitelistModalOpened && (
        <Modal onClose={onClose} title="Withdrawal whitelist">
          <div className="mm-withdraw-whitelist-modal">
            {loading && <Spinner size="small" />}
            {!loading && whitelist.length === 0 && (
              <div className="whitelist-empty">
                <EmptyIcon />
                <span>Your whitelist is empty</span>
              </div>
            )}
            {!loading && whitelist.length !== 0 && (
              <StaticTable className="mm-common-table">
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{ width: header.getSize() }}
                          >
                            {header.isPlaceholder ? null : (
                              <div>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                              </div>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <TBody>
                  {rows.map(row => (
                    <React.Fragment key={row.index}>
                      <tr>
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  ))}
                </TBody>
              </StaticTable>
            )}
            <div className="whitelist-actions">
              {loading && <ButtonLoading />}
              {!loading && (
                <Button onClick={() => setAddWhitelistModalOpened(true)}>Add wallet</Button>
              )}
            </div>
          </div>
        </Modal>
      )}
      {addWhitelistModalOpened && (
        <AddWithdrawWhitelistModal
          onClose={() => setAddWhitelistModalOpened(false)}
          onConfirm={() => handleLoadWhitelist()}
        />
      )}
      {invalidMetamaskModalOpened && (
        <ChangePairNetwork onClose={() => setInvalidMetamaskModalOpened(false)} />
      )}
    </>
  );
};

export { WithdrawWhitelistModal };
