import React, { useState, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { Panel, AutoRefresh } from 'ui';
import PairStatisticContextProvider from 'context/PairStatisticContext';

import { Content } from './components';
import { PAIR_STATISTIC_RELOAD } from 'constants/reload';
import { IPanelProps } from 'types/pairs';

import { ColumnChartIcon } from 'assets/icons';
import './statistic.scss';

const Statistic: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(false);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      icon={ColumnChartIcon}
      title="Statistic"
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        isPanelOpened && (
          <div className="mm-project-statistic-panel-auto-refresh">
            <AutoRefresh
              busEvent={PAIR_STATISTIC_RELOAD}
              tooltipText="Autorefresh"
              pairId={pair.id}
              cacheName="statistic"
            />
          </div>
        )
      }
    >
      <PairStatisticContextProvider>
        <Content />
      </PairStatisticContextProvider>
    </Panel>
  );
};

export { Statistic };
