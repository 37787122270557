import { useCallback } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { isNil } from 'lodash';
import { v4 as uuid } from 'uuid';

import { EDexPairFilters } from 'types/filters';
import { IDexFilterValue } from 'types/filters/common';

import { setupInitialDexPairFilters, setupInitialDexFilterType } from '..';

export function useGetDexPairFilters<V extends EDexPairFilters>({
  pairId,
  type,
}: {
  pairId: number | undefined;
  type: V;
}) {
  type ReturnGetType = IDexFilterValue[];

  const dispatch = useTypedDispatch();
  const pairState = useTypedSelector(store =>
    !isNil(pairId) ? store.dexPairFilters[pairId] : undefined,
  );

  const getDexPairFilters = useCallback((): IDexFilterValue[] => {
    if (isNil(pairId)) return [];

    if (!pairState) {
      const initialFilters = [{ id: uuid() }];

      dispatch(
        setupInitialDexPairFilters({
          pairId,
          filters: {
            updated_at: new Date(Date.now()).toISOString(),
            filters: {
              [type]: {
                filters: initialFilters,
                setted: [],
              },
            },
          },
        }),
      );

      return initialFilters as ReturnGetType;
    }

    const filtersByType = pairState.filters[type];

    if (!filtersByType) {
      const initialFilters = [{ id: uuid() }];

      dispatch(
        setupInitialDexFilterType({
          pairId,
          filters: { [type]: { filters: initialFilters, setted: [] } },
        }),
      );

      return initialFilters as ReturnGetType;
    }

    return filtersByType.filters as ReturnGetType;
  }, [dispatch, pairId, pairState, type]);

  return getDexPairFilters;
}
