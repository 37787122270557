import mitt, { Emitter, EventType } from 'mitt';

export class EventBus {
  private handledEvents: Array<EventType> = [];

  private emitter: Emitter<Record<EventType, unknown>>;

  constructor() {
    this.emitter = mitt<Record<EventType, unknown>>();
  }

  on(eventName: EventType, handlerFn: (payload: unknown) => void): void {
    if (this.handledEvents.includes(eventName)) return;

    this.emitter.on(eventName, handlerFn);
    this.handledEvents.push(eventName);
  }

  emit(eventName: EventType, payload?: unknown): void {
    this.emitter.emit(eventName, payload);
  }

  off(eventName: EventType, handlerFn: (payload: unknown) => void): void {
    if (!this.handledEvents.includes(eventName)) return;

    this.emitter.off(eventName, handlerFn);
    this.handledEvents = this.handledEvents.filter(event => event !== eventName);
  }
}

export const Bus = new EventBus();
