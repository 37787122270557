import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { v4 as uuid } from 'uuid';

import { TableCheckbox } from 'ui';
import ProjectListIconPairs from 'common/project/ProjectListWithPairs/PlatformIcon';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';
import { ICexName } from 'types';

interface ICexAccountCellProps {
  row: Row<ICexAccounts>;
}

const CexAccountCell: React.FC<ICexAccountCellProps> = ({ row }) => {
  const checkboxId = useMemo(() => uuid(), []);

  return (
    <div className="checkbox-area">
      <TableCheckbox
        id={checkboxId}
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
      <ProjectListIconPairs
        className="trading-pair-account-icon"
        platform={row.original.cex as ICexName}
      />
    </div>
  );
};

export default CexAccountCell;
