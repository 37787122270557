import { createSlice } from '@reduxjs/toolkit';

import { IAlert } from './models';

interface IUiSliceState {
  alert: IAlert | undefined;
  copyPopupShow: boolean;
  triggerCopying: boolean;
}
const uiSliceInitialState: IUiSliceState = {
  alert: undefined,
  copyPopupShow: false,
  triggerCopying: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: uiSliceInitialState as IUiSliceState,
  reducers: {
    setAlertState: (state, { payload }: { payload: IAlert }) => {
      state.alert = payload;
    },
    dropAlertState: (state) => {
      state.alert = undefined;
    },
    setCopyPopupShow: (state, { payload }: { payload: boolean }) => {
      state.copyPopupShow = payload;
      state.triggerCopying = !state.triggerCopying;
    },
  },
});

export const { setAlertState, dropAlertState, setCopyPopupShow } = uiSlice.actions;

export default uiSlice.reducer;
