import React, {
  createContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ApiStatistic } from 'api';
import { usePairConfig } from 'hooks';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { PAIR_CEX_STATISTIC_RELOAD, STOP_RELOAD_PREFIX } from 'constants/reload';
import { Bus } from 'tools';
import { IChartRange } from 'types/charts';
import { ICexOrder, ICexStatisticToken } from '../api/apiStatistic/models/IStatisticToken';

interface IStatisticContext {
  cexStatisticTokens: ICexStatisticToken[] | undefined;
  statisticCexOrders: ICexOrder[] | undefined;
  generalLoading: boolean;
  firstCexTradeTime: string | undefined;
  selectedRange: {
    get: IChartRange | undefined;
    set: (value: IChartRange | undefined) => void;
  };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  rangeLoading: boolean;
}

export const CexPairStatisticContext = createContext<IStatisticContext>({
  cexStatisticTokens: undefined,
  statisticCexOrders: undefined,
  firstCexTradeTime: undefined,
  generalLoading: false,
  selectedRange: { get: '1M', set: () => {} },
  startDate: { get: undefined, set: () => {} },
  endDate: { get: undefined, set: () => {} },
  rangeLoading: false,
});

interface ICexPairStatisticContextProps {
  children?: React.ReactNode;
}

const CexPairStatisticContextProvider: React.FC<ICexPairStatisticContextProps> = ({ children }) => {
  const dispatch = useTypedDispatch();
  const pair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const { getPairConfig, setPairConfig } = usePairConfig();
  const initialSelectedRange = useMemo(
    () => getPairConfig(pair.id).statisticRange ?? '1M',
    [pair, getPairConfig],
  );

  const [generalLoading, setGeneralLoading] = useState<boolean>(true);
  const [selectedRange, setSelectedRange] = useState<IChartRange | undefined>(initialSelectedRange);
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [rangeLoading, setRangeLoading] = useState<boolean>(false);
  const [firstCexTradeTime, setFirstCexTradeTime] = useState<string | undefined>(undefined);

  const [cexStatisticTokens, setCexStatisticTokens] = useState<ICexStatisticToken[] | undefined>(
    undefined,
  );
  const [statisticCexOrders, setStatisticCexOrders] = useState<ICexOrder[] | undefined>(undefined);

  const handleSetSelectedRange = useCallback(
    (newChartRange: IChartRange | undefined) => {
      setSelectedRange(newChartRange);

      const newPairConfig = getPairConfig(pair.id);
      newPairConfig.statisticRange = newChartRange;

      setPairConfig(pair.id, newPairConfig);
    },
    [getPairConfig, setPairConfig, pair],
  );
  const setupAllCexStatisticGetRequests = useCallback(
    async (options: { silent: boolean } = { silent: false }) => {
      try {
        if (!options.silent) {
          if (!cexStatisticTokens) {
            setGeneralLoading(true);
          } else {
            setRangeLoading(true);
          }
        }
        const { isSuccess, data, errorMessage } = await ApiStatistic.getCexStatistic({
          projectId: pair.id,
          ranges: [
            selectedRange
              ? selectedRange
              : startDate && endDate
              ? `${Math.floor(startDate / 1000)}-${Math.floor(endDate / 1000)}`
              : '1D',
          ],
        });
        if (isSuccess && data) {
          const resultTokens: ICexStatisticToken[] = [];
          const baseCexTokenStatistic = data.tokens.find(
            el => el.token.symbol === pair.token_base.symbol,
          );
          const quoteCexTokenStatistic = data.tokens.find(
            el => el.token.symbol === pair.token_quote.symbol,
          );
          const nativeCexTokenStatistic = data.tokens.find(
            el => el.token.symbol === pair?.token_native?.symbol,
          );

          if (baseCexTokenStatistic) {
            resultTokens.push(baseCexTokenStatistic);
          }
          if (quoteCexTokenStatistic) {
            resultTokens.push(quoteCexTokenStatistic);
          }
          if (nativeCexTokenStatistic) {
            resultTokens.push(nativeCexTokenStatistic);
          }

          setCexStatisticTokens(resultTokens);
          setStatisticCexOrders(data.orders);
          setFirstCexTradeTime(data.first_trade_time);
        } else if (!isSuccess && errorMessage) {
          dispatch(
            setAlertState({
              text: errorMessage,
              type: 'failed',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setGeneralLoading(false);
        setRangeLoading(false);
      }
    },
    [pair, selectedRange, startDate, endDate, cexStatisticTokens],
  );

  useEffect(() => {
    setupAllCexStatisticGetRequests();
  }, [pair, selectedRange, startDate, endDate]);

  const handleReload = useCallback(async () => {
    if (!generalLoading) {
      const timeStart = Date.now();

      await setupAllCexStatisticGetRequests({ silent: true });

      const timeEnd = Date.now();

      if (timeEnd - timeStart >= 1500) {
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_CEX_STATISTIC_RELOAD);
      } else {
        setTimeout(() => {
          Bus.emit(STOP_RELOAD_PREFIX + PAIR_CEX_STATISTIC_RELOAD);
        }, 1500 - (timeEnd - timeStart));
      }
    }
  }, [selectedRange, generalLoading, setupAllCexStatisticGetRequests]);

  useEffect(() => {
    Bus.on(PAIR_CEX_STATISTIC_RELOAD, handleReload);

    return () => {
      Bus.off(PAIR_CEX_STATISTIC_RELOAD, handleReload);
    };
  }, [handleReload]);

  const value = useMemo(
    () => ({
      cexStatisticTokens,
      statisticCexOrders,
      generalLoading,
      firstCexTradeTime,
      selectedRange: { get: selectedRange, set: handleSetSelectedRange },
      startDate: { get: startDate, set: setStartDate },
      endDate: { get: endDate, set: setEndDate },
      rangeLoading,
    }),
    [
      cexStatisticTokens,
      statisticCexOrders,
      firstCexTradeTime,
      generalLoading,
      selectedRange,
      startDate,
      endDate,
      rangeLoading,
      handleSetSelectedRange,
    ],
  );

  return (
    <CexPairStatisticContext.Provider value={value}>{children}</CexPairStatisticContext.Provider>
  );
};

export default CexPairStatisticContextProvider;
