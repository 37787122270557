import React, { useContext, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

import { RangeInput } from 'ui';

import {
  MIN_TRADE_FREQUENCY,
  MAX_TRADE_FREQUENCY,
  DEFAULT_TRADE_FREQUENCY,
} from 'types/wash-trading-bot';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import useScroll from './useScroll';
import { parseDurationToSeconds } from 'utils';

import './style.scss';

const TradeFrequency: React.FC = () => {
  const { tradeFrequencyCoefficient, currentConfig, chartLoading } =
    useContext(PairWashTradingContext);

  const { innerCoefficient, scrollPos, setScrollPos } = useScroll({
    coefficient: tradeFrequencyCoefficient.get,
    setCoefficient: tradeFrequencyCoefficient.set,
    points: {
      initial: DEFAULT_TRADE_FREQUENCY,
      max: MAX_TRADE_FREQUENCY,
      min: MIN_TRADE_FREQUENCY,
    },
  });

  const coefficient = useMemo(() => tradeFrequencyCoefficient.get, [tradeFrequencyCoefficient.get]);
  const [lastUsedCoefficient, setLastUseCoefficient] = useState<number>(coefficient);

  useDebounce(
    () => {
      if (
        currentConfig.get &&
        currentConfig.get.config?.random_strategy &&
        (!lastUsedCoefficient || lastUsedCoefficient !== coefficient)
      ) {
        const newMaxPauseFromConfig = parseDurationToSeconds(
          currentConfig.get.config.random_strategy.max_pause_between_trades
        );
        const minPauseFromConfig = parseDurationToSeconds(
          currentConfig.get.config.random_strategy.min_pause_between_trades
        );

        const newMaxPause = Number(
          Math.floor((newMaxPauseFromConfig * coefficient) / lastUsedCoefficient)
        );
        const newMaxPauseResult =
          newMaxPause < minPauseFromConfig ? minPauseFromConfig : newMaxPause;

        currentConfig.set({
          ...currentConfig.get,
          config: {
            ...currentConfig.get.config,
            random_strategy: {
              ...currentConfig.get.config.random_strategy,
              max_pause_between_trades: `${newMaxPauseResult}s`,
            },
          },
        });
        setLastUseCoefficient(coefficient);
      }
    },
    100,
    [coefficient, lastUsedCoefficient]
  );

  const maxPauseBetweenTrades = useMemo(() => {
    if (currentConfig.get && currentConfig.get.config?.random_strategy) {
      return parseDurationToSeconds(
        currentConfig.get.config.random_strategy.max_pause_between_trades
      );
    }

    return undefined;
  }, [currentConfig.get]);

  return (
    <div className="mm-wt-bot-scroll-controller">
      <div className="controller-head">
        <span>Trade frequency:</span>
        <span>
          {innerCoefficient.toFixed(4)}{' '}
          {maxPauseBetweenTrades
            ? `(${dayjs.duration({ seconds: maxPauseBetweenTrades }).humanize()})`
            : null}
        </span>
      </div>
      <RangeInput
        className="total-frequency-range"
        value={scrollPos}
        min={0}
        max={100}
        step={1}
        setValue={setScrollPos}
        disabled={!!chartLoading.get}
      />
    </div>
  );
};

export default TradeFrequency;
