import React from 'react';
import cn from 'classnames';

import { IDexBuySellBotDirection } from 'types/bots';

interface IDirectionProps {
  direction: IDexBuySellBotDirection;
}

export const Direction: React.FC<IDirectionProps> = ({ direction }) => {
  return (
    <div className="direction-cell">
      <span className={cn({ green: direction === 'buy', red: direction === 'sell' })}>
        {direction}
      </span>
    </div>
  );
};
