import { IDexPair } from 'types/pairs';

import { TOTAL_TOKEN_ID } from 'constants/numbers';
import { IFilterSchemeExtended, EFilterEntity, EFilterFormatter } from '../common';

export const dexPairWalletsFiltersScheme = (
  pair: IDexPair,
): Record<string, IFilterSchemeExtended> => ({
  balance: {
    name: 'balance',
    label: 'Balance',
    filters: [
      {
        label: pair.token_base.symbol,
        name: 'baseBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: pair.token_base.id,
        decimals: pair.token_base.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_quote.symbol,
        name: 'quoteBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: pair.token_quote.id,
        decimals: pair.token_quote.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_fee.symbol,
        name: 'feeBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: pair.token_fee.id,
        decimals: pair.token_fee.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: 'All',
        name: 'allBalance',
        type: EFilterEntity.number,
        field: 'balance',
        tokenId: TOTAL_TOKEN_ID,
        decimals: 18,
        formatter: EFilterFormatter.bn_number_token,
      },
    ],
  },
  volume: {
    name: 'volume',
    label: 'Volume',
    filters: [
      {
        label: pair.token_base.symbol,
        name: 'baseVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: pair.token_base.id,
        decimals: pair.token_base.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_quote.symbol,
        name: 'quoteVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: pair.token_quote.id,
        decimals: pair.token_quote.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: pair.token_fee.symbol,
        name: 'feeVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: pair.token_fee.id,
        decimals: pair.token_fee.decimals,
        formatter: EFilterFormatter.bn_number_token,
      },
      {
        label: 'All',
        name: 'allVolume',
        type: EFilterEntity.number,
        field: 'volume',
        tokenId: TOTAL_TOKEN_ID,
        decimals: 18,
        formatter: EFilterFormatter.bn_number_token,
      },
    ],
  },
  transactions: {
    name: 'transactions',
    label: 'Transactions',
    filters: [
      {
        label: pair.token_base.symbol,
        name: 'baseTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: pair.token_base.id,
        formatter: EFilterFormatter.natural_number_token,
      },
      {
        label: pair.token_quote.symbol,
        name: 'quoteTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: pair.token_quote.id,
        formatter: EFilterFormatter.natural_number_token,
      },
      {
        label: pair.token_fee.symbol,
        name: 'feeTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: pair.token_fee.id,
        formatter: EFilterFormatter.natural_number_token,
      },
      {
        label: 'All',
        name: 'allTransactions',
        type: EFilterEntity.natural_number,
        field: 'transactions_count',
        tokenId: TOTAL_TOKEN_ID,
        formatter: EFilterFormatter.natural_number_token,
      },
    ],
  },
  availability: {
    name: 'availability',
    type: EFilterEntity.boolean,
    label: 'Available',
    field: 'is_disabled',
    formatter: EFilterFormatter.boolean,
  },
});
