interface ICandle {
  close: number;
  high: number;
  low: number;
}

interface ICalculateATRsProps {
  candles: ICandle[];
  period: number;
}

// ATRPreferAvg calculates average true range indicator value for the recent candle
function ATRPreferAvg(period: number, candles: ICandle[]) {
  if (period === 1) {
    return TR(0, candles);
  }

  if (candles.length < period) {
    throw new Error(
      `Candles not enough to calculate ATR, expected minimum=${period}, optimum=${
        2 * period - 1
      }, but got ${candles.length}`,
    );
  }

  if (candles.length >= 2 * period - 1) {
    return atr(2 * (period - 1), period - 1, period, candles);
  }

  return atr(candles.length - 1, candles.length - period, period, candles);
}

function atr(start: number, end: number, period: number, candles: ICandle[]) {
  let sum = 0.0;

  for (let i = start; i >= end; i--) {
    sum += TR(i, candles);
  }
  let res = sum / period;

  for (let i = end - 1; i >= 0; i--) {
    res = (res * (period - 1) + TR(i, candles)) / period;
  }
  return res;
}

// TR calculates true range indicator value for specified candle `idx`
function TR(idx: number, candles: ICandle[]) {
  if (idx === candles.length - 1) {
    return candles[idx].high - candles[idx].low;
  }

  return Math.max(
    candles[idx].high - candles[idx].low,
    Math.max(
      Math.abs(candles[idx].high - candles[idx + 1].close),
      Math.abs(candles[idx].low - candles[idx + 1].close),
    ),
  );
}

export const calculateATRs = ({ candles, period }: ICalculateATRsProps) => {
  const n = candles.length;

  if (n <= period) return [];

  const ATRS: number[] = [];

  for (let i = period; i <= candles.length; i++) {
    const _candles = candles.slice(0, i).reverse();

    const atr = ATRPreferAvg(period, _candles);

    ATRS.push(atr);
  }

  return ATRS;
};
