import { formatStringToNaturalNumber, formatStringToAnyPositiveNumber } from './functions';

const NATURAL_NUMBER_FORMAT_TYPE = 'natural-number';
const ANY_POSITIVE_NUMBER_FORMAT_TYPE = 'any-positive-number';

export type FormatType = typeof NATURAL_NUMBER_FORMAT_TYPE | typeof ANY_POSITIVE_NUMBER_FORMAT_TYPE;

class Formatter {
  private static mapFormatToFunction: {
    [key in FormatType]: (value: string | undefined) => {
      value: string | undefined;
      formatedValue: string | undefined;
    };
  } = {
    [NATURAL_NUMBER_FORMAT_TYPE]: (
      value: string | undefined,
    ): { value: string | undefined; formatedValue: string | undefined } => {
      return formatStringToNaturalNumber({ string: value });
    },
    [ANY_POSITIVE_NUMBER_FORMAT_TYPE]: (
      value: string | undefined,
    ): { value: string | undefined; formatedValue: string | undefined } => {
      return formatStringToAnyPositiveNumber({ string: value });
    },
  };

  public static format = ({
    format,
    value,
  }: {
    format: FormatType;
    value: string;
  }): {
    value: string | undefined;
    formatedValue: string | undefined;
  } => {
    const formatFunction = this.mapFormatToFunction[format];
    const formatResult = formatFunction(value);

    return formatResult;
  };
}

export { Formatter };
