import { useState, useMemo, useCallback, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash';
import { useTypedDispatch, useTypedSelector } from 'store';

import { useScrollPagination } from 'hooks';
import { useDexPairFilters } from 'store/slices/dex_pair_filters/hooks';
import { EDexPairFilters } from 'types/filters';
import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import { useDexBoostHoldersWallets } from '../api';

interface IUseDexBoostHoldersWalletsRecords {
  wallets: 'source' | 'destination';
  task_id: number;
}

const useDexBoostHoldersWalletsRecords = ({
  task_id,
  wallets,
}: IUseDexBoostHoldersWalletsRecords) => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { settedPairFilters } = useDexPairFilters({
    dexPair,
    type: EDexPairFilters.boostHoldersWallets,
  });
  const previousFilters = usePrevious(settedPairFilters);

  const [records, setRecords] = useState<IBalanceBotWallet[] | undefined>(undefined);
  const [virtualRecords, setVirtualRecords] = useState<IBalanceBotWallet[]>([]);
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});

  const getWallets = useDexBoostHoldersWallets();

  const selectedRecords = useMemo(() => {
    const selectedRows: IBalanceBotWallet[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, records]);

  const getRecords = useCallback(
    async ({
      limit,
      lastSeenId,
    }: {
      limit: number;
      lastSeenId: number;
    }): Promise<{ records: IBalanceBotWallet[]; has_more: boolean } | undefined> => {
      try {
        const { isSuccess, errorMessage, data } = await getWallets({
          lastSeenId,
          limit,
          filters: settedPairFilters,
          task_id,
          wallets,
        });

        if (isSuccess && data) {
          return {
            records: data.items,
            has_more: data.has_next,
          };
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          return undefined;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [dispatch, getWallets, settedPairFilters, task_id, wallets],
  );

  const { hasMore, loading, loadMore, remount } = useScrollPagination({
    limit: 20,
    getRecords,
  });

  const handleRemount = useCallback(() => {
    setRecords(undefined);
    setRowSelection({});
    remount();
  }, [remount]);

  const handleLoadMore = useCallback(async () => {
    const newRecordsSlice = await loadMore();

    if (newRecordsSlice) {
      setRecords(v => (v ? [...v].concat([...newRecordsSlice]) : newRecordsSlice));
    }
  }, [loadMore]);

  useEffect(() => {
    if (!!previousFilters && !isEqual(settedPairFilters, previousFilters)) {
      handleRemount();
    }

    //eslint-disable-next-line
  }, [settedPairFilters]);

  const result = useMemo(
    () => ({
      records: { get: records, set: setRecords },
      virtualRecords: { get: virtualRecords, set: setVirtualRecords },
      hasMore,
      loading,
      handleLoadMore,
      handleRemount,
      rowSelection: { get: rowSelection, set: setRowSelection },
      selectedRecords,
    }),
    [
      records,
      setRecords,
      hasMore,
      loading,
      handleLoadMore,
      handleRemount,
      selectedRecords,
      rowSelection,
      setRowSelection,
      virtualRecords,
      setVirtualRecords,
    ],
  );

  return result;
};

export { useDexBoostHoldersWalletsRecords };
