import { number, object, string, boolean } from 'yup';
import { isNil } from 'lodash';

const isPositiveNumber = (v: string | number) => !isNaN(Number(v)) && Number(v) > 0;

export const validationSchema = () =>
  object({
    enabled: boolean(),
    uniq_id: string(),
    start_at: number()
      .nullable(true)
      .test('start_at', 'Field is required', (v: number | null | undefined) => !isNil(v)),
    end_at: number().nullable(true),
    time_frame: string().required('Field is required'),
    close_bar_before: number().test(
      'close_bar_before',
      'Field is required',
      (v: number | undefined) => !isNil(v) && v > 0,
    ),
    indicator_period: string().test(
      'indicator_period',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
    min_transaction_amount_base: string().test(
      'min_transaction_amount_base',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
    min_pause_between_series: number().test(
      'min_pause_between_series',
      'Field is required',
      (v: number | undefined) => !isNil(v) && v > 0,
    ),
    max_pause_between_series: number().test(
      'max_pause_between_series',
      'Field is required',
      (v: number | undefined) => !isNil(v) && v > 0,
    ),
    min_volume: string().test(
      'min_volume',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
    max_volume: string().test(
      'max_volume',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
    min_transactions_in_series: string().test(
      'min_transactions_in_series',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
    max_transactions_in_series: string().test(
      'max_transactions_in_series',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
    per_transaction_amount_dev: string().test(
      'per_transaction_amount_dev',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
    stop_volume_threshold_percentage: string().test(
      'stop_volume_threshold_percentage',
      'Field is required',
      (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
    ),
  });
