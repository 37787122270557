import React, { useCallback, useContext, useMemo } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { useTypedDispatch, useTypedSelector } from 'store';

import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';
import { Spinner } from 'ui';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { ICexWTOrganicTask } from 'types/bots';
import { addOrganicTask } from 'modals/CexWTOrganicTaskModal/useWTOrganicTask/addOrganicTask';
import { formatToken } from 'utils/formats';
import { OrganicRowInfo } from '../../RowInfo/OrganicRowInfo';

import { ExecutionTime, Status, DailyVolume } from './columns';

import '../style.scss';

const TableComponent: React.FC = () => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const {
    initialLoading,
    organicEnableLoading,
    organicTasks,
    handleLoadRecords,
    extendedOrganicRow,
  } = useContext(CexWashAlgorithmContext);

  const handleDeleteTask = useCallback(
    (task: ICexWTOrganicTask) => {
      dispatch(
        setAlertState({
          type: 'sure',
          text: 'You have unsaved changes. Confirming this will result in losing all unsaved data.',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            addOrganicTask({
              dispatch,
              task: task,
              newTask: task,
              pairId: cexPair.id,
              handleLoadRecords,
              mode: 'delete',
            });
          },
        }),
      );
    },
    [dispatch, handleLoadRecords, cexPair],
  );

  const handleEnableTask = useCallback(
    async (task: ICexWTOrganicTask, checked: boolean) => {
      await addOrganicTask({
        dispatch,
        task: { ...task, enabled: checked },
        newTask: { ...task, enabled: checked },
        pairId: cexPair.id,
        handleLoadRecords,
        setLoading: v => {
          if (v === false) {
            organicEnableLoading.set(undefined);
          } else {
            organicEnableLoading.set(task.start_at);
          }
        },
        onSuccess: () => {
          if (organicTasks.get) {
            const newOrganicTasks = [...organicTasks.get];
            const findIndex = newOrganicTasks.findIndex(el => el.start_at === task.start_at);

            if (findIndex >= 0) {
              newOrganicTasks[findIndex] = { ...task, enabled: checked };
            }

            organicTasks.set(newOrganicTasks);
          }
        },
        mode: 'edit',
      });
    },

    //eslint-disable-next-line
    [cexPair, dispatch, handleLoadRecords, organicEnableLoading.set],
  );

  const columns = useMemo<ColumnDef<ICexWTOrganicTask>[]>(
    () => [
      {
        header: () => <div style={{ textAlign: 'left' }}>Execution time:</div>,
        id: 'execution-time',
        cell: ({ row: { original } }) => <ExecutionTime task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Timeframe</div>,
        id: 'group-volume',
        cell: ({ row: { original } }) => <div>{original.options.time_frame}</div>,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Close bar before</div>,
        id: 'makers-takers',
        cell: ({ row: { original } }) => <div>{original.options.close_bar_before}</div>,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Base volume</div>,
        id: 'base-volume',
        cell: ({ row: { original } }) => (
          <div>
            {formatToken(BigNumber.from(original.options.base_volume), 18)}{' '}
            {cexPair.token_base.symbol}
          </div>
        ),
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Min/Max orders</div>,
        id: 'min-max-orders',
        cell: ({ row: { original } }) => {
          return (
            <div>
              {original.options.orders_min} / {original.options.orders_max}
            </div>
          );
        },
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Daily volume</div>,
        id: 'daily-estimate',
        cell: ({ row: { original } }) => <DailyVolume task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'right' }}>Status</div>,
        id: 'status',
        cell: ({
          table: {
            options: { meta },
          },
          row,
        }) => {
          const _meta = meta as any;

          return (
            <Status
              task={row.original}
              rowIndex={row.index}
              extendedRow={_meta.extendedRow}
              onEnableTask={handleEnableTask}
            />
          );
        },
      },
    ],
    [cexPair, handleEnableTask],
  );

  const table = useReactTable({
    data: organicTasks.get ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      extendedRow: extendedOrganicRow.get,
    },
  });

  const { rows } = table.getRowModel();

  return (
    <>
      <table className={cn('mm-static-table', 'mm-wash-algorithm-table')}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {!initialLoading.get &&
            rows.map(row => {
              return (
                <React.Fragment key={row.index}>
                  <tr
                    key={row.index}
                    className={cn({
                      _selected: extendedOrganicRow.get === row.index,
                    })}
                    onClick={() => extendedOrganicRow.set(row.index)}
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                  <AnimatePresence>
                    {extendedOrganicRow.get === row.index && (
                      <tr className={cn('mm-wash-algorithm-opened-task')}>
                        <td colSpan={7}>
                          <OrganicRowInfo
                            onDeleteTask={() => handleDeleteTask(row.original)}
                            task={row.original}
                            rowIdx={row.index}
                          />
                        </td>
                      </tr>
                    )}
                  </AnimatePresence>
                  <tr className="tr-separator" />
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
      {initialLoading.get && (
        <div className="mm-wash-algorithm-table-loader">
          <Spinner size="mini" />
        </div>
      )}
    </>
  );
};

const OrganicVolumeTable = React.memo(TableComponent);
export { OrganicVolumeTable };
