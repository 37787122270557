import { IDexPair } from 'types/pairs';

import { IFilterSchemeExtended, EFilterEntity, EFilterFormatter } from '../common';

export const dexBoostHoldersWalletsFiltersScheme = (
  pair: IDexPair,
): Record<string, IFilterSchemeExtended> => ({
  not_connected: {
    name: 'not_connected',
    type: EFilterEntity.boolean,
    label: 'Connected',
    field: 'not_connected',
    formatter: EFilterFormatter.boolean,
  },
});
