import { JsonRpcProvider } from '@ethersproject/providers';

const bscRpcUrl = process.env.REACT_APP_BSC_RPC_URL;
const ethRpcUrl = process.env.REACT_APP_ETH_RPC_URL;
const polygonRpcUrl = process.env.REACT_APP_POLYGON_RPC_URL;
const arbitrumRpcUrl = process.env.REACT_APP_ARBITRUM_RPC_URL;

export const BscProvider = new JsonRpcProvider(bscRpcUrl);
export const EthProvider = new JsonRpcProvider(ethRpcUrl);
export const PolygonProvider = new JsonRpcProvider(polygonRpcUrl);
export const ArbitrumProvider = new JsonRpcProvider(arbitrumRpcUrl);
