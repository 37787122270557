import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { ICexPair, IDexPair } from 'types/pairs';
import { multiplyBignumbers } from 'utils/formulas';

interface FormatDailyVolumeValues {
  delay_min: number;
  delay_max: number;
  volume_min: string;
  volume_max: string;
  pair: ICexPair | IDexPair;
}

export const dailyVolume = ({
  delay_min,
  delay_max,
  volume_min,
  volume_max,
  pair,
}: FormatDailyVolumeValues) => {
  const decimals = 'dex' in pair ? pair.token_base.decimals : 18;

  if (!delay_min || !delay_max || !volume_min || !volume_max) {
    return { volume: BigNumber.from(0), volumeUsd: BigNumber.from(0) };
  }

  const delayAvg = (delay_min + delay_max) / 2;
  const volumeAvg = (Number(volume_min) + Number(volume_max)) / 2;

  const count = (24 * 60 * 60 * 1000) / delayAvg;

  const volume = Number((count * volumeAvg).toFixed(0)) || 0;

  const volumeBN = parseUnits(volume.toString(), decimals);
  const volumeUsdBN = multiplyBignumbers(
    [volumeBN, decimals],
    [BigNumber.from(pair.token_base.price_usd), 6],
  );

  return {
    volume: volumeBN,
    volumeUsd: volumeUsdBN,
  };
};
