import React, { useContext, useCallback, useState, useEffect } from 'react';

import { ToggleRadio, Button } from 'ui';
import { BotSettingsModal } from 'modals';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';

import { EDexBot } from 'types/bots';
import { mapWTBotTabToName, mapWTBotNameToTab, IWTBotTabs } from 'types/wash-trading-bot';

import { CogIcon } from 'assets/icons';
import './toolbar.scss';

const Toolbar: React.FC = () => {
  const {
    selectedTab,
    botSettings,
    handleLoadBotSettings,
    handleLoadPresets,
    currentConfig,
    initialConfig,
  } = useContext(PairWashTradingContext);

  const [botSettingsModalOpened, setBotSettingsModalOpened] = useState(false);

  const handleSettingBot = useCallback(() => {
    setBotSettingsModalOpened(true);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      await handleLoadBotSettings();

      setTimeout(() => {
        handleLoadPresets();
      }, 0);
    };

    loadData();
  }, []);

  const handleSelectOtherTab = useCallback(
    (tab: IWTBotTabs) => {
      if (tab !== selectedTab.get) {
        selectedTab.set(tab);
      }
    },
    [selectedTab, initialConfig, currentConfig],
  );

  return (
    <>
      <div className="mm-wash-trading-bot-toolbar">
        <div className="mm-wash-trading-bot-toolbar__left">
          <ToggleRadio
            titles={[mapWTBotTabToName('quick-start'), mapWTBotTabToName('advanced')]}
            value={mapWTBotTabToName(selectedTab.get)}
            onChange={e => handleSelectOtherTab(mapWTBotNameToTab(e.target.value))}
          />
        </div>
        <div className="mm-wash-trading-bot-toolbar__right">
          <Button color="transparent" className="settings-button" onClick={handleSettingBot}>
            <CogIcon color={'#5932EA'} />
            <span>Settings</span>
          </Button>
        </div>
      </div>
      {botSettingsModalOpened && (
        <BotSettingsModal
          onSave={() => {
            handleLoadBotSettings();
          }}
          bot={EDexBot.wash_trading}
          buySellBotSettings={botSettings}
          onOpen={() => setBotSettingsModalOpened(true)}
          onClose={() => setBotSettingsModalOpened(false)}
        />
      )}
    </>
  );
};

export default Toolbar;
