import React from 'react';

import { Pencil2Icon } from 'assets/icons';
import { TrashEmptyIcon } from 'assets/icons';

interface IActionsCellProps {
  onEdit: () => void;
  onDelete: () => void;
}

const ActionsCell: React.FC<IActionsCellProps> = ({ onEdit, onDelete }) => {
  return (
    <div className="actions-cell">
      <button className="action-button" onClick={onEdit}>
        <Pencil2Icon />
      </button>
      <button className="action-button" onClick={onDelete}>
        <TrashEmptyIcon />
      </button>
    </div>
  );
};

export { ActionsCell };
