import React, { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'react-use';
import cn from 'classnames';
import { isEqual } from 'lodash';

import './style.scss';

interface IJsonEditorProps {
  className?: string | undefined;
  delay?: number;
  config: object;
  onChange: (config: string) => any;
}

const JsonEditor: React.FC<IJsonEditorProps> = ({ className, delay = 2000, config, onChange }) => {
  const [innerConfig, setInnerConfig] = useState<string>(JSON.stringify(config, null, 2));

  useEffect(() => {
    try {
      if (!isEqual(config, JSON.parse(innerConfig))) {
        setInnerConfig(JSON.stringify(config ?? {}, null, 2));
      }
    } catch (error) {}
  }, [config]);

  const handleChangeInnerConfig = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInnerConfig(event.currentTarget.value);
    },
    [innerConfig, config]
  );

  const handlePrettierConfig = useCallback(() => {
    try {
      const newObject = JSON.parse(innerConfig);
      const newObjectStringify = JSON.stringify(newObject, null, 2);

      setInnerConfig(newObjectStringify);
    } catch (error) {
      console.log('error: ', error);
    }
  }, [innerConfig]);

  useDebounce(
    () => {
      if (onChange) {
        onChange(innerConfig);
      }
    },
    delay,
    [innerConfig]
  );

  return (
    <div className={cn('mm-json-editor-container', { [className ?? '']: !!className })}>
      <div className="tools-headers">
        <button className="prettier-button" onClick={handlePrettierConfig}>
          Prettier
        </button>
      </div>
      <textarea
        className={cn('mm-json-editor-textarea')}
        value={innerConfig}
        onChange={handleChangeInnerConfig}
      />
    </div>
  );
};

export { JsonEditor };
