import React, { HTMLAttributes, useMemo } from 'react';

interface ITBodyProps extends HTMLAttributes<HTMLTableSectionElement> {
  paddingTop?: number;
  paddingBottom?: number;
  children?: React.ReactNode;
}

const TBody: React.FC<ITBodyProps> = ({ paddingTop, paddingBottom, children, ...rest }) => {
  const paddingTopExist = useMemo(() => paddingTop !== undefined && paddingTop > 0, [paddingTop]);
  const paddingBottomExist = useMemo(
    () => paddingBottom !== undefined && paddingBottom > 0,
    [paddingBottom],
  );

  return (
    <tbody {...rest}>
      {paddingTopExist && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}
      {children}
      {paddingBottomExist && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </tbody>
  );
};

export { TBody };
