import React, { useCallback, useContext, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTypedDispatch } from 'store';

import { Button, ButtonLoading, Modal, JsonEditor } from 'ui';
import { ApiWTBot } from 'api';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { IPreset } from 'types/wash-trading-bot';

import { ArrowDropDown, TrashIcon } from 'assets/icons';
import './style.scss';

interface IWTPresetsModalProps {
  onClose: () => void;
  onOpenCreateModal: () => void;
}

const WTPresetsModal: React.FC<IWTPresetsModalProps> = ({ onClose, onOpenCreateModal }) => {
  const dispatch = useTypedDispatch();
  const {
    presetsShort,
    handleLoadPreset,
    botSettings,
    handleLoadPresets,
    selectedPresetId: _selectedPresetId,
  } = useContext(PairWashTradingContext);

  const [selectedPresetId, setSelectedPresetId] = useState<number | undefined>(undefined);
  const [selectedPresetConfig, setSelectedPresetConfig] = useState<IPreset | undefined>(undefined);
  const [selectedPresetError, setSelectedPresetError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectPreset = useCallback(async (id: number) => {
    setSelectedPresetId(selectedId => (selectedId === id ? undefined : id));

    const config = await handleLoadPreset(id);

    if (config) {
      setSelectedPresetConfig(config);
    }
  }, []);

  const handleChangeCurrentConfig = useCallback((config: IPreset) => {
    setSelectedPresetConfig(config);
  }, []);

  const handleEditPreset = useCallback(async () => {
    if (!selectedPresetId || !presetsShort || !selectedPresetConfig || !botSettings) return;

    const withLoading = _selectedPresetId.get === selectedPresetId;

    const name = presetsShort.find(el => el.id === selectedPresetId)?.name ?? 'name';

    try {
      if (withLoading) {
        setLoading(true);
      }

      const { errorMessage, isSuccess } = await ApiWTBot.updateWTPreset({
        id: selectedPresetId,
        config: {
          name: name,
          random_strategy: selectedPresetConfig.random_strategy,
          volumes_strategy: selectedPresetConfig.volumes_strategy,
          send_private_transactions: botSettings.send_private_transactions,
          slippage_percent: botSettings.slippage_percent,
        },
      });

      onClose();

      if (isSuccess) {
        dispatch(
          setAlertState({
            type: 'success',
            text: 'Successfully changed preset settings!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        setSelectedPresetId(undefined);
        setSelectedPresetConfig(undefined);
        setSelectedPresetError(false);
        handleLoadPresets();
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage ?? 'Error',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      }
    } catch (error) {
      console.log(error);
      onClose();
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Failed while changing preset settings...',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
        }),
      );
    } finally {
      if (withLoading) {
        setLoading(false);
      }
    }
  }, [
    onClose,
    selectedPresetId,
    selectedPresetConfig,
    botSettings,
    handleLoadPresets,
    _selectedPresetId.get,
  ]);

  const handleDeletePreset = useCallback(
    async (id: number) => {
      try {
        setLoading(true);

        const { isSuccess, errorMessage } = await ApiWTBot.deleteWTPreset(id);

        onClose();

        if (isSuccess) {
          dispatch(
            setAlertState({
              type: 'success',
              text: 'Successfully deleted preset settings!',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          setSelectedPresetId(undefined);
          setSelectedPresetConfig(undefined);
          setSelectedPresetError(false);
          handleLoadPresets();
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage ?? 'Failed while deleting preset settings...',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        }
      } catch (error) {
        console.log(error);
        onClose();
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Failed while deleting preset settings...',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      } finally {
        setLoading(false);
      }
    },
    [handleLoadPresets, onClose],
  );

  const handleChangeSelectedPresetConfig = useCallback(
    (newPresetConfig: string) => {
      try {
        const newPresetConfigParsed = JSON.parse(newPresetConfig);

        handleChangeCurrentConfig(newPresetConfigParsed);
        setSelectedPresetError(false);
      } catch (error) {
        setSelectedPresetError(true);
      }
    },
    [handleChangeCurrentConfig],
  );

  return (
    <Modal
      onClose={onClose}
      customHeader={
        <div className="mm-wt-presets-modal__header">
          <span className="title">Profiles</span>
          <Button className="create-new" onClick={onOpenCreateModal}>
            <span>Create new</span>
            <span>+</span>
          </Button>
        </div>
      }
      className="mm-wt-presets-modal"
    >
      <div className="mm-wt-presets-modal-content">
        <div className="mm-wt-presets-modal__presets scrollable">
          <div>
            {(!presetsShort || presetsShort.length === 0) && (
              <div className="presets-no-info">
                <span>
                  {"You don't have presets right now. Create your first using button above!"}
                </span>
              </div>
            )}
            <AnimatePresence>
              {presetsShort &&
                presetsShort.map(presetShort => {
                  if (selectedPresetId !== undefined && presetShort.id !== selectedPresetId)
                    return null;

                  return (
                    <motion.div
                      initial={{ marginTop: 0, height: 0, opacity: 0 }}
                      animate={{ marginTop: 16, height: 'auto', opacity: 1 }}
                      exit={{ marginTop: 0, height: 0, opacity: 0 }}
                      key={`preset-short-${presetShort.id}`}
                      className="preset-container"
                    >
                      <div className="preset-item">
                        <div
                          className="preset-item__inner"
                          onClick={() => handleSelectPreset(presetShort.id)}
                        >
                          <span className="name">{presetShort.name}</span>
                          <motion.div
                            animate={{ rotate: presetShort.id === selectedPresetId ? 180 : 0 }}
                            className="preset-arrow-button"
                          >
                            <ArrowDropDown color={'#5932EA'} />
                          </motion.div>
                        </div>
                        <AnimatePresence>
                          {selectedPresetId && presetShort.id === selectedPresetId ? (
                            <motion.div
                              initial={{ height: 0, opacity: 0, marginTop: 0 }}
                              animate={{ height: 'auto', opacity: 1, marginTop: 16 }}
                              exit={{ height: 0, opacity: 0, marginTop: 0 }}
                              className="preset-item-extended"
                            >
                              <JsonEditor
                                config={selectedPresetConfig ?? {}}
                                delay={300}
                                onChange={handleChangeSelectedPresetConfig}
                              />
                            </motion.div>
                          ) : null}
                        </AnimatePresence>
                      </div>
                      <motion.div
                        whileHover={{ scale: 1.2 }}
                        className="preset-trash-button"
                        onClick={() => (!loading ? handleDeletePreset(presetShort.id) : null)}
                      >
                        <TrashIcon color={'#7F91BB'} />
                      </motion.div>
                    </motion.div>
                  );
                })}
            </AnimatePresence>
          </div>
        </div>
        <AnimatePresence>
          {selectedPresetId !== undefined ? (
            <motion.div
              key="mm-wt-preset-edit-action"
              initial={{ height: 0, opacity: 0, marginTop: 0 }}
              animate={{ height: 'auto', opacity: 1, marginTop: 15 }}
              exit={{ height: 0, opacity: 0, marginTop: 0 }}
              className="mm-wt-presets-modal__action"
            >
              {loading && <ButtonLoading />}
              {!loading && (
                <Button disabled={selectedPresetError} onClick={handleEditPreset}>
                  Save
                </Button>
              )}
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </Modal>
  );
};

export { WTPresetsModal };
