import React from 'react';

import './walletsPageWrapper.scss';

interface IWalletsPageWrapperProps {
  children: React.ReactNode;
}

const WalletsPageWrapper: React.FC<IWalletsPageWrapperProps> = ({ children }) => {
  return <div className="mm-wallets-page-wrapper">{children}</div>;
};

export { WalletsPageWrapper };
