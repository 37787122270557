import React from 'react';
import { Formik } from 'formik';

import { Modal, Button, ButtonLoading } from 'ui';

import { useUDPairModal } from './useU&DPairModal/useU&DPairModal';
import { PairForm } from 'ui/forms';
import { EExchange } from 'types';
import { InputField } from 'fields';

interface IUDPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  pairId: number;
  projectId: number;
  pairSymbol: string;
  initialNotes: string;
  exchange: EExchange;
}

const UDPairModal: React.FC<IUDPairModalProps> = ({
  onClose,
  onOpen,
  pairId,
  projectId,
  pairSymbol,
  initialNotes,
  exchange,
}) => {
  const { loading, initialValues, onUpdatePair, onDeletePair } = useUDPairModal({
    onClose,
    onOpen,
    pairId,
    projectId,
    pairSymbol,
    initialNotes,
    exchange,
  });

  return (
    <Modal onClose={onClose} title="Trading pair settings">
      <Formik initialValues={initialValues} onSubmit={onUpdatePair}>
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <PairForm handleSubmit={handleSubmit}>
              <InputField
                label="Notes"
                type="text"
                value={values.notes}
                setValue={newNotes => setFieldValue('notes', newNotes ?? '')}
              />
              {loading && <ButtonLoading />}
              {!loading && (
                <>
                  <Button type="submit">Confirm changing</Button>
                  <Button type="button" color="error-secondary" onClick={onDeletePair}>
                    Delete trading pair
                  </Button>
                </>
              )}
            </PairForm>
          );
        }}
      </Formik>
    </Modal>
  );
};

export { UDPairModal };
