import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTypedDispatch } from 'store';
import cn from 'classnames';
import { MM_MAIN_ELEMENT_ID } from 'tools/constants';

import { Backdrop } from './Backdrop/Backdrop';
import { CloseIcon } from 'assets/icons';

import './modal.scss';
import { dropAlertState, setAlertState } from 'store/slices/ui';

type IModalComponentProps = {
  children: React.ReactNode;
  title?: string;
  backdropClose?: boolean;
  customHeader?: JSX.Element;
  customButtons?: JSX.Element;
  onClose: () => void;
  className?: string;
  modalRefItem?: React.Ref<HTMLDivElement>;
  edited?: boolean;
} & ({ title: string; customHeader?: never } | { customHeader: JSX.Element; title?: never });

const ModalComponent: React.FC<IModalComponentProps> = ({
  children,
  title,
  backdropClose = true,
  customHeader: CustomHeader,
  customButtons: CustomButtons,
  className,
  modalRefItem,
  onClose,
  edited = false,
}) => {
  const dispatch = useTypedDispatch();

  const handleClose = useCallback(() => {
    if (edited) {
      dispatch(
        setAlertState({
          type: 'sure',
          text: 'You have unsaved changes. Confirming this will result in losing all unsaved data.',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
            onClose();
          },
        }),
      );

      return;
    }

    onClose();
  }, [onClose, edited, dispatch]);

  const handleClickBackdrop = useCallback(() => {
    if (backdropClose) {
      handleClose();
    }
  }, [backdropClose, handleClose]);

  useEffect(() => {
    const onCloseModalOnEscapePressed = (event: KeyboardEvent) => {
      event.stopPropagation();
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', onCloseModalOnEscapePressed);

    return () => {
      document.removeEventListener('keydown', onCloseModalOnEscapePressed);
    };
  }, [handleClose]);

  return (
    <Backdrop onClickBackdrop={handleClickBackdrop}>
      <div
        className={cn('mm-general-modal', { [className ?? '']: className })}
        ref={modalRefItem ?? undefined}
      >
        <div className="mm-general-modal__header">
          {CustomHeader && CustomHeader}
          {title && <h3>{title}</h3>}
          <div className="mm-general-modal__header__close-button" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="mm-general-modal__content scrollable">{children}</div>
        {CustomButtons && <div className="mm-general-modal__buttons">{CustomButtons}</div>}
      </div>
    </Backdrop>
  );
};

const ModalPortal: React.FC<IModalComponentProps> = props => {
  return createPortal(
    <ModalComponent {...props} />,
    document.getElementById(MM_MAIN_ELEMENT_ID) as HTMLElement,
  );
};

export { ModalPortal as Modal };
