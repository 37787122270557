import React, { createContext, useCallback, useEffect, useState, useMemo } from 'react';
import { useTypedSelector } from 'store';
import { uniq } from 'lodash';

import { ECexBot, ICexWTRegularTask, ICexWTOrganicTask } from 'types/bots';

import { ApiBot } from 'api';
import { IKattanaCexChartResponse } from 'api/apiCharts/models';
import { useCexCandlesByTimeframe } from 'hooks';
import { durationToMs } from 'utils/duration';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { ATR_PERIOD } from 'constants/numbers';

interface ICexWashAlgorithmContext {
  initialLoading: { get: boolean; set: (v: boolean) => void };
  taskLoading: { get: boolean; set: (v: boolean) => void };
  regularEnableLoading: { get: string | undefined; set: (v: string | undefined) => void };
  organicEnableLoading: { get: string | undefined; set: (v: string | undefined) => void };
  regularTasks: {
    get: ICexWTRegularTask[] | undefined | null;
    set: (v: ICexWTRegularTask[] | undefined) => void;
  };
  organicTasks: {
    get: ICexWTOrganicTask[] | undefined | null;
    set: (v: ICexWTOrganicTask[] | undefined) => void;
  };
  handleLoadRecords: () => void;
  extendedRegularRow: { get: number | undefined; set: (v: number | undefined) => void };
  extendedOrganicRow: { get: number | undefined; set: (v: number | undefined) => void };
  organicCandles: Record<string, IKattanaCexChartResponse>;
  errorMessage: string | undefined;
}

export const CexWashAlgorithmContext = createContext<ICexWashAlgorithmContext>({
  initialLoading: { get: false, set: () => {} },
  taskLoading: { get: false, set: () => {} },
  regularEnableLoading: { get: undefined, set: () => {} },
  organicEnableLoading: { get: undefined, set: () => {} },
  regularTasks: { get: undefined, set: () => {} },
  organicTasks: { get: undefined, set: () => {} },
  handleLoadRecords: () => {},
  extendedRegularRow: { get: undefined, set: () => {} },
  extendedOrganicRow: { get: undefined, set: () => {} },
  organicCandles: {},
  errorMessage: undefined,
});

interface IWashAlgorithmContextProviderProps {
  children?: React.ReactNode;
}

export const CexWashAlgorithmContextProvider: React.FC<IWashAlgorithmContextProviderProps> = ({
  children,
}) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [taskLoading, setTaskLoading] = useState<boolean>(false);

  const [regularEnableLoading, setRegularEnableLoading] = useState<string | undefined>(undefined);
  const [organicEnableLoading, setOrganicEnableLoading] = useState<string | undefined>(undefined);

  const [regularTasks, setRegularTasks] = useState<ICexWTRegularTask[] | undefined>(undefined);
  const [organicTasks, setOrganicTasks] = useState<ICexWTOrganicTask[] | undefined>(undefined);
  const [timeframes, setTimeframes] = useState<string[]>([]);

  const { candles } = useCexCandlesByTimeframe({
    timeframes: useMemo(() => timeframes, [timeframes]),
    period: ATR_PERIOD,
    cex: useMemo(() => cexPair.cex, [cexPair]),
    pair: useMemo(() => cexPair.cex_id, [cexPair]),
  });

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [extendedRegularRow, setExtendedRegularRow] = useState<number | undefined>(undefined);
  const [extendedOrganicRow, setExtendedOrganicRow] = useState<number | undefined>(undefined);

  const handleSetExtendedRegularRow = useCallback((index: number | undefined) => {
    if (index == undefined) {
      setExtendedRegularRow(undefined);
    } else {
      setExtendedRegularRow(v => (v === index ? undefined : index));
    }
  }, []);

  const handleSetExtendedOrganicRow = useCallback((index: number | undefined) => {
    if (index == undefined) {
      setExtendedOrganicRow(undefined);
    } else {
      setExtendedOrganicRow(v => (v === index ? undefined : index));
    }
  }, []);

  const handleLoadRecords = useCallback(async () => {
    setInitialLoading(true);
    setExtendedRegularRow(undefined);
    setExtendedOrganicRow(undefined);
    setErrorMessage(undefined);

    try {
      const { isSuccess, errorMessage, data } = await ApiBot.getCexBotConfig({
        pairId: cexPair.id,
        bot: ECexBot.wash_trading,
      });

      if (isSuccess && data) {
        if (data.wt_options?.volumes_tasks) {
          const sortedTasks = data.wt_options.volumes_tasks
            .sort((a, b) => {
              const dateEndsA = new Date(a.end_at ?? 0).valueOf();
              const dateEndsB = new Date(b.end_at ?? 0).valueOf();

              if (dateEndsA < dateEndsB) return -1;

              if (dateEndsA === dateEndsA) return 0;

              return 1;
            })
            .sort((a, b) => {
              if (a.enabled && !b.enabled) return -1;

              if (a.enabled === b.enabled) return 0;

              return 1;
            });

          setRegularTasks(sortedTasks);
        } else {
          setRegularTasks(undefined);
        }

        if (data.wt_options?.organic_volumes_tasks) {
          const sortedTasks = data.wt_options.organic_volumes_tasks
            .sort((a, b) => {
              const dateEndsA = new Date(a.end_at ?? 0).valueOf();
              const dateEndsB = new Date(b.end_at ?? 0).valueOf();

              if (dateEndsA < dateEndsB) return -1;

              if (dateEndsA === dateEndsA) return 0;

              return 1;
            })
            .sort((a, b) => {
              if (a.enabled && !b.enabled) return -1;

              if (a.enabled === b.enabled) return 0;

              return 1;
            });

          const _timeframes = uniq(sortedTasks.map(el => el.options.time_frame));
          setTimeframes(
            _timeframes
              .map(timeframe => {
                const find = WT_TIMEFRAMES.find(
                  el => durationToMs(el.value) === durationToMs(timeframe),
                );

                if (find) {
                  return find.value;
                } else return null;
              })
              .filter(el => el !== null) as string[],
          );

          setOrganicTasks(sortedTasks);
        } else {
          setOrganicTasks(undefined);
        }

        setErrorMessage(undefined);
      } else {
        setRegularTasks(undefined);
        setOrganicTasks(undefined);
        setErrorMessage(errorMessage ?? undefined);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        'You get an error while trying to get buy sell bot records. Try to refresh your page...',
      );
    } finally {
      setInitialLoading(false);
    }
  }, [cexPair.id]);

  useEffect(() => {
    handleLoadRecords();
  }, [handleLoadRecords]);

  return (
    <CexWashAlgorithmContext.Provider
      value={{
        initialLoading: { get: initialLoading, set: setInitialLoading },
        taskLoading: { get: taskLoading, set: setTaskLoading },
        regularEnableLoading: { get: regularEnableLoading, set: setRegularEnableLoading },
        organicEnableLoading: { get: organicEnableLoading, set: setOrganicEnableLoading },
        regularTasks: { get: regularTasks, set: setRegularTasks },
        organicTasks: { get: organicTasks, set: setOrganicTasks },
        handleLoadRecords,
        errorMessage,
        extendedRegularRow: { get: extendedRegularRow, set: handleSetExtendedRegularRow },
        extendedOrganicRow: { get: extendedOrganicRow, set: handleSetExtendedOrganicRow },
        organicCandles: candles,
      }}
    >
      {children}
    </CexWashAlgorithmContext.Provider>
  );
};
