import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { LocalStorage } from 'tools';
import { useTypedSelector } from 'store';
import { IUserRole } from 'api/apiUsers/models';
import { IUserMe } from 'store/slices/user/models';

interface IPrivateRouteProps {
  children: React.ReactNode;
  roles: IUserRole[];
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children, roles }) => {
  const navigate = useNavigate();

  const user = useTypedSelector(store => store.user.user) || LocalStorage.get<IUserMe>('user');

  useEffect(() => {
    if (!user) navigate('/auth');

    if (user && !roles.includes(user.role)) navigate('/');

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <>{children}</>;
};

export { PrivateRoute };
