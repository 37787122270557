import { useCallback, useEffect, useState } from 'react';

import { ApiCharts } from 'api';
import { durationToMs } from 'utils/duration';
import { ICexName } from 'types';
import { IKattanaCexChartResponse } from 'api/apiCharts/models';

interface IProps {
  timeframes: string[];
  period: number;
  cex: ICexName;
  pair: string;
}

const timeframeToRange = ({
  timeframe,
  candles,
  period,
}: {
  timeframe: string;
  candles: number;
  period: number;
}) => {
  const ms = durationToMs(timeframe);

  return {
    end: Date.now(),
    start: Date.now() - ms * candles + period,
  };
};

export const useCexCandlesByTimeframe = ({ timeframes, period, cex, pair }: IProps) => {
  const [resultCandles, setResultCandles] = useState<Record<string, IKattanaCexChartResponse>>({});

  const setupKattanaTimeframes = useCallback(async () => {
    try {
      if (timeframes.length === 0) return;

      const ranges = timeframes.map(timeframe => {
        const tf = durationToMs(timeframe);

        const tfsCount = Math.ceil((24 * 60 * 60 * 1000) / tf);
        const limit = tfsCount + period;

        return {
          timeframe,
          ...timeframeToRange({ timeframe, candles: limit, period }),
          limit,
        };
      });

      const results = await Promise.all(
        ranges.map(el =>
          ApiCharts.getKattanaCexPairChart({
            cex,
            startTime: el.start,
            endTime: el.end,
            limit: el.limit,
            timeframe: el.timeframe,
            pair,
          }),
        ),
      );

      const _candles = ranges.reduce(
        (acc, val, idx) => ({
          ...acc,
          [val.timeframe]: results[idx].data ?? [],
        }),
        {},
      );

      setResultCandles(_candles);
    } catch (error) {
      console.log('error: ', error);
    }
  }, [timeframes, period, cex, pair]);

  useEffect(() => {
    setupKattanaTimeframes();
  }, [setupKattanaTimeframes]);

  return {
    candles: resultCandles,
  };
};
