export const formatStringToNaturalNumber = ({
  string,
}: {
  string: string | undefined;
}): { value: string | undefined; formatedValue: string | undefined } => {
  if (string === undefined) return { value: undefined, formatedValue: undefined };

  if (string === '0' || string === '') return { value: '', formatedValue: '' };

  const resultStringWithoutChars = string
    .split('')
    .filter(char => char.match(/[0-9]$/))
    .join('');

  return { value: resultStringWithoutChars, formatedValue: resultStringWithoutChars };
};
