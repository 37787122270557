import React, { useCallback, useEffect, useMemo, useRef, Dispatch, SetStateAction } from 'react';
import { ColumnDef, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';

import { Spinner, FiltersPopup, TableToolbar, TableSelectedCaption } from 'ui';
import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { EExchange } from 'types';
import { EDexPairFilters } from 'types/filters';

import { WalletsHead, TokenBaseHead, TokenQuoteHead, TokenFeeHead, TotalHead } from './headers';
import { WalletsCell, TokenBaseCell, TokenQuoteCell, TokenFeeCell, TotalCell } from './columns';
import { StaticTable, TBody } from '../../../common';

import './style.scss';

interface IConnectWalletBalanceTaskTableProps {
  mode: 'disconnect' | 'connect';
  readOnlyMode: boolean | undefined;
  selectedRecords: IBalanceBotWallet[];
  records: {
    get: IBalanceBotWallet[] | undefined;
    set: Dispatch<SetStateAction<IBalanceBotWallet[] | undefined>>;
  };
  rowSelection: {
    get: Record<number, boolean>;
    set: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  };
  hasMore: boolean;
  loading: boolean;
  handleLoadMore: () => void;
}

const ConnectWalletBalanceTaskTable: React.FC<IConnectWalletBalanceTaskTableProps> = ({
  readOnlyMode = false,
  records,
  mode,
  rowSelection,
  selectedRecords,
  hasMore,
  loading,
  handleLoadMore,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo<ColumnDef<IBalanceBotWallet>[]>(
    () => [
      {
        id: 'select',
        accessorFn: row => row,
        header: ({ table }) => <WalletsHead table={table} readOnlyMode={readOnlyMode} />,
        cell: ({ row }) => {
          return <WalletsCell row={row} readOnlyMode={readOnlyMode} />;
        },
      },
      {
        id: 'token_base',
        accessorFn: row => row,
        header: () => <TokenBaseHead />,
        cell: ({ row }) => {
          return <TokenBaseCell row={row} />;
        },
      },
      {
        id: 'token_quote',
        accessorFn: row => row,
        header: () => <TokenQuoteHead />,
        cell: ({ row }) => {
          return <TokenQuoteCell row={row} />;
        },
      },
      {
        id: 'token_fee',
        accessorFn: row => row,
        header: () => <TokenFeeHead />,
        cell: ({ row }) => {
          return <TokenFeeCell row={row} />;
        },
      },
      {
        id: 'total',
        accessorFn: row => row,
        header: () => <TotalHead />,
        cell: ({ row }) => {
          return <TotalCell row={row} />;
        },
      },
    ],
    [readOnlyMode],
  );

  const table = useReactTable({
    data: useMemo(() => records.get ?? [], [records.get]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: rowSelection.set,
    state: {
      rowSelection: rowSelection.get,
    },
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      handleLoadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      handleLoadMore();
    }
  }, [rowVirtualizer.virtualItems, hasMore, loading, handleLoadMore, rows.length]);

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  return (
    <div className="mm-connect-dex-wallets-bb-container">
      {!readOnlyMode && (
        <TableToolbar
          customFilters={
            <FiltersPopup exchange={EExchange.dex} type={EDexPairFilters.boostHoldersWallets} />
          }
          customActions={
            <TableSelectedCaption
              selected={selectedRecords.length}
              totalRecords={records.get?.length ?? 0}
            />
          }
        />
      )}
      {!!loading && (records.get === undefined || records.get.length === 0) && (
        <div className="table-loading">
          <Spinner size="medium" />
        </div>
      )}
      {records.get === undefined && !loading && !hasMore && (
        <div className="mm-common-table-no-info">Records not found</div>
      )}
      {records.get && records.get.length === 0 && !loading && (
        <div className="mm-common-table-no-info">
          {mode === 'connect' ? 'Not found wallets to connect' : 'Not found wallets to disconnect'}
        </div>
      )}
      {records.get && records.get.length !== 0 && (
        <div className="table-container scrollable" ref={tableContainerRef}>
          <StaticTable className="mm-common-table">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <TBody paddingTop={paddingTop} paddingBottom={paddingBottom}>
              {virtualRows.map(virtualRow => {
                const row = rows[virtualRow.index];

                return (
                  <tr key={virtualRow.index}>
                    {row ? (
                      row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id} style={{ height: virtualRow.size }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })
                    ) : (
                      <td style={{ height: virtualRow.size }}>-</td>
                    )}
                  </tr>
                );
              })}
            </TBody>
          </StaticTable>
        </div>
      )}
    </div>
  );
};

export { ConnectWalletBalanceTaskTable };
