import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ColumnDef, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import cn from 'classnames';
import { isNil } from 'lodash';
import { useTypedDispatch } from 'store';

import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { DexWTRegularTaskModal } from 'modals/DexWTRegularTaskModal';
import { IDexWTBotVolumeStrategyTask } from 'types/bots';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import {
  DailyFeeCell,
  DailyVolumeCell,
  GroupPauseCell,
  GroupTransactionsCell,
  SerieDurationCell,
  SeriePauseCell,
  SerieVolumeCell,
  StartConditionCell,
  ActionsCell,
} from './columns';

import './style.scss';

const RegularVolumesTable: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { currentConfig } = useContext(PairWashTradingContext);

  const [editTaskIndex, setEditTaskIndex] = useState<number | undefined>(undefined);

  const tasks = useMemo<IDexWTBotVolumeStrategyTask[]>(
    () => [...(currentConfig.get ? currentConfig.get.config?.volumes_strategy?.tasks ?? [] : [])],
    [currentConfig.get],
  );

  const onEditTask = useCallback(
    (task: IDexWTBotVolumeStrategyTask) => {
      if (isNil(editTaskIndex)) return;

      const newTasks = currentConfig.get?.config.volumes_strategy?.tasks ?? [];
      newTasks[editTaskIndex] = task;

      currentConfig.set(
        currentConfig.get
          ? {
              ...currentConfig.get,
              config: {
                ...currentConfig.get.config,
                volumes_strategy: {
                  tasks: newTasks,
                },
              },
            }
          : undefined,
      );

      dispatch(
        setAlertState({
          type: 'success',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
          text: 'You have successfully edited task!',
        }),
      );
    },
    [editTaskIndex, currentConfig, dispatch],
  );

  const onOpenEditTask = useCallback((idx: number) => {
    setEditTaskIndex(idx);
  }, []);

  const onDeleteTask = useCallback(
    (idx: number) => {
      dispatch(
        setAlertState({
          type: 'sure',
          text: 'Are you sure that you want to delete task?',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            let newTasks = currentConfig.get?.config.volumes_strategy?.tasks ?? [];
            newTasks = newTasks.filter((_, index) => index !== idx);

            dispatch(dropAlertState());
            currentConfig.set(
              currentConfig.get
                ? {
                    ...currentConfig.get,
                    config: {
                      ...currentConfig.get.config,
                      volumes_strategy: {
                        tasks: newTasks,
                      },
                    },
                  }
                : undefined,
            );
          },
        }),
      );
    },
    [dispatch, currentConfig],
  );

  const columns = useMemo<ColumnDef<IDexWTBotVolumeStrategyTask>[]>(
    () => [
      {
        id: 'start-condition',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Start condition</div>,
        cell: ({ row: { original } }) => <StartConditionCell task={original} />,
        size: 220,
      },
      {
        id: 'serie-volume',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Serie volume</div>,
        cell: ({ row: { original } }) => <SerieVolumeCell task={original} />,
      },
      {
        id: 'serie-duration',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Serie duration</div>,
        cell: ({ row: { original } }) => <SerieDurationCell task={original} />,
      },
      {
        id: 'serie-pause',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Serie pause</div>,
        cell: ({ row: { original } }) => <SeriePauseCell task={original} />,
      },
      {
        id: 'group-pause',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Groups pause</div>,
        cell: ({ row: { original } }) => <GroupPauseCell task={original} />,
      },
      {
        id: 'group-transactions',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Group transactions</div>,
        cell: ({ row: { original } }) => <GroupTransactionsCell task={original} />,
      },
      {
        id: 'daily-volume',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Est 24 volume</div>,
        cell: ({ row: { original } }) => <DailyVolumeCell task={original} />,
        size: 200,
      },
      {
        id: 'daily-fees',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Fee 24h</div>,
        cell: ({ row: { original } }) => <DailyFeeCell task={original} />,
        size: 200,
      },
      {
        id: 'actions',
        accessorFn: row => row,
        header: () => <></>,
        size: 80,
        cell: ({ row }) => (
          <ActionsCell
            onEdit={() => onOpenEditTask(row.index)}
            onDelete={() => onDeleteTask(row.index)}
          />
        ),
      },
    ],
    [onOpenEditTask, onDeleteTask],
  );

  const table = useReactTable({
    data: tasks,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <table className={cn('mm-static-table', 'mm-dex-wt-regular-volumes-table')}>
        {tasks.length !== 0 && (
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody>
          {tasks.length === 0 && (
            <tr>
              <td colSpan={9}>
                <div className="no-active-records">
                  <span>There are no tasks</span>
                </div>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map(row => {
            return (
              <React.Fragment key={row.index}>
                <tr key={row.index}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
                <tr className="tr-separator" />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {!isNil(editTaskIndex) && (
        <DexWTRegularTaskModal
          mode="edit"
          onClose={() => setEditTaskIndex(undefined)}
          task={tasks[editTaskIndex]}
          onSubmit={onEditTask}
        />
      )}
    </>
  );
};

export { RegularVolumesTable };
