import React, { useContext, useMemo } from 'react';
import { capitalize, isNil } from 'lodash';
import dayjs from 'dayjs';
import { parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import { Modal, Button, Spinner } from 'ui';
import { CreateTaskForm } from 'ui/forms';
import { useCexCandlesByTimeframe } from 'hooks';
import { DateField, DurationField, InputField, SelectTimeframeField } from 'fields';
import { calculateATRs } from 'utils/calculates';
import { durationToMs } from 'utils/duration';
import { formatToken, normalizeNumber } from 'utils/formats';
import { ICexWTOrganicTask } from 'types/bots';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';
import { ATR_PERIOD } from 'constants/numbers';

import { useWTOrganicTask } from './useWTOrganicTask/useWTOrganicTask';

import './style.scss';

interface IProps {
  onClose: () => void;
  mode: 'create' | 'edit';
  task?: ICexWTOrganicTask;
}

export const CexWTOrganicTaskModal: React.FC<IProps> = ({ onClose, mode, task }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const { taskLoading } = useContext(CexWashAlgorithmContext);

  const { edited, values, errors, touched, setFieldValue, handleSubmit } = useWTOrganicTask({
    mode,
    onClose,
    task,
  });

  const { candles } = useCexCandlesByTimeframe({
    cex: useMemo(() => cexPair.cex, [cexPair]),
    pair: useMemo(() => cexPair.cex_id, [cexPair]),
    period: ATR_PERIOD,
    timeframes: useMemo(
      () => (values.time_frame ? [values.time_frame.value] : []),
      [values.time_frame],
    ),
  });

  const sequence = useMemo(
    () => (values.time_frame ? candles[values.time_frame.value] ?? [] : []),
    [values.time_frame, candles],
  );

  const ATRS = useMemo(() => calculateATRs({ candles: sequence, period: ATR_PERIOD }), [sequence]);

  const dailyVolume = useMemo(() => {
    if (!values.time_frame) return undefined;

    const dailyVolumeNumber =
      ATRS.reduce((acc, val) => acc + val * Number(values.base_volume ?? '0'), 0) *
      ((24 * 60 * 60 * 1000) /
        (durationToMs(values.time_frame.value) * Math.abs(sequence.length - ATR_PERIOD)));

    const normalizedNumber = normalizeNumber(dailyVolumeNumber);

    return parseUnits(normalizedNumber, 18);
  }, [values.time_frame, values.base_volume, ATRS, sequence.length]);

  return (
    <Modal
      title={`${capitalize(mode)} task (organic volume)`}
      edited={edited}
      className="mm-cex-wt-bot-organic-task-modal"
      onClose={onClose}
    >
      <CreateTaskForm handleSubmit={handleSubmit}>
        <div className="form-inner scrollable">
          <div className="form-container">
            <DateField
              label="Start time"
              date={values?.start_at ? values.start_at : null}
              setDate={value =>
                setFieldValue(
                  'start_at',
                  value ? dayjs(value).second(0).millisecond(0).valueOf() : null,
                )
              }
              errorMessage={errors.start_at && touched.start_at ? errors.start_at : undefined}
            />
            <DateField
              label="End time"
              date={values?.end_at ? values.end_at : null}
              setDate={value =>
                setFieldValue(
                  'end_at',
                  value ? dayjs(value).second(0).millisecond(0).valueOf() : null,
                )
              }
            />
            <InputField
              label="Base volume"
              type="decimal-number"
              value={values.base_volume}
              setValue={v => setFieldValue('base_volume', v ? v : '')}
              errorMessage={
                errors.base_volume && touched.base_volume ? errors.base_volume : undefined
              }
            />
            <div className="inputs-row">
              <InputField
                value={values.indicator_period}
                type="decimal-number"
                setValue={v => {
                  setFieldValue('indicator_period', v);
                }}
                label="Indicator period"
                id="indicator_period"
                errorMessage={
                  errors.indicator_period && touched.indicator_period
                    ? errors.indicator_period
                    : undefined
                }
              />
              <InputField
                value={values.base_volume_deviation}
                type="decimal-number"
                setValue={v => {
                  setFieldValue('base_volume_deviation', v);
                }}
                label="Volume deviation"
                id="base_volume_deviation"
                errorMessage={
                  errors.base_volume_deviation && touched.base_volume_deviation
                    ? errors.base_volume_deviation
                    : undefined
                }
              />
            </div>
            <SelectTimeframeField
              items={WT_TIMEFRAMES}
              onSelectItem={v => setFieldValue('time_frame', v)}
              label="Timeframe"
              selectedItem={values.time_frame ?? undefined}
              errorMessage={errors.time_frame && touched.time_frame ? errors.time_frame : undefined}
            />
            <DurationField
              label="Close bar before"
              value={values?.close_bar_before ? values.close_bar_before : null}
              setValue={value => setFieldValue('close_bar_before', isNil(value) ? null : value)}
              errorMessage={
                errors.close_bar_before && touched.close_bar_before
                  ? errors.close_bar_before
                  : undefined
              }
            />
            <div>
              <div className="text-main-purple font-bold text-sm">Orders per serie</div>
              <div className="inputs-row">
                <InputField
                  value={values.orders_min}
                  type="natural-number"
                  setValue={v => {
                    setFieldValue('orders_min', v);
                  }}
                  label="Minimum"
                  id="orders_min"
                  errorMessage={
                    errors.orders_min && touched.orders_min ? errors.orders_min : undefined
                  }
                />
                <InputField
                  value={values.orders_max}
                  type="natural-number"
                  setValue={v => {
                    setFieldValue('orders_max', v);
                  }}
                  label="Maximum"
                  id="orders_max"
                  errorMessage={
                    errors.orders_max && touched.orders_max ? errors.orders_max : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Pause between series</div>
              <div className="inputs-row">
                <DurationField
                  value={values.pause_between_series_min}
                  setValue={v => {
                    setFieldValue('pause_between_series_min', isNil(v) ? null : v);
                  }}
                  label="Minimum"
                  id="pause_between_series_min"
                  errorMessage={
                    errors.pause_between_series_min && touched.pause_between_series_min
                      ? errors.pause_between_series_min
                      : undefined
                  }
                />
                <DurationField
                  value={values.pause_between_series_max}
                  setValue={v => {
                    setFieldValue('pause_between_series_max', isNil(v) ? null : v);
                  }}
                  label="Maximum"
                  id="pause_between_series_max"
                  errorMessage={
                    errors.pause_between_series_max && touched.pause_between_series_max
                      ? errors.pause_between_series_max
                      : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Pause before cancel</div>
              <div className="inputs-row">
                <DurationField
                  value={values.cancel_delay_min}
                  setValue={v => {
                    setFieldValue('cancel_delay_min', isNil(v) ? null : v);
                  }}
                  label="Minimum"
                  id="cancel_delay_min"
                  errorMessage={
                    errors.cancel_delay_min && touched.cancel_delay_min
                      ? errors.cancel_delay_min
                      : undefined
                  }
                />
                <DurationField
                  value={values.cancel_delay_max}
                  setValue={v => {
                    setFieldValue('cancel_delay_max', isNil(v) ? null : v);
                  }}
                  label="Maximum"
                  id="cancel_delay_max"
                  errorMessage={
                    errors.cancel_delay_max && touched.cancel_delay_max
                      ? errors.cancel_delay_max
                      : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Amount deviation per order</div>
              <div className="inputs-row">
                <InputField
                  value={values.per_order_amount_dev_min}
                  type="decimal-number"
                  setValue={v => {
                    setFieldValue('per_order_amount_dev_min', v);
                  }}
                  label="Minimum"
                  id="per_order_amount_dev_min"
                  errorMessage={
                    errors.per_order_amount_dev_min && touched.per_order_amount_dev_min
                      ? errors.per_order_amount_dev_min
                      : undefined
                  }
                />
                <InputField
                  value={values.per_order_amount_dev_max}
                  type="decimal-number"
                  setValue={v => {
                    setFieldValue('per_order_amount_dev_max', v);
                  }}
                  label="Maximum"
                  id="per_order_amount_dev_max"
                  errorMessage={
                    errors.per_order_amount_dev_max && touched.per_order_amount_dev_max
                      ? errors.per_order_amount_dev_max
                      : undefined
                  }
                />
              </div>
            </div>
            <InputField
              value={values.ignore_volume_percent}
              type="decimal-number"
              setValue={v => {
                setFieldValue('ignore_volume_percent', v);
              }}
              label="Ignore percent amount %"
              id="ignore_volume_percent"
              errorMessage={
                errors.ignore_volume_percent && touched.ignore_volume_percent
                  ? errors.ignore_volume_percent
                  : undefined
              }
            />
          </div>
          {dailyVolume && (
            <div className="daily-container">
              <div className="daily-container__daily-volume">
                <span>Daily volume:</span>
                <span>
                  {formatToken(dailyVolume, 18)} {cexPair.token_base.symbol}
                </span>
              </div>
              <div className="daily-container__daily-free-costs">
                <span>Daily fee costs: </span>
                <span>??? USDT</span>
              </div>
            </div>
          )}
        </div>
        <div className="task-actions">
          {taskLoading.get && (
            <div className="spinner-container">
              <Spinner size="small" />
            </div>
          )}
          {!taskLoading.get && (
            <Button type="submit">{mode === 'create' ? 'Create task' : 'Save'}</Button>
          )}
        </div>
      </CreateTaskForm>
    </Modal>
  );
};
