import React from 'react';

import './pairPageWrapper.scss';

interface IPairPageWrapperProps {
  children: React.ReactNode;
}

const PairPageWrapper: React.FC<IPairPageWrapperProps> = ({ children }) => {
  return <div className="mm-pair-page-wrapper">{children}</div>;
};

export { PairPageWrapper };
