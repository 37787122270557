import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';
import { BotEnableStatus, Panel } from 'ui';
import { AccountsIcon } from 'assets/icons/AccountsIcon';

import { CexBotStatusToggler } from 'common/bots';
import { ECexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';
import { Content } from './components';

import './cexFillTheGaps.scss';

const CexFillTheGaps: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[cexPair.id],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[ECexBot.fill_the_gaps] : false),
    [pairConnectedBots],
  );

  return (
    <Panel
      title="Fill the gaps"
      icon={AccountsIcon}
      initialOpened={initialOpened}
      onChange={setIsOpened}
      headerNode={<BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />}
      className="mm-panel"
      switcher={<CexBotStatusToggler bot={ECexBot.fill_the_gaps} />}
    >
      <Content />
    </Panel>
  );
};

export { CexFillTheGaps };
