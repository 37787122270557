import React, { useCallback, useState } from 'react';
import { TableAction, TableActionIcon } from 'ui';

import { PlusIcon } from 'assets/icons';
import { CexWTRegularTaskModal } from 'modals/CexWTRegularTaskModal';

import './toolbar.scss';

const RegularVolumeToolbar: React.FC = () => {
  const [createNewTaskModalOpened, setCreateNewTaskModalOpened] = useState<boolean>(false);

  const handleCreateTask = useCallback(() => {
    setCreateNewTaskModalOpened(true);
  }, []);

  return (
    <div className="wash-algorithm-header">
      <div className="wash-algorithm-header__tab-title">Regular volume tasks</div>
      <TableAction
        iconLeft={<TableActionIcon icon={PlusIcon} />}
        text={'Create Regular volume task'}
        onClick={handleCreateTask}
      />
      {createNewTaskModalOpened && (
        <CexWTRegularTaskModal mode="create" onClose={() => setCreateNewTaskModalOpened(false)} />
      )}
    </div>
  );
};

export { RegularVolumeToolbar };
