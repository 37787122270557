import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { IBaseResponse, Response } from '../apiBase';
import { AApiBase } from '../apiBase/AApiBase';
import { ICexNotConnectedAccounts } from './models/ICexAccount';

class ApiCexAccount extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiCexAccount | undefined;
  private static instance = (() => {
    if (ApiCexAccount.__instance === undefined) {
      ApiCexAccount.__instance = new ApiCexAccount();
    }
    return ApiCexAccount.__instance;
  })();

  public static getCexAccountsList = async ({
    limit,
    lastSeenId,
    cexId,
  }: {
    limit: number;
    lastSeenId: number;
    cexId: number;
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<{
      has_next: boolean;
      items: ICexNotConnectedAccounts[];
    }>;
    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('last_seen_id', lastSeenId.toString());
    try {
      const response = await ApiCexAccount.instance.get<IBaseResponse<any>>(
        `/cex-pairs/${cexId}/accounts` + '?' + urlParams.toString(),
      );
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static getNotConnectedCexAccounts = async ({
    cexId,
    limit,
    lastSeenId,
  }: {
    cexId: number;
    limit: number;
    lastSeenId: number;
  }): Promise<
    Response<{
      has_next: boolean;
      items: ICexNotConnectedAccounts[];
    }>
  > => {
    let mappedResponseOrError: Response<{
      has_next: boolean;
      items: ICexNotConnectedAccounts[];
    }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('last_seen_id', lastSeenId.toString());

    try {
      const response = await ApiCexAccount.instance.get<
        IBaseResponse<{
          has_next: boolean;
          items: ICexNotConnectedAccounts[];
        }>
      >(`/cex-pairs/${cexId}/accounts/not-connected` + '?' + urlParams.toString());
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{
        has_next: boolean;
        items: ICexNotConnectedAccounts[];
      }>({ error });
    }

    return mappedResponseOrError;
  };

  public static connectAccountToCexPair = async ({
    cexId,
    accounts,
  }: {
    cexId: number;
    accounts: { bots_ids: number[]; id: number; is_disabled: boolean }[];
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;
    try {
      const response = await ApiCexAccount.instance.put<IBaseResponse<any>>(
        `/cex-pairs/${cexId}/accounts`,
        {
          accounts,
        },
      );
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };
  public static deleteAccountFromCexPair = async ({
    cexId,
    accounts_ids,
  }: {
    cexId: number;
    accounts_ids: number[];
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;
    try {
      const response = await ApiCexAccount.instance.delete<IBaseResponse<any>>(
        `/cex-pairs/${cexId}/accounts`,
        {
          data: {
            accounts_ids: accounts_ids,
          },
        },
      );
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiCexAccount };
