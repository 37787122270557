import React, { useCallback } from 'react';
import cn from 'classnames';

import './tableAction.scss';

interface ITableActionProps {
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  customClassName?: string;
}

const TableAction: React.FC<ITableActionProps> = ({
  iconLeft: IconLeft,
  iconRight: IconRight,
  text,
  onClick,
  disabled = false,
  customClassName,
}) => {
  const handleClick = useCallback(() => {
    if (disabled) return;

    if (onClick) onClick();
  }, [onClick, disabled]);

  return (
    <div
      className={cn('mm-common-table-action', customClassName && customClassName, {
        _is_disabled: disabled,
      })}
      onClick={handleClick}
    >
      {IconLeft && IconLeft}
      <span className="mm-common-table-action__text">{text}</span>
      {IconRight && IconRight}
    </div>
  );
};

export { TableAction };
