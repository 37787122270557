const isTxAddress = (value: any): boolean => {
  if (!value || value.length !== 66 || value === '' || value === '0x') {
    return false;
  }

  try {
    const address = value.toLowerCase();
    if (address.length !== 66) return false;

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export { isTxAddress };
