import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';

import { IWallet } from 'api/apiWallets/models';
import { calculateTokensUsdBalance } from 'utils/calculates';
import { formatFiat } from 'utils/formats';

interface IBalanceCellProps {
  row: Row<IWallet>;
}

const BalanceCell: React.FC<IBalanceCellProps> = React.memo(({ row }) => {
  const totalBalance = useMemo(() => calculateTokensUsdBalance(row.original.tokens), [row]);

  if (!row.original.tokens) return <div style={{ textAlign: 'center' }}>{'$0'}</div>;

  return <div style={{ textAlign: 'center' }}>{formatFiat(totalBalance, 18)}</div>;
});

export default BalanceCell;
