import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';

import { Spinner, Switcher } from 'ui';
import { EExchange } from 'types';
import { ECexBot } from 'types/bots';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { getPairLinkedBots } from 'utils/getPairLinkedBots';
import { ApiBot } from 'api';

interface ICexBotStatusTogglerProps {
  bot: ECexBot;
}

const CexBotStatusToggler: React.FC<ICexBotStatusTogglerProps> = ({ bot }) => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[cexPair.id],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[bot] : false),
    [pairConnectedBots, bot],
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(isBotEnabled);

  useEffect(() => {
    setIsEnabled(isBotEnabled);
  }, [isBotEnabled]);

  const loadBotSettings = useCallback(async () => {
    try {
      const { isSuccess, data, errorMessage } = await ApiBot.getCexBotConfig({
        pairId: cexPair.id,
        bot: bot,
      });

      if (isSuccess && data) return data;
      else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage ?? 'Something went wrong...',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }, [cexPair.id, dispatch, bot]);

  const handleToggleEnableStatus = useCallback(
    async (_isEnabled: boolean) => {
      try {
        setLoading(true);
        setIsEnabled(_isEnabled);

        const botSettings = await loadBotSettings();

        const { isSuccess, errorMessage } = await ApiBot.saveCexBotConfig({
          pairId: cexPair.id,
          bot: bot,
          body: {
            ...botSettings,
            is_enabled: _isEnabled,
          },
        });

        if (isSuccess) {
          getPairLinkedBots({ pairId: cexPair.id, dispatch, exchange: EExchange.cex });
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage ?? 'Something went wrong...',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        }
      } catch (error) {
        console.log('error');
      } finally {
        setLoading(false);
      }
    },
    [cexPair, dispatch, loadBotSettings, bot],
  );

  return (
    <>
      {loading ? (
        <Spinner size="mini" />
      ) : (
        <Switcher value={isEnabled} onChange={value => handleToggleEnableStatus(value)} />
      )}
    </>
  );
};

export { CexBotStatusToggler };
