export enum ETransactionAction {
  ActionBuy = 'buy',
  ActionSell = 'sell',
  ActionDeposit = 'deposit',
  ActionWithdrawal = 'withdrawal',
  ActionApproval = 'approval',
  ActionBuyEth = 'buy_eth',
  ActionBuyEthHolder = 'buy_eth_holder',
  ActionSellEth = 'sell_eth',
  ActionBulkBuy = 'bulk_buy',
  ActionBulkSell = 'bulk_sell',
  ActionBulkBuyMempool = 'bulk_buy_mempool',
  ActionBulkSellMempool = 'bulk_sell_mempool',
  ActionAddLiquidity = 'add_liquidity',
  ActionRemoveLiquidity = 'remove_liquidity',
  ActionAFRBuy = 'afr_buy',
  ActionAFRSell = 'afr_sell',
}
