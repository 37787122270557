import React from 'react';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';

interface ISerieDurationCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const SerieDurationCell: React.FC<ISerieDurationCellProps> = ({ task }) => {
  return (
    <div>
      {task.min_series_duration} / {task.max_series_duration}
    </div>
  );
};

export { SerieDurationCell };
