import React from 'react';
import dayjs from 'dayjs';

import { ICexOrder } from 'types/orders';

interface ITimeProps {
  time: string;
}

const Time = React.memo(({ time }: ITimeProps) => {
  return <div style={{ textAlign: 'left' }}>{time}</div>;
});

const timeAccessor = (row: ICexOrder) => dayjs(row.created_at).format('LTS L');

Time.displayName = 'Time';

export { Time, timeAccessor };
