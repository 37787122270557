import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';

import { ApiPairs } from 'api';
import { filtersToValues } from 'utils/filters';
import { IDexFilterValue } from 'types/filters/common';
import { dexPairWalletsFiltersScheme } from 'types/filters/dex_pair';

const useDexWallets = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const getter = useCallback(
    async ({
      limit,
      lastSeenId,
      filters,
    }: {
      limit: number;
      lastSeenId: number;
      filters: IDexFilterValue[];
    }) => {
      const filterValuesFlat = filtersToValues({
        filters: filters,
        scheme: dexPairWalletsFiltersScheme(dexPair),
      });

      const filterValues = filterValuesFlat.reduce((acc, val) => ({ ...acc, ...val }), {});

      const { isSuccess, errorMessage, data } = await ApiPairs.getWalletsConnectedToPair({
        pairId: dexPair.id,
        lastSeenId,
        limit,
        balance: filterValues.balance,
        volume: filterValues.volume,
        transactions_count: filterValues.transactions_count,
        is_disabled: isNil(filterValues.is_disabled)
          ? undefined
          : `${!(filterValues.is_disabled === 'true')}`,
      });

      return { isSuccess, errorMessage, data };
    },
    [dexPair],
  );

  return getter;
};

export { useDexWallets };
