import React, { HTMLAttributes, useMemo } from 'react';
import cn from 'classnames';

import './style.scss';

interface IStatisTableProps extends HTMLAttributes<HTMLTableElement> {
  children?: React.ReactNode;
}

const StaticTable: React.FC<IStatisTableProps> = ({ children, ...rest }) => {
  const className = useMemo(() => cn('mm-static-table', rest.className ?? ''), [rest.className]);

  return (
    <table {...rest} className={className}>
      {children}
    </table>
  );
};

export { StaticTable };
