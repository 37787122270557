import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { formatToken } from 'utils/formats';
import { IPairWallet } from 'api/apiPairs/models';

interface ITokenQuoteCellProps {
  row: Row<IPairWallet>;
}

const TokenQuoteCell: React.FC<ITokenQuoteCellProps> = React.memo(({ row }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const tokenQuoteId = useMemo(() => pair?.token_quote.id, [pair]);
  const rowTokenQuoteInfo = useMemo(
    () => row.original.tokens?.find(token => token.token.id === tokenQuoteId),
    [row, tokenQuoteId],
  );

  if (!tokenQuoteId || !rowTokenQuoteInfo) return <div style={{ textAlign: 'center' }}>0</div>;

  return (
    <div style={{ textAlign: 'center' }}>
      {formatToken(BigNumber.from(rowTokenQuoteInfo.balance), rowTokenQuoteInfo.token.decimals)}
    </div>
  );
});

export default TokenQuoteCell;
