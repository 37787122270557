import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import dayjs, { Dayjs } from 'dayjs';

import { Modal } from '../Modal/Modal';
import { Button } from '../Button/Button';

import './rangeCalendar.scss';
import 'react-calendar/dist/Calendar.css';

interface IRangeCalendarProps {
  onClose: () => void;
  title: string;
  handleDefaultTotal?: () => void;
  handleFilter: ([date1, date2]: [Dayjs | null, Dayjs | null]) => void;
  initialStartDate: Dayjs | null;
  initialEndDate: Dayjs | null;
}

const RangeCalendar: React.FC<IRangeCalendarProps> = ({
  onClose,
  title,
  handleDefaultTotal,
  handleFilter,
  initialStartDate,
  initialEndDate,
}) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(initialStartDate);
  const [endDate, setEndDate] = useState<Dayjs | null>(initialEndDate);
  const calendarModalRef = useRef<HTMLDivElement>(null);

  const onHandleFilter = useCallback(() => {
    onClose();
    handleFilter([startDate, endDate]);
  }, [onClose, handleFilter, startDate, endDate]);

  const onHandleDefaultTotal = useCallback(() => {
    onClose();
    if (handleDefaultTotal) {
      handleDefaultTotal();
    }
  }, [onClose, handleDefaultTotal]);

  useEffect(() => {
    setStartDate(initialStartDate);
  }, [initialStartDate]);

  useEffect(() => {
    setEndDate(initialEndDate);
  }, [initialEndDate]);

  const keyDownEvent = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (event.key === 'Escape' || event.key === 'Enter') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (calendarModalRef.current) {
      calendarModalRef.current.focus();
    }
  }, [calendarModalRef]);

  return (
    <Modal onClose={onClose} title={title}>
      <div
        className="mm-range-calendar-modal"
        ref={calendarModalRef}
        tabIndex={0}
        onKeyDown={keyDownEvent}
      >
        <ReactCalendar
          locale="en"
          selectRange
          value={[startDate ? startDate.toDate() : null, endDate ? endDate.toDate() : null]}
          onChange={(values: [Date] | [Date, Date]) => {
            setStartDate(dayjs(values[0]));
            setEndDate(values[1] ? dayjs(values[1]) : dayjs(values[0]));
          }}
        />
        <div className="mm-range-calendar-modal__selected-dates">
          <p>Start date: {startDate ? dayjs(startDate).format('L') : 'unselected'}</p>
          <p>End date: {endDate ? dayjs(endDate).format('L') : 'unselected'}</p>
        </div>
        <div className="mm-range-calendar-modal__buttons">
          <Button color="primary" onClick={onHandleFilter}>
            Sumbit
          </Button>
          {handleDefaultTotal && (
            <Button color="secondary" onClick={onHandleDefaultTotal}>
              Default (Total)
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { RangeCalendar };
