import { useMemo, useState, useContext, useCallback, useEffect } from 'react';
import { FormikProps, useFormik } from 'formik';
import { normalizeBigNumber } from 'utils/formulas';
import { validationSchema } from './addHolderModalShema';
import { useTypedDispatch, useTypedSelector } from 'store';
import { IDexBalanceBotTask } from 'types/bots';
import { IPairBalanceBotContext, PairBalanceBotContext } from 'context/PairBalanceBotContext';
import { addBalanceTask } from './addBalanceTask';
import { durationToMs } from 'utils/duration';

interface IUseCreateNewTaskModalProps {
  onClose?: (balanceBotTask?: IDexBalanceBotTask | undefined) => void;
  task?: IDexBalanceBotTask;
  mode: 'create' | 'edit';
}

export interface IBalanceHolderInitialValues {
  min_amount: string;
  max_amount: string;
  min_pause: number | undefined;
  max_pause: number | undefined;
  sourceWallets: number;
  destinationWallets: number;
  buyback_enabled: boolean;
  buyback_min_pause: number | undefined;
  buyback_max_pause: number | undefined;
  buyback_min_swaps: string;
  buyback_max_swaps: string;
}

export const useHolderBalanceTask = ({ onClose, task, mode }: IUseCreateNewTaskModalProps) => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const { handleLoadRecords, estimateFee } =
    useContext<IPairBalanceBotContext>(PairBalanceBotContext);
  const [edited, setEdited] = useState<boolean>(false);

  const initialValues: IBalanceHolderInitialValues = useMemo(() => {
    return mode === 'edit' && task
      ? {
          ...task.add_holder_options,
          min_amount: normalizeBigNumber(task.add_holder_options?.min_amount || 0, 18, 6, false),
          max_amount: normalizeBigNumber(task.add_holder_options?.max_amount || 0, 18, 6, false),
          min_pause: durationToMs(task.add_holder_options?.min_pause),
          max_pause: durationToMs(task.add_holder_options?.max_pause),
          sourceWallets: task.add_holder_options?.source_wallet_ids?.length || 0,
          destinationWallets: task.destination_wallets_count | 0,
          buyback_enabled: task.add_holder_options?.buyback_enabled || false,
          buyback_min_pause: durationToMs(task.add_holder_options?.buyback_options?.min_pause),
          buyback_max_pause: durationToMs(task.add_holder_options?.buyback_options?.max_pause),
          buyback_min_swaps: String(task.add_holder_options?.buyback_options?.min_swaps) || '',
          buyback_max_swaps: String(task.add_holder_options?.buyback_options?.max_swaps) || '',
        }
      : {
          min_amount: '',
          max_amount: '',
          min_pause: undefined,
          max_pause: undefined,
          sourceWallets: 0,
          destinationWallets: 0,

          buyback_enabled: false,
          buyback_min_pause: undefined,
          buyback_max_pause: undefined,
          buyback_min_swaps: '',
          buyback_max_swaps: '',
        };
  }, [task, mode]);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const handleAddTask = useCallback(
    (values: IBalanceHolderInitialValues): void => {
      addBalanceTask({
        task: {
          ...values,
          dispatch,
          handleLoadRecords,
          pairId: dexPair.id,
          mode,
          ...(task?.id ? { taskId: task?.id } : {}),
          onClose,
          typeTask: 'add_holder',
        },
      });
    },
    [dexPair, dispatch, handleLoadRecords, mode, onClose, task],
  );

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    validateForm,
  }: FormikProps<IBalanceHolderInitialValues> = useFormik<IBalanceHolderInitialValues>({
    initialValues,
    onSubmit: handleAddTask,
    validationSchema,
  });

  const handleSetFieldValue = useCallback(
    (
      field: string,
      value: any,
      options: { triggerEdit?: boolean; shouldValidate?: boolean } = {
        triggerEdit: false,
        shouldValidate: undefined,
      },
    ) => {
      if (options.triggerEdit) {
        setEdited(true);
      }
      setFieldValue(field, value, options.shouldValidate);
    },
    [setFieldValue],
  );

  useEffect(() => {
    const handleSetFormError = () => {
      const keys = Object.keys(errors);

      for (const key of keys) {
        const keyWithType = key as keyof typeof errors;

        if (errors[keyWithType] && touched[keyWithType]) {
          setFormError(`${errors[keyWithType]}` || undefined);
          return;
        }
      }

      setFormError(undefined);
    };

    handleSetFormError();
  }, [errors, touched]);

  return {
    initialValues,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    formError,
    validateForm,
    edited,
    estimateFee,
  };
};
