import React, { useCallback, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { IPairWallet } from 'api/apiPairs/models';
import { PairTableConnectedBotsHead } from '../../PairTableConnectedBotsHead';

interface IConnectedBotsHeadProps {
  meta: any;
}

const ConnectedBotsHead: React.FC<IConnectedBotsHeadProps> = React.memo(({ meta }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const _meta = useMemo(() => meta, [meta]);
  const isActive = useMemo(() => _meta.selectedWallets.length !== 0, [_meta]);

  const { records, setRecords } = _meta;

  const changeWallets = useCallback(
    (newSelectedRecords: IPairWallet[]) => {
      const newRecords = [...records].map(el => {
        const selectedRecord = newSelectedRecords.find(
          selectedRecord => selectedRecord.id === el.id,
        );

        if (selectedRecord) return selectedRecord;

        return el;
      });

      setRecords(newRecords);
    },
    [records, setRecords],
  );

  return (
    <PairTableConnectedBotsHead
      isActive={isActive}
      selectedWallets={_meta.selectedWallets}
      pair={pair!}
      onChangeSelectedWallets={changeWallets}
    />
  );
});

export default ConnectedBotsHead;
