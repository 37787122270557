import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import { parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import { PairChartsContext } from 'context/PairChartsContext/PairChartsContext';
import { chartFormatter } from 'utils/charts';
import { formatFiat } from 'utils/formats';
import { chartVolumeBarColor, internalVolumeBarColor } from 'constants/charts';
import { EExchange } from 'types';
import { EChartConvertedFormat } from 'types/charts';

import './style.scss';

interface IPairTooltipProps {
  open: number;
  close: number;
  high: number;
  low: number;
  volumeTotal: number;
  volumeInternal: number;
  startPrice: number | undefined;
}

const PairTooltip: React.FC<IPairTooltipProps> = ({
  close,
  high,
  low,
  open,
  volumeTotal,
  volumeInternal,
  startPrice,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const { convertedFormat, exchange } = useContext(PairChartsContext);

  const pair = useMemo(
    () => (exchange === EExchange.cex ? cexPair : dexPair),
    [cexPair, dexPair, exchange],
  );

  const baseSymbol = useMemo(() => pair.token_base.symbol, [pair]);
  const pairSymbol = useMemo(() => pair.symbol, [pair]);

  const isChartConverted = useMemo(
    () => convertedFormat.get === EChartConvertedFormat.Converted,
    [convertedFormat.get],
  );

  const pairChartTitle = useMemo(
    () =>
      isChartConverted && exchange === EExchange.dex ? `${baseSymbol}/USD (converted)` : pairSymbol,
    [baseSymbol, isChartConverted, pairSymbol, exchange],
  );

  const isGreen = useMemo(() => close >= open, [close, open]);
  const isConverted = useMemo(
    () => convertedFormat.get === EChartConvertedFormat.Converted,
    [convertedFormat.get],
  );

  const amountCN = useMemo(() => cn('amount', { green: isGreen, red: !isGreen }), [isGreen]);

  const diff = useMemo(
    () => (startPrice !== undefined ? close - startPrice : undefined),
    [close, startPrice],
  );

  const diffPercentage = useMemo(
    () =>
      startPrice !== undefined && startPrice !== 0 && diff !== undefined
        ? Number(Math.abs((diff / startPrice) * 100).toFixed(2))
        : undefined,
    [startPrice, diff],
  );

  const totalVolumeString = useMemo(() => {
    const number = chartFormatter(volumeTotal);

    const bn = parseUnits(number.toString(), 18);

    return formatFiat(bn, 18, isConverted ? '$' : '');
  }, [volumeTotal, isConverted]);

  const internalVolumeString = useMemo(() => {
    const number = chartFormatter(volumeInternal);

    const bn = parseUnits(number.toString(), 18);

    return formatFiat(bn, 18, isConverted ? '$' : '');
  }, [volumeInternal, isConverted]);

  return (
    <div className="mm-pair-pair-chart-tooltip">
      <div className="tooltip-header">
        <span className="tooltip-title">{pairChartTitle}</span>
      </div>
      <div className="pair-values">
        <div className="value">
          <span className="caption">O</span>
          <span className={amountCN}>{chartFormatter(open)}</span>
        </div>
        <div className="value">
          <span className="caption">H</span>
          <span className={amountCN}>{chartFormatter(high)}</span>
        </div>
        <div className="value">
          <span className="caption">L</span>
          <span className={amountCN}>{chartFormatter(low)}</span>
        </div>
        <div className="value">
          <span className="caption">C</span>
          <span className={amountCN}>{chartFormatter(close)}</span>
        </div>
        {diff !== undefined && diffPercentage !== undefined && (
          <div className="value">
            <span className={cn('amount', { green: diff >= 0, red: diff < 0 })}>
              {diff > 0 ? '+' : ''}
              {chartFormatter(diff)}
            </span>
            <span className={cn('amount', { green: diff >= 0, red: diff < 0 })}>
              ({diff >= 0 ? '+' : '-'}
              {diffPercentage}%)
            </span>
          </div>
        )}
      </div>
      <div className="pair-values">
        <div className="volume-value">
          <div className="round" style={{ backgroundColor: chartVolumeBarColor }} />
          <span className="caption">Total v:</span>
          <span className="amount">{totalVolumeString}</span>
        </div>
      </div>
      <div className="pair-values">
        <div className="volume-value">
          <div className="round" style={{ backgroundColor: internalVolumeBarColor }} />
          <span className="caption">Internal v:</span>
          <span className="amount">{internalVolumeString}</span>
        </div>
      </div>
    </div>
  );
};

export { PairTooltip };
