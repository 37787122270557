import React, { useMemo, useContext } from 'react';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import { IDextWTOrganicVolumeTask } from 'types/bots';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { durationToMs } from 'utils/duration';
import { calculateATRs } from 'utils/calculates';
import { dexDailyFees } from 'utils/daily';
import { normalizeNumber, formatToken, formatFiat } from 'utils/formats';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { ATR_PERIOD } from 'constants/numbers';

interface IDailyFeesCellProps {
  task: IDextWTOrganicVolumeTask;
}

const DailyFeesCell: React.FC<IDailyFeesCellProps> = ({ task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { organicCandles } = useContext(PairWashTradingContext);

  const timeframe =
    WT_TIMEFRAMES.find(el => durationToMs(el.value) === durationToMs(task.options.time_frame))
      ?.value ?? task.options.time_frame;

  const sequence = useMemo(() => organicCandles[timeframe] ?? [], [timeframe, organicCandles]);

  const ATRS = useMemo(() => calculateATRs({ candles: sequence, period: ATR_PERIOD }), [sequence]);

  const dailyVolume = useMemo(() => {
    const maxVolume = formatUnits(task.options.max_volume, dexPair.token_base.decimals);
    const minVolume = formatUnits(task.options.min_volume, dexPair.token_base.decimals);

    const avgVolume = (Number(maxVolume) + Number(minVolume)) / 2;

    const dailyVolumeNumber =
      ATRS.reduce((acc, val) => acc + val * avgVolume, 0) *
      ((24 * 60 * 60 * 1000) / (durationToMs(timeframe) * Math.abs(sequence.length - ATR_PERIOD)));

    const normalizedNumber = normalizeNumber(dailyVolumeNumber);

    return parseUnits(normalizedNumber, dexPair.token_base.decimals);
  }, [task, ATRS, sequence.length, dexPair, timeframe]);

  const dailyTxs = useMemo(() => {
    const {
      min_transactions_in_series,
      max_transactions_in_series,
      min_pause_between_series,
      max_pause_between_series,
    } = task.options;

    const avgTxs = Math.floor(
      (+(min_transactions_in_series ?? '0') + +(max_transactions_in_series ?? '0')) / 2,
    );

    const avgPause =
      (durationToMs(max_pause_between_series) + durationToMs(min_pause_between_series)) / 2;
    const dailySeries = avgPause === 0 ? 0 : Math.floor((24 * 60 * 60 * 1000) / avgPause);

    return dailySeries * avgTxs;
  }, [task.options]);

  const dailyFee = useMemo(
    () => dexDailyFees({ daily_txs: dailyTxs, daily_volume: dailyVolume, dexPair }),
    [dailyTxs, dailyVolume, dexPair],
  );

  return (
    <div>
      {formatToken(dailyFee.base.amount, 18)} {dexPair.token_base.symbol}
      {formatToken(dailyFee.fee.amount, 18) === '0'
        ? ' '
        : '+ ' + formatToken(dailyFee.fee.amount, 18) + ' ' + dexPair.token_fee.symbol}{' '}
      / {formatFiat(dailyFee.total.usd, 18)}
    </div>
  );
};

export { DailyFeesCell };
