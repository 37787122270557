export enum EFilterEntity {
  number = 'number',
  natural_number = 'natural_number',
  boolean = 'boolean',
}

export enum EFilterNumberConnection {
  equal = '=',
  gte = '>=',
  gt = '>',
  lte = '<=',
  lt = '<',
}

export enum EFilterBooleanConnection {
  is = 'is',
  is_not = 'is_not',
}

export enum EFilterFormatter {
  natural_number_token = 'natural_number_token',
  bn_number_token = 'bn_number_token',
  boolean = 'boolean',
}

export const FILTER_NUMBER_CONNECTIONS = [
  { label: '=', value: EFilterNumberConnection.equal },
  { label: '>=', value: EFilterNumberConnection.gte },
  { label: '>', value: EFilterNumberConnection.gt },
  { label: '<=', value: EFilterNumberConnection.lte },
  { label: '<', value: EFilterNumberConnection.lt },
];

export const FILTER_BOOLEAN_CONNECTIONS = [
  { label: 'Is', value: EFilterBooleanConnection.is },
  { label: 'Is not', value: EFilterBooleanConnection.is_not },
];

export interface IFilterScheme {
  type: EFilterEntity;
  label: string;
  name: string;
  formatter?: EFilterFormatter;
  tokenId?: number;
  field?: string;
  decimals?: number;
}

export type IFilterSchemeExtended =
  | IFilterScheme
  | IFilterScheme[]
  | { name: string; label: string; filters: IFilterSchemeExtended };

export interface IFilterNumberResult {
  connection: EFilterNumberConnection;
  value: string;
}

export interface IFilterBooleanResult {
  connection: EFilterBooleanConnection;
  value: boolean;
}

export interface IDexFilterValue {
  id: string;
  parentNames?: string[];
  name?: string | undefined;
  type?: EFilterEntity;
  value?: IFilterNumberResult | IFilterBooleanResult;
}
