import React from 'react';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';

interface IGroupPauseCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const GroupPauseCell: React.FC<IGroupPauseCellProps> = ({ task }) => {
  return (
    <div>
      {task.min_pause_between_groups} / {task.max_pause_between_groups}
    </div>
  );
};

export { GroupPauseCell };
