import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IWithdrawWhitelistItem, IPrepareConfirmationResponse } from './models';

class ApiWithdrawWhitelist extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiWithdrawWhitelist | undefined;
  private static instance = (() => {
    if (ApiWithdrawWhitelist.__instance === undefined) {
      ApiWithdrawWhitelist.__instance = new ApiWithdrawWhitelist();
    }
    return ApiWithdrawWhitelist.__instance;
  })();

  public static getWithdrawWhitelist = async ({
    limit,
    offset,
    projectIds,
    pairsIds,
  }: {
    limit: number;
    offset: number;
    projectIds?: string[];
    pairsIds?: string[];
  }): Promise<Response<{ items: IWithdrawWhitelistItem[] | null; total_items: number }>> => {
    let mappedResponseOrError: Response<{
      items: IWithdrawWhitelistItem[] | null;
      total_items: number;
    }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('offset', offset.toString());

    if (projectIds && projectIds.length !== 0) {
      for (const projectId of projectIds) {
        urlParams.append('project_ids', projectId.toString());
      }
    }

    if (pairsIds && pairsIds.length !== 0) {
      for (const pairId of pairsIds) {
        urlParams.append('pair_ids', pairId.toString());
      }
    }

    try {
      const response = await ApiWithdrawWhitelist.instance.get<
        IBaseResponse<{ items: IWithdrawWhitelistItem[] | null; total_items: number }>
      >('/withdrawal-whitelist?' + urlParams.toString());

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{
        items: IWithdrawWhitelistItem[] | null;
        total_items: number;
      }>({ error });
    }

    return mappedResponseOrError;
  };

  public static getWithdrawWhitelistItem = async ({ id }: { id: number }) => {
    let mappedResponseOrError: Response<IWithdrawWhitelistItem>;

    try {
      const response = await ApiWithdrawWhitelist.instance.get<
        IBaseResponse<IWithdrawWhitelistItem>
      >(`/withdrawal-whitelist/${id}`);

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IWithdrawWhitelistItem>({ error });
    }

    return mappedResponseOrError;
  };

  public static addWithdrawWhitelist = async ({
    pair_id,
    wallet_address,
  }: {
    pair_id: number;
    wallet_address: string;
  }): Promise<Response<null>> => {
    let mappedResponseOrError: Response<null>;

    try {
      const response = await ApiWithdrawWhitelist.instance.post<IBaseResponse<null>>(
        '/withdrawal-whitelist',
        {
          pair_id,
          wallet_address,
        },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<null>({ error });
    }

    return mappedResponseOrError;
  };

  public static deleteWithdrawWhitelist = async ({
    id,
  }: {
    id: number;
  }): Promise<Response<null>> => {
    let mappedResponseOrError: Response<null>;

    try {
      const response = await ApiWithdrawWhitelist.instance.delete<IBaseResponse<null>>(
        `/withdrawal-whitelist/${id}`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<null>({ error });
    }

    return mappedResponseOrError;
  };

  public static confirm = async ({
    id,
    signature,
  }: {
    id: number;
    signature: string;
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiWithdrawWhitelist.instance.post<IBaseResponse<any>>(
        `/withdrawal-whitelist/${id}/confirm`,
        { signature },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static prepareConfirmation = async ({
    id,
  }: {
    id: number;
  }): Promise<Response<IPrepareConfirmationResponse>> => {
    let mappedResponseOrError: Response<IPrepareConfirmationResponse>;

    try {
      const response = await ApiWithdrawWhitelist.instance.post<
        IBaseResponse<IPrepareConfirmationResponse>
      >(`/withdrawal-whitelist/${id}/prepare-confirmation`, {});

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IPrepareConfirmationResponse>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiWithdrawWhitelist };
