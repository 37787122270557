import React, { useMemo, useState, useCallback } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import { RadioBox, TableAction, TableActionIcon } from 'ui';
import { AddPairModal, TradingPairModal, SelectPairDexModal } from 'modals';
import { IDex } from 'api/apiDictionary/models';
import { EExchange } from 'types';
import { ICexPair, IDexPair } from 'types/pairs';
import { IProject } from 'types/project';

import PlatformIcon from './PlatformIcon';
import NetworkIcon from './NetworkIcon';

import { PlusIcon, SelectArrow } from 'assets/icons';
import './projectListWithPairs.scss';

type ICommonPair = (IDexPair | ICexPair) & { exchange: EExchange };

interface IProjectListWithPairs {
  project: IProject;
  selectedProjectId: number | null;
  onSelectProject: (id: number) => void;
}

const ProjectListWithPairs: React.FC<IProjectListWithPairs> = ({
  project,
  selectedProjectId,
  onSelectProject,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const projectIsSelected: boolean = useMemo(
    () => project.id === selectedProjectId,
    [project.id, selectedProjectId],
  );

  const [tradingType, setTradingType] = useState<EExchange>(EExchange.cex);
  const [dex, setDex] = useState<IDex | undefined>(undefined);

  const [tradingPairWindowOpened, setTradingPairWindowOpened] = useState<boolean>(false);
  const [addPairWindowOpened, setAddPairWindowOpened] = useState<boolean>(false);
  const [selectPairDexModalOpened, setSelectPairDexModalOpened] = useState<boolean>(false);

  const openTradingPairWindow = useCallback(() => {
    setTradingPairWindowOpened(true);
  }, []);

  const closeTradingPairWindow = useCallback(() => {
    setTradingPairWindowOpened(false);
  }, []);

  const openAddPairWindow = useCallback(() => {
    setAddPairWindowOpened(true);
    setTradingPairWindowOpened(false);
    setSelectPairDexModalOpened(false);
  }, []);

  const openSelectPairDex = useCallback(() => {
    setDex(undefined);
    setSelectPairDexModalOpened(true);
    setTradingPairWindowOpened(false);
  }, []);

  const closeSelectPairDex = useCallback(() => {
    setSelectPairDexModalOpened(false);
  }, []);

  const closeAddPairWindow = useCallback(() => {
    setAddPairWindowOpened(false);
  }, []);

  const projectAllPairsIsActive = useMemo(
    () => Boolean(matchPath(`project/${project.id}`, pathname)),
    [pathname, project],
  );

  const pairs = useMemo(
    () =>
      [...project.dexPairs, ...project.cexPairs]
        .sort((a, b) => {
          const createdAtA = new Date(a.created_at).valueOf();
          const createdAtB = new Date(b.created_at).valueOf();

          if (createdAtA > createdAtB) return -1;
          if (createdAtA < createdAtB) return 1;
          return 0;
        })
        .map(el => {
          if ('cex' in el) return { ...el, exchange: EExchange.cex };
          else if ('dex' in el) return { ...el, exchange: EExchange.dex };
        }) as ICommonPair[],
    [project],
  );

  const onSelectPair = useCallback(
    (pair: ICommonPair) => {
      navigate(`/project/${project.id}/${pair.exchange}-pair/${pair.id}`);
    },
    [navigate, project],
  );

  return (
    <>
      <div className="mm-sidebar__projects__project" key={project.id}>
        <div
          className="mm-sidebar__projects__project__header"
          onClick={() => onSelectProject(project.id)}
        >
          <motion.div
            animate={{ color: projectIsSelected ? '#5932EA' : '#7F91BB' }}
            className="mm-sidebar__projects__project__name"
          >
            <span>{project.name}</span>
          </motion.div>
          <motion.div
            animate={{ rotate: projectIsSelected ? 180 : 0 }}
            className="mm-sidebar__projects__project__select-icon"
          >
            <SelectArrow color={projectIsSelected ? '#5932EA' : undefined} />
          </motion.div>
        </div>
        <motion.div
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{
            height: projectIsSelected ? 'auto' : 0,
            overflow: projectIsSelected ? 'auto' : 'hidden',
            marginBottom: projectIsSelected ? '1rem' : '0rem',
          }}
          className={cn('mm-sidebar__projects__project__pairs', 'scrollable')}
        >
          <RadioBox
            checked={projectAllPairsIsActive}
            text={'All pairs'}
            onChange={() => navigate(`/project/${project.id}`)}
          />
          {pairs.length === 0 && (
            <span className="mm-sidebar__projects__project__no-info">
              There is no existing pairs. Create first one!
            </span>
          )}
          {pairs.map(pair => (
            <div key={pair.created_at} className="mm-sidebar__projects__project__trading-pair">
              <RadioBox
                key={pair.id}
                checked={Boolean(
                  matchPath(`/project/${project.id}/${pair.exchange}-pair/${pair.id}`, pathname),
                )}
                text={pair.symbol}
                onChange={() => onSelectPair(pair)}
                pairIcon={
                  <div className="flex flex-row items-center gap-1">
                    {'dex' in pair && <NetworkIcon network={pair.network} />}
                    <PlatformIcon
                      platform={'cex' in pair ? pair.cex : pair.dex}
                      isIcon
                      className={'mm-sidebar__projects__project__trading-pair-icon'}
                    />
                  </div>
                }
              />
            </div>
          ))}
          {isAdmin && (
            <TableAction
              iconLeft={<TableActionIcon icon={PlusIcon} sideBar />}
              text={'Add new pair'}
              onClick={openTradingPairWindow}
              customClassName="mm-sidebar__projects__project__add-pair"
            />
          )}
        </motion.div>
      </div>
      {tradingPairWindowOpened && (
        <TradingPairModal
          openAddPairWindow={openAddPairWindow}
          openSelectPairDex={openSelectPairDex}
          onClose={closeTradingPairWindow}
          typeTradingPair={tradingType}
          setTypeTradingPair={setTradingType}
        />
      )}
      {selectPairDexModalOpened && (
        <SelectPairDexModal
          dex={dex}
          setDex={setDex}
          openAddPairWindow={openAddPairWindow}
          onClose={closeSelectPairDex}
        />
      )}
      {addPairWindowOpened && (
        <AddPairModal
          onOpen={openAddPairWindow}
          onClose={closeAddPairWindow}
          projectId={project.id}
          tradingType={tradingType}
          dex={dex}
        />
      )}
    </>
  );
};

export { ProjectListWithPairs };
