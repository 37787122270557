import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IAuthUserMe, IAuthUser } from './models';

class ApiUsers extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiUsers | undefined;
  private static instance = (() => {
    if (ApiUsers.__instance === undefined) {
      ApiUsers.__instance = new ApiUsers();
    }
    return ApiUsers.__instance;
  })();

  public static getMe = async (): Promise<Response<IAuthUserMe>> => {
    let mappedResponseOrError: Response<IAuthUserMe>;

    try {
      const response = await ApiUsers.instance.get<IBaseResponse<IAuthUserMe>>('/users/me');

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<IAuthUserMe>({ error });
    }

    return mappedResponseOrError;
  };

  public static getAllUsers = async ({
    limit,
    offset,
    sort_by,
  }: {
    limit: number;
    offset: number;
    sort_by?: string;
  }): Promise<Response<{ items: IAuthUser[] }>> => {
    let mappedResponseOrError: Response<{ items: IAuthUser[] }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('offset', offset.toString());
    if (sort_by) {
      urlParams.append('sort_by', sort_by);
    }

    try {
      const response = await ApiUsers.instance.get<IBaseResponse<{ items: IAuthUser[] }>>(
        '/users' + '?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ items: IAuthUser[] }>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiUsers };
