import React from 'react';

const SureImgIcon: React.FC = () => {
  return (
    <svg width="152" height="161" viewBox="0 0 152 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.1" x="5" width="140" height="140" rx="39.2683" fill="#4A3AFF" />
      <g filter="url(#filter0_d_848_7592)">
        <path
          d="M52.1598 31.7767C56.9127 31.7806 61.5391 30.2453 65.3465 27.4003L71.841 22.5476C74.2666 20.8487 77.4962 20.8487 79.9218 22.5476L86.8347 27.5609C90.567 30.2675 95.0552 31.7333 99.6655 31.751L105.161 31.7723C110.606 31.7723 115.021 36.1864 115.021 41.6315C115.021 46.5985 117.219 51.3108 121.024 54.5029L121.26 54.7009C121.739 55.1024 122.015 55.695 122.015 56.3196V112.1C122.015 115.989 118.862 119.142 114.973 119.142H37.0267C33.1376 119.142 29.9844 115.989 29.9844 112.1L29.9878 56.3194C29.9878 55.6949 30.2643 55.1024 30.7423 54.701L30.9738 54.5068C34.7802 51.3136 36.9786 46.5999 36.9786 41.6315C36.9786 36.1864 41.3924 31.7723 46.8379 31.7723L52.1598 31.7767Z"
          fill="#FF0000"
          fillOpacity="0.01"
        />
      </g>
      <mask
        id="mask0_848_7592"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="29"
        y="18"
        width="94"
        height="102"
      >
        <path
          d="M58.0746 31.7733L71.9592 20.1257C74.3848 18.4268 77.6144 18.4268 80.04 20.1257L93.9256 31.7733H105.161C110.606 31.7733 115.021 36.1874 115.021 41.6326V49.4682L121.26 54.702C121.739 55.1034 122.015 55.696 122.015 56.3206V112.101C122.015 115.99 118.862 119.143 114.973 119.143H37.0267C33.1376 119.143 29.9844 115.99 29.9844 112.101L29.9878 56.3205C29.9878 55.6959 30.2643 55.1034 30.7423 54.702L36.9786 49.4705V41.6326C36.9786 36.1874 41.3924 31.7733 46.8379 31.7733H58.0746Z"
          fill="#EBE9FF"
        />
      </mask>
      <g mask="url(#mask0_848_7592)">
        <path
          d="M69.71 23.0826L33.1116 52.7967C31.1353 54.4012 29.9876 56.8112 29.9874 59.3569L29.9844 112.099C29.9844 115.988 33.1373 119.141 37.0267 119.141H114.973C118.862 119.141 122.015 115.988 122.015 112.099V59.367C122.015 56.8156 120.862 54.4007 118.878 52.7962L82.1244 23.0708C78.5029 20.142 73.3259 20.1469 69.71 23.0826Z"
          fill="#AFA8FF"
        />
        <path
          d="M105.162 31.7656C110.607 31.7656 115.021 36.1798 115.021 41.6249L115.021 117.727C115.021 118.505 114.391 119.135 113.613 119.135L38.3878 119.135C37.61 119.135 36.9794 118.505 36.9794 117.727L36.9794 41.6249C36.9794 36.1798 41.3935 31.7656 46.8386 31.7656L105.162 31.7656Z"
          fill="#F9F9FF"
        />
        <rect x="47.6074" y="65.6875" width="44.4953" height="6.42546" rx="3.21273" fill="#BCB6FF" />
        <rect x="47.6074" y="43.8281" width="44.0601" height="6.42546" rx="3.21273" fill="#BCB6FF" />
        <rect x="47.6074" y="54.7578" width="33.4123" height="6.42546" rx="3.21273" fill="#BCB6FF" />
        <path d="M65.4275 87.0284L29.5635 58.0859L29.8124 116.254L65.4275 87.0284Z" fill="#8277FF" />
        <path d="M86.7566 87.5341L123.088 58.5L122.584 113.824L86.7566 87.5341Z" fill="#8277FF" />
        <path
          d="M14.7675 117.375L68.0569 80.0503C72.9079 76.6525 79.3664 76.6525 84.2175 80.0503L148.481 125.062C149.608 125.852 149.05 127.624 147.673 127.624L17.9996 127.624C12.4921 127.624 10.2564 120.535 14.7675 117.375Z"
          fill="#4A3AFF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_848_7592"
          x="0.905666"
          y="4.16831"
          width="150.189"
          height="156.025"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11.9736" />
          <feGaussianBlur stdDeviation="14.5394" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_848_7592" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_848_7592" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export { SureImgIcon };
