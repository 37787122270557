import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import {
  DepositWithdrawContext,
  IInnerWallet,
  mapTypeToCaption,
} from 'context/DepositWithdrawContext/DepositWithdrawContext';
import { motion } from 'framer-motion';
import { useDebouncedCallback } from 'hooks';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { Button, ButtonLoading, ErrorText } from 'ui';
import { InputField, SelectField } from 'fields';
import { calculateWalletTokenBalance } from 'utils/calculates';
import { addBignumbers } from 'utils/formulas';
import { IDexPair } from 'types/pairs';
import { ITransfer } from 'types/transfers';

import { Warning } from './components';
import useDeposit from './useDeposit';
import useWithdraw from './useWithdraw';
import { WalletItem } from './WalletItem';

type IFormProps = {
  handleOpenManualTransactionsModal: () => void;
  onRemountTable: () => void;
  onClose: () => void;
  pair: IDexPair;
  onWithdrawResult?: (transfers: ITransfer[]) => void;
};

const Form: React.FC<IFormProps> = ({
  handleOpenManualTransactionsModal,
  onRemountTable,
  onClose,
  pair,
  onWithdrawResult,
}) => {
  const {
    type,
    values,
    touched,
    setFieldValue,
    validateForm,
    selectedToken,
    token: { human: tokenBalanceHuman },
    innerWallets,
    handleChangeTotalAmount,
    formError,
  } = useContext(DepositWithdrawContext);

  const { deposit, loading: depositLoading } = useDeposit({ onClose, pair });
  const { withdraw, loading: withdrawLoading } = useWithdraw({
    onClose,
    onRemountTable,
    pair,
    onWithdrawResult,
  });

  const loading = useMemo(
    () => depositLoading || withdrawLoading || false,
    [withdrawLoading, depositLoading],
  );

  const calculateTotalSendByWalletBalances = useCallback(
    (resultWallets: IInnerWallet[]) => {
      const totalSend = resultWallets.reduce((acc, val) => {
        if (val.balance === '' || Number(val.balance) === 0) return acc;

        return addBignumbers(
          [acc, 18],
          [parseUnits(val.balance, selectedToken.get.decimals), selectedToken.get.decimals],
        );
      }, BigNumber.from('0'));

      setFieldValue('totalSend', formatUnits(totalSend, 18));
    },
    [selectedToken.get, setFieldValue],
  );

  const calculateTotalSendByWalletBalancesDebounced = useDebouncedCallback(
    calculateTotalSendByWalletBalances,
    300,
  );

  const handleChangeWalletBalance = useCallback(
    (newBalance: string, index: number) => {
      const newWalletBalances = [...innerWallets.get];
      newWalletBalances[index] = {
        ...newWalletBalances[index],
        balance: newBalance,
        max_amount: !!parseUnits(newBalance, selectedToken.get.decimals).eq(
          newWalletBalances[index].walletBalance,
        ),
      };

      calculateTotalSendByWalletBalancesDebounced(newWalletBalances);
      innerWallets.set(newWalletBalances);
    },
    [calculateTotalSendByWalletBalancesDebounced, innerWallets, selectedToken.get],
  );

  const selectTokenList = useMemo(
    () => [pair.token_base, pair.token_quote, pair.token_fee],
    [pair],
  );

  const [innerTotalSend, setInnerTotalSend] = useState<string | undefined>(
    values.totalSend ?? undefined,
  );

  useEffect(() => {
    setInnerTotalSend(values.totalSend ?? undefined);
  }, [values.totalSend]);

  useDebounce(
    () => {
      if (innerTotalSend !== values.totalSend) {
        handleChangeTotalAmount(innerTotalSend);
      }
    },
    300,
    [innerTotalSend],
  );

  const onSubmit = useCallback(async () => {
    const result = await validateForm();

    if (Object.keys(result).length === 0) {
      if (type === 'deposit') {
        deposit();
      } else {
        withdraw();
      }
    }
  }, [type, deposit, withdraw, validateForm]);

  return (
    <div className="mm-deposit-withdraw-modal-form">
      <div className="mm-deposit-withdraw-modal-token-info">
        <SelectField
          label="Token"
          placeholder="Select token"
          items={selectTokenList}
          itemToString={token => token.symbol}
          onSelectItem={token => (token ? selectedToken.set(token) : undefined)}
          selectedItem={selectedToken.get}
        />
        <span className="mm-deposit-withdraw-modal-token-info__balance">
          Balance: {tokenBalanceHuman}
        </span>
        <div className="token-total-send-row">
          <InputField
            label="Total amount"
            type="decimal-number"
            value={innerTotalSend ?? ''}
            setValue={setInnerTotalSend}
          />
          <motion.button
            type="button"
            className="max-button"
            whileTap={{ backgroundColor: '#F7F7F7' }}
            onClick={() =>
              handleChangeTotalAmount(formatUnits(values.balance, selectedToken.get.decimals))
            }
          >
            Max
          </motion.button>
        </div>
      </div>
      <div className="mm-deposit-withdraw-modal-wallets">
        <div className="mm-deposit-withdraw-modal-wallets__header">
          <span>Selected wallets: {innerWallets.get.length}</span>
          <div className="mm-deposit-withdraw-modal-wallets__header__right">
            <span>Amount</span>
          </div>
        </div>
        <ul className="mm-deposit-withdraw-modal-wallets__list scrollable">
          {innerWallets.get.map((wallet, index) => (
            <WalletItem
              key={wallet.id}
              address={wallet.address}
              balance={wallet.balance}
              setBalance={b => handleChangeWalletBalance(b, index)}
              walletBalance={calculateWalletTokenBalance(wallet, selectedToken.get)}
              decimals={selectedToken.get.decimals}
              type={type}
              distributionTouched={!!touched.totalSend}
              disabled={wallet.disabled}
              isValid={wallet.isValid}
            />
          ))}
        </ul>
      </div>
      <Warning />
      <ErrorText>{formError as string}</ErrorText>
      {!loading && (
        <Button type="button" onClick={onSubmit}>
          {mapTypeToCaption(type)}
        </Button>
      )}
      {loading && <ButtonLoading />}
      {!loading && type === 'deposit' && (
        <Button color="transparent" onClick={handleOpenManualTransactionsModal}>
          Manual transactions
        </Button>
      )}
    </div>
  );
};

export { Form };
