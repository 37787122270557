import React from 'react';
import { Table } from '@tanstack/react-table';

import { IPairWallet } from 'api/apiPairs/models';
import { TableCheckbox } from 'ui';
import { useTypedSelector } from 'store';

interface IWalletAddressHeadProps {
  table: Table<IPairWallet>;
}

const WalletAddressHead: React.FC<IWalletAddressHeadProps> = ({ table }) => {
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  return (
    <div className="checkbox-area">
      {isAdmin && (
        <TableCheckbox
          id="select-all"
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      )}
      <span>Wallet address</span>
    </div>
  );
};

export default WalletAddressHead;
