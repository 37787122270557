import { FC, memo, ReactNode } from 'react';

import './createTaskForm.scss';

type Props = {
  handleSubmit: () => void;
  children: ReactNode;
};

export const CreateTaskFormComponent: FC<Props> = ({ handleSubmit, children }) => (
  <form className="mm-create-task-form" onSubmit={handleSubmit}>
    {children}
  </form>
);

const CreateTaskForm = memo(CreateTaskFormComponent);

export { CreateTaskForm };
