import { memo, FC, useState, useCallback } from 'react';
import cn from 'classnames';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Button, Modal, ErrorText, Switcher } from 'ui';
import { DurationField, InputField } from 'fields';
import { CreateTaskForm } from 'ui/forms';
import { useHolderBalanceTask } from './useAddHolderModal/useHolderBalanceTask';

import { IDexBalanceBotTask } from 'types/bots';
import { ConnectWalletsToBoostHoldersModal } from '../ConnectWalletsToBoostHoldersModal';
import { TotalAmount } from 'pages/DexPair/BalanceBot/components/HolderBalanceTask/components/Table/columns';
import { divideBignumbers, multiplyBignumbers } from 'utils/formulas';
import { formatFiat, formatToken } from 'utils/formats';

import './holderTaskBalanceModal.scss';

dayjs.extend(duration);

type IHolderTaskBalanceModal = {
  onClose: (balanceBotTask?: IDexBalanceBotTask | undefined) => void;
  mode: 'create' | 'edit';
  task?: IDexBalanceBotTask;
};

export const HolderTaskBalanceModal: FC<IHolderTaskBalanceModal> = memo(
  ({ onClose, mode, task }) => {
    const pair = useTypedSelector(store => store.pairs.selectedDexPair);
    const [isOpenWalletsModal, setIsOpenWalletsModal] = useState<'source' | 'destination' | ''>('');
    const [isConnectedWallets, setIsConnectedWallets] = useState<'true' | 'false' | ''>('');

    const { values, handleSubmit, setFieldValue, formError, edited, estimateFee } =
      useHolderBalanceTask({
        onClose,
        task,
        mode,
      });

    const baseToken = pair?.token_base.symbol;
    const decimals = pair?.token_base.decimals || 18;
    const isCreateMode = mode === 'create';

    const currentSwapPriceUsd =
      estimateFee?.current_pair?.transactions_count &&
      estimateFee.current_pair?.transactions_count >= 5
        ? estimateFee?.current_pair.avg_amount_usd
        : estimateFee?.all_pairs.avg_amount_usd;

    const currentSwapPriceToken = divideBignumbers(
      [BigNumber.from(currentSwapPriceUsd || 0), decimals],
      [BigNumber.from(pair?.token_fee.price_usd || 0), decimals],
    );

    const multipliedFeeCostUsd = multiplyBignumbers(
      [BigNumber.from(currentSwapPriceUsd || 0), decimals],
      [BigNumber.from(values.destinationWallets || 0), 1],
    );

    const feeCostUsd = values.buyback_enabled
      ? multiplyBignumbers(
          [BigNumber.from(multipliedFeeCostUsd || 0), decimals],
          [BigNumber.from('2'), 1],
        )
      : multipliedFeeCostUsd;

    const multipliedFeeCostToken = multiplyBignumbers(
      [BigNumber.from(currentSwapPriceToken || 0), decimals],
      [BigNumber.from(values.destinationWallets || 0), 1],
    );

    const feeCostToken = !values.buyback_enabled
      ? multiplyBignumbers(
          [BigNumber.from(multipliedFeeCostToken || 0), decimals],
          [BigNumber.from('2'), 0],
        )
      : multipliedFeeCostToken;

    const requiredTime =
      values.min_pause || values.max_pause || values.destinationWallets
        ? dayjs
            .duration(
              (((values.min_pause ?? 0) + (values.max_pause ?? 0)) / 2) * values.destinationWallets,
              'milliseconds',
            )
            .humanize()
        : '-';

    const dailyVolumePeriod = ((values.min_pause || 0) + (values.max_pause || 0)) / 2;
    const dailyVolumeCount = (24 * 60 * 60 * 1000) / dailyVolumePeriod;
    const dailySummariseVolume = (+values.min_amount + +values.max_amount) / 2;

    const dailyVolume =
      isFinite(dailyVolumeCount) && dailySummariseVolume
        ? `${(dailyVolumeCount * dailySummariseVolume).toFixed(6)} ${pair?.token_base.symbol}`
        : '-';
    const feeCostValue = values.destinationWallets
      ? `${formatToken(feeCostToken, decimals)} ${pair?.token_fee?.symbol} /
  ${formatFiat(feeCostUsd)}`
      : '-';

    const handleOpenSourceConnectedWallets = useCallback(() => {
      setIsOpenWalletsModal('source');
      setIsConnectedWallets('false');
    }, []);

    const handleOpenSourceNotConnectedWallets = useCallback(() => {
      setIsOpenWalletsModal('source');
      setIsConnectedWallets('true');
    }, []);

    const handleOpenDestinationConnectedWallets = useCallback(() => {
      setIsOpenWalletsModal('destination');
      setIsConnectedWallets('false');
    }, []);

    const handleOpenDestinationNotConnectedWallets = useCallback(() => {
      setIsOpenWalletsModal('destination');
      setIsConnectedWallets('true');
    }, []);

    const handleCloseConnectWalletsModal = useCallback(() => {
      setIsOpenWalletsModal('');
      setIsConnectedWallets('');
    }, []);
    return (
      <>
        <Modal
          title={`${capitalize(mode)} task`}
          edited={edited}
          className="mm-create-add-holder-task-modal"
          onClose={onClose}
        >
          {
            <CreateTaskForm handleSubmit={handleSubmit}>
              <div className="form-inner scrollable">
                <div className="mm-add-pair-modal">
                  <div>
                    <div className="inputs-row">
                      <InputField
                        type="decimal-number"
                        label={'Min amount'}
                        setValue={value =>
                          setFieldValue('min_amount', value, { triggerEdit: true })
                        }
                        value={values.min_amount}
                      />
                      <InputField
                        type="decimal-number"
                        label={'Max amount'}
                        setValue={value =>
                          setFieldValue('max_amount', value, { triggerEdit: true })
                        }
                        value={values.max_amount}
                      />
                    </div>
                  </div>

                  <div className="inputs-row">
                    <DurationField
                      label="Min pause"
                      value={values.min_pause}
                      setValue={v => setFieldValue('min_pause', v, { triggerEdit: true })}
                    />
                    <DurationField
                      label="Max pause"
                      value={values.max_pause}
                      setValue={v => setFieldValue('max_pause', v, { triggerEdit: true })}
                    />
                  </div>
                  {!isCreateMode && (
                    <>
                      <InputField
                        type="decimal-number"
                        label={'From'}
                        value={`${values.sourceWallets || 0} ${
                          values.sourceWallets === 1 ? 'wallet' : 'wallets'
                        }`}
                        onClick={handleOpenSourceConnectedWallets}
                        className={cn('mm-input-field-purple-text', {
                          'mm-input-field-gray-text': isCreateMode,
                        })}
                        nodeRight={
                          <div
                            onClick={handleOpenSourceNotConnectedWallets}
                            className={cn('wallets-count-text', {
                              disabled: isCreateMode,
                            })}
                          >
                            Add wallets
                          </div>
                        }
                      />

                      <InputField
                        type="decimal-number"
                        label={'To'}
                        value={`${values.destinationWallets || 0} ${
                          values.destinationWallets === 1 ? 'wallet' : 'wallets'
                        }`}
                        className={cn('mm-input-field-purple-text', {
                          'mm-input-field-gray-text': isCreateMode,
                        })}
                        onClick={handleOpenDestinationConnectedWallets}
                        nodeRight={
                          <span
                            onClick={handleOpenDestinationNotConnectedWallets}
                            className={cn('wallets-count-text', {
                              disabled: isCreateMode,
                            })}
                          >
                            Add wallets
                          </span>
                        }
                      />
                    </>
                  )}

                  <div className="inputs-row">
                    <div className="text-main-purple font-bold mb-1.5 text-sm">
                      Reverse swap (buyback)
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Switcher
                        value={values.buyback_enabled}
                        onChange={value =>
                          setFieldValue('buyback_enabled', value, { triggerEdit: true })
                        }
                      />
                    </div>
                  </div>
                  {values.buyback_enabled && (
                    <>
                      <div className="inputs-row">
                        <DurationField
                          label="Min pause"
                          value={values.buyback_min_pause}
                          setValue={v =>
                            setFieldValue('buyback_min_pause', v, { triggerEdit: true })
                          }
                        />
                        <DurationField
                          label="Max pause"
                          value={values.buyback_max_pause}
                          setValue={v =>
                            setFieldValue('buyback_max_pause', v, { triggerEdit: true })
                          }
                        />
                      </div>

                      <div className="text-main-purple font-bold mb-1.5 text-sm">
                        {`Allocate ${pair?.token_fee.symbol} for swaps:`}
                      </div>
                      <div className="inputs-row">
                        <InputField
                          type="decimal-number"
                          label={'Min'}
                          value={values.buyback_min_swaps}
                          setValue={value =>
                            setFieldValue('buyback_min_swaps', value, { triggerEdit: true })
                          }
                        />
                        <InputField
                          type="decimal-number"
                          label={'Max'}
                          value={values.buyback_max_swaps}
                          setValue={value =>
                            setFieldValue('buyback_max_swaps', value, { triggerEdit: true })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="add-holder-container">
                  <div className="add-holder-container__indicator">
                    <span>Total amount:</span>
                    <span>
                      {task ? (
                        <TotalAmount original={task as IDexBalanceBotTask} baseToken={baseToken} />
                      ) : (
                        <div>-</div>
                      )}
                    </span>
                  </div>
                  <div className="add-holder-container__indicator">
                    <span>Duration:</span>
                    <span>{requiredTime}</span>
                  </div>
                  <div className="add-holder-container__indicator">
                    <span>Daily volume:</span>
                    <span>{dailyVolume} </span>
                  </div>
                  <div className="add-holder-container__indicator">
                    <span>Fee costs:</span>
                    <span> {feeCostValue}</span>
                  </div>
                </div>
              </div>
              <div className="mm-new-buy-sell-bot-task-actions">
                {formError && <ErrorText>{formError}</ErrorText>}
                <Button type="submit">{isCreateMode ? 'Create task' : 'Save'}</Button>
              </div>
            </CreateTaskForm>
          }
        </Modal>

        {isOpenWalletsModal && (
          <ConnectWalletsToBoostHoldersModal
            task={task}
            walletType={isOpenWalletsModal}
            not_connected={isConnectedWallets}
            onClose={handleCloseConnectWalletsModal}
            taskType={task!.type}
            taskId={task!.id}
            currentTaskValue={values}
          />
        )}
      </>
    );
  },
);
