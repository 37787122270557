import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { PairWashTradingBotContextProvider } from 'context/PairWashTradingBotContext';
import { Panel, BotEnableStatus } from 'ui';
import { Toolbar, ContentWrapper, Chart, Form, Tables } from './components';
import { EDexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';

import { WashTradingBotIcon } from 'assets/icons';

const WashTradingBot: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pairId = useTypedSelector(store => store.pairs.selectedDexPair)!.id;
  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[pairId],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[EDexBot.wash_trading] : undefined),
    [pairConnectedBots],
  );

  return (
    <Panel
      title="Smart liquidity algorithm"
      icon={WashTradingBotIcon}
      initialOpened={initialOpened}
      onChange={setIsOpened}
      headerNode={
        isBotEnabled !== undefined && (
          <BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />
        )
      }
    >
      <PairWashTradingBotContextProvider>
        <Toolbar />
        <ContentWrapper>
          <Form />
          <Chart />
        </ContentWrapper>
        <Tables />
      </PairWashTradingBotContextProvider>
    </Panel>
  );
};

export { WashTradingBot };
