import { ApiBot } from 'api';
import { ECexBot, EDexBot } from 'types/bots';
import { setConnectedBots } from 'store/slices/pairs_connected_bots';
import store, { AppDispatch } from 'store';
import { EExchange } from 'types';

export const getPairLinkedBots = async ({
  pairId,
  dispatch,
  exchange,
}: {
  pairId: number;
  dispatch: AppDispatch;
  exchange: EExchange;
}) => {
  const isAdmin = store.getState().user.isAdmin;

  if (!isAdmin) return;

  try {
    const { isSuccess, data } =
      exchange === EExchange.dex
        ? await ApiBot.getDexLinkedBots({ pairId })
        : await ApiBot.getCexLinkedBots({ pairId });

    if (isSuccess && data) {
      const bots = Object.values(EDexBot).concat(Object.values(ECexBot) as number[]) as number[];
      const connectedBots: Record<EDexBot | ECexBot, boolean> = {} as Record<
        EDexBot | ECexBot,
        boolean
      >;

      for (const value of bots) {
        connectedBots[value as EDexBot | ECexBot] =
          data.items.find(el => el.bot.id === value)?.is_enabled ?? false;
      }

      dispatch(
        setConnectedBots({
          pairId,
          connectedBots: connectedBots,
        }),
      );
    }
  } catch (error) {
    console.log('error: ', error);
  }
};
