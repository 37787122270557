import React, { useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTypedDispatch, useTypedSelector } from 'store';

import { AuthPageWrapper, Button, ErrorText, ButtonLoading } from 'ui';
import { onLogin, setAuthError } from 'store/slices/auth';
import { InputField } from 'fields';

const Authentication: React.FC = () => {
  const dispatch = useTypedDispatch();
  const authLoading = useTypedSelector(store => store.auth.authLoading);
  const authError = useTypedSelector(store => store.auth.authError);

  const initialValues: { login: string | undefined; password: string | undefined } = useMemo(
    () => ({
      login: undefined,
      password: undefined,
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        login: Yup.string().required('Login is required').max(50, 'Max login length is 50 symbols'),
        password: Yup.string().required('Password is required'),
      }),
    [],
  );

  const handleSubmitAuth = useCallback(
    async ({ login, password }: { login: string | undefined; password: string | undefined }) => {
      if (login === undefined || password === undefined) return;

      dispatch(onLogin({ login, password }));
    },
    [dispatch],
  );

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmitAuth,
  });

  const formError = useMemo(() => {
    let error: string | undefined = undefined;

    if (errors.login && touched.login) {
      error = errors.login;
    } else if (errors.password && touched.password) {
      error = errors.password;
    } else if (authError) {
      error = authError;
    }

    return error;
  }, [authError, errors, touched]);

  const setFieldValuesExtended = (field: 'login' | 'password', value: string | undefined) => {
    const newValue = value === undefined || value === '' ? undefined : value;

    setFieldValue(field, newValue);

    if (authError) {
      dispatch(setAuthError(null));
    }
  };

  return (
    <AuthPageWrapper>
      <div className="flex flex-col gap-2">
        <InputField
          placeholder={'Login'}
          type="text"
          name={'login'}
          id={'login'}
          value={values.login ?? ''}
          setValue={newLoginValue => setFieldValuesExtended('login', newLoginValue)}
        />
        <InputField
          placeholder="Password"
          type="password"
          name={'password'}
          id={'password'}
          value={values.password ?? ''}
          setValue={newPasswordValue => setFieldValuesExtended('password', newPasswordValue)}
        />
      </div>
      <ErrorText>{formError}</ErrorText>
      {authLoading && <ButtonLoading color="primary" />}
      {!authLoading && (
        <Button color="primary" onClick={handleSubmit}>
          Sign in
        </Button>
      )}
    </AuthPageWrapper>
  );
};

export { Authentication };
