import React, { useCallback, useState, HTMLAttributes, useRef, useMemo } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';

import {
  fieldLabelFocused,
  fieldLabelNotFocusedFilled,
  fieldLabelNotFocusedNotFilled,
} from 'constants/fields';
import { FieldHints } from '../../components';

import { ArrowDropDown } from 'assets/icons';

import './style.scss';

interface IBaseSelectFieldProps<V> extends HTMLAttributes<HTMLInputElement> {
  selectedItem?: V;
  onSelectItem: (item: V | undefined) => void;
  items: V[];
  renderItem: (item: V) => React.ReactNode;
  selectedItemText?: string;
  renderSelectedItem?: (item: V) => React.ReactNode;
  comment?: string;
  errorMessage?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

function BaseSelectField<V>({
  comment,
  errorMessage,
  placeholder,
  label,
  disabled = false,
  onClick,
  className,
  id,
  items,
  selectedItem,
  selectedItemText,
  renderSelectedItem,
  onSelectItem,
  renderItem,
  ...rest
}: IBaseSelectFieldProps<V>) {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-start',
    open: disabled ? false : isOpened,
    onOpenChange: disabled ? () => {} : setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    listNav,
    click,
  ]);

  const handleSelect = useCallback(
    (item: V, index: number) => {
      setSelectedIndex(index);
      onSelectItem(item);
      setIsOpened(false);
    },
    [onSelectItem],
  );

  const value = useMemo(
    () =>
      selectedItem ? (renderSelectedItem ? ' ' : selectedItemText ? selectedItemText : '') : '',
    [selectedItem, selectedItemText, renderSelectedItem],
  );

  const inputId = useMemo(() => {
    return id ?? `mm-select-${uuid()}`;
  }, [id]);

  const lineStyles = useMemo(() => {
    let borderColor = 'rgba(127, 145, 187, 0.2)';

    if (errorMessage !== undefined) {
      borderColor = '#CE4966';
    } else if (isOpened) {
      borderColor = '#5932ea';
    }

    return { borderBottom: `2px solid ${borderColor}` };
  }, [isOpened, errorMessage]);

  const labelStyles = useMemo(() => {
    const fieldFilled = value !== undefined && value !== null && value !== '';
    const fieldFocused = isOpened;

    if (fieldFocused) return fieldLabelFocused(!!errorMessage);

    if (!fieldFocused && fieldFilled) return fieldLabelNotFocusedFilled(!!errorMessage);

    return fieldLabelNotFocusedNotFilled;
  }, [value, isOpened, errorMessage]);

  return (
    <>
      <div className={cn({ [className ?? '']: className }, 'mm-base-select-field')} {...rest}>
        <div
          className={cn('mm-base-select-field__container', {
            _disabled: disabled,
          })}
          tabIndex={0}
          ref={refs.setReference}
          aria-labelledby="select-label"
          aria-autocomplete="none"
          {...getReferenceProps()}
        >
          <div className="mm-base-select-field__inner">
            {selectedItem && renderSelectedItem && (
              <div className="select-node-left">{renderSelectedItem(selectedItem)}</div>
            )}
            <div className="mm-base-select-field__inner__input-holder">
              <input
                className={cn('mm-base-select-field__inner__input', { _focused: isOpened })}
                value={selectedItem && renderSelectedItem ? ' ' : value}
                id={inputId}
                autoComplete="off"
                type={'text'}
                placeholder={placeholder}
                readOnly={true}
                disabled={disabled}
              />
            </div>
            {label ? (
              <motion.label
                initial={fieldLabelNotFocusedNotFilled}
                animate={labelStyles}
                className={cn('mm-base-select-field__inner__label', { _disabled: !!disabled })}
                htmlFor={inputId}
              >
                {label}
              </motion.label>
            ) : null}
            <button className={cn('arrow-angle-down', { _opened: isOpened })}>
              <ArrowDropDown />
            </button>
          </div>
          <motion.div
            animate={lineStyles}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
            className={'mm-base-select-field__line'}
          />
        </div>
        <FieldHints comment={comment} errorMessage={errorMessage} />
      </div>
      {isOpened && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              className="mm-base-select-field__dropdown scrollable"
              {...getFloatingProps()}
            >
              {items.map((item, i) => (
                <div
                  className="mm-base-select-field__dropdown__item"
                  key={i}
                  ref={node => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === selectedIndex && i === activeIndex}
                  style={{
                    background: i === activeIndex ? '#D2C6FF' : '',
                  }}
                  {...getItemProps({
                    onClick() {
                      handleSelect(item, i);
                    },
                    onKeyDown(event) {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSelect(item, i);
                      }

                      if (event.key === ' ') {
                        event.preventDefault();
                        handleSelect(item, i);
                      }
                    },
                  })}
                >
                  {renderItem(item)}
                </div>
              ))}
              {items.length === 0 && <div className="dropdown__not-found">Not found...</div>}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}

export { BaseSelectField };
