import React from 'react';
import { Row } from '@tanstack/react-table';

import { TableProjects } from 'ui';
import { IPairWallet } from 'api/apiPairs/models';

interface IProjectsCell {
  row: Row<IPairWallet>;
}

const ProjectsCell: React.FC<IProjectsCell> = ({ row }) => {
  return <TableProjects projects={row.original.projects} tooltip="projects" />;
};

export default ProjectsCell;
