import React, { useContext, useCallback, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useTypedSelector } from 'store';

import { TableAction, TableActionIcon } from 'ui';
import { PairWalletsContext } from 'context/PairWalletsContext/PairWalletsContext';
import { BSC_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID, ARBITRUM_CHAIN_ID } from 'types/web3';
import { WithdrawWhitelistButton } from './WithdrawalWhitelistButton';

import { PlusIcon, ExitIcon, ArrowToIcon, ArrowFromIcon } from 'assets/icons';
import { ENetwork } from 'types';

const Actions: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const {
    selectedRecords,
    modals: {
      changeNetworkOpened,
      depositOpened,
      withdrawOpened,
      disconnectWalletsOpened,
      connectWalletsOpened,
    },
  } = useContext(PairWalletsContext);
  const { account, chainId } = useWeb3React();

  const metamaskConnectionIsValid = useMemo<boolean>(() => {
    if (!account || !dexPair) return false;

    if (dexPair.network === ENetwork.bsc && chainId && chainId !== BSC_CHAIN_ID) return false;

    if (dexPair.network === ENetwork.eth && chainId && chainId !== ETH_CHAIN_ID) return false;

    if (dexPair.network === ENetwork.polygon && chainId && chainId !== POLYGON_CHAIN_ID)
      return false;

    if (dexPair.network === ENetwork.arbitrum && chainId && chainId !== ARBITRUM_CHAIN_ID)
      return false;

    return true;
  }, [account, dexPair, chainId]);

  const handleOpenDepositModal = useCallback(() => {
    if (!metamaskConnectionIsValid) {
      changeNetworkOpened.set(true);
      return;
    }

    depositOpened.set(true);
  }, [metamaskConnectionIsValid, changeNetworkOpened, depositOpened]);

  const handleOpenWithdrawModal = useCallback(() => {
    if (!metamaskConnectionIsValid) {
      changeNetworkOpened.set(true);
      return;
    }

    withdrawOpened.set(true);
  }, [metamaskConnectionIsValid, changeNetworkOpened, withdrawOpened]);

  const handleDeactivateWallets = useCallback(() => {
    disconnectWalletsOpened.set(true);
  }, [disconnectWalletsOpened]);

  return (
    <>
      {isAdmin && (
        <>
          <TableAction
            iconLeft={
              <TableActionIcon icon={ArrowFromIcon} disabled={selectedRecords.length === 0} />
            }
            iconRight={<WithdrawWhitelistButton />}
            text={'Withdraw'}
            disabled={selectedRecords.length === 0}
            onClick={handleOpenWithdrawModal}
          />
          <TableAction
            iconLeft={
              <TableActionIcon icon={ArrowToIcon} disabled={selectedRecords.length === 0} />
            }
            text={'Deposit'}
            disabled={selectedRecords.length === 0}
            onClick={handleOpenDepositModal}
          />
          <TableAction
            iconLeft={<TableActionIcon icon={ExitIcon} disabled={selectedRecords.length === 0} />}
            text={'Deactivate'}
            disabled={selectedRecords.length === 0}
            onClick={handleDeactivateWallets}
          />
          <TableAction
            iconLeft={<TableActionIcon icon={PlusIcon} />}
            text={'Connect new wallets'}
            onClick={() => connectWalletsOpened.set(true)}
          />
        </>
      )}
    </>
  );
};

export default Actions;
