import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { Panel, BotEnableStatus } from 'ui';
import { EDexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';

import { BalanceBotIcon } from 'assets/icons';
import { PairBalanceBotContextProviderV2 } from 'context/PairBalanceBotContext';

import { BotSettingsSection } from './components/BotSettingsSection';
import { MaintainFeeBalanceTask } from './components/MaintainFeeBalanceTask/MaintainFeeBalanceTask';
import { HolderBalance } from './components/HolderBalanceTask/HolderBalance';
import BalanceContentWrapper from './components/ContentWrapper/BalanceContentWrapper';

const BalanceBot: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pairId = useTypedSelector(store => store.pairs.selectedDexPair)!.id;
  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[pairId],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[EDexBot.balance_bot] : undefined),
    [pairConnectedBots],
  );

  return (
    <Panel
      icon={BalanceBotIcon}
      title={'Boost holders algorithm'}
      initialOpened={initialOpened}
      onChange={setIsOpened}
      headerNode={
        isBotEnabled !== undefined && (
          <BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />
        )
      }
    >
      <PairBalanceBotContextProviderV2>
        <BalanceContentWrapper>
          <BotSettingsSection />
          <MaintainFeeBalanceTask />
          <HolderBalance />
        </BalanceContentWrapper>
      </PairBalanceBotContextProviderV2>
    </Panel>
  );
};

export { BalanceBot };
