import React, { useState, useCallback, useMemo, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import cn from 'classnames';

import { Calendar } from 'ui';

import { InputField } from '../base/InputField/InputField';
import { CalendarIcon } from 'assets/icons';

import './style.scss';

interface IDateFieldProps {
  date: number | null;
  setDate: (v?: number | null) => void;
  readonly?: boolean;
  disabled?: boolean;
  label: string;
  calendarTitle?: string;
  errorMessage?: string;
  comment?: string;
}

const DateField: React.FC<IDateFieldProps> = ({
  date,
  setDate,
  readonly = false,
  disabled = false,
  label,
  calendarTitle = 'Select date',
  errorMessage,
  comment,
}) => {
  const [internalValue, setInternalValue] = useState<string>('');
  const [calendarOpened, setCalendarOpened] = useState<boolean>(false);

  useEffect(() => {
    if (date) {
      if (date !== 0) {
        setInternalValue(dayjs(date).format('MM/DD/YYYY, HH:mm'));
      } else {
        setInternalValue('');
      }
    } else {
      setInternalValue('');
    }
  }, [date]);

  useEffect(() => {
    if (readonly || disabled) {
      setCalendarOpened(false);
    }
  }, [readonly, disabled]);

  const calendarValue = useMemo(() => (date ? dayjs(date) : null), [date]);

  const handleCalendarSelect = useCallback(
    (date?: Dayjs | null | undefined) => {
      setDate(isNil(date) ? null : date.valueOf());
    },
    [setDate],
  );

  const handleClickIcon = useCallback(() => {
    if (readonly || disabled) return;

    setCalendarOpened(true);
  }, [readonly, disabled]);

  const isMaskValid = useCallback((value: string) => {
    const maskPattern = /^\d{2}\/\d{2}\/\d{4},\s\d{2}:\d{2}$/;

    return maskPattern.test(value);
  }, []);

  const handleBlur = useCallback(() => {
    const year = Number(internalValue.slice(6, 10));
    const month = Number(internalValue.slice(0, 2));
    const day = Number(internalValue.slice(3, 5));
    const hours = Number(internalValue.slice(12, 14));
    const minutes = Number(internalValue.slice(15, 17));

    const date = new Date(year, month - 1, day, hours, minutes).valueOf();

    const slicedInternalValue = internalValue.slice(0, 17);

    try {
      if (isMaskValid(slicedInternalValue)) {
        setDate(date);
      } else {
        setDate(0);
      }
    } catch (error) {}
  }, [internalValue, isMaskValid, setDate]);

  return (
    <>
      <InputField
        value={internalValue}
        setValue={setInternalValue}
        type="text"
        mask={[
          /\d/,
          /\d/,
          '/',
          /\d/,
          /\d/,
          '/',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ',',
          ' ',
          /\d/,
          /\d/,
          ':',
          /\d/,
          /\d/,
        ]}
        placeholder="MM/DD/YYYY, HH:mm"
        label={label}
        nodeRight={
          <div
            className={cn('mm-date-field-calendar-icon-holder', {
              error: !!errorMessage,
              disabled: readonly || disabled,
            })}
            onClick={handleClickIcon}
          >
            <CalendarIcon className="calendar-icon" />
          </div>
        }
        readonly={readonly}
        disabled={disabled}
        errorMessage={errorMessage}
        comment={comment}
        onBlur={handleBlur}
      />
      {calendarOpened && (
        <Calendar
          onClose={() => setCalendarOpened(false)}
          title={calendarTitle}
          onChange={handleCalendarSelect}
          initialDate={calendarValue}
          timeActive
        />
      )}
    </>
  );
};

export { DateField };
