import React from 'react';

const SuccessIcon: React.FC = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill="url(#paint0_linear_842_7227)" />
      <path
        d="M30 51.9296L42.6167 64.5463L42.5352 64.4648L71 36"
        stroke="white"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_842_7227"
          x1="-5.58793e-07"
          y1="50"
          x2="100"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4A3AFF" />
          <stop offset="1" stopColor="#6D3AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { SuccessIcon };
