import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, matchPath, PathMatch } from 'react-router';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';

import { ListIcon } from 'assets/icons';
import { ProjectListWithPairs } from 'common/project';

import './projects.scss';

const Projects: React.FC = () => {
  const userIsAdmin = useTypedSelector(store => store.user.isAdmin);
  const projects = useTypedSelector(store => store.projects.projects);
  const [listOpened, setListOpened] = useState<boolean>(true);
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
  const { pathname } = useLocation();

  const onOpenProjectsList = useCallback((): void => {
    setListOpened(v => !v);
  }, []);

  const onSelectProject = useCallback(
    (projectId: number): void => {
      setSelectedProjectId(projectId === selectedProjectId ? null : projectId);
    },
    [selectedProjectId],
  );

  useEffect(() => {
    if (projects && projects.length !== 0) {
      const _matchPath: PathMatch<'projectId'> | null = matchPath(
        '/project/:projectId/*',
        pathname,
      );

      const projectId = _matchPath ? _matchPath?.params?.projectId ?? undefined : undefined;

      if (!isNil(projectId) && !isNaN(Number(projectId))) {
        setSelectedProjectId(Number(projectId));
      }
    }
  }, [pathname, projects]);

  return (
    <>
      <div
        className={cn('mm-sidebar__menu__item', { _active: listOpened })}
        onClick={onOpenProjectsList}
      >
        <div className="mm-sidebar__menu__item__left">
          <div className="mm-sidebar__menu__item__icon">
            <ListIcon color={listOpened ? '#5932EA' : undefined} />
          </div>
          <span className="mm-sidebar__menu__item__text">Projects</span>
        </div>
      </div>
      <motion.div
        initial={{ height: 0, overflow: 'hidden' }}
        animate={{
          height: listOpened ? 'auto' : 0,
          overflow: listOpened ? 'auto' : 'hidden',
        }}
        transition={{ easings: ['easeIn', 'easeInOut'] }}
        className={cn('mm-sidebar__projects', 'scrollable')}
      >
        {projects && projects.length === 0 && (
          <span className="mm-sidebar__projects__no-info">
            {userIsAdmin
              ? "Projects don't exist yet. Please create your first one!"
              : 'Ви не були запрошені до жодного з проектів. Запитайте адміна'}
          </span>
        )}
        {projects &&
          projects.length > 0 &&
          projects.map(project => (
            <ProjectListWithPairs
              project={project}
              onSelectProject={onSelectProject}
              selectedProjectId={selectedProjectId}
              key={project.id}
            />
          ))}
      </motion.div>
    </>
  );
};

export { Projects };
