import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { ICexOrder } from 'types/orders';
import { formatFiat, formatToken } from 'utils/formats';
import { divideBignumbers } from 'utils/formulas';

interface IAvgFilledPriceProps {
  order: ICexOrder;
}

const AvgFilledPrice = React.memo(({ order }: IAvgFilledPriceProps) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const baseTokenSymbol = useMemo(() => cexPair.token_base.symbol, [cexPair]);
  const quoteTokenSymbol = useMemo(() => cexPair.token_quote.symbol, [cexPair]);
  const decimals = useMemo(() => 18, []);

  const baseTrades = useMemo(
    () => order.trades?.filter(el => el.token.symbol === baseTokenSymbol && !el.is_fee) ?? [],
    [order, baseTokenSymbol],
  );

  const quoteTrades = useMemo(
    () => order.trades?.filter(el => el.token.symbol === quoteTokenSymbol && !el.is_fee) ?? [],
    [order, quoteTokenSymbol],
  );

  const baseAmountInTrades = useMemo(
    () =>
      baseTrades.reduce((acc, val) => acc.add(BigNumber.from(val.amount).abs()), BigNumber.from(0)),
    [baseTrades],
  );

  const quoteAmountInTrades = useMemo(
    () =>
      quoteTrades.reduce(
        (acc, val) => acc.add(BigNumber.from(val.amount).abs()),
        BigNumber.from(0),
      ),
    [quoteTrades],
  );

  const price = useMemo(
    () => divideBignumbers([quoteAmountInTrades, decimals], [baseAmountInTrades, decimals]),
    [baseAmountInTrades, quoteAmountInTrades, decimals],
  );

  const baseAmountUsdInTrades = useMemo(
    () =>
      baseTrades.reduce(
        (acc, val) => acc.add(BigNumber.from(val.amount_usd).abs()),
        BigNumber.from(0),
      ),
    [baseTrades],
  );

  const priceUsd = useMemo(
    () => divideBignumbers([baseAmountUsdInTrades, 6], [baseAmountInTrades, decimals]),
    [baseAmountUsdInTrades, baseAmountInTrades, decimals],
  );

  return (
    <div style={{ textAlign: 'left', textOverflow: 'ellipsis', minWidth: 0, overflow: 'hidden' }}>
      {price.gt(0) && (
        <>
          <span className="text-primary">{formatToken(price, decimals)} / </span>
          <span className="text-secondary">{formatFiat(priceUsd, decimals)}</span>
        </>
      )}
    </div>
  );
});

AvgFilledPrice.displayName = 'AvgFilledPrice';

export { AvgFilledPrice };
