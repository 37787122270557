import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { divideBignumbers, subtractBignumbers } from 'utils/formulas';

export const calculateWithdrawAmounts = (
  total: BigNumber,
  balances: { address: string; balance: BigNumber }[],
  decimal: number
) => {
  const balancesLength = balances.length;

  const sortedDescBalances = balances.sort((a, b) => {
    const bnABalance = BigNumber.from(a.balance);
    const bnBBalance = BigNumber.from(b.balance);

    if (bnABalance.gt(bnBBalance)) return 1;

    if (bnBBalance.gt(bnABalance)) return -1;

    return 0;
  });

  let remnantWallets = balancesLength;
  let remnantBalance = total;
  let averageBalance = divideBignumbers(
    [total, decimal],
    [BigNumber.from(parseUnits(balancesLength.toString(), decimal)), decimal]
  );

  const withdrawWalletBalances = {} as Record<string, BigNumber>;

  for (const wallet of sortedDescBalances) {
    const walletWithdrawBalance = wallet.balance.lt(averageBalance) ? wallet.balance : averageBalance;

    withdrawWalletBalances[wallet.address] = walletWithdrawBalance;

    remnantWallets = remnantWallets - 1;
    remnantBalance = subtractBignumbers([remnantBalance, decimal], [walletWithdrawBalance, decimal]);
    averageBalance = divideBignumbers(
      [remnantBalance, decimal],
      [BigNumber.from(parseUnits(remnantWallets.toString(), decimal)), decimal]
    );
  }

  return withdrawWalletBalances;
};
