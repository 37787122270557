export const toFixed = (value: number | string, fix: number): string => {
  const valueStringify = value.toString();

  const integer = valueStringify.split('.')[0];
  const float = valueStringify.split('.')[1];

  if (!float) return integer;

  const floatSlicedPart = float.slice(0, fix);

  if (!floatSlicedPart || Number(floatSlicedPart) === 0) return integer;

  return integer + '.' + floatSlicedPart;
};
