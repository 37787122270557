import React, { HTMLAttributes } from 'react';

import './style.scss';

interface IStatisticCellProps extends HTMLAttributes<HTMLTableHeaderCellElement> {
  title: string;
  subtitle?: string;
  colSpan?: number;
}

const StatisticCell: React.FC<IStatisticCellProps> = React.memo(
  ({ subtitle, title, colSpan, ...rest }) => {
    return (
      <th {...rest} colSpan={colSpan}>
        <div className="mm-statistic-table-cell">
          <span className="value">{title}</span>
          {subtitle && <span className="thin">{subtitle}</span>}
        </div>
      </th>
    );
  },
);

export default StatisticCell;
