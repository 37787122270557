import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash';

import { Bus } from 'tools';
import { useScrollPagination } from 'hooks';
import { IPairWallet } from 'api/apiPairs/models';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { useDexPairFilters } from 'store/slices/dex_pair_filters/hooks';

import { EDexPairFilters } from 'types/filters';
import { PAIR_WALLETS_RELOAD, STOP_RELOAD_PREFIX } from 'constants/reload';
import { useDexWallets } from '../api';

const useDexWalletsRecords = () => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { settedPairFilters } = useDexPairFilters({
    dexPair,
    type: EDexPairFilters.wallets,
  });
  const previousFilters = usePrevious(settedPairFilters);

  const [records, setRecords] = useState<IPairWallet[] | undefined>(undefined);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [virtualRecords, setVirtualRecords] = useState<IPairWallet[]>([]);
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});

  const getWallets = useDexWallets();

  const selectedRecords = useMemo(() => {
    const selectedRows: IPairWallet[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, records]);

  const getRecords = useCallback(
    async ({
      limit,
      lastSeenId,
    }: {
      limit: number;
      lastSeenId: number;
    }): Promise<{ records: IPairWallet[]; has_more: boolean } | undefined> => {
      try {
        const { isSuccess, errorMessage, data } = await getWallets({
          lastSeenId,
          limit,
          filters: settedPairFilters,
        });

        if (isSuccess && data) {
          if (data && data.total_connected_wallets) {
            setTotalRecords(data.total_connected_wallets);
          }

          return {
            records: data.items,
            has_more: data.has_next,
          };
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          return undefined;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [dispatch, getWallets, settedPairFilters],
  );

  const { hasMore, loading, loadMore, remount } = useScrollPagination({
    limit: 20,
    getRecords,
  });

  const handleRemount = useCallback(() => {
    setRecords(undefined);
    setRowSelection({});
    remount();
  }, [remount]);

  const handleLoadMore = useCallback(async () => {
    const newRecordsSlice = await loadMore();

    if (newRecordsSlice) {
      setRecords(v => (v ? [...v].concat([...newRecordsSlice]) : newRecordsSlice));
    }
  }, [loadMore]);

  const updateVisibleRecords = useCallback(async () => {
    const lastSeenId = virtualRecords[0] ? virtualRecords[0].id : undefined;
    const limit = virtualRecords.length;

    if (!lastSeenId || limit === 0 || !records) {
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
      return;
    }

    try {
      const startTime = Date.now();

      const { isSuccess, errorMessage, data } = await getWallets({
        lastSeenId: lastSeenId - 1,
        limit,
        filters: settedPairFilters,
      });

      const endTime = Date.now();

      if (isSuccess && data) {
        const firstSeenIndex = records.findIndex(el => el.id === lastSeenId);
        const newRecords = [...records];

        newRecords.splice(firstSeenIndex, data.items.length, ...data.items);

        setRecords(newRecords);

        if (endTime - startTime >= 1500) {
          Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
        } else {
          setTimeout(() => {
            Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
          }, 1500 - (endTime - startTime));
        }
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage ?? 'Something went wrong',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
      }
    } catch (error) {
      console.log(error);
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_WALLETS_RELOAD);
    }
  }, [virtualRecords, records, dispatch, getWallets, settedPairFilters]);

  useEffect(() => {
    if (!!previousFilters && !isEqual(settedPairFilters, previousFilters)) {
      handleRemount();
    }

    //eslint-disable-next-line
  }, [settedPairFilters]);

  useEffect(() => {
    Bus.on(PAIR_WALLETS_RELOAD, updateVisibleRecords);

    return () => {
      Bus.off(PAIR_WALLETS_RELOAD, updateVisibleRecords);
    };
  }, [updateVisibleRecords]);

  const result = useMemo(
    () => ({
      records: { get: records, set: setRecords },
      virtualRecords: { get: virtualRecords, set: setVirtualRecords },
      totalRecords,
      hasMore,
      loading,
      handleLoadMore,
      handleRemount,
      rowSelection: { get: rowSelection, set: setRowSelection },
      selectedRecords,
    }),
    [
      records,
      setRecords,
      totalRecords,
      hasMore,
      loading,
      handleLoadMore,
      handleRemount,
      selectedRecords,
      rowSelection,
      setRowSelection,
      virtualRecords,
      setVirtualRecords,
    ],
  );

  return result;
};

export { useDexWalletsRecords };
