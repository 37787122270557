import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { ITransfer } from 'types/transfers';

interface ITokenProps {
  tokenId: number;
}

const Token = React.memo(({ tokenId }: ITokenProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const token = useMemo(() => {
    const findToken = [dexPair.token_base, dexPair.token_quote, dexPair.token_fee].find(
      el => el.id === tokenId,
    );

    return findToken;
  }, [dexPair, tokenId]);

  return <div>{token ? token.symbol : '-'}</div>;
});

const tokenAccessor = (row: ITransfer) => row.token_id;

export { Token, tokenAccessor };
