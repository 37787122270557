import React, { useContext } from 'react';

import { ProjectStatisticContext } from 'context/ProjectStatisticContext';
import { PanelLoading, PanelError, PanelNoInfo } from 'ui';
import { StatisticTab, StatisticSelectRange, GeneralTables } from 'common/statistic';

import './content.scss';

const Content: React.FC = () => {
  const {
    generalLoading,
    statisticTokens,
    endDate,
    firstTradeTime,
    rangeLoading,
    selectedRange,
    startDate,
    statisticTransactions,
  } = useContext(ProjectStatisticContext);

  if (generalLoading) {
    return <PanelLoading spinnerSize="small" />;
  }

  if (!generalLoading && statisticTokens === undefined) {
    return (
      <PanelError title={'Failed'} text={'Something went wrong... Try to refresh your page.'} />
    );
  }

  if (statisticTokens && statisticTokens.length === 0) {
    return (
      <PanelNoInfo
        title={"You don't have project statistics yet"}
        text={'Setup your first trading pair!'}
      />
    );
  }

  if (statisticTokens) {
    return (
      <div className="mm-project-statistic-panel">
        <div className="range-container">
          <span className="range-label">Select statistic period:</span>
          <StatisticSelectRange
            selectedRange={selectedRange}
            startDate={startDate}
            endDate={endDate}
            loading={generalLoading || rangeLoading}
          />
        </div>
        <StatisticTab title="General">
          <GeneralTables
            selectedRange={selectedRange.get}
            statisticTokens={statisticTokens}
            statisticTransactions={statisticTransactions || []}
            firstTradeTime={firstTradeTime}
            endDate={endDate.get}
            startDate={startDate.get}
          />
        </StatisticTab>
      </div>
    );
  }

  return null;
};

export { Content };
