import React from 'react';
import { Row } from '@tanstack/react-table';

import { IWallet } from 'api/apiWallets/models';
import { TableProjects } from 'ui';

interface IProjectsCellProps {
  row: Row<IWallet>;
}

const ProjectsCell: React.FC<IProjectsCellProps> = React.memo(({ row }) => {
  return <TableProjects projects={row.original.projects} tooltip={'projects'} align="flex-end" />;
});

export default ProjectsCell;
