import React from 'react';

import { ICexOrder } from 'types/orders';

interface IUserProps {
  user: string;
}

const User = React.memo(({ user }: IUserProps) => {
  return <div style={{ textAlign: 'left' }}>{user}</div>;
});

const userAccessor = (row: ICexOrder) => row.user.login;

User.displayName = 'User';

export { User, userAccessor };
