import { ENetwork } from 'types';

import bscScanSrc from 'assets/images/scans/bscscan-logo.webp';
import ethScanSrc from 'assets/images/scans/logo-etherscan.png';
import polygonScanSrc from 'assets/images/scans/logo-polygonscan.png';
import arbiScanSrc from 'assets/images/scans/logo-arbiscan.svg';

export const calculateScanImg = (network: ENetwork) => {
  if (network === ENetwork.bsc) {
    return bscScanSrc;
  }

  if (network === ENetwork.eth) {
    return ethScanSrc;
  }

  if (network === ENetwork.polygon) {
    return polygonScanSrc;
  }

  if (network === ENetwork.arbitrum) {
    return arbiScanSrc;
  }
};
