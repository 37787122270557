import { isNil } from 'lodash';
import { mixed, number, object, string } from 'yup';

export const validationSchema = () =>
  object({
    start_at: number()
      .nullable(true)
      .test('start_at', 'Field is required', value => !isNil(value) && value >= 0),
    base_volume: string()
      .required('Field is required')
      .test(
        'base_volume',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    base_volume_deviation: string()
      .required('Field is required')
      .test(
        'base_volume_deviation',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    cancel_delay_max: number()
      .nullable(true)
      .required('Field is required')
      .test(
        'cancel_delay_max',
        'Field is required',
        (value: number | null | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    cancel_delay_min: number()
      .nullable(true)
      .required('Field is required')
      .test(
        'cancel_delay_min',
        'Field is required',
        (value: number | null | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    close_bar_before: number()
      .nullable(true)
      .required('Field is required')
      .test(
        'close_bar_before',
        'Field is required',
        (value: number | null | undefined) => !isNaN(Number(value)),
      ),
    ignore_volume_percent: string()
      .required('Field is required')
      .test(
        'ignore_volume_percent',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)),
      ),
    indicator_period: string()
      .required('Field is required')
      .test(
        'indicator_period',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    orders_max: string()
      .required('Field is required')
      .test(
        'orders_max',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    orders_min: string()
      .required('Field is required')
      .test(
        'orders_min',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    pause_between_series_max: number()
      .nullable(true)
      .required('Field is required')
      .test(
        'pause_between_series_max',
        'Field is required',
        (value: number | null | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    pause_between_series_min: number()
      .nullable(true)
      .required('Field is required')
      .test(
        'pause_between_series_min',
        'Field is required',
        (value: number | null | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    per_order_amount_dev_max: string()
      .required('Field is required')
      .test(
        'per_order_amount_dev_max',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    per_order_amount_dev_min: string()
      .required('Field is required')
      .test(
        'per_order_amount_dev_min',
        'Field is required',
        (value: string | undefined) => !isNaN(Number(value)) && Number(value) >= 0,
      ),
    time_frame: mixed().nullable(true).required('Field in required'),
  });
