import React from 'react';
import { Row } from '@tanstack/react-table';

import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

import { Pencil2Icon, TrashIcon } from 'assets/icons';
import './columnsAccounts.scss';

interface IActionButtonsAccountCellProps {
  row: Row<ICexAccounts>;
  handleEditAccount: (editableItem: any) => void;
  handleDeactivateSelectedAccounts: (editableItem: any) => void;
}

const ActionButtonsAccountCell: React.FC<IActionButtonsAccountCellProps> = React.memo(
  ({ row, handleEditAccount, handleDeactivateSelectedAccounts }) => {
    return (
      <div className={'action-button-cell'}>
        <Pencil2Icon
          onClick={() =>
            handleEditAccount({
              ...row.original,
              cex: { description: '', cex: row.original.cex },
            })
          }
        />
        <TrashIcon onClick={() => handleDeactivateSelectedAccounts([row.original.id])} />
      </div>
    );
  },
);

export default ActionButtonsAccountCell;
