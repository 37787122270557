import { useCallback, useMemo } from 'react';

import { LocalStorage } from 'tools';
import { IChartRange } from 'types/charts';
import { ECexPairPanel, EDexPairPanel } from 'types/pairs';

interface IPairConfig {
  statisticAutorefresh?: boolean;
  walletsAutorefresh?: boolean;
  transactionsAutorefresh?: boolean;
  cexAccountsAutorefresh?: boolean;
  cexStatisticAutorefresh?: boolean;
  statisticRange?: IChartRange;
  chartRange?: IChartRange;
  lastOpenedPanel?: EDexPairPanel | ECexPairPanel;
}

const usePairConfig = () => {
  const getPairConfig = useCallback((id: number): IPairConfig => {
    const pairs = LocalStorage.get<Record<number, IPairConfig>>('pair-configs');
    const pairConfig = pairs[id];

    return pairConfig ?? {};
  }, []);

  const setPairConfig = useCallback((id: number, newPairConfig: IPairConfig) => {
    const newPairs = LocalStorage.get<Record<number, IPairConfig>>('pair-configs');

    newPairs[id] = newPairConfig;

    LocalStorage.set('pair-configs', newPairs);
  }, []);

  const value = useMemo(() => ({ getPairConfig, setPairConfig }), [getPairConfig, setPairConfig]);

  return value;
};

export default usePairConfig;
