import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ApiCexPairs, ApiProjects } from 'api';
import { IProject } from 'types/project';
import { CogIcon } from 'assets/icons';
import { UDPairModal } from 'modals';
import { setSelectedCexPair, setSelectedDexPair } from 'store/slices/pairs';
import ProjectListIconPairs from '../../project/ProjectListWithPairs/PlatformIcon';
import kattanaLogoImg from 'assets/images/kattana-logo.png';
import { getPairLinkedBots } from 'utils/getPairLinkedBots';

import { EExchange } from 'types';

const cexLinkMapping: Record<string, string> = {
  gate: 'https://www.gate.io/trade',
  kucoin: 'https://www.kucoin.com/trade',
  mexc: 'https://mexc.com/exchange',
  binance: 'https://www.binance.com/trade',
};
const cexKattanaMapping: Record<string, string> = {
  gate: 'https://app.kattana.io/gateio',
  kucoin: 'https://app.kattana.io/kucoin',
  mexc: 'https://app.kattana.io/mexc',
  binance: 'https://app.kattana.io/binance',
};

interface ICexPairHeaderProps {
  pairId: number;
  projectId: number;
}

const CexPairHeader: React.FC<ICexPairHeaderProps> = ({ pairId, projectId }) => {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const projects = useTypedSelector(store => store.projects.projects);
  const isAdmin = useTypedSelector(store => store.user.isAdmin);
  const [project, setProject] = useState<IProject | null>(null);
  const [updateModalOpened, setUpdateModalOpened] = useState<boolean>(false);

  const onOpenUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(true);
  }, []);

  const onCloseUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(false);
  }, []);

  useEffect(() => {
    const setupSelectProjectAndPair = async () => {
      if (projects) {
        try {
          dispatch(setSelectedCexPair(null));
          dispatch(setSelectedDexPair(null));

          const [activeProject, activePair] = await Promise.all([
            ApiProjects.getProjectById(projectId),
            ApiCexPairs.getCexPairById(pairId),
          ]);

          if (activeProject.isSuccess && activePair.isSuccess) {
            setProject({
              created_at: activeProject.data.created_at,
              id: activeProject.data.id,
              name: activeProject.data.name,
              cexPairs: [],
              dexPairs: [],
              updated_at: '',
              notes: activeProject.data.notes,
            });

            if ('cex' in activePair.data) {
              dispatch(setSelectedCexPair(activePair.data));
            }

            await getPairLinkedBots({
              pairId: activePair.data.id,
              dispatch,
              exchange: EExchange.cex,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    setupSelectProjectAndPair();
  }, [dispatch, pathname, projects, projectId, pairId]);

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
      className="mm-header__header-pair"
    >
      {project && cexPair && (
        <>
          <div className="mm-header__header-pair__info">
            <div className="pair-name">
              <span>{cexPair.token_base.symbol}</span>
              <span>/</span>
              <span>{cexPair.token_quote.symbol}</span>
            </div>
            {isAdmin && (
              <motion.div
                whileHover={{ rotate: 180, scale: 1.3 }}
                transition={{ duration: 0.3 }}
                className="pair-settings"
                onClick={onOpenUpdateModalOpened}
              >
                <CogIcon />
              </motion.div>
            )}
            <NavLink to={`/project/${project.id}`} className="project-name">
              {project.name}
            </NavLink>
            <div className="header-trading-pair-logo-header">
              <ProjectListIconPairs platform={cexPair.cex} />
            </div>
          </div>
          <div className="mm-header__header-pair__bottom">
            <a
              className="scan-link"
              target="_blank"
              rel="noreferref noreferrer"
              href={`${cexLinkMapping[cexPair?.cex || '']}/${cexPair.symbol
                .split('/')
                .join(cexPair?.cex === 'kucoin' ? '-' : '_')}`}
            >
              <ProjectListIconPairs
                platform={cexPair.cex}
                className={'header-trading-pair-logo-bottom'}
                isIcon
              />
            </a>
            <a
              className="trade-pair-link"
              target="_blank"
              rel="noreferrer"
              href={`${cexKattanaMapping[cexPair?.cex || '']}/${cexPair.symbol}`}
            >
              <img src={kattanaLogoImg} alt="katana" />
            </a>
            {cexPair.notes && <span>{cexPair.notes}</span>}
          </div>
          {updateModalOpened && (
            <UDPairModal
              exchange={EExchange.cex}
              onClose={onCloseUpdateModalOpened}
              onOpen={onOpenUpdateModalOpened}
              pairId={cexPair.id}
              projectId={project.id}
              pairSymbol={cexPair.symbol}
              initialNotes={cexPair.notes ?? ''}
            />
          )}
        </>
      )}
    </motion.div>
  );
};

export default CexPairHeader;
