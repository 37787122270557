import React, { useState, useCallback } from 'react';

import { Formatter, FormatType } from '../../Formatter/Formatter';

interface IFormatterProps {
  format?: FormatType;
}

interface IWrappedProps {
  value?: string;
  onChange: (value: string | undefined) => void;
  formatedValue?: string;
}

export function withFormatter<T extends IFormatterProps & IWrappedProps>(
  Wrapped: React.ComponentType<T>,
) {
  return (props: IFormatterProps & T) => {
    const { onChange, format, value } = props;

    const [formatedValue, setFormatedValue] = useState<string | undefined>(value);

    const handleForFormatting = useCallback(
      (value: string): void => {
        if (format) {
          const { formatedValue, value: pureValue } = Formatter.format({ format, value });
          setFormatedValue(formatedValue);

          if (onChange) {
            onChange(pureValue);
          }
        }
      },
      [format, onChange],
    );

    if (format) {
      return <Wrapped {...props} value={formatedValue} onChange={handleForFormatting} />;
    }

    return <Wrapped {...props} onChange={onChange} value={value} />;
  };
}
