import React from 'react';
import { Table } from '@tanstack/react-table';

import { TableCheckbox } from 'ui';
import { ICexNotConnectedAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface IApiHeadProps {
  table: Table<ICexNotConnectedAccounts>;
}

const ApiHead: React.FC<IApiHeadProps> = ({ table }) => {
  return (
    <div className="checkbox-area">
      <TableCheckbox
        id="select-all-to-connect-account-cex-pair"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
      <span>API masked</span>
    </div>
  );
};

export { ApiHead };
