import { ICexName } from './common';

interface ICexOrderAccount {
  api_key_masked: string;
  id: number;
  notes: string;
}

interface ICexOrderTrade {
  amount: string;
  amount_usd: string;
  cex_trade_id: string;
  created_at: string;
  is_fee: boolean;
  token: {
    id: number;
    symbol: string;
  };
}

export type ICexOrderSide = 'buy' | 'sell';

export enum ECexOrderStatus {
  active = 'active',
  canceled = 'canceled',
  filled = 'filled',
  partially_filled = 'partially_filled',
  error = 'error',
}

export interface ICexOrder {
  account: ICexOrderAccount;
  account_id: number;
  amount_usd: string;
  base_amount: string;
  cex: ICexName;
  cex_client_order_id: string;
  cex_order_id: string;
  created_at: string;
  id: number;
  metadata: {
    price?: string;
  };
  pair_id: number;
  project_id: number;
  quote_amount: string;
  sequence_no: number;
  side: ICexOrderSide;
  status: ECexOrderStatus;
  trades?: ICexOrderTrade[];
  updated_at: string;
  user: {
    id: number;
    login: string;
  };
  user_id: number;
}

export interface ICexOrderTransfer {
  cex_trade_id: string;
  created_at: string;
  tokens: ICexOrderTrade[];
}

export interface ICexOrderAgregatedTrades extends ICexOrder {
  transfers: ICexOrderTransfer[];
}
