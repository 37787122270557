export enum EExchange {
  cex = 'cex',
  dex = 'dex',
}

export enum ENetwork {
  eth = 'eth',
  bsc = 'bsc',
  polygon = 'polygon',
  arbitrum = 'arbitrum_one',
}

export const networks: { network: ENetwork; label: string }[] = [
  {
    network: ENetwork.eth,
    label: 'Ethereum',
  },
  {
    network: ENetwork.bsc,
    label: 'BSC',
  },
  {
    network: ENetwork.polygon,
    label: 'Polygon',
  },
  {
    network: ENetwork.arbitrum,
    label: 'Arbitrum',
  },
];

export type IDexName =
  | 'uniswap_v3'
  | 'uniswap_v2'
  | 'pancakeswap_v2'
  | 'quickswap_v2'
  | 'sushiswap_v2'
  | 'uniswap_v3:arbitrum_one'
  | 'camelot_v2';

export type ICexName = 'gate' | 'kucoin' | 'mexc' | 'binance';
