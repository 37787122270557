import { IDexPair } from 'types/pairs';
import { dexPairWalletsFiltersScheme, dexBoostHoldersWalletsFiltersScheme } from './dex_pair';
import { IDexFilterValue } from './common';

export enum EDexPairFilters {
  wallets = 'wallets',
  boostHoldersWallets = 'boostHoldersWallets',
}

export const dexPairFiltersScheme = ({
  pair,
  type,
}: {
  pair: IDexPair | undefined;
  type: EDexPairFilters;
}) => {
  return pair
    ? {
        [EDexPairFilters.wallets]: dexPairWalletsFiltersScheme(pair),
        [EDexPairFilters.boostHoldersWallets]: dexBoostHoldersWalletsFiltersScheme(pair),
      }[type]
    : undefined;
};

export type IDexPairFilters = {
  [EDexPairFilters.wallets]?: {
    filters: IDexFilterValue[];
    setted: IDexFilterValue[];
  };
  [EDexPairFilters.boostHoldersWallets]?: {
    filters: IDexFilterValue[];
    setted: IDexFilterValue[];
  };
};

type IPairId = number;
export type IDexPairsFilters = Record<
  IPairId,
  { updated_at: string; filters: IDexPairFilters } | undefined
>;
