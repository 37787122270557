import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';
import { BigNumber } from '@ethersproject/bignumber';

import { StatisticTable, StatisticHeader, StatisticCell } from 'common/statistic';
import { IStatisticToken, IStatisticTransaction } from 'api/apiStatistic/models';
import { ETransactionAction } from 'api/apiTransactions/models';
import { addBignumbers, subtractBignumbers } from 'utils/formulas';
import { formatFiat, formatToken, formatNatural } from 'utils/formats';

interface IBoostHoldersAlgorithmTableProps {
  statisticTokens: IStatisticToken[];
  statisticTransactions: IStatisticTransaction[];
}

const BoostHoldersAlgorithmTable: React.FC<IBoostHoldersAlgorithmTableProps> = ({
  statisticTokens,
  statisticTransactions,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const baseToken = useMemo(
    () => statisticTokens.find(el => el.token.address === pair!.token_base.address),
    [pair, statisticTokens],
  );

  const newHolders = useMemo(() => {
    if (!baseToken) return 0;

    return baseToken.finish_holders - baseToken.start_holders;
  }, [baseToken]);

  const holders = useMemo(() => {
    if (!baseToken) return 0;

    return baseToken.finish_holders;
  }, [baseToken]);

  const volumes = useMemo(() => {
    if (!baseToken)
      return {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      };

    const actions = [
      ETransactionAction.ActionBuyEth,
      ETransactionAction.ActionBuyEthHolder,
      ETransactionAction.ActionSellEth,
    ];

    return baseToken.volumes.reduce(
      (acc, val) => {
        if (!actions.includes(val.action)) return acc;

        return {
          volumeTokens: addBignumbers(
            [acc.volumeTokens, 18],
            [BigNumber.from(val.amount), baseToken.token.decimals],
          ),
          volumeUsd: addBignumbers([acc.volumeUsd, 18], [BigNumber.from(val.amount_usd), 6]),
        };
      },
      { volumeTokens: BigNumber.from(0), volumeUsd: BigNumber.from(0) },
    );
  }, [baseToken]);

  const balancedAmount = useMemo(() => {
    if (!baseToken)
      return {
        balancedTokens: BigNumber.from(0),
        balancedUsd: BigNumber.from(0),
      };

    const actions = [ETransactionAction.ActionSellEth];

    return baseToken.volumes.reduce(
      (acc, val) => {
        if (!actions.includes(val.action)) return acc;

        return {
          balancedTokens: addBignumbers(
            [acc.balancedTokens, 18],
            [BigNumber.from(val.amount), baseToken.token.decimals],
          ),
          balancedUsd: addBignumbers([acc.balancedUsd, 18], [BigNumber.from(val.amount_usd), 6]),
        };
      },
      { balancedTokens: BigNumber.from(0), balancedUsd: BigNumber.from(0) },
    );
  }, [baseToken]);

  const convertedInBnb = useMemo(() => {
    if (!baseToken)
      return {
        tokens: BigNumber.from(0),
        usd: BigNumber.from(0),
      };

    const actionsAdd = [ETransactionAction.ActionBuyEth, ETransactionAction.ActionBuyEthHolder];

    const actionsSubtract = [ETransactionAction.ActionSellEth];

    return baseToken.volumes.reduce(
      (acc, val) => {
        if (!actionsAdd.includes(val.action) && !actionsSubtract.includes(val.action)) return acc;

        if (actionsAdd.includes(val.action)) {
          return {
            tokens: addBignumbers(
              [acc.tokens, 18],
              [BigNumber.from(val.amount), baseToken.token.decimals],
            ),
            usd: addBignumbers([acc.usd, 18], [BigNumber.from(val.amount_usd), 6]),
          };
        }

        return {
          tokens: subtractBignumbers(
            [acc.tokens, 18],
            [BigNumber.from(val.amount), baseToken.token.decimals],
          ),
          usd: subtractBignumbers([acc.usd, 18], [BigNumber.from(val.amount_usd), 6]),
        };
      },
      { tokens: BigNumber.from(0), usd: BigNumber.from(0) },
    );
  }, [baseToken]);

  const transactions = useMemo(() => {
    if (!baseToken)
      return {
        total: 0,
        failed: 0,
      };

    const actions = [
      ETransactionAction.ActionBuyEth,
      ETransactionAction.ActionBuyEthHolder,
      ETransactionAction.ActionSellEth,
    ];

    return statisticTransactions.reduce(
      (acc, val) => {
        if (!actions.includes(val.action)) return acc;

        return {
          total: acc.total + val.total,
          failed: acc.failed + val.failed,
        };
      },
      { total: 0, failed: 0 },
    );
  }, [statisticTransactions, baseToken]);

  const feeSpent = useMemo(() => {
    const actions = [
      ETransactionAction.ActionBuyEth,
      ETransactionAction.ActionBuyEthHolder,
      ETransactionAction.ActionSellEth,
    ];

    const feeToken = statisticTokens.find(el => el.token.address === pair!.token_fee.address);

    if (!feeToken || !feeToken.spent_fees)
      return {
        feeTokens: BigNumber.from(0),
        feeUsd: BigNumber.from(0),
      };

    return feeToken.spent_fees
      .filter(el => actions.includes(el.action))
      .reduce(
        (acc, val) => {
          return {
            feeTokens: addBignumbers(
              [BigNumber.from(val.amount), feeToken.token.decimals],
              [acc.feeTokens, 18],
            ),
            feeUsd: addBignumbers([BigNumber.from(val.amount_usd), 6], [acc.feeUsd, 18]),
          };
        },
        {
          feeTokens: BigNumber.from(0),
          feeUsd: BigNumber.from(0),
        },
      );
  }, [statisticTokens, pair]);

  return (
    <StatisticTable>
      <thead>
        <tr>
          <StatisticHeader title={'New holders/Holders '} />
          <StatisticHeader title={'Volume'} />
          <StatisticHeader title={'Balanced amount'} />
          <StatisticHeader title={`Converted in ${pair?.token_fee.symbol}`} />
          <StatisticHeader title={'Transactions'} />
          <StatisticHeader title={'Fee spent'} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <StatisticCell title={newHolders.toString()} subtitle={`/ ${holders}`} />
          <StatisticCell
            title={formatToken(volumes.volumeTokens, 18) + ' ' + pair!.token_base.symbol}
            subtitle={`(${formatFiat(volumes.volumeUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(balancedAmount.balancedTokens, 18) + ' ' + pair!.token_base.symbol}
            subtitle={`(${formatFiat(balancedAmount.balancedUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(convertedInBnb.tokens, 18) + ' ' + pair!.token_base.symbol}
            subtitle={`(${formatFiat(convertedInBnb.usd, 18)})`}
          />
          <StatisticCell
            title={formatNatural(transactions.total.toString())}
            subtitle={`(${formatNatural(transactions.failed.toString())} failed)`}
          />
          <StatisticCell
            title={formatToken(feeSpent.feeTokens) + ' ' + pair!.token_fee.symbol}
            subtitle={`(${formatFiat(feeSpent.feeUsd, 18)})`}
          />
        </tr>
      </tbody>
    </StatisticTable>
  );
};

export default BoostHoldersAlgorithmTable;
