import React, { HTMLAttributes, useCallback } from 'react';
import cn from 'classnames';

import { EChartConvertedFormat } from 'types/charts';

import './chartConvertedFormat.scss';

interface IChartConvertedFormatProps extends HTMLAttributes<HTMLDivElement> {
  supportedFormats: EChartConvertedFormat[];
  disabledFormats?: EChartConvertedFormat[];
  selectedFormat: EChartConvertedFormat;
  setSelectedFormat: (v: EChartConvertedFormat) => void;
  disabled?: boolean;
}

const ChartConvertedFormat: React.FC<IChartConvertedFormatProps> = ({
  className,
  supportedFormats,
  selectedFormat,
  disabledFormats = [],
  setSelectedFormat,
  disabled = false,
  ...rest
}) => {
  const modeToCaption = useCallback((priceMode: EChartConvertedFormat) => {
    if (priceMode === EChartConvertedFormat.Original) {
      return 'Original';
    }
    if (priceMode === EChartConvertedFormat.Converted) {
      return 'Converted USD';
    }

    return undefined;
  }, []);

  return (
    <div {...rest} className={cn({ [className ?? '']: !!className }, 'mm-chart-converted-formats')}>
      {supportedFormats.map((el) => {
        const caption = modeToCaption(el);

        const isSelected = el === selectedFormat;
        const isDisabled = disabledFormats.includes(el) || disabled;

        if (!caption) return null;

        return (
          <div
            key={caption}
            className={cn('chart-converted-format-item', {
              '_is-selected': isSelected,
              '_is-disabled': isDisabled,
            })}
            onClick={() => !isDisabled && setSelectedFormat(el)}
          >
            {caption}
          </div>
        );
      })}
    </div>
  );
};

export default ChartConvertedFormat;
