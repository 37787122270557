import React from 'react';

import { ENetwork } from 'types';
import { ETransactionAction, ITransactionItem } from 'api/apiTransactions/models';
import { TableAddressChip } from 'ui';
import { IDexPair } from 'types/pairs';

interface IWalletProps {
  address?: string;
  network?: ENetwork;
}

export const Wallet: React.FC<IWalletProps> = ({ address, network }) => {
  if (!address || !network) return <>{''}</>;

  return (
    <TableAddressChip
      address={address}
      addressType={'address'}
      network={network}
      format={'short'}
    />
  );
};

export const walletAccessor = (row: ITransactionItem, pair: IDexPair) => {
  if (!row.transfers || row.transfers.length === 0) return null;

  if (
    row.action === ETransactionAction.ActionBuyEthHolder ||
    row.action === ETransactionAction.ActionBuyEth
  ) {
    const bnbToWalletTransfer = row.transfers.find(
      el => el.is_fee === false && el.token.id === pair.token_fee.id,
    );

    if (!bnbToWalletTransfer) return null;

    return {
      address: bnbToWalletTransfer.wallet.address,
      network: row.network,
    };
  }

  if (
    row.transfers.filter(transfer => transfer.wallet.id === row.transfers![0].wallet.id).length !==
    row.transfers.length
  )
    return null;

  return { address: row.transfers[0].wallet.address, network: row.network };
};
