import React, { useCallback, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { IPairBot } from 'types/bots';
import { PairCexTableConnectedBots } from '../PairCexTableConnectedBots';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface ICexConnectedBotsCell {
  meta: any;
  row: any;
}

const CexConnectedBotsCell: React.FC<ICexConnectedBotsCell> = ({ meta, row }) => {
  const pair = useTypedSelector(store => store.pairs.selectedCexPair);
  const { setRecords } = useMemo(() => meta, [meta]);
  const connectedBots: IPairBot[] | undefined = useMemo(() => row.original.connected_bots, [row]);
  const accountId = useMemo(() => row.original.id, [row]);
  const onChangeConnectedBots = useCallback(
    (new_connected_bots: IPairBot[]) => {
      setRecords((records: ICexAccounts[] | undefined) => {
        const newRecords = records === undefined ? [] : [...records];
        const botIndexOf = newRecords.findIndex(el => el.id === row.original.id);

        newRecords[botIndexOf] = {
          ...newRecords[botIndexOf],
          connected_bots: new_connected_bots,
        };

        return newRecords;
      });
    },
    [setRecords, row.original.id],
  );
  return (
    <PairCexTableConnectedBots
      connected_bots={connectedBots}
      pair={pair!}
      accountId={accountId}
      onChangeConnectedBots={onChangeConnectedBots}
    />
  );
};

export default React.memo(CexConnectedBotsCell);
