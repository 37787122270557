import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector, useTypedDispatch } from 'store';

import { CopyPopup } from 'ui';
import { MM_MAIN_ELEMENT_ID } from 'tools/constants';
import { getMe } from 'store/slices/user';
import { getAllProjectsAndPairs } from 'store/slices/projects';

import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';

import './applicationLayout.scss';

interface IApplicationLayout {
  children: React.ReactNode;
}

const ApplicationLayout: React.FC<IApplicationLayout> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const userIsAuthenticated = useTypedSelector(store => Boolean(store.auth.jwt));
  const user = useTypedSelector(store => store.user.user);
  const isUser = useTypedSelector(store => store.user.isUser);

  useEffect(() => {
    if (userIsAuthenticated) {
      dispatch(getMe({}));
    }
  }, [userIsAuthenticated, dispatch]);

  useEffect(() => {
    const redirectToFirstProjectPair = ({ projectId }: { projectId: number | undefined }) => {
      if (projectId && user && isUser && location.pathname === '/') {
        navigate(`/project/${projectId}`);
      }
    };

    if (userIsAuthenticated && user) {
      dispatch(getAllProjectsAndPairs({ redirect: redirectToFirstProjectPair }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsAuthenticated, user, dispatch]);

  return (
    <div className="mm-application-layout">
      <Sidebar />
      <div className="mm-application-layout__main__holder">
        <section id={MM_MAIN_ELEMENT_ID} className="mm-application-layout__main scrollable">
          <Header />
          <main className="mm-application-layout__content">{children}</main>
        </section>
        <CopyPopup />
      </div>
    </div>
  );
};

export { ApplicationLayout };
