import { useCallback } from 'react';
import { useTypedSelector } from 'store';

import { ECexBot } from 'types/bots';
import { ApiBot } from 'api';

const useLoadFillGapsSettings = () => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const load = useCallback(async () => {
    try {
      const { isSuccess, data } = await ApiBot.getCexBotConfig({
        pairId: cexPair.id,
        bot: ECexBot.fill_the_gaps,
      });

      if (isSuccess) return data;
      return undefined;
    } catch (error) {
      return undefined;
    }
  }, [cexPair.id]);

  return load;
};

export default useLoadFillGapsSettings;
