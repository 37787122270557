import React, { useEffect, useCallback, useContext, useState, useRef, useMemo } from 'react';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';
import { useTypedSelector } from 'store';
import axios from 'axios';
import {
  createChart,
  CrosshairMode,
  IChartApi,
  PriceScaleMode,
  UTCTimestamp,
} from 'lightweight-charts';
import { useDebounce } from 'react-use';

import { Spinner } from 'ui';
import { ApiWTBot } from 'api';
import { DEXES_FEES } from 'api/apiDictionary/models/IDex';
import { formatFiat, formatToken, normalizeNumber } from 'utils/formats';
import { addBignumbers, multiplyBignumbers } from 'utils/formulas';
import { chartFormatter } from 'utils/charts';
import { durationToMs } from 'utils/duration';
import { chartVolumeBarColor } from 'constants/charts';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { IDexName } from 'types';

import TimeframeController from '../TimeframeController/TimeframeController';
import ReseedButton from '../ReseedButton/ReseedButton';
import ActionButtons from '../ActionButtons/ActionButtons';
import { PairTooltip } from './PairTooltip';

import './chart.scss';

interface IChartCandle {
  time: number;
  open: number;
  close: number;
  low: number;
  high: number;
  volume: number;
}

const Chart: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const {
    chartLoading,
    chartErrorMessage,
    chartFormInfo,
    currentConfig,
    CancelTokenRef,
    triggerLoadChart,
    useAdditionalReserves,
    manualPooledBase,
    manualPooledQuote,
  } = useContext(PairWashTradingContext);

  const chartHolderRef = useRef<HTMLDivElement>(null);
  const pairChartRef = useRef<HTMLDivElement>(null);
  const pairAPIRef = useRef<IChartApi | undefined>(undefined);

  const [tooltipIndex, setTooltipIndex] = useState<number | undefined>(undefined);
  const [firstVisibleIndex, setFirstVisibleIndex] = useState<number | undefined>(undefined);
  const [lastVisibleIndex, setLastVisibleIndex] = useState<number | undefined>(undefined);

  const hoveredIndex = useMemo(
    () => (tooltipIndex === undefined ? lastVisibleIndex : tooltipIndex),
    [lastVisibleIndex, tooltipIndex],
  );

  const [volumeDaily, setVolumeDaily] = useState<string | undefined>(undefined);
  const [feesDaily, setFeesDaily] = useState<string | undefined>(undefined);
  const [baseAmount, setBaseAmount] = useState<string | undefined>(undefined);
  const [quoteAmount, setQuoteAmount] = useState<string | undefined>(undefined);

  const [chartCandles, setChartCandles] = useState<IChartCandle[]>([]);

  const [dexFeePercentage, setDexFeePercentage] = useState<number>(0);
  const [dexFee, setDexFee] = useState<string | undefined>(undefined);

  const removePairChart = useCallback(() => {
    if (pairAPIRef && !!pairAPIRef.current) {
      pairAPIRef.current.remove();
    }
  }, [pairAPIRef]);

  const initChart = useCallback(async () => {
    if (!pairChartRef.current) return;

    if (currentConfig.get) {
      try {
        chartLoading.set(true);
        chartErrorMessage.set(undefined);

        const options: any = { ...currentConfig.get, pair_id: dexPair.id };

        const isManualBPooledValid =
          manualPooledBase.get !== '' && !isNaN(Number(manualPooledBase.get));
        const isManualQPooledValid =
          manualPooledQuote.get !== '' && !isNaN(Number(manualPooledQuote.get));

        if (useAdditionalReserves.get && isManualBPooledValid && isManualQPooledValid) {
          options.pair_id = undefined;
          options.dex = dexPair.dex;

          //TODO remove this case
          if (options.dex === 'uniswap_v3:arbitrum_one') {
            options.dex = 'uniswap_v2';
          }

          options.reserve_base = parseUnits(
            manualPooledBase.get,
            dexPair.token_base.decimals,
          ).toString();
          options.reserve_quote = parseUnits(
            manualPooledQuote.get,
            dexPair.token_quote.decimals,
          ).toString();
        }

        const { isSuccess, errorMessage, data } = await ApiWTBot.getWTSimulationResult(
          {
            options,
          },
          { cancelToken: CancelTokenRef.current?.token ?? undefined },
        );

        chartLoading.set(false);

        if (!isSuccess && !axios.isCancel(CancelTokenRef.current?.token.reason)) {
          chartErrorMessage.set(errorMessage);
          return;
        }

        if (data) {
          currentConfig.set({ ...currentConfig.get, seed: Number(data.actual_seed) });

          const candlesRecords = data.items.map(el => ({
            time: Math.floor(dayjs(el.time).valueOf() / 1000),
            open: Number(el.open),
            close: Number(el.close),
            low: Number(el.low),
            high: Number(el.high),
            volume: Number(
              formatUnits(normalizeNumber(el.volume), dexPair.token_base.decimals ?? 18),
            ),
          }));

          setChartCandles(candlesRecords);

          setVolumeDaily(
            `${formatToken(BigNumber.from(data.volumes.day), dexPair.token_base.decimals)} ${
              dexPair.token_base.symbol
            } / ${formatFiat(
              multiplyBignumbers(
                [BigNumber.from(data.volumes.day), dexPair.token_base.decimals],
                [BigNumber.from(data.token_base.price_usd ?? '0'), 6],
              ),
              18,
              '$',
            )}`,
          );
          setFeesDaily(
            `${formatToken(BigNumber.from(data.fees.day ?? '0'), dexPair.token_fee.decimals)} ${
              dexPair.token_fee.symbol
            } / ${formatFiat(
              multiplyBignumbers(
                [BigNumber.from(data.fees.day ?? '0'), dexPair.token_fee.decimals],
                [BigNumber.from(dexPair.token_fee.price_usd), 6],
              ),
              18,
              '$',
            )}`,
          );
          setBaseAmount(
            `${formatToken(
              BigNumber.from(data.base_amounts.min),
              dexPair.token_base.decimals,
            )} / ${formatToken(
              BigNumber.from(data.base_amounts.avg),
              dexPair.token_base.decimals,
            )} / ${formatToken(
              BigNumber.from(data.base_amounts.max),
              dexPair.token_base.decimals,
            )}`,
          );
          setQuoteAmount(
            `${formatToken(
              BigNumber.from(data.quote_amounts.min),
              dexPair.token_quote.decimals,
            )} / ${formatToken(
              BigNumber.from(data.quote_amounts.avg),
              dexPair.token_quote.decimals,
            )} / ${formatToken(
              BigNumber.from(data.quote_amounts.max),
              dexPair.token_quote.decimals,
            )}`,
          );

          const _dexPercentage = DEXES_FEES[dexPair.dex as IDexName]?.fee ?? 0;
          setDexFeePercentage(_dexPercentage);

          const _dexPercentageString = (_dexPercentage / 100).toFixed(6);

          const dexFeeTokens = multiplyBignumbers(
            [BigNumber.from(data.volumes.day), dexPair.token_base.decimals],
            [
              parseUnits(_dexPercentageString, dexPair.token_fee.decimals),
              dexPair.token_fee.decimals,
            ],
          );
          const dexFeeUsd = multiplyBignumbers(
            [dexFeeTokens, 18],
            [BigNumber.from(dexPair.token_base.price_usd), 6],
          );

          setDexFee(
            `${formatToken(dexFeeTokens)} ${dexPair.token_base.symbol} / ${formatFiat(dexFeeUsd)}`,
          );

          chartFormInfo.set({
            pooledBaseToken: formatToken(
              BigNumber.from(data.token_base.amount),
              dexPair.token_base.decimals,
            ),
            pooledFeeToken: formatToken(
              BigNumber.from(data.token_quote.amount),
              dexPair.token_quote.decimals,
            ),
            poolLiquidity: formatFiat(
              addBignumbers(
                [BigNumber.from(data.token_base.amount_usd ?? '0'), 6],
                [BigNumber.from(data.token_quote.amount_usd ?? '0'), 6],
              ),
              18,
              '$',
            ),
            tokenPrice: `${formatToken(
              BigNumber.from(data.token_base.price ?? '0'),
              dexPair.token_quote.decimals,
            )} / ${formatFiat(BigNumber.from(data.token_base.price_usd ?? '0'), 6, '$')}`,
          });

          // start init chart
          const pairChart = createChart(pairChartRef.current as HTMLElement, {
            crosshair: {
              mode: CrosshairMode.Normal,
            },
            rightPriceScale: {
              mode: PriceScaleMode.Normal,
              borderColor: '#d8dade',
            },
            grid: {
              horzLines: {
                color: '#ebedf2',
              },
              vertLines: {
                color: '#ebedf2',
              },
            },
            layout: {
              background: {
                color: 'transparent',
              },
            },
            timeScale: {
              timeVisible: true,
              secondsVisible: false,
              fixLeftEdge: true,
              fixRightEdge: true,
              borderColor: '#d8dade',
            },
            localization: {
              priceFormatter: chartFormatter,
            },
          });

          // init records
          const candleStickSeries = pairChart.addCandlestickSeries();
          candleStickSeries.priceScale().applyOptions({ scaleMargins: { top: 0, bottom: 0.2 } });

          candleStickSeries.setData(
            candlesRecords.map(el => ({
              time: el.time as UTCTimestamp,
              open: el.open,
              close: el.close,
              low: el.low,
              high: el.high,
            })),
          );

          const volumeSeries = pairChart.addHistogramSeries({
            color: chartVolumeBarColor,
            priceFormat: {
              type: 'volume',
            },
            priceScaleId: '',
            lastValueVisible: false,
          });
          volumeSeries.priceScale().applyOptions({ scaleMargins: { top: 0.8, bottom: 0 } });

          volumeSeries.setData(
            candlesRecords.map(el => ({
              time: el.time as UTCTimestamp,
              value: el.volume,
            })),
          );

          pairChart.timeScale().fitContent();

          pairChart.timeScale().subscribeVisibleLogicalRangeChange(logicalRange => {
            if (!logicalRange) return;

            setFirstVisibleIndex(Math.ceil(logicalRange.from));
            setLastVisibleIndex(Math.floor(logicalRange.to));
          });

          pairChart.subscribeCrosshairMove(function (param) {
            if (param.time) {
              setTooltipIndex(param.logical ?? undefined);
            } else {
              setTooltipIndex(undefined);
            }
          });

          removePairChart();

          pairAPIRef.current = pairChart;
        }
      } catch (error) {
        console.log('error: ', error);

        if (axios.isCancel(error) || axios.isCancel(CancelTokenRef.current?.token.reason)) {
          return;
        }
        chartErrorMessage.set('Chart configuration suddenly failed...');
      } finally {
        chartLoading.set(false);
      }
    }

    //eslint-disable-next-line
  }, [dexPair, triggerLoadChart.get]);

  useDebounce(() => initChart(), 500, [initChart]);

  useEffect(() => {
    const pairResize = (entry: ResizeObserverEntry[]) => {
      if (entry.length === 0) return;
      pairAPIRef.current?.applyOptions({
        width: entry[0].contentRect.width,
        height: entry[0].contentRect.height,
      });
    };

    let pairResizeObserver: ResizeObserver | undefined = undefined;

    const pairChartContainer = pairChartRef.current;

    if (pairChartContainer) {
      pairResizeObserver = new ResizeObserver(pairResize);
      pairResizeObserver.observe(pairChartContainer);
    }

    return () => {
      if (pairChartContainer && pairResizeObserver) {
        pairResizeObserver.unobserve(pairChartContainer);
      }
    };
  }, []);

  const dailyTransactions = useMemo(() => {
    if (!currentConfig.get) return '-';

    try {
      const minDelay = durationToMs(
        currentConfig.get.config.random_strategy.min_pause_between_trades,
      );
      const maxDelay = durationToMs(
        currentConfig.get.config.random_strategy.max_pause_between_trades,
      );

      const oneDay = durationToMs('24h');

      return Math.floor(oneDay / ((minDelay + maxDelay) / 2));
    } catch {
      return '-';
    }
  }, [currentConfig.get]);

  return (
    <div className="mm-wt-bot-chart-wrapper">
      <div className="mm-wt-bot-chart-wrapper__toolbar">
        <TimeframeController />
        <ReseedButton />
        <ActionButtons />
      </div>
      <div className="chart" ref={chartHolderRef}>
        <div className="pair-chart" ref={pairChartRef} />
        {hoveredIndex !== undefined && (
          <PairTooltip
            close={chartCandles[hoveredIndex]?.close ?? 0}
            high={chartCandles[hoveredIndex]?.high ?? 0}
            low={chartCandles[hoveredIndex]?.low ?? 0}
            open={chartCandles[hoveredIndex]?.open ?? 0}
            volume={chartCandles[hoveredIndex]?.volume ?? 0}
            startPrice={
              firstVisibleIndex !== undefined
                ? chartCandles[firstVisibleIndex]?.close ?? 0
                : undefined
            }
          />
        )}
        {chartLoading.get && (
          <div className="chart__loading">
            <Spinner size="small" />
            <span className="chart__loading__title">Loading chart...</span>
          </div>
        )}
        {!chartLoading.get && !!chartErrorMessage.get ? (
          <div className="chart__error">
            <span className="chart__error__title">Simulation failed with error:</span>
            <span className="chart__error__subtitle">{chartErrorMessage.get}</span>
          </div>
        ) : null}
      </div>
      <div>
        {!chartLoading.get && !chartErrorMessage.get && (
          <div className="chart-summary">
            <div className="summary-section">
              <span>Volume daily:</span>
              <span>{volumeDaily}</span>
            </div>
            <div className="summary-section">
              <span>{dexPair.token_base.symbol} amount (min/avg/max):</span>
              <span>{baseAmount}</span>
            </div>
            <div className="summary-section">
              <span>Fees daily:</span>
              <span>{feesDaily}</span>
            </div>
            <div className="summary-section">
              <span>{dexPair.token_quote.symbol} amount (min/avg/max):</span>
              <span>{quoteAmount}</span>
            </div>
            <div className="summary-section">
              <span>Dex fee ({dexFeePercentage}%):</span>
              <span>{dexFee}</span>
            </div>
            <div className="summary-section">
              <span>Daily transactions:</span>
              <span>{dailyTransactions}</span>
            </div>
          </div>
        )}
        {!chartLoading.get && !!chartErrorMessage.get && (
          <div className="chart-summary__error">
            <span className="chart-summary__error__title">Simulation failed...</span>
          </div>
        )}
        {!!chartLoading.get && (
          <div className="chart-summary__loader">
            <Spinner size="small" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chart;
