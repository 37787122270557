import React from 'react';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';

import { ICexWTRegularTask } from 'types/bots';

dayjs.extend(utcPlugin);

interface ITimeProps {
  task: ICexWTRegularTask;
}

export const Time: React.FC<ITimeProps> = ({ task }) => {
  return (
    <div className="time-cell">
      <span>{task.start_at ? dayjs(task.start_at).format('L LT') : '~'}</span>
      <span>-</span>
      <span>{task.end_at ? dayjs(task.end_at).format('L LT') : '~'}</span>
    </div>
  );
};
