import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useTypedDispatch } from 'store';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';

import { useScrollPagination } from 'hooks';
import { ApiWallets } from 'api';
import { IWallet } from 'api/apiWallets/models';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { TableToolbar } from 'ui';
import { Bus } from 'tools';
import { STOP_RELOAD_PREFIX, PROJECT_WALLETS_RELOAD } from 'constants/reload';

import {
  WalletAddressHead,
  BalanceHead,
  TokensHead,
  TransactionsHead,
  ProjectsHead,
} from './headers';
import {
  WalletAddressCell,
  BalanceCell,
  TokensCell,
  TransactionsCell,
  ProjectsCell,
} from './columns';

import './table.scss';

interface IWalletsTable {
  triggerRemount: boolean;
}

const WalletsTable: React.FC<IWalletsTable> = ({ triggerRemount }) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useTypedDispatch();

  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
  const [records, setRecords] = useState<IWallet[] | undefined>(undefined);
  const [virtualRecords, setVirtualRecords] = useState<IWallet[]>([]);

  const handleGetMoreItems = useCallback(
    async ({ limit, lastSeenId }: { limit: number; lastSeenId: number }) => {
      try {
        const { isSuccess, data, errorMessage } = await ApiWallets.getWallets({
          limit,
          lastSeenId,
        });

        if (isSuccess) {
          return {
            has_more: data.has_next,
            records: data.items,
          };
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );

          return undefined;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [dispatch],
  );

  const { hasMore, loading, loadMore, remount } = useScrollPagination({
    limit: 20,
    getRecords: handleGetMoreItems,
  });

  const selectedWallets = useMemo(() => {
    const selectedRows: IWallet[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, records]);

  useEffect(() => {
    if (records && records.length !== 0) {
      remount();
    }
  }, [triggerRemount]);

  const columns = useMemo<ColumnDef<IWallet>[]>(
    () => [
      {
        id: 'select',
        accessorFn: row => row,
        header: ({ table }) => <WalletAddressHead table={table} />,
        cell: ({ row }) => {
          return <WalletAddressCell row={row} />;
        },
      },
      {
        id: 'balance',
        accessorFn: row => row,
        header: () => <BalanceHead />,
        cell: ({ row }) => {
          return <BalanceCell row={row} />;
        },
      },
      {
        id: 'tokens',
        accessorFn: row => row,
        header: () => <TokensHead />,
        cell: ({ row }) => {
          return <TokensCell row={row} />;
        },
      },
      {
        id: 'transactions',
        accessorFn: row => row,
        header: () => <TransactionsHead />,
        cell: ({ row }) => {
          return <TransactionsCell row={row} />;
        },
      },
      {
        id: 'projects',
        accessorFn: row => row,
        header: () => <ProjectsHead />,
        cell: ({ row }) => {
          return <ProjectsCell row={row} />;
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: useMemo(() => records ?? [], [records]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const handleLoadMore = useCallback(async () => {
    const newRecordsSlice = await loadMore();

    if (newRecordsSlice) {
      setRecords(v => (v ? [...v].concat([...newRecordsSlice]) : newRecordsSlice));
    }
  }, [loadMore]);

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      handleLoadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      handleLoadMore();
    }
  }, [rowVirtualizer.virtualItems, hasMore, loading, handleLoadMore, rows.length]);

  useEffect(() => {
    if (rows) {
      setVirtualRecords(virtualRows.map(({ index }) => rows[index].original));
    }
  }, [virtualRows]);

  const updateVisibleRecords = useCallback(async () => {
    const lastSeenId = virtualRecords[0] ? virtualRecords[0].id : undefined;
    const limit = virtualRecords.length;

    if (!lastSeenId || limit === 0 || !records) {
      Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
      return;
    }

    try {
      const startTime = Date.now();

      const { isSuccess, data, errorMessage } = await ApiWallets.getWallets({
        lastSeenId: lastSeenId - 1,
        limit,
      });

      const endTime = Date.now();

      if (isSuccess) {
        const firstSeenIndex = records.findIndex(el => el.id === lastSeenId);
        const newRecords = [...records];

        newRecords.splice(firstSeenIndex, data.items.length, ...data.items);

        setRecords(newRecords);

        if (endTime - startTime >= 1500) {
          Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
        } else {
          setTimeout(() => {
            Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
          }, 1500 - (endTime - startTime));
        }
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
      }
    } catch (error) {
      console.log(error);
      Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
    }
  }, [virtualRecords, records]);

  useEffect(() => {
    Bus.on(PROJECT_WALLETS_RELOAD, updateVisibleRecords);

    return () => {
      Bus.off(PROJECT_WALLETS_RELOAD, updateVisibleRecords);
    };
  }, [updateVisibleRecords]);

  return (
    <>
      <TableToolbar
        customActions={
          <div className="wallets-panel-wallets-table-toolbar__actions">
            <span className="selected-caption">Selected: {selectedWallets.length}</span>
          </div>
        }
        customFilters={<></>}
      />
      <div ref={tableContainerRef} className="wallets-table-container scrollable">
        <table className="mm-common-table w-full">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map(virtualRow => {
              const row = rows[virtualRow.index];

              return (
                <tr key={virtualRow.index}>
                  {row ? (
                    row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id} style={{ height: virtualRow.size }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })
                  ) : (
                    <td style={{ height: virtualRow.size }}>-</td>
                  )}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
        {records === undefined && !loading && (
          <div className="mm-common-table-no-info">
            {'You get an error while trying to get wallets records. Try to refresh your page...'}
          </div>
        )}
        {records && records.length === 0 && (
          <div className="mm-common-table-no-info">
            {
              'You don\'t have any wallets yet. Please create your first by clicking on "Create new wallets" button'
            }
          </div>
        )}
      </div>
    </>
  );
};

export { WalletsTable };
