import React, { useContext } from 'react';

import { PanelLoading, PanelNoInfo, PanelError } from 'ui';
import { StatisticTab, StatisticSelectRange } from 'common/statistic';
import { CexPairStatisticContext } from 'context/CexPairStatisticContext';
import { CexGeneralTable } from '../GeneralTables';
import { CexWTAlgorithmTable } from '../CexWTAlgorithmTable';

const CexContent: React.FC = () => {
  const {
    cexStatisticTokens,
    statisticCexOrders,
    firstCexTradeTime,
    generalLoading,
    selectedRange,
    startDate,
    endDate,
    rangeLoading,
  } = useContext(CexPairStatisticContext);

  if (generalLoading) {
    return <PanelLoading spinnerSize="small" />;
  }

  if (!generalLoading && cexStatisticTokens === undefined) {
    return (
      <PanelError title={'Failed'} text={'Something went wrong... Try to refresh your page.'} />
    );
  }

  if (cexStatisticTokens && cexStatisticTokens.length === 0) {
    return (
      <PanelNoInfo
        title={"You don't have pair statistics yet"}
        text={'Start with adding wallets to pair!'}
      />
    );
  }

  if (cexStatisticTokens) {
    return (
      <div className="mm-pair-statistic-panel">
        <div className="range-container">
          <span className="range-label">Select statistic period:</span>
          <StatisticSelectRange
            selectedRange={selectedRange}
            startDate={startDate}
            endDate={endDate}
            loading={generalLoading || rangeLoading}
          />
        </div>
        <StatisticTab title="General">
          <CexGeneralTable
            selectedRange={selectedRange.get}
            cexStatisticTokens={cexStatisticTokens || []}
            statisticCexOrders={statisticCexOrders || []}
            firstCexTradeTime={firstCexTradeTime}
            startDate={startDate.get}
            endDate={endDate.get}
          />
        </StatisticTab>
        <StatisticTab title="Wash-trading algorithm">
          <CexWTAlgorithmTable
            selectedRange={selectedRange.get}
            cexStatisticTokens={cexStatisticTokens || []}
            statisticCexOrders={statisticCexOrders || []}
          />
        </StatisticTab>
      </div>
    );
  }

  return null;
};

export { CexContent };
