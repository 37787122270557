import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { EDexPairFilters, IDexPairsFilters } from 'types/filters';
import { DEX_PAIR_FILTERS_KEY } from 'constants/cache';

export const deleteDexPairFilterReducer: CaseReducer<
  IDexPairsFilters,
  PayloadAction<{
    pairId: number;
    type: EDexPairFilters;
    id: string;
  }>
> = (state, { payload: { pairId, type, id } }) => {
  if (!state[pairId]) return;

  state[pairId]!.updated_at = new Date(Date.now()).toISOString();

  const newFilters = [...(state[pairId]!.filters[type]?.filters ?? [])].filter(el => el.id !== id);

  state[pairId]!.filters = {
    ...state[pairId]!.filters,
    [type]: { filters: newFilters, setted: state[pairId]!.filters[type]?.setted ?? [] },
  };

  LocalStorage.set(DEX_PAIR_FILTERS_KEY, state);
};
