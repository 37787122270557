import React from 'react';

import { IIconProps } from './types';

const RoundChartIcon: React.FC<IIconProps> = ({ className, color = '#7F91BB', onClick }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="6" stroke={color} strokeWidth="1.5" />
      <path d="M12 12H18" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M12 6V11.9379C12 11.9776 12.0158 12.0158 12.0439 12.0439L16 16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { RoundChartIcon };
