import React, { memo } from 'react';
import dayjs from 'dayjs';

import { ITransactionItem } from 'api/apiTransactions/models';

interface ITimeProps {
  time: string;
}

export const Time: React.FC<ITimeProps> = memo(({ time }) => {
  return <div style={{ textAlign: 'left' }}>{time}</div>;
});

export const timeAccessor = (row: ITransactionItem) => dayjs(row.created_at).format('LTS L');
