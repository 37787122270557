import { isTestnet } from 'types/web3';
import { ENetwork, IDexName } from 'types';

export interface IDex {
  description: string;
  dex: IDexName;
  network: ENetwork;
}

export const DEXES_FEES: Record<IDexName, { fee: number }> = {
  uniswap_v3: {
    //TODO calculate from pair config
    fee: 0.3,
  },
  uniswap_v2: {
    fee: 0.3,
  },
  pancakeswap_v2: {
    fee: isTestnet ? 0.2 : 0.25,
  },
  quickswap_v2: {
    fee: 0.3,
  },
  sushiswap_v2: {
    //TODO remove hardcode
    fee: 0.3,
  },
  'uniswap_v3:arbitrum_one': {
    fee: 0.3,
  },
  camelot_v2: {
    fee: 0.3,
  },
};
