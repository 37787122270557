import React from 'react';
import { motion } from 'framer-motion';

import { CheckedIcon } from '../../Checkbox/CheckedIcon';

import './tableCheckbox.scss';

const TableCheckbox = React.forwardRef<
  HTMLInputElement,
  {
    indeterminate: boolean;
    id: string;
    checked: boolean;
    onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    not_connected?: boolean;
  }
>(({ indeterminate, id, checked, onChange, not_connected, ...rest }, ref) => {
  const defaultRef = React.useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  return (
    <>
      <input
        className="mm-table-checkbox-input"
        type="checkbox"
        id={id}
        checked={checked}
        ref={resolvedRef}
        onChange={onChange}
        {...rest}
      />
      <motion.label
        initial={{ borderColor: '#838DA3', backgroundColor: '#fff' }}
        animate={{
          borderColor: !checked ? '#838DA3' : '#5932EA',
          backgroundColor: !checked ? '#fff' : '#5932EA',
        }}
        className={'mm-table-checkbox'}
        htmlFor={id}
      >
        <CheckedIcon />
      </motion.label>
    </>
  );
});

export { TableCheckbox };
