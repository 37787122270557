import { useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { FormikProps, useFormik } from 'formik';
import { v4 as uuid } from 'uuid';
import { useTypedSelector } from 'store';

import { IDextWTOrganicVolumeTask } from 'types/bots';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { durationToMs, msToDuration } from 'utils/duration';
import { trimDecimalZeroes } from 'utils/formats';
import { validationSchema } from './validationSchema';

interface IForm {
  enabled: boolean;
  uniq_id: string;
  start_at: number | null;
  end_at: number | null;
  time_frame: string | null;
  close_bar_before: number | undefined;
  indicator_period: string;
  min_transaction_amount_base: string;
  min_pause_between_series: number | undefined;
  max_pause_between_series: number | undefined;
  min_volume: string;
  max_volume: string;
  min_transactions_in_series: string;
  max_transactions_in_series: string;
  per_transaction_amount_dev: string;
  stop_volume_threshold_percentage: string;
}

interface IUseOrganicTaskProps {
  onSubmit: (task: IDextWTOrganicVolumeTask) => void;
  onClose: () => void;
  task?: IDextWTOrganicVolumeTask;
  mode: 'create' | 'edit';
}

const useOrganicTask = ({ mode, onClose, onSubmit: submit, task }: IUseOrganicTaskProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [edited, setEdited] = useState<boolean>(false);

  const initialValues = useMemo<IForm>(() => {
    if (mode === 'edit' && task) {
      return {
        enabled: task.enabled,
        uniq_id: task.uniq_id,
        start_at: dayjs(task.start_at).valueOf(),
        end_at: task.end_at ? dayjs(task.end_at).valueOf() : null,
        time_frame:
          WT_TIMEFRAMES.find(
            val => durationToMs(val.value) === durationToMs(task.options.time_frame),
          )?.value ?? null,
        close_bar_before: durationToMs(task.options.close_bar_before),
        indicator_period: String(task.options.indicator_period),
        min_transaction_amount_base: trimDecimalZeroes(
          formatUnits(
            BigNumber.from(task.options.min_transaction_amount_base),
            dexPair.token_base.decimals,
          ),
        ),
        min_pause_between_series: durationToMs(task.options.min_pause_between_series),
        max_pause_between_series: durationToMs(task.options.max_pause_between_series),
        min_volume: formatUnits(
          BigNumber.from(task.options.min_volume),
          dexPair.token_base.decimals,
        ),
        max_volume: formatUnits(
          BigNumber.from(task.options.max_volume),
          dexPair.token_base.decimals,
        ),
        min_transactions_in_series: String(task.options.min_transactions_in_series),
        max_transactions_in_series: String(task.options.max_transactions_in_series),
        per_transaction_amount_dev: String(task.options.per_transaction_amount_dev),
        stop_volume_threshold_percentage: String(task.options.stop_volume_threshold_percentage),
      };
    }

    return {
      uniq_id: uuid(),
      enabled: true,
      start_at: null,
      end_at: null,
      time_frame: WT_TIMEFRAMES[0].value,
      close_bar_before: undefined,
      indicator_period: '',
      min_transaction_amount_base: '',
      min_pause_between_series: undefined,
      max_pause_between_series: undefined,
      min_volume: '',
      max_volume: '',
      min_transactions_in_series: '',
      max_transactions_in_series: '',
      per_transaction_amount_dev: '',
      stop_volume_threshold_percentage: '',
    };
  }, [mode, task, dexPair]);

  const onSubmit = useCallback(
    ({
      close_bar_before,
      enabled,
      uniq_id,
      end_at,
      indicator_period,
      max_pause_between_series,
      max_transactions_in_series,
      max_volume,
      min_pause_between_series,
      min_transaction_amount_base,
      min_transactions_in_series,
      min_volume,
      per_transaction_amount_dev,
      start_at,
      stop_volume_threshold_percentage,
      time_frame,
    }: IForm) => {
      submit({
        enabled: enabled,
        end_at: end_at ? dayjs(end_at).toISOString() : null,
        start_at: dayjs(start_at).toISOString(),
        uniq_id: uniq_id,
        options: {
          close_bar_before: msToDuration(close_bar_before),
          indicator_period: Number(indicator_period),
          time_frame: time_frame,
          min_pause_between_series: msToDuration(min_pause_between_series),
          max_pause_between_series: msToDuration(max_pause_between_series),
          min_volume: parseUnits(min_volume, dexPair.token_base.decimals).toString(),
          max_volume: parseUnits(max_volume, dexPair.token_base.decimals).toString(),
          max_transactions_in_series: Number(max_transactions_in_series),
          min_transactions_in_series: Number(min_transactions_in_series),
          min_transaction_amount_base: parseUnits(
            min_transaction_amount_base,
            dexPair.token_base.decimals,
          ).toString(),
          per_transaction_amount_dev: Number(per_transaction_amount_dev),
          stop_volume_threshold_percentage: Number(stop_volume_threshold_percentage),
        },
      } as IDextWTOrganicVolumeTask);
      onClose();
    },
    [onClose, submit, dexPair],
  );

  const { handleSubmit, setFieldValue, values, errors, touched }: FormikProps<IForm> =
    useFormik<IForm>({
      initialValues,
      onSubmit: onSubmit,
      validationSchema,
    });

  const handleSetFieldValue = useCallback(
    (field: keyof IForm, value: any) => {
      setEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  return {
    edited,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    errors,
    touched,
  };
};

export { useOrganicTask };
