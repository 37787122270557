import { parseUnits } from '@ethersproject/units';

import { multiplyBignumbers } from 'utils/formulas';

interface ICalculateSwapsGasPriceArgs {
  swap_gas_limit: number;
  swaps_count: number;
  gas_unit: 'gwei' | 'ether';
  gasPriceInUnit: string;
}

export const calculateSwapsGasPrice = ({
  swap_gas_limit,
  gas_unit,
  swaps_count,
  gasPriceInUnit,
}: ICalculateSwapsGasPriceArgs) => {
  const swapsFeeBN = multiplyBignumbers(
    [
      multiplyBignumbers(
        [parseUnits(swap_gas_limit.toString(), gas_unit), 18],
        [parseUnits(gasPriceInUnit, 9), 9],
      ),
      18,
    ],
    [parseUnits(swaps_count.toString(), 18), 18],
  );

  return swapsFeeBN;
};
