import React from 'react';

import { ITransfer } from 'types/transfers';
import { Button, Modal } from 'ui';
import { WithdrawalStatusTable } from 'tables';

interface IWithdrawalStatusModalProps {
  transfers: ITransfer[];
  onClose: () => void;
}

const WithdrawalStatusModal: React.FC<IWithdrawalStatusModalProps> = ({ transfers, onClose }) => {
  return (
    <Modal onClose={onClose} title="Withdrawal status">
      <div className="flex flex-col gap-6">
        <WithdrawalStatusTable transfers={transfers} />
        <Button onClick={onClose}>Okay</Button>
      </div>
    </Modal>
  );
};

export { WithdrawalStatusModal };
