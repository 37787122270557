import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { PairPageWrapper } from 'ui';
import { EDexPairPanel, IPanelProps } from 'types/pairs';
import { usePairConfig } from 'hooks';

import { AntiFrontRunBot } from './AntiFrontRunBot';
import { BalanceBot } from './BalanceBot';
import { BuySellBot } from './BuySellBot';
import { Charts } from './Charts';
import { Statistic } from './Statistic';
import { Transactions } from './Transactions';
import { Wallets } from './Wallets';
import { WashTradingBot } from './WashTradingBot';

const DexPair: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const { getPairConfig, setPairConfig } = usePairConfig();
  const pairId = useMemo(() => dexPair?.id ?? 0, [dexPair]);

  const pairConfig = useMemo(() => getPairConfig(pairId), [getPairConfig, pairId]);

  const panels = useMemo(() => {
    const adminPanel = [
      EDexPairPanel.afr_bot,
      EDexPairPanel.wt_bot,
      EDexPairPanel.balance_bot,
      EDexPairPanel.buy_sell_bot,
    ];

    const panelValues = Object.values(EDexPairPanel).filter(
      el => isAdmin || !adminPanel.includes(el),
    );

    return panelValues.map(value => {
      const props: IPanelProps = {
        initialOpened: pairConfig.lastOpenedPanel === value,
        setIsOpened: (opened: boolean) =>
          setPairConfig(pairId, { ...pairConfig, lastOpenedPanel: opened ? value : undefined }),
      };

      const mapper: Record<EDexPairPanel, React.ReactNode> = {
        [EDexPairPanel.charts]: <Charts key="charts" {...props} />,
        [EDexPairPanel.statistics]: <Statistic key="statistic" {...props} />,
        [EDexPairPanel.wallets]: <Wallets key="wallets" {...props} />,
        [EDexPairPanel.afr_bot]: <AntiFrontRunBot key="afr-bot" {...props} />,
        [EDexPairPanel.wt_bot]: <WashTradingBot key="wash-trading-bot" {...props} />,
        [EDexPairPanel.balance_bot]: <BalanceBot key="balance-bot" {...props} />,
        [EDexPairPanel.buy_sell_bot]: <BuySellBot key="buy-sell-bot" {...props} />,
        [EDexPairPanel.transactions]: <Transactions key="transactions" {...props} />,
      };

      return mapper[value];
    });
  }, [isAdmin, pairConfig, setPairConfig, pairId]);

  if (!dexPair) {
    return null;
  }

  return <PairPageWrapper>{panels}</PairPageWrapper>;
};

export { DexPair };
