import React, { useContext } from 'react';

import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { RegularVolumesToolbar } from '../RegularVolumesToolbar';
import { OrganicVolumesToolbar } from '../OrganicVolumesToolbar';
import { OrganicVolumesTable } from '../OrganicVolumesTable';
import { RegularVolumesTable } from '../RegularVolumesTable';

const Tables: React.FC = () => {
  const { loading } = useContext(PairWashTradingContext);

  if (loading.get) return null;

  return (
    <div className="w-full flex flex-col items-center gap-3 pb-3 px-3">
      <RegularVolumesToolbar />
      <RegularVolumesTable />
      <OrganicVolumesToolbar />
      <OrganicVolumesTable />
    </div>
  );
};

export { Tables };
