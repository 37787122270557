import React from 'react';
import { TableWrapper } from 'ui';
import { HolderBalanceTable, HolderBalanceTaskToolbar } from './components';

import './holderBalance.scss';

const HolderBalance: React.FC = () => {
  return (
    <div className="balance-tasks">
      <TableWrapper>
        <div className="balance-tasks__all-tasks">
          <HolderBalanceTaskToolbar />
          <HolderBalanceTable />
        </div>
      </TableWrapper>
    </div>
  );
};

export { HolderBalance };
