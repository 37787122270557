import React, { useCallback, useContext, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { useTypedDispatch, useTypedSelector } from 'store';

import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';
import { Spinner } from 'ui';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { ICexWTRegularTask } from 'types/bots';
import { addRegularTask } from 'modals/CexWTRegularTaskModal/useCreateNewTaskModal/addRegularTask';

import {
  Time,
  GroupVolume,
  Makers,
  DailyGroupsTrades,
  DailyVolume,
  DailyFees,
  Status,
} from './columns';
import { RegularRowInfo } from '../../RowInfo/RegularRowInfo';

import '../style.scss';

const TableComponent: React.FC = () => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const {
    initialLoading,
    regularEnableLoading,
    regularTasks,
    handleLoadRecords,
    extendedRegularRow,
  } = useContext(CexWashAlgorithmContext);

  const handleDeleteTask = useCallback(
    (task: ICexWTRegularTask) => {
      if (cexPair.id) {
        dispatch(
          setAlertState({
            type: 'sure',
            text: 'You have unsaved changes. Confirming this will result in losing all unsaved data.',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              addRegularTask({
                dispatch,
                task: task,
                newTask: task,
                pairId: cexPair.id,
                handleLoadRecords,
                mode: 'delete',
              });
            },
          }),
        );
      }
    },
    [cexPair.id, dispatch, handleLoadRecords],
  );

  const handleEnableTask = useCallback(
    async (task: ICexWTRegularTask, checked: boolean) => {
      if (cexPair.id) {
        await addRegularTask({
          dispatch,
          task: { ...task, enabled: checked },
          newTask: { ...task, enabled: checked },
          pairId: cexPair.id,
          setLoading: v => {
            if (v === false) {
              regularEnableLoading.set(undefined);
            } else {
              regularEnableLoading.set(task.start_at);
            }
          },
          onSuccess: () => {
            if (regularTasks.get) {
              const newRegularTasks = [...regularTasks.get];
              const findIndex = newRegularTasks.findIndex(el => el.start_at === task.start_at);

              if (findIndex >= 0) {
                newRegularTasks[findIndex] = { ...task, enabled: checked };
              }

              regularTasks.set(newRegularTasks);
            }
          },
          handleLoadRecords,
          mode: 'edit',
        });
      }
    },
    //eslint-disable-next-line
    [cexPair.id, dispatch, handleLoadRecords, regularEnableLoading.set],
  );

  const columns = useMemo<ColumnDef<ICexWTRegularTask>[]>(
    () => [
      {
        header: () => <div style={{ textAlign: 'left' }}>Execution time:</div>,
        id: 'execution-time',
        cell: ({ row: { original } }) => <Time task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Group volume:</div>,
        id: 'group-volume',
        cell: ({ row: { original } }) => <GroupVolume task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Makers / takers</div>,
        id: 'makers-takers',
        cell: ({ row: { original } }) => <Makers task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Daily groups / trades:</div>,
        id: 'daily-groups_trades:',
        cell: ({ row: { original } }) => <DailyGroupsTrades task={original} />,
        size: 160,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Daily volume:</div>,
        id: 'daily-volume',
        cell: ({ row: { original } }) => <DailyVolume task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Daily fees:</div>,
        id: 'daily-fees',
        cell: ({ row: { original } }) => <DailyFees task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'right' }}>Status</div>,
        id: 'status',
        accessorFn: () => {},
        cell: ({
          table: {
            options: { meta },
          },
          row,
        }) => {
          const _meta = meta as any;

          return (
            <Status
              task={row.original}
              rowIndex={row.index}
              extendedRow={_meta.extendedRow}
              onEnableTask={handleEnableTask}
            />
          );
        },
      },
    ],
    [handleEnableTask],
  );

  const table = useReactTable({
    data: regularTasks.get ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      extendedRow: extendedRegularRow.get,
    },
  });

  const { rows } = table.getRowModel();

  return (
    <>
      <table className={cn('mm-static-table', 'mm-wash-algorithm-table')}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {!initialLoading.get &&
            rows.map(row => {
              return (
                <React.Fragment key={row.index}>
                  <tr
                    key={row.index}
                    className={cn({
                      _selected: extendedRegularRow.get === row.index,
                    })}
                    onClick={() => extendedRegularRow.set(row.index)}
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                  <AnimatePresence>
                    {extendedRegularRow.get === row.index && (
                      <tr className={cn('mm-wash-algorithm-opened-task')}>
                        <td colSpan={8}>
                          {
                            <RegularRowInfo
                              onDeleteTask={() => handleDeleteTask(row.original)}
                              task={row.original}
                              rowIdx={row.index}
                            />
                          }
                        </td>
                      </tr>
                    )}
                  </AnimatePresence>
                  <tr className="tr-separator" />
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
      {initialLoading.get && (
        <div className="mm-wash-algorithm-table-loader">
          <Spinner size="mini" />
        </div>
      )}
    </>
  );
};
const RegularVolumeTable = React.memo(TableComponent);
export { RegularVolumeTable };
