import { useEffect } from 'react';
import { useTypedSelector } from 'store';

import { Bus } from 'tools';
import { EExchange } from 'types';
import { EDexPairFilters } from 'types/filters';
import { useDexPairFilters } from 'store/slices/dex_pair_filters/hooks';

import FilterItem from '../FilterItem';

import './style.scss';

interface IFiltersListProps<V> {
  id: string;
  exchange: V;
  //TODO for cex pairs
  type: V extends EExchange.dex ? EDexPairFilters : any;
}

function FiltersList<V extends EExchange>({ id, exchange, type }: IFiltersListProps<V>) {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);

  const { scheme, pairFilters, addFilter, updateFilter, deleteFilter } = useDexPairFilters({
    dexPair: exchange === EExchange.dex ? dexPair! : undefined,
    type,
  });

  useEffect(() => {
    const handleAddFilter = () => {
      addFilter();
    };

    Bus.on(`${id}-FILTERS-ADD`, handleAddFilter);

    return () => {
      Bus.off(`${id}-FILTERS-ADD`, handleAddFilter);
    };
  }, [id, addFilter]);

  if (pairFilters.length === 0) return null;

  return (
    <div className="mm-filters-container scrollable">
      {scheme &&
        pairFilters.map((filter, idx) => (
          <FilterItem
            key={idx}
            scheme={scheme}
            filter={filter}
            updateFilter={updateFilter}
            deleteFilter={deleteFilter}
          />
        ))}
    </div>
  );
}

export default FiltersList;
