import { useCallback, useContext, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { parseUnits } from '@ethersproject/units';
import { useTypedDispatch } from 'store';

import { Button } from 'ui';

import { DepositWithdrawContext } from 'context/DepositWithdrawContext/DepositWithdrawContext';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { useERC20Deposit } from 'hooks/useERC20Deposit/useERC20Deposit';
import { ApiPairs } from 'api';
import { IDexPair } from 'types/pairs';

interface IUseDepositProps {
  onClose: () => void;
  pair: IDexPair;
}

const useDeposit = ({ onClose, pair }: IUseDepositProps) => {
  const { account } = useWeb3React();
  const dispatch = useTypedDispatch();
  const { innerWalletsValid, innerWallets, formError, selectedToken } =
    useContext(DepositWithdrawContext);

  const { deposit } = useERC20Deposit(selectedToken.get.address);

  const [loading, setLoading] = useState(false);

  const handleDeposit = useCallback(async () => {
    const transactionHashes: string[] = [];

    if (!account) {
      return onClose();
    }

    if (formError) return;

    if (!innerWalletsValid) return;

    try {
      setLoading(true);

      const depositWallets = innerWallets.get.filter(el => !el.disabled);

      for (const wallet of depositWallets) {
        const result = await deposit({
          walletAddress: wallet.address,
          amount: parseUnits(wallet.balance, selectedToken.get.decimals),
        });

        if (result?.hash && typeof result?.hash === 'string') {
          transactionHashes.push(result.hash);
        }
      }
    } catch (error) {
      console.log(error);
      return onClose();
    } finally {
      setLoading(false);
    }

    if (transactionHashes.length === 0) {
      onClose();
      dispatch(
        setAlertState({
          type: 'failed',
          text: 'Something went wrong with signing transactions in metamask',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
          buttons: (
            <div className="w-full flex flex-col items-center">
              <Button color={'error'} onClick={() => dispatch(dropAlertState())}>
                Okay
              </Button>
            </div>
          ),
        }),
      );
      return;
    }

    try {
      setLoading(true);
      const { errorMessage, isSuccess } = await ApiPairs.deposit({
        pairId: pair.id,
        transactionHashes,
      });

      onClose();
      if (isSuccess) {
        dispatch(
          setAlertState({
            type: 'success',
            text: 'Transactions in process. Deposit is handling',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    innerWalletsValid,
    formError,
    innerWallets.get,
    deposit,
    selectedToken,
    pair,
    account,
    dispatch,
    onClose,
  ]);

  return { deposit: handleDeposit, loading };
};

export default useDeposit;
