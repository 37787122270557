interface ITradeSizeArgs {
  value: number;
  points: {
    initial: number;
    min: number;
    max: number;
  };
}

// scroll position from 1 to 100
export const getScrollPositionFromValue = ({
  value,
  points: { initial, min, max },
}: ITradeSizeArgs) => {
  // 1 - half

  if (value === initial) return 50;

  if (value < initial) {
    // x = ((value - min) / (initial - min)) * 50 | %

    const scrollPosition = Math.floor(((value - min) / (initial - min)) * 50);

    return scrollPosition;
  }

  if (value > initial) {
    // pos = ((value - initial) / (min + max)) * 50 + 50 | %

    const scrollPosition = Math.floor(((value - initial) / (max - initial)) * 50 + 50);

    return scrollPosition;
  }

  return 0;
};

interface IScrollPositionArgs {
  scrollPosition: number;
  points: {
    initial: number;
    min: number;
    max: number;
  };
}

export const getValueFromScrollPosition = ({
  scrollPosition,
  points: { initial, max, min },
}: IScrollPositionArgs) => {
  // 1 - half
  if (scrollPosition === 50) return initial;

  if (scrollPosition < 50) {
    // value = ((pos) / 50) * (initial - min) + min

    const value = Number(((scrollPosition / 50) * (initial - min) + min).toFixed(4));

    return value;
  }

  if (scrollPosition > 50) {
    // value = ((pos - 50) / 50) * (max - initial) + initial

    const value = Number((((scrollPosition - 50) / 50) * (max - initial) + initial).toFixed(4));

    return value;
  }

  return 0;
};
