export const GOERLI_TESTNET_CHAIN_ID = 5;
export const GOERLI_MAIN_ASSET = '0x0000000000000000000000000000000000000002';

export const BSC_TESTNET_CHAIN_ID = 97;
export const BSC_TESTNET_MAIN_ASSET = '0x0000000000000000000000000000000000000003';

export const ETH_MAINNET_CHAIN_ID = 1;
export const ETH_MAINNET_MAIN_ASSET = '0x0000000000000000000000000000000000000000';

export const BSC_MAINNET_CHAIN_ID = 56;
export const BSC_MAINNET_MAIN_ASSET = '0x0000000000000000000000000000000000000003';

export const POLYGON_TESTNET_CHAIN_ID = 80001;
export const POLYGON_TESTNET_MAIN_ASSET = '0x0000000000000000000000000000000000001010';

export const POLYGON_MAINNET_CHAIN_ID = 137;
export const POLYGON_MAINNET_MAIN_ASSET = '0x0000000000000000000000000000000000000004';

export const ARBITRUM_TESTNET_CHAIN_ID = 421614;
export const ARBITRUM_TESTNET_MAIN_ASSET = '0x0000000000000000000000000000000000000005';

export const ARBITRUM_MAINNET_CHAIN_ID = 42161;
export const ARBITRUM_MAINNET_MAIN_ASSET = '0x0000000000000000000000000000000000000005';

export const isTestnet = process.env.REACT_APP_NETWORK_TYPE === 'testnet';

export const BSC_CHAIN_ID = isTestnet ? BSC_TESTNET_CHAIN_ID : BSC_MAINNET_CHAIN_ID;
export const ETH_CHAIN_ID = isTestnet ? GOERLI_TESTNET_CHAIN_ID : ETH_MAINNET_CHAIN_ID;
export const POLYGON_CHAIN_ID = isTestnet ? POLYGON_TESTNET_CHAIN_ID : POLYGON_MAINNET_CHAIN_ID;
export const ARBITRUM_CHAIN_ID = isTestnet ? ARBITRUM_TESTNET_CHAIN_ID : ARBITRUM_MAINNET_CHAIN_ID;

export const ETH_MAIN_ASSET = isTestnet ? GOERLI_MAIN_ASSET : ETH_MAINNET_MAIN_ASSET;
export const BSC_MAIN_ASSET = isTestnet ? BSC_TESTNET_MAIN_ASSET : BSC_MAINNET_MAIN_ASSET;
export const POLYGON_MAIN_ASSET = isTestnet
  ? POLYGON_TESTNET_MAIN_ASSET
  : POLYGON_MAINNET_MAIN_ASSET;
export const ARBITRUM_MAIN_ASSET = isTestnet
  ? ARBITRUM_TESTNET_MAIN_ASSET
  : ARBITRUM_MAINNET_MAIN_ASSET;

export type IRange = 'ALL' | '4H' | '1M' | '1D' | '1W';
