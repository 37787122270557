import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ColumnDef, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { isNil } from 'lodash';
import { useTypedDispatch } from 'store';

import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { IDextWTOrganicVolumeTask } from 'types/bots';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import {
  CloseBarCell,
  DailyFeesCell,
  DailyVolumeCell,
  ExecutionTimeCell,
  SeriePauseCell,
  StatusCell,
  TimeframeCell,
  TransactionSerieCell,
  VolumeSerieCell,
} from './columns';
import { RowInfo } from './RowInfo';

import './style.scss';

const OrganicVolumesTable: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { currentConfig } = useContext(PairWashTradingContext);

  const tasks = useMemo<IDextWTOrganicVolumeTask[]>(
    () => [...(currentConfig.get ? currentConfig.get.config?.organic_volumes_tasks ?? [] : [])],
    [currentConfig.get],
  );
  const [openedRowIndex, setOpenedRowIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    setOpenedRowIndex(undefined);
  }, [tasks]);

  const onTriggerEnable = useCallback(
    (taskIdx: number, flag: boolean) => {
      const newTasks = [...tasks];
      newTasks[taskIdx] = { ...newTasks[taskIdx], enabled: flag };

      currentConfig.set(
        currentConfig.get
          ? {
              ...currentConfig.get,
              config: {
                ...currentConfig.get.config,
                organic_volumes_tasks: newTasks,
              },
            }
          : undefined,
      );
      dispatch(
        setAlertState({
          type: 'success',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
          text: `You have successfully ${flag ? 'enabled' : 'disabled'} task!`,
        }),
      );
    },
    [currentConfig, tasks, dispatch],
  );

  const columns = useMemo<ColumnDef<IDextWTOrganicVolumeTask>[]>(
    () => [
      {
        id: 'execution-time',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Execution time</div>,
        cell: ({ row: { original } }) => <ExecutionTimeCell task={original} />,
        size: 220,
      },
      {
        id: 'timerfame',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Timeframe</div>,
        cell: ({ row: { original } }) => <TimeframeCell task={original} />,
      },
      {
        id: 'close-bar-before',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Close bar before</div>,
        cell: ({ row: { original } }) => <CloseBarCell task={original} />,
      },
      {
        id: 'serie-pause',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Serie pause</div>,
        cell: ({ row: { original } }) => <SeriePauseCell task={original} />,
      },
      {
        id: 'volume-serie',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Volume serie</div>,
        cell: ({ row: { original } }) => <VolumeSerieCell task={original} />,
      },
      {
        id: 'transaction-serie',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Transactions in serie</div>,
        cell: ({ row: { original } }) => <TransactionSerieCell task={original} />,
      },
      {
        id: 'daily-volume',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Est 24 volume</div>,
        cell: ({ row: { original } }) => <DailyVolumeCell task={original} />,
        size: 200,
      },
      {
        id: 'daily-fees',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Fee 24h</div>,
        cell: ({ row: { original } }) => <DailyFeesCell task={original} />,
        size: 200,
      },
      {
        id: 'status',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Status</div>,
        size: 80,
        cell: ({
          row,
          table: {
            options: { meta },
          },
        }) => (
          <StatusCell
            idx={row.index}
            openedRowIdx={(meta as any).openedRowIndex as number}
            onTriggerEnable={flag => onTriggerEnable(row.index, flag)}
            task={row.original}
          />
        ),
      },
    ],
    [onTriggerEnable],
  );

  const table = useReactTable({
    data: tasks,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      openedRowIndex,
    },
  });

  return (
    <table className={cn('mm-static-table', 'mm-dex-wt-organic-volumes-table')}>
      {tasks.length !== 0 && (
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
      )}
      <tbody>
        {tasks.length === 0 && (
          <tr>
            <td colSpan={9}>
              <div className="no-active-records">
                <span>There are no tasks</span>
              </div>
            </td>
          </tr>
        )}
        {table.getRowModel().rows.map(row => {
          return (
            <React.Fragment key={row.index}>
              <tr
                key={row.index}
                className={cn({
                  _selected: openedRowIndex === row.index,
                })}
                onClick={() => setOpenedRowIndex(v => (isNil(v) ? row.index : undefined))}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
              <AnimatePresence>
                {openedRowIndex === row.index && (
                  <tr className="opened-task">
                    <td colSpan={9}>
                      <RowInfo task={row.original} rowIdx={row.index} />
                    </td>
                  </tr>
                )}
              </AnimatePresence>
              <tr className="tr-separator" />
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export { OrganicVolumesTable };
