import { BigNumber } from '@ethersproject/bignumber';
import React from 'react';
import { useTypedSelector } from 'store';

import { IDextWTOrganicVolumeTask } from 'types/bots';
import { formatToken } from 'utils/formats';

interface IVolumeSerieCellProps {
  task: IDextWTOrganicVolumeTask;
}

const VolumeSerieCell: React.FC<IVolumeSerieCellProps> = ({ task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  return (
    <div>
      {formatToken(BigNumber.from(task.options.min_volume), dexPair.token_base.decimals)} /{' '}
      {formatToken(BigNumber.from(task.options.max_volume), dexPair.token_base.decimals)}
    </div>
  );
};

export { VolumeSerieCell };
