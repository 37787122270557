import React from 'react';

import { Spinner } from '../Spinner/Spinner';
import { ISpinnerSize } from '../types';

import './panelLoading.scss';

interface IPanelLoading {
  spinnerSize?: ISpinnerSize;
}

const PanelLoading: React.FC<IPanelLoading> = ({ spinnerSize = 'medium' }) => {
  return (
    <div className="mm-panel-loading">
      <Spinner size={spinnerSize} />
    </div>
  );
};

export { PanelLoading };
