import React, { useContext } from 'react';
import { useTypedSelector } from 'store';

import { Switcher } from 'ui';
import { InputField } from 'fields';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';

import './style.scss';

const AdditionalReserves: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { useAdditionalReserves, manualPooledBase, manualPooledQuote } =
    useContext(PairWashTradingContext);

  return (
    <div className="mm-wt-bot-additional-reserves">
      <div className="switcher-container">
        <span className="switcher-caption">Additional reserves</span>
        <Switcher value={useAdditionalReserves.get} onChange={v => useAdditionalReserves.set(v)} />
      </div>
      {useAdditionalReserves.get && (
        <div className="inputs">
          <InputField
            type="text"
            label={`Pooled ${dexPair.token_base.symbol}`}
            value={manualPooledBase.get}
            setValue={manualPooledBase.set}
          />
          <InputField
            type="text"
            label={`Pooled ${dexPair.token_quote.symbol}`}
            value={manualPooledQuote.get}
            setValue={manualPooledQuote.set}
          />
        </div>
      )}
    </div>
  );
};

export default AdditionalReserves;
