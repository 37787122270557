import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePrevious } from 'react-use';
import { getAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTypedSelector } from 'store';

import { usePairConfig } from 'hooks';
import { ApiCharts } from 'api';
import {
  IKattanaDexChartResponse,
  IKattanaCexChartResponse,
  IBalanceRecord,
} from 'api/apiCharts/models';
import { IChartRange, IPriceFormat, EChartFormat, EChartConvertedFormat } from 'types/charts';
import { IBalancesChartItem, IKattanaChartItem } from './chartsWorker';
import {
  rangeToInterval,
  rangeToStartEndTimes,
  rangeToLimit,
  convertStartEndTime,
} from 'utils/charts';
import { EExchange } from 'types';

dayjs.extend(duration);

interface IKattanaChartParams {
  interval: number;
  startTime: number;
  endTime: number;
  limit: number;
}

interface IPairChartsContext {
  loading: { get: boolean };
  exchange: EExchange;
  handleLoadCharts: () => Promise<any>;
  handleLoadPairKattanaChart: (args?: IKattanaChartParams) => Promise<any>;
  handleLoadOnlyPairChart: () => Promise<any>;
  balanceRecords: IBalanceRecord[];
  kattanaPairRecords: IKattanaDexChartResponse | IKattanaCexChartResponse;
  selectedRange: {
    get: IChartRange | undefined;
    set: (value: IChartRange | undefined) => void;
  };
  startDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  endDate: { get: number | undefined; set: Dispatch<SetStateAction<number | undefined>> };
  selectedChartFormat: { get: EChartFormat; set: Dispatch<SetStateAction<EChartFormat>> };
  priceFormat: { get: IPriceFormat; set: Dispatch<SetStateAction<IPriceFormat>> };
  convertedFormat: {
    get: EChartConvertedFormat;
    set: Dispatch<SetStateAction<EChartConvertedFormat>>;
  };
  disabledFormats: EChartConvertedFormat[];
  balancesShow: {
    get: Record<string, boolean>;
    set: Dispatch<SetStateAction<Record<string, boolean>>>;
  };
  visibleLocalRange: {
    get: { from: number; to: number } | undefined;
    set: Dispatch<SetStateAction<{ from: number; to: number } | undefined>>;
  };
}

export const PairChartsContext = createContext<IPairChartsContext>({
  loading: { get: false },
  exchange: EExchange.dex,
  handleLoadCharts: async () => {},
  handleLoadPairKattanaChart: async () => {},
  handleLoadOnlyPairChart: async () => {},
  balanceRecords: [],
  kattanaPairRecords: [],
  selectedRange: { get: undefined, set: () => {} },
  startDate: { get: undefined, set: () => {} },
  endDate: { get: undefined, set: () => {} },
  selectedChartFormat: { get: EChartFormat.Normal, set: () => {} },
  priceFormat: { get: 'usd', set: () => {} },
  convertedFormat: {
    get: EChartConvertedFormat.Converted,
    set: () => {},
  },
  disabledFormats: [],
  balancesShow: { get: {}, set: () => {} },
  visibleLocalRange: { get: undefined, set: () => {} },
});

interface IPairChartsContextProviderProps {
  children?: React.ReactNode;
  exchange: EExchange;
}

const PairChartsContextProvider: React.FC<IPairChartsContextProviderProps> = ({
  children,
  exchange,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const pair = useMemo(
    () => (exchange === EExchange.dex ? dexPair : cexPair),
    [exchange, cexPair, dexPair],
  );

  const { getPairConfig, setPairConfig } = usePairConfig();

  const initialSelectedRange = useMemo(
    () => getPairConfig(pair.id).chartRange ?? '1M',
    [pair, getPairConfig],
  );

  const [balanceRecords, setBalanceRecords] = useState<IBalanceRecord[]>([]);
  const [kattanaPairRecords, setKattanaPairRecords] = useState<
    IKattanaDexChartResponse | IKattanaCexChartResponse
  >([]);
  const [chartLoading, setChartLoading] = useState<boolean>(true);

  const [selectedRange, setSelectedRange] = useState<IChartRange | undefined>(initialSelectedRange);
  const [startDate, setStartDate] = useState<number | undefined>(undefined);
  const [endDate, setEndDate] = useState<number | undefined>(undefined);
  const [selectedChartFormat, setSelectedChartFormat] = useState<EChartFormat>(
    EChartFormat.Percentage,
  );
  const [priceFormat, setPriceFormat] = useState<IPriceFormat>('token');
  const [convertedFormat, setConvertedFormat] = useState<EChartConvertedFormat>(
    EChartConvertedFormat.Converted,
  );
  const [disabledFormats, setDisabledFormats] = useState<EChartConvertedFormat[]>([]);

  const [visibleLocalRange, setVisibleLocalRange] = useState<
    { from: number; to: number } | undefined
  >(undefined);

  const INITIAL_BALANCES_SHOW = useMemo(
    () => ({
      [pair.token_base.symbol]: true,
      [pair.token_quote.symbol]: true,
      ['dex' in pair ? pair.token_fee.symbol : pair.token_native.symbol]: true,
      Total: true,
    }),
    [pair],
  );

  const [balancesShow, setBalancesShow] = useState<Record<string, boolean>>(INITIAL_BALANCES_SHOW);

  const handleSetSelectedRange = useCallback(
    (newChartRange: IChartRange | undefined) => {
      setSelectedRange(newChartRange);

      const newPairConfig = getPairConfig(pair.id);
      newPairConfig.chartRange = newChartRange;

      setPairConfig(pair.id, newPairConfig);
    },
    [getPairConfig, setPairConfig, pair],
  );

  const calculateChartRequestArgs = useCallback(() => {
    if (selectedRange) {
      const interval = rangeToInterval(selectedRange);
      const { endTime, startTime } = rangeToStartEndTimes(selectedRange);
      const limit = rangeToLimit(selectedRange);

      return { interval, endTime, startTime, limit };
    } else if (startDate && endDate) {
      const startTime = Math.floor(startDate / 1000);
      const endTime = Math.floor(endDate / 1000);
      const { interval, limit } = convertStartEndTime(startTime, endTime);

      return { interval, endTime, startTime, limit };
    }

    return { interval: 0, endTime: 0, startTime: 0, limit: 0 };
  }, [selectedRange, startDate, endDate]);

  const handleLoadPairKattanaChart = useCallback(async () => {
    const isFlipped =
      'dex' in pair
        ? BigNumber.from(pair.token_base.address).gt(BigNumber.from(pair.token_quote.address))
        : false;

    const isConverted = convertedFormat === EChartConvertedFormat.Converted;

    try {
      setChartLoading(true);

      const { limit, interval, endTime, startTime } = calculateChartRequestArgs();

      const intervalDayjs = dayjs.duration(interval, 'seconds');
      let intervalStr = '';
      if (interval < 60) {
        intervalStr = intervalDayjs.format('s[s]');
      } else if (interval < 60 * 60) {
        intervalStr = intervalDayjs.format('m[m]');
      } else if (interval < 60 * 60 * 24) {
        intervalStr = intervalDayjs.format('H[h]');
      } else if (interval) {
        intervalStr = intervalDayjs.format('D[D]');
      }

      let _data: IKattanaDexChartResponse | IKattanaCexChartResponse | undefined;
      let _isSuccess = false;

      if (exchange === EExchange.dex && 'dex' in pair) {
        const { isSuccess, data } = await ApiCharts.getKattanaDexPairChart({
          network: pair.network,
          pair: getAddress(pair.address),
          timeframe: intervalStr,
          precision: pair.token_base.decimals,
          startTime,
          endTime,
          limit,
          flipped: isFlipped,
          converted: isConverted ? pair.token_quote.symbol : undefined,
        });

        _data = data;
        _isSuccess = isSuccess;
      } else if ('cex' in pair) {
        const { isSuccess, data } = await ApiCharts.getKattanaCexPairChart({
          cex: pair.cex,
          pair: pair.cex_id,
          timeframe: intervalStr,
          startTime: startTime * 1000,
          endTime: endTime * 1000,
          limit,
        });

        _data = data;
        _isSuccess = isSuccess;
      }

      let kattanaData: IKattanaDexChartResponse | IKattanaCexChartResponse = [];
      let isConvertedError = false;
      if (_isSuccess && _data) {
        kattanaData = _data;
      } else {
        kattanaData = [];
        if (isConverted) {
          isConvertedError = true;
        }
      }

      return { kattanaData, isConverted, isConvertedError };
    } catch (error) {
      console.log('error: ', error);
      return { kattanaData: [], isConverted, isConvertedError: false };
    }
  }, [exchange, pair, convertedFormat, calculateChartRequestArgs]);

  const handleLoadBalancesChart = useCallback(async () => {
    try {
      setChartLoading(true);
      setBalancesShow(INITIAL_BALANCES_SHOW);

      const { isSuccess, data: balances } = await ApiCharts.getBalances({
        pair_ids: 'dex' in pair ? [pair.id] : undefined,
        cex_pair_ids: 'cex' in pair ? [pair.id] : undefined,
        ranges: [
          selectedRange
            ? selectedRange
            : startDate && endDate
            ? `${Math.floor(startDate / 1000)}-${Math.floor(endDate / 1000)}`
            : '1D',
        ],
      });

      if (
        isSuccess &&
        balances &&
        balances.items.length !== 0 &&
        balances.items[0].points.length > 1
      ) {
        return { balancesData: balances.items };
      } else {
        return { balancesData: [] };
      }
    } catch (error) {
      console.log('error: ', error);
      return { balancesData: [] };
    }
  }, [selectedRange, startDate, endDate, pair, INITIAL_BALANCES_SHOW]);

  const handleLoadOnlyPairChart = useCallback(async () => {
    try {
      setChartLoading(true);
      setBalancesShow(INITIAL_BALANCES_SHOW);
      setVisibleLocalRange(undefined);

      const { kattanaData } = await handleLoadPairKattanaChart();
      const { interval } = calculateChartRequestArgs();

      const worker = new Worker(new URL('./pairChartWorker.ts', import.meta.url));

      worker.postMessage({ kattanaData, interval, balanceRecords, pair });

      worker.onmessage = (e: {
        data: {
          chartBalanceRecords: IBalancesChartItem[];
          chartKattanaRecords: IKattanaChartItem[];
        };
      }) => {
        setBalanceRecords(e.data.chartBalanceRecords);
        setKattanaPairRecords(e.data.chartKattanaRecords);
        setChartLoading(false);
      };
    } catch (error) {
      console.log('error: ', error);
    }
  }, [
    handleLoadPairKattanaChart,
    calculateChartRequestArgs,
    balanceRecords,
    INITIAL_BALANCES_SHOW,
    pair,
  ]);

  const handleLoadCharts = useCallback(async () => {
    try {
      setBalancesShow(INITIAL_BALANCES_SHOW);
      setVisibleLocalRange(undefined);

      const [{ kattanaData, isConvertedError }, { balancesData }] = await Promise.all([
        handleLoadPairKattanaChart(),
        handleLoadBalancesChart(),
      ]);

      const { interval } = calculateChartRequestArgs();

      const worker = new Worker(new URL('./chartsWorker.ts', import.meta.url));

      worker.postMessage({ balancesData, pair: pair, kattanaData, interval });

      worker.onmessage = (e: {
        data: {
          chartBalanceRecords: IBalancesChartItem[];
          chartKattanaRecords: IKattanaChartItem[];
        };
      }) => {
        setBalanceRecords(e.data.chartBalanceRecords);

        if (!isConvertedError) {
          setKattanaPairRecords(e.data.chartKattanaRecords);
          setChartLoading(false);
        } else {
          setConvertedFormat(EChartConvertedFormat.Original);
          setDisabledFormats([...disabledFormats, EChartConvertedFormat.Converted]);
        }
      };
    } catch (error) {
      console.log('error: ', error);
    }
  }, [
    pair,
    handleLoadPairKattanaChart,
    handleLoadBalancesChart,
    calculateChartRequestArgs,
    disabledFormats,
    INITIAL_BALANCES_SHOW,
  ]);

  const previousConvertedFormat = usePrevious(convertedFormat);

  useEffect(() => {
    if (previousConvertedFormat && previousConvertedFormat !== convertedFormat) {
      handleLoadOnlyPairChart();
    }

    //eslint-disable-next-line
  }, [convertedFormat]);

  useEffect(() => {
    handleLoadCharts();
  }, [pair, selectedRange, startDate, endDate]);

  const value = useMemo(
    () => ({
      loading: { get: chartLoading },
      exchange,
      handleLoadCharts,
      handleLoadPairKattanaChart,
      handleLoadOnlyPairChart,
      balanceRecords,
      kattanaPairRecords,
      selectedRange: { get: selectedRange, set: handleSetSelectedRange },
      startDate: { get: startDate, set: setStartDate },
      endDate: { get: endDate, set: setEndDate },
      selectedChartFormat: { get: selectedChartFormat, set: setSelectedChartFormat },
      priceFormat: { get: priceFormat, set: setPriceFormat },
      convertedFormat: { get: convertedFormat, set: setConvertedFormat },
      disabledFormats,
      balancesShow: { get: balancesShow, set: setBalancesShow },
      visibleLocalRange: { get: visibleLocalRange, set: setVisibleLocalRange },
    }),
    [
      exchange,
      chartLoading,
      balanceRecords,
      kattanaPairRecords,
      selectedRange,
      startDate,
      priceFormat,
      endDate,
      selectedChartFormat,
      convertedFormat,
      disabledFormats,
      handleLoadCharts,
      handleLoadPairKattanaChart,
      handleSetSelectedRange,
      handleLoadOnlyPairChart,
      balancesShow,
      visibleLocalRange,
    ],
  );

  return <PairChartsContext.Provider value={value}>{children}</PairChartsContext.Provider>;
};

export default PairChartsContextProvider;
