import React, { createContext, useCallback, useState } from 'react';

import { useTypedSelector } from 'store';
import { ApiBot } from 'api';
import { EDexBot, IDexBotSettings } from 'types/bots';

interface IPairAFRBotContext {
  loading: { get: boolean; set: (v: boolean) => void };
  handleLoadBotSettings: () => void;
  errorMessage: string | undefined;
  botSettings: IDexBotSettings | undefined;
}

export const PairAFRBotContext = createContext<IPairAFRBotContext>({
  loading: { get: false, set: () => {} },
  handleLoadBotSettings: () => {},
  errorMessage: undefined,
  botSettings: undefined,
});

interface IPairAFRBotContextProviderProps {
  children?: React.ReactNode;
}

const PairAFRBotContextProvider: React.FC<IPairAFRBotContextProviderProps> = ({ children }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [botSettings, setBotSettings] = useState<IDexBotSettings | undefined>(undefined);

  const handleLoadBotSettings = useCallback(async () => {
    setLoading(true);

    if (!pair) return;

    try {
      const { isSuccess, data, errorMessage } = await ApiBot.getDexBotConfig({
        pairId: pair.id,
        bot: EDexBot.anti_font_run,
      });

      if (isSuccess && data) {
        setBotSettings({
          enableBot: data.is_enabled,
          privateTx: data.send_private_transactions,
          slipage: data.slippage_percent,
          afr_options: data.afr_options,
        });
        setErrorMessage(undefined);
      } else if (!isSuccess) {
        setErrorMessage(errorMessage ?? 'Failed to load bot config. Try to refresh your page...');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed to load bot config. Try to refresh your page...');
    } finally {
      setLoading(false);
    }
  }, [pair]);

  return (
    <PairAFRBotContext.Provider
      value={{
        loading: { get: loading, set: setLoading },
        handleLoadBotSettings,
        errorMessage,
        botSettings,
      }}
    >
      {children}
    </PairAFRBotContext.Provider>
  );
};

export default PairAFRBotContextProvider;
