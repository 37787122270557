import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export type IPriceFormat = 'usd' | 'token';

export enum EChartFormat {
  Percentage = 'percentage',
  Normal = 'normal',
}

export const enum EChartConvertedFormat {
  Original = 'original',
  Converted = 'converted',
}

type INumber = number;

type IHoursRange = `${INumber}H`;
type IDaysRange = `${INumber}D`;
type IWeeksRange = `${INumber}W`;
type IMonthsRange = `${INumber}M`;
type IYearsRange = `${INumber}Y`;
type IAllRange = 'ALL';

export type IChartRange =
  | IHoursRange
  | IDaysRange
  | IWeeksRange
  | IMonthsRange
  | IYearsRange
  | IAllRange;

export const mapChartRangeToRangeAPI = (range: IChartRange): IChartRange => {
  if (range === 'ALL') return 'ALL';

  const rangeNumber = Number(
    range
      .split('')
      .filter(el => el.match(/^\d$/))
      .join(''),
  );

  // is hours
  if (range.includes('H')) {
    return `${rangeNumber}H`;
  }

  // is days
  if (range.includes('D')) {
    return `${rangeNumber}D`;
  }

  // is weeks
  if (range.includes('W')) {
    return `${rangeNumber}W`;
  }

  // is month
  if (range.includes('M')) {
    return `${rangeNumber}M`;
  }

  // is year
  if (range.includes('Y')) {
    return `${rangeNumber}Y`;
  }

  return `${rangeNumber}` as IChartRange;
};

export const mapChartRangeToCaption = (range: IChartRange) => {
  if (range === 'ALL') return 'ALL';

  const rangeNumber = Number(
    range
      .split('')
      .filter(el => el.match(/^\d$/))
      .join(''),
  );

  // is hours
  if (range.includes('H')) {
    return `${rangeNumber}H`;
  }

  // is days
  if (range.includes('D')) {
    return `${rangeNumber}D`;
  }

  // is weeks
  if (range.includes('W')) {
    return `${rangeNumber}W`;
  }

  // is month
  if (range.includes('M')) {
    return `${rangeNumber}M`;
  }

  // is year
  if (range.includes('Y')) {
    return `${rangeNumber}Y`;
  }
};

export type IKattanaNetwork = 'BSC' | 'ETH' | 'BSC_TESTNET' | 'ETH_TESTNET';
