import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from 'store';

import { ApiBalanceBot, ApiBot } from 'api';
import { IDexBalanceBotTask, EDexBot, IDexBotSettings } from 'types/bots';
import { IEstimateFee } from '../api/apiBalanceBot/models/IGetBalanceBotResponse';

export interface IPairBalanceBotContext {
  loading: { get: boolean; set: (v: boolean) => void };
  records: {
    get: IDexBalanceBotTask[] | undefined | null;
    set: (v: IDexBalanceBotTask[] | undefined | null) => void;
  };
  estimateFee: IEstimateFee | undefined | null;
  botSettings: IDexBotSettings | undefined;
  handleLoadRecords: () => Promise<void>;
  handleLoadBotSettings: () => Promise<void>;
  extendedRow: number | undefined;
  setExtendedRow: (v: number | undefined) => void;
  errorMessage: string | undefined;
  taskEnablingLoading: { get: number | undefined; set: (v: number | undefined) => void };
}

export const PairBalanceBotContext = createContext<IPairBalanceBotContext>({
  loading: { get: false, set: () => {} },
  records: { get: undefined, set: () => {} },
  estimateFee: undefined,
  extendedRow: undefined,
  setExtendedRow: () => {},
  botSettings: undefined,
  handleLoadRecords: async () => {},
  handleLoadBotSettings: async () => {},
  errorMessage: undefined,
  taskEnablingLoading: { get: undefined, set: () => {} },
});

interface IPairBalanceBotContextProviderPropsV2 {
  children?: React.ReactNode;
}

export const PairBalanceBotContextProviderV2: React.FC<IPairBalanceBotContextProviderPropsV2> = ({
  children,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const [loading, setLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<IDexBalanceBotTask[] | undefined | null>(undefined);
  const [estimateFee, setEstimateFee] = useState<IEstimateFee | undefined | null>(undefined);
  const [botSettings, setBotSettings] = useState<IDexBotSettings | undefined>(undefined);

  const [taskEnablingLoading, setTaskEnablingLoading] = useState<number | undefined>(undefined);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [extendedRow, setExtendedRow] = useState<number | undefined>(undefined);

  const handleSetExtendedRow = useCallback((index: number | undefined) => {
    if (index == undefined) {
      setExtendedRow(undefined);
    } else {
      setExtendedRow(v => (v === index ? undefined : index));
    }
  }, []);

  const handleLoadRecords = useCallback(async () => {
    setLoading(true);
    setExtendedRow(undefined);
    setErrorMessage(undefined);

    if (!pair) return;
    try {
      const { isSuccess, errorMessage, data } = await ApiBalanceBot.getPairBalanceBotTasks({
        pairs_ids: pair.id,
      });
      if (isSuccess && data) {
        setRecords(data.items);

        setErrorMessage(undefined);
      } else {
        setRecords(undefined);
        setErrorMessage(errorMessage ?? undefined);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        'You get an error while trying to get balance bot records. Try to refresh your page...',
      );
    } finally {
      setLoading(false);
    }
  }, [pair]);

  const handleLoadBotSettings = useCallback(async () => {
    setLoading(true);

    if (!pair) return;

    try {
      const { isSuccess, data, errorMessage } = await ApiBot.getDexBotConfig({
        pairId: pair.id,
        bot: EDexBot.balance_bot,
      });

      if (isSuccess && data) {
        setErrorMessage(undefined);
        setBotSettings({
          enableBot: data.is_enabled,
          privateTx: data.send_private_transactions,
          slipage: data.slippage_percent,
        });
      } else if (!isSuccess) {
        setErrorMessage(errorMessage ?? 'Failed to load bot config. Try to refresh your page...');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed to load bot config. Try to refresh your page...');
    } finally {
      setLoading(false);
    }
  }, [pair]);

  useEffect(() => {
    handleLoadBotSettings();
  }, [handleLoadBotSettings]);

  const handleLoadEstimateFee = useCallback(async () => {
    setLoading(true);
    setExtendedRow(undefined);
    setErrorMessage(undefined);
    if (!pair) return;
    try {
      const { isSuccess, errorMessage, data } = await ApiBalanceBot.getEstimateFeeById({
        pairs_id: pair.id,
      });
      if (isSuccess && data) {
        setEstimateFee(data);

        setErrorMessage(undefined);
      } else {
        setRecords(undefined);
        setErrorMessage(errorMessage ?? undefined);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        'You get an error while trying to get Estimate fee records. Try to refresh your page...',
      );
    } finally {
      setLoading(false);
    }
  }, [pair?.id]);

  useEffect(() => {
    handleLoadRecords();
    handleLoadEstimateFee();
  }, [pair?.id]);

  return (
    <PairBalanceBotContext.Provider
      value={{
        loading: { get: loading, set: setLoading },
        records: { get: records, set: setRecords },
        estimateFee,
        botSettings,
        handleLoadRecords,
        handleLoadBotSettings,
        errorMessage,
        extendedRow: extendedRow,
        setExtendedRow: handleSetExtendedRow,
        taskEnablingLoading: { get: taskEnablingLoading, set: setTaskEnablingLoading },
      }}
    >
      {children}
    </PairBalanceBotContext.Provider>
  );
};
