import React from 'react';

import './style.scss';

interface IStatisticTotalCellProps {
  title: string;
  subtitle: string;
}

const StatisticTotalCell: React.FC<IStatisticTotalCellProps> = React.memo(({ subtitle, title }) => {
  return (
    <th>
      <div className="mm-statistic-table-total-cell">
        <span className="value">{title}</span>
        <span className="subvalue">{subtitle}</span>
      </div>
    </th>
  );
});

export default StatisticTotalCell;
