import React, { useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { AnimatePresence } from 'framer-motion';
import { useTypedSelector } from 'store';

import { PanelNoInfo, Spinner } from 'ui';
import { ICexOrderSide, ICexOrderAgregatedTrades } from 'types/orders';

import { useRecords } from './useRecords';
import { StaticTable, TBody } from '../../common';
import {
  Time,
  timeAccessor,
  Account,
  accountAccessor,
  User,
  userAccessor,
  OrderId,
  sideAccessor,
  Side,
  FilledAmount,
  Price,
  AvgFilledPrice,
  Fee,
  FilledTotal,
} from './colums';
import ExtendedTr from './ExtendedTr/ExtendedTr';

import './style.scss';

const CexPairOrdersTable: React.FC = () => {
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { records, handleLoadMore, hasMore, loading } = useRecords();
  const [extendedOrderId, setExtendedOrderId] = useState<null | number>(null);

  const handleSetExtendedOrderId = useCallback((id: number) => {
    setExtendedOrderId(previousTransactionId => {
      if (previousTransactionId === id) return null;

      return id;
    });
  }, []);

  const columns = useMemo(
    () =>
      (
        [
          {
            header: () => <div style={{ textAlign: 'left' }}>Time</div>,
            id: 'order-time',
            accessorFn: timeAccessor,
            cell: ({ getValue }) => <Time time={getValue() as string} />,
            size: 90,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Account</div>,
            id: 'order-account',
            accessorFn: accountAccessor,
            cell: ({ getValue }) => <Account account={getValue() as string} />,
            size: 110,
          },
          isAdmin
            ? {
                header: () => <div style={{ textAlign: 'left' }}>User</div>,
                id: 'order-user',
                accessorFn: userAccessor,
                cell: ({ getValue }) => <User user={getValue() as string} />,
                size: 100,
              }
            : null,
          {
            header: () => <div style={{ textAlign: 'left' }}>Order id</div>,
            id: 'order-id',
            accessorFn: () => {},
            cell: ({ row }) => <OrderId order={row.original} />,
            size: 80,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Side</div>,
            id: 'order-side',
            accessorFn: sideAccessor,
            cell: ({ getValue }) => <Side side={getValue() as ICexOrderSide} />,
            size: 40,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Filled/Amount</div>,
            id: 'order-filled-amount',
            accessorFn: () => {},
            cell: ({ row }) => <FilledAmount order={row.original} />,
            size: 110,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Price</div>,
            id: 'order-price',
            accessorFn: () => {},
            cell: ({ row }) => <Price order={row.original} />,
            size: 100,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Avg filled price</div>,
            id: 'order-avg-filled-price',
            accessorFn: () => {},
            cell: ({ row }) => <AvgFilledPrice order={row.original} />,
            size: 100,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Filled/Total</div>,
            id: 'order-filled-total-price',
            accessorFn: () => {},
            cell: ({ row }) => <FilledTotal order={row.original} />,
            size: 100,
          },
          {
            header: () => <div style={{ textAlign: 'right' }}>Fee</div>,
            id: 'order-fee',
            accessorFn: () => {},
            cell: ({
              row,
              table: {
                options: { meta },
              },
            }) => {
              const _meta = meta as any;

              const extendedOrderId = _meta.extendedOrderId;
              const handleSetExtendedOrderId = _meta.handleSetExtendedOrderId;

              return (
                <Fee
                  order={row.original}
                  extendedOrderId={extendedOrderId}
                  handleSetExtendedOrderId={handleSetExtendedOrderId}
                />
              );
            },
            minSize: 80,
            size: 80,
          },
        ] as ColumnDef<ICexOrderAgregatedTrades>[]
      ).filter(el => el !== null),
    [isAdmin],
  );

  const table = useReactTable({
    data: useMemo(() => records ?? [], [records]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      extendedOrderId,
      handleSetExtendedOrderId,
    },
  });

  const { rows } = table.getRowModel();

  const { virtualItems: virtualRows, totalSize } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 5,
    estimateSize: React.useCallback(() => 57, []),
  });

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse();

    if (!lastItem) {
      handleLoadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      handleLoadMore();
    }
  }, [virtualRows, hasMore, loading, handleLoadMore, rows.length]);

  return (
    <div
      className="mm-cex-order-history-table-container scrollable"
      ref={tableContainerRef}
      onScroll={() => setExtendedOrderId(null)}
    >
      {!!loading && records === undefined && (
        <div className="table-loading">
          <Spinner size={'medium'} />
        </div>
      )}
      {!!records && records.length === 0 && (
        <PanelNoInfo title="Not found" text="You don't have any transactions yet" />
      )}
      <div style={{ width: '100%', height: `${totalSize}px` }}>
        <StaticTable className="mm-cex-order-history-table">
          {records && records.length !== 0 && (
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <TBody paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <AnimatePresence>
              {virtualRows.map(virtualRow => {
                const row = rows[virtualRow.index];

                const rowIsExtended = row.original.id === extendedOrderId;

                return (
                  <React.Fragment key={virtualRow.index}>
                    <tr
                      style={{ height: virtualRow.size }}
                      className={Number(row.id) % 2 === 0 ? 'tr-colorized' : undefined}
                    >
                      {row ? (
                        row.getVisibleCells().map(cell => {
                          return (
                            <td
                              key={cell.id}
                              style={{ height: virtualRow.size, width: cell.column.getSize() }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })
                      ) : (
                        <td style={{ height: virtualRow.size }}>-</td>
                      )}
                    </tr>
                    {rowIsExtended &&
                      row.original.transfers.map(transfer => (
                        <ExtendedTr key={transfer.cex_trade_id} transfer={transfer} />
                      ))}
                  </React.Fragment>
                );
              })}
            </AnimatePresence>
          </TBody>
        </StaticTable>
      </div>
    </div>
  );
};

export { CexPairOrdersTable };
