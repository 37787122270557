import React, { useMemo, memo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { motion } from 'framer-motion';

import { ITransactionItem } from 'api/apiTransactions/models';
import { addBignumbers } from 'utils/formulas';
import { SelectArrowSmall } from 'assets/icons';
import { formatToken } from 'utils/formats';
import { IDexPair } from 'types/pairs';

interface ITransactionFeeProps {
  pair: IDexPair;
  row: ITransactionItem;
  extendedTransactionId: number | null;
  handleSetExtendedTransactionId: (id: number) => void;
}

export const TransactionFee: React.FC<ITransactionFeeProps> = memo(
  ({ pair, row, extendedTransactionId, handleSetExtendedTransactionId }) => {
    const { id } = useMemo(() => row, [row]);

    const transfers = useMemo(() => row.transfers ?? [], [row]);

    const fee = useMemo(() => {
      return transfers.reduce(
        (acc, transfer) => {
          if (!transfer.is_fee) return acc;

          return {
            amount: addBignumbers(
              [acc.amount, transfer.token.decimals],
              [BigNumber.from(transfer.amount), transfer.token.decimals],
            ),
            decimal: transfer.token.decimals,
            symbol: transfer.token.symbol,
          };
        },
        {
          amount: BigNumber.from('0'),
          decimal: pair ? pair.token_fee.decimals : 18,
          symbol: pair ? pair.token_fee.symbol : '',
        },
      );
    }, [pair, transfers]);

    const rowIsSelected = useMemo(() => extendedTransactionId === id, [extendedTransactionId, id]);

    if (!transfers || transfers.length === 0) return <>{'-'}</>;

    return (
      <div className="fee-and-select" onClick={() => handleSetExtendedTransactionId(id)}>
        <span>{`${formatToken(fee.amount, fee.decimal)} ${fee.symbol}`}</span>
        <motion.div animate={{ rotate: rowIsSelected ? 180 : 0 }} className="select-icon-container">
          <SelectArrowSmall />
        </motion.div>
      </div>
    );
  },
);
