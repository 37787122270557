import React from 'react';
import { Row } from '@tanstack/react-table';

import { TableTokens } from 'ui';
import { IPairWallet } from 'api/apiPairs/models';
import { useTypedSelector } from 'store';

interface ITokensCell {
  row: Row<IPairWallet>;
}

const TokensCell: React.FC<ITokensCell> = ({ row }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  return (
    <TableTokens
      tokens={row.original.tokens}
      mainBaseTokenSymbol={dexPair.token_base.symbol}
      mainQuoteTokenSymbol={dexPair.token_quote.symbol}
    />
  );
};

export default TokensCell;
