import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';

import { IPairWallet } from 'api/apiPairs/models';
import { TOTAL_TOKEN_ID } from 'constants/numbers';

interface ITransactionsCellProps {
  row: Row<IPairWallet>;
}

const TransactionsCell: React.FC<ITransactionsCellProps> = React.memo(({ row }) => {
  const totalInfo = useMemo(
    () => row.original.tokens?.find((token) => token.token.id === TOTAL_TOKEN_ID),
    [row]
  );

  if (!totalInfo) return <div style={{ textAlign: 'right' }}>0</div>;

  return <div style={{ textAlign: 'right' }}>{totalInfo.transactions_count}</div>;
});

export default TransactionsCell;
