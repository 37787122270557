export const PROJECT_STATISTIC_RELOAD = 'reload/project-statistic';
export const PAIR_STATISTIC_RELOAD = 'reload/pair-statistic';
export const PAIR_CEX_STATISTIC_RELOAD = 'reload/cex-pair-statistic';
export const PAIR_WALLETS_RELOAD = 'reload/pair-wallets';
export const PAIR_TRANSACTIONS_RELOAD = 'reload/pair-transactions';

export const CEX_ORDERS_RELOAD = 'reload/cex-pair-orders';

export const PROJECT_WALLETS_RELOAD = 'reload/project-wallets';
export const PROJECT_ACCOUNT_RELOAD = 'reload/project-accounts';
export const PROJECT_CEX_ACCOUNTS_RELOAD = 'reload/cex-project-accounts';

export const STOP_RELOAD_PREFIX = 'stop-reload/';

export const RELOAD_WITHDRAWAL_WHTELIST = 'withdrawal-whitelist-reload';
