import React, { useContext } from 'react';

import { PairWashTradingContext } from 'context/PairWashTradingBotContext';

import FormChartInfo from '../FormChartInfo/FormChartInfo';
import Presets from '../Presets/Presets';
import Advanced from '../Advanced/Advanced';
import QuickStart from '../QuickStart/QuickStart';
import AdditionalReserves from '../AdditionalReserves';

import './form.scss';

const Form: React.FC = () => {
  const { selectedTab } = useContext(PairWashTradingContext);

  return (
    <div className="mm-wt-bot-form-container">
      {selectedTab.get === 'advanced' && <AdditionalReserves />}
      <FormChartInfo />
      <Presets />
      <h4 className="strategy-title">Random strategy</h4>
      {selectedTab.get === 'advanced' && <Advanced />}
      {selectedTab.get === 'quick-start' && <QuickStart />}
    </div>
  );
};

export default Form;
