import React from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { Row } from '@tanstack/react-table';

import { IPairWallet } from 'api/apiPairs/models';
import { formatFiat } from 'utils/formats';
import { TOTAL_TOKEN_ID } from 'constants/numbers';

interface ITotalUsdCellProps {
  row: Row<IPairWallet>;
}

const TotalUsdCell: React.FC<ITotalUsdCellProps> = React.memo(({ row }) => {
  const totalInfo = row.original.tokens?.find((token) => token.token.id === TOTAL_TOKEN_ID);

  if (!totalInfo) return <div style={{ textAlign: 'right' }}>0</div>;

  return (
    <div style={{ textAlign: 'right' }}>{formatFiat(BigNumber.from(totalInfo.balance_usd), 6)}</div>
  );
});

export default TotalUsdCell;
