import React, { HTMLAttributes } from 'react';
import cn from 'classnames';

import './button.scss';

interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  color?: 'primary' | 'secondary' | 'transparent' | 'error' | 'error-secondary' | 'error-bordered';
  disabled?: boolean;
  type?: 'submit' | 'button';
  size?: 'small' | 'big';
  nodeLeft?: React.ReactNode;
  nodeRight?: React.ReactNode;
  className?: string;
}

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      color = 'primary',
      type,
      disabled = false,
      onClick,
      size = 'big',
      nodeLeft,
      nodeRight,
      className,
      ...rest
    },
    ref,
  ) => {
    const handleClick = (): void => {
      if (onClick) {
        onClick();
      }
    };

    return (
      <button
        disabled={disabled}
        className={cn(
          'mm-button',
          `mm-button-${color}`,
          {
            'mm-button-disabled': disabled,
          },
          `mm-button-${size}`,
          className,
        )}
        type={type}
        onClick={handleClick}
        {...rest}
        ref={ref ?? undefined}
      >
        {nodeLeft && nodeLeft}
        {children}
        {nodeRight && nodeRight}
      </button>
    );
  },
);

export { Button };
