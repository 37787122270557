import React, { useCallback, useContext, useEffect } from 'react';

import { SelectTimeframeField } from 'fields';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';

import './style.scss';

const TimeframeController: React.FC = () => {
  const { currentConfig, chartLoading, triggerLoadChart } = useContext(PairWashTradingContext);

  useEffect(() => {
    if (currentConfig.get) {
      currentConfig.set({ ...currentConfig.get, interval: '15m' });
    }

    //eslint-disable-next-line
  }, []);

  const handleSelectItem = useCallback(
    (value: string) => {
      if (currentConfig.get) {
        currentConfig.set({ ...currentConfig.get, interval: value });

        setTimeout(() => {
          triggerLoadChart.set(v => !v);
        }, 0);
      }
    },
    //eslint-disable-next-line
    [currentConfig.get, currentConfig.set, triggerLoadChart.set],
  );

  if (!currentConfig.get) return null;

  return (
    <div className="mm-wt-bot-panel__timeframe-controller">
      <SelectTimeframeField
        label="Timeframe"
        placeholder="Select timeframe"
        items={WT_TIMEFRAMES}
        selectedItem={
          WT_TIMEFRAMES.find(el => el.value === currentConfig.get?.interval) ?? undefined
        }
        onSelectItem={v => (v ? handleSelectItem(v.value) : undefined)}
        disabled={!!chartLoading.get}
      />
    </div>
  );
};

export default TimeframeController;
