import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { ITransactionItem } from './models';

class ApiTransactions extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiTransactions | undefined;
  private static instance = (() => {
    if (ApiTransactions.__instance === undefined) {
      ApiTransactions.__instance = new ApiTransactions();
    }
    return ApiTransactions.__instance;
  })();

  public static getTransactions = async ({
    limit,
    lastSeenId,
    pair_ids,
  }: {
    limit: number;
    lastSeenId: number;
    pair_ids?: number[];
  }): Promise<Response<{ has_next: boolean; items: ITransactionItem[] }>> => {
    let mappedResponseOrError: Response<{ has_next: boolean; items: ITransactionItem[] }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('last_seen_id', lastSeenId.toString());

    if (pair_ids) {
      for (const pairId of pair_ids) {
        urlParams.append('pair_ids', pairId.toString());
      }
    }

    try {
      const response = await ApiTransactions.instance.get<
        IBaseResponse<{ has_next: boolean; items: ITransactionItem[] }>
      >('/transactions?' + urlParams.toString());
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ has_next: boolean; items: ITransactionItem[] }>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiTransactions };
