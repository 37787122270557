export const formatStringToAnyPositiveNumber = ({
  string,
}: {
  string: string | undefined;
}): { value: string | undefined; formatedValue: string | undefined } => {
  if (string === undefined) return { value: undefined, formatedValue: undefined };

  const resultStringWithoutChars = string
    .split('')
    .filter((char) => char.match(/[0-9\.\,]$/))
    .join('')
    .replace(',', '.') // replace commas
    .replace(/^(\.|\,)/, '') // replace single comma from begining
    .replace(/^0{2,}/, '0') // remove zeros from begining
    .replace(/^0(?=\d)/, '0.') // replace 0123 to 0.123
    .replace(/[.|,](?=.*[.|,])/g, '') // remove double commas and points
    .replace(/^(\d{0,12})\d*(\.\d{0,18}|\d{0,19}).*/g, '$1$2'); // limit for amount - 7 before point, 2 after

  return { value: resultStringWithoutChars, formatedValue: resultStringWithoutChars };
};
