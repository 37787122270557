import { useMemo } from 'react';
import { useTypedSelector } from 'store';
import { isNil } from 'lodash';

import { EDexPairFilters } from 'types/filters';

export function useIsDexPairFiltersSet<V extends EDexPairFilters>({
  pairId,
  type,
}: {
  pairId: number | undefined;
  type: V;
}) {
  const filters = useTypedSelector(store =>
    !isNil(pairId)
      ? store.dexPairFilters[pairId]
        ? store.dexPairFilters[pairId]!.filters[type]?.setted
        : undefined
      : undefined,
  );

  const isSetted = useMemo(() => {
    if (isNil(pairId)) return false;
    if (!filters) return false;

    for (const field of filters) {
      if (field.value !== undefined) return true;
    }

    return false;
  }, [pairId, filters]);

  return isSetted;
}
