import { useCallback, useMemo } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';
import { isNil } from 'lodash';

import { IDexPair } from 'types/pairs';
import { EDexPairFilters, dexPairFiltersScheme } from 'types/filters';
import { IDexFilterValue } from 'types/filters/common';

import { useGetDexPairFilters } from './useGetDexPairFilters';
import { useIsDexPairFiltersSet } from './useIsDexPairFiltersSet';
import {
  addDexPairFilter,
  updateDexPairFilter,
  deleteDexPairFilter,
  clearDexPairFilters,
  saveDexPairFilters,
} from '..';

export function useDexPairFilters<V extends EDexPairFilters>({
  dexPair,
  type,
}: {
  dexPair: IDexPair | undefined;
  type: V;
}) {
  const dispatch = useTypedDispatch();

  const pairId = useMemo(() => dexPair?.id, [dexPair]);

  const getDexPairFilters = useGetDexPairFilters({ pairId, type });
  const isFilterSetted = useIsDexPairFiltersSet({ pairId, type });

  const pairFiltersScheme = useMemo(
    () => dexPairFiltersScheme({ pair: dexPair, type }),
    [dexPair, type],
  );

  const pairFilters = useMemo(() => getDexPairFilters(), [getDexPairFilters]);

  const settedPairFilters = useTypedSelector(store =>
    !isNil(pairId) ? store.dexPairFilters[pairId]?.filters[type]?.setted ?? [] : [],
  );

  const addFilter = useCallback(
    (filter?: IDexFilterValue) => {
      if (isNil(pairId)) return;

      dispatch(addDexPairFilter({ pairId, type, filter }));
    },
    [dispatch, pairId, type],
  );

  const updateFilter = useCallback(
    function (id: string, filter?: IDexFilterValue | undefined) {
      if (isNil(pairId)) return;

      dispatch(updateDexPairFilter({ id, filter, pairId, type }));
    },
    [dispatch, pairId, type],
  );

  const deleteFilter = useCallback(
    (id: string) => {
      if (isNil(pairId)) return;

      dispatch(deleteDexPairFilter({ pairId, type, id }));
    },
    [dispatch, pairId, type],
  );

  const clearFilters = useCallback(() => {
    dispatch(clearDexPairFilters({ pairId, type }));
  }, [dispatch, pairId, type]);

  const saveFilters = useCallback(() => {
    dispatch(saveDexPairFilters({ pairId, type }));
  }, [dispatch, pairId, type]);

  return {
    pairFilters,
    settedPairFilters,
    addFilter,
    updateFilter,
    deleteFilter,
    isFilterSetted,
    scheme: pairFiltersScheme,
    clearFilters,
    saveFilters,
  };
}
