import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { IPairWallet, IDexToken } from 'api/apiPairs/models';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { useERC20Balance } from 'hooks';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { calculateWalletsTokenBalance, calculateWalletTokenBalance } from 'utils/calculates';
import { IDexPair } from 'types/pairs';
import { trimDecimalZeroes } from 'utils/formats';
import * as Yup from 'yup';

import { calculateDepositAmounts, calculateWithdrawAmounts } from './utils';

type IType = 'deposit' | 'withdraw';

export interface IInnerWallet extends IPairWallet {
  balance: string;
  walletBalance: BigNumber;
  disabled: boolean;
  isValid: boolean;
  max_amount: boolean;
}

export interface IFormValues {
  balance: BigNumber;
  totalSend: string | undefined;
}

export const mapTypeToCaption = (type: IType) => {
  switch (type) {
    case 'deposit': {
      return 'Deposit';
    }
    case 'withdraw': {
      return 'Withdraw';
    }
  }
};

interface IDepositWithdrawContext {
  type: IType;
  values: IFormValues;
  errors: FormikErrors<IFormValues>;
  touched: FormikTouched<IFormValues>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<IFormValues>>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isFormValid: boolean;
  validateForm: () => Promise<FormikErrors<IFormValues>>;
  selectedToken: { get: IDexToken; set: (v: IDexToken) => void };
  token: {
    balance: BigNumber | null;
    error: string | null | undefined;
    human: string;
  };
  innerWallets: { get: IInnerWallet[]; set: (v: IInnerWallet[]) => void };
  innerWalletsValid: boolean;
  handleChangeTotalAmount: (v: string | undefined) => void;
  formError: string | undefined;
}

export const DepositWithdrawContext = createContext<IDepositWithdrawContext>({
  type: 'deposit',
  values: {} as IFormValues,
  errors: {} as FormikErrors<IFormValues>,
  touched: {} as FormikTouched<IFormValues>,
  setFieldValue: async () => {},
  handleSubmit: () => {},
  isFormValid: false,
  validateForm: async () => {
    return {} as FormikErrors<IFormValues>;
  },
  selectedToken: { get: {} as IDexToken, set: () => {} },
  token: {
    balance: null,
    error: null,
    human: '',
  },
  innerWallets: { get: [], set: () => {} },
  innerWalletsValid: false,
  handleChangeTotalAmount: () => {},
  formError: undefined,
});

interface IDepositWithdrawContextProviderProps {
  children?: React.ReactNode;
  options: {
    type: IType;
    pair: IDexPair;
    wallets: IPairWallet[];
  };
}

const DepositWithdrawContextProvider: React.FC<IDepositWithdrawContextProviderProps> = ({
  children,
  options: { pair, type, wallets },
}) => {
  const [_selectedToken, _setSelectedToken] = useState<IDexToken>(pair.token_base);

  const walletBalances = useMemo(
    () =>
      wallets.reduce(
        (acc, wallet) => {
          const baseBalance =
            wallet.tokens?.find(el => el.token.id === pair.token_base.id)?.balance ??
            BigNumber.from(0);

          const quoteBalance =
            wallet.tokens?.find(el => el.token.id === pair.token_quote.id)?.balance ??
            BigNumber.from(0);

          const feeBalance =
            wallet.tokens?.find(el => el.token.id === pair.token_fee.id)?.balance ??
            BigNumber.from(0);

          return {
            ...acc,
            [pair.token_base.symbol]: acc[pair.token_base.symbol].add(baseBalance),
            [pair.token_quote.symbol]: acc[pair.token_quote.symbol].add(quoteBalance),
            [pair.token_fee.symbol]: acc[pair.token_fee.symbol].add(feeBalance),
          };
        },
        {
          [pair.token_base.symbol]: BigNumber.from(0),
          [pair.token_quote.symbol]: BigNumber.from(0),
          [pair.token_fee.symbol]: BigNumber.from(0),
        } as Record<string, BigNumber>,
      ),
    [wallets, pair],
  );

  const [innerWallets, setInnerWallets] = useState<IInnerWallet[]>(
    wallets.map(wallet => ({
      ...wallet,
      balance: '0',
      walletBalance: calculateWalletTokenBalance(wallet, _selectedToken),
      disabled: false,
      isValid: true,
      max_amount: false,
    })),
  );

  const innerWalletsValid = useMemo(
    () => innerWallets.filter(el => el.isValid).length === innerWallets.length,
    [innerWallets],
  );

  const initialFormValues: IFormValues = useMemo(
    () => ({
      balance: BigNumber.from('0'),
      totalSend: undefined,
    }),
    [],
  );

  const validationFormSchema = useMemo(
    () =>
      Yup.object({
        balance: Yup.object().test(
          'Balance',
          'Selected token balance must be greater than 0',
          (val: any) => val.gt(BigNumber.from('0')),
        ),
        totalSend: Yup.string().test(
          'totalSend',
          'Please enter valid "Total amount" field',
          (totalSend: string | undefined) => totalSend !== '' && !isNaN(Number(totalSend)),
        ),
      }),
    [],
  );

  const [_loading, _setLoading] = useState<boolean>(false);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    isValid,
    validateForm,
  } = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationFormSchema,
    onSubmit: () => {},
  });

  const [tokenBalance, _, tokenBalanceError] = useERC20Balance(_selectedToken.address);

  const tokenBalanceHuman = useMemo(() => {
    const balanceBN = type === 'deposit' ? tokenBalance : walletBalances[_selectedToken.symbol];

    if (!balanceBN) return '0';

    const balance = formatUnits(balanceBN, _selectedToken.decimals);

    return type === 'deposit' ? tokenBalanceError ?? balance : balance;
  }, [type, tokenBalanceError, tokenBalance, _selectedToken, walletBalances]);

  // SELECTED TOKEN
  const handleChangeSelectedToken = useCallback(
    (newSelectedToken: IDexToken) => {
      _setSelectedToken(newSelectedToken);

      setFieldValue('totalSend', undefined);
      setFieldTouched('totalSend', false);

      setInnerWallets(
        wallets.map(wallet => ({
          ...wallet,
          balance: '0',
          walletBalance: calculateWalletTokenBalance(wallet, _selectedToken),
          disabled:
            type === 'withdraw'
              ? calculateWalletTokenBalance(wallet, _selectedToken).eq(BigNumber.from(0))
              : false,
          isValid: true,
          max_amount: false,
        })),
      );
    },
    [_selectedToken, setFieldTouched, setFieldValue, type, wallets],
  );

  // handle change token balance when we select another token
  useEffect(() => {
    if (type === 'deposit') {
      setFieldValue('balance', tokenBalance ?? BigNumber.from('0'));
    }

    if (type === 'withdraw') {
      const walletsTokensBalance = calculateWalletsTokenBalance({
        wallets: wallets,
        token: _selectedToken,
      });
      setFieldValue('balance', walletsTokensBalance);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_selectedToken, wallets, tokenBalance]);

  // handle wallets validation
  const handleValidateInnerWallets = useCallback(
    (wallets: IInnerWallet[]) => {
      const newInnerWallets = wallets.map(wallet => {
        if (wallet.disabled) return wallet;

        const walletBalance = wallet.balance === '' ? '0' : wallet.balance;

        const hasFilledBalance = Number(walletBalance) !== 0;
        const isBalanceBiggerThanZero = parseUnits(walletBalance, _selectedToken.decimals).gt(
          BigNumber.from('0'),
        );
        const hasEnoughTokens = calculateWalletTokenBalance(wallet, _selectedToken).gte(
          parseUnits(walletBalance, _selectedToken.decimals),
        );

        return {
          ...wallet,
          isValid:
            type === 'deposit'
              ? true
              : hasFilledBalance && isBalanceBiggerThanZero && hasEnoughTokens,
        };
      });
      setInnerWallets(newInnerWallets);
    },
    [_selectedToken, type],
  );

  useEffect(() => {
    setInnerWallets(
      wallets.map(wallet => {
        const isDisabled =
          type === 'withdraw'
            ? calculateWalletTokenBalance(wallet, _selectedToken).eq(BigNumber.from(0))
            : false;

        return {
          ...wallet,
          balance: '0',
          walletBalance: calculateWalletTokenBalance(wallet, _selectedToken),
          disabled: isDisabled,
          isValid: true,
          max_amount: false,
        };
      }),
    );
  }, [wallets, _selectedToken, type]);

  const handleChangeInnerWallets = useCallback(
    (newInnerWallets: IInnerWallet[]) => {
      handleValidateInnerWallets(newInnerWallets);
    },
    [handleValidateInnerWallets],
  );

  // TOTAL send

  // is total send valid
  const isTotalSendValid = useMemo(() => {
    if (!values.totalSend || values.totalSend === '') return false;

    const totalSendBN = parseUnits(values.totalSend, _selectedToken.decimals);
    const totalSendBNGtZero = totalSendBN.gt(BigNumber.from('0'));

    return totalSendBNGtZero && values.balance.gte(totalSendBN);
  }, [values.totalSend, values.balance, _selectedToken]);

  // handler of change total amount that change all of wallets balances
  const handleChangeTotalAmount = useCallback(
    (totalAmount: string | undefined) => {
      setFieldValue('totalSend', totalAmount ?? undefined);
      setFieldTouched('totalSend', true);

      const totalAmountBN = !totalAmount
        ? BigNumber.from('0')
        : BigNumber.from(parseUnits(totalAmount ? totalAmount : '0', _selectedToken.decimals));

      const calculateFunction =
        type === 'withdraw' ? calculateWithdrawAmounts : calculateDepositAmounts;

      const newWalletsAmountsBN = calculateFunction(
        totalAmountBN,
        innerWallets
          .filter(el => !el.disabled)
          .map(el => ({
            address: el.address,
            balance: calculateWalletTokenBalance(el, _selectedToken),
          })),
        _selectedToken.decimals,
      );

      const newInnerWallets = innerWallets.map(wallet =>
        wallet.disabled
          ? { ...wallet }
          : {
              ...wallet,
              balance: trimDecimalZeroes(formatUnits(newWalletsAmountsBN[wallet.address], 18)),
              max_amount:
                type === 'withdraw' && newWalletsAmountsBN[wallet.address].eq(wallet.walletBalance),
            },
      );

      handleValidateInnerWallets(newInnerWallets);
    },
    [
      innerWallets,
      _selectedToken,
      handleValidateInnerWallets,
      type,
      setFieldTouched,
      setFieldValue,
    ],
  );

  const formError = useMemo(() => {
    let error = undefined;
    if (errors.balance && touched.balance) {
      error = errors.balance as string;
    } else if (!isTotalSendValid && touched.totalSend) {
      error = 'Please enter valid total send';
    } else if (touched.totalSend && !innerWallets) {
      error = 'Some of wallet balances is invalid';
    }

    return error;
  }, [errors, touched, isTotalSendValid, innerWallets]);

  return (
    <DepositWithdrawContext.Provider
      value={{
        type,
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        isFormValid: isValid,
        validateForm,
        token: {
          balance: type === 'deposit' ? tokenBalance : null,
          error: type === 'deposit' ? tokenBalanceError : null,
          human: tokenBalanceHuman,
        },
        selectedToken: { get: _selectedToken, set: handleChangeSelectedToken },
        innerWallets: { get: innerWallets, set: handleChangeInnerWallets },
        innerWalletsValid,
        handleChangeTotalAmount,
        formError,
      }}
    >
      {children}
    </DepositWithdrawContext.Provider>
  );
};

export default DepositWithdrawContextProvider;
