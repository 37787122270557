import React from 'react';

import { ProjectPageWrapper } from 'ui';

import * as Panels from './panels';

const Project: React.FC = () => {
  return (
    <ProjectPageWrapper>
      <Panels.Statistic />
    </ProjectPageWrapper>
  );
};

export { Project };
