import React, { useMemo } from 'react';

import { ITransactionItem, ETransactionAction } from 'api/apiTransactions/models';
import { IDexPair } from 'types/pairs';

interface ITransactionAssetProps {
  pair: IDexPair;
  row: ITransactionItem;
}

export const TransactionAsset: React.FC<ITransactionAssetProps> = ({ pair, row }) => {
  const { action } = useMemo(() => row, [row]);

  const transfers = useMemo(() => row.transfers ?? [], [row]);

  const baseTokenTransfer = useMemo(
    () => transfers.find(transfer => transfer.token.id === pair.token_base.id),
    [transfers, pair.token_base.id],
  );

  const feeTokenTransfer = useMemo(
    () => transfers.find(transfer => transfer.token.id === pair.token_fee.id),
    [transfers, pair.token_fee.id],
  );

  const depositWithdrawTransfer = useMemo(
    () => (transfers.length === 1 ? transfers[0] : transfers.filter(el => !el.is_fee)[0]),
    [transfers],
  );

  const asset = useMemo(() => {
    let innerToken;

    if (
      action === ETransactionAction.ActionBuy ||
      action === ETransactionAction.ActionBulkBuy ||
      action === ETransactionAction.ActionBulkBuyMempool ||
      action === ETransactionAction.ActionAFRBuy ||
      action === ETransactionAction.ActionSell ||
      action === ETransactionAction.ActionBulkSell ||
      action === ETransactionAction.ActionBulkSellMempool ||
      action === ETransactionAction.ActionAFRSell
    ) {
      innerToken = baseTokenTransfer;
    } else if (
      action === ETransactionAction.ActionDeposit ||
      action === ETransactionAction.ActionWithdrawal
    ) {
      innerToken = depositWithdrawTransfer;
    } else if (
      action === ETransactionAction.ActionBuyEth ||
      action === ETransactionAction.ActionSellEth
    ) {
      innerToken = baseTokenTransfer;
    } else if (action === ETransactionAction.ActionBuyEthHolder) {
      innerToken = feeTokenTransfer;
    }

    if (!innerToken) return null;

    return innerToken.token.symbol;
  }, [action, baseTokenTransfer, depositWithdrawTransfer, feeTokenTransfer]);

  if (action === ETransactionAction.ActionApproval) return <>{''}</>;

  if (!transfers || transfers.length === 0 || !pair) return <>{''}</>;

  return <div className="transaction-asset">{asset}</div>;
};
