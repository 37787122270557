import React, { useContext, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';

import { PairBuySellBotContext } from 'context/PairBuySellBotContext';
import {
  Time,
  Direction,
  TokensAmount,
  Progress,
  AvgPrice,
  FeeSpent,
  RequiredTime,
  OpenIcon,
  QuoteAmount,
} from './columns';
import { RowInfo } from '../RowInfo/RowInfo';
import { IBuySellBotTaskItemInner } from 'types/buy-sell-bot';

import './style.scss';

const Table: React.FC = () => {
  const { records, extendedRow, setExtendedRow } = useContext(PairBuySellBotContext);

  const hasNoRecords = useMemo(() => records.get && records.get.length === 0, [records.get]);

  const columns = useMemo<ColumnDef<IBuySellBotTaskItemInner>[]>(
    () => [
      {
        header: () => <div style={{ textAlign: 'left' }}>Start time</div>,
        id: 'time',
        cell: ({ row: { original } }) => <Time task={original} status={original.status} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Direction</div>,
        id: 'direction',
        cell: ({ row: { original } }) => <Direction direction={original.task.direction} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Total amount</div>,
        id: 'tokens-amount',
        cell: ({ row: { original } }) => (
          <TokensAmount totalAmount={original.task.total_token_amount} />
        ),
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Progress</div>,
        id: 'progress',
        cell: ({ row: { original } }) => (
          <Progress
            progress={{
              amount: original.total.amount,
              amountPercent: original.total.amount_percent,
            }}
          />
        ),
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Quote amount</div>,
        id: 'quote-amount',
        cell: ({ row: { original } }) => <QuoteAmount task={original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Avg price</div>,
        id: 'avg-price',
        cell: ({ row: { original } }) => (
          <AvgPrice
            price={{
              avgPrice: original.total.avg_price,
              avgPriceUsd: original.total.avg_price_usd,
            }}
          />
        ),
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Fee spent</div>,
        id: 'fee-spent',
        cell: ({ row: { original } }) => (
          <FeeSpent
            fee={{ feeSpent: original.total.spent_fee, feeSpentUsd: original.total.spent_fee_usd }}
          />
        ),
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Required time</div>,
        id: 'required-time',
        cell: ({ row: { original } }) => <RequiredTime timeLeftInSec={original.time_left_sec} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}> </div>,
        id: 'open-extended',
        accessorFn: () => {},
        cell: ({
          table: {
            options: { meta },
          },
          row,
        }) => {
          const _meta = meta as any;

          return (
            <OpenIcon opened={_meta.extendedRow !== undefined && _meta.extendedRow === row.index} />
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: records.get ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      //@ts-ignore
      extendedRow,
    },
  });

  const { rows } = table.getRowModel();

  return (
    <table className={cn('mm-static-table', 'mm-buy-sell-bot-table')}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {hasNoRecords && (
          <tr>
            <td colSpan={8}>
              <div className="no-active-records">
                <span>There are no planned/in progress tasks</span>
              </div>
            </td>
          </tr>
        )}
        {rows.map(row => {
          return (
            <React.Fragment key={row.index}>
              <tr
                key={row.index}
                className={cn(
                  {
                    _selected: extendedRow === row.index,
                  },
                  { 'task-finished': row.original.status === 'past' },
                )}
                onClick={() => setExtendedRow(row.index)}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
              <AnimatePresence>
                {extendedRow === row.index && (
                  <tr
                    className={cn('mm-buy-sell-bot-opened-task', {
                      'task-finished': row.original.status === 'past',
                    })}
                  >
                    <td colSpan={9}>
                      <RowInfo task={row.original} rowIdx={row.index} />
                    </td>
                  </tr>
                )}
              </AnimatePresence>
              <tr className="tr-separator" />
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export { Table };
