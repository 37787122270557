import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import {
  StatisticTable,
  StatisticHeader,
  StatisticCell,
  StatisticTotalCell,
} from 'common/statistic';
import { ETransactionAction } from 'api/apiTransactions/models';
import {
  addBignumbers,
  divideBignumbers,
  multiplyBignumbers,
  subtractBignumbers,
} from 'utils/formulas';
import { formatFiat, formatNatural, formatToken } from 'utils/formats';
import { IChartRange } from 'types/charts';
import { ICexOrder, ICexStatisticToken } from 'api/apiStatistic/models/IStatisticToken';

interface IWTAlgorithmTableProps {
  cexStatisticTokens: ICexStatisticToken[];
  statisticCexOrders: ICexOrder[];
  selectedRange: IChartRange | undefined;
}

const CexWTAlgorithmTable: React.FC<IWTAlgorithmTableProps> = ({
  cexStatisticTokens,
  statisticCexOrders,
  selectedRange,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedCexPair);
  const afrActions = useMemo(
    () => [ETransactionAction.ActionBuy, ETransactionAction.ActionSell],
    [],
  );

  const baseToken = useMemo(
    () => cexStatisticTokens.find(el => el.token.symbol === pair!.token_base.symbol),
    [pair, cexStatisticTokens],
  );

  const volumes = useMemo(() => {
    if (!baseToken)
      return {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      };

    return baseToken.volumes.reduce(
      (acc, val) => {
        if (!afrActions.includes(val.action)) return acc;

        const decimals = 18;

        return {
          volumeTokens: addBignumbers(
            [acc.volumeTokens, 18],
            [BigNumber.from(val.amount), decimals],
          ),
          volumeUsd: addBignumbers([acc.volumeUsd, 18], [BigNumber.from(val.amount_usd), 6]),
        };
      },
      {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      },
    );
  }, [afrActions, baseToken]);

  const transactions = useMemo(() => {
    if (!baseToken)
      return {
        total: 0,
        failed: 0,
      };

    return statisticCexOrders.reduce(
      (acc, val) => {
        if (!afrActions.includes(val.action)) return acc;

        return {
          total: acc.total + val.total,
          // failed: acc.failed + val.failed,
        };
      },
      { total: 0 /*failed: 0*/ },
    );
  }, [baseToken, statisticCexOrders, afrActions]);

  const avgAmounts = useMemo(() => {
    const totalTransactions = transactions.total;

    if (totalTransactions <= 0)
      return { avgAmountTokens: BigNumber.from(0), avgAmountUsd: BigNumber.from(0) };

    return {
      avgAmountTokens: divideBignumbers(
        [volumes.volumeTokens, 18],
        [parseUnits(totalTransactions.toString(), 18), 18],
      ),
      avgAmountUsd: divideBignumbers(
        [volumes.volumeUsd, 18],
        [parseUnits(totalTransactions.toString(), 18), 18],
      ),
    };
  }, [transactions, volumes]);

  const baseAndQuoteSpent = useMemo(() => {
    const baseToken = cexStatisticTokens.find(el => el.token.symbol === pair?.token_base?.symbol);
    const quoteToken = cexStatisticTokens.find(el => el.token.symbol === pair?.token_quote?.symbol);

    const baseTokenSpentFees = baseToken?.spent_fees ?? [];
    const quoteTokenSpentFess = quoteToken?.spent_fees ?? [];

    if (!baseToken && !quoteToken) {
      return {
        feeTokens: BigNumber.from(0),
        feeUsd: BigNumber.from(0),
      };
    }

    const baseTokenFees = baseTokenSpentFees
      .filter(el => !afrActions.includes(el.action))
      .reduce(
        (acc, val) => {
          const decimals = 18;

          return {
            feeTokens: addBignumbers([BigNumber.from(val.amount), decimals], [acc.feeTokens, 18]),
            feeUsd: addBignumbers([BigNumber.from(val.amount_usd), 6], [acc.feeUsd, 18]),
          };
        },
        {
          feeTokens: BigNumber.from(0),
          feeUsd: BigNumber.from(0),
        },
      );

    const quoteTokenFees = quoteTokenSpentFess
      .filter(el => !afrActions.includes(el.action))
      .reduce(
        (acc, val) => {
          const decimals = 18;

          return {
            feeTokens: addBignumbers([BigNumber.from(val.amount), decimals], [acc.feeTokens, 18]),
            feeUsd: addBignumbers([BigNumber.from(val.amount_usd), 6], [acc.feeUsd, 18]),
          };
        },
        {
          feeTokens: BigNumber.from(0),
          feeUsd: BigNumber.from(0),
        },
      );

    return {
      tableFeeTokens: `${formatToken(baseTokenFees?.feeTokens || BigNumber.from(0))} ${
        baseToken?.token.symbol
      } + ${formatToken(quoteTokenFees?.feeTokens || BigNumber.from(0)) || 0} ${
        quoteToken?.token.symbol
      }`,
      feeTokens: addBignumbers(
        [baseTokenFees?.feeTokens || BigNumber.from(0), 18],
        [quoteTokenFees?.feeTokens || BigNumber.from(0), 18],
      ),

      feeUsd: addBignumbers(
        [baseTokenFees?.feeUsd || BigNumber.from(0), 18],
        [quoteTokenFees?.feeUsd || BigNumber.from(0), 18],
      ),
    };
  }, [cexStatisticTokens, pair, afrActions]);

  const PNLS = useMemo(() => {
    return cexStatisticTokens.map(el => {
      const buyVolume = el.volumes.find(el => el.action === ETransactionAction.ActionBuy);
      const sellVolume = el.volumes.find(el => el.action === ETransactionAction.ActionSell);

      const decimals = 18;

      if (el.token.symbol === pair?.token_native?.symbol)
        return {
          token: el.token,
          pnlTokens: subtractBignumbers([BigNumber.from(0), 18], [baseAndQuoteSpent.feeTokens, 18]),
          pnlUsd: subtractBignumbers([BigNumber.from(0), 18], [baseAndQuoteSpent.feeUsd, 18]),
        };

      if (!buyVolume && !sellVolume)
        return {
          token: el.token,
          pnlTokens: BigNumber.from(0),
          pnlUsd: BigNumber.from(0),
        };

      return {
        token: el.token,
        pnlTokens: subtractBignumbers(
          [BigNumber.from(buyVolume ? buyVolume.amount : '0'), decimals],
          [BigNumber.from(sellVolume ? sellVolume.amount : '0'), decimals],
        ),
        pnlUsd: subtractBignumbers(
          [BigNumber.from(buyVolume ? buyVolume.amount_usd : '0'), 6],
          [BigNumber.from(sellVolume ? sellVolume.amount_usd : '0'), 6],
        ),
      };
    });
  }, [cexStatisticTokens, baseAndQuoteSpent, pair]);
  const ROIS = useMemo(() => {
    return cexStatisticTokens.map(el => {
      const pnl = PNLS.find(pnl => pnl.token.symbol === el.token.symbol);
      const decimals = 18;

      if (!pnl)
        return {
          token: el.token,
          roiTokens: BigNumber.from(0),
          roiUsd: BigNumber.from(0),
        };

      const startBalanceTokensBN = BigNumber.from(
        selectedRange === 'ALL' ? el.initial_balance ?? '0' : el.start_balance,
      );
      const startBalanceUsdBN = BigNumber.from(
        selectedRange === 'ALL' ? el.initial_balance_usd ?? '0' : el.start_balance_usd,
      );

      return {
        token: el.token,
        roiTokens: !startBalanceTokensBN.eq(BigNumber.from(0))
          ? multiplyBignumbers(
              [divideBignumbers([pnl.pnlTokens, 18], [startBalanceTokensBN, decimals]), 18],
              [parseUnits('100', 18), 18],
            )
          : BigNumber.from(0),
        roiUsd: !startBalanceUsdBN.eq(BigNumber.from(0))
          ? multiplyBignumbers(
              [divideBignumbers([pnl.pnlUsd, 18], [startBalanceUsdBN, 6]), 18],
              [parseUnits('100', 18), 18],
            )
          : BigNumber.from(0),
      };
    });
  }, [cexStatisticTokens, PNLS, selectedRange]);

  const TOTALS = useMemo(() => {
    const totalPnl = PNLS.reduce((acc, val) => {
      return addBignumbers([acc, 18], [val.pnlUsd, 18]);
    }, BigNumber.from(0));

    const totalStartBalanceUsd = cexStatisticTokens.reduce((acc, val) => {
      const startBalanceUsd =
        selectedRange === 'ALL' ? val.initial_balance_usd ?? '0' : val.start_balance_usd;

      return addBignumbers([acc, 18], [BigNumber.from(startBalanceUsd), 6]);
    }, BigNumber.from(0));

    return {
      totalPnl: totalPnl,
      totalROI: totalStartBalanceUsd.eq(BigNumber.from(0))
        ? BigNumber.from(0)
        : multiplyBignumbers(
            [divideBignumbers([totalPnl, 18], [totalStartBalanceUsd, 18]), 18],
            [parseUnits('100', 18), 18],
          ),
    };
  }, [PNLS, cexStatisticTokens, selectedRange]);

  return (
    <div className="mm-statistic-general-tables">
      <StatisticTable>
        <thead>
          <tr>
            <StatisticHeader title={'Volume'} />
            <StatisticHeader title={'Avg amount'} />
            <StatisticHeader title={'Trades'} />
            <StatisticHeader title={'Fee spent'} />
            <StatisticHeader title={'PnL'} />
            <StatisticHeader title={'ROI'} />
          </tr>
        </thead>
        <tbody>
          <tr>
            <StatisticCell
              title={formatToken(volumes.volumeTokens) + ' ' + pair?.token_base.symbol}
              subtitle={`(${formatFiat(volumes.volumeUsd, 18)})`}
            />
            <StatisticCell
              title={formatToken(avgAmounts.avgAmountTokens) + ' ' + pair?.token_base.symbol}
              subtitle={`(${formatFiat(avgAmounts.avgAmountUsd, 18)})`}
            />
            <StatisticCell
              title={formatNatural(transactions.total.toString())}
              // subtitle={`(${formatNatural(transactions.failed.toString())} failed)`}
            />

            <StatisticCell
              title={`${baseAndQuoteSpent.tableFeeTokens}`}
              subtitle={`(${formatFiat(baseAndQuoteSpent.feeUsd, 18)})`}
            />
            <StatisticCell
              title={
                formatToken(
                  PNLS.find(el => el.token.symbol === pair!.token_base?.symbol)?.pnlTokens ??
                    BigNumber.from(0),
                ) +
                ' ' +
                pair!.token_base.symbol
              }
              subtitle={`(${formatFiat(
                PNLS.find(el => el.token.symbol === pair!.token_base?.symbol)?.pnlUsd ??
                  BigNumber.from(0),
                18,
              )})`}
            />
            <StatisticCell
              title={
                formatFiat(
                  ROIS.find(el => el.token.symbol === pair!.token_base?.symbol)?.roiTokens ??
                    BigNumber.from(0),
                  18,
                  '',
                  { zeroWithoutMinus: true },
                ) + '%'
              }
              subtitle={`/ ${formatFiat(
                ROIS.find(el => el.token.symbol === pair!.token_base?.symbol)?.roiUsd ??
                  BigNumber.from(0),
                18,
                '',
                { zeroWithoutMinus: true },
              )}%`}
            />
          </tr>
          {PNLS.filter(el => el.token.symbol !== pair!.token_base?.symbol).map((el, index) => {
            const ROI = ROIS.find(roi => roi.token.symbol === el.token.symbol)!;

            return (
              <tr key={index}>
                <StatisticCell title={''} subtitle={''} />
                <StatisticCell title={''} subtitle={''} />
                <StatisticCell title={''} subtitle={''} />
                <StatisticCell title={''} subtitle={''} />
                <StatisticCell
                  title={formatToken(el.pnlTokens) + ' ' + el.token.symbol}
                  subtitle={`(${formatFiat(el.pnlUsd, 18)})`}
                />
                <StatisticCell
                  title={formatFiat(ROI.roiTokens, 18, '', { zeroWithoutMinus: true }) + '%'}
                  subtitle={`/ ${formatFiat(ROI.roiUsd, 18, '', { zeroWithoutMinus: true })}%`}
                />
              </tr>
            );
          })}
          <tr>
            <StatisticCell title={''} subtitle={''} />
            <StatisticCell title={''} subtitle={''} />
            <StatisticCell title={''} subtitle={''} />
            <StatisticCell title={''} subtitle={''} />
            <StatisticTotalCell title="Total" subtitle={formatFiat(TOTALS.totalPnl, 18)} />
            <StatisticTotalCell
              title="Total"
              subtitle={formatFiat(TOTALS.totalROI, 18, '', { zeroWithoutMinus: true }) + '%'}
            />
          </tr>
        </tbody>
      </StatisticTable>
    </div>
  );
};

export { CexWTAlgorithmTable };
