import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { ICexWTRegularTask } from 'types/bots';
import { durationToMs } from 'utils/duration';
import { normalizeBigNumber } from 'utils/formulas';
import { formatToken, formatFiat } from 'utils/formats';
import { dailyVolume } from 'utils/daily';

interface IDailyVolumeProps {
  task: ICexWTRegularTask;
}

export const DailyVolume: React.FC<IDailyVolumeProps> = ({ task }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const delayMin = durationToMs(task.options.pause_between_groups_min);
  const delayMax = durationToMs(task.options.pause_between_groups_max);

  const decimals = useMemo(() => 18, []);

  const volumeMin = normalizeBigNumber(task.options.vol_per_group_min, decimals, decimals);
  const volumeMax = normalizeBigNumber(task.options.vol_per_group_max, decimals, decimals);

  const { volume, volumeUsd } = dailyVolume({
    delay_min: delayMin,
    delay_max: delayMax,
    volume_min: volumeMin,
    volume_max: volumeMax,
    pair: cexPair,
  });

  return (
    <div>
      {formatToken(volume, decimals)} {cexPair.token_base.symbol} / {formatFiat(volumeUsd, 18)}
    </div>
  );
};
