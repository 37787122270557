import { createSlice } from '@reduxjs/toolkit';
import { IAccountsResponse } from 'api/apiCexAccount/models/ICexAccount';

const pairsSliceInitialState: IAccountsResponse = {
  has_next: false,
  items: [],
};

const accountsSlice = createSlice({
  name: 'pairs',
  initialState: pairsSliceInitialState as any,
  reducers: {
    setAccountsList: (state, action) => {
      state.has_next = action?.payload.has_next;
      state.accountsList = action?.payload.items;
    },
  },
});

export const { setAccountsList } = accountsSlice.actions;

export default accountsSlice.reducer;
