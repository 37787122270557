import React from 'react';

import { IIconProps } from './types';

const CalendarIcon: React.FC<IIconProps> = ({ className, onClick }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <path
        d="M1 6.36328H15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.125 2.84766H1.875C1.39175 2.84766 1 3.24119 1 3.72663V16.0323C1 16.5178 1.39175 16.9113 1.875 16.9113H14.125C14.6082 16.9113 15 16.5178 15 16.0323V3.72663C15 3.24119 14.6082 2.84766 14.125 2.84766Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1565 10.408C11.4488 10.1145 11.4477 9.6396 11.1541 9.34737C10.8606 9.05515 10.3857 9.05622 10.0935 9.34978L11.1565 10.408ZM7.125 13.3948L6.59347 13.9239C6.73422 14.0653 6.9255 14.1448 7.125 14.1448C7.3245 14.1448 7.51578 14.0653 7.65653 13.9239L7.125 13.3948ZM5.90653 11.1077C5.6143 10.8142 5.13943 10.8131 4.84587 11.1053C4.55232 11.3976 4.55124 11.8724 4.84347 12.166L5.90653 11.1077ZM10.0935 9.34978L6.59347 12.8657L7.65653 13.9239L11.1565 10.408L10.0935 9.34978ZM7.65653 12.8657L5.90653 11.1077L4.84347 12.166L6.59347 13.9239L7.65653 12.8657Z"
        fill="currentColor"
      />
      <path
        d="M11.5 1.08984V2.8478"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 1.08984V2.8478"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CalendarIcon };
