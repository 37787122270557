import { useCallback } from 'react';

import { signMessage } from 'utils/signMessage';
import { ApiWithdrawWhitelist } from 'api';

interface IUseSignWithdrawWalletApproveProps {
  onSuccess: (id: number) => void;
  onError: (errorMessage: string) => void;
}

const useSignWithdrawWalletApprove = ({
  onError,
  onSuccess,
}: IUseSignWithdrawWalletApproveProps) => {
  const signMessageFunc = useCallback(
    async ({ id }: { id: number }) => {
      try {
        const {
          isSuccess: prepareIsSuccess,
          errorMessage: prepareErrorMessage,
          data: prepareData,
        } = await ApiWithdrawWhitelist.prepareConfirmation({
          id,
        });

        if (prepareIsSuccess && prepareData) {
          const { message } = prepareData;

          const signature = await signMessage(message);

          if (!signature) {
            onError('Something went wrong while signing message');
            return;
          }

          const { isSuccess, errorMessage } = await ApiWithdrawWhitelist.confirm({
            id,
            signature,
          });

          if (isSuccess) {
            onSuccess(id);
          } else if (errorMessage) {
            onError(errorMessage);
          }
        } else if (prepareErrorMessage) {
          onError(prepareErrorMessage);
        }
      } catch (error) {
        console.log('error: ', error);
        onError('Something went wrong');
      }
    },
    [onError, onSuccess]
  );

  return signMessageFunc;
};

export default useSignWithdrawWalletApprove;
