import { memo, FC, useState, useEffect, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';
import { capitalize, isNil } from 'lodash';

import { useTaskTime, useFeeCostsUiString } from 'hooks';
import { Button, Modal, ToggleRadio, ButtonLoading, ErrorText } from 'ui';
import { DateField, DurationField, InputField } from 'fields';
import { CreateTaskForm } from 'ui/forms';
import { formatFiat } from 'utils/formats';
import { multiplyBignumbers } from 'utils/formulas';
import { useCreateNewTaskModal } from './useCreateNewTaskModal/useCreateNewTaskModal';
import { mempoolConfigMap, MempoolConfigMap, revertedMempoolConfigMap } from 'constants/common';
import { IDexBuySellBotDirection } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { IAddPairArgs } from 'api/apiPairs/models';
import { IBuySellBotMode, IBuySellBotTaskItemInner } from 'types/buy-sell-bot';

import './createBuySellTaskModal.scss';

type Direction = 'Sell' | 'Buy';

type Props = {
  onClose: () => void;
  onOpen: () => void;
  mode: IBuySellBotMode;
  task?: IBuySellBotTaskItemInner;
};

export const CreateBuySellTaskModal: FC<Props> = memo(({ onClose, mode, task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const [direction, setDirection] = useState<Capitalize<IDexBuySellBotDirection>>('Sell');

  const {
    loading,
    values,
    handleSubmit,
    setFieldValue,
    formError,
    memPoolConfig,
    setMemPoolConfig,
    validateForm,
    edited,
    errors,
    touched,
  } = useCreateNewTaskModal({
    pair: dexPair as IAddPairArgs & IDexPair,
    direction,
    onClose,
    task,
    mode,
  });

  const memPoolDisabled = useMemo(() => memPoolConfig === 'Do not use', [memPoolConfig]);
  const isTaskCurrent = useMemo(() => task?.status === 'current', [task]);

  const feeCostsUiString = useFeeCostsUiString({
    totalTokenAmount: values.totalTokenAmount,
    minAmount: values.minAmount,
    maxAmount: values.maxAmount,
  });

  const handleChangeMempoolConfig = useCallback(
    (mpConfig: MempoolConfigMap) => {
      setMemPoolConfig(mpConfig);
      setTimeout(() => {
        validateForm();
      }, 0);
    },
    [setMemPoolConfig, validateForm],
  );

  useEffect(() => {
    if (task) {
      const mempoolConfigValue = revertedMempoolConfigMap[task.task.use_mempool];
      const directionValue = capitalize(task.task.direction) as 'Buy' | 'Sell';

      setMemPoolConfig(mempoolConfigValue);
      setDirection(directionValue);
    }
  }, [task, setMemPoolConfig]);

  const { finishTimeUiString } = useTaskTime({
    minAmount: values.minAmount,
    maxAmount: values.maxAmount,
    minDelay: values.minDelay ?? undefined,
    maxDelay: values.maxDelay ?? undefined,
    totalTokenAmount: values.totalTokenAmount,
  });

  const mempoolInputTitleClassName = useMemo(() => {
    if (!memPoolDisabled) {
      return 'text-main-purple font-bold mb-1.5 text-sm';
    } else {
      return 'text-gray-2 font-bold mb-1.5 text-sm';
    }
  }, [memPoolDisabled]);

  return (
    <Modal
      title={`${capitalize(mode)} task`}
      edited={edited}
      className="mm-create-buy-sell-bot-task-modal"
      onClose={onClose}
    >
      <CreateTaskForm handleSubmit={handleSubmit}>
        <div className="form-inner scrollable">
          <div className="mm-add-pair-modal">
            <ToggleRadio
              disabled={isTaskCurrent}
              label="Direction"
              titles={['Sell', 'Buy']}
              name="new-task-direction"
              value={direction}
              onChange={e => setDirection(e.target.value as Direction)}
            />
            <DateField
              date={values.startDate ? dayjs(values.startDate).valueOf() : null}
              label="Start time"
              setDate={value =>
                setFieldValue(
                  'startDate',
                  !isNil(value) ? dayjs(value).second(0).millisecond(0).valueOf() : null,
                )
              }
              readonly={isTaskCurrent}
              errorMessage={errors.startDate && touched.startDate ? errors.startDate : undefined}
            />
            <InputField
              type="decimal-number"
              value={values.totalTokenAmount}
              label={`Total ${dexPair.token_base.symbol} amount`}
              setValue={v => setFieldValue('totalTokenAmount', v ? v : '')}
              errorMessage={
                errors.totalTokenAmount && touched.totalTokenAmount
                  ? errors.totalTokenAmount
                  : undefined
              }
            />
            <InputField
              type="decimal-number"
              value={values.priceThreshold}
              label={`Don’t ${direction === 'Buy' ? 'buy' : 'sell'} if the price ${
                direction === 'Buy' ? 'higher' : 'lower'
              } than`}
              placeholder="1.15"
              setValue={v => setFieldValue('priceThreshold', v ? v : '')}
              errorMessage={
                errors.priceThreshold && touched.priceThreshold ? errors.priceThreshold : undefined
              }
              nodeRight={
                <span className="input-right-caption">
                  {formatFiat(
                    multiplyBignumbers(
                      [
                        values.priceThreshold !== ''
                          ? parseUnits(values.priceThreshold, dexPair.token_quote.decimals ?? 18)
                          : BigNumber.from('0'),
                        dexPair.token_quote.decimals ?? 18,
                      ],
                      [BigNumber.from(dexPair.token_quote.price_usd), 6],
                    ),
                    18,
                    '$',
                  )}
                </span>
              }
            />
            <div>
              <div className="text-main-purple font-bold mb-1.5 text-sm">
                One trade {dexPair.token_base.symbol} amount
              </div>
              <div className="inputs-row">
                <InputField
                  type="decimal-number"
                  value={values.minAmount}
                  placeholder="1.12"
                  label="Minimum"
                  setValue={v => setFieldValue('minAmount', v ? v : '')}
                  errorMessage={
                    errors.minAmount && touched.minAmount ? errors.minAmount : undefined
                  }
                />
                <InputField
                  type="decimal-number"
                  value={values.maxAmount}
                  placeholder="2.34"
                  label="Maximum"
                  setValue={v => setFieldValue('maxAmount', v ? v : '')}
                  errorMessage={
                    errors.maxAmount && touched.maxAmount ? errors.maxAmount : undefined
                  }
                />
              </div>
            </div>
            <AnimatePresence>
              {memPoolConfig !== 'Only' && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.6, ease: 'easeInOut' }}
                  key={'trades-pause-params'}
                  className="trades-pause-container"
                >
                  <div className="text-main-purple font-bold mb-1.5 text-sm">
                    Pause between trades
                  </div>
                  <div className="inputs-row">
                    <DurationField
                      value={values.minDelay}
                      setValue={v => {
                        const newValue = isNil(v) ? null : v;

                        setFieldValue('minDelay', newValue);
                      }}
                      label="Min delay"
                      id="min-delay"
                      zeroable
                      errorMessage={
                        errors.minDelay && touched.minDelay ? errors.minDelay : undefined
                      }
                    />
                    <DurationField
                      value={values.maxDelay}
                      setValue={v => {
                        const newValue = isNil(v) ? null : v;

                        setFieldValue('maxDelay', newValue);
                      }}
                      label="Max delay"
                      id="max-delay"
                      zeroable
                      errorMessage={
                        errors.maxDelay && touched.maxDelay ? errors.maxDelay : undefined
                      }
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <ToggleRadio
              label="Mempool"
              titles={Object.keys(mempoolConfigMap)}
              name="new-task-mempool"
              value={memPoolConfig}
              onChange={e => handleChangeMempoolConfig(e.target.value as MempoolConfigMap)}
            />
            <AnimatePresence>
              {!memPoolDisabled && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.6, easings: '' }}
                  key={'mempool-params'}
                  className="mempool-agruments"
                >
                  <div className="mempool-agruments-area">
                    <div className={mempoolInputTitleClassName}>Contr trade %</div>
                    <div className="inputs-row">
                      <InputField
                        type="decimal-number"
                        value={values.amountDisperseCoeffMin}
                        placeholder="30"
                        label="Minimum"
                        setValue={v => setFieldValue('amountDisperseCoeffMin', v ? v : '')}
                        disabled={memPoolDisabled}
                        errorMessage={
                          errors.amountDisperseCoeffMin && touched.amountDisperseCoeffMin
                            ? errors.amountDisperseCoeffMin
                            : undefined
                        }
                      />
                      <InputField
                        type="decimal-number"
                        value={values.amountDisperseCoeffMax}
                        placeholder="30"
                        label="Maximum"
                        setValue={v => setFieldValue('amountDisperseCoeffMax', v ? v : '')}
                        disabled={memPoolDisabled}
                        errorMessage={
                          errors.amountDisperseCoeffMax && touched.amountDisperseCoeffMax
                            ? errors.amountDisperseCoeffMax
                            : undefined
                        }
                      />
                    </div>
                  </div>
                  <div className="mempool-agruments-area">
                    <div className={mempoolInputTitleClassName}>Skip transactions</div>
                    <div className="inputs-row">
                      <InputField
                        type="natural-number"
                        value={values.skipTransactionsMin}
                        placeholder="30"
                        label="Minimum"
                        setValue={v => setFieldValue('skipTransactionsMin', v ? v : '')}
                        disabled={memPoolDisabled}
                        errorMessage={
                          errors.skipTransactionsMin && touched.skipTransactionsMin
                            ? errors.skipTransactionsMin
                            : undefined
                        }
                      />
                      <InputField
                        type="natural-number"
                        value={values.skipTransactionsMax}
                        placeholder="30"
                        label="Maximum"
                        setValue={v => setFieldValue('skipTransactionsMax', v ? v : '')}
                        disabled={memPoolDisabled}
                        errorMessage={
                          errors.skipTransactionsMax && touched.skipTransactionsMax
                            ? errors.skipTransactionsMax
                            : undefined
                        }
                      />
                    </div>
                  </div>
                  {memPoolConfig === 'Use additionaly' && (
                    <InputField
                      type="decimal-number"
                      value={values.advanceCoefficient}
                      placeholder="10"
                      label="Advanced mempool progress %"
                      setValue={v => setFieldValue('advanceCoefficient', v ? v : '')}
                      disabled={memPoolDisabled}
                      errorMessage={
                        errors.advanceCoefficient && touched.advanceCoefficient
                          ? errors.advanceCoefficient
                          : undefined
                      }
                    />
                  )}
                </motion.div>
              )}
            </AnimatePresence>
            <div className="grid grid-flow-row gap-2.5 text-main-purple font-normal">
              {finishTimeUiString && (
                <div className="grid grid-flow-col">
                  <span className="text-sm">Finish time:</span>
                  <span className="text-right text-sm">{finishTimeUiString}</span>
                </div>
              )}
              {feeCostsUiString && (
                <div className="grid grid-flow-col">
                  <span className="text-sm">Fee costs:</span>
                  <span className="text-right text-sm">{feeCostsUiString}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mm-new-buy-sell-bot-task-actions">
          {formError && <ErrorText>{formError}</ErrorText>}
          {loading ? (
            <ButtonLoading />
          ) : (
            <Button type="submit">{mode === 'create' ? 'Create task' : 'Save'}</Button>
          )}
        </div>
      </CreateTaskForm>
    </Modal>
  );
});
