import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { IProjectShort } from 'api/apiWallets/models/IWallet';

import { Tooltip } from '../../Tooltip/Tooltip';

import './style.scss';

interface ITableProjects {
  projects?: IProjectShort[] | undefined;
  align?: 'flex-start' | 'flex-end' | 'center';
  tooltip: 'projects' | 'pairs';
}

const TableProjects: React.FC<ITableProjects> = ({ projects, align = 'flex-start', tooltip }) => {
  const capturedProject = useMemo(() => {
    if (!projects) return '-';

    return projects[0].name;
  }, [projects]);

  const id = useMemo(() => {
    return uuid();
  }, []);

  const tooltipString = useMemo(() => {
    if (!projects || projects.length === 0) return '';

    if (tooltip === 'projects') {
      return `${projects.map(el => `<span>${el.name}<span>`).join(', ')}`;
    }

    if (tooltip === 'pairs') {
      return `${projects
        .map(el => (el.pairs ? el.pairs.map(el => `<span>${el.symbol}<span>`).join(', ') : ''))
        .join(', ')}`;
    }

    return '';
  }, [projects, tooltip]);

  if (!projects || projects.length === 0)
    return (
      <div
        style={{
          justifyContent: align,
          textAlign: align === 'flex-start' ? 'left' : align === 'flex-end' ? 'right' : 'center',
        }}
      >
        {'-'}
      </div>
    );

  return (
    <div className="mm-table-projects" style={{ justifyContent: align }}>
      <span className="caption">{capturedProject}</span>
      <Tooltip id={id} item={tooltipString} tooltipEvent="click">
        <span>{projects.length}</span>
      </Tooltip>
    </div>
  );
};

export { TableProjects };
