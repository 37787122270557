import React from 'react';

const CheckedIcon: React.FC = () => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.47004 0.790949C9.40807 0.728463 9.33434 0.678867 9.2531 0.645021C9.17186 0.611175 9.08472 0.59375 8.99671 0.59375C8.9087 0.59375 8.82157 0.611175 8.74033 0.645021C8.65909 0.678867 8.58535 0.728463 8.52338 0.790949L3.55671 5.76428L1.47004 3.67095C1.4057 3.60879 1.32974 3.55991 1.2465 3.52711C1.16326 3.49431 1.07438 3.47822 0.984926 3.47977C0.895472 3.48132 0.807199 3.50047 0.725147 3.53613C0.643095 3.57179 0.56887 3.62327 0.506711 3.68762C0.444552 3.75196 0.395676 3.82792 0.362874 3.91116C0.330071 3.9944 0.313984 4.08328 0.315532 4.17273C0.317079 4.26219 0.336231 4.35046 0.371893 4.43251C0.407556 4.51456 0.45903 4.58879 0.523378 4.65095L3.08338 7.21095C3.14535 7.27343 3.21909 7.32303 3.30033 7.35688C3.38157 7.39072 3.4687 7.40815 3.55671 7.40815C3.64472 7.40815 3.73186 7.39072 3.8131 7.35688C3.89434 7.32303 3.96807 7.27343 4.03005 7.21095L9.47004 1.77095C9.53771 1.70852 9.59172 1.63275 9.62866 1.54842C9.6656 1.46409 9.68467 1.37302 9.68467 1.28095C9.68467 1.18888 9.6656 1.09781 9.62866 1.01348C9.59172 0.929145 9.53771 0.853377 9.47004 0.790949Z"
        fill="white"
      />
    </svg>
  );
};

export { CheckedIcon };
