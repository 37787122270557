import { useCallback } from 'react';
import cn from 'classnames';

import { BaseSelectField } from '../base/BaseSelectField';

import './style.scss';

interface ISelectFieldProps<V> {
  items: V[];
  itemToString: (item: V) => string;
  selectedItem?: V;
  onSelectItem: (item: V | undefined) => void;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  comment?: string;
}

function SelectField<V>({
  items,
  itemToString,
  selectedItem,
  onSelectItem,
  label,
  placeholder,
  errorMessage,
  comment,
  disabled = false,
}: ISelectFieldProps<V>) {
  const itemRenderer = useCallback(
    (item: V) => {
      const itemString = itemToString(item);

      const isItemSelected = selectedItem && itemString === itemToString(selectedItem);

      return (
        <div className={cn('mm-select-field__option', { _selected: isItemSelected })}>
          {itemString}
        </div>
      );
    },
    [itemToString, selectedItem],
  );

  return (
    <BaseSelectField
      className="mm-select-field"
      items={items}
      onSelectItem={onSelectItem}
      selectedItem={selectedItem}
      selectedItemText={selectedItem ? itemToString(selectedItem) : undefined}
      renderItem={itemRenderer}
      disabled={disabled}
      label={label}
      placeholder={placeholder}
      errorMessage={errorMessage}
      comment={comment}
    />
  );
}

export { SelectField };
