import { number, object, string } from 'yup';
import { isNil } from 'lodash';

const isPositiveNumber = (v: string | number) => !isNaN(Number(v)) && Number(v) > 0;

export const validationSchema = () =>
  object({
    condition_type: string(),
    // conditional
    start_at: number()
      .nullable(true)
      .when('condition_type', {
        is: 'start_at',
        then: number()
          .nullable(true)
          .test('start_at', 'Field is required', (v: number | null | undefined) => !isNil(v)),
      }),
    duration: number().when('condition_type', {
      is: 'price_change',
      then: number().test(
        'duration',
        'Field is required',
        (v: number | undefined) => !isNil(v) && v > 0,
      ),
    }),
    threshold_percentage: string().when('condition_type', {
      is: 'price_change',
      then: string().test(
        'threshold_percentage',
        'Field is required',
        (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
      ),
    }),
    //common
    min_volume: string()
      .required('Field is reguired')
      .test(
        'min_volume',
        'Field is required',
        (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
      ),
    max_volume: string()
      .required('Field is reguired')
      .test(
        'max_volume',
        'Field is required',
        (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
      ),
    min_serie_duration: number().required('Field is reguired'),
    max_serie_duration: number().required('Field is reguired'),
    min_pause_between_series: number().required('Field is reguired'),
    max_pause_between_series: number().required('Field is reguired'),
    min_pause_between_groups: number().required('Field is reguired'),
    max_pause_between_groups: number().required('Field is reguired'),
    min_transactions_in_group: string()
      .required('Field is reguired')
      .test(
        'min_transactions_in_group',
        'Field is required',
        (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
      ),
    max_transactions_in_group: string()
      .required('Field is reguired')
      .test(
        'max_transactions_in_group',
        'Field is required',
        (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
      ),
    slippage_percent: string()
      .required('Field is reguired')
      .test(
        'slippage_percent',
        'Field is required',
        (v: string | undefined) => !isNil(v) && isPositiveNumber(v),
      ),
    repeatability: string(),
  });
