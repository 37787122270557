import React, { useCallback } from 'react';
import cn from 'classnames';

import { ENetwork } from 'types';
import { networksMapping } from 'constants/logos';
import { BaseSelectField } from '../base/BaseSelectField';

import './style.scss';

interface INetwork {
  label: string;
  network: ENetwork;
}

interface ISelectNetworkFieldProps {
  label?: string;
  placeholder?: string;
  items: INetwork[];
  selectedItem?: INetwork;
  onSelectItem: (v: INetwork | undefined) => void;
  disabled?: boolean;
  errorMessage?: string;
  comment?: string;
}

const SelectNetworkField: React.FC<ISelectNetworkFieldProps> = ({
  label,
  placeholder,
  items,
  selectedItem,
  onSelectItem,
  errorMessage,
  comment,
  disabled = false,
}) => {
  const renderItem = useCallback(
    (item: INetwork) => {
      const isItemSelected = selectedItem && item.network === selectedItem.network;

      const icon = networksMapping({ name: item.network });

      return (
        <div className={cn('mm-select-network-field-item', { _selected: isItemSelected })}>
          <img src={icon.src} alt={icon.alt} className="network-image" />
          <span>{item.label}</span>
        </div>
      );
    },
    [selectedItem],
  );

  return (
    <BaseSelectField
      label={label}
      placeholder={placeholder}
      items={items}
      disabled={disabled}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
      renderItem={renderItem}
      renderSelectedItem={renderItem}
      errorMessage={errorMessage}
      comment={comment}
    />
  );
};

export { SelectNetworkField };
