import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import './backdrop.scss';

interface IBackdrop {
  children: React.ReactNode;
  onClickBackdrop?: () => void;
}

const Backdrop: React.FC<IBackdrop> = ({ children, onClickBackdrop }) => {
  const backdropRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState<string>('0px');

  const observer = useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      const { width } = entries[0].contentRect;

      setMaxWidth(`${width - 40}px`);
    })
  );

  const handleClickBackdrop = useCallback(() => {
    if (onClickBackdrop) {
      onClickBackdrop();
    }
  }, [onClickBackdrop]);

  useEffect(() => {
    if (backdropRef.current) {
      observer.current.observe(backdropRef.current);
    }

    return () => {
      if (backdropRef.current) {
        observer.current.unobserve(backdropRef.current);
      }
    };
  }, [backdropRef, observer]);

  return (
    <>
      <div className={cn('mm-general-modal-backdrop-holder')} ref={backdropRef}>
        <motion.div
          key="general-modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn('mm-general-modal-backdrop', { _clickable: onClickBackdrop })}
          onClick={handleClickBackdrop}
        />
      </div>
      <div className="mm-general-modal-backdrop-children-holder">
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          className={'mm-general-modal-backdrop__children-wrapper'}
          style={{ maxWidth }}
        >
          {children}
        </motion.div>
      </div>
      <div className="mm-general-modal-backdrop__sidebar-cover" onClick={handleClickBackdrop} />
    </>
  );
};

export { Backdrop };
