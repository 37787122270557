import React, { useState } from 'react';

import { Panel, AutoRefresh } from 'ui';
import { Content } from './components';
import ProjectStatisticContextProvider from 'context/ProjectStatisticContext';
import { PROJECT_STATISTIC_RELOAD } from 'constants/reload';

import { ListIcon } from 'assets/icons';

import './style.scss';

const Statistic: React.FC = () => {
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(true);

  return (
    <Panel
      title="Statistic"
      icon={ListIcon}
      initialOpened
      onChange={setIsPanelOpened}
      headerNode={
        !!isPanelOpened && (
          <div className="mm-project-statistic-panel-auto-refresh">
            <AutoRefresh busEvent={PROJECT_STATISTIC_RELOAD} tooltipText="Autorefresh" />
          </div>
        )
      }
    >
      <ProjectStatisticContextProvider>
        <Content />
      </ProjectStatisticContextProvider>
    </Panel>
  );
};

export { Statistic };
