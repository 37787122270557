import MaskedInput from 'react-text-mask';

import { IMaskProps } from './InputField';

interface IDefaultInputProps<V> {
  className?: string;
  innerRef?: React.Ref<HTMLInputElement>;
  value?: V;
  id: string;
  type: 'text' | 'number' | 'password';
  inputMode: 'decimal' | 'numeric' | undefined;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  name?: string | undefined;
  tabindex?: number | undefined;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInput: (event: React.FormEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

interface MaskedDefaultInputProps<V extends string> extends IDefaultInputProps<V>, IMaskProps {}

interface InputAdapterProps<V extends string> extends IDefaultInputProps<V>, IMaskProps {}

function DefaultInput<V extends string>({
  className,
  id,
  value,
  type,
  inputMode,
  placeholder,
  readOnly,
  disabled,
  tabindex,
  name,
  onChange,
  onClick,
  onInput,
  onFocus,
  onBlur,
  innerRef,
}: IDefaultInputProps<V>) {
  return (
    <input
      ref={innerRef}
      className={className}
      name={name}
      tabIndex={disabled || readOnly ? -1 : tabindex}
      value={value}
      id={id}
      type={type}
      inputMode={inputMode}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      onInput={onInput}
      onChange={onChange}
      onClick={onClick}
      onWheel={event => {
        event.currentTarget.blur();
      }}
    />
  );
}

function MaskedDefaultInput<V extends string>({
  mask,
  maskPlaceholderChar = undefined,
  maskGuide = true,
  maskKeepCharPositions = true,
  ...props
}: MaskedDefaultInputProps<V>) {
  if (!mask) return null;

  return (
    <MaskedInput
      mask={mask}
      placeholderChar={maskPlaceholderChar}
      guide={maskGuide}
      keepCharPositions={maskKeepCharPositions}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      render={(ref, inputProps) => {
        const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
          if (inputProps.onBlur) {
            inputProps.onBlur(event);
          }

          if (props.onBlur) {
            props.onBlur(event);
          }
        };

        return (
          <DefaultInput
            innerRef={(input: HTMLInputElement) => input && ref(input)}
            {...props}
            value={undefined}
            {...inputProps}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
}

function Input<V extends string>(props: InputAdapterProps<V>) {
  if (props.mask) {
    return <MaskedDefaultInput {...props} />;
  }

  return <DefaultInput {...props} />;
}

export { Input };
