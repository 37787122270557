import React from 'react';

import './panelNoInfo.scss';

interface IPanelNoInfoProps {
  title: string;
  text: string;
}

const PanelNoInfo: React.FC<IPanelNoInfoProps> = ({ title, text }) => {
  return (
    <div className="mm-panel-no-info">
      <div className="mm-panel-no-info__title">
        <h3>{title}</h3>
      </div>
      <span className="mm-panel-no-info__text">{text}</span>
    </div>
  );
};

export { PanelNoInfo };
