import React from 'react';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

import { Button, Modal, ButtonLoading } from 'ui';
import { SelectField, DateField, InputField } from 'fields';

import { useAddAccountModal } from './useCreatePairModal/useAddAccountModal';
import { IInitialModalAccount } from './useCreatePairModal/models/IForm';

import './createAccountModal.scss';

interface IAddPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
  modalAccount: IInitialModalAccount;
}

const CreateNewAccountModal: React.FC<IAddPairModalProps> = ({
  onClose,
  onOpen,
  onSuccess,
  modalAccount,
}) => {
  const { form, setFormField, handleAddAccount, formError, loading, edited, cexs } =
    useAddAccountModal({
      onClose,
      onOpen,
      onSuccess,
      modalAccount,
    });

  const isEditMode = modalAccount.mode === 'edit';
  const titleAccountModal = isEditMode ? 'Edit account' : 'Connect new account';

  return (
    <Modal
      title={titleAccountModal}
      edited={edited}
      onClose={onClose}
      customButtons={
        <>
          {loading && <ButtonLoading />}
          {!loading && (
            <Button onClick={handleAddAccount}>
              {modalAccount.mode === 'new' ? 'Create' : 'Update'}
            </Button>
          )}
        </>
      }
    >
      <div className="mm-add-account-modal">
        <SelectField
          items={cexs!}
          label="Exchange"
          placeholder="Choose exchange"
          itemToString={item => item.description}
          selectedItem={form.cex}
          onSelectItem={cex => {
            setFormField('cex', cex, { triggerEdit: true });
          }}
          disabled={loading || isEditMode}
        />
        <DateField
          date={isNil(form?.expire_at) ? null : form.expire_at}
          label="Expire at"
          setDate={value =>
            setFormField(
              'expire_at',
              value ? dayjs(value).second(0).millisecond(0).valueOf() : null,
              {
                triggerEdit: true,
              },
            )
          }
        />
        <InputField
          type="text"
          label="Api Key"
          value={form.api_key ?? ''}
          setValue={apiKey => setFormField('api_key', apiKey, { triggerEdit: true })}
        />
        {form?.cex?.cex === 'kucoin' && (
          <InputField
            type="text"
            label="Passphrase"
            value={form.passphrase ?? ''}
            setValue={passphrase => setFormField('passphrase', passphrase, { triggerEdit: true })}
          />
        )}
        <InputField
          type="text"
          label="Secret"
          value={form.secret ?? ''}
          setValue={secret => setFormField('secret', secret, { triggerEdit: true })}
        />
        <InputField
          type="text"
          label="Notes"
          value={form.notes ?? ''}
          setValue={newNotes => setFormField('notes', newNotes, { triggerEdit: true })}
        />
        <InputField
          type="text"
          label="Proxy"
          value={form.proxy ?? ''}
          setValue={newProxy => setFormField('proxy', newProxy, { triggerEdit: true })}
        />
        {formError && <span className="mm-add-account-modal__error">{formError}</span>}
      </div>
    </Modal>
  );
};

export { CreateNewAccountModal };
