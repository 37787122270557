import { ETransactionAction } from 'api/apiTransactions/models';
import { normalizeBigNumber } from 'utils/formulas';
import { trimDecimalZeroes } from 'utils/formats';
import { IDexBalanceBotTask } from 'types/bots';
import { IDexPair } from 'types/pairs';

interface IAddHolderProgress {
  original: IDexBalanceBotTask;
  pair: IDexPair | null;
  baseToken: string | undefined;
}

export const Progress: React.FC<IAddHolderProgress> = ({ original, pair, baseToken }) => {
  const { statistic, buy_eth_holder_swaps, destination_wallets_count } = original;

  const token = statistic?.tokens?.find(item => {
    return item?.token?.symbol === baseToken;
  });

  const decimals = token?.token?.decimals || 18;

  const buyEthHolderAmount =
    token?.volumes?.find(volume => volume.action === ETransactionAction.ActionBuyEthHolder)
      ?.amount || '0';

  const progressPercentage = trimDecimalZeroes(
    ((buy_eth_holder_swaps / destination_wallets_count) * 100 || 0).toFixed(2),
  );

  return (
    <div>
      <>
        {normalizeBigNumber(buyEthHolderAmount, decimals, 0, false)} &#47; {progressPercentage}{' '}
        &#37;
      </>
    </div>
  );
};
