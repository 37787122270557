import { parseUnits } from '@ethersproject/units';
import { AppDispatch } from 'store';

import { ApiBalanceBot } from 'api';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { multiplyBignumbers } from 'utils/formulas';
import { msToDuration } from 'utils/duration';

import { IDexBalanceBotTask } from 'types/bots';
import { IPairBalanceBotContext } from 'context/PairBalanceBotContext';

interface IAddBalanceTaskProps {
  enabled?: boolean;
  dispatch: AppDispatch;
  handleLoadRecords: IPairBalanceBotContext['handleLoadRecords'];
  pairId: number | undefined;
  mode: 'edit' | 'create';
  taskId?: number;
  onClose?: (newCreatedBalanceTask?: IDexBalanceBotTask | undefined) => void;
  typeTask: string;
  min_amount?: string;
  max_amount?: string;
  min_pause?: number | undefined;
  max_pause?: number | undefined;
  buyback_enabled?: boolean;
  buyback_min_pause?: number | undefined;
  buyback_max_pause?: number | undefined;
  buyback_min_swaps?: string;
  buyback_max_swaps?: string;
  source_wallet_ids?: number[] | null;
  interval?: number;
  min_deposit_fee_factor?: number;
  max_deposit_fee_factor?: number;
  min_fee_threshold_factor?: number;
}

export const addBalanceTask = async ({
  task: {
    min_amount,
    max_amount,
    min_pause,
    max_pause,
    enabled,
    buyback_enabled,
    buyback_min_pause,
    buyback_max_pause,
    buyback_min_swaps,
    buyback_max_swaps,
    dispatch,
    handleLoadRecords,
    pairId,
    mode,
    taskId,
    onClose,
    typeTask,
    source_wallet_ids,
    interval,
    min_deposit_fee_factor,
    max_deposit_fee_factor,
    min_fee_threshold_factor,
  },
  onSuccess,
}: {
  task: IAddBalanceTaskProps;
  onSuccess?: () => void;
}) => {
  if (pairId) {
    const body =
      typeTask === 'maintain_fee_balance'
        ? {
            pair_id: pairId,
            ...(mode === 'edit' ? { id: taskId } : {}),
            type: typeTask,
            maintain_fee_balance_options: {
              enabled,
              interval: msToDuration(interval),
              min_deposit_fee_factor: +min_deposit_fee_factor! || 0,
              max_deposit_fee_factor: +max_deposit_fee_factor! || 0,
              min_fee_threshold_factor: +min_fee_threshold_factor! || 0,
              source_wallet_ids,
            },
          }
        : {
            pair_id: pairId,
            type: typeTask,
            ...(mode === 'edit' ? { id: taskId } : {}),
            add_holder_options: {
              buyback_enabled: buyback_enabled,
              enabled,
              ...(buyback_enabled
                ? {
                    buyback_options: {
                      interval: '10s',
                      min_pause: `${buyback_min_pause}ms`,
                      max_pause: `${buyback_max_pause}ms`,
                      min_swaps: buyback_min_swaps ? +buyback_min_swaps : 0,
                      max_swaps: buyback_max_swaps ? +buyback_max_swaps : 0,
                    },
                  }
                : {}),
              min_amount: String(
                multiplyBignumbers([parseUnits(min_amount || '0'), 18], [parseUnits('1'), 18]),
              ),
              max_amount: String(
                multiplyBignumbers([parseUnits(max_amount || '0'), 18], [parseUnits('1'), 18]),
              ),
              min_pause: `${min_pause}ms`,
              max_pause: `${max_pause}ms`,
              source_wallet_ids,
            },
          };

    let isSuccess, errorMessage;
    let newCreatedBalanceTask = undefined;

    if (mode === 'create') {
      const {
        isSuccess: successNewBalanceTask,
        errorMessage: newCreatedBalanceTaskError,
        ...rest
      } = await ApiBalanceBot.addPairBalanceBotTasks({
        body,
      });
      isSuccess = successNewBalanceTask;
      errorMessage = newCreatedBalanceTaskError;

      if (successNewBalanceTask) {
        const { errorMessage, data: dataTaskById } = await ApiBalanceBot.getPairBalanceBotTasksById(
          { task_id: rest?.data?.id ?? 0 },
        );
        !errorMessage && (newCreatedBalanceTask = dataTaskById);
      }
    } else {
      if (!taskId) {
        return;
      }
      const { isSuccess: successUpdatedBalanceTask, errorMessage: updatedBalanceTaskError } =
        await ApiBalanceBot.updatePairBalanceBotTasks({
          taskId,
          body,
        });
      isSuccess = successUpdatedBalanceTask;
      errorMessage = updatedBalanceTaskError;
    }

    if (!isSuccess) {
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: errorMessage || 'Something went wrong',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
      return;
    }
    if (isSuccess) {
      if (onSuccess) {
        onSuccess();
      }

      onClose?.(Object.keys(newCreatedBalanceTask).length ? newCreatedBalanceTask : undefined);

      if ((!newCreatedBalanceTask || !Object.keys(newCreatedBalanceTask).length) && !onSuccess) {
        handleLoadRecords();
        dispatch(
          setAlertState({
            type: 'success',
            text:
              mode === 'create'
                ? 'You successfully create new task!'
                : 'You successfully edit task!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    }
  }
};
