import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useTypedSelector } from 'store';
import { formatToken } from 'utils/formats';

export const TokensAmount: React.FC<{ totalAmount: string }> = ({ totalAmount }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const tokensAmount = useMemo(
    () => (pair ? formatToken(BigNumber.from(totalAmount), pair.token_base.decimals) : ''),
    [totalAmount, pair],
  );

  return <>{tokensAmount}</>;
};
