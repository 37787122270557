import { useMemo, useEffect, useCallback, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { useTypedDispatch, useTypedSelector } from 'store';

import { FG_TIMEFRAMES } from 'types/wash-trading-bot';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { trimDecimalZeroes } from 'utils/formats';
import { durationToMs } from 'utils/duration';
import { ECexBot } from 'types/bots';

import useLoadFillGapsSettings from './useLoadFillGapsSettings';
import { ApiBot } from 'api';

interface IFillTheGapsForm {
  time_frame: { label: string; value: string };
  min_amount: string;
  max_amount: string;
  trade_min_amounts: boolean;

  cancel_delay_min: string;
  cancel_delay_max: string;
  close_delay_max: string;
  close_delay_min: string;
  open_delay_max: string;
  open_delay_min: string;
}

export const useCreateFillTheGaps = () => {
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const decimals = useMemo(() => 18, []);

  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[cexPair.id],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[ECexBot.fill_the_gaps] : false),
    [pairConnectedBots],
  );

  const initialValues = useMemo(
    () => ({
      time_frame: FG_TIMEFRAMES[0],
      min_amount: '',
      max_amount: '',
      trade_min_amounts: false,

      cancel_delay_max: '1s',
      cancel_delay_min: '0s',
      close_delay_max: '2s',
      close_delay_min: '1s',
      open_delay_max: '1s',
      open_delay_min: '0s',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        time_frame: Yup.object().required('Time frame is required field'),
        min_amount: Yup.string().when('trade_min_amounts', {
          is: false,
          then: Yup.string().required('Fill out this field').min(1, 'Fill out this field'),
        }),
        max_amount: Yup.string().when('trade_min_amounts', {
          is: false,
          then: Yup.string().required('Fill out this field').min(1, 'Fill out this field'),
        }),
      }),
    [],
  );

  const [initialLoading, setInitialLoading] = useState<boolean>(false);

  const loadBotSettings = useLoadFillGapsSettings();

  const handleAddTask = async ({
    cancel_delay_min,
    cancel_delay_max,
    close_delay_max,
    close_delay_min,
    max_amount,
    min_amount,
    open_delay_max,
    open_delay_min,
    time_frame,
    trade_min_amounts,
  }: IFillTheGapsForm) => {
    const newFillGap: any = {
      cancel_delay_min,
      cancel_delay_max,
      close_delay_max,
      close_delay_min,
      open_delay_max,
      open_delay_min,

      min_amount: trade_min_amounts ? null : parseUnits(min_amount, decimals).toString(),
      max_amount: trade_min_amounts ? null : parseUnits(max_amount, decimals).toString(),
      time_frame: time_frame.value,
    };

    try {
      const { isSuccess, errorMessage } = await ApiBot.saveCexBotConfig({
        pairId: cexPair.id,
        bot: ECexBot.fill_the_gaps,
        body: {
          is_enabled: isBotEnabled,
          fill_gaps_options: newFillGap,
        },
      });
      if (isSuccess) {
        dispatch(
          setAlertState({
            type: 'success-img',
            text: 'You have successfully updated "Fill the gaps" config',
            onClose: () => {
              dispatch(dropAlertState());
            },
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } else
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
    } catch (errorMessage) {
      console.log('errorMessage ', errorMessage);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    validateForm,
    setFieldTouched,
  }: FormikProps<IFillTheGapsForm> = useFormik<IFillTheGapsForm>({
    initialValues,
    onSubmit: handleAddTask,
    validationSchema,
  });

  const handleLoadFillTheGaps = useCallback(async () => {
    try {
      setInitialLoading(true);

      const fillTheGaps = await loadBotSettings();

      if (fillTheGaps && fillTheGaps.fill_gaps_options) {
        const botOptions = fillTheGaps.fill_gaps_options;

        const currentTimeFrame = FG_TIMEFRAMES.find(
          timeFrame => durationToMs(timeFrame.value) === durationToMs(botOptions.time_frame),
        );

        const formKeys = Object.keys(initialValues) as Array<keyof IFillTheGapsForm>;

        if (!botOptions.min_amount && !botOptions.max_amount) {
          setFieldValue('trade_min_amounts', true);
        }

        formKeys.forEach(item => {
          //@ts-ignore
          const fillTheGapsOptions = botOptions[item];

          if (item === 'time_frame') {
            setFieldValue(item, currentTimeFrame);
          } else if (item === 'min_amount' || (item === 'max_amount' && fillTheGapsOptions)) {
            const value = trimDecimalZeroes(formatUnits(fillTheGapsOptions ?? '0', decimals));

            setFieldValue(item, value);
          } else if (fillTheGapsOptions) {
            setFieldValue(item, fillTheGapsOptions ?? '');
          }
        });
      }
    } catch (error) {
      console.log('Fill The Gaps Error Message', error);
    } finally {
      setInitialLoading(false);
    }
  }, [initialValues, setFieldValue, loadBotSettings, decimals]);

  useEffect(() => {
    handleLoadFillTheGaps();
  }, [handleLoadFillTheGaps]);

  return {
    initialValues,
    values,
    handleSubmit,
    setFieldValue,
    validateForm,
    touched,
    setFieldTouched,
    errors,
    initialLoading,
  };
};
