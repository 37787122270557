import React from 'react';

const FailedIcon: React.FC = () => {
  return (
    <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51 101C78.6142 101 101 78.6142 101 51C101 23.3858 78.6142 1 51 1C23.3858 1 1 23.3858 1 51C1 78.6142 23.3858 101 51 101Z"
        fill="#CE4966"
        stroke="#CE4966"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.7236 70.1641H51.2792V70.7196H50.7236V70.1641Z"
        stroke="white"
        strokeWidth="11"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M51 31.2734V53.4957" stroke="white" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export { FailedIcon };
