import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { ICex, IDex } from './models';

class ApiDictionary extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiDictionary | undefined;
  private static instance = (() => {
    if (ApiDictionary.__instance === undefined) {
      ApiDictionary.__instance = new ApiDictionary();
    }
    return ApiDictionary.__instance;
  })();

  public static getDexs = async (): Promise<Response<{ items: IDex[] }>> => {
    let mappedResponseOrError: Response<{ items: IDex[] }>;

    try {
      const response = await ApiDictionary.instance.get<IBaseResponse<{ items: IDex[] }>>('/dexs');

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ items: IDex[] }>({ error });
    }

    return mappedResponseOrError;
  };

  public static getCexs = async (): Promise<Response<{ items: ICex[] }>> => {
    let mappedResponseOrError: Response<{ items: ICex[] }>;

    try {
      const response = await ApiDictionary.instance.get<IBaseResponse<{ items: ICex[] }>>('/cexs');

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ items: ICex[] }>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiDictionary };
