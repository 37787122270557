import { FC, memo, ReactNode } from 'react';

type Props = {
  handleSubmit: () => void;
  children: ReactNode;
};

const EditBuySellBotSettingsFormComponent: FC<Props> = ({ handleSubmit, children }) => (
  <form className="mm-edit-buysell-bot-settings-form" onSubmit={handleSubmit}>
    {children}
  </form>
);

const EditBuySellBotSettingsForm = memo(EditBuySellBotSettingsFormComponent);

export { EditBuySellBotSettingsForm };
