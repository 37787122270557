import React, { useContext } from 'react';

import { PairBuySellBotContext } from 'context/PairBuySellBotContext';
import { PanelLoading, PanelError, PanelNoInfo } from 'ui/index';

import './style.scss';

interface ITableWrapperProps {
  children?: React.ReactNode;
}

const TableWrapper: React.FC<ITableWrapperProps> = ({ children }) => {
  const { loading, errorMessage, records } = useContext(PairBuySellBotContext);

  if (loading.get) return <PanelLoading />;

  if (!loading.get && errorMessage) return <PanelError title={'Failed'} text={errorMessage} />;

  if (records.get && records.get.length === 0) {
    return (
      <PanelNoInfo
        title={"You don't have buy sell bot records yet"}
        text={'Start with adding new task to pair!'}
      />
    );
  }

  return (
    <div className="mm-buy-sell-bot-table-container">
      <div className="table-container scrollable">{children}</div>
    </div>
  );
};

export { TableWrapper };
