interface ISepataredDuration {
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

interface IDuration {
  years: string | undefined;
  months: string | undefined;
  weeks: string | undefined;
  days: string | undefined;
  hours: string | undefined;
  minutes: string | undefined;
  seconds: string | undefined;
  milliseconds: string | undefined;
}

export const parseDuration = (unparsedString: string): ISepataredDuration => {
  const years = unparsedString.match(/(\d+)y|yr|year|years/);
  const months = unparsedString.match(/(\d+)mo|month|months/);
  const weeks = unparsedString.match(/(\d+)w|wk|week|weeks/);
  const days = unparsedString.match(/(\d+)d|day|days/);
  const hours = unparsedString.match(/(\d+)h|hr|hour|hours/);
  const minutes = unparsedString.match(/(\d+)min|minute|minutes/);
  const seconds = unparsedString.match(/(\d+)s|second|seconds/);
  const milliseconds = unparsedString.match(/(\d+)ms|milli|milliseconds/);

  return {
    years: years ? parseInt(years[1]) : 0,
    months: months ? parseInt(months[1]) : 0,
    weeks: weeks ? parseInt(weeks[1]) : 0,
    days: days ? parseInt(days[1]) : 0,
    hours: hours ? parseInt(hours[1]) : 0,
    minutes: minutes ? parseInt(minutes[1]) : 0,
    seconds: seconds ? parseInt(seconds[1]) : 0,
    milliseconds: milliseconds ? parseInt(milliseconds[1]) : 0,
  };
};

const parseDurationString = ({
  years,
  months,
  weeks,
  days,
  hours,
  minutes,
  seconds,
  milliseconds,
}: ISepataredDuration): string => {
  return [
    ...(years ? [`${years}yr`] : []),
    ...(months ? [`${months}mo`] : []),
    ...(weeks ? [`${weeks}wk`] : []),
    ...(days ? [`${days}d`] : []),
    ...(hours ? [`${hours}hr`] : []),
    ...(minutes ? [`${minutes}min`] : []),
    ...(seconds ? [`${seconds}s`] : []),
    ...(milliseconds ? [`${milliseconds}ms`] : []),
  ].join(' ');
};

export const parseDurationShortString = (
  { years, months, weeks, days, hours, minutes, seconds, milliseconds }: ISepataredDuration,
  separator?: string,
) => {
  return [
    ...(years ? [`${years}yr`] : []),
    ...(months ? [`${months}mo`] : []),
    ...(weeks ? [`${weeks}wk`] : []),
    ...(days ? [`${days}d`] : []),
    ...(hours ? [`${hours}hr`] : []),
    ...(minutes ? [`${minutes}min`] : []),
    ...(seconds ? [`${seconds}s`] : []),
    ...(milliseconds ? [`${milliseconds}ms`] : []),
  ].join(separator || ' ');
};

export const parseMilliseconds = (milliseconds: number | string): string => {
  const _milliseconds = Number(milliseconds);

  if (_milliseconds < 1) {
    return '0';
  }

  const oneSecond = 1000;
  const oneMinute = 60 * oneSecond;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;
  const oneWeek = oneDay * 7;
  const oneMonth = oneDay * 30;
  const oneYear = oneDay * 365;

  const years = Math.floor(_milliseconds / oneYear);
  const months = Math.floor((_milliseconds - oneYear * years) / oneMonth);
  const weeks = Math.floor((_milliseconds - oneYear * years - oneMonth * months) / oneWeek);
  const days = Math.floor(
    (_milliseconds - oneYear * years - oneMonth * months - oneWeek * weeks) / oneDay,
  );
  const hours = Math.floor(
    (_milliseconds - oneYear * years - oneMonth * months - oneWeek * weeks - oneDay * days) /
      oneHour,
  );
  const minutes = Math.floor(
    (_milliseconds -
      oneYear * years -
      oneMonth * months -
      oneWeek * weeks -
      oneDay * days -
      oneHour * hours) /
      oneMinute,
  );
  const sec = Math.floor(
    (_milliseconds -
      oneYear * years -
      oneMonth * months -
      oneWeek * weeks -
      oneDay * days -
      oneHour * hours -
      oneMinute * minutes) /
      1000,
  );
  const ms = Math.floor(
    _milliseconds -
      oneYear * years -
      oneMonth * months -
      oneWeek * weeks -
      oneDay * days -
      oneHour * hours -
      oneMinute * minutes -
      oneSecond * sec,
  );

  const times = {
    year: years,
    month: months,
    week: weeks,
    day: days,
    hour: hours,
    minute: minutes,
    second: sec,
    ms: ms,
  };

  const qualifier = (num: number) => (num > 1 ? 's' : '');
  const numToStr = (num: number, unit: string) => (num > 0 ? `${num}${unit}${qualifier(num)}` : '');

  const result = Object.entries(times).reduce((acc, [unit, num]) => acc + numToStr(num, unit), '');

  return result;
};

const generateCombinations = ({
  years,
  weeks,
  days,
  hours,
  seconds,
  minutes,
  months,
  milliseconds,
  possibleMonths,
  possibleMinutes,
  possibleMilliseconds,
  possibleNumber,
}: {
  years: string[];
  weeks: string[];
  days: string[];
  hours: string[];
  seconds: string[];
  minutes: string[];
  months: string[];
  milliseconds: string[];
  possibleMonths: string[];
  possibleMinutes: string[];
  possibleMilliseconds: string[];
  possibleNumber: string | undefined;
}): { stringValue: string; msValue: number }[] => {
  const _minutes = minutes.length > 0 ? minutes : [undefined];
  const _months = months.length > 0 ? months : [undefined];
  const _years = years.length > 0 ? years : [undefined];
  const _weeks = weeks.length > 0 ? weeks : [undefined];
  const _days = days.length > 0 ? days : [undefined];
  const _hours = hours.length > 0 ? hours : [undefined];
  const _seconds = seconds.length > 0 ? seconds : [undefined];
  const _milliseconds = milliseconds.length > 0 ? milliseconds : [undefined];

  let combinations: IDuration[] = [];

  for (let y = 0; y < _years.length; y++) {
    for (let mon = 0; mon < _months.length; mon++) {
      for (let w = 0; w < _weeks.length; w++) {
        for (let d = 0; d < _days.length; d++) {
          for (let h = 0; h < _hours.length; h++) {
            for (let min = 0; min < _minutes.length; min++) {
              for (let s = 0; s < _seconds.length; s++) {
                for (let ms = 0; ms < _milliseconds.length; ms++) {
                  combinations.push({
                    years: _years[y] ?? undefined,
                    months: _months[mon] ?? undefined,
                    weeks: _weeks[w] ?? undefined,
                    days: _days[d] ?? undefined,
                    hours: _hours[h] ?? undefined,
                    minutes: _minutes[min] ?? undefined,
                    seconds: _seconds[s] ?? undefined,
                    milliseconds: _milliseconds[ms] ?? undefined,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  // calculate possible periods depend on possibleMilliseconds | possibleMinutes | possibleMonth
  if (possibleMilliseconds.length > 0 || possibleMinutes.length > 0 || possibleMonths.length > 0) {
    const newCombinations: IDuration[] = [];

    if (possibleMilliseconds.length > 0) {
      for (const combination of combinations) {
        if (!combination.milliseconds) {
          newCombinations.push({ ...combination, milliseconds: possibleMilliseconds[0] });
        }
      }
    }

    if (possibleMinutes.length > 0) {
      for (const combination of combinations) {
        if (!combination.minutes) {
          newCombinations.push({ ...combination, minutes: possibleMinutes[0] });
        }
      }
    }

    if (possibleMonths.length > 0) {
      for (const combination of combinations) {
        if (!combination.minutes) {
          newCombinations.push({ ...combination, months: possibleMonths[0] });
        }
      }
    }

    combinations = newCombinations;
  }

  // calculate possible periods depend on possibleNumber
  if (possibleNumber !== undefined) {
    const newCombinations: IDuration[] = [];

    let possibleCount = 0;

    for (const combination of combinations) {
      const priorityPossiblePeriods = [
        'seconds',
        'milliseconds',
        'minutes',
        'hours',
        'days',
        'weeks',
        'months',
        'years',
      ];

      if (Number(possibleNumber) === 0) {
        newCombinations.push({
          ...combination,
          ['seconds']: '0',
        });
      } else {
        for (const possiblePeriod of priorityPossiblePeriods) {
          if (!combination[possiblePeriod as keyof IDuration]) {
            possibleCount++;

            newCombinations.push({
              ...combination,
              [possiblePeriod]: possibleNumber,
            });
          }

          if (possibleCount === 3) break;
        }
      }
    }

    combinations = newCombinations;
  }

  return combinations.slice(0, 3).map(el => {
    const _seconds = Number(el.seconds ?? '0');
    const _minutes = Number(el.minutes ?? '0');
    const _hours = Number(el.hours ?? '0');
    const _days = Number(el.days ?? '0');
    const _weeks = Number(el.weeks ?? '0');
    const _months = Number(el.months ?? '0');
    const _year = Number(el.years ?? '0');
    const _milliseconds = Number(el.milliseconds ?? '0');

    const _msValue =
      _milliseconds +
      _seconds * 1000 +
      _minutes * 60 * 1000 +
      _hours * 60 * 60 * 1000 +
      _days * 60 * 60 * 24 * 1000 +
      _weeks * 60 * 60 * 24 * 7 * 1000 +
      _months * 60 * 60 * 24 * 30 * 1000 +
      _year * 60 * 60 * 24 * 365 * 1000;

    return {
      stringValue:
        _msValue === 0
          ? '0 Seconds'
          : parseDurationString({
              years: _year,
              seconds: _seconds,
              minutes: _minutes,
              hours: _hours,
              days: _days,
              weeks: _weeks,
              months: _months,
              milliseconds: _milliseconds,
            }),
      msValue: _msValue,
    };
  });
};

export const durationStringToPossibleList = (str: string) => {
  const _str = str.trim();

  const letters = _str.match(/[^0-9]+/g);
  const numbers = _str.match(/\d+/g);

  const groups = numbers
    ? numbers.map((el, index) => ({
        number: el,
        period: letters ? letters[index] ?? undefined : undefined,
      }))
    : [];

  if (!groups || groups.length === 0) return [];

  const findSimilarity = (word: string, pattern: string) => {
    if (word.toLowerCase().startsWith(pattern)) {
      return true;
    } else if (pattern.length > word.length) {
      for (let i = 0; i < word.length; i++) {
        if (word[i].toLowerCase() !== pattern[i]) {
          return false;
        }
      }
      return true;
    } else return false;
  };

  const filterByStr = (startsWith: string) =>
    groups
      .filter(el => el.period && el.period.toLowerCase().startsWith(startsWith))
      .map(el => el.number);

  const years = filterByStr('y');
  const weeks = filterByStr('w');
  const days = filterByStr('d');
  const hours = filterByStr('h');
  const seconds = filterByStr('s');
  const minutes = filterByStr('min');
  const months = filterByStr('mo');
  const milliseconds = filterByStr('mil').concat(filterByStr('ms'));

  const possibleMonths =
    months.length !== 0
      ? []
      : groups.filter(el => el.period && findSimilarity(el.period, 'mo')).map(el => el.number);

  const possibleMinutes =
    minutes.length !== 0
      ? []
      : groups.filter(el => el.period && findSimilarity(el.period, 'min')).map(el => el.number);

  const possibleMilliseconds =
    milliseconds.length !== 0
      ? []
      : groups
          .filter(
            el =>
              el.period && (findSimilarity(el.period, 'ms') || findSimilarity(el.period, 'milli')),
          )
          .map(el => el.number);

  const possibleNumber =
    groups[groups.length - 1].period === undefined ? groups[groups.length - 1].number : undefined;

  const combinations = generateCombinations({
    days,
    hours,
    minutes,
    months,
    weeks,
    years,
    seconds,
    milliseconds,
    possibleMinutes,
    possibleMonths,
    possibleMilliseconds,
    possibleNumber,
  });

  return combinations;
};
