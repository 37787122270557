import React, { useState, useCallback } from 'react';

import { ActionPanel, Button, AutoRefresh } from 'ui';
import { CreateWalletsModal } from 'modals';
import { WalletsTable } from '../WalletsTable/WalletsTable';
import { PROJECT_WALLETS_RELOAD } from 'constants/reload';

import { RoundChartIcon } from 'assets/icons';

import './style.scss';

const WalletsPanel: React.FC = () => {
  const [createWalletsModalOpened, setCreateWalletsModalOpened] = useState<boolean>(false);
  const [triggerWalletsTableRemount, setTriggerWalletsTableRemount] = useState<boolean>(false);

  const onOpenCreateWalletsModal = useCallback(() => {
    setCreateWalletsModalOpened(true);
  }, []);

  const onCloseCreateWalletsModal = useCallback(() => {
    setCreateWalletsModalOpened(false);
  }, []);

  const onSuccessChangedWallets = useCallback(() => {
    setTriggerWalletsTableRemount(v => !v);
  }, []);

  return (
    <>
      <ActionPanel
        icon={RoundChartIcon}
        title="Wallets"
        actions={
          <div className="mm-project-wallets-actions">
            <AutoRefresh
              busEvent={PROJECT_WALLETS_RELOAD}
              tooltipText={'Autorefresh visible wallets'}
            />
            <Button size={'small'} onClick={onOpenCreateWalletsModal}>
              Create new wallets
            </Button>
          </div>
        }
        headerNode
      >
        <WalletsTable triggerRemount={triggerWalletsTableRemount} />
      </ActionPanel>
      {createWalletsModalOpened && (
        <CreateWalletsModal
          onOpen={onOpenCreateWalletsModal}
          onClose={onCloseCreateWalletsModal}
          onSuccess={onSuccessChangedWallets}
        />
      )}
    </>
  );
};

export { WalletsPanel };
