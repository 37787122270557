import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useTypedSelector } from 'store';
import { formatFiat, formatToken } from 'utils/formats';

export const FeeSpent: React.FC<{ fee: { feeSpent: string; feeSpentUsd: string } }> = ({ fee }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const feePriceUsdString = useMemo(
    () => formatFiat(BigNumber.from(fee.feeSpentUsd ?? '0'), 6, '$'),
    [fee],
  );

  const feeSpentTokenString = useMemo(
    () => (pair ? formatToken(BigNumber.from(fee.feeSpent), pair.token_fee.decimals) : ''),
    [fee],
  );

  return (
    <>
      {feeSpentTokenString} BNB / {feePriceUsdString}
    </>
  );
};
