import ReactDOM from 'react-dom/client';
import { Buffer } from 'buffer';

import loadTimeLocale from 'utils/loadTimeLocale';

import './global.scss';
import './extensions.scss';

import { MainApp } from 'core/MainApp';

loadTimeLocale();

window.Buffer = Buffer;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<MainApp />);
