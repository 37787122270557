import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { LocalStorage } from 'tools';
import { EDexPairFilters, IDexPairsFilters } from 'types/filters';
import { DEX_PAIR_FILTERS_KEY } from 'constants/cache';
import { IDexFilterValue } from 'types/filters/common';

export const addDexPairFilterReducer: CaseReducer<
  IDexPairsFilters,
  PayloadAction<{
    pairId: number;
    type: EDexPairFilters;
    filter?: IDexFilterValue;
  }>
> = (state, { payload: { pairId, type, filter } }) => {
  if (!state[pairId]) return;

  state[pairId]!.updated_at = new Date(Date.now()).toISOString();

  const newFilters = [...(state[pairId]!.filters[type]?.filters ?? [])];
  newFilters.push(filter ?? { id: uuid() });

  state[pairId]!.filters = {
    ...state[pairId]!.filters,
    [type]: {
      filters: newFilters,
      setted: state[pairId]!.filters[type]?.setted ?? [],
    },
  };

  LocalStorage.set(DEX_PAIR_FILTERS_KEY, state);
};
