import { createSlice } from '@reduxjs/toolkit';

import { IDexPair, ICexPair } from 'types/pairs';

interface IPairsSliceState {
  selectedDexPair: IDexPair | null;
  selectedCexPair: ICexPair | null;
}
const pairsSliceInitialState: IPairsSliceState = {
  selectedDexPair: null,
  selectedCexPair: null,
};

const pairsSlice = createSlice({
  name: 'pairs',
  initialState: pairsSliceInitialState as IPairsSliceState,
  reducers: {
    setSelectedDexPair: (state, { payload }: { payload: IDexPair | null }) => {
      state.selectedDexPair = payload;
      state.selectedCexPair = null;
    },
    setSelectedCexPair: (state, { payload }: { payload: ICexPair | null }) => {
      state.selectedCexPair = payload;
      state.selectedDexPair = null;
    },
  },
});

export const { setSelectedDexPair, setSelectedCexPair } = pairsSlice.actions;

export default pairsSlice.reducer;
