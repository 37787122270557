import { number, object } from 'yup';

export const validationSchema = () =>
  object({
    min_amount: number().required('Minimum amount field is required'),
    max_amount: number()
      .required('Maximum amount field is required')
      .test(
        'max_amount',
        "Maximum amount can't be lower than minimum",
        (max_amount: number | undefined, context) =>
          Number(max_amount) > Number(context.parent.min_amount),
      ),
    min_pause: number().required('Minimum pause field is required'),
    max_pause: number()
      .required('Maximum pause field is required')
      .test(
        'max_pause',
        "Maximum pause can't be lower than minimum",
        (max_pause: number | undefined, context) =>
          Number(max_pause) > Number(context.parent.min_pause),
      ),
  });
