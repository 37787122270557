import React from 'react';

import { FailedIcon } from 'assets/icons';

import './panelError.scss';

interface IPanelError {
  title: string;
  text: string;
}

const PanelError: React.FC<IPanelError> = ({ text, title }) => {
  return (
    <div className="mm-panel-error">
      <div className="mm-panel-error__title">
        <div className="mm-panel-error__title__icon">
          <FailedIcon />
        </div>
        <h3>{title}</h3>
      </div>
      <span className="mm-panel-error__text">{text}</span>
    </div>
  );
};

export { PanelError };
