import { IDexName, ICexName, ENetwork } from 'types';

//networks
import bscLogo from 'assets/images/networks/bsc.png';
import ethLogo from 'assets/images/networks/eth.svg';
import polygonLogo from 'assets/images/networks/polygon.png';
import arbitrumLogo from 'assets/images/networks/arbitrum.png';

//exchanges
import panckageLogo from 'assets/images/dex/pancakeswapLogo.svg';
import panckageLogoWordMark from 'assets/images/dex/pancakeswapLogoWordMark.svg';
import uniswapLogo from 'assets/images/dex/uniswapLogo.svg';
import uniswapLogoWordMark from 'assets/images/dex/uniswapLogoWordMark.svg';
import quickswapLogo from 'assets/images/dex/quickswapLogo.svg';
import quickswapLogoWordMark from 'assets/images/dex/quickswapLogoWordMark.svg';
import sushiswapLogo from 'assets/images/dex/sushiswapLogo.svg';
import sushiswapLogoWordMark from 'assets/images/dex/sushiswapLogoWordMark.png';
import gateIoLogo from 'assets/images/cex/gateIoLogo.svg';
import gateIoLogoWordMark from 'assets/images/cex/gateIoLogoWordMark.svg';
import kuCoinLogo from 'assets/images/cex/kuCoinLogo.svg';
import kiCoinWordMarkIcon from 'assets/images/cex/kuCoinWordMark.svg';
import mexcLogo from 'assets/images/cex/mexcLogo.svg';
import mexcLogoWordMark from 'assets/images/cex/mexcWordMark.svg';
import binaceLogo from 'assets/images/cex/binanceLogo.svg';
import binaceLogoWordMark from 'assets/images/cex/binanceLogoWordMark.svg';
import camelotLogo from 'assets/images/dex/camelotLogo.png';
import camelotLogoWordMark from 'assets/images/dex/camelotLogoWordMark.webp';

interface IconMappingType {
  alt?: string;
  src?: string;
}

export const networksMapping = ({ name }: { name: ENetwork }) => {
  const mapper: Record<ENetwork, IconMappingType> = {
    bsc: {
      alt: 'bsc',
      src: bscLogo,
    },
    eth: {
      alt: 'eth',
      src: ethLogo,
    },
    polygon: {
      alt: 'polygon',
      src: polygonLogo,
    },
    arbitrum_one: {
      alt: 'arbitrum',
      src: arbitrumLogo,
    },
  };

  return mapper[name];
};

export const exchangesMapping = ({
  isIcon,
  name,
}: {
  isIcon: boolean;
  name: IDexName | ICexName;
}): IconMappingType => {
  const mapper: Record<IDexName | ICexName, IconMappingType> = {
    pancakeswap_v2: {
      alt: 'pancakeswap_v2',
      src: isIcon ? panckageLogo : panckageLogoWordMark,
    },
    uniswap_v2: {
      alt: 'uniswap_v2',
      src: isIcon ? uniswapLogo : uniswapLogoWordMark,
    },
    uniswap_v3: {
      alt: 'uniswap_v3',
      src: isIcon ? uniswapLogo : uniswapLogoWordMark,
    },
    quickswap_v2: {
      alt: 'quickswap_v2',
      src: isIcon ? quickswapLogo : quickswapLogoWordMark,
    },
    sushiswap_v2: {
      alt: 'sushiswap_v2',
      src: isIcon ? sushiswapLogo : sushiswapLogoWordMark,
    },
    'uniswap_v3:arbitrum_one': {
      alt: 'uniswap_v3',
      src: isIcon ? uniswapLogo : uniswapLogoWordMark,
    },
    camelot_v2: {
      alt: 'camelot_v2',
      src: isIcon ? camelotLogo : camelotLogoWordMark,
    },
    gate: { alt: 'gate', src: isIcon ? gateIoLogo : gateIoLogoWordMark },
    kucoin: { alt: 'kucoin', src: isIcon ? kuCoinLogo : kiCoinWordMarkIcon },
    mexc: { alt: 'mexc', src: isIcon ? mexcLogo : mexcLogoWordMark },
    binance: { alt: 'binance', src: isIcon ? binaceLogo : binaceLogoWordMark },
  };

  return mapper[name];
};
