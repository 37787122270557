import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';
import { formatFiat, formatToken } from 'utils/formats';
import { BigNumber } from '@ethersproject/bignumber';
import { Tooltip } from 'ui';
import { v4 as uuid } from 'uuid';
import './columnsAccounts.scss';

interface ITokensAccountsCellProps {
  row: Row<ICexAccounts>;
  searchableToken: string;
}

const TokensAccountsCell: React.FC<ITokensAccountsCellProps> = React.memo(
  ({ row, searchableToken }) => {
    const currentToken = row.original.tokens?.find(item => item?.token?.symbol === searchableToken);
    const isTotal = searchableToken === 'TOTAL';
    const id = useMemo(() => uuid(), []);

    return (
      <div className="tokens-accounts-cell-wrapper">
        {currentToken ? (
          isTotal ? (
            formatFiat(BigNumber.from(currentToken.balance_usd), 6)
          ) : (
            <Tooltip
              id={id}
              item={`${formatFiat(BigNumber.from(currentToken?.balance_usd), 6)}`}
              tooltipEvent="hover"
              cexTable
            >
              {formatToken(BigNumber.from(currentToken.balance), 18)}
            </Tooltip>
          )
        ) : (
          '-'
        )}
      </div>
    );
  },
);

export default TokensAccountsCell;
