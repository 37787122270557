import React, { useMemo } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { ITransfer } from 'types/transfers';

import { StaticTable, TBody } from '../../common';
import { Token, tokenAccessor, Amount, Status, Hash } from './columns';

import './style.scss';

interface IWithdrawalStatusTableProps {
  transfers: ITransfer[];
}

const WithdrawalStatusTable: React.FC<IWithdrawalStatusTableProps> = ({ transfers }) => {
  const columns = useMemo<ColumnDef<ITransfer>[]>(
    () => [
      {
        header: () => <div style={{ textAlign: 'left' }}>Token</div>,
        id: 'token',
        accessorFn: tokenAccessor,
        cell: ({ getValue }) => <Token tokenId={getValue() as number} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Amount</div>,
        id: 'amount',
        cell: ({ row }) => <Amount transfer={row.original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Status</div>,
        id: 'status',
        cell: ({ row }) => <Status transfer={row.original} />,
      },
      {
        header: () => <div style={{ textAlign: 'left' }}>Txn Hash</div>,
        id: 'hash',
        cell: ({ row }) => <Hash transfer={row.original} />,
      },
    ],
    [],
  );

  const table = useReactTable({
    data: transfers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  return (
    <div className="mm-withdraw-status-table-container scrollable">
      <StaticTable className="mm-withdraw-status-table">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <TBody>
          {rows.map(row => (
            <React.Fragment key={row.index}>
              <tr className={Number(row.id) % 2 === 0 ? 'tr-colorized' : undefined}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            </React.Fragment>
          ))}
        </TBody>
      </StaticTable>
    </div>
  );
};

export { WithdrawalStatusTable };
