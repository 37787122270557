import React from 'react';

import { IDextWTOrganicVolumeTask } from 'types/bots';

interface ITransactionSerieCellProps {
  task: IDextWTOrganicVolumeTask;
}

const TransactionSerieCell: React.FC<ITransactionSerieCellProps> = ({ task }) => {
  return (
    <div>
      {task.options.min_transactions_in_series} / {task.options.max_transactions_in_series}
    </div>
  );
};

export { TransactionSerieCell };
