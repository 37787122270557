import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { EDexPairFilters, IDexPairsFilters } from 'types/filters';
import { DEX_PAIR_FILTERS_KEY } from 'constants/cache';

export const updateDexPairFilterReducer: CaseReducer<
  IDexPairsFilters,
  PayloadAction<{
    pairId: number;
    id: string;
    filter?: any;
    type: EDexPairFilters;
  }>
> = (state, { payload: { pairId, id, filter, type } }) => {
  if (!state[pairId]) return;

  state[pairId]!.updated_at = new Date(Date.now()).toISOString();

  const newFilters = [...(state[pairId]!.filters[type]?.filters ?? [])];
  const findFilterIdx = newFilters.findIndex(el => el.id === id);

  if (findFilterIdx === -1) return;

  newFilters[findFilterIdx] = filter;

  state[pairId]!.filters = {
    ...state[pairId]!.filters,
    [type]: { filters: newFilters, setted: state[pairId]!.filters[type]?.setted ?? [] },
  };

  LocalStorage.set(DEX_PAIR_FILTERS_KEY, state);
};
