import React, { useCallback } from 'react';

import { getTextFromClipboard } from 'utils';

import './style.scss';

interface IInsertButtonProps {
  onInsert: (value: string) => void;
}

const InsertButton: React.FC<IInsertButtonProps> = ({ onInsert }) => {
  const handleInsert = useCallback(async () => {
    const text = await getTextFromClipboard();

    if (text) {
      onInsert(text);
    }
  }, [onInsert]);

  return (
    <button className="input-insert-button" onClick={handleInsert}>
      Insert
    </button>
  );
};

export { InsertButton };
