import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import { useTypedSelector } from 'store';

import { Modal, Button } from 'ui';
import { CreateTaskForm } from 'ui/forms';
import { SelectField, DateField, InputField, DurationField } from 'fields';
import { IDexWTBotVolumeStrategyTask } from 'types/bots';
import { useRegularTask } from './useRegularTask';
import { dailyVolume, dexDailyFees } from 'utils/daily';
import { formatFiat, formatToken } from 'utils/formats';

import './style.scss';

const CONDITION_TYPES = [
  { value: 'price_change', label: 'Price change' },
  { value: 'start_at', label: 'Start at' },
];

const DIRECTIONS = [
  { value: 'any', label: 'Any' },
  { value: 'rise', label: 'Up' },
  { value: 'fall', label: 'Down' },
];

interface IProps {
  onClose: () => void;
  mode: 'create' | 'edit';
  task?: IDexWTBotVolumeStrategyTask;
  onSubmit: (task: IDexWTBotVolumeStrategyTask) => void;
}

export const DexWTRegularTaskModal: React.FC<IProps> = ({ mode, onClose, task, onSubmit }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { edited, handleSubmit, errors, setFieldValue, touched, values } = useRegularTask({
    onSubmit,
    task,
    onClose,
    mode,
  });

  const _dailyVolume = useMemo(() => {
    const minPause = (values.min_pause_between_series ?? 0) + (values.min_serie_duration ?? 0);

    const maxPause = (values.max_pause_between_series ?? 0) + (values.max_serie_duration ?? 0);

    const { volume, volumeUsd } = dailyVolume({
      delay_min: minPause,
      delay_max: maxPause,
      volume_max: values.max_volume,
      volume_min: values.min_volume,
      pair: dexPair,
    });

    return { volume, volumeUsd };
  }, [dexPair, values]);

  const dailyFee = useMemo(() => {
    const avgGroupTransactions =
      Number(+values.min_transactions_in_group ?? 0) +
      Number(+values.max_transactions_in_group ?? 0);
    const avgPause =
      (values.max_pause_between_groups ?? 0 + (values.min_pause_between_groups ?? 0)) / 2;
    const dailyGroups = avgPause === 0 ? 0 : Math.floor((24 * 60 * 60 * 1000) / avgPause);

    const { base, fee, total } = dexDailyFees({
      daily_txs: dailyGroups * avgGroupTransactions,
      daily_volume: _dailyVolume.volume,
      dexPair,
    });

    return { base, fee, total };
  }, [_dailyVolume, dexPair, values]);

  return (
    <Modal
      title={`${capitalize(mode)} task (regular volume)`}
      edited={edited}
      className="mm-dex-wt-bot-regular-task-modal"
      onClose={onClose}
    >
      <CreateTaskForm handleSubmit={handleSubmit}>
        <div className="form-inner scrollable">
          <div className="form-container">
            <SelectField
              items={CONDITION_TYPES}
              itemToString={v => v.label}
              label="Condition type"
              onSelectItem={item =>
                item ? setFieldValue('condition_type', item.value) : undefined
              }
              selectedItem={CONDITION_TYPES.find(el => el.value === values.condition_type)}
            />
            {values.condition_type === 'start_at' && (
              <DateField
                date={values.start_at}
                label="Start time"
                setDate={d => setFieldValue('start_at', d)}
                errorMessage={touched.start_at && errors.start_at ? errors.start_at : undefined}
              />
            )}
            {values.condition_type === 'price_change' && (
              <div className="triple-inputs">
                <InputField
                  type="decimal-number"
                  value={values.threshold_percentage}
                  setValue={v => setFieldValue('threshold_percentage', v)}
                  label="Price change %"
                  errorMessage={
                    touched.threshold_percentage && errors.threshold_percentage
                      ? errors.threshold_percentage
                      : undefined
                  }
                />
                <DurationField
                  value={values.duration}
                  setValue={v => setFieldValue('threshold_percentage', v)}
                  label="In time"
                  errorMessage={
                    touched.threshold_percentage && errors.threshold_percentage
                      ? errors.threshold_percentage
                      : undefined
                  }
                />
                <SelectField
                  items={DIRECTIONS}
                  itemToString={v => v.label}
                  label="Side"
                  onSelectItem={item => (item ? setFieldValue('direction', item.value) : undefined)}
                  selectedItem={DIRECTIONS.find(el => el.value === values.direction)}
                />
              </div>
            )}
            <div>
              <div className="text-main-purple font-bold text-sm">Serie volume</div>
              <div className="inputs-row">
                <InputField
                  type="decimal-number"
                  value={values.min_volume}
                  setValue={v => setFieldValue('min_volume', v)}
                  label="Minimum"
                  errorMessage={
                    touched.min_volume && errors.min_volume ? errors.min_volume : undefined
                  }
                />
                <InputField
                  type="decimal-number"
                  value={values.max_volume}
                  setValue={v => setFieldValue('max_volume', v)}
                  label="Maximum"
                  errorMessage={
                    touched.max_volume && errors.max_volume ? errors.max_volume : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Serie duration</div>
              <div className="inputs-row">
                <DurationField
                  value={values.min_serie_duration}
                  setValue={v => setFieldValue('min_serie_duration', v)}
                  label="Minimum"
                  errorMessage={
                    touched.min_serie_duration && errors.min_serie_duration
                      ? errors.min_serie_duration
                      : undefined
                  }
                />
                <DurationField
                  value={values.max_serie_duration}
                  setValue={v => setFieldValue('max_serie_duration', v)}
                  label="Maximum"
                  errorMessage={
                    touched.max_serie_duration && errors.max_serie_duration
                      ? errors.max_serie_duration
                      : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Pause between series</div>
              <div className="inputs-row">
                <DurationField
                  value={values.min_pause_between_series}
                  setValue={v => setFieldValue('min_pause_between_series', v)}
                  label="Minimum"
                  errorMessage={
                    touched.min_pause_between_series && errors.min_pause_between_series
                      ? errors.min_pause_between_series
                      : undefined
                  }
                />
                <DurationField
                  value={values.max_pause_between_series}
                  setValue={v => setFieldValue('max_pause_between_series', v)}
                  label="Maximum"
                  errorMessage={
                    touched.max_pause_between_series && errors.max_pause_between_series
                      ? errors.max_pause_between_series
                      : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Pause between groups</div>
              <div className="inputs-row">
                <DurationField
                  value={values.min_pause_between_groups}
                  setValue={v => setFieldValue('min_pause_between_groups', v)}
                  label="Minimum"
                  errorMessage={
                    touched.min_pause_between_groups && errors.min_pause_between_groups
                      ? errors.min_pause_between_groups
                      : undefined
                  }
                />
                <DurationField
                  value={values.max_pause_between_groups}
                  setValue={v => setFieldValue('max_pause_between_groups', v)}
                  label="Maximum"
                  errorMessage={
                    touched.max_pause_between_groups && errors.max_pause_between_groups
                      ? errors.max_pause_between_groups
                      : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Group transactions</div>
              <div className="inputs-row">
                <InputField
                  type="natural-number"
                  value={values.min_transactions_in_group}
                  setValue={v => setFieldValue('min_transactions_in_group', v)}
                  label="Minimum"
                  errorMessage={
                    touched.min_transactions_in_group && errors.min_transactions_in_group
                      ? errors.min_transactions_in_group
                      : undefined
                  }
                />
                <InputField
                  type="natural-number"
                  value={values.max_transactions_in_group}
                  setValue={v => setFieldValue('max_transactions_in_group', v)}
                  label="Maximum"
                  errorMessage={
                    touched.max_transactions_in_group && errors.max_transactions_in_group
                      ? errors.max_transactions_in_group
                      : undefined
                  }
                />
              </div>
            </div>
            <InputField
              type="decimal-number"
              value={values.slippage_percent}
              setValue={v => setFieldValue('slippage_percent', v)}
              label="Slippage percentage"
              errorMessage={
                touched.slippage_percent && errors.slippage_percent
                  ? errors.slippage_percent
                  : undefined
              }
            />
            <InputField
              type="decimal-number"
              value={values.repeatability}
              setValue={v => setFieldValue('repeatability', v)}
              label="Repeatability"
            />
          </div>
          <div className="daily-container">
            <div className="daily-container__daily-volume">
              <span>Daily volume:</span>
              <span>
                {formatToken(_dailyVolume.volume, dexPair.token_base.decimals)}{' '}
                {dexPair.token_base.symbol} / {formatFiat(_dailyVolume.volumeUsd, 18)}
              </span>
            </div>
            <div className="daily-container__daily-free-costs">
              <span>Daily fee costs: </span>
              <span>
                {formatToken(dailyFee.base.amount, 18)} {dexPair.token_base.symbol}
                {formatToken(dailyFee.fee.amount, 18) === '0'
                  ? ' '
                  : '+ ' +
                    formatToken(dailyFee.fee.amount, 18) +
                    ' ' +
                    dexPair.token_fee.symbol}{' '}
                / {formatFiat(dailyFee.total.usd, 18)}
              </span>
            </div>
          </div>
        </div>
        <div className="actions">
          <Button type="submit">{mode === 'create' ? 'Create task' : 'Save task'}</Button>
        </div>
      </CreateTaskForm>
    </Modal>
  );
};
