import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';

import { isAddress, getContract } from 'utils';

const useWalletContract = (address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null => {
  const { library, account } = useWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !isAddress(address)) return null;

    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
};

export { useWalletContract };
