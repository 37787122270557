import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import cn from 'classnames';
import { motion } from 'framer-motion';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { addressShortView } from 'utils';

interface IWalletItemProps {
  address: string;
  balance: string;
  setBalance: (b: string, adr: string) => any;
  walletBalance: BigNumber;
  decimals: number;
  type: 'deposit' | 'withdraw';
  distributionTouched: boolean;
  disabled: boolean;
  isValid: boolean;
}

const WalletItemComponent: React.FC<IWalletItemProps> = ({
  address,
  balance,
  setBalance,
  walletBalance,
  decimals,
  type,
  distributionTouched,
  disabled,
  isValid,
}) => {
  const [walletTouched, setWalletTouched] = useState<boolean>(distributionTouched);

  useEffect(() => {
    if (distributionTouched) {
      setWalletTouched(true);
    }
  }, [distributionTouched]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;

      if (isNaN(Number(newValue))) return;

      if (newValue.split('.')[1]?.length > 18) return;

      setWalletTouched(true);
      setBalance(newValue, address);
    },
    [address, setBalance],
  );

  const handleSetMaxValue = useCallback(() => {
    setBalance(formatUnits(walletBalance, decimals), address);
  }, [setBalance, walletBalance, decimals, address]);

  return (
    <li>
      <div className="wallet-hint">
        <span className="wallet-balance">Balance: {formatUnits(walletBalance, decimals)}</span>
        {!isValid && walletTouched && !disabled && (
          <span className="wallet-error">Invalid amount</span>
        )}
      </div>
      <div className={cn('wallet-item', { _disabled: !!disabled })}>
        <span className="wallet-address">{addressShortView(address)}</span>
        <div className="wallet-item__right">
          {!disabled && (
            <input type="text" disabled={disabled} value={balance} onChange={handleChange} />
          )}
          {type === 'withdraw' && !disabled && (
            <motion.button
              type="button"
              className="max-button without-padding"
              whileTap={{ backgroundColor: '#F7F7F7' }}
              onClick={handleSetMaxValue}
            >
              Max
            </motion.button>
          )}
        </div>
      </div>
    </li>
  );
};

const WalletItem = memo(WalletItemComponent);

export { WalletItem };
