import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { Modal } from 'ui';
import { Form } from './Form';
import { IPairWallet } from 'api/apiPairs/models';
import { IDexPair } from 'types/pairs';
import { ITransfer } from 'types/transfers';
import DepositWithdrawContextProvider, {
  mapTypeToCaption,
} from 'context/DepositWithdrawContext/DepositWithdrawContext';

import './depositWithdrawModal.scss';

interface IDepositWithdrawModalProps {
  onClose: () => void;
  onRemountTable: () => void;
  wallets: IPairWallet[];
  pair: IDexPair;
  type: 'deposit' | 'withdraw';
  setManualTxsModalOpened: Dispatch<SetStateAction<boolean>>;
  onWithdrawResult?: (transfers: ITransfer[]) => void;
}

const DepositWithdrawModal: React.FC<IDepositWithdrawModalProps> = ({
  onClose,
  onRemountTable,
  wallets,
  type,
  pair,
  setManualTxsModalOpened,
  onWithdrawResult,
}) => {
  const handleOpenManualTransactionsModal = useCallback(() => {
    onClose();
    setManualTxsModalOpened(true);
  }, [setManualTxsModalOpened, onClose]);

  return (
    <DepositWithdrawContextProvider options={{ type, pair, wallets }}>
      <Modal onClose={onClose} title={mapTypeToCaption(type)}>
        <Form
          onRemountTable={onRemountTable}
          handleOpenManualTransactionsModal={handleOpenManualTransactionsModal}
          onWithdrawResult={onWithdrawResult}
          onClose={onClose}
          pair={pair}
        />
      </Modal>
    </DepositWithdrawContextProvider>
  );
};

export { DepositWithdrawModal };
