import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { PairBuySellBotContextProvider } from 'context/PairBuySellBotContext';
import { Panel, BotEnableStatus } from 'ui';
import { Toolbar, TableWrapper, Table } from './components';
import { EDexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';

import { TradingIcon } from 'assets/icons';

const BuySellBot: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pairId = useTypedSelector(store => store.pairs.selectedDexPair)!.id;

  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[pairId],
  );
  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[EDexBot.buy_sell_bot] : undefined),
    [pairConnectedBots],
  );

  return (
    <Panel
      title="BuyAndSellTokens algorithm"
      icon={TradingIcon}
      initialOpened={initialOpened}
      onChange={setIsOpened}
      headerNode={
        isBotEnabled !== undefined && (
          <BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />
        )
      }
    >
      <PairBuySellBotContextProvider>
        <Toolbar />
        <TableWrapper>
          <Table />
        </TableWrapper>
      </PairBuySellBotContextProvider>
    </Panel>
  );
};

export { BuySellBot };
