import React, { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTypedSelector, useTypedDispatch } from 'store';

import { Button, ButtonLoading } from 'ui';
import { InputField } from 'fields';
import { ApiBot } from 'api';
import { PairAFRBotContext } from 'context/PairAFRBotContext';
import { isAddress } from 'utils';
import { EDexBot } from 'types/bots';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import './form.scss';

interface IFormikValues {
  protectionContract: string;
  analysisDepth: string;
}

const Form: React.FC = () => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const dispatch = useTypedDispatch();

  const { botSettings, handleLoadBotSettings } = useContext(PairAFRBotContext);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const handleChangeBotConfig = useCallback(
    async ({ protectionContract, analysisDepth }: IFormikValues) => {
      if (!pair || !botSettings) return;

      setFormLoading(true);

      try {
        const { isSuccess, errorMessage } = await ApiBot.saveDexBotConfig({
          pairId: pair.id,
          bot: EDexBot.anti_font_run,
          body: {
            ...botSettings,
            slippage_percent: botSettings.slipage,
            send_private_transactions: botSettings.privateTx,
            is_enabled: botSettings.enableBot,
            afr_options: {
              ...botSettings.afr_options,
              protection_contract: protectionContract,
              analysis_depth: Number(analysisDepth),
            },
          },
        });

        if (isSuccess) {
          dispatch(
            setAlertState({
              type: 'success-img',
              text: `Successfully updated bot config!`,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          handleLoadBotSettings();
        } else {
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        }
      } catch (error) {
        console.log(error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Failed to update bot config...',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      } finally {
        setFormLoading(false);
      }
    },
    [pair, botSettings, handleLoadBotSettings],
  );

  const initialValues = useMemo<IFormikValues>(
    () => ({ protectionContract: '', analysisDepth: '' }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        protectionContract: Yup.string()
          .required('Enter correct address')
          .test(
            'protectionContract',
            'Enter correct address',
            (protectionContract: string | undefined) => isAddress(protectionContract),
          ),
        analysisDepth: Yup.string().required('Enter correct number'),
      }),
    [],
  );

  const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, isValid } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: handleChangeBotConfig,
    });

  useEffect(() => {
    setFieldValue('protectionContract', botSettings?.afr_options?.protection_contract ?? '');
    setFieldValue('analysisDepth', botSettings?.afr_options?.analysis_depth.toString() ?? '');
  }, [botSettings]);

  return (
    <div className="mm-afr-bot-panel__form">
      <InputField
        className="depth-blocks"
        type="natural-number"
        value={values.analysisDepth}
        errorMessage={
          touched.analysisDepth && errors.analysisDepth ? errors.analysisDepth : undefined
        }
        label="Depth  (blocks)"
        placeholder="Depth  (blocks)"
        setValue={value => setFieldValue('analysisDepth', value)}
        onBlur={() => setFieldTouched('analysisDepth', true)}
      />
      <InputField
        className="protection-contract"
        type="text"
        value={values.protectionContract}
        errorMessage={
          touched.protectionContract && errors.protectionContract
            ? errors.protectionContract
            : undefined
        }
        label="Protection contract"
        placeholder="Protection contract"
        comment="Enter protection contract address"
        setValue={value => setFieldValue('protectionContract', value)}
        onBlur={() => setFieldTouched('protectionContract', true)}
      />
      {formLoading && <ButtonLoading className="mm-afr-bot-panel__submit-button" />}
      {!formLoading && (
        <Button className="mm-afr-bot-panel__submit-button" onClick={handleSubmit}>
          Save
        </Button>
      )}
    </div>
  );
};

export default Form;
