import { useState, useCallback, useEffect } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ITransactionItem } from 'api/apiTransactions/models';
import { ApiTransactions } from 'api';
import { useScrollPagination } from 'hooks';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { Bus } from 'tools';
import { STOP_RELOAD_PREFIX, PAIR_TRANSACTIONS_RELOAD } from 'constants/reload';

const useRecords = () => {
  const dispatch = useTypedDispatch();
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const [records, setRecords] = useState<ITransactionItem[] | undefined>(undefined);

  const getRecords = useCallback(
    async ({ limit, lastSeenId }: { limit: number; lastSeenId: number }) => {
      try {
        const { errorMessage, isSuccess, data } = await ApiTransactions.getTransactions({
          limit,
          lastSeenId,
          pair_ids: pair ? [pair.id] : undefined,
        });

        if (!isSuccess) {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        } else if (data) {
          return {
            has_more: data.has_next,
            records: data.items,
          };
        }
      } catch (error) {
        console.log(error);
      }

      return undefined;
    },
    [pair, dispatch],
  );

  const {
    hasMore,
    loading,
    records: paginationRecords,
    loadMore,
  } = useScrollPagination({
    limit: 40,
    getRecords,
  });

  const handleLoadMore = useCallback(async () => {
    const newRecordsSlice = await loadMore();

    if (newRecordsSlice) {
      setRecords(v => (v ? [...v].concat([...newRecordsSlice]) : newRecordsSlice));
    }
  }, [loadMore]);

  useEffect(() => {
    if (!records && paginationRecords && paginationRecords.length !== 0) {
      setRecords(paginationRecords);
    }
  }, [paginationRecords, records]);

  const updateVisibleRecords = useCallback(async () => {
    try {
      const startTime = Date.now();

      const { isSuccess, data, errorMessage } = await ApiTransactions.getTransactions({
        lastSeenId: 0,
        limit: 40,
        pair_ids: pair ? [pair.id] : undefined,
      });

      const endTime = Date.now();

      if (isSuccess) {
        const newRecords = records ? [...records] : [];

        for (let i = 0; i < data.items.length; i++) {
          const newRecord = data.items[i];

          const newExistRecordIndex = newRecords.findIndex(el => el.id === newRecord.id);

          if (newExistRecordIndex < 0) {
            newRecords.unshift(newRecord);
          } else {
            newRecords[newExistRecordIndex] = newRecord;
          }
        }

        setRecords(newRecords);

        if (endTime - startTime >= 1500) {
          Bus.emit(STOP_RELOAD_PREFIX + PAIR_TRANSACTIONS_RELOAD);
        } else {
          setTimeout(() => {
            Bus.emit(STOP_RELOAD_PREFIX + PAIR_TRANSACTIONS_RELOAD);
          }, 1500 - (endTime - startTime));
        }
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        Bus.emit(STOP_RELOAD_PREFIX + PAIR_TRANSACTIONS_RELOAD);
      }
    } catch (error) {
      console.log(error);
      Bus.emit(STOP_RELOAD_PREFIX + PAIR_TRANSACTIONS_RELOAD);
    }
  }, [pair, records, dispatch]);

  useEffect(() => {
    Bus.on(PAIR_TRANSACTIONS_RELOAD, updateVisibleRecords);

    return () => {
      Bus.off(PAIR_TRANSACTIONS_RELOAD, updateVisibleRecords);
    };
  }, [updateVisibleRecords]);

  return {
    records,
    hasMore,
    loading,
    handleLoadMore,
  };
};

export { useRecords };
