import React from 'react';
import { Row } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { formatToken } from 'utils/formats';

interface ITokenQuoteCellProps {
  row: Row<IBalanceBotWallet>;
}

const TokenQuoteCell: React.FC<ITokenQuoteCellProps> = ({ row }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const quoteToken = row.original.tokens.find(item => item.token.id === dexPair.token_quote.id)!;

  return (
    <div>{formatToken(BigNumber.from(quoteToken?.balance ?? '0'), quoteToken.token.decimals)}</div>
  );
};

export { TokenQuoteCell };
