import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { divideBignumbers } from 'utils/formulas';

export const calculateDepositAmounts = (
  total: BigNumber,
  balances: { address: string; balance: BigNumber }[],
  decimal: number,
) => {
  const balancesLength = balances.length;
  const dividedBalance = divideBignumbers(
    [total, decimal],
    [parseUnits(balancesLength.toString(), decimal), decimal],
  );

  const withdrawWalletBalances = {} as Record<string, BigNumber>;

  for (const wallet of balances) {
    withdrawWalletBalances[wallet.address] = dividedBalance;
  }

  return withdrawWalletBalances;
};
