import React from 'react';
import { useTypedSelector } from 'store';

const TokenBaseHead: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  return <div style={{ textAlign: 'left' }}>{dexPair.token_base.symbol}</div>;
};

export { TokenBaseHead };
