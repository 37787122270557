import React, { useMemo } from 'react';
import { useLocation, matchPath, PathMatch } from 'react-router';

import DexPairHeader from './DexPairHeader';
import CexPairHeader from './CexPairHeader';

const HeaderPair: React.FC = () => {
  const { pathname } = useLocation();

  const dexPathMatch: PathMatch<'projectId' | 'pairId'> | null = matchPath(
    '/project/:projectId/dex-pair/:pairId',
    pathname,
  );
  const cexPathMatch: PathMatch<'projectId' | 'pairId'> | null = matchPath(
    '/project/:projectId/cex-pair/:pairId',
    pathname,
  );

  const isDexPair = useMemo(() => !!dexPathMatch, [dexPathMatch]);
  const isCexPair = useMemo(() => !!cexPathMatch, [cexPathMatch]);

  const pairId = useMemo(
    () => Number(dexPathMatch ? dexPathMatch.params.pairId ?? 0 : cexPathMatch?.params.pairId ?? 0),
    [dexPathMatch, cexPathMatch],
  );
  const projectId = useMemo(
    () =>
      Number(
        dexPathMatch ? dexPathMatch.params.projectId ?? 0 : cexPathMatch?.params.projectId ?? 0,
      ),
    [dexPathMatch, cexPathMatch],
  );

  return (
    <>
      {isCexPair && <CexPairHeader pairId={pairId} projectId={projectId} />}
      {isDexPair && <DexPairHeader pairId={pairId} projectId={projectId} />}
    </>
  );
};

export { HeaderPair };
