import React, { useMemo } from 'react';
import { ENetwork } from 'types';

import { networksMapping } from 'constants/logos';

import './style.scss';

interface INetworkIconProps {
  network: ENetwork;
}

const NetworkIcon: React.FC<INetworkIconProps> = ({ network }) => {
  const iconTradingPair = useMemo(
    () => networksMapping({ name: network }),

    [network],
  );

  return (
    <img
      className="mm-trading-pair-network-icon"
      alt={iconTradingPair.alt}
      src={iconTradingPair.src}
    />
  );
};

export default NetworkIcon;
