import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useTypedDispatch, useTypedSelector } from 'store';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';

import { useScrollPagination } from 'hooks';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import {
  TableAction,
  TableActionIcon,
  TableToolbar,
  TableSelectedCaption,
  TableTotalCaption,
} from 'ui';
import { Bus } from 'tools';
import {
  STOP_RELOAD_PREFIX,
  PROJECT_WALLETS_RELOAD,
  PROJECT_CEX_ACCOUNTS_RELOAD,
} from 'constants/reload';

import { ConnectAccountToCexPairModal } from 'modals/ConnectAccountToCexPairModal/ConnectAccountToCexPairModal';
import { ApiCexAccount } from 'api/apiCexAccount/apiCexAccount';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';
import { NoteAccountHead } from './CexAccountsHeaders';
import { ApiKeyAccountCell, NotesAccountCell, TokensAccountsCell } from './CexAccountsCell';
import CexConnectedBotsHead from './CexAccountsHeaders/CexConnectedBotsHead';
import CexConnectedBotsCell from './CexAccountsCell/CexConnectedBotsCell';

import { ExitIcon, PlusIcon } from 'assets/icons';
import './style.scss';

const CexAccountsTable: React.FC = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useTypedDispatch();
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const isAdmin = useTypedSelector(store => store.user.isAdmin);
  const [connectAccountToCexModal, setConnectAccountToCexModal] = useState(false);
  const [triggerRemount, setTriggerRemount] = useState<boolean>(false);

  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
  const [virtualRecords, setVirtualRecords] = useState<ICexAccounts[]>([]);

  const handleGetMoreItems = useCallback(
    async ({ limit, lastSeenId }: { limit: number; lastSeenId: number }) => {
      try {
        const { isSuccess, data, errorMessage } = await ApiCexAccount.getCexAccountsList({
          limit,
          lastSeenId,
          cexId: cexPair.id,
        });
        if (isSuccess) {
          return {
            has_more: data.has_next,
            records: data.items,
          };
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );

          return undefined;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [cexPair.id, dispatch],
  );
  const { hasMore, records, setRecords, loading, loadMore, remount } =
    useScrollPagination<ICexAccounts>({
      limit: 20,
      getRecords: handleGetMoreItems,
    });

  const selectedAccounts = useMemo(() => {
    const selectedRows: ICexAccounts[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, records]);

  useEffect(() => {
    remount();
  }, [triggerRemount]);

  const columns = useMemo(
    () =>
      (
        [
          {
            id: 'select',
            accessorFn: row => row,
            header: ({ table }) => <NoteAccountHead table={table} />,
            cell: ({ row }) => <NotesAccountCell row={row} />,
          },
          {
            id: 'API key',
            accessorFn: row => row,
            header: () => <div style={{ textAlign: 'left' }}>API key</div>,
            cell: ({ row }) => <ApiKeyAccountCell row={row} />,
          },
          {
            id: 'Token_base',
            accessorFn: row => row,
            header: () => <div style={{ textAlign: 'left' }}>{cexPair.token_base.symbol}</div>,
            cell: ({ row }) => (
              <TokensAccountsCell row={row} searchableToken={`${cexPair.token_base.symbol}`} />
            ),
          },
          {
            id: 'Token_quote',
            accessorFn: row => row,
            header: () => <div style={{ textAlign: 'left' }}>{cexPair.token_quote.symbol}</div>,
            cell: ({ row }) => (
              <TokensAccountsCell row={row} searchableToken={`${cexPair.token_quote.symbol}`} />
            ),
          },
          {
            id: 'Token_native',
            accessorFn: row => row,
            header: () => <div style={{ textAlign: 'left' }}>{cexPair.token_native.symbol}</div>,
            cell: ({ row }) => (
              <TokensAccountsCell row={row} searchableToken={`${cexPair.token_native.symbol}`} />
            ),
          },
          {
            id: 'Total USD',
            accessorFn: row => row,
            header: () => <div style={{ textAlign: 'left' }}>Total USD</div>,
            cell: ({ row }) => <TokensAccountsCell row={row} searchableToken={'TOTAL'} />,
          },
          isAdmin
            ? {
                id: 'cexConnectedToBot',
                accessorFn: row => row,
                header: ({
                  table: {
                    options: { meta },
                  },
                }) => <CexConnectedBotsHead meta={meta} />,
                cell: ({
                  row,
                  table: {
                    options: { meta },
                  },
                }) => <CexConnectedBotsCell meta={meta} row={row} />,
              }
            : null,
        ] as ColumnDef<ICexAccounts>[]
      ).filter(el => el !== null),
    [cexPair, isAdmin],
  );

  const table = useReactTable({
    data: useMemo(() => records ?? [], [records]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    meta: {
      selectedAccounts,
      records,
      setRecords,
    },
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      loadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      loadMore();
    }
  }, [rowVirtualizer.virtualItems, hasMore, loading, loadMore, rows.length]);

  useEffect(() => {
    if (rows) {
      setVirtualRecords(virtualRows.map(({ index }) => rows[index].original));
    }
  }, [virtualRows]);

  const updateVisibleRecords = useCallback(async () => {
    const lastSeenId = virtualRecords[0] ? virtualRecords[0].id : undefined;
    const limit = virtualRecords.length;

    if (!lastSeenId || limit === 0 || !records) {
      Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
      return;
    }

    try {
      const startTime = Date.now();

      const { isSuccess, data, errorMessage } = await ApiCexAccount.getCexAccountsList({
        limit,
        lastSeenId,
        cexId: cexPair.id,
      });
      const endTime = Date.now();

      if (isSuccess) {
        const firstSeenIndex = records.findIndex(el => el.id === lastSeenId);
        const newRecords = [...records];

        newRecords.splice(firstSeenIndex, data.items.length, ...data.items);

        if (endTime - startTime >= 1500) {
          Bus.emit(STOP_RELOAD_PREFIX + PROJECT_CEX_ACCOUNTS_RELOAD);
        } else {
          setTimeout(() => {
            Bus.emit(STOP_RELOAD_PREFIX + PROJECT_CEX_ACCOUNTS_RELOAD);
          }, 1500 - (endTime - startTime));
        }
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        Bus.emit(STOP_RELOAD_PREFIX + PROJECT_CEX_ACCOUNTS_RELOAD);
      }
    } catch (error) {
      console.log(error);
      Bus.emit(STOP_RELOAD_PREFIX + PROJECT_CEX_ACCOUNTS_RELOAD);
    }
  }, [virtualRecords, records, cexPair, dispatch]);

  useEffect(() => {
    Bus.on(PROJECT_CEX_ACCOUNTS_RELOAD, updateVisibleRecords);

    return () => {
      Bus.off(PROJECT_CEX_ACCOUNTS_RELOAD, updateVisibleRecords);
    };
  }, [updateVisibleRecords]);

  const closeCreateAccountModal = useCallback(() => {
    setConnectAccountToCexModal(false);
  }, []);

  const openCreateAccountModal = useCallback(() => {
    setConnectAccountToCexModal(true);
  }, []);

  const onSuccessChangedAccounts = useCallback(() => {
    setTriggerRemount(v => !v);
  }, []);

  const handleDeactivateSelectedAccounts = useCallback(async () => {
    const disconnectAccountsList = selectedAccounts.map(selectedAccount => selectedAccount.id);
    dispatch(
      setAlertState({
        type: 'sure',
        text: 'You will deactivate all selected accounts.',
        onClose: () => dispatch(dropAlertState()),
        onSubmit: async () => {
          const isSuccess = await ApiCexAccount.deleteAccountFromCexPair({
            accounts_ids: disconnectAccountsList,
            cexId: cexPair.id,
          });

          if (isSuccess) {
            setRowSelection({});
            setRecords(prevRecords =>
              prevRecords?.filter(prevRecord => !disconnectAccountsList.includes(prevRecord.id)),
            );
          }
          dispatch(dropAlertState());
        },
      }),
    );
  }, [selectedAccounts, dispatch, cexPair, setRecords]);

  return (
    <>
      <TableToolbar
        customActions={
          <div className="accounts-panel-wallets-table-toolbar__actions">
            {isAdmin && (
              <>
                {selectedAccounts.length >= 1 && (
                  <>
                    <TableSelectedCaption
                      selected={selectedAccounts.length}
                      totalRecords={records?.length ?? 0}
                    />
                    <TableAction
                      iconLeft={<TableActionIcon icon={ExitIcon} />}
                      text={'Deactivate'}
                      onClick={handleDeactivateSelectedAccounts}
                    />
                  </>
                )}
                <TableAction
                  iconLeft={<TableActionIcon icon={PlusIcon} />}
                  text={'Connect account to pair'}
                  onClick={openCreateAccountModal}
                />
              </>
            )}
            {!isAdmin && <TableTotalCaption totalRecords={records?.length ?? 0} />}
          </div>
        }
      />
      <div ref={tableContainerRef} className="accounts-table-container scrollable">
        <table className="mm-common-table w-full">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map(virtualRow => {
              const row = rows[virtualRow.index];

              return (
                <tr key={virtualRow.index}>
                  {row ? (
                    row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id} style={{ height: virtualRow.size }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })
                  ) : (
                    <td style={{ height: virtualRow.size }}>-</td>
                  )}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
        {records === undefined && !loading && (
          <div className="mm-common-table-no-info">
            {'You get an error while trying to get wallets records. Try to refresh your page...'}
          </div>
        )}
        {records && records.length === 0 && (
          <div className="mm-common-table-no-info">
            {
              'You don\'t have any accounts yet. Please create your first by clicking on "Create new account" button'
            }
          </div>
        )}
      </div>
      {connectAccountToCexModal && (
        <ConnectAccountToCexPairModal
          onOpen={openCreateAccountModal}
          onClose={closeCreateAccountModal}
          onSuccess={onSuccessChangedAccounts}
        />
      )}
    </>
  );
};

export { CexAccountsTable };
