import React, { useCallback, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedDispatch, useTypedSelector } from 'store';

import { Button } from 'ui';
import { IDextWTOrganicVolumeTask } from 'types/bots';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { DexWTOrganicTaskModal } from 'modals/DexWTOrganicTaskModal';
import { formatToken } from 'utils/formats';

import './style.scss';

interface IRowInfoProps {
  task: IDextWTOrganicVolumeTask;
  rowIdx: number;
}

const RowInfo: React.FC<IRowInfoProps> = ({ task, rowIdx }) => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { currentConfig } = useContext(PairWashTradingContext);

  const [editModalOpened, setEditModaOpened] = useState<boolean>(false);

  const handleOpenDeleteTaskModal = useCallback(() => {
    dispatch(
      setAlertState({
        type: 'sure',
        text: 'Are you sure that you want to delete task?',
        onClose: () => dispatch(dropAlertState()),
        onSubmit: () => {
          let newTasks = [
            ...(currentConfig.get ? currentConfig.get.config?.organic_volumes_tasks ?? [] : []),
          ];

          newTasks = newTasks.filter((_, idx) => idx !== rowIdx);

          currentConfig.set(
            currentConfig.get
              ? {
                  ...currentConfig.get,
                  config: {
                    ...currentConfig.get.config,
                    organic_volumes_tasks: newTasks,
                  },
                }
              : undefined,
          );

          dispatch(dropAlertState());
        },
      }),
    );
  }, [currentConfig, rowIdx, dispatch]);

  const onEditTask = useCallback(
    (task: IDextWTOrganicVolumeTask) => {
      const newTasks = currentConfig.get?.config.organic_volumes_tasks ?? [];
      newTasks[rowIdx] = task;

      currentConfig.set(
        currentConfig.get
          ? {
              ...currentConfig.get,
              config: {
                ...currentConfig.get.config,
                organic_volumes_tasks: newTasks,
              },
            }
          : undefined,
      );

      dispatch(
        setAlertState({
          type: 'success',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
          text: 'You have successfully edited task!',
        }),
      );
    },
    [rowIdx, currentConfig, dispatch],
  );

  return (
    <motion.div
      key={`extended-row-${rowIdx}`}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      className="mm-dex-wt-organic-volume-row-extended-info"
    >
      <div className="line-separator" />
      <div className="sections">
        <span className="section-title">Settings</span>
        <div className="section">
          <div className="grid content-start gap-2">
            <span>Indicator [Per]: {task.options.indicator_period}</span>
            <span>
              Min transactions amount:{' '}
              {formatToken(
                BigNumber.from(task.options.min_transaction_amount_base),
                dexPair.token_base.decimals,
              )}{' '}
              {dexPair.token_base.symbol}
            </span>
          </div>
          <div className="grid content-start gap-2">
            <span>Amount dev per trade: {task.options.per_transaction_amount_dev}</span>
            <span>
              Amount threshold percentage: {task.options.stop_volume_threshold_percentage}%
            </span>
          </div>
        </div>
      </div>
      <div className="line-separator" />
      <div className="extended-row__footer">
        <div className="flex flex-row items-center gap-3">
          <Button
            color="secondary"
            className="edit-task-button"
            onClick={() => setEditModaOpened(true)}
          >
            Edit task
          </Button>
          <Button
            color="error-bordered"
            className="delete-task-button"
            onClick={handleOpenDeleteTaskModal}
          >
            Delete task
          </Button>
        </div>
      </div>
      {editModalOpened && (
        <DexWTOrganicTaskModal
          mode="edit"
          onClose={() => setEditModaOpened(false)}
          task={task}
          onSubmit={onEditTask}
        />
      )}
    </motion.div>
  );
};

export { RowInfo };
