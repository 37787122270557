import React from 'react';

import { IIconProps } from './types';

const CopyIcon: React.FC<IIconProps> = ({ className, color = '#7F91BB', onClick }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M12.3349 2.66797H5.66661C5.4015 2.66797 5.14725 2.77328 4.95979 2.96074C4.77233 3.14821 4.66702 3.40246 4.66702 3.66757V4.66717H3.66635C3.53499 4.66717 3.40492 4.69306 3.28357 4.74336C3.16222 4.79366 3.05198 4.86739 2.95915 4.96032C2.86631 5.05326 2.7927 5.16358 2.74253 5.28498C2.69236 5.40638 2.66661 5.53648 2.66675 5.66783V12.3361C2.66675 12.6012 2.77206 12.8555 2.95952 13.0429C3.14699 13.2304 3.40124 13.3357 3.66635 13.3357H10.3346C10.5997 13.3357 10.854 13.2304 11.0414 13.0429C11.2289 12.8555 11.3342 12.6012 11.3342 12.3361V11.3354H12.3338C12.5989 11.3354 12.8532 11.2301 13.0406 11.0427C13.2281 10.8552 13.3334 10.6009 13.3334 10.3358V3.66757C13.3334 3.40264 13.2282 3.14855 13.041 2.96112C12.8538 2.77369 12.5998 2.66825 12.3349 2.66797V2.66797ZM10.2097 12.3361H3.79237C3.75923 12.3361 3.72745 12.3229 3.70401 12.2995C3.68058 12.2761 3.66742 12.2443 3.66742 12.2112V5.79385C3.66742 5.76071 3.68058 5.72893 3.70401 5.7055C3.72745 5.68207 3.75923 5.6689 3.79237 5.6689H4.66702V10.3358C4.66702 10.6009 4.77233 10.8552 4.95979 11.0427C5.14725 11.2301 5.4015 11.3354 5.66661 11.3354H10.3346V12.2101C10.3348 12.2266 10.3316 12.2429 10.3254 12.2582C10.3192 12.2735 10.31 12.2874 10.2984 12.2991C10.2868 12.3108 10.273 12.3201 10.2577 12.3265C10.2425 12.3328 10.2262 12.3361 10.2097 12.3361ZM12.2099 10.3358H5.79263C5.75949 10.3358 5.72771 10.3227 5.70428 10.2992C5.68085 10.2758 5.66768 10.244 5.66768 10.2109V3.79359C5.66754 3.77709 5.67067 3.76072 5.67689 3.74544C5.6831 3.73016 5.69228 3.71626 5.7039 3.70454C5.71552 3.69283 5.72934 3.68353 5.74457 3.67718C5.7598 3.67083 5.77613 3.66757 5.79263 3.66757H12.2099C12.2263 3.66757 12.2426 3.6708 12.2577 3.67708C12.2729 3.68336 12.2867 3.69256 12.2983 3.70417C12.3099 3.71577 12.3191 3.72954 12.3254 3.7447C12.3317 3.75986 12.3349 3.77611 12.3349 3.79252V10.2109C12.3349 10.244 12.3217 10.2758 12.2983 10.2992C12.2749 10.3227 12.2431 10.3358 12.2099 10.3358Z"
        fill={color}
      />
    </svg>
  );
};

export { CopyIcon };
