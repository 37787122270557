import React, { useMemo } from 'react';

import { ICexName, IDexName } from 'types';
import { exchangesMapping } from 'constants/logos';

import './style.scss';

interface IPlatformIconProps {
  platform: IDexName | ICexName;
  isIcon?: boolean;
  className?: string;
}

const PlatformIcon: React.FC<IPlatformIconProps> = ({ platform, isIcon, className }) => {
  const iconTradingPair = useMemo(
    () => exchangesMapping({ isIcon: !!isIcon, name: platform }),

    [isIcon, platform],
  );

  return <img className={className} alt={iconTradingPair?.alt} src={iconTradingPair?.src} />;
};

export default PlatformIcon;
