import { useCallback } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3React } from '@web3-react/core';

import { useERC20Contract } from 'hooks';
import {
  ETH_MAIN_ASSET,
  BSC_MAIN_ASSET,
  POLYGON_MAIN_ASSET,
  ARBITRUM_MAIN_ASSET,
} from 'types/web3';

const useERC20Deposit = (tokenAddress: string) => {
  const erc20Contract = useERC20Contract(tokenAddress);
  const { library, account } = useWeb3React();

  const deposit = useCallback(
    async ({ walletAddress, amount }: { walletAddress: string; amount: BigNumber }) => {
      if (!erc20Contract) return;

      const isContractWithMainAsset =
        tokenAddress === ETH_MAIN_ASSET ||
        tokenAddress === BSC_MAIN_ASSET ||
        tokenAddress === POLYGON_MAIN_ASSET ||
        tokenAddress === ARBITRUM_MAIN_ASSET;

      //TODO extend contract types
      try {
        const result = isContractWithMainAsset
          ? await library
              .getSigner(account)
              .connectUnchecked()
              .sendTransaction({ to: walletAddress, value: amount })
          : await erc20Contract.transfer(walletAddress, amount);

        return result;
      } catch (error) {
        console.log(error);
      }
    },
    [erc20Contract, tokenAddress, account, library],
  );

  return {
    deposit,
  };
};

export { useERC20Deposit };
