import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';
import { formatToken } from 'utils/formats';

interface ISerieVolumeCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const SerieVolumeCell: React.FC<ISerieVolumeCellProps> = ({ task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const minVolumeBN = useMemo(() => BigNumber.from(task.min_volume), [task]);
  const maxVolumeBN = useMemo(() => BigNumber.from(task.max_volume), [task]);

  const decimals = dexPair.token_base.decimals;

  return (
    <div>
      {formatToken(minVolumeBN, decimals)} / {formatToken(maxVolumeBN, decimals)}{' '}
      {dexPair.token_base.symbol}
    </div>
  );
};

export { SerieVolumeCell };
