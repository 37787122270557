import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { PairPageWrapper } from 'ui';
import { ECexPairPanel, IPanelProps } from 'types/pairs';
import { usePairConfig } from 'hooks';

import { CexAccounts } from './CexAccounts';
import { CexFillTheGaps } from './CexFillTheGaps';
import { CexStatistic } from './CexStatistic';
import { CexWashAlgorithm } from './CexWashAlgorithm';
import { Charts } from './Charts';
import { OrderHistory } from './OrderHistory';

const CexPair: React.FC = () => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair);
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const { getPairConfig, setPairConfig } = usePairConfig();
  const pairId = useMemo(() => cexPair?.id ?? 0, [cexPair]);

  const pairConfig = useMemo(() => getPairConfig(pairId), [getPairConfig, pairId]);

  const panels = useMemo(() => {
    const adminPanel = [ECexPairPanel.wt_bot, ECexPairPanel.fg_bot];

    const panelValues = Object.values(ECexPairPanel).filter(
      el => isAdmin || !adminPanel.includes(el),
    );

    return panelValues.map(value => {
      const props: IPanelProps = {
        initialOpened: pairConfig.lastOpenedPanel === value,
        setIsOpened: (opened: boolean) =>
          setPairConfig(pairId, { ...pairConfig, lastOpenedPanel: opened ? value : undefined }),
      };

      const mapper: Record<ECexPairPanel, React.ReactNode> = {
        [ECexPairPanel.charts]: <Charts key="charts" {...props} />,
        [ECexPairPanel.statistics]: <CexStatistic key="statistic" {...props} />,
        [ECexPairPanel.accounts]: <CexAccounts key="accounts" {...props} />,
        [ECexPairPanel.wt_bot]: <CexWashAlgorithm key="wash-trading" {...props} />,
        [ECexPairPanel.fg_bot]: <CexFillTheGaps key="fill-gaps" {...props} />,
        [ECexPairPanel.order_history]: <OrderHistory key="order-history" {...props} />,
      };

      return mapper[value];
    });
  }, [isAdmin, pairConfig, setPairConfig, pairId]);

  if (!cexPair) return null;

  return <PairPageWrapper>{panels}</PairPageWrapper>;
};

export { CexPair };
