import React, { HTMLAttributes } from 'react';

import './style.scss';

interface IStatisticHeaderProps extends HTMLAttributes<HTMLTableHeaderCellElement> {
  title: string;
  colSpan?: number | undefined;
}

const StatisticHeader: React.FC<IStatisticHeaderProps> = React.memo(
  ({ title, colSpan, ...rest }) => {
    return (
      <th {...rest} colSpan={colSpan}>
        <div className="mm-statistic-table-header">{title}</div>
      </th>
    );
  },
);

export default StatisticHeader;
