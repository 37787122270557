import React from 'react';

import './style.scss';

interface IChartNotFoundProps {
  text: string;
}

const ChartNotFound: React.FC<IChartNotFoundProps> = ({ text }) => {
  return (
    <div className="mm-pair-chart-not-found">
      <span className="caption">{text}</span>
    </div>
  );
};

export { ChartNotFound };
