import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';

import { HeaderProject } from '../HeaderProject/HeaderProject';
import { HeaderPair } from '../HeaderPair';

const PageHeaderPiece: React.FC = () => {
  const { pathname } = useLocation();
  const [headerType, setHeaderType] = useState<'header-project' | 'header-pair' | null>(null);

  useEffect(() => {
    const changeHeaderTypeRelateToPathname = () => {
      const matchDexPair = matchPath({ path: `/project/:projectId/dex-pair/:pairId` }, pathname);
      const matchCexPair = matchPath({ path: `/project/:projectId/cex-pair/:pairId` }, pathname);
      if (matchPath({ path: '/project/:projectId' }, pathname)) {
        return setHeaderType('header-project');
      }
      if (matchDexPair || matchCexPair) {
        return setHeaderType('header-pair');
      }

      return setHeaderType(null);
    };

    changeHeaderTypeRelateToPathname();
  }, [pathname]);

  return (
    <>
      {headerType === 'header-project' && <HeaderProject />}
      {headerType === 'header-pair' && <HeaderPair />}
    </>
  );
};

export { PageHeaderPiece };
