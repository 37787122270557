import React, { ErrorInfo } from 'react';

interface IError {
  message: string;
  stack?: string;
}

interface IErrorBoundaryProps {
  children: React.ReactNode;
}

interface IErrorBoundaryState {
  error: IError | undefined;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  state: IErrorBoundaryState = {
    error: undefined,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.dir({ error, errorInfo });
    this.setState({ error: { message: error.message, stack: errorInfo.componentStack } });
  }

  render(): React.ReactNode {
    return <>{this.props.children}</>;
  }
}

export { ErrorBoundary };
