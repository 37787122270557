import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { last } from 'lodash';

import { LocalStorage } from 'tools';
import { IDexPairFilters, IDexPairsFilters } from 'types/filters';
import { MAX_CACHED_PAIRS, DEX_PAIR_FILTERS_KEY } from 'constants/cache';

export const setupInitialDexPairFiltersReducer: CaseReducer<
  IDexPairsFilters,
  PayloadAction<{ pairId: number; filters: { updated_at: string; filters: IDexPairFilters } }>
> = (state, { payload: { filters, pairId } }) => {
  const updatedState = { ...state, [pairId]: filters };

  state[pairId] = filters;

  const pairStates = Object.keys(updatedState);

  if (pairStates.length <= MAX_CACHED_PAIRS) {
    LocalStorage.set(DEX_PAIR_FILTERS_KEY, state);

    return;
  }

  const sortedByUpdatedAt = pairStates
    .map(key => ({
      pairId: Number(key),
      updatedAt: updatedState[Number(key)]!.updated_at,
    }))
    .sort((a, b) => {
      const dateA = new Date(a.updatedAt).valueOf();
      const dateB = new Date(b.updatedAt).valueOf();

      if (dateA > dateB) return -1;
      if (dateB < dateA) return 1;
      return 0;
    });

  state[last(sortedByUpdatedAt)!.pairId] = undefined;

  LocalStorage.set(DEX_PAIR_FILTERS_KEY, state);
};
