import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import { DEFAULT_REFRESH_INTERVAL } from 'constants/tables';
import { STOP_RELOAD_PREFIX } from 'constants/reload';
import { Bus } from 'tools';
import { usePairConfig } from 'hooks';

import { RefreshIcon } from 'assets/icons';
import './style.scss';

type ICacheName =
  | 'statistic'
  | 'wallets'
  | 'transactions'
  | 'cex-accounts'
  | 'cex-statistic'
  | 'cex-order-history';

interface IAutoRefreshProps {
  busEvent: string;
  tooltipText?: string;
  pairId?: number;
  cacheName?: ICacheName;
}

const AutoRefresh: React.FC<IAutoRefreshProps> = ({ busEvent, tooltipText, pairId, cacheName }) => {
  const { getPairConfig, setPairConfig } = usePairConfig();

  const cacheNameToValue = useCallback(
    (cacheName?: ICacheName) => {
      if (pairId === undefined || !cacheName) return false;

      const pairValue = getPairConfig(pairId);

      if (cacheName === 'statistic') return !!pairValue.statisticAutorefresh;
      if (cacheName === 'wallets') return !!pairValue.walletsAutorefresh;
      if (cacheName === 'transactions') return !!pairValue.transactionsAutorefresh;
      if (cacheName === 'cex-accounts') return !!pairValue.cexAccountsAutorefresh;
      if (cacheName === 'cex-statistic') return !!pairValue.cexStatisticAutorefresh;

      return false;
    },
    [getPairConfig, pairId],
  );

  const handleSetPairConfigValue = useCallback(
    (value: boolean) => {
      if (pairId === undefined || !cacheName) return;

      const pairValue = getPairConfig(pairId);

      if (cacheName === 'statistic') {
        pairValue.statisticAutorefresh = value;
      }
      if (cacheName === 'wallets') {
        pairValue.walletsAutorefresh = value;
      }
      if (cacheName === 'transactions') {
        pairValue.transactionsAutorefresh = value;
      }
      if (cacheName === 'cex-accounts') {
        pairValue.cexAccountsAutorefresh = value;
      }
      if (cacheName === 'cex-statistic') {
        pairValue.cexStatisticAutorefresh = value;
      }

      setPairConfig(pairId, pairValue);
    },
    [setPairConfig, pairId, cacheName, getPairConfig],
  );

  const [isActive, setIsActive] = useState<boolean>(cacheNameToValue(cacheName));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const refreshIntervalRef = useRef<ReturnType<typeof setInterval>>();

  const id = useMemo(() => uuid(), []);

  useEffect(() => {
    if (isActive) {
      Bus.emit(busEvent);
      setIsLoading(true);

      refreshIntervalRef.current = setInterval(() => {
        Bus.emit(busEvent);
        setIsLoading(true);
      }, DEFAULT_REFRESH_INTERVAL);
    }

    return () => {
      clearInterval(refreshIntervalRef.current);
    };
  }, [isActive, busEvent]);

  useEffect(() => {
    const handleDropLoading = () => {
      setIsLoading(false);
    };

    Bus.on(STOP_RELOAD_PREFIX + busEvent, handleDropLoading);

    return () => {
      Bus.off(STOP_RELOAD_PREFIX + busEvent, handleDropLoading);
    };
  }, [busEvent]);

  const handleToggleRefresh = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const newIsActive = !isActive;

      setIsActive(newIsActive);
      handleSetPairConfigValue(newIsActive);
    },
    [isActive, handleSetPairConfigValue],
  );

  return (
    <div className="mm-auto-refresh">
      {tooltipText && (
        <ReactTooltip place="top" id={id} openOnClick={false}>
          {tooltipText}
        </ReactTooltip>
      )}
      <button
        data-tooltip-id={id}
        title={'Autorefresh'}
        className={cn('refresh', {
          _active: isActive,
          _loading: isLoading,
        })}
        onClick={handleToggleRefresh}
      >
        <RefreshIcon />
      </button>
    </div>
  );
};

export { AutoRefresh };
