import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { v4 as uuid } from 'uuid';
import { useTypedSelector } from 'store';

import { TableCheckbox, TableAddressChip } from 'ui';
import { IBalanceBotWallet } from 'api/apiBalanceBot/models';

interface IWalletsCellProps {
  row: Row<IBalanceBotWallet>;
  readOnlyMode: boolean;
}

const WalletsCell: React.FC<IWalletsCellProps> = ({ row, readOnlyMode }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const checkboxId = useMemo(() => uuid(), []);

  return (
    <div className="checkbox-area">
      {!readOnlyMode && (
        <TableCheckbox
          id={checkboxId}
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      )}
      <TableAddressChip
        address={row.original.address}
        network={dexPair.network}
        addressType={'address'}
      />
    </div>
  );
};

export { WalletsCell };
