import { motion } from 'framer-motion';

import { IFilterSchemeExtended, IDexFilterValue } from 'types/filters/common';

import { FilterConnection } from '../FilterConnection';
import { FilterValue } from '../FilterValue';
import { FilterSelectType } from '../FilterSelectType';

import { TrashIcon } from 'assets/icons';

interface IFilterItemProps {
  filter: IDexFilterValue;
  scheme: Record<string, IFilterSchemeExtended>;
  updateFilter: (id: string, filter?: IDexFilterValue) => void;
  deleteFilter: (id: string) => void;
}

function FilterItem({ filter, scheme, updateFilter, deleteFilter }: IFilterItemProps) {
  return (
    <div className="mm-filter-item_container">
      <FilterSelectType filter={filter} scheme={scheme} updateFilter={updateFilter} />
      <FilterConnection filter={filter} type={filter.type} updateFilter={updateFilter} />
      <FilterValue filter={filter} type={filter.type} updateFilter={updateFilter} />
      <motion.button className="delete-filter" onClick={() => deleteFilter(filter.id)}>
        <TrashIcon />
      </motion.button>
    </div>
  );
}

export default FilterItem;
