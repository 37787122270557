import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { IWalletToken } from 'api/apiWallets/models';
import { TOTAL_TOKEN_ID } from 'constants/numbers';
import { Tooltip } from '../../Tooltip/Tooltip';

import './style.scss';
import 'react-tooltip/dist/react-tooltip.css';

interface ITableTokensProps {
  tokens: IWalletToken[] | undefined;
  mainBaseTokenSymbol?: string;
  mainQuoteTokenSymbol?: string;
  align?: 'flex-start' | 'flex-end' | 'center';
}

const TableTokens: React.FC<ITableTokensProps> = ({
  tokens,
  mainBaseTokenSymbol,
  mainQuoteTokenSymbol,
  align = 'flex-start',
}) => {
  const filteredTokens = useMemo(
    () => (!tokens ? [] : tokens.filter(el => el.token.id !== TOTAL_TOKEN_ID)),
    [tokens],
  );

  const capturedTokens = useMemo(() => {
    if (!filteredTokens) return null;

    const mainToken = mainBaseTokenSymbol
      ? filteredTokens.find(el => el.token.symbol === mainBaseTokenSymbol)
      : undefined;
    const quoteToken = mainQuoteTokenSymbol
      ? filteredTokens.find(el => el.token.symbol === mainQuoteTokenSymbol)
      : undefined;

    const result: IWalletToken[] = [];
    if (mainToken) {
      result.push(mainToken);
    }
    if (quoteToken) {
      result.push(quoteToken);
    }

    if (!mainToken && !quoteToken) {
      if (filteredTokens[0]) {
        result.push(filteredTokens[0]);
      }
      if (filteredTokens[1]) {
        result.push(filteredTokens[1]);
      }
    }

    return result;
  }, [filteredTokens]);

  const id = useMemo(() => {
    return uuid();
  }, []);

  if (!tokens) return <div style={{ justifyContent: align }}>{'-'}</div>;

  return (
    <div className="mm-table-tokens" style={{ justifyContent: align }}>
      <span className="caption">
        {filteredTokens?.length}
        {' Tokens '}
        {capturedTokens && capturedTokens.length !== 0
          ? `(${capturedTokens.map(el => el.token.symbol).join('/')}...)`
          : ''}
      </span>

      <Tooltip
        id={id}
        item={`${filteredTokens.map(el => `<span>${el.token.symbol}<span>`).join(', ')}`}
        tooltipEvent="click"
      >
        <span>i</span>
      </Tooltip>
    </div>
  );
};

export { TableTokens };
