import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { v4 as uuid } from 'uuid';
import { useTypedSelector } from 'store';

import { TableCheckbox } from 'ui';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface ICexAccountCellProps {
  row: Row<ICexAccounts>;
}

const NotesAccountCell: React.FC<ICexAccountCellProps> = ({ row }) => {
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const checkboxId = useMemo(() => uuid(), []);

  return (
    <div className="checkbox-area">
      {isAdmin && (
        <TableCheckbox
          id={checkboxId}
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      )}
      {row.original.notes}
    </div>
  );
};

export default NotesAccountCell;
