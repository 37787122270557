import React from 'react';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';

interface IGroupTransactionsCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const GroupTransactionsCell: React.FC<IGroupTransactionsCellProps> = ({ task }) => {
  return (
    <div>
      {task.min_transactions_in_group} / {task.max_transactions_in_group}
    </div>
  );
};

export { GroupTransactionsCell };
