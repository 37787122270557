import React from 'react';
import { Row } from '@tanstack/react-table';

import { TableTokens } from 'ui';
import { IWallet } from 'api/apiWallets/models';

interface ITokenCellProps {
  row: Row<IWallet>;
}

const TokensCell: React.FC<ITokenCellProps> = React.memo(({ row }) => {
  return <TableTokens tokens={row.original.tokens} />;
});

export default TokensCell;
