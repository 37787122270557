import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { v4 as uuid } from 'uuid';

import { TableCheckbox } from 'ui';
import { ICexNotConnectedAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface IApiCellProps {
  row: Row<ICexNotConnectedAccounts>;
}

const ApiCell: React.FC<IApiCellProps> = ({ row }) => {
  const checkboxId = useMemo(() => uuid(), []);

  return (
    <div className="checkbox-area">
      <TableCheckbox
        id={checkboxId}
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
      <div>{row.original.api_key_masked}</div>
    </div>
  );
};

export { ApiCell };
