import { useCallback, useEffect, useState } from 'react';
import { useTypedSelector } from 'store';

import { ApiWithdrawWhitelist } from 'api';
import { Bus } from 'tools';
import { RELOAD_WITHDRAWAL_WHTELIST } from 'constants/reload';

interface IStatus {
  status: 'pending' | 'success';
  pendingCount: number;
}

const useWithdrawalWhitelistStatus = () => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const [status, setStatus] = useState<IStatus | undefined>(undefined);

  const calculateStatus = useCallback(async () => {
    try {
      const { isSuccess, data } = await ApiWithdrawWhitelist.getWithdrawWhitelist({
        limit: 100,
        offset: 0,
        pairsIds: [pair!.id.toString()],
        projectIds: [pair!.project_id.toString()],
      });

      if (isSuccess && data) {
        const pendingCount = (data.items ?? []).filter(
          el => !el.confirmed_at && el.can_confirm,
        ).length;

        setStatus({ status: pendingCount > 0 ? 'pending' : 'success', pendingCount });
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }, []);

  useEffect(() => {
    calculateStatus();

    Bus.on(RELOAD_WITHDRAWAL_WHTELIST, calculateStatus);

    return () => {
      Bus.off(RELOAD_WITHDRAWAL_WHTELIST, calculateStatus);
    };
  }, []);

  return status;
};

export default useWithdrawalWhitelistStatus;
