import React from 'react';

import './projectForm.scss';

interface IProjectFormProps {
  handleSubmit: () => void;
  children: React.ReactNode;
}

const ProjectForm: React.FC<IProjectFormProps> = ({ handleSubmit, children }) => {
  return (
    <form className="mm-project-form" onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export { ProjectForm };
