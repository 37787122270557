import React, { useMemo, memo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { IDexPair } from 'types/pairs';
import { ITransactionItem, ETransactionAction } from 'api/apiTransactions/models';
import { divideBignumbers, multiplyBignumbers } from 'utils/formulas';
import { formatToken, formatFiat } from 'utils/formats';

interface ITransactionPriceProps {
  pair: IDexPair;
  row: ITransactionItem;
}

export const TransactionPrice: React.FC<ITransactionPriceProps> = memo(({ pair, row }) => {
  const { action } = useMemo(() => row, [row]);

  const transfers = useMemo(() => row.transfers ?? [], [row]);

  const baseTokenTransfer = useMemo(
    () => transfers.find(transfer => transfer.token.id === pair.token_base.id),
    [transfers, pair.token_base.id],
  );
  const quoteTokenTransfer = useMemo(
    () => transfers.find(transfer => transfer.token.id === pair.token_quote.id),
    [transfers, pair.token_quote.id],
  );

  const priceUsd = useMemo(
    () =>
      multiplyBignumbers(
        [
          divideBignumbers(
            [
              BigNumber.from(quoteTokenTransfer ? quoteTokenTransfer.amount.replace('-', '') : '0'),
              quoteTokenTransfer?.token.decimals ?? 18,
            ],
            [
              BigNumber.from(baseTokenTransfer ? baseTokenTransfer.amount.replace('-', '') : '0'),
              baseTokenTransfer?.token.decimals ?? 18,
            ],
          ),
          18,
        ],
        [
          divideBignumbers(
            [
              BigNumber.from(
                quoteTokenTransfer ? quoteTokenTransfer.amount_usd.replace('-', '') : '0',
              ),
              6,
            ],
            [
              BigNumber.from(
                baseTokenTransfer ? baseTokenTransfer.amount_usd.replace('-', '') : '0',
              ),
              6,
            ],
          ),
          18,
        ],
      ),
    [baseTokenTransfer, quoteTokenTransfer],
  );

  const price = useMemo(
    () =>
      divideBignumbers(
        [
          BigNumber.from(quoteTokenTransfer ? quoteTokenTransfer.amount : '0'),
          quoteTokenTransfer?.token.decimals ?? 18,
        ],
        [
          BigNumber.from(baseTokenTransfer ? baseTokenTransfer.amount : '0'),
          baseTokenTransfer?.token.decimals ?? 18,
        ],
      ),
    [quoteTokenTransfer, baseTokenTransfer],
  );

  const priceString = useMemo(() => formatToken(price, 18).replace('-', ''), [price]);

  if (
    action !== ETransactionAction.ActionBuy &&
    action !== ETransactionAction.ActionBulkBuy &&
    action !== ETransactionAction.ActionBulkBuyMempool &&
    action !== ETransactionAction.ActionAFRBuy &&
    action !== ETransactionAction.ActionSell &&
    action !== ETransactionAction.ActionBulkSell &&
    action !== ETransactionAction.ActionBulkSellMempool &&
    action !== ETransactionAction.ActionAFRSell
  )
    return <>{''}</>;

  if (!transfers || transfers.length === 0 || !pair) return <>{''}</>;

  if (!baseTokenTransfer || !quoteTokenTransfer) return <>{''}</>;

  return (
    <div style={{ textAlign: 'left' }}>
      {priceString} / {formatFiat(priceUsd)}
    </div>
  );
});
