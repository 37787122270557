import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { capitalize } from 'lodash';

import { useTypedSelector } from 'store';
import { PanelNoInfo, Spinner } from 'ui';
import { ExtendedTr } from './ExtendedTr/ExtendedTr';
import { ITransactionItem } from 'api/apiTransactions/models';
import { ENetwork } from 'types';
import { ETransactionsStatuses } from 'api/apiTransactions/models/ITransactionItem';
import {
  Time,
  timeAccessor,
  Hash,
  hashAccessor,
  Wallet,
  walletAccessor,
  TransactionAmount,
  TransactionAsset,
  TransactionPrice,
  TransactionFee,
} from './columns';
import { StaticTable, TBody } from '../../common';
import { useRecords } from './useRecords';

import './transactionsTable.scss';

const DexPairTransactionsTable: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const [extendedTransactionId, setExtendedTransactionId] = useState<null | number>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { records, handleLoadMore, hasMore, loading } = useRecords();

  const handleSetExtendedTransactionId = useCallback((id: number) => {
    setExtendedTransactionId(previousTransactionId => {
      if (previousTransactionId === id) return null;

      return id;
    });
  }, []);

  const columns = useMemo(
    () =>
      (
        [
          {
            header: () => <div style={{ textAlign: 'left' }}>Time</div>,
            id: 'time',
            accessorFn: timeAccessor,
            cell: ({ getValue }) => <Time time={getValue() as string} />,
            size: 95,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Hash</div>,
            id: 'transaction-hash',
            accessorFn: hashAccessor,
            cell: ({ getValue }) => {
              const { hash, network, status } = getValue() as {
                hash: string;
                network: ENetwork;
                status: ETransactionsStatuses;
              };

              return <Hash hash={hash} network={network} status={status} />;
            },
            size: 160,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Wallet</div>,
            id: 'transaction-wallet',
            accessorFn: row => walletAccessor(row, dexPair),
            cell: ({ getValue }) => {
              const data = getValue() as { address: string; network: ENetwork } | null;

              return <Wallet address={data?.address} network={data?.network} />;
            },
            size: 130,
          },
          {
            header: () => <div style={{ textAlign: 'center' }}>Action</div>,
            id: 'transaction-action',
            accessorKey: 'action',
            cell: ({ getValue }) => (
              <div style={{ textAlign: 'center', wordBreak: 'break-all' }} className="direction">
                <span
                  className={cn({
                    green: (getValue() as string) === 'buy',
                    red: (getValue() as string) === 'sell',
                  })}
                >
                  {capitalize(getValue() as string)}
                </span>
              </div>
            ),
            size: 110,
          },
          isAdmin
            ? {
                header: () => <div style={{ textAlign: 'center' }}>User</div>,
                id: 'transaction-user',
                accessorFn: row => row.user?.login ?? 'system',
                cell: ({ getValue }) => (
                  <div style={{ textAlign: 'center', wordBreak: 'break-all' }}>
                    {getValue() as string}
                  </div>
                ),
                minSize: 70,
              }
            : null,
          {
            header: () => <div style={{ textAlign: 'left' }}>Amount</div>,
            id: 'transaction-amount',
            accessorFn: () => ({}),
            cell: ({
              row,
              table: {
                options: { meta },
              },
            }) => {
              const _meta = meta as any;

              const pair = _meta?.pair;

              return <TransactionAmount pair={pair!} row={row.original} />;
            },
            size: 110,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Asset</div>,
            id: 'transaction-asset',
            accessorFn: () => ({}),
            cell: ({ row }) => <TransactionAsset pair={dexPair} row={row.original} />,
            size: 70,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Price</div>,
            id: 'transaction-price',
            accessorFn: () => ({}),
            cell: ({
              row,
              table: {
                options: { meta },
              },
            }) => {
              const _meta = meta as any;

              const pair = _meta?.pair;

              return <TransactionPrice pair={pair!} row={row.original} />;
            },
            size: 100,
          },
          {
            header: () => <div style={{ textAlign: 'left' }}>Fee</div>,
            id: 'transaction-fee',
            accessorFn: () => ({}),
            cell: ({
              row,
              table: {
                options: { meta },
              },
            }) => {
              const _meta = meta as any;

              const pair = _meta?.pair;
              const extendedTransactionId = _meta?.extendedTransactionId;
              const handleSetExtendedTransactionId = _meta?.handleSetExtendedTransactionId;

              if (!handleSetExtendedTransactionId) return null;

              return (
                <TransactionFee
                  pair={pair!}
                  row={row.original}
                  extendedTransactionId={extendedTransactionId ?? null}
                  handleSetExtendedTransactionId={handleSetExtendedTransactionId}
                />
              );
            },
            minSize: 120,
          },
        ] as ColumnDef<ITransactionItem>[]
      ).filter(el => el !== null),
    [dexPair, isAdmin],
  );

  const table = useReactTable({
    data: useMemo(() => records ?? [], [records]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      pair: dexPair,
      extendedTransactionId,
      handleSetExtendedTransactionId,
    },
  });

  const { rows } = table.getRowModel();

  const { virtualItems: virtualRows, totalSize } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 5,
    estimateSize: React.useCallback(() => 57, []),
  });

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse();

    if (!lastItem) {
      handleLoadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      handleLoadMore();
    }
  }, [virtualRows, hasMore, loading, handleLoadMore, rows.length]);

  return (
    <div
      className="mm-pair-transactions-table-container scrollable"
      ref={tableContainerRef}
      onScroll={() => setExtendedTransactionId(null)}
    >
      {!!loading && records === undefined && (
        <div className="table-loading">
          <Spinner size={'medium'} />
        </div>
      )}
      {records && records.length === 0 && (
        <PanelNoInfo title="Not found" text="You don't have any transactions yet" />
      )}
      <div style={{ width: '100%', height: `${totalSize}px` }}>
        <StaticTable className="mm-pair-transactions-table">
          {records && records.length !== 0 && (
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}
          <TBody paddingTop={paddingTop} paddingBottom={paddingBottom}>
            <AnimatePresence>
              {virtualRows.map(virtualRow => {
                const row = rows[virtualRow.index];

                const rowIsExtended = row.original.id === extendedTransactionId;

                return (
                  <React.Fragment key={virtualRow.index}>
                    <tr
                      style={{ height: virtualRow.size }}
                      className={Number(row.id) % 2 === 0 ? 'tr-colorized' : undefined}
                    >
                      {row ? (
                        row.getVisibleCells().map(cell => {
                          return (
                            <td
                              key={cell.id}
                              style={{ height: virtualRow.size, width: cell.column.getSize() }}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          );
                        })
                      ) : (
                        <td style={{ height: virtualRow.size }}>-</td>
                      )}
                    </tr>
                    {rowIsExtended &&
                      row.original.transfers &&
                      row.original.transfers.map(transfer => (
                        <ExtendedTr
                          key={transfer.id}
                          transfer={transfer}
                          network={row.original.network}
                        />
                      ))}
                  </React.Fragment>
                );
              })}
            </AnimatePresence>
          </TBody>
        </StaticTable>
      </div>
    </div>
  );
};

export { DexPairTransactionsTable };
