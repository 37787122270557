import React, { useContext, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import { RangeInput } from 'ui';

import { IDexWTSimulationOptions } from 'types/bots';
import { MIN_TRADE_SIZE, MAX_TRADE_SIZE, DEFAULT_TRADE_SIZE } from 'types/wash-trading-bot';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import useScroll from './useScroll';

import './style.scss';

const TradeSize: React.FC = () => {
  const { tradeSizeCoefficient, currentConfig, chartLoading } = useContext(PairWashTradingContext);

  const { innerCoefficient, scrollPos, setScrollPos } = useScroll({
    coefficient: tradeSizeCoefficient.get,
    setCoefficient: tradeSizeCoefficient.set,
    points: { initial: DEFAULT_TRADE_SIZE, max: MAX_TRADE_SIZE, min: MIN_TRADE_SIZE },
  });

  const coefficient = useMemo(() => tradeSizeCoefficient.get, [tradeSizeCoefficient.get]);
  const [lastUsedCoefficient, setLastUseCoefficient] = useState<number>(coefficient);

  useDebounce(
    () => {
      if (
        currentConfig.get &&
        currentConfig.get.config?.random_strategy &&
        (!lastUsedCoefficient || lastUsedCoefficient !== coefficient)
      ) {
        const newConfig: IDexWTSimulationOptions = { ...currentConfig.get };

        newConfig.config.random_strategy.target_factory.blocks = [
          ...newConfig.config.random_strategy.target_factory.blocks,
        ].map(el => {
          const new_min_price_change_by_trade = el.config.min_price_change_by_trade
            ? Number(
                ((el.config.min_price_change_by_trade * coefficient) / lastUsedCoefficient).toFixed(
                  5,
                ),
              )
            : undefined;
          const new_max_price_change_by_trade = el.config.max_price_change_by_trade
            ? Number(
                ((el.config.max_price_change_by_trade * coefficient) / lastUsedCoefficient).toFixed(
                  5,
                ),
              )
            : undefined;

          return {
            ...el,
            config: {
              ...el.config,
              min_price_change_by_trade: new_min_price_change_by_trade,
              max_price_change_by_trade: new_max_price_change_by_trade,
            },
          };
        });

        setLastUseCoefficient(coefficient);
        currentConfig.set(newConfig);
      }
    },
    100,
    [coefficient, lastUsedCoefficient],
  );

  return (
    <div className="mm-wt-bot-scroll-controller">
      <div className="controller-head">
        <span>Trade size:</span>
        <span>{innerCoefficient.toFixed(4)}</span>
      </div>
      <RangeInput
        value={scrollPos}
        min={0}
        max={100}
        step={1}
        setValue={setScrollPos}
        disabled={!!chartLoading.get}
      />
    </div>
  );
};

export default TradeSize;
