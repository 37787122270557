import React, { useContext } from 'react';

import { PanelLoading, PanelError, PanelNoInfo } from 'ui';
import { PairStatisticContext } from 'context/PairStatisticContext';
import { StatisticTab, StatisticSelectRange } from 'common/statistic';

import {
  GeneralTables,
  AfrAlgorithmTable,
  WTAlgorithmTable,
  BoostHoldersAlgorithmTable,
  BuySellAlgorithmTable,
} from 'common/statistic';

const Content: React.FC = () => {
  const {
    generalLoading,
    selectedRange,
    statisticTokens,
    statisticTransactions,
    firstTradeTime,
    endDate,
    startDate,
    rangeLoading,
  } = useContext(PairStatisticContext);

  if (generalLoading) {
    return <PanelLoading spinnerSize="small" />;
  }

  if (!generalLoading && statisticTokens === undefined) {
    return (
      <PanelError title={'Failed'} text={'Something went wrong... Try to refresh your page.'} />
    );
  }

  if (statisticTokens && statisticTokens.length === 0) {
    return (
      <PanelNoInfo
        title={"You don't have pair statistics yet"}
        text={'Start with adding wallets to pair!'}
      />
    );
  }

  if (statisticTokens) {
    return (
      <div className="mm-pair-statistic-panel">
        <div className="range-container">
          <span className="range-label">Select statistic period:</span>
          <StatisticSelectRange
            selectedRange={selectedRange}
            startDate={startDate}
            endDate={endDate}
            loading={generalLoading || rangeLoading}
          />
        </div>
        <StatisticTab title="General">
          <GeneralTables
            selectedRange={selectedRange.get}
            statisticTokens={statisticTokens}
            statisticTransactions={statisticTransactions || []}
            firstTradeTime={firstTradeTime}
            endDate={endDate.get}
            startDate={startDate.get}
          />
        </StatisticTab>
        <StatisticTab title="Anti Front Run algorithm">
          <AfrAlgorithmTable
            statisticTokens={statisticTokens}
            statisticTransactions={statisticTransactions || []}
          />
        </StatisticTab>
        <StatisticTab title="Wash-trading algorithm">
          <WTAlgorithmTable
            selectedRange={selectedRange.get}
            statisticTokens={statisticTokens}
            statisticTransactions={statisticTransactions || []}
          />
        </StatisticTab>
        <StatisticTab title="Boost holders algorithm">
          <BoostHoldersAlgorithmTable
            statisticTokens={statisticTokens}
            statisticTransactions={statisticTransactions || []}
          />
        </StatisticTab>
        <StatisticTab title="Buy&Sell algorithm">
          <BuySellAlgorithmTable
            statisticTokens={statisticTokens}
            statisticTransactions={statisticTransactions || []}
          />
        </StatisticTab>
      </div>
    );
  }

  return null;
};

export { Content };
