import React, { useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTypedSelector, useTypedDispatch } from 'store';

import { Modal, ErrorText, Button, ButtonLoading } from 'ui';
import { CreateWithdrawWhitelistForm } from 'ui/forms';
import { isAddress } from 'utils';
import { InputField } from 'fields';
import { InsertButton } from 'fields/components';
import { ApiWithdrawWhitelist } from 'api';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { Bus } from 'tools';
import { RELOAD_WITHDRAWAL_WHTELIST } from 'constants/reload';

import './style.scss';

interface IAddWithdrawWhitelistModalProps {
  onClose: () => void;
  onConfirm?: () => void;
}

const AddWithdrawWhitelistModal: React.FC<IAddWithdrawWhitelistModalProps> = ({
  onClose,
  onConfirm,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);

  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const dispatch = useTypedDispatch();

  const handleConfirm = useCallback(
    async ({ addresses }: { addresses: string[] }) => {
      if (!pair) return;

      try {
        setLoading(true);

        const results = await Promise.all(
          addresses.map(el =>
            ApiWithdrawWhitelist.addWithdrawWhitelist({ pair_id: pair.id, wallet_address: el }),
          ),
        );

        if (results.filter(el => el.isSuccess).length === results.length) {
          dispatch(
            setAlertState({
              type: 'success',
              text: 'Congratulations! You have successfully added a new wallets to whitelist',
              onClose: () => {
                dispatch(dropAlertState());
                onClose();
              },
              onSubmit: () => {
                dispatch(dropAlertState());
                onClose();
              },
            }),
          );
          if (onConfirm) {
            Bus.emit(RELOAD_WITHDRAWAL_WHTELIST);
            onConfirm();
          }
        } else {
          const errorMessage = results.find(el => el.errorMessage)?.errorMessage;

          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage ?? 'Something went wrong',
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [onConfirm, pair, dispatch, onClose],
  );

  const { values, errors, handleSubmit, setFieldValue, touched, setTouched } = useFormik({
    initialValues: {
      addresses: [] as string[],
    },
    validationSchema: Yup.object({
      addresses: Yup.array().test(
        'addresses',
        'Please insert at least one valid address',
        (addresses: string[] | undefined) => !!addresses && addresses.length !== 0,
      ),
    }),
    onSubmit: handleConfirm,
  });

  const handleChangeAddresses = useCallback(
    (addressesFromClipboard: string | undefined) => {
      if (!addressesFromClipboard) {
        return;
      }

      setEdited(true);

      const addressesArray = addressesFromClipboard
        .split(/\r?\n/)
        .filter(el => isAddress(el))
        .slice(0, 10)
        .map(el => el.toString());

      setTouched({ ...touched, addresses: true });
      if (addressesArray.length !== 0) {
        setFieldValue('addresses', addressesArray);
      } else {
        setEdited(false);
      }
    },
    [setTouched, setFieldValue, touched],
  );

  const error = useMemo(() => {
    let _error = undefined;
    if (errors.addresses && touched.addresses === true) {
      _error = errors.addresses;
    }

    return _error;
  }, [errors, touched]);

  return (
    <Modal onClose={onClose} edited={edited} title="Add wallets">
      <CreateWithdrawWhitelistForm handleSubmit={handleSubmit}>
        <div className="mm-create-withdraw-whitelist-modal-content">
          <div className="modal-content-form">
            {values.addresses.length === 0 && (
              <>
                <span className="addresses-info">Enter a list of addresses, up to 10</span>
                <InputField
                  placeholder="0x00...000"
                  type="text"
                  value=""
                  readonly
                  nodeRight={<InsertButton onInsert={handleChangeAddresses} />}
                />
              </>
            )}
            {values.addresses.length !== 0 && (
              <div className="addresses-grid scrollable">
                {values.addresses.map((address, index) => (
                  <div className="address-cell" key={index}>
                    #{index + 1} {address}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="modal-content-actions">
            <ErrorText>{error as string}</ErrorText>
            {!loading && (
              <>
                <Button type="submit">Confim</Button>
                <Button color="error-secondary" onClick={onClose}>
                  Cancel
                </Button>
              </>
            )}
            {loading && <ButtonLoading />}
          </div>
        </div>
      </CreateWithdrawWhitelistForm>
    </Modal>
  );
};

export { AddWithdrawWhitelistModal };
