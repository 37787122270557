import React, { useCallback, useEffect, useState, useContext } from 'react';

import { CreateBuySellTaskModal, BotSettingsModal } from 'modals';
import { PairBuySellBotContext } from 'context/PairBuySellBotContext';

import { Button } from 'ui';
import { EDexBot } from 'types/bots';

import { CogIcon } from 'assets/icons';
import './style.scss';

const Toolbar: React.FC = () => {
  const [createNewTaskModalOpened, setCreateNewTaskModalOpened] = useState(false);
  const [botSettingsModalOpened, setBotSettingsModalOpened] = useState(false);
  const { handleLoadBotSettings, botSettings } = useContext(PairBuySellBotContext);

  const handleCreateTask = useCallback(() => {
    setCreateNewTaskModalOpened(true);
  }, []);

  const handleSettingBot = useCallback(() => {
    setBotSettingsModalOpened(true);
  }, []);

  useEffect(() => {
    handleLoadBotSettings();
  }, [handleLoadBotSettings]);

  return (
    <div className="mm-buy-sell-bot-toolbar">
      <div className="mm-buy-sell-bot-toolbar__left">
        <span className="title">All tasks</span>
        <div className="indicators">
          <div className="indicator">
            <div className="round round-green" />
            <span className="indicator__caption">Current</span>
          </div>
          <div className="indicator">
            <div className="round round-purple" />
            <span className="indicator__caption">Future</span>
          </div>
          <div className="indicator">
            <div className="round round-gray" />
            <span className="indicator__caption">Finished</span>
          </div>
        </div>
      </div>
      <div className="mm-buy-sell-bot-toolbar__right">
        <Button color="transparent" onClick={handleCreateTask}>
          + Create new task
        </Button>
        <Button color="transparent" className="settings-button" onClick={handleSettingBot}>
          <CogIcon color={'#5932EA'} />
          <span>Settings</span>
        </Button>
      </div>
      {createNewTaskModalOpened && (
        <CreateBuySellTaskModal
          mode="create"
          onOpen={() => setCreateNewTaskModalOpened(true)}
          onClose={() => setCreateNewTaskModalOpened(false)}
        />
      )}
      {botSettingsModalOpened && (
        <BotSettingsModal
          onSave={handleLoadBotSettings}
          bot={EDexBot.buy_sell_bot}
          buySellBotSettings={botSettings}
          onOpen={() => setBotSettingsModalOpened(true)}
          onClose={() => setBotSettingsModalOpened(false)}
        />
      )}
    </div>
  );
};

export { Toolbar };
