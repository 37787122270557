import React from 'react';

import { IIconProps } from './types';

const SuccessIcon: React.FC<IIconProps> = ({ className, onClick, color = '#64E0A5' }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <circle
        cx="8.00055"
        cy="8.0025"
        r="6.0025"
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.62891 8.23101L7.07417 9.67628L7.06484 9.66694L10.3255 6.40625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { SuccessIcon };
