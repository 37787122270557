import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTypedSelector } from 'store';

import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { WTPresetsModal, WTCreatePresetModal } from 'modals';
import {
  DEFAULT_TRADE_FREQUENCY,
  DEFAULT_TRADE_SIZE,
  DEFAULT_VOLATILITY,
  INTERVAL_DEFAULT,
  PERIOD_DEFAULT,
  SEED_DEFAULT,
} from 'types/wash-trading-bot';

import { CogIcon } from 'assets/icons';
import './presets.scss';
import { SelectField } from 'fields';

const Presets: React.FC = memo(() => {
  const {
    presetsShort,
    currentConfig,
    selectedPresetId,
    handleLoadPreset,
    chartLoading,
    triggerLoadChart,
    initialConfig,
    initialOptions,
    tradeFrequencyCoefficient,
    tradeSizeCoefficient,
    volatilityCoefficient,
  } = useContext(PairWashTradingContext);
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const [presetsModalOpened, setPresetsModalOpened] = useState<boolean>(false);
  const [createPresetModalOpened, setCreatePresetModalOpened] = useState<boolean>(false);

  const togglePresetsModalOpened = useCallback(() => {
    setPresetsModalOpened(v => !v);
  }, []);

  const onSelectPreset = useCallback(
    async (id: number) => {
      selectedPresetId.set(id);

      if (id === -1) {
        currentConfig.set(initialConfig.get);
        tradeSizeCoefficient.set(initialOptions.get?.trade_size_coefficient ?? DEFAULT_TRADE_SIZE);
        tradeFrequencyCoefficient.set(
          initialOptions.get?.trade_frequency_coefficient ?? DEFAULT_TRADE_FREQUENCY,
        );
        volatilityCoefficient.set(initialOptions.get?.volatility_coefficient ?? DEFAULT_VOLATILITY);
      } else {
        const presetConfig = await handleLoadPreset(id);

        if (presetConfig) {
          currentConfig.set({
            config: {
              random_strategy: { ...presetConfig.random_strategy },
              volumes_strategy: presetConfig.volumes_strategy
                ? { ...presetConfig.volumes_strategy }
                : undefined,
            },
            interval: INTERVAL_DEFAULT,
            seed: SEED_DEFAULT,
            period: PERIOD_DEFAULT,
          });
          tradeFrequencyCoefficient.set(DEFAULT_TRADE_FREQUENCY);
          tradeSizeCoefficient.set(DEFAULT_TRADE_SIZE);
          volatilityCoefficient.set(DEFAULT_VOLATILITY);
        } else {
          currentConfig.set(undefined);
          tradeFrequencyCoefficient.set(DEFAULT_TRADE_FREQUENCY);
          tradeSizeCoefficient.set(DEFAULT_TRADE_SIZE);
          volatilityCoefficient.set(DEFAULT_VOLATILITY);
        }
      }
      triggerLoadChart.set(v => !v);
    },
    [initialConfig.get, initialOptions.get],
  );

  const presetsList = useMemo(() => {
    const initialConfigItem = { name: 'Current config', id: -1 };

    if (!presetsShort && !initialConfigItem) return [];

    if (presetsShort) {
      if (initialConfigItem) return [initialConfigItem].concat(presetsShort);

      return presetsShort;
    }
  }, [initialConfig, presetsShort]);

  useEffect(() => {
    onSelectPreset(-1);
  }, [initialConfig.get]);

  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key="wt-bot-presets-bar"
          className="mm-wt-bot-presets-bar"
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: 'auto',
            opacity: 1,
            transition: {
              height: {
                duration: 0.4,
              },
              opacity: {
                duration: 0.25,
                delay: 0.15,
              },
            },
          }}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              height: {
                duration: 0.4,
              },
              opacity: {
                duration: 0.25,
              },
            },
          }}
        >
          <SelectField
            label="Preset"
            placeholder="Select preset"
            items={presetsList ?? []}
            itemToString={item => item.name}
            disabled={!!chartLoading.get}
            selectedItem={
              presetsList ? presetsList.find(el => el.id === selectedPresetId.get) : undefined
            }
            onSelectItem={item => (item ? onSelectPreset(item.id) : undefined)}
          />
          {isAdmin && (
            <motion.div
              whileHover={{ scale: 1.3, rotate: 180 }}
              onClick={togglePresetsModalOpened}
              className="mm-wt-bot-presets-cog"
            >
              <CogIcon />
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
      {presetsModalOpened && (
        <WTPresetsModal
          onClose={togglePresetsModalOpened}
          onOpenCreateModal={() => {
            setCreatePresetModalOpened(true);
            togglePresetsModalOpened();
          }}
        />
      )}
      {createPresetModalOpened && (
        <WTCreatePresetModal onClose={() => setCreatePresetModalOpened(false)} />
      )}
    </>
  );
});

export default Presets;
