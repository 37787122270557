import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { Modal, Button } from 'ui';
import { CreateTaskForm } from 'ui/forms';
import { DateField, SelectField, DurationField, InputField } from 'fields';

import { IDextWTOrganicVolumeTask } from 'types/bots';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { durationToMs } from 'utils/duration';
import { calculateATRs } from 'utils/calculates';
import { multiplyBignumbers } from 'utils/formulas';
import { dexDailyFees } from 'utils/daily';
import { formatFiat, formatToken, normalizeNumber } from 'utils/formats';
import { useDexCandlesByTimeframe } from 'hooks';
import { ATR_PERIOD } from 'constants/numbers';
import { useOrganicTask } from './useOrganicTask';

import './style.scss';

interface IDexWTOrganicTaskModalProps {
  onClose: () => void;
  mode: 'create' | 'edit';
  task?: IDextWTOrganicVolumeTask;
  onSubmit: (task: IDextWTOrganicVolumeTask) => void;
}

const DexWTOrganicTaskModal: React.FC<IDexWTOrganicTaskModalProps> = ({
  mode,
  onClose,
  onSubmit,
  task,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { edited, errors, handleSubmit, setFieldValue, touched, values } = useOrganicTask({
    mode,
    onClose,
    onSubmit,
    task,
  });

  const { candles } = useDexCandlesByTimeframe({
    dexPair: useMemo(() => dexPair, [dexPair]),
    period: ATR_PERIOD,
    timeframes: useMemo(() => (values.time_frame ? [values.time_frame] : []), [values.time_frame]),
  });

  const sequence = useMemo(
    () => (values.time_frame ? candles[values.time_frame] ?? [] : []),
    [values.time_frame, candles],
  );

  const ATRS = useMemo(() => calculateATRs({ candles: sequence, period: ATR_PERIOD }), [sequence]);

  const dailyVolume = useMemo(() => {
    if (!values.time_frame || isNaN(Number(values.min_volume)) || isNaN(Number(values.max_volume)))
      return BigNumber.from(0);

    const avgVolume = (Number(values.max_volume) + Number(values.min_volume)) / 2;

    const dailyVolumeNumber =
      ATRS.reduce((acc, val) => acc + val * avgVolume, 0) *
      ((24 * 60 * 60 * 1000) /
        (durationToMs(values.time_frame) * Math.abs(sequence.length - ATR_PERIOD)));

    const normalizedNumber = normalizeNumber(dailyVolumeNumber);

    return parseUnits(normalizedNumber, dexPair.token_base.decimals);
  }, [values.time_frame, values.min_volume, values.max_volume, ATRS, sequence.length, dexPair]);

  const dailyVolumeUsd = useMemo(() => {
    return multiplyBignumbers(
      [dailyVolume, dexPair.token_base.decimals],
      [BigNumber.from(dexPair.token_base.price_usd), 6],
    );
  }, [dailyVolume, dexPair]);

  const dailyTxs = useMemo(() => {
    const avgTxs = Math.floor(
      (+(values.min_transactions_in_series ?? '0') + +(values.max_transactions_in_series ?? '0')) /
        2,
    );

    const avgPause =
      (values.max_pause_between_series ?? 0 + (values.min_pause_between_series ?? 0)) / 2;
    const dailySeries = avgPause === 0 ? 0 : Math.floor((24 * 60 * 60 * 1000) / avgPause);

    return dailySeries * avgTxs;
  }, [
    values.min_transactions_in_series,
    values.max_transactions_in_series,
    values.min_pause_between_series,
    values.max_pause_between_series,
  ]);

  const dailyFee = useMemo(
    () => dexDailyFees({ daily_txs: dailyTxs, daily_volume: dailyVolume, dexPair }),
    [dailyTxs, dailyVolume, dexPair],
  );

  return (
    <Modal
      title={`${capitalize(mode)} task (regular volume)`}
      edited={edited}
      className="mm-dex-wt-bot-organic-task-modal"
      onClose={onClose}
    >
      <CreateTaskForm handleSubmit={handleSubmit}>
        <div className="form-inner scrollable">
          <div className="form-container">
            <DateField
              date={values.start_at}
              label="Start time"
              setDate={v => setFieldValue('start_at', v)}
              errorMessage={errors.start_at && touched.start_at ? errors.start_at : undefined}
            />
            <DateField
              date={values.end_at}
              label="End time"
              setDate={v => setFieldValue('end_at', v)}
            />
            <SelectField
              items={WT_TIMEFRAMES}
              itemToString={v => v.label}
              label="Timeframe"
              onSelectItem={v => setFieldValue('time_frame', v?.value)}
              selectedItem={
                WT_TIMEFRAMES.find(
                  el => durationToMs(el.value) === durationToMs(values.time_frame ?? undefined),
                ) ?? undefined
              }
              errorMessage={errors.time_frame && touched.time_frame ? errors.time_frame : undefined}
            />
            <DurationField
              value={values.close_bar_before}
              setValue={v => setFieldValue('close_bar_before', v)}
              label="Close bar before"
              errorMessage={
                errors.close_bar_before && touched.close_bar_before
                  ? errors.close_bar_before
                  : undefined
              }
            />
            <div className="inputs-row">
              <InputField
                type="decimal-number"
                value={values.indicator_period}
                label="Indicator period"
                setValue={v => setFieldValue('indicator_period', v)}
                errorMessage={
                  errors.indicator_period && touched.indicator_period
                    ? errors.indicator_period
                    : undefined
                }
              />
              <InputField
                type="decimal-number"
                value={values.min_transaction_amount_base}
                label="Min transaction amount"
                setValue={v => setFieldValue('min_transaction_amount_base', v)}
                errorMessage={
                  errors.min_transaction_amount_base && touched.min_transaction_amount_base
                    ? errors.min_transaction_amount_base
                    : undefined
                }
                nodeRight={<span className="text-sm text-gray-2">{dexPair.token_base.symbol}</span>}
              />
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Pause between series</div>
              <div className="inputs-row">
                <DurationField
                  value={values.min_pause_between_series}
                  setValue={v => setFieldValue('min_pause_between_series', v)}
                  label="Minimum"
                  errorMessage={
                    errors.min_pause_between_series && touched.min_pause_between_series
                      ? errors.min_pause_between_series
                      : undefined
                  }
                />
                <DurationField
                  value={values.max_pause_between_series}
                  setValue={v => setFieldValue('max_pause_between_series', v)}
                  label="Maximum"
                  errorMessage={
                    errors.max_pause_between_series && touched.max_pause_between_series
                      ? errors.max_pause_between_series
                      : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Volume per serie</div>
              <div className="inputs-row">
                <InputField
                  type="decimal-number"
                  value={values.min_volume}
                  label="Minimum"
                  setValue={v => setFieldValue('min_volume', v)}
                  errorMessage={
                    errors.min_volume && touched.min_volume ? errors.min_volume : undefined
                  }
                />
                <InputField
                  type="decimal-number"
                  value={values.max_volume}
                  label="Maximum"
                  setValue={v => setFieldValue('max_volume', v)}
                  errorMessage={
                    errors.max_volume && touched.max_volume ? errors.max_volume : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Transactions per serie</div>
              <div className="inputs-row">
                <InputField
                  type="natural-number"
                  value={values.min_transactions_in_series}
                  label="Minimum"
                  setValue={v => setFieldValue('min_transactions_in_series', v)}
                  errorMessage={
                    errors.min_transactions_in_series && touched.min_transactions_in_series
                      ? errors.min_transactions_in_series
                      : undefined
                  }
                />
                <InputField
                  type="natural-number"
                  value={values.max_transactions_in_series}
                  label="Maximum"
                  setValue={v => setFieldValue('max_transactions_in_series', v)}
                  errorMessage={
                    errors.max_transactions_in_series && touched.max_transactions_in_series
                      ? errors.max_transactions_in_series
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="inputs-row">
              <InputField
                type="decimal-number"
                value={values.per_transaction_amount_dev}
                label="Amount dev per trade"
                setValue={v => setFieldValue('per_transaction_amount_dev', v)}
                errorMessage={
                  errors.per_transaction_amount_dev && touched.per_transaction_amount_dev
                    ? errors.per_transaction_amount_dev
                    : undefined
                }
              />
              <InputField
                type="decimal-number"
                value={values.stop_volume_threshold_percentage}
                label="Amount threshold"
                setValue={v => setFieldValue('stop_volume_threshold_percentage', v)}
                errorMessage={
                  errors.stop_volume_threshold_percentage &&
                  touched.stop_volume_threshold_percentage
                    ? errors.stop_volume_threshold_percentage
                    : undefined
                }
              />
            </div>
          </div>
          <div className="daily-container">
            <div className="daily-container__daily-volume">
              <span>Daily volume:</span>
              <span>
                {formatToken(dailyVolume, dexPair.token_base.decimals)} {dexPair.token_base.symbol}{' '}
                / {formatFiat(dailyVolumeUsd, 18)}
              </span>
            </div>
            <div className="daily-container__daily-free-costs">
              <span>Daily fee costs: </span>
              <span>
                {formatToken(dailyFee.base.amount, 18)} {dexPair.token_base.symbol}
                {formatToken(dailyFee.fee.amount, 18) === '0'
                  ? ' '
                  : '+ ' +
                    formatToken(dailyFee.fee.amount, 18) +
                    ' ' +
                    dexPair.token_fee.symbol}{' '}
                / {formatFiat(dailyFee.total.usd, 18)}
              </span>
            </div>
          </div>
        </div>
        <div className="actions">
          <Button type="submit">{mode === 'create' ? 'Create task' : 'Save task'}</Button>
        </div>
      </CreateTaskForm>
    </Modal>
  );
};

export { DexWTOrganicTaskModal };
