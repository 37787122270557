import { useMemo, useEffect, useState, useContext, useCallback } from 'react';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import { parseUnits, formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ApiBot, ApiBuySellBot } from 'api';
import { IAddPairArgs } from 'api/apiPairs/models';
import { PairBuySellBotContext } from 'context/PairBuySellBotContext';

import { durationToMs } from 'utils/duration';
import { trimDecimalZeroes } from 'utils/formats';
import { mempoolConfigMap, MempoolConfigMap } from 'constants/common';
import { EDexBot, IDexBuySellBotDirection } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { IBuySellBotMode, IBuySellBotTaskItemInner } from 'types/buy-sell-bot';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { isNil } from 'lodash';

const formatStartTimeToUniqueId = (startTime: string) => new Date(startTime).getTime();

interface IForm {
  startDate: number | null;
  totalTokenAmount: string;
  minAmount: string;
  maxAmount: string;
  minDelay: number | null;
  maxDelay: number | null;
  advanceCoefficient: string;
  priceThreshold: string;
  amountDisperseCoeffMin: string;
  amountDisperseCoeffMax: string;
  skipTransactionsMin: string;
  skipTransactionsMax: string;
}

interface IUseCreateNewTaskModalProps {
  pair: IAddPairArgs & IDexPair;
  direction: Capitalize<IDexBuySellBotDirection>;
  onClose: () => void;
  task?: IBuySellBotTaskItemInner;
  mode: IBuySellBotMode;
}

export const useCreateNewTaskModal = ({
  pair,
  direction,
  onClose,
  task,
  mode,
}: IUseCreateNewTaskModalProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const dispatch = useTypedDispatch();
  const { handleLoadRecords, handleLoadBotSettings, botSettings } =
    useContext(PairBuySellBotContext);
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [memPoolConfig, setMemPoolConfig] = useState<MempoolConfigMap>('Use additionaly');

  const initialValues = useMemo<IForm>(() => {
    if (task) {
      const minDelayMs = durationToMs(task?.task.min_pause);
      const maxDelayMs = durationToMs(task?.task.max_pause);

      return {
        startDate: dayjs(task.task.start_time).valueOf(),
        totalTokenAmount: trimDecimalZeroes(
          formatUnits(BigNumber.from(task.task.total_token_amount), dexPair.token_base.decimals),
        ),
        minAmount: trimDecimalZeroes(
          formatUnits(BigNumber.from(task.task.min_amount), dexPair.token_base.decimals),
        ),
        maxAmount: trimDecimalZeroes(
          formatUnits(BigNumber.from(task.task.max_amount), dexPair.token_base.decimals),
        ),
        minDelay: minDelayMs,
        maxDelay: maxDelayMs,
        advanceCoefficient: (Number(task.task.advance_coefficient) * 100).toString(),
        priceThreshold: trimDecimalZeroes(task.task.price_threshold ?? '0'),
        amountDisperseCoeffMin: (task.task.amount_disperse_coeff_min * 100).toString(),
        amountDisperseCoeffMax: (task.task.amount_disperse_coeff_max * 100).toString(),
        skipTransactionsMin: task.task.skip_transactions_min.toString(),
        skipTransactionsMax: task.task.skip_transactions_max.toString(),
      };
    }

    return {
      startDate: dayjs().second(0).millisecond(0).valueOf(),
      totalTokenAmount: '',
      minAmount: '',
      maxAmount: '',
      minDelay: null,
      maxDelay: null,
      advanceCoefficient: '30',
      priceThreshold: '0',
      amountDisperseCoeffMin: '50',
      amountDisperseCoeffMax: '80',
      skipTransactionsMin: '2',
      skipTransactionsMax: '5',
    };
  }, [task, dexPair]);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const validationSchema = useMemo(
    () =>
      object({
        startDate: number().test(
          'startDate',
          '"Start time" field is required',
          startDate => !isNil(startDate) && startDate > 0,
        ),
        totalTokenAmount:
          mode === 'create'
            ? number()
                .required('"Total amount" field is required')
                .positive('"Total amount" field is required')
            : number().required('"Total amount" field is required'),

        minAmount: number()
          .test(
            'minAmount',
            '"Min amount" field is required',
            minAmount => !isNaN(Number(minAmount)) && Number(minAmount) > 0,
          )
          .test('minAmount', 'Enter valid "Min amount"', (minAmount, testContext) => {
            if (
              !minAmount ||
              isNaN(minAmount) ||
              isNaN(Number(testContext.parent.totalTokenAmount))
            )
              return true;

            return minAmount <= Number(testContext.parent.totalTokenAmount);
          }),
        maxAmount: number()
          .test(
            'maxAmount',
            '"Max amount" field is required',
            maxAmount => !isNaN(Number(maxAmount)) && Number(maxAmount) > 0,
          )
          .test('maxAmount', 'Enter valid "Max amount"', (maxAmount, testContext) => {
            if (
              !maxAmount ||
              !testContext.parent.minAmount ||
              isNaN(maxAmount) ||
              isNaN(Number(testContext.parent.totalTokenAmount)) ||
              isNaN(testContext.parent.minAmount)
            )
              return true;

            return (
              maxAmount <= Number(testContext.parent.totalTokenAmount) &&
              maxAmount >= testContext.parent.minAmount
            );
          }),
        minDelay: number()
          .nullable(true)
          .test(
            'minDelay',
            '"Min delay" field is required',
            (minDelay: number | undefined | null) =>
              memPoolConfig === 'Only' ? true : !isNil(minDelay),
          ),
        maxDelay: number()
          .nullable(true)
          .test(
            'maxDelay',
            '"Max delay" field is required',
            (maxDelay: number | undefined | null) =>
              memPoolConfig === 'Only' ? true : !isNil(maxDelay),
          ),
        advanceCoefficient: number()
          .test(
            'advanceCoefficient',
            '"Advanced mempool progress" field is required',
            (advanceCoefficient: number | undefined) =>
              memPoolConfig === 'Do not use' || memPoolConfig === 'Only'
                ? true
                : !isNaN(Number(advanceCoefficient)),
          )
          .test(
            'advanceCoefficient',
            '"Advanced mempool progress" must pe percent value',
            (advanceCoefficient: number | undefined) =>
              memPoolConfig === 'Do not use' || memPoolConfig === 'Only'
                ? true
                : Number(advanceCoefficient) <= 100,
          ),
        priceThreshold: string().test(
          'priceThreshold',
          '"Price threshould" field must be valid',
          (priceThreshold: string | undefined) =>
            !priceThreshold ||
            priceThreshold === '' ||
            (!!priceThreshold && !isNaN(Number(priceThreshold))),
        ),
        amountDisperseCoeffMin: number()
          .test(
            'amountDisperseCoeffMin',
            '"Min contr trade" field is required',
            (amountDisperseCoeffMin: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : !isNaN(Number(amountDisperseCoeffMin)),
          )
          .test(
            'amountDisperseCoeffMin',
            '"Min contr trade" must pe percent value',
            (amountDisperseCoeffMin: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : Number(amountDisperseCoeffMin) <= 100,
          ),
        amountDisperseCoeffMax: number()
          .test(
            'amountDisperseCoeffMax',
            '"Max contr trade" number is required',
            (amountDisperseCoeffMax: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : !isNaN(Number(amountDisperseCoeffMax)),
          )
          .test(
            'amountDisperseCoeffMax',
            '"Max contr trade" must pe percent value',
            (amountDisperseCoeffMax: number | undefined) =>
              memPoolConfig === 'Do not use' ? true : Number(amountDisperseCoeffMax) <= 100,
          ),
        skipTransactionsMin: string().test(
          'skipTransactionsMin',
          '"Min skip transactions" field is required',
          (skipTransactionsMin: string | undefined) =>
            memPoolConfig === 'Do not use' ? true : !isNaN(Number(skipTransactionsMin)),
        ),
        skipTransactionsMax: string().test(
          'skipTransactionsMin',
          '"Max skip transactions" field is required',
          (skipTransactionsMax: string | undefined) =>
            memPoolConfig === 'Do not use' ? true : !isNaN(Number(skipTransactionsMax)),
        ),
      }),
    [memPoolConfig, mode],
  );

  const handleAddTask = async ({
    startDate,
    totalTokenAmount,
    minAmount,
    maxAmount,
    minDelay,
    maxDelay,
    advanceCoefficient,
    priceThreshold,
    amountDisperseCoeffMin,
    amountDisperseCoeffMax,
    skipTransactionsMin,
    skipTransactionsMax,
  }: IForm) => {
    if (!startDate) return;

    setLoading(true);

    const newTask = {
      direction: direction.toLowerCase() as IDexBuySellBotDirection,
      start_time: dayjs(startDate).toISOString(),
      total_token_amount: parseUnits(totalTokenAmount, pair.token_base.decimals ?? 18).toString(),
      min_amount: parseUnits(minAmount, pair.token_base.decimals ?? 18).toString(),
      max_amount: parseUnits(maxAmount, pair.token_base.decimals ?? 18).toString(),
      min_pause: memPoolConfig !== 'Only' ? `${minDelay}ms` : '1s',
      max_pause: memPoolConfig !== 'Only' ? `${maxDelay}ms` : '2s',
      use_mempool: mempoolConfigMap[memPoolConfig],
      advance_coefficient:
        memPoolConfig === 'Use additionaly' ? Number(advanceCoefficient) / 100 : 1,
      price_threshold:
        priceThreshold !== '' && !isNaN(Number(priceThreshold)) ? priceThreshold.toString() : '0',
      amount_disperse_coeff_min:
        memPoolConfig !== 'Do not use' ? Number(amountDisperseCoeffMin) / 100 : 1,
      amount_disperse_coeff_max:
        memPoolConfig !== 'Do not use' ? Number(amountDisperseCoeffMax) / 100 : 1,
      skip_transactions_min: memPoolConfig !== 'Do not use' ? Number(skipTransactionsMin) : 0,
      skip_transactions_max: memPoolConfig !== 'Do not use' ? Number(skipTransactionsMax) : 0,
    };

    try {
      const {
        data: dataStatistic,
        isSuccess: isSuccessFirst,
        errorMessage: errorMessageFirst,
      } = await ApiBuySellBot.buySellStatistics({ pairId: pair.id });

      const taskIdForEdit = task ? formatStartTimeToUniqueId(task.task.start_time) : undefined;

      const prevTasks = dataStatistic?.items?.map(({ task }) => {
        const taskId = formatStartTimeToUniqueId(task.start_time);

        if (taskIdForEdit === taskId) {
          return newTask;
        } else {
          return task;
        }
      });

      if (!isSuccessFirst) {
        onClose();
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessageFirst,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
        return;
      }

      const tasks = (taskIdForEdit ? prevTasks : [...(prevTasks || []), newTask]) ?? [];

      const { isSuccess, errorMessage } = await ApiBot.saveDexBotConfig({
        pairId: pair.id,
        bot: EDexBot.buy_sell_bot,
        body: {
          slippage_percent: botSettings?.slippage_percent ?? '0',
          send_private_transactions: botSettings?.send_private_transactions ?? false,
          is_enabled: botSettings?.is_enabled ?? false,
          buy_sell_options: {
            tasks,
          },
        },
      });

      if (!isSuccess) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
        return;
      }

      if (isSuccess) {
        handleLoadRecords();
        handleLoadBotSettings();
        onClose();
        dispatch(
          setAlertState({
            type: 'success',
            text:
              mode === 'create'
                ? 'You successfully create new task!'
                : 'You successfully edit task!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (e) {
      console.error(e);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Something went wrong',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, setFieldValue, setFieldTouched, values, errors, touched, validateForm } =
    useFormik<IForm>({
      initialValues,
      onSubmit: handleAddTask,
      validationSchema,
    });

  const handleSetFieldValue = useCallback(
    (field: string, value: any) => {
      setEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    const handleSetFormError = () => {
      const keys = Object.keys(errors);

      for (const key of keys) {
        const keyWithType = key as keyof typeof errors;

        if (errors[keyWithType] && touched[keyWithType]) {
          setFormError(errors[keyWithType]);
          return;
        }
      }

      setFormError(undefined);
    };

    handleSetFormError();
  }, [errors, touched]);

  return {
    validationSchema,
    loading,
    initialValues,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    setFieldTouched,
    formError,
    memPoolConfig,
    setMemPoolConfig,
    validateForm,
    edited,
    errors,
    touched,
  };
};
