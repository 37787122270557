import { useMemo, useEffect, useState, useContext, useCallback } from 'react';
import { FormikProps, useFormik } from 'formik';
import { parseUnits } from '@ethersproject/units';
import dayjs from 'dayjs';
import { useTypedDispatch } from 'store';

import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';
import { ICexWTRegularTask } from 'types/bots';
import { multiplyBignumbers, normalizeBigNumber } from 'utils/formulas';
import { extractToPercent, numberParse, percentageParse } from 'utils/formats';
import { durationToMs } from 'utils/duration';

import { addRegularTask } from './addRegularTask';
import { validationSchema } from './washAlgorithmTaskSchema';

interface IFormWashAlgorithm {
  start_at: number | null;
  end_at: number | null;
  enabled: boolean;
  uniq_id: string;
  cancel_delay_min: number | undefined;
  cancel_delay_max: number | undefined;
  ignore_volume_percent: string;
  makers_min: string;
  makers_max: string;
  not_equal_amount_percent_min: string;
  not_equal_amount_percent_max: string;
  not_equal_amount_probability: string;
  rounding_probability: string;
  takers_max: string;
  takers_min: string;
  vol_per_group_max: string;
  vol_per_group_min: string;

  pause_between_groups_min: number | undefined;
  pause_between_groups_max: number | undefined;
  pause_between_orders_min: number | undefined;
  pause_between_orders_max: number | undefined;
  price_selection_strategy: string;
}

interface IUseCreateNewTaskModalProps {
  onClose: () => void;
  task?: ICexWTRegularTask;
  pairId?: number | null;
  mode: 'create' | 'edit';
}

export const useCreateWashAlgorithmTaskModal = ({
  onClose,
  task,
  pairId,
  mode,
}: IUseCreateNewTaskModalProps) => {
  const dispatch = useTypedDispatch();
  const { handleLoadRecords, taskLoading } = useContext(CexWashAlgorithmContext);
  const [edited, setEdited] = useState(false);

  const initialValues = useMemo(() => {
    return mode === 'edit' && task
      ? {
          ...task.options,
          enabled: task.enabled ? task.enabled : false,
          uniq_id: task.uniq_id ? task.uniq_id : '',
          start_at: dayjs(task.start_at).second(0).millisecond(0).valueOf(),
          end_at: task?.end_at ? dayjs(task.end_at).second(0).millisecond(0).valueOf() : null,
          vol_per_group_min: normalizeBigNumber(task.options.vol_per_group_min, 18, 18, false),
          vol_per_group_max: normalizeBigNumber(task.options.vol_per_group_max, 18, 18, false),
          pause_between_groups_min: durationToMs(task.options.pause_between_groups_min),
          pause_between_groups_max: durationToMs(task.options.pause_between_groups_max),

          ignore_volume_percent: extractToPercent(task.options.ignore_volume_percent),
          makers_min: String(task.options.makers_min),
          makers_max: String(task.options.makers_max),
          takers_min: String(task.options.takers_min),
          takers_max: String(task.options.takers_max),
          not_equal_amount_percent_min: extractToPercent(task.options.not_equal_amount_percent_min),
          not_equal_amount_percent_max: extractToPercent(task.options.not_equal_amount_percent_max),
          not_equal_amount_probability: extractToPercent(task.options.not_equal_amount_probability),
          rounding_probability: extractToPercent(task.options.rounding_probability),

          cancel_delay_min: durationToMs(task.options.cancel_delay_min),
          cancel_delay_max: durationToMs(task.options.cancel_delay_max),
          pause_between_orders_min: durationToMs(task.options.pause_between_orders_min),
          pause_between_orders_max: durationToMs(task.options.pause_between_orders_max),
          price_selection_strategy: task.options.price_selection_strategy || 'random',
        }
      : {
          enabled: true,
          uniq_id: '',
          start_at: dayjs().second(0).millisecond(0).valueOf(),
          end_at: null,
          vol_per_group_min: '',
          vol_per_group_max: '',
          cancel_delay_min: undefined,
          cancel_delay_max: undefined,
          ignore_volume_percent: '',
          makers_min: '',
          makers_max: '',
          takers_min: '',
          takers_max: '',
          not_equal_amount_percent_min: '',
          not_equal_amount_percent_max: '',
          not_equal_amount_probability: '',
          pause_between_groups_min: undefined,
          pause_between_groups_max: undefined,
          pause_between_orders_min: undefined,
          pause_between_orders_max: undefined,
          rounding_probability: '',
          price_selection_strategy: '',
        };
  }, [task, mode]);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const handleAddTask = async ({
    enabled = true,
    start_at,
    end_at,
    vol_per_group_min,
    vol_per_group_max,
    pause_between_groups_min,
    pause_between_groups_max,
    makers_min,
    makers_max,
    takers_min,
    takers_max,
    cancel_delay_min,
    cancel_delay_max,
    pause_between_orders_min,
    pause_between_orders_max,
    price_selection_strategy,
    rounding_probability,
    not_equal_amount_probability,
    not_equal_amount_percent_min,
    not_equal_amount_percent_max,
    ignore_volume_percent,
  }: IFormWashAlgorithm) => {
    if (!start_at) return;

    const newTask: ICexWTRegularTask = {
      enabled: enabled,
      start_at: dayjs(start_at).toISOString(),
      ...(end_at ? { end_at: dayjs(end_at).toISOString() } : {}),
      uniq_id: '',
      options: {
        vol_per_group_min: String(
          multiplyBignumbers([parseUnits(vol_per_group_min), 18], [parseUnits('1'), 18]),
        ),
        vol_per_group_max: String(
          multiplyBignumbers([parseUnits(vol_per_group_max), 18], [parseUnits('1'), 18]),
        ),
        pause_between_groups_min: `${pause_between_groups_min ?? 0}ms`,
        pause_between_groups_max: `${pause_between_groups_max ?? 0}ms`,

        makers_min: numberParse(makers_min),
        makers_max: numberParse(makers_max),
        takers_min: numberParse(takers_min),
        takers_max: numberParse(takers_max),
        rounding_probability: percentageParse(rounding_probability),
        not_equal_amount_probability: percentageParse(not_equal_amount_probability),
        not_equal_amount_percent_min: percentageParse(not_equal_amount_percent_min),
        not_equal_amount_percent_max: percentageParse(not_equal_amount_percent_max),
        ignore_volume_percent: percentageParse(ignore_volume_percent),

        cancel_delay_min: `${cancel_delay_min ?? 0}ms`,
        cancel_delay_max: `${cancel_delay_max ?? 0}ms`,
        pause_between_orders_min: `${pause_between_orders_min ?? 0}ms`,
        pause_between_orders_max: `${pause_between_orders_max ?? 0}ms`,
        price_selection_strategy: price_selection_strategy,
      },
    };

    if (pairId) {
      await addRegularTask({
        dispatch,
        task,
        newTask,
        onClose,
        setLoading: taskLoading.set,
        pairId,
        handleLoadRecords,
        mode,
      });
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    validateForm,
  }: FormikProps<IFormWashAlgorithm> = useFormik<IFormWashAlgorithm>({
    initialValues,
    onSubmit: handleAddTask,
    validationSchema,
  });

  const handleSetFieldValue = useCallback(
    (field: string, value: any) => {
      setEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    const handleSetFormError = () => {
      const keys = Object.keys(errors);

      for (const key of keys) {
        const keyWithType = key as keyof typeof errors;

        if (errors[keyWithType] && touched[keyWithType]) {
          setFormError(errors[keyWithType]);
          return;
        }
      }

      setFormError(undefined);
    };

    handleSetFormError();
  }, [errors, touched]);

  return {
    validationSchema,
    initialValues,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    formError,
    validateForm,
    edited,
    errors,
    touched,
  };
};
