import { useState, useCallback, useEffect } from 'react';
import { useTypedSelector, useTypedDispatch } from 'store';
import { IForm } from './models';
import { getCexs } from 'store/slices/dictionary';

import { setAlertState, dropAlertState } from 'store/slices/ui';

import { ApiAccounts } from 'api/apiAccounts';
import { IInitialModalAccount } from './models/IForm';

interface IUseAddAccountModal {
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
  modalAccount: IInitialModalAccount;
}
export const initialForm: IForm = {
  cex: undefined,
  expire_at: null,
  secret: undefined,
  passphrase: undefined,
  notes: undefined,
  proxy: undefined,
  api_key: undefined,
};

const initialTouched: { [key in keyof IForm]: boolean } = {
  cex: false,
  expire_at: false,
  secret: false,
  passphrase: false,
  notes: false,
  proxy: false,
  api_key: false,
};

const useAddAccountModal = ({ onClose, onOpen, onSuccess, modalAccount }: IUseAddAccountModal) => {
  const dispatch = useTypedDispatch();
  const cexs = useTypedSelector(store => store.dictionary.cexs);

  const [form, setForm] = useState<IForm>(initialForm);
  const [edited, setEdited] = useState<boolean>(false);
  const [touched, setTouched] = useState<{ [key in keyof IForm]: boolean }>(initialTouched);
  const [formError, setFormError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const isNewAccountMode = modalAccount.mode === 'new';

  const setFormField = useCallback(
    function <T extends keyof typeof form>(
      field: T,
      value: (typeof form)[T],
      options: { triggerEdit?: boolean } = { triggerEdit: false },
    ): void {
      if (loading) return;

      if (options?.triggerEdit) {
        setEdited(true);
      }

      setForm(form => ({ ...form, [field]: value }));
      setTouched(touched => ({ ...touched, [field]: true }));
    },
    [loading],
  );

  const validateForm = useCallback(
    (form: IForm, config?: { isAccept?: boolean }): string | null => {
      if (!isNewAccountMode) {
        return null;
      }
      if (!form.expire_at || form.expire_at <= 0) {
        setFormError('Expire date is required');
        return 'Expire date is required';
      }
      if (!form.cex && (touched.cex || config?.isAccept)) {
        setFormError('CEX pair is required');
        return 'CEX pair is required';
      }
      if (!form.api_key && (touched.api_key || config?.isAccept)) {
        setFormError('API key is required');
        return 'API key is required';
      }
      if (
        form?.cex?.cex === 'kucoin' &&
        !form.passphrase &&
        (touched.passphrase || config?.isAccept)
      ) {
        setFormError('Passphrase is required');
        return 'Passphrase is required';
      }
      if (!form.secret && (touched.secret || config?.isAccept)) {
        setFormError('Secret is required');
        return 'Secret is required';
      }
      if (!form.notes && (touched.notes || config?.isAccept)) {
        setFormError('Notes is required');
        return 'Notes is required';
      }
      setFormError(null);
      return null;
    },
    [isNewAccountMode, touched],
  );

  const handleAddAccount = useCallback(async () => {
    const validationResult = validateForm(form, { isAccept: true });

    if (validationResult) return;

    setLoading(true);

    const isVisiblePassphrase = form?.cex?.cex === 'kucoin';
    const cex = form.cex?.cex ?? '';
    const addAccountForm: any = {
      ...(cex ? { cex: cex } : {}),
      ...(form.expire_at ? { expire_at: form.expire_at } : {}),
      ...(form.api_key || form.passphrase || form.secret
        ? {
            credentials: {
              ...(form.api_key ? { api_key: form.api_key } : {}),
              ...(isVisiblePassphrase ? { passphrase: form.passphrase } : {}),
              ...(form.secret ? { secret: form.secret } : {}),
            },
          }
        : {}),
      ...(form.notes ? { notes: form.notes } : {}),
      ...(form.proxy ? { proxy: form.proxy } : {}),
      ...(!isNewAccountMode ? { accountId: modalAccount.item.accountId } : {}),
    };

    const { isSuccess, errorMessage } = await (isNewAccountMode
      ? ApiAccounts.addAccount(addAccountForm)
      : ApiAccounts.updateAccount(addAccountForm));

    if (!isSuccess) {
      onClose();
      setLoading(false);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: errorMessage,
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
            onOpen();
          },
        }),
      );
      return;
    }

    if (isSuccess) {
      onSuccess();
      onClose();
      setLoading(false);

      dispatch(
        setAlertState({
          type: 'success',
          text: `You successfully created account`,
          onClose: () => {
            dispatch(dropAlertState());
          },
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    }
  }, [form, dispatch, onClose, onOpen, validateForm, modalAccount, isNewAccountMode, onSuccess]);

  useEffect(() => {
    if (modalAccount.mode === 'edit' && cexs) {
      const currentCex = cexs?.find(cex => cex.cex === modalAccount.item.cex?.cex);
      const formKeys = Object.keys(initialForm) as Array<keyof IForm>;
      formKeys.forEach(item => {
        setFormField(item, item === 'cex' ? currentCex : modalAccount?.item[item], {
          triggerEdit: true,
        });
      });
    }
  }, [modalAccount, cexs, setFormField]);

  useEffect(() => {
    if (cexs && isNewAccountMode) {
      setFormField('cex', cexs[0]);
    }
    if (!cexs) dispatch(getCexs());
  }, [dispatch, isNewAccountMode, cexs, setFormField]);

  useEffect(() => {
    validateForm(form);
  }, [form, touched, validateForm]);

  return {
    form,
    setFormField,
    handleAddAccount,
    formError,
    loading,
    edited,
    cexs,
  };
};

export { useAddAccountModal };
