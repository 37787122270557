import { useCallback, useMemo } from 'react';

import {
  FILTER_BOOLEAN_CONNECTIONS,
  EFilterBooleanConnection,
  IDexFilterValue,
} from 'types/filters/common';

import { FilterSelectField } from '../fields';

interface IBooleanProps {
  filter: IDexFilterValue;
  updateFilter: (id: string, filter?: IDexFilterValue) => void;
}

function Boolean({ filter, updateFilter }: IBooleanProps) {
  const selectedItem = useMemo(
    () => FILTER_BOOLEAN_CONNECTIONS.find(el => el.value === filter.value?.connection) ?? undefined,
    [filter.value],
  );

  const handleSelectItem = useCallback(
    (item: { label: string; value: EFilterBooleanConnection }) => {
      //@ts-ignore
      updateFilter(filter.id, {
        ...filter,
        value: { connection: item.value, value: (filter.value?.value as boolean) ?? undefined },
      });
    },
    [filter, updateFilter],
  );

  return (
    <FilterSelectField
      items={FILTER_BOOLEAN_CONNECTIONS}
      itemToString={v => v.label}
      selected={selectedItem}
      onSelectItem={handleSelectItem}
      placeholder="Select"
    />
  );
}

export { Boolean };
