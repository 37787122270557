import React from 'react';
import { Row } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';

import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { formatFiat } from 'utils/formats';

interface ITotalCellProps {
  row: Row<IBalanceBotWallet>;
}

const TotalCell: React.FC<ITotalCellProps> = ({ row }) => {
  const totalToken = row.original.tokens.find((item: any) => item.token.symbol === 'TOTAL');

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
      <div>{formatFiat(BigNumber.from(totalToken?.balance_usd ?? '0'), 6)}</div>
    </div>
  );
};

export { TotalCell };
