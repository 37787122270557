import { IDexBalanceBotTask } from 'types/bots';
import { IRange } from 'types/web3';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { mapError } from '../apiBase/mapError';
import { mapResponse } from '../apiBase/mapResponse';
import { IGetBalanceBotResponse, IGetHoldersStatisticResponse, IBalanceBotWallet } from './models';

class ApiBalanceBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiBalanceBot | undefined;
  private static instance = (() => {
    if (ApiBalanceBot.__instance === undefined) {
      ApiBalanceBot.__instance = new ApiBalanceBot();
    }
    return ApiBalanceBot.__instance;
  })();

  public static getHoldersStatistic = async ({
    pairId,
    ranges,
  }: {
    pairId: number;
    ranges: (IRange | string)[];
  }): Promise<Response<IGetHoldersStatisticResponse>> => {
    let mappedResponseOrError: Response<IGetHoldersStatisticResponse>;

    const urlParams = new URLSearchParams();
    for (const range of ranges) {
      urlParams.append('range', range.toString());
    }

    try {
      const response = await ApiBalanceBot.instance.get<
        IBaseResponse<IGetHoldersStatisticResponse>
      >(`/pairs/${pairId}/holders-statistics` + '?' + urlParams.toString());

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IGetHoldersStatisticResponse>({ error });
    }

    return mappedResponseOrError;
  };

  //////// V2 Balance Bot requests
  public static getPairBalanceBotTasks = async ({
    pairs_ids,
  }: {
    pairs_ids: number;
  }): Promise<Response<IGetBalanceBotResponse>> => {
    let mappedResponseOrError: Response<IGetBalanceBotResponse>;
    const urlParams = new URLSearchParams();

    urlParams.append('pairs_ids', `${pairs_ids}`);
    urlParams.append('limit', '1000');

    //TODO remove hardcoded params
    try {
      const response = await ApiBalanceBot.instance.get<IBaseResponse<IGetBalanceBotResponse>>(
        `/balancebot-tasks?` +
          urlParams.toString() +
          `&sort_by=type%3Adesc&sort_by=is_active%3Adesc&sort_by=created_at%3Adesc&`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static getPairBalanceBotTasksById = async ({
    task_id,
  }: {
    task_id: number;
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<IDexBalanceBotTask>;

    try {
      const response = await ApiBalanceBot.instance.get<IBaseResponse<IDexBalanceBotTask>>(
        `/balancebot-tasks/${task_id}`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IDexBalanceBotTask>({ error });
    }

    return mappedResponseOrError;
  };

  public static getEstimateFeeById = async ({
    pairs_id,
  }: {
    pairs_id: number;
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiBalanceBot.instance.get<IBaseResponse<any>>(
        `/pairs/${pairs_id}/estimate-fee`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static addPairBalanceBotTasks = async ({
    body,
  }: {
    body: any;
  }): Promise<Response<{ id: number }>> => {
    let mappedResponseOrError: Response<{ id: number }>;

    try {
      const response = await ApiBalanceBot.instance.post<IBaseResponse<{ id: number }>>(
        `/balancebot-tasks`,
        body,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{ id: number }>({ error });
    }

    return mappedResponseOrError;
  };

  public static updatePairBalanceBotTasks = async ({
    taskId,
    body,
  }: {
    taskId: number;
    body: any;
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiBalanceBot.instance.patch<IBaseResponse<any>>(
        `/balancebot-tasks/${taskId}`,
        body,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static getSourceBalanceBotTasksById = async ({
    balance_bot_id,
    limit,
    lastSeenId,
    not_connected,
  }: {
    balance_bot_id: number;
    limit?: number;
    lastSeenId?: number;
    not_connected: string;
  }): Promise<Response<{ has_next: boolean; items: IBalanceBotWallet[] }>> => {
    let mappedResponseOrError: Response<{ has_next: boolean; items: IBalanceBotWallet[] }>;

    const urlParams = new URLSearchParams();

    limit && urlParams.append('limit', limit.toString());
    lastSeenId && urlParams.append('last_seen_id', lastSeenId.toString());
    not_connected && urlParams.append('not_connected', not_connected.toString());
    try {
      const response = await ApiBalanceBot.instance.get<
        IBaseResponse<{ has_next: boolean; items: IBalanceBotWallet[] }>
      >(`/balancebot-tasks/${balance_bot_id}/source-wallets` + '?' + urlParams.toString());

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{ has_next: boolean; items: IBalanceBotWallet[] }>({
        error,
      });
    }

    return mappedResponseOrError;
  };

  public static getDestinationWalletsTasksById = async ({
    balance_bot_id,
    limit,
    lastSeenId,
    not_connected,
  }: {
    balance_bot_id: number;
    limit?: number;
    lastSeenId?: number;
    not_connected: string;
  }): Promise<Response<{ has_next: boolean; items: IBalanceBotWallet[] }>> => {
    let mappedResponseOrError: Response<{ has_next: boolean; items: IBalanceBotWallet[] }>;

    const urlParams = new URLSearchParams();

    limit && urlParams.append('limit', limit.toString());
    lastSeenId && urlParams.append('last_seen_id', lastSeenId.toString());
    not_connected && urlParams.append('not_connected', not_connected.toString());

    try {
      const response = await ApiBalanceBot.instance.get<
        IBaseResponse<{ has_next: boolean; items: IBalanceBotWallet[] }>
      >(`/balancebot-tasks/${balance_bot_id}/destination-wallets` + '?' + urlParams.toString());

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{ has_next: boolean; items: IBalanceBotWallet[] }>({
        error,
      });
    }

    return mappedResponseOrError;
  };
  public static updateSourceWalletsTasksById = async ({
    balance_bot_id,
    body,
  }: {
    balance_bot_id: number;
    body: any;
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiBalanceBot.instance.patch<IBaseResponse<any>>(
        `/balancebot-tasks/${balance_bot_id}`,
        body,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static updateDestinationWalletsTasksById = async ({
    balance_bot_id,
    body,
  }: {
    balance_bot_id: number;
    body: any;
  }): Promise<Response<{ has_next: boolean; items: IBalanceBotWallet[] }>> => {
    let mappedResponseOrError: Response<{ has_next: boolean; items: IBalanceBotWallet[] }>;

    try {
      const response = await ApiBalanceBot.instance.put<
        IBaseResponse<{ has_next: boolean; items: IBalanceBotWallet[] }>
      >(`/balancebot-tasks/${balance_bot_id}/destination-wallets`, body);

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{ has_next: boolean; items: IBalanceBotWallet[] }>({
        error,
      });
    }

    return mappedResponseOrError;
  };

  public static deleteDestinationWalletsTasksById = async ({
    balance_bot_id,
    body,
  }: {
    balance_bot_id: number;
    body: {
      wallet_ids: number[];
    };
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiBalanceBot.instance.delete<IBaseResponse<any>>(
        `/balancebot-tasks/${balance_bot_id}/destination-wallets`,
        { data: body },
      );

      mappedResponseOrError = mapResponse<any>({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({
        error,
      });
    }

    return mappedResponseOrError;
  };
}

export { ApiBalanceBot };
