import React, { useCallback, useMemo } from 'react';
import { useTypedSelector } from 'store';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import {
  StatisticTable,
  StatisticHeader,
  StatisticCell,
  StatisticBuySellCell,
} from 'common/statistic';
import { IStatisticToken, IStatisticTransaction } from 'api/apiStatistic/models';
import { ETransactionAction } from 'api/apiTransactions/models';
import { addBignumbers, divideBignumbers, multiplyBignumbers } from 'utils/formulas';
import { formatFiat, formatToken, formatNatural } from 'utils/formats';

import './style.scss';

interface IBuySellAlgorithmTableProps {
  statisticTokens: IStatisticToken[];
  statisticTransactions: IStatisticTransaction[];
}

const BuySellAlgorithmTable: React.FC<IBuySellAlgorithmTableProps> = ({
  statisticTokens,
  statisticTransactions,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const calculateOptions = useCallback(
    (action: 'buy' | 'sell') => {
      const baseToken = statisticTokens.find(el => el.token.address === pair!.token_base.address);
      const quoteToken = statisticTokens.find(el => el.token.address === pair!.token_quote.address);
      const feeToken = statisticTokens.find(el => el.token.address === pair!.token_fee.address);

      const actions =
        action === 'buy'
          ? [ETransactionAction.ActionBulkBuy, ETransactionAction.ActionBulkBuyMempool]
          : [ETransactionAction.ActionBulkSell, ETransactionAction.ActionBulkSellMempool];

      const mempoolActions =
        action === 'buy'
          ? [ETransactionAction.ActionBulkBuyMempool]
          : [ETransactionAction.ActionBulkSellMempool];

      if (!baseToken || !quoteToken || !feeToken)
        return {
          totalAmount: BigNumber.from(0),
          mempoolAmount: BigNumber.from(0),
          mempoolPercentAmount: BigNumber.from(0),
          avgPriceTokens: BigNumber.from(0),
          avgPriceUsd: BigNumber.from(0),
          transactionsTotal: 0,
          transactionsFailed: 0,
          feeSpentTokens: BigNumber.from(0),
          feeSpentUsd: BigNumber.from(0),
          valueTokens: BigNumber.from(0),
          valueUsd: BigNumber.from(0),
          avgAmount: BigNumber.from(0),
        };

      const totalAmount = baseToken.volumes.reduce((acc, val) => {
        if (!actions.includes(val.action)) return acc;

        return addBignumbers([acc, 18], [BigNumber.from(val.amount), baseToken.token.decimals]);
      }, BigNumber.from(0));

      const mempoolAmount = baseToken.volumes.reduce((acc, val) => {
        if (!mempoolActions.includes(val.action)) return acc;

        return addBignumbers([acc, 18], [BigNumber.from(val.amount), baseToken.token.decimals]);
      }, BigNumber.from(0));

      const mempoolPercentAmount = totalAmount.eq(BigNumber.from(0))
        ? BigNumber.from(0)
        : multiplyBignumbers(
            [divideBignumbers([mempoolAmount, 18], [totalAmount, 18]), 18],
            [parseUnits('100', 18), 18],
          );

      const totalBaseVolumeTokens = baseToken.volumes.reduce((acc, val) => {
        if (!actions.includes(val.action)) return acc;

        return addBignumbers([acc, 18], [BigNumber.from(val.amount), baseToken.token.decimals]);
      }, BigNumber.from(0));
      const totalQuoteVolumeTokens = quoteToken.volumes.reduce((acc, val) => {
        if (!actions.includes(val.action)) return acc;

        return addBignumbers([acc, 18], [BigNumber.from(val.amount), quoteToken.token.decimals]);
      }, BigNumber.from(0));
      const totalBaseVolumeUsd = baseToken.volumes.reduce((acc, val) => {
        if (!actions.includes(val.action)) return acc;

        return addBignumbers([acc, 18], [BigNumber.from(val.amount_usd), 6]);
      }, BigNumber.from(0));

      const avgPriceTokens = divideBignumbers(
        [totalQuoteVolumeTokens, 18],
        [totalBaseVolumeTokens, 18],
      );

      const avgPriceUsd = divideBignumbers([totalBaseVolumeUsd, 18], [totalBaseVolumeTokens, 18]);

      const transactions = statisticTransactions.reduce(
        (acc, val) => {
          if (!actions.includes(val.action)) return acc;

          return { total: acc.total + val.total, failed: acc.failed + val.failed };
        },
        { total: 0, failed: 0 },
      );

      const feeSpent = (feeToken.spent_fees || []).reduce(
        (acc, val) => {
          if (!actions.includes(val.action)) return acc;

          return {
            tokens: addBignumbers([acc.tokens, 18], [BigNumber.from(val.amount), 18]),
            usd: addBignumbers([acc.usd, 18], [BigNumber.from(val.amount_usd), 6]),
          };
        },
        { tokens: BigNumber.from(0), usd: BigNumber.from(0) },
      );

      const value = quoteToken.volumes.reduce(
        (acc, val) => {
          if (!actions.includes(val.action)) return acc;

          return {
            tokens: addBignumbers(
              [acc.tokens, 18],
              [BigNumber.from(val.amount), quoteToken.token.decimals],
            ),
            usd: addBignumbers([acc.usd, 18], [BigNumber.from(val.amount_usd), 6]),
          };
        },
        {
          tokens: BigNumber.from(0),
          usd: BigNumber.from(0),
        },
      );

      const avgAmount = divideBignumbers(
        [totalAmount, 18],
        [parseUnits(transactions.total.toString(), 18), 18],
      );

      return {
        totalAmount,
        mempoolAmount,
        mempoolPercentAmount,
        avgPriceTokens,
        avgPriceUsd,
        transactionsTotal: transactions.total,
        transactionsFailed: transactions.failed,
        feeSpentTokens: feeSpent.tokens,
        feeSpentUsd: feeSpent.usd,
        valueTokens: value.tokens,
        valueUsd: value.usd,
        avgAmount,
      };
    },
    [pair, statisticTokens, statisticTransactions],
  );

  const buyOptions = useMemo(() => calculateOptions('buy'), [calculateOptions]);
  const sellOptions = useMemo(() => calculateOptions('sell'), [calculateOptions]);

  return (
    <StatisticTable style={{ width: '116.66%' }}>
      <thead>
        <tr>
          <StatisticHeader title={'Total amount'} />
          <StatisticHeader title={'Mempool amount'} />
          <StatisticHeader title={'Avg price'} />
          <StatisticHeader title={'Value'} />
          <StatisticHeader title={'Fee spent'} />
          <StatisticHeader title={'Avg amount'} />
          <StatisticHeader title={'Transactions'} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <StatisticBuySellCell
            type="sell"
            title={formatToken(sellOptions.totalAmount, 18) + ' ' + pair!.token_base.symbol}
          />
          <StatisticCell
            title={formatToken(sellOptions.mempoolAmount, 18) + ' ' + pair!.token_base.symbol}
            subtitle={`/ ${formatFiat(sellOptions.mempoolPercentAmount, 18, '', {
              zeroWithoutMinus: true,
            })}%`}
          />
          <StatisticCell
            title={formatToken(sellOptions.avgPriceTokens, 18)}
            subtitle={`/${formatFiat(sellOptions.avgPriceUsd, 18)}`}
          />
          <StatisticCell
            title={formatToken(sellOptions.valueTokens, 18) + ' ' + pair!.token_quote.symbol}
            subtitle={`(${formatFiat(sellOptions.valueUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(sellOptions.feeSpentTokens, 18) + ' ' + pair!.token_fee.symbol}
            subtitle={`(${formatFiat(sellOptions.feeSpentUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(sellOptions.avgAmount, 18) + ' ' + pair!.token_base.symbol}
            subtitle=""
          />
          <StatisticCell
            title={formatNatural(sellOptions.transactionsTotal.toString())}
            subtitle={`(${formatNatural(sellOptions.transactionsFailed.toString())} failed)`}
          />
        </tr>
        <tr>
          <StatisticBuySellCell
            type="buy"
            title={formatToken(buyOptions.totalAmount, 18) + ' ' + pair!.token_base.symbol}
          />
          <StatisticCell
            title={formatToken(buyOptions.mempoolAmount, 18) + ' ' + pair!.token_base.symbol}
            subtitle={`/ ${formatFiat(buyOptions.mempoolPercentAmount, 18, '', {
              zeroWithoutMinus: true,
            })}%`}
          />
          <StatisticCell
            title={formatToken(buyOptions.avgPriceTokens, 18)}
            subtitle={`/${formatFiat(buyOptions.avgPriceUsd, 18)}`}
          />
          <StatisticCell
            title={formatToken(buyOptions.valueTokens, 18) + ' ' + pair!.token_quote.symbol}
            subtitle={`(${formatFiat(buyOptions.valueUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(buyOptions.feeSpentTokens, 18) + ' ' + pair!.token_fee.symbol}
            subtitle={`(${formatFiat(buyOptions.feeSpentUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(buyOptions.avgAmount, 18) + ' ' + pair!.token_base.symbol}
            subtitle=""
          />
          <StatisticCell
            title={formatNatural(buyOptions.transactionsTotal.toString())}
            subtitle={`(${formatNatural(buyOptions.transactionsFailed.toString())} failed)`}
          />
        </tr>
      </tbody>
    </StatisticTable>
  );
};

export default BuySellAlgorithmTable;
