import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import dayjs, { Dayjs } from 'dayjs';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import { useClickAway } from 'react-use';

import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

import 'react-calendar/dist/Calendar.css';
import './calendar.scss';

export interface ICalendarProps {
  onClose: () => void;
  title: string;
  initialDate: Dayjs | null;
  onChange: (date?: Dayjs | null | undefined) => void;
  timeActive?: boolean;
}

const Calendar: React.FC<ICalendarProps> = ({
  onClose,
  title,
  initialDate,
  onChange,
  timeActive = false,
}) => {
  const [date, setDate] = useState<Dayjs | null>(initialDate);
  const [time, setTime] = useState<string>('');
  const calendarModalContainerRef = useRef<HTMLDivElement>(null);
  const calendarModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDate(initialDate);
    if (initialDate) {
      setTime(initialDate.format('HH:mm'));
    } else {
      setTime('00:00');
    }
  }, [initialDate]);

  useEffect(() => {
    const keyDownEvent = (event: KeyboardEvent) => {
      event.stopImmediatePropagation();
      event.stopPropagation();
      if (event.code === 'Escape' || event.code === 'Enter') {
        onClose();
      }
    };

    document.addEventListener('keydown', keyDownEvent);

    return () => {
      document.removeEventListener('keydown', keyDownEvent);
    };
  }, [onClose]);

  const keyDownEvent = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (event.key === 'Escape' || event.key === 'Enter') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (calendarModalRef.current) {
      calendarModalRef.current.focus();
    }
  }, [calendarModalRef]);

  useClickAway(calendarModalContainerRef, onClose);

  const onChangeTime = useCallback(
    (value: TimePickerValue) => {
      const hours = Number(value.toString().split(':')[0]);
      const minutes = Number(value.toString().split(':')[1]);

      let newDateResult = date ? date.startOf('day') : null;
      if (newDateResult) {
        newDateResult = newDateResult.add(hours, 'hours').add(minutes, 'minutes');
      }

      setTime(value.toString());
      setDate(newDateResult);
    },
    [date],
  );

  const onChangeDate = useCallback(
    (value: Date) => {
      let resultDate = dayjs(value).startOf('date');

      if (timeActive && time !== '' && time !== '00:00') {
        const hours = Number(time.toString().split(':')[0]);
        const minutes = Number(time.toString().split(':')[1]);

        resultDate = resultDate.add(hours, 'hours').add(minutes, 'minutes');
      }

      setDate(resultDate);
    },
    [timeActive, time],
  );

  const handleSumbit = useCallback(() => {
    onClose();

    onChange(date);
  }, [date, onClose, onChange]);

  return (
    <Modal onClose={onClose} title={title} modalRefItem={calendarModalContainerRef}>
      <div
        ref={calendarModalRef}
        className="mm-calendar-modal"
        tabIndex={0}
        onKeyDown={keyDownEvent}
      >
        <ReactCalendar
          locale="en"
          selectRange={false}
          value={date ? date.toDate() : null}
          onChange={onChangeDate}
        />
        {timeActive && (
          <div className="grid grid-flow-col gap-4 items-center">
            <span className="text-main-purple font-bold">Select time:</span>
            <TimePicker onChange={onChangeTime} value={time} clockIcon={null} clearIcon={null} />
          </div>
        )}
        <Button onClick={handleSumbit}>Submit</Button>
      </div>
    </Modal>
  );
};

export { Calendar };
