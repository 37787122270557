import React, { useMemo, useState, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { BotEnableStatus, Panel } from 'ui';
import { CexBotStatusToggler } from 'common/bots';
import { CexWashAlgorithmContextProvider } from 'context/CexWashAlgorithmContext';
import { ECexBot } from 'types/bots';
import { IPanelProps } from 'types/pairs';

import {
  RegularVolumeTable,
  OrganicVolumeTable,
  RegularVolumeToolbar,
  OrganicVolumeToolbar,
} from './components';

import { ColumnChartIcon } from 'assets/icons';
import './cexWashAlgorithm.scss';

const CexWashAlgorithm: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pair = useTypedSelector(store => store.pairs.selectedCexPair);

  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(initialOpened ?? false);

  const pairConnectedBots = useTypedSelector(
    store => store.pairConnectedBots.pairsConnectedBots[pair!.id],
  );

  const isBotEnabled = useMemo(
    () => (pairConnectedBots ? pairConnectedBots[ECexBot.wash_trading] : false),
    [pairConnectedBots],
  );

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="Smart liquidity algorithm"
      icon={ColumnChartIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={<BotEnableStatus status={isBotEnabled ? 'enabled' : 'disabled'} />}
      switcher={<CexBotStatusToggler bot={ECexBot.wash_trading} />}
    >
      {isPanelOpened && (
        <CexWashAlgorithmContextProvider>
          <div className="wash-algorithm">
            <div className="wash-algorithm__all-tasks">
              <RegularVolumeToolbar />
              <RegularVolumeTable />
            </div>
            <div className="wash-algorithm__all-tasks">
              <OrganicVolumeToolbar />
              <OrganicVolumeTable />
            </div>
          </div>
        </CexWashAlgorithmContextProvider>
      )}
    </Panel>
  );
};

export { CexWashAlgorithm };
