import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { formatToken } from 'utils/formats';
import { IPairWallet } from 'api/apiPairs/models';

interface ITokenBaseCellProps {
  row: Row<IPairWallet>;
}

const TokenBaseCell: React.FC<ITokenBaseCellProps> = React.memo(({ row }) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const tokenBaseId = useMemo(() => pair?.token_base.id, [pair]);
  const rowTokenBaseInfo = useMemo(
    () => row.original.tokens?.find(token => token.token.id === tokenBaseId),
    [row, tokenBaseId],
  );

  if (!tokenBaseId || !rowTokenBaseInfo) return <div style={{ textAlign: 'center' }}>{'0'}</div>;

  return (
    <div style={{ textAlign: 'center' }}>
      {formatToken(BigNumber.from(rowTokenBaseInfo.balance), rowTokenBaseInfo.token.decimals)}
    </div>
  );
});

export default TokenBaseCell;
