import { useState, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useTypedDispatch } from 'store';

import { ApiWallets } from 'api';
import { dropAlertState, setAlertState } from 'store/slices/ui';

interface IUseCreateWalletsModalArgs {
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
}

interface IReturnUseCreateWalletsModalArgs {
  loading: boolean;
  initialValues: { amount: undefined | string };
  validationSchema: any;
  handleCreateWallets: ({ amount }: { amount: undefined | string }) => void;
}

const useCreateWalletsModal = ({
  onClose,
  onOpen,
  onSuccess,
}: IUseCreateWalletsModalArgs): IReturnUseCreateWalletsModalArgs => {
  const dispatch = useTypedDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues: { amount: undefined | string } = useMemo(
    () => ({
      amount: undefined,
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        amount: Yup.string().required('Amount is required field'),
      }),
    []
  );

  const handleCreateWallets = useCallback(
    async ({ amount }: { amount: undefined | string }) => {
      if (!amount) return;
      setLoading(true);

      try {
        const { isSuccess, errorMessage } = await ApiWallets.createWallets({ count: Number(amount) });

        setLoading(false);

        if (!isSuccess) {
          onClose();
          dispatch(
            setAlertState({
              type: 'failed-img',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
                onOpen();
              },
            })
          );
        }

        if (isSuccess) {
          onSuccess();
          onClose();
          dispatch(
            setAlertState({
              type: 'success-img',
              text: `Created ${amount} new wallet(s)!`,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
              },
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    [onClose, onOpen, onSuccess]
  );

  return {
    loading,
    initialValues,
    validationSchema,
    handleCreateWallets,
  };
};

export { useCreateWalletsModal };
