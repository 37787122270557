const calculateFix = (amount: number): number => {
  const integer = 0;
  const amountAbs = Math.abs(amount);

  if (amountAbs > 1000000) return 0;

  if (amountAbs > 1000) return 2;

  if (Math.abs(integer) > 100) return 3;

  if (Math.abs(integer) > 10) return 4;

  if (Math.abs(integer) > 1) return 8;

  if (Math.abs(integer) < 1) return 10;

  return 10;
};

export const formatNumberToLocale = (number: string | number, fix?: number) => {
  const decimalPlaces = fix ? fix : calculateFix(Number(number));

  // Use the toLocaleString method to format the number to the current locale
  const formattedNumber = Number(number).toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  // If decimalPlaces is zero, return the formatted number without decimals
  if (decimalPlaces === 0) {
    return formattedNumber;
  }

  // Otherwise, split the formatted number into its integer and fractional parts
  const parts = formattedNumber.split('.');
  let integerPart = parts[0];
  let fractionalPart = '';

  // If there is a fractional part, round it to the specified number of decimal places
  if (parts.length > 1) {
    fractionalPart = parseFloat(`0.${parts[1]}`).toFixed(decimalPlaces).substring(2);
  } else {
    // If there is no fractional part, add zeros to the specified number of decimal places
    fractionalPart = '0'.repeat(decimalPlaces);
  }

  // Remove any trailing zeros from the fractional part
  while (fractionalPart.length > 0 && fractionalPart[fractionalPart.length - 1] === '0') {
    fractionalPart = fractionalPart.slice(0, -1);
  }

  // If there is still a decimal point and no digits after it, remove it as well
  if (fractionalPart.length > 0) {
    fractionalPart = `.${fractionalPart}`;
  }

  // Remove any trailing dots from the integer part
  while (integerPart.length > 1 && integerPart[integerPart.length - 1] === '.') {
    integerPart = integerPart.slice(0, -1);
  }

  // Return the formatted number with the rounded or padded fractional part
  return `${integerPart}${fractionalPart}`;
};
