import React from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import {
  addBignumbers,
  divideBignumbers,
  multiplyBignumbers,
  normalizeBigNumber,
} from 'utils/formulas';
import { IDexBalanceBotTask } from 'types/bots';

interface TotalAmountProps {
  original: IDexBalanceBotTask;
  baseToken: string | undefined;
}

export const TotalAmount: React.FC<TotalAmountProps> = ({ original, baseToken }) => {
  const { add_holder_options } = original;
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const minAmount = add_holder_options?.min_amount ? add_holder_options.min_amount : 0;
  const maxAmount = add_holder_options?.max_amount ? add_holder_options.max_amount : 0;
  const decimals = pair?.token_base.decimals || 18;

  const summarisedValue = addBignumbers(
    [BigNumber.from(minAmount || 0), decimals],
    [BigNumber.from(maxAmount || 0), decimals],
  );

  const averageValue = divideBignumbers([summarisedValue, decimals], [BigNumber.from(2), 1]);

  const totalAmount = multiplyBignumbers([averageValue || 0, decimals], [BigNumber.from(1), 1]);

  const totalAmountValue = normalizeBigNumber(totalAmount, decimals, 0, true);
  const displayedTotalAmount = totalAmount
    ? normalizeBigNumber(totalAmount, 18, +totalAmountValue < 1 ? 3 : 0, true)
    : 0;
  return (
    <div>
      &#126;
      {displayedTotalAmount} {baseToken}
    </div>
  );
};
