import React, { FC, InputHTMLAttributes, memo } from 'react';
import cn from 'classnames';
import './toggleRadio.scss';

type Props = {
  label?: string;
  titles: string[];
  color?: 'primary';
} & Exclude<InputHTMLAttributes<HTMLInputElement>, 'type' | 'id' | 'value' | 'checked'>;

const ToggleRadioComponent: FC<Props> = ({ titles, color = 'primary', label, ...props }) => (
  <div>
    {label && (
      <div
        className={cn(
          props.disabled ? 'text-gray-2' : 'text-main-purple',
          'font-bold mb-1.5 text-sm',
        )}
      >
        {label}
      </div>
    )}
    <div
      className={cn('mm-toggle-radio', `mm-toggle-radio-${color}`, {
        'mm-toggle-radio--disabled': props.disabled,
      })}
    >
      {titles.map(title => (
        <React.Fragment key={title}>
          <input {...props} id={title} type="radio" value={title} checked={props.value === title} />
          <label
            className={cn('mm-toggle-radio__text', `mm-toggle-radio__text--${color}`, {
              'mm-toggle-radio__text--selected': props.value === title,
              'mm-toggle-radio__text--disabled': props.disabled && props.value === title,
              '!cursor-not-allowed': props.disabled,
            })}
            htmlFor={title}
          >
            <span className="mm-toggle-radio__text-node">{title}</span>
          </label>
        </React.Fragment>
      ))}
    </div>
  </div>
);

const ToggleRadio = memo(ToggleRadioComponent);

export { ToggleRadio };
