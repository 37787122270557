import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { mapError } from '../apiBase/mapError';
import { mapResponse } from '../apiBase/mapResponse';
import { ILoginArgs, ILoginResponse } from './models';

class ApiAuth extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = false;

  private static __instance: ApiAuth | undefined;
  private static instance = (() => {
    if (ApiAuth.__instance === undefined) {
      ApiAuth.__instance = new ApiAuth();
    }
    return ApiAuth.__instance;
  })();

  public static login = async ({
    login,
    password,
  }: ILoginArgs): Promise<Response<ILoginResponse>> => {
    let mappedResponseOrError: Response<ILoginResponse>;

    try {
      const response = await ApiAuth.instance.post<IBaseResponse<ILoginResponse>>(
        '/auth/sessions',
        {
          login,
          password,
        },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<ILoginResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static logout = async (): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiAuth.instance.delete<IBaseResponse<any>>('/auth/sessions', {
        manualWithHeader: true,
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiAuth };
