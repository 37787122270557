import { createSlice } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { IDexPairsFilters } from 'types/filters';
import { DEX_PAIR_FILTERS_KEY } from 'constants/cache';

import {
  setupInitialDexPairFiltersReducer,
  setupInitialDexFilterTypeReducer,
  addDexPairFilterReducer,
  updateDexPairFilterReducer,
  deleteDexPairFilterReducer,
  clearDexPairFiltersReducer,
  saveDexPairFiltersReducer,
} from './reducers';

const LSValue = LocalStorage.get<IDexPairsFilters | undefined>(DEX_PAIR_FILTERS_KEY);

const initialState: IDexPairsFilters = LSValue ?? {};

const dexPairFiltersSlice = createSlice({
  name: 'dex_pair_filters',
  initialState: initialState,
  reducers: {
    setupInitialDexPairFilters: setupInitialDexPairFiltersReducer,
    setupInitialDexFilterType: setupInitialDexFilterTypeReducer,
    addDexPairFilter: addDexPairFilterReducer,
    updateDexPairFilter: updateDexPairFilterReducer,
    deleteDexPairFilter: deleteDexPairFilterReducer,
    clearDexPairFilters: clearDexPairFiltersReducer,
    saveDexPairFilters: saveDexPairFiltersReducer,
  },
});

export const {
  setupInitialDexPairFilters,
  setupInitialDexFilterType,
  addDexPairFilter,
  updateDexPairFilter,
  deleteDexPairFilter,
  clearDexPairFilters,
  saveDexPairFilters,
} = dexPairFiltersSlice.actions;

export default dexPairFiltersSlice.reducer;
