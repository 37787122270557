import React from 'react';
import { useTypedSelector } from 'store';

import { Alert } from 'ui';

interface IAlertProviderProps {
  children: React.ReactNode;
}

const AlertProvider: React.FC<IAlertProviderProps> = ({ children }) => {
  const alertState = useTypedSelector(store => store.ui.alert);

  return (
    <>
      {alertState !== undefined && <Alert {...alertState} />}
      {children}
    </>
  );
};

export { AlertProvider };
