import React from 'react';

import { ICexWTRegularTask } from 'types/bots';

interface IMakersProps {
  task: ICexWTRegularTask;
}

export const Makers: React.FC<IMakersProps> = ({ task }) => {
  return (
    <div>
      {task.options.makers_min}-{task.options.makers_max} / {task.options.takers_min}-
      {task.options.takers_max}
    </div>
  );
};
