import { parseUnits } from '@ethersproject/units';
import { isNil } from 'lodash';

import {
  EFilterNumberConnection,
  IFilterScheme,
  IFilterNumberResult,
  IFilterBooleanResult,
  EFilterFormatter,
  EFilterBooleanConnection,
} from 'types/filters/common';

export const naturalNumberTokenFormatter = ({
  value,
  connection,
  scheme,
}: {
  value: string;
  connection: EFilterNumberConnection;
  scheme: IFilterScheme;
}) => {
  const tokenId = scheme.tokenId;

  if (isNil(tokenId)) return undefined;
  if (isNaN(Number(value))) return undefined;

  return `token${tokenId}${connection}${value.toString()}`;
};

export const bnTokenFormatter = ({
  value,
  connection,
  scheme,
}: {
  value: string;
  connection: EFilterNumberConnection;
  scheme: IFilterScheme;
}) => {
  const tokenId = scheme.tokenId;
  const decimals = scheme.decimals;

  if (isNil(tokenId) || isNil(decimals)) return undefined;
  if (isNaN(Number(value))) return undefined;

  const valueStringify = parseUnits(value, decimals).toString();

  return `token${tokenId}${connection}${valueStringify}`;
};

export const booleanFormatter = ({
  value,
  connection,
}: {
  value: boolean;
  connection: EFilterBooleanConnection;
}) => {
  const valueStringify = connection === EFilterBooleanConnection.is ? value : !value;

  return `${valueStringify}`;
};

export const filtersFormatter = ({
  filter,
  formatter,
  scheme,
}: {
  filter: IFilterNumberResult | IFilterBooleanResult;
  formatter: EFilterFormatter | undefined;
  scheme: IFilterScheme;
}) => {
  let result: any = '';

  if (formatter === EFilterFormatter.natural_number_token) {
    result = naturalNumberTokenFormatter({
      value: filter.value as string,
      connection: filter.connection as EFilterNumberConnection,
      scheme,
    });
  } else if (formatter === EFilterFormatter.bn_number_token) {
    result = bnTokenFormatter({
      value: filter.value as string,
      connection: filter.connection as EFilterNumberConnection,
      scheme,
    });
  } else if (formatter === EFilterFormatter.boolean) {
    result = booleanFormatter({
      value: filter.value as boolean,
      connection: filter.connection as EFilterBooleanConnection,
    });
  }

  if (!scheme.field) return {};
  else return { [scheme.field]: result };
};
