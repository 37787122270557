import React from 'react';
import { useTypedSelector } from 'store';

import { Accounts, CreateNewProject, Projects, WalletsSideBar } from './components';

import pgLogo from 'assets/images/pg-logo.png';
import './sidebar.scss';

const Sidebar: React.FC = () => {
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  return (
    <aside className="mm-sidebar">
      <section className="mm-sidebar__header">
        <img src={pgLogo} alt="" />
        <h2 className="mm-sidebar__title">111PG</h2>
      </section>
      <div className="mm-sidebar__separator" />
      <section className="mm-sidebar__content">
        <div className="mm-sidebar__menu">
          {isAdmin && <WalletsSideBar />}
          {isAdmin && <Accounts />}
          <Projects />
          {isAdmin && <CreateNewProject />}
        </div>
      </section>
    </aside>
  );
};

export { Sidebar };
