import React from 'react';
import cn from 'classnames';

import { IBotStatus } from 'types/bots';

import './style.scss';

interface IBotEnableStatusProps {
  status: IBotStatus;
}

const BotEnableStatus: React.FC<IBotEnableStatusProps> = ({ status }) => {
  return <div className={cn('mm-bot-status', { _disabled: status === 'disabled' })} />;
};

export { BotEnableStatus };
