import { IFilterScheme, IFilterSchemeExtended, IDexFilterValue } from 'types/filters/common';
import { filtersFormatter } from './formatters';

export function filtersToValues({
  filters,
  scheme,
}: {
  filters: IDexFilterValue[];
  scheme: Record<string, IFilterSchemeExtended>;
}) {
  const mapper = (node: IFilterSchemeExtended): IFilterScheme[] => {
    if ('name' in node && 'type' in node) {
      return [node];
    }

    if ('filters' in node) {
      return (node.filters as IFilterScheme[]).map(el => mapper(el)).flat();
    }

    return [];
  };

  const flatSchemes = Object.keys(scheme)
    .map(key => mapper(scheme[key]))
    .flat();

  const reversedFilters = [...filters].reverse();

  const values = reversedFilters.map(el => {
    const findScheme = flatSchemes.find(flatScheme => flatScheme.name === el.name);

    if (!findScheme || !el.value) return {};

    return filtersFormatter({
      filter: el.value,
      formatter: findScheme.formatter,
      scheme: findScheme,
    });
  });

  return values;
}
