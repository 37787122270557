import { ENetwork } from 'types';

export const mempoolConfigMap = {
  'Use additionaly': 'on',
  Only: 'only',
  'Do not use': 'off',
} as const;

export const revertedMempoolConfigMap = {
  on: 'Use additionaly',
  only: 'Only',
  off: 'Do not use',
} as const;

export type MempoolConfigMap = keyof typeof mempoolConfigMap;

type ChainSymbol = 'BNB' | 'ETH' | 'MATIC';

export const Network: Record<
  ENetwork,
  { gastrackerUrl: string; unit: 'gwei' | 'ether'; symbol: ChainSymbol; network: ENetwork }
> = {
  bsc: {
    gastrackerUrl: 'https://api.bscscan.com/api?module=gastracker&action=gasoracle',
    unit: 'gwei',
    symbol: 'BNB',
    network: ENetwork.bsc,
  },
  eth: {
    gastrackerUrl: 'https://api.etherscan.com/api?module=gastracker&action=gasoracle',
    unit: 'gwei',
    symbol: 'ETH',
    network: ENetwork.eth,
  },
  polygon: {
    gastrackerUrl: 'https://api.polygonscan.com/api?module=gastracker&action=gasoracle',
    unit: 'gwei',
    symbol: 'MATIC',
    network: ENetwork.polygon,
  },
  arbitrum_one: {
    gastrackerUrl: 'https://arbiscan.io/api?module=gastracker&action=gasoracle',
    unit: 'gwei',
    symbol: 'ETH',
    network: ENetwork.arbitrum,
  },
};
