import React, { useMemo } from 'react';

import { TableAddressChip } from 'ui';
import { ITransfer } from 'types/transfers';

interface IHashProps {
  transfer: ITransfer;
}

const Hash = React.memo(({ transfer }: IHashProps) => {
  const hash = useMemo(() => transfer.hash ?? undefined, [transfer]);

  return hash ? (
    <TableAddressChip address={hash} addressType="transaction" format={'short'} />
  ) : (
    <div>-</div>
  );
});

export { Hash };
