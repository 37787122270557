import React, { useState, useCallback, useContext } from 'react';

import { Button } from 'ui';
import { BotSettingsModal } from 'modals';
import { EDexBot } from 'types/bots';
import { PairBalanceBotContext } from 'context/PairBalanceBotContext';

import { CogIcon } from 'assets/icons';

import './style.scss';

const BotSettingsSection: React.FC = () => {
  const { handleLoadBotSettings, botSettings } = useContext(PairBalanceBotContext);
  const [botSettingsModalOpened, setBotSettingsModalOpened] = useState<boolean>(false);

  const handleSettingBot = useCallback(() => {
    setBotSettingsModalOpened(true);
  }, []);

  return (
    <div className="mm-balance-bot-settings-section">
      <Button color="transparent" className="settings-button" onClick={handleSettingBot}>
        <CogIcon color={'#5932EA'} />
        <span>Settings</span>
      </Button>
      {botSettingsModalOpened && (
        <BotSettingsModal
          onSave={() => {
            handleLoadBotSettings();
          }}
          bot={EDexBot.balance_bot}
          buySellBotSettings={botSettings}
          onOpen={() => setBotSettingsModalOpened(true)}
          onClose={() => setBotSettingsModalOpened(false)}
        />
      )}
    </div>
  );
};

export { BotSettingsSection };
