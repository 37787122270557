import React from 'react';
import { motion } from 'framer-motion';

import './style.scss';

interface IFieldHintProps {
  comment?: string;
  errorMessage?: string;
}

const FieldHints: React.FC<IFieldHintProps> = ({ comment, errorMessage }) => {
  return (
    <>
      {comment && !errorMessage ? (
        <motion.div className="mm-field-comment">
          <motion.span
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            key="comment-message"
          >
            {comment}
          </motion.span>
        </motion.div>
      ) : null}
      {errorMessage ? (
        <motion.div className="mm-field-error">
          <motion.span
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            key="error-message"
          >
            {errorMessage}
          </motion.span>
        </motion.div>
      ) : null}
    </>
  );
};

export { FieldHints };
