import { useCallback, useMemo } from 'react';
import { TableMeta, Row } from '@tanstack/react-table';
import cn from 'classnames';
import { isNil } from 'lodash';

import { IDexBalanceBotTask } from 'types/bots';
import { Spinner, Switcher } from 'ui';

import { OpenIcon } from 'assets/icons/OpenIcon';

interface StatusProps {
  row: Row<IDexBalanceBotTask> | any;
  meta: TableMeta<IDexBalanceBotTask> | undefined;
  handleEnableTask: (task: IDexBalanceBotTask, checked: boolean) => void;
}

export const Status: React.FC<StatusProps> = ({ row, handleEnableTask, meta }) => {
  const _meta = meta as any;

  const onChangeSwitcher = useCallback(
    (flag: boolean) => {
      if (!isNil(_meta.taskEnablingLoading)) return;

      handleEnableTask(row.original, flag);
    },
    [handleEnableTask, row.original, _meta],
  );

  const isEnabled = useMemo(() => !!row.original.add_holder_options?.enabled, [row]);
  const isLoading = useMemo(() => _meta.taskEnablingLoading === row.original.id, [_meta, row]);

  return (
    <div className={cn('switcher-cell', isEnabled && 'task-finished')}>
      {isLoading && <Spinner size="mini" />}
      {!isLoading && (
        <div onClick={e => e.stopPropagation()}>
          <Switcher onChange={onChangeSwitcher} value={isEnabled} />
        </div>
      )}
      <OpenIcon opened={_meta.extendedRow !== undefined && _meta.extendedRow === row.index} />
    </div>
  );
};
