import React from 'react';

import { ICexWTRegularTask } from 'types/bots';

interface IDailyFeesProps {
  task: ICexWTRegularTask;
}

export const DailyFees: React.FC<IDailyFeesProps> = ({ task }) => {
  return <div>??? USDT / $???</div>;
};
