import { isNil } from 'lodash';
import { boolean, number, object, string } from 'yup';

export const validationSchema = () =>
  object({
    enabled: boolean().required(),
    start_at: number()
      .nullable(true)
      .test(
        'start_at',
        '"Start time" field is required',
        start_time => !isNil(start_time) && start_time >= 0,
      ),
    vol_per_group_min: string()
      .required('"Group volume min" field is required')
      .test(
        'vol_per_group_min',
        '"Group volume min" should be more or equal 0',
        (vol_per_group_min: string | undefined) =>
          !isNaN(Number(vol_per_group_min)) && Number(vol_per_group_min) >= 0,
      ),
    vol_per_group_max: string()
      .required('"Group volume max" field is required')
      .test(
        'vol_per_group_max',
        '"Group volume max" should be more or equal volume min',
        (vol_per_group_max: string | undefined, context) => {
          const vol_per_group_min = context.parent.vol_per_group_min;
          return (
            !isNaN(Number(vol_per_group_max)) && Number(vol_per_group_max) >= vol_per_group_min
          );
        },
      ),
    pause_between_groups_min: string()
      .required('"Delay between trade groups min" field is required')
      .test(
        'pause_between_groups_min',
        '"Delay between trade groups min" should be more than 0',
        (pause_between_groups_min: string | undefined) =>
          !isNaN(Number(pause_between_groups_min)) && Number(pause_between_groups_min) >= 0,
      ),
    pause_between_groups_max: string()
      .required('"Delay between trade groups max" field is required')
      .test(
        'pause_between_groups_max',
        '"Delay between trade groups max" should be more or equal groups min',
        (pause_between_groups_max: string | undefined, context) => {
          const pause_between_groups_min = context.parent.pause_between_groups_min;

          return (
            !isNaN(Number(pause_between_groups_max)) &&
            Number(pause_between_groups_max) >= pause_between_groups_min
          );
        },
      ),

    makers_min: number()
      .required('"Number of makers min" field is required')
      .test(
        'makers_min',
        '"Number of makers min" should be grater or equal 0 ',
        (makers_min: number | undefined) => !isNaN(Number(makers_min)) && Number(makers_min) >= 0,
      )
      .test(
        'makers_min',
        '"Number of makers min" should be less or equal takers max',
        (makers_min: number | undefined, context) => {
          const makers_max = context.parent.makers_max;
          return !isNaN(Number(makers_min)) && Number(makers_min) <= Number(makers_max);
        },
      ),
    makers_max: number()
      .required('"Number of makers max" field is required')
      .test(
        'makers_max',
        '"Number of makers max" should be more or equal than min value',
        (makers_max: number | undefined, context) => {
          const makers_min = context.parent.makers_min;
          return !isNaN(Number(makers_max)) && Number(makers_max) >= Number(makers_min);
        },
      )
      .test(
        'makers_max',
        '"Number of makers max" should be grater or equal 0',
        (makers_max: number | undefined) => !isNaN(Number(makers_max)) && Number(makers_max) >= 0,
      ),
    takers_min: number()
      .required('"Number of takers min" field is required')
      .test(
        'takers_min',
        '"Number of takers min" should be grater or equal 0 ',
        (takers_min: number | undefined) => !isNaN(Number(takers_min)) && Number(takers_min) >= 0,
      )
      .test(
        'takers_min',
        '"Number of takers min" should be less or equal takers max',
        (takers_min: number | undefined, context) => {
          const takers_max = context.parent.takers_max;
          return !isNaN(Number(takers_min)) && Number(takers_min) <= Number(takers_max);
        },
      ),
    takers_max: number()
      .required('"Number of takers max" field is required')
      .test(
        'takers_max',
        '"Number of takers max" should be more or equal than min value',
        (takers_max: number | undefined, context) => {
          const takers_min = context.parent.takers_min;
          return !isNaN(Number(takers_max)) && Number(takers_max) >= Number(takers_min);
        },
      )
      .test(
        'takers_max',
        '"Number of takers max" should be grater or equal 0',
        (takers_max: number | undefined) => !isNaN(Number(takers_max)) && Number(takers_max) >= 0,
      ),
    cancel_delay_min: number()
      .required('"Canceling delay" field is required')
      .test(
        'cancel_delay_min',
        '"Canceling delay min" should be grater or equal 0 ',
        (cancel_delay_min: number | undefined) =>
          !isNaN(Number(cancel_delay_min)) && Number(cancel_delay_min) >= 0,
      )
      .test(
        'cancel_delay_min',
        '"Canceling delay min" should be less or equal max',
        (cancel_delay_min: number | undefined, context) => {
          const cancel_delay_max = context.parent.cancel_delay_max;
          return (
            !isNaN(Number(cancel_delay_min)) && Number(cancel_delay_min) <= Number(cancel_delay_max)
          );
        },
      ),
    cancel_delay_max: number()
      .required('"Canceling delay max" field is required')
      .test(
        'cancel_delay_max',
        '"Canceling delay max" should be more or equal than min value',
        (cancel_delay_max: number | undefined, context) => {
          const cancel_delay_min = context.parent.cancel_delay_min;
          return (
            !isNaN(Number(cancel_delay_max)) && Number(cancel_delay_max) >= Number(cancel_delay_min)
          );
        },
      )
      .test(
        'cancel_delay_max',
        '"Canceling delay max" should be grater or equal 0',
        (cancel_delay_max: number | undefined) =>
          !isNaN(Number(cancel_delay_max)) && Number(cancel_delay_max) >= 0,
      ),

    pause_between_orders_min: number()
      .required('"Pause between orders" field is required')
      .test(
        'pause_between_orders_min',
        '"Pause between orders min" should be grater or equal 0 ',
        (pause_between_orders_min: number | undefined) =>
          !isNaN(Number(pause_between_orders_min)) && Number(pause_between_orders_min) >= 0,
      )
      .test(
        'pause_between_orders_min',
        '"Pause between orders  min" should be less or equal max',
        (pause_between_orders_min: number | undefined, context) => {
          const pause_between_orders_max = context.parent.pause_between_orders_max;
          return (
            !isNaN(Number(pause_between_orders_min)) &&
            Number(pause_between_orders_min) <= Number(pause_between_orders_max)
          );
        },
      ),
    pause_between_orders_max: number()
      .required('"Pause between orders max" field is required')
      .test(
        'pause_between_orders_max',
        '"Pause between orders max" should be grater or equal 0',
        (pause_between_orders_max: number | undefined) =>
          !isNaN(Number(pause_between_orders_max)) && Number(pause_between_orders_max) >= 0,
      )
      .test(
        'pause_between_orders_max',
        '"Pause between orders max" should be more or equal than min',
        (pause_between_orders_max: number | undefined, context) => {
          const pause_between_orders_min = context.parent.pause_between_orders_min;
          return (
            !isNaN(Number(pause_between_orders_max)) &&
            Number(pause_between_orders_max) >= Number(pause_between_orders_min)
          );
        },
      ),

    price_selection_strategy: string().required('"Price selection strategy" field is required'),

    rounding_probability: string()
      .required('"Round amount probability" field is required')
      .test(
        'rounding_probability',
        '"Round amount probability" should be less or equal than 100 percent',
        (rounding_probability: string | undefined) =>
          !isNaN(Number(rounding_probability)) && Number(rounding_probability) <= 100,
      )
      .test(
        'rounding_probability',
        '"Round amount probability" should be grater or equal 0 percent',
        (rounding_probability: string | undefined) =>
          !isNaN(Number(rounding_probability)) && Number(rounding_probability) >= 0,
      ),
    not_equal_amount_probability: string()
      .required('"Takers != makers amount probability" field is required')
      .test(
        'not_equal_amount_probability',
        '"Takers != makers amount probability" should be grater or equal 0',
        (not_equal_amount_probability: string | undefined) =>
          !isNaN(Number(not_equal_amount_probability)) && Number(not_equal_amount_probability) >= 0,
      ),
    not_equal_amount_percent_min: string()
      .required('"Takers != makers amount percent min" field is required')
      .test(
        'not_equal_amount_percent_min',
        '"Takers != makers amount percent min" should be less than max value',
        (not_equal_amount_percent_min: string | undefined, context) => {
          const not_equal_amount_percent_max = context.parent.not_equal_amount_percent_max;
          return (
            !isNaN(Number(not_equal_amount_percent_min)) &&
            Number(not_equal_amount_percent_min) <= not_equal_amount_percent_max
          );
        },
      )
      .test(
        'not_equal_amount_percent_min',
        '"Takers != makers amount percent min" should be grater or equal 0 percent',
        (not_equal_amount_percent_min: string | undefined) =>
          !isNaN(Number(not_equal_amount_percent_min)) && Number(not_equal_amount_percent_min) >= 0,
      ),
    not_equal_amount_percent_max: string()
      .required('"Takers != makers amount percent max" field is required')
      .test(
        'not_equal_amount_percent_max',
        '"Takers != makers amount percent max" should be more or equal min percent',
        (not_equal_amount_percent_max: string | undefined, context) => {
          const not_equal_amount_percent_min = context.parent.not_equal_amount_percent_min;
          return (
            !isNaN(Number(not_equal_amount_percent_max)) &&
            Number(not_equal_amount_percent_max) >= not_equal_amount_percent_min
          );
        },
      )
      .test(
        'not_equal_amount_percent_max',
        '"Takers != makers amount percent max" should be grater or equal 0 percent',
        (not_equal_amount_percent_max: string | undefined) =>
          !isNaN(Number(not_equal_amount_percent_max)) && Number(not_equal_amount_percent_max) >= 0,
      ),
    ignore_volume_percent: string()
      .required('"Ignore percent amount" field is required')
      .test(
        'ignore_volume_percent',
        '"Ignore percent amount" should be less than 100 percent',
        (ignore_volume_percent: string | undefined) =>
          !isNaN(Number(ignore_volume_percent)) && Number(ignore_volume_percent) <= 100,
      )
      .test(
        'ignore_volume_percent',
        '"Ignore percent amount" should be grater or equal 0 percent',
        (ignore_volume_percent: string | undefined) =>
          !isNaN(Number(ignore_volume_percent)) && Number(ignore_volume_percent) >= 0,
      ),
  });
