import React, { useMemo, memo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { ITransactionItem, ETransactionAction } from 'api/apiTransactions/models';
import { formatToken, formatFiat } from 'utils/formats';
import { IDexPair } from 'types/pairs';

interface ITransactionAmountProps {
  pair: IDexPair;
  row: ITransactionItem;
}

export const TransactionAmount: React.FC<ITransactionAmountProps> = memo(({ pair, row }) => {
  const { action, id } = useMemo(() => row, [row]);

  const transfers = useMemo(() => row.transfers ?? [], [row]);

  const baseTokenTransfer = useMemo(
    () => transfers.find(transfer => transfer.token.id === pair.token_base.id),
    [transfers, pair.token_base.id],
  );

  const depositWithdrawTransfer = useMemo(
    () => (transfers.length === 1 ? transfers[0] : transfers.filter(el => !el.is_fee)[0]),
    [transfers],
  );

  const buyEthHolderTransfer = useMemo(
    () =>
      transfers.length === 1
        ? transfers[0]
        : transfers.filter(el => !el.is_fee && el.token.id === pair.token_fee.id)[0],
    [transfers, pair.token_fee.id],
  );

  const amounts = useMemo(() => {
    let innerToken;

    if (
      action === ETransactionAction.ActionBuy ||
      action === ETransactionAction.ActionBulkBuy ||
      action === ETransactionAction.ActionBulkBuyMempool ||
      action === ETransactionAction.ActionAFRBuy ||
      action === ETransactionAction.ActionSell ||
      action === ETransactionAction.ActionBulkSell ||
      action === ETransactionAction.ActionBulkSellMempool ||
      action === ETransactionAction.ActionAFRSell
    ) {
      innerToken = baseTokenTransfer;
    } else if (
      action === ETransactionAction.ActionDeposit ||
      action === ETransactionAction.ActionWithdrawal
    ) {
      innerToken = depositWithdrawTransfer;
    } else if (
      action === ETransactionAction.ActionBuyEth ||
      action === ETransactionAction.ActionSellEth
    ) {
      innerToken = baseTokenTransfer;
    } else if (action === ETransactionAction.ActionBuyEthHolder) {
      innerToken = buyEthHolderTransfer;
    }

    if (!innerToken)
      return {
        amount_token: null,
        amount_usd: null,
      };

    return {
      amount_token: formatToken(BigNumber.from(innerToken.amount), innerToken.token.decimals),
      amount_usd: formatFiat(BigNumber.from(innerToken.amount_usd), innerToken.token.decimals),
    };
  }, [action, baseTokenTransfer, depositWithdrawTransfer, buyEthHolderTransfer]);

  if (action === ETransactionAction.ActionApproval) return <>{''}</>;

  if (!transfers || transfers.length === 0 || !pair) return <>{''}</>;

  if (!amounts.amount_token || !amounts.amount_usd) return <></>;

  return (
    <div className="transaction-amount">
      <span data-tooltip-id={id + '-amount-usd'}>{amounts.amount_token}</span>
      <ReactTooltip place="top" id={id + '-amount-usd'} className="transaction-amount__usd-tooltip">
        {'$ ' + amounts.amount_usd}
      </ReactTooltip>
    </div>
  );
});
