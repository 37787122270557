import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface IApiKeyAccountCellProps {
  row: Row<ICexAccounts>;
}

const ApiKeyAccountCell: React.FC<IApiKeyAccountCellProps> = React.memo(({ row }) => {
  return <>{row.original.api_key_masked}</>;
});

export default ApiKeyAccountCell;
