import React, { useContext, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import { RangeInput } from 'ui';

import { MAX_VOLATILITY, MIN_VOLATILITY, DEFAULT_VOLATILITY } from 'types/wash-trading-bot';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import useScroll from './useScroll';

import './style.scss';

const Volatility: React.FC = () => {
  const { volatilityCoefficient, currentConfig, chartLoading } = useContext(PairWashTradingContext);

  const { innerCoefficient, scrollPos, setScrollPos } = useScroll({
    coefficient: volatilityCoefficient.get,
    setCoefficient: volatilityCoefficient.set,
    points: {
      initial: DEFAULT_VOLATILITY,
      max: MAX_VOLATILITY,
      min: MIN_VOLATILITY,
    },
  });

  const coefficient = useMemo(() => volatilityCoefficient.get, [volatilityCoefficient.get]);
  const [lastUsedCoefficient, setLastUseCoefficient] = useState<number>(coefficient);

  useDebounce(
    () => {
      if (
        currentConfig.get &&
        currentConfig.get.config?.random_strategy &&
        (!lastUsedCoefficient || lastUsedCoefficient !== coefficient)
      ) {
        const newMinPricePercentage = Number(
          (
            (currentConfig.get.config.random_strategy.min_price_percentage * coefficient) /
            lastUsedCoefficient
          ).toFixed(5)
        );
        const newMaxPricePercentage = Number(
          (
            (currentConfig.get.config.random_strategy.max_price_percentage * coefficient) /
            lastUsedCoefficient
          ).toFixed(5)
        );

        currentConfig.set({
          ...currentConfig.get,
          config: {
            ...currentConfig.get.config,
            random_strategy: {
              ...currentConfig.get.config.random_strategy,
              min_price_percentage: newMinPricePercentage,
              max_price_percentage: newMaxPricePercentage,
            },
          },
        });
        setLastUseCoefficient(coefficient);
      }
    },
    100,
    [coefficient, lastUsedCoefficient]
  );

  return (
    <div className="mm-wt-bot-scroll-controller">
      <div className="controller-head">
        <span>Volatility:</span>
        <span>{innerCoefficient.toFixed(4)}</span>
      </div>
      <RangeInput
        value={scrollPos}
        min={0}
        max={100}
        step={1}
        setValue={setScrollPos}
        disabled={!!chartLoading.get}
      />
    </div>
  );
};

export default Volatility;
