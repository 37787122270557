import React from 'react';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';

interface ISeriePauseCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const SeriePauseCell: React.FC<ISeriePauseCellProps> = ({ task }) => {
  return (
    <div>
      {task.min_pause_between_series} / {task.max_pause_between_series}
    </div>
  );
};

export { SeriePauseCell };
