import React, { useState, useCallback } from 'react';
import { useTypedSelector } from 'store';

import { AutoRefresh, Panel, TableWrapper } from 'ui';
import { CexAccountsTable } from 'tables';
import { PROJECT_CEX_ACCOUNTS_RELOAD } from 'constants/reload';
import { IPanelProps } from 'types/pairs';

import { AccountsIcon } from 'assets/icons/AccountsIcon';

const CexAccounts: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  const pair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const [isPanelOpened, setIsPanelOpened] = useState<boolean>(false);

  const handleSwitch = useCallback(
    (opened: boolean) => {
      setIsPanelOpened(opened);
      setIsOpened?.(opened);
    },
    [setIsOpened],
  );

  return (
    <Panel
      title="Accounts"
      icon={AccountsIcon}
      initialOpened={initialOpened}
      onChange={handleSwitch}
      headerNode={
        isPanelOpened && (
          <div className="mm-project-statistic-panel-auto-refresh">
            <AutoRefresh
              busEvent={PROJECT_CEX_ACCOUNTS_RELOAD}
              tooltipText={'Auto refresh visible cex accounts'}
              pairId={pair.id}
              cacheName="cex-accounts"
            />
          </div>
        )
      }
    >
      <TableWrapper>
        <div className="wash-algorithm__all-tasks">
          <CexAccountsTable />
        </div>
      </TableWrapper>
    </Panel>
  );
};

export { CexAccounts };
