import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { IDexBalanceBotTask } from 'types/bots';
import { durationToMs } from 'utils/duration';

dayjs.extend(duration);

interface FinishInProps {
  original: IDexBalanceBotTask;
}

export const FinishIn: React.FC<FinishInProps> = ({ original }) => {
  const { add_holder_options, destination_wallets_count, buy_eth_holder_swaps, is_active } =
    original;

  if (!add_holder_options) return <div></div>;

  const maxPause = durationToMs(add_holder_options.max_pause);
  const minPause = durationToMs(add_holder_options.min_pause);

  const finishIn = ((minPause + maxPause) / 2) * (destination_wallets_count - buy_eth_holder_swaps);

  const finishInDate =
    finishIn === 0 ? 'Finished' : dayjs.duration(finishIn, 'milliseconds').humanize();

  if (is_active && finishIn === 0) return <div></div>;

  return <div>{finishInDate}</div>;
};
