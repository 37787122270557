import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const RequiredTime: React.FC<{ timeLeftInSec: number }> = ({ timeLeftInSec }) => {
  const timLeftDuration = dayjs.duration(timeLeftInSec * 1000).humanize();

  return <>{timLeftDuration}</>;
};
