import React, { useCallback } from 'react';
import cn from 'classnames';

import { BaseSelectField } from '../base/BaseSelectField';

import './style.scss';

interface ITimeframe {
  label: string;
  value: string;
}

interface ISelectTimeframeFieldProps {
  label?: string;
  placeholder?: string;
  items: ITimeframe[];
  selectedItem?: ITimeframe;
  onSelectItem: (v: ITimeframe | undefined) => void;
  disabled?: boolean;
  errorMessage?: string;
  comment?: string;
}

const SelectTimeframeField: React.FC<ISelectTimeframeFieldProps> = ({
  label,
  placeholder,
  items,
  selectedItem,
  onSelectItem,
  errorMessage,
  comment,
  disabled = false,
}) => {
  const renderItem = useCallback(
    (item: ITimeframe) => {
      const isItemSelected = selectedItem && item.value === selectedItem.value;

      return (
        <div className={cn('mm-select-timeframe-field-item', { _selected: isItemSelected })}>
          {item.label}
        </div>
      );
    },
    [selectedItem],
  );

  return (
    <BaseSelectField
      label={label}
      placeholder={placeholder}
      items={items}
      disabled={disabled}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
      renderItem={renderItem}
      selectedItemText={selectedItem ? selectedItem.label : undefined}
      errorMessage={errorMessage}
      comment={comment}
    />
  );
};

export { SelectTimeframeField };
