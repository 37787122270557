import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useTypedDispatch } from 'store';

import { Modal, Button, ButtonLoading, JsonEditor } from 'ui';
import { ApiWTBot } from 'api';
import { InputField } from 'fields';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import './style.scss';

interface IWTCreatePrsetModalProps {
  onClose: () => void;
}

const WTCreatePresetModal: React.FC<IWTCreatePrsetModalProps> = ({ onClose }) => {
  const { botSettings, handleLoadPresets } = useContext(PairWashTradingContext);
  const dispatch = useTypedDispatch();
  const [name, setName] = useState<string>('');
  const [config, setConfig] = useState<any>({});
  const [jsonError, setJsonError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formValid = useMemo(() => {
    return name !== '' && !jsonError;
  }, [name, jsonError]);

  const handleChangeConfig = useCallback((newConfig: string) => {
    try {
      const newConfigParsed = JSON.parse(newConfig);
      setConfig(newConfigParsed);
      setJsonError(false);
    } catch (error) {
      setJsonError(true);
    }
  }, []);

  const handleCreateProfile = useCallback(async () => {
    if (!botSettings) return;

    try {
      setLoading(true);

      const { isSuccess, errorMessage } = await ApiWTBot.createWTPreset({
        name: name,
        config: {
          random_strategy: config.config.random_strategy ?? {},
          volumes_strategy: config.config.volumes_strategy ?? {},
          send_private_transactions: botSettings.send_private_transactions,
          slippage_percent: botSettings.slippage_percent,
        },
      });

      if (isSuccess) {
        dispatch(
          setAlertState({
            type: 'success',
            text: 'Successfully created new preset!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        onClose();
        handleLoadPresets();
      } else {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Failed while creating new preset...',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [name, config, handleLoadPresets]);

  return (
    <Modal title="Create profile" onClose={onClose}>
      <div className="mm-wt-bot-create-preset-modal-container">
        <InputField
          label="Profile name"
          type="text"
          value={name}
          setValue={(v: string | undefined) => setName(v ?? '')}
        />
        <JsonEditor config={config} delay={300} onChange={handleChangeConfig} />
        {loading && <ButtonLoading />}
        {!loading && (
          <Button disabled={!formValid} onClick={handleCreateProfile}>
            Create
          </Button>
        )}
      </div>
    </Modal>
  );
};

export { WTCreatePresetModal };
