import { BigNumber } from '@ethersproject/bignumber';

import { IWalletToken } from 'api/apiWallets/models';
import { addBignumbers } from 'utils/formulas';

export const calculateTokensUsdBalance = (tokens?: IWalletToken[]) => {
  if (!tokens) {
    return BigNumber.from('0');
  }

  const totalBalance = tokens.reduce((acc, val) => {
    return addBignumbers([acc, 18], [BigNumber.from(val.balance_usd), 6]);
  }, BigNumber.from('0'));

  return totalBalance;
};
