import React, { useCallback, useContext } from 'react';

import { JsonEditor } from 'ui';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';

import './advanced.scss';
import 'jsoneditor/dist/jsoneditor.min.css';

const Advanced: React.FC = () => {
  const { currentConfig } = useContext(PairWashTradingContext);

  const handleChangeConfig = useCallback(
    (config: string) => {
      try {
        const parsedConfig = JSON.parse(config);
        currentConfig.set(parsedConfig);
      } catch (error) {
        console.log('error: ', error);
      }
    },

    //eslint-disable-next-line
    [currentConfig.set],
  );

  return (
    <div className="mm-wt-bot-advanced-section">
      <span className="advanced-config-title">Result config:</span>
      <JsonEditor config={currentConfig.get ?? {}} onChange={handleChangeConfig} delay={300} />
    </div>
  );
};

export default Advanced;
