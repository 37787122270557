import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { injected } from 'web3/connector';

const useEagerConnect = () => {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    const loginMethod = localStorage.getItem('market-maker/web3-auth-method');

    if (loginMethod === 'metamask') {
      injected
        .isAuthorized()
        .then(isAuthorized => {
          if (isAuthorized) {
            activate(injected, undefined, true).catch(() => {
              setTried(true);
            });
          } else {
            setTried(true);
          }
        })
        .catch(() => {});
    }
  }, [activate]);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return { tried };
};

export { useEagerConnect };
