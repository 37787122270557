import { ENetwork } from 'types';

const ADDRESS_TEXT = '/address/';
const TX_TEXT = '/tx/';
const TOKEN_TEXT = '/token/';

export const calculateScanLink = ({
  address,
  network,
  addressType,
}: {
  address: string;
  network: ENetwork;
  addressType: 'transaction' | 'address' | 'token';
}): string => {
  const BSC_SCAN_URL = process.env.REACT_APP_BSC_SCAN;
  const ETH_SCAN_URL = process.env.REACT_APP_ETH_SCAN;
  const POLYGON_SCAN_URL = process.env.REACT_APP_POLYGON_SCAN;
  const ARBI_SCAN_URL = process.env.REACT_APP_ARBI_SCAN_URL;

  let slug = '';
  if (addressType === 'transaction') {
    slug = TX_TEXT;
  } else if (addressType === 'token') {
    slug = TOKEN_TEXT;
  } else if (addressType === 'address') {
    slug = ADDRESS_TEXT;
  }

  if (network === ENetwork.bsc) {
    return BSC_SCAN_URL + slug + address;
  }

  if (network === ENetwork.eth) {
    return ETH_SCAN_URL + slug + address;
  }

  if (network === ENetwork.polygon) {
    return POLYGON_SCAN_URL + slug + address;
  }

  if (network === ENetwork.arbitrum) {
    return ARBI_SCAN_URL + slug + address;
  }

  return '';
};
