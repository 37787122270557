import React from 'react';

import TradeSizeController from './ScrollbarControllers/TradeSize';
import TradeFrequencyController from './ScrollbarControllers/TradeFrequency';
import VolatilityController from './ScrollbarControllers/Volatility';

import './quickStart.scss';

const QuickStart: React.FC = () => {
  return (
    <div className="mm-wt-bot-quick-section">
      <div className="mm-wt-bot-ranges">
        <TradeFrequencyController />
        <VolatilityController />
        <TradeSizeController />
      </div>
    </div>
  );
};

export default QuickStart;
