import React from 'react';

import PairChartsContextProvider from 'context/PairChartsContext/PairChartsContext';
import { EExchange } from 'types';

import { LightweightChart } from '../LightweighChart/Chart';

import './style.scss';

interface IContentWrapperProps {
  exchange: EExchange;
}

const ContentWrapper: React.FC<IContentWrapperProps> = ({ exchange }) => {
  return (
    <PairChartsContextProvider exchange={exchange}>
      <div className="mm-pair-charts-container">
        <LightweightChart />
      </div>
    </PairChartsContextProvider>
  );
};

export default ContentWrapper;
