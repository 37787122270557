import React, { useCallback, useMemo, useState } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { useTypedSelector, useTypedDispatch } from 'store';

import { Modal, TableCheckbox, TableAddressChip, Button, ButtonLoading, ErrorText } from 'ui';
import { ConnectNewWalletsToPairForm } from 'ui/forms';
import { IPairWallet } from 'api/apiPairs/models';
import { ApiPairs } from 'api';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { addBignumbers } from 'utils/formulas';
import { formatToken } from 'utils/formats';

import './disconnectWalletsFromPairModal.scss';

interface IDisconnectWalletsFromPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
  wallets: IPairWallet[];
}

const DisconnectWalletsFromPairModal: React.FC<IDisconnectWalletsFromPairModalProps> = ({
  onClose,
  onOpen,
  onSuccess,
  wallets,
}) => {
  const dispatch = useTypedDispatch();
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [validateError, setValidateError] = useState<string | null>(null);

  const [rowSelection, setRowSelection] = React.useState<Record<number, boolean>>(
    wallets.reduce((acc, val, index) => ({ ...acc, [index]: true }), {}),
  );

  const selectedWallets = useMemo(() => {
    const selectedRows: IPairWallet[] = [];

    if (!wallets) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(wallets[Number(key)]);
    }

    return selectedRows;
  }, [rowSelection, wallets]);

  const columns = useMemo<ColumnDef<IPairWallet>[]>(
    () => [
      {
        id: 'select-deactivate',
        accessorFn: row => row,
        header: ({ table }) => (
          <div className="checkbox-area">
            <TableCheckbox
              id="select-all-deactivate"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
            <span>Wallet address</span>
          </div>
        ),
        cell: ({ row }) => {
          return (
            <div className="checkbox-area">
              <TableCheckbox
                id={uuid()}
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
              />
              <TableAddressChip
                address={row.original.address}
                network={pair?.network}
                addressType={'address'}
              />
            </div>
          );
        },
      },
      {
        id: 'token-base-deactivate',
        header: () => <div style={{ textAlign: 'center' }}>{pair?.token_base.symbol}</div>,
        accessorFn: row => row,
        cell: ({ row }) => {
          const tokenBaseId = pair?.token_base.id;
          if (!tokenBaseId) return '0';

          const rowTokenBaseInfo = row.original.tokens?.find(
            token => token.token.id === tokenBaseId,
          );
          if (!rowTokenBaseInfo) return '0';

          return (
            <div style={{ textAlign: 'center' }}>
              {formatToken(
                BigNumber.from(rowTokenBaseInfo.balance),
                rowTokenBaseInfo.token.decimals,
              )}
            </div>
          );
        },
      },
      {
        id: 'token-quote-deactivate',
        header: () => <div style={{ textAlign: 'center' }}>{pair?.token_quote.symbol}</div>,
        accessorFn: row => row,
        cell: ({ row }) => {
          const tokenQuoteId = pair?.token_quote.id;
          if (!tokenQuoteId) return <div style={{ textAlign: 'center' }}>0</div>;

          const rowTokenQuoteInfo = row.original.tokens?.find(
            token => token.token.id === tokenQuoteId,
          );
          if (!rowTokenQuoteInfo) return <div style={{ textAlign: 'center' }}>0</div>;

          return (
            <div style={{ textAlign: 'center' }}>
              {formatToken(
                BigNumber.from(rowTokenQuoteInfo.balance),
                rowTokenQuoteInfo.token.decimals,
              )}
            </div>
          );
        },
      },
      {
        id: 'token-fee-deactivate',
        header: () => <div style={{ textAlign: 'center' }}>{pair?.token_fee.symbol}</div>,
        accessorFn: row => row,
        cell: ({ row }) => {
          const tokenFeeId = pair?.token_fee.id;
          if (!tokenFeeId) return <div style={{ textAlign: 'center' }}>0</div>;

          const rowTokenFeeInfo = row.original.tokens?.find(token => token.token.id === tokenFeeId);
          if (!rowTokenFeeInfo) return <div style={{ textAlign: 'center' }}>0</div>;

          return (
            <div style={{ textAlign: 'center' }}>
              {formatToken(BigNumber.from(rowTokenFeeInfo.balance), rowTokenFeeInfo.token.decimals)}
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: useMemo(() => wallets ?? [], [wallets]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  const handleDisconnectWallets = useCallback(async () => {
    if (!pair) return;
    if (selectedWallets.length === 0) {
      setValidateError('Select at least one wallet');
      return;
    }

    const rowHasAmounts = selectedWallets.find(
      el =>
        el.tokens &&
        el.tokens.find(
          token => token.balance && !BigNumber.from(token.balance).eq(BigNumber.from('0')),
        ),
    );

    if (rowHasAmounts) {
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Some of selected wallets has non-zero token balace. Withdraw non-zero amounts before deactivating',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
      return;
    }

    try {
      setRequestLoading(true);

      const { errorMessage, isSuccess } = await ApiPairs.deleteWalletsFromPair({
        pairId: pair.id,
        walletIds: selectedWallets.map(selectedRow => selectedRow.id),
      });

      if (isSuccess) {
        onSuccess();
        onClose();
        dispatch(
          setAlertState({
            type: 'success-img',
            text: `You deactivated ${selectedWallets.length} from pair: ${pair.symbol}`,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      } else {
        onClose();
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              onOpen();
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestLoading(false);
    }
  }, [pair, selectedWallets, onSuccess, onClose, onOpen, dispatch, setAlertState, dropAlertState]);

  const totalBaseTokenAmount: BigNumber = useMemo(() => {
    const amount = selectedWallets.reduce((acc, val) => {
      const rowTokenBase =
        val.tokens && val.tokens.find(token => token.token.id === pair?.token_base.id);

      if (rowTokenBase) {
        return addBignumbers(
          [acc, pair?.token_base.decimals ?? 18],
          [BigNumber.from(rowTokenBase.balance), pair?.token_base.decimals ?? 18],
        );
      } else {
        return acc;
      }
    }, BigNumber.from('0'));

    return amount;
  }, [selectedWallets, pair]);

  const totalQuoteTokenAmount: BigNumber = useMemo(() => {
    const amount = selectedWallets.reduce((acc, val) => {
      const rowTokenQuote =
        val.tokens && val.tokens.find(token => token.token.id === pair?.token_quote.id);

      if (rowTokenQuote) {
        return addBignumbers(
          [acc, pair?.token_quote.decimals ?? 18],
          [BigNumber.from(rowTokenQuote.balance), pair?.token_quote.decimals ?? 18],
        );
      } else {
        return acc;
      }
    }, BigNumber.from('0'));

    return amount;
  }, [selectedWallets, pair]);

  const totalFeeTokenAmount: BigNumber = useMemo(() => {
    const amount = selectedWallets.reduce((acc, val) => {
      const rowTokenFee =
        val.tokens && val.tokens.find(token => token.token.id === pair?.token_fee.id);

      if (rowTokenFee) {
        return addBignumbers(
          [acc, pair?.token_fee.decimals ?? 18],
          [BigNumber.from(rowTokenFee.balance), pair?.token_fee.decimals ?? 18],
        );
      } else {
        return acc;
      }
    }, BigNumber.from('0'));

    return amount;
  }, [selectedWallets, pair]);

  const { rows } = table.getRowModel();

  return (
    <Modal onClose={onClose} title={`Disconnect wallets : ${selectedWallets.length}`}>
      <ConnectNewWalletsToPairForm handleSubmit={handleDisconnectWallets}>
        <div className="disconnect-new-wallets-to-pair-modal-content">
          <div className="disconnect-new-wallets-to-pair-modal-content__table-container scrollable">
            <table className="mm-common-table">
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    className={cn({ _is_selected: selectedWallets.length === wallets.length })}
                  >
                    {headerGroup.headers.map(header => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {rows.map(row => {
                  const isSelected = !!selectedWallets.find(el => el.id === row.original.id);

                  return (
                    <tr key={row.index} className={cn({ _is_selected: isSelected })}>
                      {row
                        ? row.getVisibleCells().map(cell => {
                            return (
                              <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })
                        : null}
                    </tr>
                  );
                })}
                <tr className="mm-common-table__total-row">
                  <th className="mm-common-table__total-row__title">Residual balance amount</th>
                  <th className="mm-common-table__total-row__value">
                    {formatToken(totalBaseTokenAmount, pair?.token_base.decimals)}
                  </th>
                  <th className="mm-common-table__total-row__value">
                    {formatToken(totalQuoteTokenAmount, pair?.token_quote.decimals)}
                  </th>
                  <th className="mm-common-table__total-row__value">
                    {formatToken(totalFeeTokenAmount, pair?.token_fee.decimals)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ErrorText>{validateError ?? undefined}</ErrorText>
        {!requestLoading && <Button type="submit">Disconnect</Button>}
        {requestLoading && <ButtonLoading />}
      </ConnectNewWalletsToPairForm>
    </Modal>
  );
};

export { DisconnectWalletsFromPairModal };
