import React from 'react';

import './createWithdrawWhitelistForm.scss';

interface ICreateWithdrawWhitelistFormProps {
  handleSubmit: () => void;
  children: React.ReactNode;
}

const CreateWithdrawWhitelistForm: React.FC<ICreateWithdrawWhitelistFormProps> = ({
  handleSubmit,
  children,
}) => {
  return (
    <form className="mm-create-withdraw-whitelist-form" onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export { CreateWithdrawWhitelistForm };
