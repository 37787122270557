import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';

import { ICexWTRegularTask } from 'types/bots';
import { normalizeBigNumber } from 'utils/formulas';

interface IGroupVolumeProps {
  task: ICexWTRegularTask;
}

export const GroupVolume: React.FC<IGroupVolumeProps> = ({ task }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const decimals = useMemo(() => 18, []);

  return (
    <div>
      {normalizeBigNumber(task.options.vol_per_group_min, decimals, decimals, false)} -{' '}
      {normalizeBigNumber(task.options.vol_per_group_max, decimals, decimals, false)}{' '}
      {cexPair.token_base.symbol}
    </div>
  );
};
