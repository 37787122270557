import React from 'react';

import { ChartRangeController } from 'ui';
import { IChartRange } from 'types/charts';
import { STATISTIC_RANGES } from 'constants/tables';

import './style.scss';

interface IStatisticSelectRange {
  selectedRange: { get: IChartRange | undefined; set: (v: IChartRange | undefined) => void };
  startDate: { get: number | undefined; set: (v: number | undefined) => void };
  endDate: { get: number | undefined; set: (v: number | undefined) => void };
  loading: boolean;
}

const StatisticSelectRange: React.FC<IStatisticSelectRange> = ({
  selectedRange,
  startDate,
  endDate,
  loading,
}) => {
  return (
    <ChartRangeController
      supportedRanges={STATISTIC_RANGES.map(el => el.label)}
      selectedRange={selectedRange.get}
      setSelectedRange={range => {
        selectedRange.set(range);
      }}
      startDate={startDate.get}
      setStartDate={newStartDate => {
        startDate.set(newStartDate);
      }}
      endDate={endDate.get}
      setEndDate={newEndDate => {
        endDate.set(newEndDate);
      }}
      disabled={loading}
    />
  );
};

export default StatisticSelectRange;
