import React, { useContext, useEffect } from 'react';

import { PairAFRBotContext } from 'context/PairAFRBotContext';
import { PanelLoading, PanelError } from 'ui';
import Form from '../Form/Form';
import Toolbar from '../Toolbar/Toolbar';

import './content.scss';

const Content: React.FC = () => {
  const { handleLoadBotSettings, loading, errorMessage } = useContext(PairAFRBotContext);

  useEffect(() => {
    handleLoadBotSettings();
  }, []);

  if (loading.get) return <PanelLoading />;

  return (
    <div className="mm-afr-bot-panel">
      <Toolbar />
      {!loading.get && errorMessage && <PanelError title={'Failed'} text={errorMessage} />}
      {!loading.get && !errorMessage && <Form />}
    </div>
  );
};

export default Content;
