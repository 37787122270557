import React from 'react';

import { Button } from 'ui';
import { useMetamaskAuth } from 'hooks';

import './connectWalletButton.scss';

const ConnectWalletButton: React.FC = () => {
  const { connect } = useMetamaskAuth();

  return (
    <div className="mm-connect-wallet-button">
      <Button onClick={connect}>Connect wallet</Button>
    </div>
  );
};

export { ConnectWalletButton };
