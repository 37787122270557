import dayjs from 'dayjs';

export const rangeToInterval = (range: string) => {
  return (
    {
      '1D': 300,
      '7D': 300,
      '1M': 3600,
      '3M': 3600,
      '1Y': 86400,
      ALL: 86400,
    }[range] ?? 86400
  );
};

export const rangeToStartEndTimes = (range: string) => {
  return (
    {
      '1D': {
        startTime: Math.floor(dayjs().subtract(1, 'day').valueOf() / 1000),
        endTime: Math.floor(dayjs().valueOf() / 1000),
      },
      '7D': {
        startTime: Math.floor(dayjs().subtract(7, 'days').valueOf() / 1000),
        endTime: Math.floor(dayjs().valueOf() / 1000),
      },
      '1M': {
        startTime: Math.floor(dayjs().subtract(1, 'month').valueOf() / 1000),
        endTime: Math.floor(dayjs().valueOf() / 1000),
      },
      '3M': {
        startTime: Math.floor(dayjs().subtract(3, 'months').valueOf() / 1000),
        endTime: Math.floor(dayjs().valueOf() / 1000),
      },
      '1Y': {
        startTime: Math.floor(dayjs().subtract(1, 'year').valueOf() / 1000),
        endTime: Math.floor(dayjs().valueOf() / 1000),
      },
      ALL: {
        startTime: 0,
        endTime: Math.floor(dayjs().valueOf() / 1000),
      },
    }[range] ?? {
      startTime: 0,
      endTime: Math.floor(dayjs().valueOf() / 1000),
    }
  );
};

export const rangeToLimit = (range: string) => {
  const { endTime, startTime } = rangeToStartEndTimes(range);
  const interval = rangeToInterval(range);

  const limit = Math.floor((endTime - startTime) / interval);

  return limit;
};

export const convertStartEndTime = (startTime: number, endTime: number) => {
  const diff = endTime - startTime;
  let interval = 0;

  const SEVEN_DAY = 3600 * 24 * 7;
  const THREE_MONTH = 3600 * 24 * 30;

  if (diff < SEVEN_DAY) {
    interval = 5 * 60;
  } else if (diff < THREE_MONTH) {
    interval = 60 * 60;
  } else {
    interval = 86400;
  }

  const limit = Math.floor((endTime - startTime) / interval);

  return { limit, interval };
};

export const chartFormatter = (num: number) => {
  const numStr = num.toString();

  const [integerPart, decimalPart] = numStr.split('.');

  if (!decimalPart) {
    return numStr;
  }

  if (Number(integerPart) > 10000) return integerPart;

  if (Number(integerPart) > 100) {
    const truncatedDecimal = decimalPart.slice(0, 2);
    return `${integerPart}.${truncatedDecimal}`;
  }

  if (Number(integerPart) > 1) {
    const truncatedDecimal = decimalPart.slice(0, 3);
    return `${integerPart}.${truncatedDecimal}`;
  }

  let lastNonZeroIndex = 0;

  for (let i = 0; i <= decimalPart.length - 1; i++) {
    if (decimalPart[i] === '0') {
      lastNonZeroIndex = i;
    }

    if (decimalPart[i] !== '0') {
      break;
    }
  }

  const digitsAfterZero = Math.min(lastNonZeroIndex + 4, decimalPart.length);
  const truncatedDecimal = decimalPart.slice(0, digitsAfterZero);
  const result = `${integerPart}.${truncatedDecimal}`;

  return result;
};
