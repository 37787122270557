import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IWallet } from './models';

class ApiWallets extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiWallets | undefined;
  private static instance = (() => {
    if (ApiWallets.__instance === undefined) {
      ApiWallets.__instance = new ApiWallets();
    }
    return ApiWallets.__instance;
  })();

  public static getWallets = async ({
    limit,
    lastSeenId,
    projectIds,
    pairsIds,
  }: {
    limit: number;
    lastSeenId: number;
    projectIds?: string[];
    pairsIds?: string[];
  }): Promise<Response<{ has_next: boolean; items: IWallet[] }>> => {
    let mappedResponseOrError: Response<{ has_next: boolean; items: IWallet[] }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('last_seen_id', lastSeenId.toString());

    try {
      const response = await ApiWallets.instance.get<
        IBaseResponse<{ has_next: boolean; items: IWallet[] }>
      >('/wallets?' + urlParams.toString());

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{ has_next: boolean; items: IWallet[] }>({ error });
    }

    return mappedResponseOrError;
  };

  public static createWallets = async ({ count }: { count: number }) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiWallets.instance.post<IBaseResponse<any>>('/wallets', { count });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiWallets };
