import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useTypedDispatch } from 'store';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { useScrollPagination } from 'hooks';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { TableAction, TableActionIcon, TableProjects, TableToolbar } from 'ui';
import { Bus } from 'tools';
import {
  STOP_RELOAD_PREFIX,
  PROJECT_WALLETS_RELOAD,
  PROJECT_ACCOUNT_RELOAD,
} from 'constants/reload';
import { ApiAccounts } from 'api/apiAccounts';

import { CreateNewAccountModal } from 'modals/CreateNewAccountModal/CreateNewAccountModal';
import { IInitialModalAccount } from 'modals/CreateNewAccountModal/useCreatePairModal/models/IForm';
import { initialForm } from 'modals/CreateNewAccountModal/useCreatePairModal/useAddAccountModal';
import CexAccountCell from './columns/CexAccountCell';
import CexAccountHead from './headers/CexAccountHead';
import NotesAccountCell from './columns/NotesAccountCell';
import ApiKeyAccountCell from './columns/ApiKeyAccountCell';
import ActionButtonsAccountCell from './columns/ActionButtonsAccountCell';
import TokensAccountsCell from 'tables/cex/CexAccountsTable/CexAccountsCell/TokensAccountsCell';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

import { ExitIcon, PlusIcon } from 'assets/icons';
import './table.scss';

export const initialModalAccount: IInitialModalAccount = {
  mode: 'new',
  item: initialForm,
};

const AccountsTable: React.FC = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useTypedDispatch();

  const [createNewAccountModalOpened, setCreateNewAccountModalOpened] = useState(false);
  const [triggerAccountsTableRemount, setTriggerAccountsTableRemount] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<Record<number, boolean>>({});
  const [records, setRecords] = useState<ICexAccounts[] | undefined>(undefined);
  const [virtualRecords, setVirtualRecords] = useState<ICexAccounts[]>([]);
  const [modalAccount, setModalAccount] = useState<IInitialModalAccount>(initialModalAccount);

  const handleGetMoreItems = useCallback(
    async ({ limit, lastSeenId }: { limit: number; lastSeenId: number }) => {
      try {
        const { isSuccess, data, errorMessage } = await ApiAccounts.getAccounts({
          limit,
          lastSeenId,
        });

        if (isSuccess) {
          return {
            has_more: data.has_next,
            records: data.items,
          };
        } else {
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => dispatch(dropAlertState()),
            }),
          );
          return undefined;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    [dispatch],
  );

  const { hasMore, loading, loadMore, remount } = useScrollPagination({
    limit: 20,
    getRecords: handleGetMoreItems,
  });

  const selectedAccounts = useMemo(() => {
    const selectedRows: ICexAccounts[] = [];

    if (!records) return [];

    for (const key of Object.keys(rowSelection)) {
      selectedRows.push(records[Number(key)]);
    }
    return selectedRows;
  }, [rowSelection, records]);

  const handleEditAccount = useCallback((editableItem: any) => {
    const editableAccount = {
      cex: editableItem.cex,
      expire_at: editableItem.expire_at,
      secret: '',
      passphrase: '',
      notes: editableItem.notes,
      proxy: editableItem.proxy,
      api_key: '',
      accountId: editableItem.id,
    };
    setCreateNewAccountModalOpened(true);
    setModalAccount({ mode: 'edit', item: editableAccount });
  }, []);

  const bulkDeletingAccounts = useMemo(
    () => selectedAccounts.map(selectedAccount => selectedAccount.id),
    [selectedAccounts],
  );

  const onSuccessChangedAccounts = useCallback(() => {
    setTriggerAccountsTableRemount(v => !v);
  }, []);

  const handleDeactivateSelectedAccounts = useCallback(
    async (editableItems: number[]) => {
      dispatch(
        setAlertState({
          type: 'sure',
          text: 'You will deactivate all selected accounts.',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: async () => {
            Promise.all(
              editableItems.map(account =>
                ApiAccounts.deleteAccounts({
                  accountId: account,
                }),
              ),
            ).then(result => {
              const errorItem = result.find(item => !item.isSuccess);
              setRowSelection({});
              if (!errorItem) {
                setRecords(prevRecords =>
                  prevRecords?.filter(prevRecord => !editableItems.includes(prevRecord.id)),
                );
                dispatch(
                  setAlertState({
                    type: 'success',
                    text: `You successfully deactivated accounts`,
                    onClose: () => {
                      dispatch(dropAlertState());
                    },
                    onSubmit: () => {
                      dispatch(dropAlertState());
                    },
                  }),
                );
              } else {
                onSuccessChangedAccounts();
                dispatch(
                  setAlertState({
                    type: 'failed-img',
                    text: String(errorItem.errorMessage),
                    onClose: () => {
                      dispatch(dropAlertState());
                    },
                    onSubmit: () => {
                      dispatch(dropAlertState());
                    },
                  }),
                );
              }
            });
          },
        }),
      );
    },
    [dispatch, onSuccessChangedAccounts],
  );

  const columns = useMemo<ColumnDef<ICexAccounts>[]>(
    () => [
      {
        id: 'select',
        accessorFn: row => row,
        header: ({ table }) => <CexAccountHead table={table} />,
        cell: ({ row }) => <CexAccountCell row={row} />,
      },
      {
        id: 'Notes',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Notes</div>,
        cell: ({ row }) => <NotesAccountCell row={row} />,
      },
      {
        id: 'API key',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>API key</div>,
        cell: ({ row }) => <ApiKeyAccountCell row={row} />,
      },
      {
        id: 'Total USD',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Total USD</div>,
        style: { textAlign: 'right' },
        cell: ({ row }) => {
          return <TokensAccountsCell row={row} searchableToken={'TOTAL'} />;
        },
      },
      {
        id: 'Projects',
        accessorFn: row => row,
        header: () => <div style={{ textAlign: 'left' }}>Projects</div>,
        style: { textAlign: 'right' },
        cell: ({ row }) => {
          return (
            <TableProjects projects={row.original.projects} tooltip="pairs" align="flex-start" />
          );
        },
      },
      {
        id: 'Actions',
        accessorFn: row => row,
        header: () => <></>,
        cell: ({ row }) => (
          <ActionButtonsAccountCell
            row={row}
            handleEditAccount={handleEditAccount}
            handleDeactivateSelectedAccounts={handleDeactivateSelectedAccounts}
          />
        ),
      },
    ],
    [handleDeactivateSelectedAccounts, handleEditAccount],
  );

  const table = useReactTable({
    data: useMemo(() => records ?? [], [records]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const handleLoadMore = useCallback(async () => {
    const newRecordsSlice = await loadMore();

    if (newRecordsSlice) {
      setRecords(v => (v ? [...v].concat([...newRecordsSlice]) : newRecordsSlice));
    }
  }, [loadMore]);

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  useEffect(() => {
    remount();
  }, [triggerAccountsTableRemount]);

  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse();

    if (!lastItem) {
      handleLoadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      handleLoadMore();
    }
  }, [virtualRows, hasMore, loading, handleLoadMore, rows.length]);

  useEffect(() => {
    if (rows) {
      setVirtualRecords(virtualRows.map(({ index }) => rows[index].original));
    }
  }, [virtualRows, rows]);

  const updateVisibleRecords = useCallback(async () => {
    const lastSeenId = virtualRecords[0] ? virtualRecords[0].id : undefined;
    const limit = virtualRecords.length;

    if (!lastSeenId || limit === 0 || !records) {
      Bus.emit(STOP_RELOAD_PREFIX + PROJECT_ACCOUNT_RELOAD);
      return;
    }

    try {
      const startTime = Date.now();

      const { isSuccess, data, errorMessage } = await ApiAccounts.getAccounts({
        lastSeenId: lastSeenId - 1,
        limit,
      });

      const endTime = Date.now();

      if (isSuccess) {
        const firstSeenIndex = records.findIndex(el => el.id === lastSeenId);
        const newRecords = [...records];
        newRecords.splice(firstSeenIndex, data.items.length, ...data.items);

        setRecords(newRecords);

        if (endTime - startTime >= 1500) {
          Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
        } else {
          setTimeout(() => {
            Bus.emit(STOP_RELOAD_PREFIX + PROJECT_WALLETS_RELOAD);
          }, 1500 - (endTime - startTime));
        }
      } else {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        Bus.emit(STOP_RELOAD_PREFIX + PROJECT_ACCOUNT_RELOAD);
      }
    } catch (error) {
      console.log(error);
      Bus.emit(STOP_RELOAD_PREFIX + PROJECT_ACCOUNT_RELOAD);
    }
  }, [virtualRecords, records, dispatch]);

  useEffect(() => {
    Bus.on(PROJECT_ACCOUNT_RELOAD, updateVisibleRecords);
    return () => {
      Bus.off(PROJECT_ACCOUNT_RELOAD, updateVisibleRecords);
    };
  }, [updateVisibleRecords]);

  const closeCreateAccountModal = useCallback(() => {
    setCreateNewAccountModalOpened(false);
    setModalAccount(initialModalAccount);
  }, []);

  const openCreateAccountModal = useCallback(() => {
    setCreateNewAccountModalOpened(true);
  }, []);

  return (
    <>
      <TableToolbar
        customActions={
          <div className="accounts-panel-table-toolbar__actions">
            {selectedAccounts.length >= 1 && (
              <>
                <span className="selected-caption">Selected: {selectedAccounts.length}</span>
                <TableAction
                  iconLeft={
                    <TableActionIcon icon={ExitIcon} disabled={selectedAccounts.length === 0} />
                  }
                  text={'Deactivate'}
                  disabled={selectedAccounts.length === 0}
                  onClick={() => handleDeactivateSelectedAccounts(bulkDeletingAccounts)}
                />
              </>
            )}
            <TableAction
              iconLeft={<TableActionIcon icon={PlusIcon} />}
              text={'Create new account'}
              onClick={openCreateAccountModal}
            />
          </div>
        }
      />
      <div ref={tableContainerRef} className="accounts-table-container scrollable">
        <table className="mm-common-table w-full">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map(virtualRow => {
              const row = rows[virtualRow.index];

              return (
                <tr key={virtualRow.index}>
                  {row ? (
                    row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id} style={{ height: virtualRow.size }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })
                  ) : (
                    <td style={{ height: virtualRow.size }}>-</td>
                  )}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
        {records === undefined && !loading && (
          <div className="mm-common-table-no-info">
            {'You get an error while trying to get wallets records. Try to refresh your page...'}
          </div>
        )}
        {records && records.length === 0 && (
          <div className="mm-common-table-no-info">
            {
              'You don\'t have any accounts yet. Please create your first by clicking on "Create new account" button'
            }
          </div>
        )}
      </div>
      {createNewAccountModalOpened && (
        <CreateNewAccountModal
          onOpen={openCreateAccountModal}
          onClose={closeCreateAccountModal}
          onSuccess={onSuccessChangedAccounts}
          modalAccount={modalAccount}
        />
      )}
    </>
  );
};

export { AccountsTable };
