import React, { useMemo } from 'react';
import { AccountsIcon } from 'assets/icons/AccountsIcon';
import cn from 'classnames';
import { matchPath, useLocation, useNavigate } from 'react-router';

const Accounts = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const accountsPathIsActive: boolean = useMemo(
    () => Boolean(matchPath('/accounts/*', pathname)),
    [pathname],
  );

  return (
    <div
      className={cn('mm-sidebar__menu__item', { _active: accountsPathIsActive })}
      onClick={() => {
        navigate('/accounts');
      }}
    >
      <div className="mm-sidebar__menu__item__left">
        <div className="mm-sidebar__menu__item__icon">
          <AccountsIcon color={accountsPathIsActive ? '#5932EA' : undefined} />
        </div>
        <span className="mm-sidebar__menu__item__text">Accounts</span>
      </div>
    </div>
  );
};

export { Accounts };
