import React from 'react';

import { PanelError } from './PanelError';

interface Props {
  children: React.ReactNode;
}

interface State {
  errorTitle: string | undefined;
}

class PanelErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorTitle: undefined };
  }

  componentDidCatch(error: Error) {
    this.setState({ errorTitle: `${error.message} \n\n ${error.stack}` });
  }

  render() {
    if (this.state.errorTitle) {
      return (
        <div className="bg-white">
          <PanelError title="Error" text={this.state.errorTitle} />
        </div>
      );
    }

    return this.props.children;
  }
}

export default PanelErrorBoundary;
