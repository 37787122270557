import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isNil } from 'lodash';
import { useTypedSelector, useTypedDispatch } from 'store';

import { PairBalanceBotContext } from 'context/PairBalanceBotContext';
import { IDexBalanceBotTask } from 'types/bots';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { addBalanceTask } from 'modals/AddHolderModal/useAddHolderModal/addBalanceTask';
import { durationToMs } from 'utils/duration';

interface IInitialBalanceMaintainFeeBalance {
  interval: number | null;
  min_fee_threshold_factor: string;
  min_deposit_fee_factor: string;
  max_deposit_fee_factor: string;
  source: number;
  destination: number;
  enabled: boolean;
}

const useBalanceMaintainFeeBalance = () => {
  const dispatch = useTypedDispatch();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { records, estimateFee, handleLoadRecords } = useContext(PairBalanceBotContext);

  const [task, setTask] = useState<IDexBalanceBotTask>();

  const [formLoading, setFormLoading] = useState<boolean>(false);

  const initialValues: IInitialBalanceMaintainFeeBalance = useMemo(
    () => ({
      interval: null,
      min_fee_threshold_factor: '',
      min_deposit_fee_factor: '',
      max_deposit_fee_factor: '',
      source: 0,
      destination: 0,
      enabled: false,
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        interval: Yup.number().nullable(true).required('Field is required'),
        min_fee_threshold_factor: Yup.string()
          .required('Field is required')
          .test(
            'min_fee_threshold_factor',
            'Field is required',
            (val: string | undefined) => val !== '' && !isNaN(Number(val)) && Number(val) > 0,
          ),
        min_deposit_fee_factor: Yup.string()
          .required('Field is required')
          .test(
            'min_deposit_fee_factor',
            'Field is required',
            (val: string | undefined) => val !== '' && !isNaN(Number(val)) && Number(val) > 0,
          ),
        max_deposit_fee_factor: Yup.string()
          .required('Field is required')
          .test(
            'max_deposit_fee_factor',
            'Field is required',
            (val: string | undefined) => val !== '' && !isNaN(Number(val)) && Number(val) > 0,
          ),
      }),
    [],
  );

  const onSubmit = useCallback(
    async ({
      enabled,
      interval,
      min_deposit_fee_factor,
      max_deposit_fee_factor,
      min_fee_threshold_factor,
    }: IInitialBalanceMaintainFeeBalance) => {
      if (isNil(interval)) return;

      setFormLoading(true);

      try {
        addBalanceTask({
          task: {
            ...task?.maintain_fee_balance_options,
            pairId: dexPair.id,
            taskId: task?.id ?? undefined,
            enabled: enabled,
            interval: interval,
            min_deposit_fee_factor: Number(min_deposit_fee_factor),
            max_deposit_fee_factor: Number(max_deposit_fee_factor),
            min_fee_threshold_factor: Number(min_fee_threshold_factor),
            dispatch,
            handleLoadRecords,
            mode: task ? 'edit' : 'create',
            typeTask: 'maintain_fee_balance',
          },
        });
      } catch (error) {
        console.log('error: ', error);
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: 'Failed to update bot config',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      } finally {
        setFormLoading(false);
      }
    },
    [dexPair, task, dispatch, handleLoadRecords],
  );

  const { handleSubmit, setFieldValue, values, errors, touched, setFieldTouched, validateForm } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema,
    });

  const handleEnableFeeTask = useCallback(
    async (task: IDexBalanceBotTask | undefined, checked: boolean) => {
      if (task && values.interval && values.interval > 0) {
        addBalanceTask({
          task: {
            ...task?.maintain_fee_balance_options,
            enabled: checked,
            interval: values.interval,
            min_deposit_fee_factor: Number(values.min_deposit_fee_factor),
            max_deposit_fee_factor: Number(values.max_deposit_fee_factor),
            min_fee_threshold_factor: Number(values.min_fee_threshold_factor),
            dispatch,
            handleLoadRecords,
            pairId: dexPair.id,
            taskId: task?.id,
            mode: 'edit',
            typeTask: 'maintain_fee_balance',
          },
        });
      }
    },
    [dexPair.id, dispatch, values, handleLoadRecords],
  );

  useEffect(() => {
    const maintainFeeBalance = records.get?.find(record => record.type === 'maintain_fee_balance');

    setTask(maintainFeeBalance);

    if (maintainFeeBalance?.maintain_fee_balance_options) {
      setFieldValue(
        'interval',
        durationToMs(maintainFeeBalance.maintain_fee_balance_options.interval),
      );
      setFieldValue(
        'min_fee_threshold_factor',
        String(maintainFeeBalance.maintain_fee_balance_options.min_fee_threshold_factor),
      );
      setFieldValue(
        'min_deposit_fee_factor',
        String(maintainFeeBalance.maintain_fee_balance_options.min_deposit_fee_factor),
      );
      setFieldValue(
        'max_deposit_fee_factor',
        String(maintainFeeBalance.maintain_fee_balance_options.max_deposit_fee_factor),
      );
      setFieldValue(
        'source',
        maintainFeeBalance?.maintain_fee_balance_options?.source_wallet_ids?.length ?? 0,
      );
      setFieldValue('destination', maintainFeeBalance.destination_wallets_count);
      setFieldValue('enabled', maintainFeeBalance.maintain_fee_balance_options.enabled);
    }
  }, [records.get, setFieldValue]);

  return {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    validateForm,
    formLoading,
    task,
    handleEnableFeeTask,
    estimateFee,
  };
};

export { useBalanceMaintainFeeBalance };
