import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { motion } from 'framer-motion';

import './smallRowListSelector.scss';

interface ISmallRowListSelector<T> {
  list: T[];
  selected?: T | undefined;
  onSelect: (item: T) => void;
  field?: keyof T;
}

function SmallRowListSelector<T>({
  list,
  selected,
  onSelect,
  field,
}: ISmallRowListSelector<T>): JSX.Element {
  const extendedList = useMemo(() => list.map(item => ({ item: item, id: uuid() })), [list]);

  return (
    <div className="mm-small-row-list-selector">
      {extendedList.map(item => {
        const itemSelected = JSON.stringify(item.item) === JSON.stringify(selected);

        return (
          <motion.div
            key={item.id}
            animate={{
              borderColor: itemSelected ? '#5932EA' : 'rgba(89, 50, 234, 0.01)',
              color: itemSelected ? '#5932EA' : '#7F91BB',
            }}
            className="mm-small-row-list-selector__item"
            onClick={() => onSelect(item.item)}
          >
            {field ? `${item.item[field]}` : `${item}`}
          </motion.div>
        );
      })}
    </div>
  );
}

export { SmallRowListSelector };
