import dayjs from 'dayjs';

import { BotEnableStatus } from 'ui';
import { IDexBalanceBotTask } from 'types/bots';

interface IStartTimeProps {
  original: IDexBalanceBotTask;
  showEnableStatus?: boolean;
}

export const StartTime: React.FC<IStartTimeProps> = ({ original, showEnableStatus = true }) => {
  const { created_at, is_active } = original;

  return (
    <div className="start-time">
      {showEnableStatus ? <BotEnableStatus status={is_active ? 'enabled' : 'disabled'} /> : null}
      <span>{dayjs(created_at).format('L LT')}</span>
    </div>
  );
};
