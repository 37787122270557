import React from 'react';
import cn from 'classnames';

import './style.scss';

interface IBuySellCellProps {
  type: 'buy' | 'sell';
  title: string;
}

const StatisticBuySellCell: React.FC<IBuySellCellProps> = ({ type, title }) => {
  return (
    <th>
      <div className="statistic-buy-sell-cell">
        <span className={cn('caption', type)}>{type === 'buy' ? 'Bought' : 'Sell'}</span>
        <span className="title">{title}</span>
      </div>
    </th>
  );
};

export default StatisticBuySellCell;
