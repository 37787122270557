import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { Checkbox } from 'ui';

import './style.scss';

interface IStatisticCheckboxHeaderProps {
  title: string;
  checked: boolean;
  onChange: () => void;
  checkboxCaption: string;
}

const StatisticCheckboxHeader: React.FC<IStatisticCheckboxHeaderProps> = ({
  title,
  checked,
  onChange,
  checkboxCaption,
}) => {
  const id = useMemo(() => uuid(), []);

  return (
    <th>
      <div className="mm-statistic-table-checkbox-header">
        <span>{title}</span>
        <div className="checkbox-area" onClick={onChange}>
          <Checkbox
            color="secondary"
            id={id}
            checked={checked}
            onChange={onChange}
            className="statistic-checkbox"
          />
          <span>{checkboxCaption}</span>
        </div>
      </div>
    </th>
  );
};

export default StatisticCheckboxHeader;
