import React, { useCallback, useState } from 'react';
import { Button, Modal, ButtonLoading, ErrorText } from 'ui';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ConnectNewWalletsToPairForm } from 'ui/forms';
import { ConnectAccountToPairTable } from 'tables';
import { ApiCexAccount } from 'api/apiCexAccount';
import { ICexNotConnectedAccounts } from 'api/apiCexAccount/models/ICexAccount';
import { dropAlertState, setAlertState } from 'store/slices/ui';

import './connectAccountToCexPair.scss';

interface IAddPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  onSuccess: () => void;
}

const ConnectAccountToCexPairModal: React.FC<IAddPairModalProps> = ({
  onClose,
  onOpen,
  onSuccess,
}) => {
  const dispatch = useTypedDispatch();
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [validateError, setValidateError] = useState<string | null>(null);
  const pair = useTypedSelector(store => store.pairs.selectedCexPair);
  const pairId = pair?.id || 0;

  const [selectedRecords, setSelectedRecords] = useState<ICexNotConnectedAccounts[]>([]);

  const handleAppendAccounts = useCallback(async () => {
    if (selectedRecords.length === 0) {
      setValidateError('Select at least one account');
      return;
    }

    try {
      setRequestLoading(true);
      const appendAccountsList = selectedRecords.map(selectedAccount => ({
        bots_ids: [],
        id: selectedAccount.id,
        is_disabled: false,
      }));
      const { errorMessage, isSuccess } = await ApiCexAccount.connectAccountToCexPair({
        cexId: pairId,
        accounts: appendAccountsList,
      });

      if (isSuccess) {
        onClose();
        dispatch(
          setAlertState({
            type: 'success',
            text: `You successfully added ${selectedRecords.length} wallets to pair`,
            onClose: () => {
              dispatch(dropAlertState());
              onSuccess();
            },
            onSubmit: () => {
              onSuccess();
              onClose();
              dispatch(dropAlertState());
            },
          }),
        );
      } else {
        onClose();

        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => {
              dispatch(dropAlertState());
            },
            onSubmit: () => {
              dispatch(dropAlertState());
              onOpen();
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestLoading(false);
    }

    setValidateError(null);
  }, [pairId, selectedRecords, dispatch, onClose, onOpen, onSuccess]);

  return (
    <Modal
      title={`Connect new account: ${selectedRecords.length}`}
      className={'mm-cex-account-modal'}
      onClose={onClose}
    >
      <ConnectNewWalletsToPairForm handleSubmit={handleAppendAccounts}>
        <div className="connect-new-wallets-to-pair-modal-content">
          <ConnectAccountToPairTable setSelectedRecords={setSelectedRecords} />
        </div>
        <ErrorText>{validateError ?? undefined}</ErrorText>
        {!requestLoading && (
          <Button type="submit" disabled={selectedRecords.length === 0}>
            Connect
          </Button>
        )}
        {requestLoading && <ButtonLoading />}
      </ConnectNewWalletsToPairForm>
    </Modal>
  );
};

export { ConnectAccountToCexPairModal };
