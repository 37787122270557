import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IDexPair, IProjectPairShort } from 'types/pairs';
import { IAddPairArgs, IPairWallet, IWithdraw } from './models';

class ApiPairs extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiPairs | undefined;
  private static instance = (() => {
    if (ApiPairs.__instance === undefined) {
      ApiPairs.__instance = new ApiPairs();
    }
    return ApiPairs.__instance;
  })();

  public static getPairsByProjectId = async ({
    limit = 1000,
    offset = 0,
    sort_by,
    id,
  }: {
    limit?: number;
    offset?: number;
    sort_by?: string;
    id: number;
  }): Promise<Response<{ items: IProjectPairShort[] }>> => {
    let mappedResponseOrError: Response<{ items: IProjectPairShort[] }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('offset', offset.toString());
    if (sort_by) {
      urlParams.append('sort_by', sort_by);
    }

    try {
      const response = await ApiPairs.instance.get<IBaseResponse<{ items: IProjectPairShort[] }>>(
        `/projects/${id}/pairs` + '?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ items: IProjectPairShort[] }>({ error });
    }

    return mappedResponseOrError;
  };

  public static addPair = async ({
    dex,
    cex,
    notes,
    token_base,
    token_quote,
    fee,
    id,
    swap_gas_limit,
    tradingType,
  }: IAddPairArgs): Promise<Response<{ id: string }>> => {
    let mappedResponseOrError: Response<{ id: string }>;

    try {
      const response = await ApiPairs.instance.post<IBaseResponse<{ id: string }>>(
        `/projects/${id}/${tradingType}-pairs`,
        {
          ...(tradingType === 'dex'
            ? {
                dex,
                fee,
                swap_gas_limit,
              }
            : { cex }),
          notes,
          token_base,
          token_quote,
        },
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);

      mappedResponseOrError = mapError<{ id: string }>({ error });
    }

    return mappedResponseOrError;
  };

  public static getDexPairById = async (id: number): Promise<Response<IDexPair>> => {
    let mappedResponseOrError: Response<IDexPair>;

    try {
      const response = await ApiPairs.instance.get<IBaseResponse<IDexPair>>(`/pairs/${id}`);

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IDexPair>({ error });
    }

    return mappedResponseOrError;
  };

  public static updateDexPair = async ({ id, notes }: { id: number; notes: string }) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiPairs.instance.patch<IBaseResponse<any>>(`/pairs/${id}`, { notes });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static deleteDexPair = async (id: number) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiPairs.instance.delete<IBaseResponse<any>>(`/pairs/${id}`);

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static getWalletsNotConnectedToPair = async ({
    pairId,
    limit,
    lastSeenId,
  }: {
    pairId: number;
    limit: number;
    lastSeenId: number;
  }): Promise<Response<{ has_next: boolean; items: IPairWallet[] }>> => {
    let mappedResponseOrError: Response<{
      has_next: boolean;
      items: IPairWallet[];
    }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('last_seen_id', lastSeenId.toString());

    try {
      const response = await ApiPairs.instance.get<
        IBaseResponse<{ has_next: boolean; items: IPairWallet[] }>
      >(`/pairs/${pairId}/wallets/not-connected` + '?' + urlParams.toString());

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{
        has_next: boolean;
        items: IPairWallet[];
      }>({ error });
    }

    return mappedResponseOrError;
  };

  public static getWalletsConnectedToPair = async ({
    pairId,
    limit,
    lastSeenId,
    balance,
    volume,
    transactions_count,
    is_disabled,
  }: {
    pairId: number;
    limit?: number;
    lastSeenId?: number;
    balance?: string | undefined;
    volume?: string | undefined;
    transactions_count?: string | undefined;
    is_disabled?: string | undefined;
  }): Promise<
    Response<{ has_next: boolean; items: IPairWallet[]; total_connected_wallets: number }>
  > => {
    let mappedResponseOrError: Response<{
      has_next: boolean;
      items: IPairWallet[];
      total_connected_wallets: number;
    }>;

    const urlParams = new URLSearchParams();

    if (limit) {
      urlParams.append('limit', limit.toString());
    }
    if (lastSeenId) {
      urlParams.append('last_seen_id', lastSeenId.toString());
    }
    if (balance) {
      urlParams.append('balance', balance);
    }
    if (volume) {
      urlParams.append('volume', volume);
    }
    if (transactions_count) {
      urlParams.append('transactions_count', transactions_count);
    }
    if (is_disabled) {
      urlParams.append('is_disabled', is_disabled);
    }

    try {
      const response = await ApiPairs.instance.get<
        IBaseResponse<{ has_next: boolean; items: IPairWallet[]; total_connected_wallets: number }>
      >(`/pairs/${pairId}/wallets` + '?' + urlParams.toString());

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<{
        has_next: boolean;
        items: IPairWallet[];
        total_connected_wallets: number;
      }>({ error });
    }

    return mappedResponseOrError;
  };

  public static addWalletsToPair = async ({
    pairId,
    wallets,
  }: {
    pairId: number;
    wallets: { id: number; bots_ids: number[] }[];
  }) => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiPairs.instance.put<IBaseResponse<any>>(`/pairs/${pairId}/wallets`, {
        wallets,
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static deleteWalletsFromPair = async ({
    pairId,
    walletIds,
  }: {
    pairId: number;
    walletIds: number[];
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiPairs.instance.delete<any>(`/pairs/${pairId}/wallets`, {
        data: {
          wallets_ids: walletIds,
        },
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static deposit = async ({
    transactionHashes,
    pairId,
  }: {
    transactionHashes: string[];
    pairId: number;
  }): Promise<Response<any>> => {
    let mappedResponseOrError: Response<any>;

    try {
      const response = await ApiPairs.instance.post<any>(`/pairs/${pairId}/deposit`, {
        transactions: transactionHashes,
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static withdraw = async ({
    recipient_address,
    pairId,
    transfers,
  }: {
    recipient_address: string;
    pairId: number;
    transfers: {
      amount: string | undefined;
      max_amount: boolean;
      token_id: number;
      wallet_id: number;
    }[];
  }): Promise<Response<IWithdraw>> => {
    let mappedResponseOrError: Response<IWithdraw>;

    try {
      const response = await ApiPairs.instance.post<IWithdraw>(`/pairs/${pairId}/withdraw`, {
        recipient_address,
        transfers,
      });

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IWithdraw>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiPairs };
