import React, { useCallback, useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { Button, ButtonLoading, Panel, Switcher } from 'ui';
import { DurationField, InputField } from 'fields';
import { useBalanceMaintainFeeBalance } from './useBalanceMaintainFeeBalance';
import { ConnectWalletsToBoostHoldersModal } from 'modals/ConnectWalletsToBoostHoldersModal';
import { addBignumbers } from 'utils/formulas';
import { ETransactionAction } from 'api/apiTransactions/models';
import { formatFiat, formatToken } from 'utils/formats';

import './maintainFeeBalance.scss';

const MaintainFeeBalanceTask: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const [isOpenWalletsModal, setIsOpenWalletsModal] = useState<'source' | 'destination' | ''>('');

  const {
    values,
    errors,
    touched,
    setFieldValue,
    task,
    estimateFee,
    handleSubmit,
    handleEnableFeeTask,
    formLoading,
  } = useBalanceMaintainFeeBalance();

  const buy_eth = task?.statistic?.transactions?.find(
    transaction => transaction.action === 'buy_eth',
  );

  const currentSwapPrice =
    estimateFee?.current_pair?.transactions_count &&
    estimateFee.current_pair?.transactions_count >= 5
      ? estimateFee?.current_pair.avg_amount_usd
      : estimateFee?.all_pairs.avg_amount_usd || 0;

  const swapCost = +currentSwapPrice / +(dexPair.token_fee.price_usd || 0);

  const averageEthMountDepositFee =
    (+values.min_deposit_fee_factor + +values.max_deposit_fee_factor) / 2;

  const normalizeTotalETHAmount = averageEthMountDepositFee * swapCost;

  const afrActions = useMemo(
    () => [ETransactionAction.ActionBuyEth, ETransactionAction.ActionSellEth],
    [],
  );
  const feeSpent = useMemo(() => {
    const feeToken = task?.statistic?.tokens.find(
      el => el.token.address === dexPair.token_fee.address,
    );

    if (!feeToken || !feeToken.spent_fees)
      return {
        feeTokens: BigNumber.from(0),
        feeUsd: BigNumber.from(0),
      };

    return feeToken.spent_fees
      .filter(el => afrActions.includes(el.action as ETransactionAction))
      .reduce(
        (acc, val) => {
          return {
            feeTokens: addBignumbers(
              [BigNumber.from(val.amount), feeToken.token.decimals],
              [acc.feeTokens, 18],
            ),
            feeUsd: addBignumbers([BigNumber.from(val.amount_usd), 6], [acc.feeUsd, 18]),
          };
        },
        {
          feeTokens: BigNumber.from(0),
          feeUsd: BigNumber.from(0),
        },
      );
  }, [task, dexPair, afrActions]);

  const handleOpenSourceWallets = useCallback(() => {
    setIsOpenWalletsModal('source');
  }, []);

  const handleOpenDestinationWallets = useCallback(() => {
    setIsOpenWalletsModal('destination');
  }, []);

  const handleCloseConnectWalletsModal = useCallback(() => {
    setIsOpenWalletsModal('');
  }, []);

  const currentTaskValue = useMemo(
    () => ({
      enabled: values.enabled,
      interval: values.interval,
      max_deposit_fee_factor: isNaN(+values.max_deposit_fee_factor)
        ? 0
        : Number(values.max_deposit_fee_factor),
      min_deposit_fee_factor: isNaN(+values.min_deposit_fee_factor)
        ? 0
        : Number(values.min_deposit_fee_factor),
      min_fee_threshold_factor: isNaN(+values.min_fee_threshold_factor)
        ? 0
        : Number(values.min_fee_threshold_factor),
    }),
    [values],
  );

  return (
    <>
      <Panel
        title={`${dexPair.token_base.symbol} -> ${dexPair.token_fee.symbol}`}
        subTitle={`Keep the ${dexPair.token_fee.symbol} balance enough to make transactions`}
        className="mm-panel mm-panel-task-wrapper"
        initialOpened
        switcher={
          <Switcher value={values.enabled} onChange={value => handleEnableFeeTask(task, value)} />
        }
      >
        <div className="mm-main-fee-balance-task">
          <div className="mm-first-row-fields">
            <DurationField
              label="Check interval"
              value={values.interval}
              setValue={value => setFieldValue('interval', value ?? null)}
              errorMessage={touched.interval && errors.interval ? errors.interval : undefined}
            />
            <InputField
              type="decimal-number"
              label="N.of swaps min deposit:"
              setValue={value => setFieldValue('min_deposit_fee_factor', value)}
              value={values.min_deposit_fee_factor}
              errorMessage={
                touched.min_deposit_fee_factor && errors.min_deposit_fee_factor
                  ? errors.min_deposit_fee_factor
                  : undefined
              }
            />
            <InputField
              type="natural-number"
              label="From"
              setValue={value => setFieldValue('maintainFrom', value)}
              readonly
              value={values.source === 0 ? 'All wallets' : `${values.source} wallets`}
              nodeRight={
                <span onClick={handleOpenSourceWallets} className="wallets-count-text">
                  Choose wallets
                </span>
              }
            />
          </div>
          <div className="mm-second-row-fields">
            <InputField
              type="natural-number"
              label={`Deposit ${dexPair.token_fee.symbol} if the number of swaps less:`}
              setValue={value => setFieldValue('min_fee_threshold_factor', value)}
              value={values.min_fee_threshold_factor}
              errorMessage={
                touched.min_fee_threshold_factor && errors.min_fee_threshold_factor
                  ? errors.min_fee_threshold_factor
                  : undefined
              }
            />
            <InputField
              type="decimal-number"
              label="N. of swaps max deposit:"
              setValue={value => setFieldValue('max_deposit_fee_factor', value)}
              value={values.max_deposit_fee_factor}
              errorMessage={
                touched.max_deposit_fee_factor && errors.max_deposit_fee_factor
                  ? errors.max_deposit_fee_factor
                  : undefined
              }
            />
            <InputField
              type="natural-number"
              label="To"
              readonly
              value={values.destination === 0 ? 'All wallets' : `${values.destination} wallets`}
              nodeRight={
                <span onClick={handleOpenDestinationWallets} className="wallets-count-text">
                  Choose wallets
                </span>
              }
            />
          </div>

          <div className="confirm-section mm-fee-confirm-section">
            <div className="fee-confirm-section-wrapper">
              <div>
                <span>Transactions: </span>
                <span>{buy_eth ? `${buy_eth.total}(${buy_eth.failed})` : 0}</span>
              </div>
              <div>
                <span>Fee spent: </span>
                <span>
                  {Number(formatToken(feeSpent.feeTokens)).toFixed(6)} {dexPair.token_fee.symbol} /{' '}
                  {formatFiat(feeSpent.feeUsd)}
                </span>
              </div>
              <div>
                <span>Min/Max {dexPair.token_fee.symbol} amount: </span>
                <span>
                  {normalizeTotalETHAmount.toFixed(6)} {dexPair.token_fee.symbol}
                </span>
              </div>
            </div>
            <div className="button-save-container">
              {!formLoading && (
                <Button
                  type="submit"
                  className="button-save-container__save-task-button"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              )}
              {formLoading && <ButtonLoading />}
            </div>
          </div>
        </div>
      </Panel>
      {isOpenWalletsModal && (
        <ConnectWalletsToBoostHoldersModal
          walletType={isOpenWalletsModal}
          onClose={handleCloseConnectWalletsModal}
          task={task}
          taskType={task!.type}
          taskId={task!.id}
          currentTaskValue={currentTaskValue}
        />
      )}
    </>
  );
};

export { MaintainFeeBalanceTask };
