import { useCallback, useMemo, useState, useContext } from 'react';
import { FormikProps, useFormik } from 'formik';
import dayjs from 'dayjs';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedDispatch, useTypedSelector } from 'store';

import { ICexWTOrganicTask } from 'types/bots';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { multiplyBignumbers } from 'utils/formulas';
import { numberParse, percentageParse, extractToPercent, trimDecimalZeroes } from 'utils/formats';
import { durationToMs } from 'utils/duration';
import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';
import { addOrganicTask } from './addOrganicTask';

import { validationSchema } from './validationSchema';

interface IWtOrganicTaskForm {
  enabled: boolean;
  start_at: number | null;
  end_at: number | null;
  base_volume: string;
  base_volume_deviation: string;
  cancel_delay_max: number | null;
  cancel_delay_min: number | null;
  close_bar_before: number | null;
  ignore_volume_percent: string;
  indicator_period: string;
  not_dividable_min_factor: string;
  orders_max: string;
  orders_min: string;
  pause_between_series_max: number | null;
  pause_between_series_min: number | null;
  per_order_amount_dev_max: string;
  per_order_amount_dev_min: string;
  time_frame: { label: string; value: string } | null;
}

interface IUseWTOrganicTaskProps {
  onClose: () => void;
  task?: ICexWTOrganicTask;
  mode: 'create' | 'edit';
}

const useWTOrganicTask = ({ mode, onClose, task }: IUseWTOrganicTaskProps) => {
  const dispatch = useTypedDispatch();
  const [edited, setEdited] = useState<boolean>(false);
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const { handleLoadRecords, taskLoading } = useContext(CexWashAlgorithmContext);

  const initialValues = useMemo<IWtOrganicTaskForm>(
    () =>
      task && mode === 'edit'
        ? {
            enabled: task.enabled,
            start_at: dayjs(task.start_at).second(0).millisecond(0).valueOf(),
            end_at: task.end_at ? dayjs(task.end_at).second(0).millisecond(0).valueOf() : null,
            base_volume: trimDecimalZeroes(
              formatUnits(BigNumber.from(task.options.base_volume), 18),
            ),
            base_volume_deviation: String(task.options.base_volume_deviation),
            cancel_delay_max: durationToMs(task.options.cancel_delay_max),
            cancel_delay_min: durationToMs(task.options.cancel_delay_min),
            close_bar_before: durationToMs(task.options.close_bar_before),
            ignore_volume_percent: extractToPercent(task.options.ignore_volume_percent),
            indicator_period: String(task.options.indicator_period),
            not_dividable_min_factor: String(task.options.not_dividable_min_factor),
            orders_max: String(task.options.orders_max),
            orders_min: String(task.options.orders_min),
            pause_between_series_max: durationToMs(task.options.pause_between_series_max),
            pause_between_series_min: durationToMs(task.options.pause_between_series_min),
            per_order_amount_dev_max: String(task.options.per_order_amount_dev_max),
            per_order_amount_dev_min: String(task.options.per_order_amount_dev_min),
            time_frame:
              WT_TIMEFRAMES.find(
                el => durationToMs(el.value) === durationToMs(task.options.time_frame),
              ) ?? null,
          }
        : {
            enabled: true,
            start_at: null,
            end_at: null,
            base_volume: '',
            base_volume_deviation: '',
            cancel_delay_max: null,
            cancel_delay_min: null,
            close_bar_before: null,
            ignore_volume_percent: '',
            indicator_period: '',
            not_dividable_min_factor: '',
            orders_max: '',
            orders_min: '',
            pause_between_series_max: null,
            pause_between_series_min: null,
            per_order_amount_dev_max: '',
            per_order_amount_dev_min: '',
            time_frame: WT_TIMEFRAMES[0],
          },
    [task, mode],
  );

  const _handleSumbit = useCallback(
    async ({
      enabled,
      base_volume,
      base_volume_deviation,
      cancel_delay_max,
      cancel_delay_min,
      close_bar_before,
      end_at,
      ignore_volume_percent,
      indicator_period,
      not_dividable_min_factor,
      orders_max,
      orders_min,
      pause_between_series_max,
      pause_between_series_min,
      per_order_amount_dev_max,
      per_order_amount_dev_min,
      start_at,
      time_frame,
    }: IWtOrganicTaskForm) => {
      const newTask: ICexWTOrganicTask = {
        enabled: enabled,
        start_at: dayjs(start_at).toISOString(),
        ...(end_at ? { end_at: dayjs(end_at).toISOString() } : {}),
        uniq_id: '',
        options: {
          base_volume: String(
            multiplyBignumbers([parseUnits(base_volume), 18], [parseUnits('1'), 18]),
          ),
          base_volume_deviation: Number(base_volume_deviation),
          cancel_delay_min: `${cancel_delay_min ?? 0}ms`,
          cancel_delay_max: `${cancel_delay_max ?? 0}ms`,
          close_bar_before: `${close_bar_before ?? 0}ms`,
          ignore_volume_percent: percentageParse(ignore_volume_percent),
          indicator_period: numberParse(indicator_period),
          not_dividable_min_factor: numberParse(not_dividable_min_factor),
          orders_max: numberParse(orders_max),
          orders_min: numberParse(orders_min),
          pause_between_series_max: `${pause_between_series_max ?? 0}ms`,
          pause_between_series_min: `${pause_between_series_min ?? 0}ms`,
          per_order_amount_dev_min: numberParse(per_order_amount_dev_min),
          per_order_amount_dev_max: numberParse(per_order_amount_dev_max),
          time_frame: time_frame!.value,
        },
      };

      await addOrganicTask({
        dispatch,
        task,
        newTask,
        onClose,
        setLoading: taskLoading.set,
        pairId: cexPair.id,
        handleLoadRecords,
        mode,
      });
    },
    [cexPair, dispatch, handleLoadRecords, mode, onClose, task, taskLoading.set],
  );

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    validateForm,
  }: FormikProps<IWtOrganicTaskForm> = useFormik<IWtOrganicTaskForm>({
    initialValues,
    onSubmit: _handleSumbit,
    validationSchema,
  });

  const handleSetFieldValue = useCallback(
    (field: string, value: any) => {
      setEdited(true);

      setFieldValue(field, value);
    },
    [setFieldValue],
  );

  return {
    validationSchema,
    initialValues,
    values,
    handleSubmit,
    setFieldValue: handleSetFieldValue,
    validateForm,
    edited,
    errors,
    touched,
  };
};

export { useWTOrganicTask };
