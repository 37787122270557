import { useMemo } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

import { getAverage } from 'utils';

type UseTaskTimeParams = {
  minAmount?: string;
  maxAmount?: string;
  minDelay?: number;
  maxDelay?: number;
  totalTokenAmount?: string;
};

export const useTaskTime = ({
  minAmount,
  maxAmount,
  minDelay,
  maxDelay,
  totalTokenAmount,
}: UseTaskTimeParams) => {
  const durationInMs = useMemo(
    () =>
      minAmount && maxAmount && minDelay && maxDelay
        ? Math.round(
            (Number(totalTokenAmount) / getAverage([minAmount, maxAmount])) *
              getAverage([minDelay, maxDelay]),
          )
        : 0,
    [totalTokenAmount, minAmount, maxAmount, minDelay, maxDelay],
  );

  const currentTimeInMs = Math.round(Date.now());
  const finishTimeInMs = durationInMs ? currentTimeInMs + durationInMs : 0;

  const difference = useMemo(
    () => dayjs.duration(dayjs(finishTimeInMs).diff(dayjs())).humanize(),
    [finishTimeInMs],
  );

  const finishTimeUiString = useMemo(
    () => !!finishTimeInMs && `${dayjs(finishTimeInMs).format('L LT')} (${difference})`,
    [finishTimeInMs, difference],
  );

  return { durationInMs, currentTimeInMs, finishTimeInMs, finishTimeUiString };
};
