import React from 'react';
import { Table } from '@tanstack/react-table';

import { TableCheckbox } from 'ui';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface ICexAccountHeadHead {
  table: Table<ICexAccounts>;
}

const CexAccountHead: React.FC<ICexAccountHeadHead> = ({ table }) => {
  return (
    <div className="checkbox-area">
      <TableCheckbox
        id="select-all"
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
      <span>CEX</span>
    </div>
  );
};

export default CexAccountHead;
