import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { motion } from 'framer-motion';

import { ICexOrderAgregatedTrades } from 'types/orders';
import { formatFiat } from 'utils/formats';
import { SelectArrowSmall } from 'assets/icons';
import { addBignumbers } from 'utils/formulas';

interface IFeeProps {
  order: ICexOrderAgregatedTrades;
  extendedOrderId: number | null;
  handleSetExtendedOrderId: (id: number) => void;
}

const Fee = React.memo(({ order, extendedOrderId, handleSetExtendedOrderId }: IFeeProps) => {
  const decimals = useMemo(() => 18, []);

  const feeAmountUsdInTrades = useMemo(
    () =>
      order.trades
        ?.filter(el => el.is_fee)
        .reduce(
          (acc, val) => addBignumbers([acc, decimals], [BigNumber.from(val.amount_usd).abs(), 6]),
          BigNumber.from(0),
        ) ?? BigNumber.from(0),
    [order, decimals],
  );

  const transfersExist = useMemo(() => order.transfers.length !== 0, [order]);
  const rowIsSelected = useMemo(() => extendedOrderId === order.id, [extendedOrderId, order]);

  return (
    <div style={{ textAlign: 'right' }}>
      <div
        className="fee-and-select"
        style={{ textAlign: 'right', textOverflow: 'ellipsis', minWidth: 0, overflow: 'hidden' }}
        onClick={transfersExist ? () => handleSetExtendedOrderId(order.id) : undefined}
      >
        <span className="text-primary">{formatFiat(feeAmountUsdInTrades, decimals)}</span>
        {transfersExist && (
          <motion.div
            animate={{ rotate: rowIsSelected ? 180 : 0 }}
            className="select-icon-container"
          >
            <SelectArrowSmall />
          </motion.div>
        )}
      </div>
    </div>
  );
});

Fee.displayName = 'Fee';

export { Fee };
