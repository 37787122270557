import React, { useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';

import { Button, Modal, SmallRowListSelector, ButtonLoading, Spinner } from 'ui';
import { InputField, SelectExchangeField } from 'fields';
import { InsertButton } from 'fields/components';
import { PairIcon } from 'assets/icons';
import { useAddPairModal } from './useAddPairModal/useAddPairModal';

import { IFee, FEES } from 'tools/constants';
import { EExchange, networks } from 'types';
import { IDex } from 'api/apiDictionary/models';

import './addPairModal.scss';

interface IAddPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  projectId: number;
  tradingType: EExchange;
  dex?: IDex;
}

const AddPairModal: React.FC<IAddPairModalProps> = ({
  onClose,
  onOpen,
  projectId,
  tradingType,
  dex,
}) => {
  const {
    cexs,
    form,
    setFormField,
    handleAddPair,
    formError,
    loading,
    pairParsingLoading,
    pairAddressError,
    edited,
  } = useAddPairModal({
    projectId,
    onClose,
    onOpen,
    tradingType,
    dex,
  });

  const feesAreVisible = useMemo(
    () => dex && (dex.dex === 'uniswap_v3' || dex.dex === 'uniswap_v3:arbitrum_one'),
    [dex],
  );

  const handleSwapTokens = useCallback(() => {
    const baseToken = form.baseToken;
    const quoteToken = form.quoteToken;

    setFormField('baseToken', quoteToken, { triggerEdit: true });
    setFormField('quoteToken', baseToken, { triggerEdit: true });
  }, [form, setFormField]);

  const cexsFormatted = useMemo(
    () => cexs?.map(el => ({ label: el.description, exchange: el.cex })) ?? [],
    [cexs],
  );

  return (
    <Modal
      title={`New trading pair ${
        dex
          ? '- ' + dex.description + ` (${networks.find(el => el.network === dex.network)!.label})`
          : ''
      }`}
      edited={edited}
      onClose={onClose}
      customButtons={
        <>
          {(loading || pairParsingLoading) && <ButtonLoading />}
          {!loading && !pairParsingLoading && (
            <Button onClick={handleAddPair} disabled={!!formError}>
              Create
            </Button>
          )}
        </>
      }
    >
      <div className="mm-add-pair-modal">
        {tradingType === 'dex' && (
          <InputField
            label="Pair address(optional)"
            type="text"
            value={form.pairAddress ?? ''}
            setValue={newPairAddress =>
              setFormField('pairAddress', newPairAddress, { triggerEdit: true })
            }
            errorMessage={pairAddressError ? 'Invalid pair address' : undefined}
            nodeRight={
              <InsertButton
                onInsert={newPairAddress =>
                  setFormField('pairAddress', newPairAddress, { triggerEdit: true })
                }
              />
            }
          />
        )}
        <motion.div className="mm-add-pair-modal__token-selector">
          <div className="mm-add-pair-modal__token-selector__pair">
            {tradingType === EExchange.cex && cexs && (
              <SelectExchangeField
                label="Exchange"
                items={cexsFormatted}
                disabled={loading}
                selectedItem={cexsFormatted.find(el => el.exchange === form.cex?.cex) ?? undefined}
                onSelectItem={cex =>
                  setFormField('cex', cexs.find(el => el.cex === cex?.exchange) ?? null, {
                    triggerEdit: true,
                  })
                }
              />
            )}
            <InputField
              label="Token base"
              disabled={pairParsingLoading}
              type="text"
              value={form.baseToken ?? ''}
              setValue={newBaseToken =>
                setFormField('baseToken', newBaseToken, { triggerEdit: true })
              }
              nodeRight={
                <InsertButton
                  onInsert={newBaseToken =>
                    setFormField('baseToken', newBaseToken, { triggerEdit: true })
                  }
                />
              }
            />
            <motion.button
              whileHover={{ scale: 1.2 }}
              className="swap-tokens-button"
              onClick={handleSwapTokens}
            >
              {pairParsingLoading && <Spinner size="mini" />}
              {!pairParsingLoading && <PairIcon />}
            </motion.button>
            <InputField
              label="Token quote"
              disabled={pairParsingLoading}
              type="text"
              value={form.quoteToken ?? ''}
              setValue={newQuoteToken =>
                setFormField('quoteToken', newQuoteToken, { triggerEdit: true })
              }
              nodeRight={
                <InsertButton
                  onInsert={newQuoteToken =>
                    setFormField('quoteToken', newQuoteToken, { triggerEdit: true })
                  }
                />
              }
            />
          </div>
        </motion.div>

        {tradingType === EExchange.dex && (
          <motion.div
            initial={{ height: 0 }}
            animate={{
              height: feesAreVisible ? 'auto' : 0,
              overflow: feesAreVisible ? 'visible' : 'hidden',
            }}
            className="mm-add-pair-modal__fee-selector"
          >
            <span className="mm-add-pair-modal__fee-selector__text">Fee:</span>
            <SmallRowListSelector<IFee>
              list={FEES}
              selected={FEES.find(fee => fee.value === form.fee)}
              field={'text'}
              onSelect={newSelectedFee =>
                setFormField('fee', newSelectedFee.value, { triggerEdit: true })
              }
            />
          </motion.div>
        )}

        <div className="mm-add-pair-modal__notes">
          <InputField
            type="text"
            label="Notes"
            value={form.notes ?? ''}
            setValue={newNotes => setFormField('notes', newNotes, { triggerEdit: true })}
          />
        </div>
        {formError && <span className="mm-add-pair-modal__error">{formError}</span>}
      </div>
    </Modal>
  );
};

export { AddPairModal };
