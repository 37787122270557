import React, { useContext } from 'react';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import { Spinner } from 'ui';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';

import './formChartInfo.scss';

const FormChartInfo: React.FC = () => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const { chartLoading, chartErrorMessage, chartFormInfo } = useContext(PairWashTradingContext);

  if (!pair) return null;

  return (
    <div
      className={cn('mm-wt-bot-form-chart-info', {
        _loading: !!chartLoading.get,
        _error: !chartLoading.get && !!chartErrorMessage.get,
      })}
    >
      {!!chartLoading.get && <Spinner size={'small'} />}
      {!chartLoading.get && !!chartErrorMessage.get && (
        <span className="error-title">Simulation failed...</span>
      )}
      {!chartLoading.get && !chartErrorMessage.get && (
        <>
          <div className="chart-info-section">
            <span className="caption">Token price:</span>
            <span>{chartFormInfo.get.tokenPrice}</span>
          </div>
          <div className="chart-info-section">
            <span className="caption">Pooled {pair.token_base.symbol}:</span>
            <span>{chartFormInfo.get.pooledBaseToken}</span>
          </div>
          <div className="chart-info-section">
            <span className="caption">Pool liquidity:</span>
            <span>{chartFormInfo.get.poolLiquidity}</span>
          </div>
          <div className="chart-info-section">
            <span className="caption">Pooled {pair.token_quote.symbol}:</span>
            <span>{chartFormInfo.get.pooledFeeToken}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default FormChartInfo;
