import React from 'react';
import { motion } from 'framer-motion';

import { SelectArrowSmall } from 'assets/icons';

interface IOpenIcon {
  opened: boolean;
}

export const OpenIcon: React.FC<IOpenIcon> = ({ opened }) => {
  return (
    <div className="open-row-cell">
      <motion.div animate={{ rotate: opened ? 180 : 0 }}>
        <SelectArrowSmall />
      </motion.div>
    </div>
  );
};
