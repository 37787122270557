export interface IFee {
  value: number;
  text: string;
}

export const FEES: IFee[] = [
  { value: 100, text: '0.01%' },
  { value: 500, text: '0.05%' },
  { value: 1000, text: '0.1%' },
  { value: 3000, text: '0.3%' },
];
