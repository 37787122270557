import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  createChart,
  CrosshairMode,
  IChartApi,
  PriceScaleMode,
  UTCTimestamp,
} from 'lightweight-charts';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import { EExchange } from 'types';
import { EChartFormat, EChartConvertedFormat } from 'types/charts';
import { PairChartsContext } from 'context/PairChartsContext/PairChartsContext';
import { chartVolumeBarColor, internalVolumeBarColor, CHARTS_COLORS } from 'constants/charts';
import { chartFormatter } from 'utils/charts';

import { ChartLoading } from '../ChartLoading';
import { ChartNotFound } from '../ChartNotFound';
import { ChartFooter } from '../ChartFooter';
import { ChartToolbar } from '../ChartToolbar';
import { PairTooltip } from '../PairTooltip';
import { BalanceTooltip } from '../BalanceTooltip';

import './style.scss';

const LightweightChart: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const chartsHolderRef = useRef<HTMLDivElement>(null);
  const pairChartRef = useRef<HTMLDivElement>(null);
  const balanceChartRef = useRef<HTMLDivElement>(null);

  const balancesAPIRef = useRef<IChartApi | undefined>(undefined);
  const pairAPIRef = useRef<IChartApi | undefined>(undefined);

  const {
    loading,
    exchange,
    kattanaPairRecords,
    balanceRecords,
    selectedChartFormat,
    priceFormat,
    convertedFormat,
    balancesShow,
    visibleLocalRange,
  } = useContext(PairChartsContext);

  const [tooltipIndex, setTooltipIndex] = useState<number | undefined>(undefined);
  const [firstVisibleIndex, setFirstVisibleIndex] = useState<number | undefined>(undefined);
  const [lastVisibleIndex, setLastVisibleIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    setTooltipIndex(undefined);
    setFirstVisibleIndex(undefined);
    setLastVisibleIndex(undefined);
  }, [loading.get]);

  const hoveredIndex = useMemo(
    () => (tooltipIndex === undefined ? lastVisibleIndex : tooltipIndex),
    [lastVisibleIndex, tooltipIndex],
  );

  const pairValid = useMemo(
    () => !loading.get && kattanaPairRecords.length !== 0,
    [loading.get, kattanaPairRecords],
  );
  const balancesValid = useMemo(
    () => !loading.get && balanceRecords.length !== 0,
    [loading.get, balanceRecords],
  );

  const removePairChart = useCallback(() => {
    if (pairAPIRef && !!pairAPIRef.current) {
      pairAPIRef.current.remove();
    }
  }, [pairAPIRef]);

  const removeBalanceChart = useCallback(() => {
    if (balancesAPIRef && !!balancesAPIRef.current) {
      balancesAPIRef.current.remove();
    }
  }, [balancesAPIRef]);

  const chartMode = useMemo(() => {
    if (selectedChartFormat.get === EChartFormat.Normal) return PriceScaleMode.Normal;

    return PriceScaleMode.Percentage;
  }, [selectedChartFormat.get]);

  const baseTokenBalance = useMemo(() => {
    if (exchange === EExchange.dex) {
      return balanceRecords.find(el => el.token!.id === dexPair.token_base.id);
    } else {
      return balanceRecords.find(el => el.cex_token!.id === cexPair.token_base.id);
    }
  }, [balanceRecords, dexPair, cexPair, exchange]);

  const candleStickRecords = useMemo(
    () =>
      kattanaPairRecords.map(el => ({
        time: el.date as UTCTimestamp,
        close: el.close,
        high: el.high,
        low: el.low,
        open: el.open,
      })),
    [kattanaPairRecords],
  );

  const totalVolumeRecords = useMemo(
    () =>
      kattanaPairRecords.map(el => ({
        time: el.date as UTCTimestamp,
        value: Number(el.volume),
      })),
    [kattanaPairRecords],
  );

  const internalVolumeRecords = useMemo(
    () =>
      baseTokenBalance
        ? baseTokenBalance.points.map(el => ({
            time: el.time as UTCTimestamp,
            value:
              convertedFormat.get === EChartConvertedFormat.Original ? el.volume : el.volume_usd,
          }))
        : [],
    [baseTokenBalance, convertedFormat.get],
  );

  const balancesChartRecords = useMemo(
    () =>
      balanceRecords.length !== 0
        ? balanceRecords.map(b => {
            return {
              records: b.points.map(el => ({
                time: el.time as UTCTimestamp,
                value: priceFormat.get === 'token' ? el.balance : el.balance_usd,
              })),
              label: exchange === EExchange.dex ? b.token!.symbol : b.cex_token!.symbol,
            };
          })
        : [],
    [balanceRecords, priceFormat.get, exchange],
  );

  const totalBalanceUsdRecords = useMemo(
    () =>
      balanceRecords.length !== 0
        ? balanceRecords[0].points.map((point, index) => {
            const totalBalanceUsd = balanceRecords.reduce((acc, val) => {
              return acc + (val.points[index]?.balance_usd ?? 0);
            }, 0);

            return {
              time: point.time as UTCTimestamp,
              value: totalBalanceUsd,
            };
          })
        : [],
    [balanceRecords],
  );

  const initCharts = useCallback(async () => {
    if (loading.get) return;

    if (!chartsHolderRef.current) return;

    try {
      // init charts
      const pairChart = pairChartRef.current
        ? createChart(pairChartRef.current as HTMLElement, {
            crosshair: {
              mode: CrosshairMode.Normal,
            },
            rightPriceScale: {
              mode: PriceScaleMode.Normal,
              minimumWidth: 100,
              borderColor: '#d8dade',
            },
            grid: {
              horzLines: {
                color: '#ebedf2',
              },
              vertLines: {
                color: '#ebedf2',
              },
            },
            layout: {
              background: {
                color: 'transparent',
              },
            },
            timeScale: {
              timeVisible: true,
              secondsVisible: false,
              fixLeftEdge: true,
              fixRightEdge: true,
              borderColor: '#d8dade',
            },
            localization: {
              priceFormatter: chartFormatter,
              percentageFormatter: (p: number) => `${p.toFixed(2)}%`,
            },
          })
        : undefined;

      const balancesChart = balanceChartRef.current
        ? createChart(balanceChartRef.current as HTMLElement, {
            crosshair: {
              mode: CrosshairMode.Magnet,
            },
            rightPriceScale: {
              mode: chartMode,
              minimumWidth: 100,
              borderColor: '#d8dade',
            },
            layout: {
              background: {
                color: 'transparent',
              },
            },
            grid: {
              horzLines: {
                color: '#ebedf2',
              },
              vertLines: {
                color: '#ebedf2',
              },
            },
            timeScale: {
              timeVisible: true,
              fixLeftEdge: true,
              fixRightEdge: true,
              secondsVisible: false,
              borderColor: '#d8dade',
            },
            localization: {
              priceFormatter: chartFormatter,
              percentageFormatter: (p: number) => `${p.toFixed(2)}%`,
            },
          })
        : undefined;
      // --- init charts

      // init records
      const balanceSeries: any[] = [];

      let index = 0;

      for (const balanceItem of balancesChartRecords) {
        if (!balancesShow.get[balanceItem.label]) {
          index++;
          continue;
        }

        const lineSeries = balancesChart?.addLineSeries({
          color: CHARTS_COLORS[index],
          lineWidth: 2,
          baseLineWidth: 1,
          title: balanceItem.label,
        });

        lineSeries?.setData(balanceItem.records);
        balanceSeries.push(lineSeries);

        index++;
      }

      if (priceFormat.get === 'usd' && !!balancesShow.get['Total']) {
        const lineSeries = balancesChart?.addLineSeries({
          color: CHARTS_COLORS[index + 1],
          lineWidth: 2,
          baseLineWidth: 1,
          title: 'Total',
        });

        lineSeries?.setData(totalBalanceUsdRecords);
        balanceSeries.push(lineSeries);
      }
      // --- init records

      const candleStickSeries = pairChart?.addCandlestickSeries();
      candleStickSeries?.priceScale().applyOptions({ scaleMargins: { top: 0, bottom: 0.2 } });

      candleStickSeries?.setData(candleStickRecords);

      const totalVolumeSeries = pairChart?.addHistogramSeries({
        color: chartVolumeBarColor,
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
        lastValueVisible: false,
      });
      totalVolumeSeries?.priceScale().applyOptions({ scaleMargins: { top: 0.8, bottom: 0 } });

      totalVolumeSeries?.setData(totalVolumeRecords);

      const internalVolumeSeries = pairChart?.addHistogramSeries({
        color: internalVolumeBarColor,
        priceFormat: {
          type: 'volume',
        },
        priceScaleId: '',
        lastValueVisible: false,
      });

      internalVolumeSeries?.priceScale().applyOptions({ scaleMargins: { top: 0.8, bottom: 0 } });

      internalVolumeSeries?.setData(internalVolumeRecords);

      balancesChart?.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        if (!timeRange) return;

        visibleLocalRange.set({ from: Number(timeRange.from), to: Number(timeRange.to) });
        pairChart
          ?.timeScale()
          .setVisibleLogicalRange({ from: Number(timeRange.from), to: Number(timeRange.to) });
      });
      balancesChart?.timeScale().fitContent();

      pairChart?.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        if (!timeRange) return;

        visibleLocalRange.set({ from: Number(timeRange.from), to: Number(timeRange.to) });
        balancesChart
          ?.timeScale()
          .setVisibleLogicalRange({ from: Number(timeRange.from), to: Number(timeRange.to) });
      });
      pairChart?.timeScale().fitContent();

      if (visibleLocalRange.get) {
        pairChart?.timeScale().setVisibleLogicalRange(visibleLocalRange.get);
        balancesChart?.timeScale().setVisibleLogicalRange(visibleLocalRange.get);
      }

      balancesChart?.timeScale().subscribeVisibleLogicalRangeChange(logicalRange => {
        if (!logicalRange) return;

        setFirstVisibleIndex(Math.ceil(logicalRange.from));
        setLastVisibleIndex(Math.floor(logicalRange.to));
      });

      pairChart?.subscribeCrosshairMove(function (param) {
        if (param.time) {
          balanceSeries.forEach(series => {
            balancesChart?.setCrosshairPosition(0, param.time!, series);
          });
          setTooltipIndex(param.logical ?? undefined);
        } else {
          balancesChart?.clearCrosshairPosition();
          setTooltipIndex(undefined);
        }
      });

      balancesChart?.subscribeCrosshairMove(function (param) {
        if (param.time) {
          if (candleStickSeries) {
            pairChart?.setCrosshairPosition(0, param.time, candleStickSeries);
          }
          setTooltipIndex(param.logical ?? undefined);
        } else {
          pairChart?.clearCrosshairPosition();
          setTooltipIndex(undefined);
        }
      });

      removePairChart();
      removeBalanceChart();

      pairAPIRef.current = pairChart;
      balancesAPIRef.current = balancesChart;
    } catch (error) {
      console.log('error: ', error);
    }

    //eslint-disable-next-line
  }, [
    loading.get,
    priceFormat.get,
    removeBalanceChart,
    removePairChart,
    chartMode,
    candleStickRecords,
    internalVolumeRecords,
    totalVolumeRecords,
    totalBalanceUsdRecords,
    balancesChartRecords,
    balancesShow.get,
  ]);

  useEffect(() => {
    initCharts();
  }, [initCharts]);

  useEffect(() => {
    const pairResize = (entry: ResizeObserverEntry[]) => {
      if (entry.length === 0) return;
      pairAPIRef.current?.applyOptions({
        width: entry[0].contentRect.width,
        height: entry[0].contentRect.height,
      });
    };

    const balanceResize = (entry: ResizeObserverEntry[]) => {
      if (entry.length === 0) return;
      balancesAPIRef.current?.applyOptions({
        width: entry[0].contentRect.width,
        height: entry[0].contentRect.height,
      });
    };

    let pairResizeObserver: ResizeObserver | undefined = undefined;
    let balanceResizeObserver: ResizeObserver | undefined = undefined;

    const pairChartContainer = pairChartRef.current;
    const balanceChartContainer = balanceChartRef.current;

    if (pairChartContainer) {
      pairResizeObserver = new ResizeObserver(pairResize);
      pairResizeObserver.observe(pairChartContainer);
    }
    if (balanceChartContainer) {
      balanceResizeObserver = new ResizeObserver(balanceResize);
      balanceResizeObserver.observe(balanceChartContainer);
    }

    return () => {
      if (pairChartContainer && pairResizeObserver) {
        pairResizeObserver.unobserve(pairChartContainer);
      }
      if (balanceChartContainer && balanceResizeObserver) {
        balanceResizeObserver.unobserve(balanceChartContainer);
      }
    };
  }, [loading.get, pairValid]);

  return (
    <>
      <ChartToolbar />
      <div className="mm-pair-charts-holder" ref={chartsHolderRef}>
        <section
          className={cn('mm-pair-chart-section', 'mm-pair-chart__pair', {
            '_not-found': !loading.get && !pairValid,
            '_is-single_pair': !loading.get && !balancesValid,
            '_is-double_not-found': !loading.get && !pairValid && !balancesValid,
          })}
        >
          <div className="mm-pair-chart-container">
            {hoveredIndex !== undefined && pairValid && (
              <PairTooltip
                close={candleStickRecords[hoveredIndex]?.close ?? 0}
                high={candleStickRecords[hoveredIndex]?.high ?? 0}
                low={candleStickRecords[hoveredIndex]?.low ?? 0}
                open={candleStickRecords[hoveredIndex]?.open ?? 0}
                volumeInternal={internalVolumeRecords[hoveredIndex]?.value ?? 0}
                volumeTotal={totalVolumeRecords[hoveredIndex]?.value ?? 0}
                startPrice={
                  firstVisibleIndex !== undefined
                    ? candleStickRecords[firstVisibleIndex]?.close ?? 0
                    : undefined
                }
              />
            )}

            {loading.get && <ChartLoading />}
            {!loading.get && !pairValid && <ChartNotFound text="Pair chart not found" />}
            {!loading.get && pairValid && <div className="chart" ref={pairChartRef} />}
          </div>
        </section>
        <section
          className={cn('mm-pair-chart-section', 'mm-pair-chart__balance', {
            '_not-found': !loading.get && !balancesValid,
            '_is-single_balances': !loading.get && !pairValid,
            '_is-double_not-found': !loading.get && !pairValid && !balancesValid,
          })}
        >
          <div className="mm-pair-chart-container">
            {hoveredIndex !== undefined && (
              <BalanceTooltip
                balances={balancesChartRecords.map(el => ({
                  value: el.records[hoveredIndex]?.value ?? 0,
                  startValue:
                    firstVisibleIndex !== undefined
                      ? el.records[firstVisibleIndex]?.value ?? 0
                      : undefined,
                  label: el.label,
                }))}
                totalBalance={
                  priceFormat.get === 'usd'
                    ? totalBalanceUsdRecords[hoveredIndex]?.value ?? 0
                    : undefined
                }
                startTotalBalance={
                  firstVisibleIndex !== undefined && priceFormat.get === 'usd'
                    ? totalBalanceUsdRecords[firstVisibleIndex]?.value ?? 0
                    : undefined
                }
              />
            )}
            {loading.get && <ChartLoading />}
            {!loading.get && !balancesValid && <ChartNotFound text="Balances chart not found" />}
            {!loading.get && balancesValid && <div className="chart" ref={balanceChartRef} />}
          </div>
        </section>
      </div>
      <ChartFooter />
    </>
  );
};

export { LightweightChart };
