import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { ICexOrder } from 'types/orders';

class ApiOrders extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiOrders | undefined;
  private static instance = (() => {
    if (ApiOrders.__instance === undefined) {
      ApiOrders.__instance = new ApiOrders();
    }
    return ApiOrders.__instance;
  })();

  public static getOrders = async ({
    limit,
    lastSeenId,
    pair_ids,
  }: {
    limit: number;
    lastSeenId: number;
    pair_ids?: number[];
  }) => {
    let mappedResponseOrError: Response<{ has_next: boolean; items: ICexOrder[] }>;

    const urlParams = new URLSearchParams();
    urlParams.append('limit', limit.toString());
    urlParams.append('last_seen_id', lastSeenId.toString());

    if (pair_ids) {
      for (const pairId of pair_ids) {
        urlParams.append('pair_ids', pairId.toString());
      }
    }

    try {
      const response = await ApiOrders.instance.get<
        IBaseResponse<{ has_next: boolean; items: ICexOrder[] }>
      >('/orders?' + urlParams.toString());
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<{ has_next: boolean; items: ICexOrder[] }>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiOrders };
