import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, matchPath, PathMatch } from 'react-router';
import { motion, useAnimationControls } from 'framer-motion';
import { useTypedSelector, useTypedDispatch } from 'store';

import { ProjectModal } from 'modals';
import { CogIcon } from 'assets/icons';
import { ApiProjects } from 'api';
import { setSelectedProjectInPage } from 'store/slices/projects';
import { setSelectedCexPair, setSelectedDexPair } from 'store/slices/pairs';

import './headerProject.scss';

const HeaderProject: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const controls = useAnimationControls();
  const selectedProject = useTypedSelector(store => store.projects.selectedProjectInPage);
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const [updateModalOpened, setUpdateModalOpened] = useState<boolean>(false);

  const pathMatch: PathMatch<'projectId'> | null = matchPath('/project/:projectId', pathname);

  const projectId = useMemo(() => Number(pathMatch?.params.projectId ?? 0), [pathMatch]);

  const onOpenUpdateModalOpened = useCallback((): void => {
    setUpdateModalOpened(true);
  }, []);

  const onCloseUpdateModalOpened = useCallback((): void => {
    setUpdateModalOpened(false);
  }, []);

  useEffect(() => {
    const setupSelectProject = async () => {
      dispatch(setSelectedDexPair(null));
      dispatch(setSelectedCexPair(null));

      if (projectId) {
        try {
          const { data, isSuccess } = await ApiProjects.getProjectById(Number(projectId));
          if (isSuccess) {
            dispatch(
              setSelectedProjectInPage({
                name: data.name,
                notes: data.notes ?? '',
                users: data.users ? data.users.map(user => ({ id: user.id, role: user.role })) : [],
                id: data.id,
                created_at: data.created_at,
              }),
            );

            controls.set({ y: -100, opacity: 0 });
            controls.start({ y: 0, opacity: 1, transition: { duration: 0.4 } });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    setupSelectProject();
  }, [projectId, controls, dispatch]);

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={controls}
      className="mm-header__header-project"
    >
      {selectedProject && (
        <>
          <div className="mm-header__header-project__info">
            <h2 className="project-name">{selectedProject.name}</h2>
            {isAdmin && (
              <motion.div
                whileHover={{ rotate: 180, scale: 1.3 }}
                transition={{ duration: 0.3 }}
                className="project-settings"
                onClick={onOpenUpdateModalOpened}
              >
                <CogIcon />
              </motion.div>
            )}
          </div>
          <div className="mm-header__header-project__notes">
            {selectedProject.notes && <span>{selectedProject.notes}</span>}
          </div>
          {updateModalOpened && (
            <ProjectModal
              type="update"
              initialNotes={selectedProject.notes ?? ''}
              initialTitle={selectedProject.name}
              initialUsers={selectedProject.users}
              projectId={selectedProject.id}
              onClose={onCloseUpdateModalOpened}
              onOpen={onOpenUpdateModalOpened}
            />
          )}
        </>
      )}
    </motion.div>
  );
};

export { HeaderProject };
