import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';
import store from 'store';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { ECexBot, EDexBot, ICexBotConfig, IDexBotConfig } from 'types/bots';
import { ILinkedBotAuthItem } from './models';
import { setConnectedBots } from 'store/slices/pairs_connected_bots';

interface IGetLinkedBotsResponse {
  items: ILinkedBotAuthItem[];
  total_items: number;
}

class ApiBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiBot | undefined;
  private static instance = (() => {
    if (ApiBot.__instance === undefined) {
      ApiBot.__instance = new ApiBot();
    }
    return ApiBot.__instance;
  })();

  public static async getDexBotConfig<V extends EDexBot>({
    pairId,
    bot,
  }: {
    pairId: number;
    bot: V;
  }): Promise<Response<IDexBotConfig<V> | undefined>> {
    type Return = IDexBotConfig<V> | undefined;

    let mappedResponseOrError: Response<Return>;

    try {
      const response = await ApiBot.instance.get<IBaseResponse<Return>>(
        `/pairs/${pairId}/bots/${bot}`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: any) {
      // If not connected to the pair
      const errorCode = error?.response?.data?.code ?? 0;

      if (errorCode === 14002) {
        mappedResponseOrError = mapResponse({ data: undefined });
      } else {
        console.log(error);
        mappedResponseOrError = mapError<Return>({ error });
      }
    }

    return mappedResponseOrError;
  }

  public static async getCexBotConfig<V extends ECexBot>({
    pairId,
    bot,
  }: {
    pairId: number;
    bot: V;
  }): Promise<Response<ICexBotConfig<V> | undefined>> {
    type Return = ICexBotConfig<V> | undefined;

    let mappedResponseOrError: Response<Return>;

    try {
      const response = await ApiBot.instance.get<IBaseResponse<Return>>(
        `/cex-pairs/${pairId}/bots/${bot}`,
      );
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: any) {
      // If not connected to the pair
      const errorCode = error?.response?.data?.code ?? 0;

      if (errorCode === 14002) {
        mappedResponseOrError = mapResponse({ data: undefined });
      } else {
        console.log(error);
        mappedResponseOrError = mapError<Return>({ error });
      }
    }

    return mappedResponseOrError;
  }

  // TODO: need types
  public static async saveDexBotConfig<V extends EDexBot>({
    pairId,
    body,
    bot,
  }: {
    pairId?: number;
    bot: EDexBot;
    body: IDexBotConfig<V>;
  }): Promise<Response<any>> {
    let mappedResponseOrError: Response<any>;

    if (!body) {
      mappedResponseOrError = mapError<any>({ error: 'Body config is required!' });

      return mappedResponseOrError;
    }

    try {
      const response = await ApiBot.instance.put<IBaseResponse<any>>(
        `/pairs/${pairId}/bots/${bot.toString()}`,
        body,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });

      if (pairId) {
        let connectedBots =
          store.getState().pairConnectedBots.pairsConnectedBots[pairId] ??
          ({} as Record<EDexBot | ECexBot, boolean>);

        connectedBots = { ...connectedBots, [bot]: body.is_enabled };

        store.dispatch(setConnectedBots({ pairId, connectedBots }));
      }
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  }

  public static saveCexBotConfig = async ({
    pairId,
    body,
    bot,
  }: {
    pairId: number;
    bot: ECexBot;
    body: any;
  }) => {
    let mappedResponseOrError: Response<any>;

    let resultBody: any = {};

    if (bot === ECexBot.fill_the_gaps) {
      resultBody = body;
    }
    if (bot === ECexBot.wash_trading) {
      resultBody = body;
    }

    try {
      const response = await ApiBot.instance.put<IBaseResponse<any>>(
        `/cex-pairs/${pairId}/bots/${bot.toString()}`,
        resultBody,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });

      let connectedBots =
        store.getState().pairConnectedBots.pairsConnectedBots[pairId] ??
        ({} as Record<EDexBot | ECexBot, boolean>);

      connectedBots = { ...connectedBots, [bot]: body.is_enabled };

      store.dispatch(setConnectedBots({ pairId, connectedBots }));
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<any>({ error });
    }

    return mappedResponseOrError;
  };

  public static getDexLinkedBots = async ({ pairId }: { pairId: number }) => {
    let mappedResponseOrError: Response<IGetLinkedBotsResponse>;

    try {
      const response = await ApiBot.instance.get<IBaseResponse<IGetLinkedBotsResponse>>(
        `/pairs/${pairId}/bots`,
      );
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IGetLinkedBotsResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static getCexLinkedBots = async ({ pairId }: { pairId: number }) => {
    let mappedResponseOrError: Response<IGetLinkedBotsResponse>;

    try {
      const response = await ApiBot.instance.get<IBaseResponse<IGetLinkedBotsResponse>>(
        `/cex-pairs/${pairId}/bots`,
      );
      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IGetLinkedBotsResponse>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiBot };
