import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { IPanelProps } from './Panel';

import './panel.scss';

type IActionProps = Omit<IPanelProps, 'initialOpened'> & { actions: JSX.Element };

const ActionPanel: React.FC<IActionProps> = ({ children, icon: Icon, title, actions: Actions }) => {
  return (
    <section className="mm-panel">
      <div className={cn('mm-panel__toolbar', { _opened: true })}>
        <div className={'mm-panel__toolbar__title'}>
          <div className={'mm-panel__toolbar__title__icon'}>{Icon && <Icon color="#5932EA" />}</div>
          <span className={cn('mm-panel__toolbar__title__inner', { _opened: true })}>{title}</span>
        </div>
        <div className="mm-panel__toolbar__custom-actions">{Actions}</div>
      </div>
      <motion.div
        animate={{
          height: 'auto',
          overflow: 'visible',
        }}
        transition={{ easings: ['easeIn', 'easeInOut'] }}
        className={cn('mm-panel__content', { _opened: true })}
      >
        {children}
      </motion.div>
    </section>
  );
};

export { ActionPanel };
