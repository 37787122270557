import React, { useMemo } from 'react';
import { useTypedSelector } from 'store';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';
import { dailyVolume } from 'utils/daily';
import { durationToMs } from 'utils/duration';
import { formatFiat, formatToken } from 'utils/formats';

interface IDailyVolumeCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const DailyVolumeCell: React.FC<IDailyVolumeCellProps> = ({ task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const _dailyVolume = useMemo(() => {
    const minPause =
      durationToMs(task.min_pause_between_series) + durationToMs(task.min_series_duration);

    const maxPause =
      durationToMs(task.max_pause_between_series) + durationToMs(task.max_series_duration);

    const { volume, volumeUsd } = dailyVolume({
      delay_min: minPause,
      delay_max: maxPause,
      volume_max: formatUnits(BigNumber.from(task.max_volume), dexPair.token_base.decimals),
      volume_min: formatUnits(BigNumber.from(task.min_volume), dexPair.token_base.decimals),
      pair: dexPair,
    });

    return { volume, volumeUsd };
  }, [dexPair, task]);

  return (
    <div>
      {formatToken(_dailyVolume.volume, dexPair.token_base.decimals)} {dexPair.token_base.symbol} /{' '}
      {formatFiat(_dailyVolume.volumeUsd, 18)}
    </div>
  );
};

export { DailyVolumeCell };
