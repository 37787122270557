import React, { useContext, useState, useCallback } from 'react';
import { useTypedDispatch } from 'store';

import { Button } from 'ui';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { DexWTOrganicTaskModal } from 'modals/DexWTOrganicTaskModal';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { IDextWTOrganicVolumeTask } from 'types/bots';

import './style.scss';

const OrganicVolumesToolbar: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { currentConfig } = useContext(PairWashTradingContext);
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);

  const onCreateNewTask = useCallback(
    (task: IDextWTOrganicVolumeTask) => {
      const newTasks = currentConfig.get?.config.organic_volumes_tasks ?? [];
      newTasks.push(task);

      currentConfig.set(
        currentConfig.get
          ? {
              ...currentConfig.get,
              config: {
                ...currentConfig.get.config,
                organic_volumes_tasks: newTasks,
              },
            }
          : undefined,
      );
      dispatch(
        setAlertState({
          type: 'success',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
          text: 'You have successfully created a new task!',
        }),
      );
    },
    [currentConfig, dispatch],
  );

  return (
    <div className="mm-wt-dex-bot-organic-volumes-toolbar">
      <h4>Organic volumes</h4>
      <Button color="transparent" onClick={() => setCreateModalOpened(true)}>
        + Create new task
      </Button>
      {createModalOpened && (
        <DexWTOrganicTaskModal
          mode="create"
          onClose={() => setCreateModalOpened(false)}
          onSubmit={onCreateNewTask}
        />
      )}
    </div>
  );
};

export { OrganicVolumesToolbar };
