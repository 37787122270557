import React from 'react';

import { IDextWTOrganicVolumeTask } from 'types/bots';

interface ISeriePauseCellProps {
  task: IDextWTOrganicVolumeTask;
}

const SeriePauseCell: React.FC<ISeriePauseCellProps> = ({ task }) => {
  return (
    <div>
      {task.options.min_pause_between_series} / {task.options.max_pause_between_series}
    </div>
  );
};

export { SeriePauseCell };
