import axios from 'axios';

import { IRange, isTestnet } from 'types/web3';

import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';
import { AApiBase, IBaseResponse, Response } from '../apiBase';
import {
  IChartItem,
  IKattanaDexChartArgs,
  IKattanaDexChartResponse,
  IKattanaCexChartArgs,
  IKattanaCexChartResponse,
} from './models';
import { ENetwork } from 'types';

interface IGetBalancesResponse {
  items: IChartItem[];
  total_items: number;
}

class ApiCharts extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiCharts | undefined;
  private static instance = (() => {
    if (ApiCharts.__instance === undefined) {
      ApiCharts.__instance = new ApiCharts();
    }
    return ApiCharts.__instance;
  })();

  public static getBalances = async ({
    projects_ids,
    pair_ids,
    cex_pair_ids,
    token_ids,
    ranges,
  }: {
    projects_ids?: number[];
    pair_ids?: number[];
    cex_pair_ids?: number[];
    token_ids?: number[];
    ranges: (IRange | string)[];
  }): Promise<Response<IGetBalancesResponse>> => {
    let mappedResponseOrError: Response<IGetBalancesResponse>;

    const urlParams = new URLSearchParams();
    if (projects_ids && projects_ids.length !== 0) {
      for (const projectId of projects_ids) {
        urlParams.append('project_ids', projectId.toString());
      }
    }

    if (pair_ids && pair_ids.length !== 0) {
      for (const pairId of pair_ids) {
        urlParams.append('pair_ids', pairId.toString());
      }
    } else {
      //TODO remove this shit
      urlParams.append('pair_ids', '');
    }

    if (cex_pair_ids && cex_pair_ids.length !== 0) {
      for (const cexPairId of cex_pair_ids) {
        urlParams.append('cex_pair_ids', cexPairId.toString());
      }
    } else {
      //TODO remove this shit
      urlParams.append('cex_pair_ids', '');
    }

    if (token_ids && token_ids.length !== 0) {
      for (const tokenId of token_ids) {
        urlParams.append('token_ids', tokenId.toString());
      }
    }

    for (const range of ranges) {
      urlParams.append('range', range.toString());
    }

    try {
      const response = await ApiCharts.instance.get<IBaseResponse<IGetBalancesResponse>>(
        '/charts/balances' + '?' + urlParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<IGetBalancesResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static getKattanaDexPairChart = async ({
    network,
    pair,
    startTime,
    endTime,
    timeframe,
    limit,
    precision,
    flipped,
    converted,
  }: IKattanaDexChartArgs): Promise<Response<IKattanaDexChartResponse>> => {
    let mappedResponseOrError: Response<IKattanaDexChartResponse>;

    const BSC_SLUG = isTestnet ? 'BSC_TESTNET' : 'BSC';
    const ETH_SLUG = isTestnet ? 'ETH_TESTNET' : 'ETH';
    const POLYGON_SLUG = isTestnet ? 'POLYGON_TESTNET' : 'POLYGON';
    const ARBITRUM_SLUG = isTestnet ? 'ARBITRUM_SEPOLIA_TESTNET' : 'ARBITRUM';

    let slug = '';
    if (network === ENetwork.bsc) {
      slug = BSC_SLUG;
    }
    if (network === ENetwork.eth) {
      slug = ETH_SLUG;
    }
    if (network === ENetwork.polygon) {
      slug = POLYGON_SLUG;
    }
    if (network === ENetwork.arbitrum) {
      slug = ARBITRUM_SLUG;
    }

    try {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append('precision', precision.toString());
      urlSearchParams.append('flipped', `${flipped}`);
      urlSearchParams.append('volume', 'base');

      if (converted) {
        urlSearchParams.append('converted', converted);
      }
      if (isTestnet) {
        urlSearchParams.append('liquidityFilter', '0');
      }

      const response = await axios.get(
        `https://api-dev.kattana.trade/chart/${slug}/${pair}/${timeframe}/${startTime}/${endTime}/${limit}` +
          '?' +
          urlSearchParams.toString(),
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<IKattanaDexChartResponse>({ error });
    }

    return mappedResponseOrError;
  };

  public static getKattanaCexPairChart = async ({
    cex,
    endTime,
    limit,
    pair,
    startTime,
    timeframe,
  }: IKattanaCexChartArgs) => {
    let mappedResponseOrError: Response<IKattanaCexChartResponse>;

    let slug = '';
    if (cex === 'binance') {
      slug = 'binance';
    }
    if (cex === 'mexc') {
      slug = 'mexc';
    }
    if (cex === 'gate') {
      slug = 'gateio';
    }
    if (cex === 'kucoin') {
      slug = 'kucoin';
    }

    try {
      const response = await axios.get(
        `https://api-dev.kattana.trade/cex/candles/${slug}/${pair}/${timeframe}/${startTime}/${endTime}/${limit}`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data.map((el: any) => ({
          ...el,
          date: Math.floor(el.date / 1000),
        })),
      });
    } catch (error: unknown) {
      console.log(error);
      mappedResponseOrError = mapError<IKattanaCexChartResponse>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiCharts };
