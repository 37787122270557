import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { LocalStorage } from 'tools';
import { EDexPairFilters, IDexPairsFilters } from 'types/filters';
import { DEX_PAIR_FILTERS_KEY } from 'constants/cache';

export const clearDexPairFiltersReducer: CaseReducer<
  IDexPairsFilters,
  PayloadAction<{
    pairId: number | undefined;
    type: EDexPairFilters;
  }>
> = (state, { payload: { pairId, type } }) => {
  if (isNil(pairId)) return;
  if (!state[pairId]) return;

  state[pairId]!.updated_at = new Date(Date.now()).toISOString();
  state[pairId]!.filters = {
    ...state[pairId]!.filters,
    [type]: {
      filters: [],
      setted: [],
    },
  };

  LocalStorage.set(DEX_PAIR_FILTERS_KEY, state);
};
