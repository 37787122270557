import { BigNumber } from '@ethersproject/bignumber';

import { IDexBalanceBotTask } from 'types/bots';
import { IDexPair } from 'types/pairs';
import { formatFiat, formatToken } from 'utils/formats';
import { addBignumbers } from 'utils/formulas';

interface FeeProps {
  original: IDexBalanceBotTask;
  pair: IDexPair;
  feeToken: string | undefined;
}

export const Fee: React.FC<FeeProps> = ({ original, pair, feeToken }) => {
  const { statistic } = original;

  const token = statistic?.tokens?.find(token => token.token.symbol === feeToken);

  const totalAmount = token?.spent_fees.reduce((total, currentFee): any => {
    return addBignumbers([total, 18], [BigNumber.from(currentFee.amount), token.token.decimals]);
  }, BigNumber.from(0));

  const totalUSDAmount = token?.spent_fees.reduce((total, currentFee): any => {
    return addBignumbers([total, 18], [BigNumber.from(currentFee.amount_usd), 6]);
  }, BigNumber.from(0));

  return (
    <div>
      {totalAmount ? formatToken(totalAmount, 18) : 0} {pair?.token_fee?.symbol} /{' '}
      {totalUSDAmount ? formatFiat(totalUSDAmount, 18) : 0}{' '}
    </div>
  );
};
