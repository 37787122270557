import React from 'react';
import cn from 'classnames';

import { ISpinnerSize } from '../types';

import './spinner.scss';

interface ISpinner {
  size?: ISpinnerSize;
}

const Spinner: React.FC<ISpinner> = ({ size = 'medium' }) => {
  return <div className={cn('mm-common-spinner', `mm-common-spinner-${size}`)} />;
};

export { Spinner };
