import { IChartRange } from 'types/charts';

export const DEFAULT_REFRESH_INTERVAL = 10 * 1000; // 10 seconds

export const STATISTIC_RANGES: { label: IChartRange; milliseconds: number }[] = [
  { label: '1D', milliseconds: 24 * 60 * 60 * 1000 },
  { label: '7D', milliseconds: 7 * 24 * 60 * 60 * 1000 },
  { label: '1M', milliseconds: 30 * 24 * 60 * 60 * 1000 },
  { label: '3M', milliseconds: 3 * 30 * 24 * 60 * 60 * 1000 },
  { label: '1Y', milliseconds: 12 * 30 * 24 * 60 * 60 * 1000 },
  { label: 'ALL', milliseconds: Date.now() },
];
