import React from 'react';

import './tableSelectedCaption.scss';

const TableSelectedCaption: React.FC<{ selected: number; totalRecords: number }> = ({
  selected,
  totalRecords,
}) => {
  return (
    <span className="mm-common-table-selected-caption">{`Selected: ${selected} / ${totalRecords}`}</span>
  );
};

export { TableSelectedCaption };
