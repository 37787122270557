import React from 'react';

import { Panel } from 'ui';

import { RoundChartIcon } from 'assets/icons';
import { EExchange } from 'types';
import { IPanelProps } from 'types/pairs';

import { ContentWrapper } from 'pages/DexPair/Charts/components';

const Charts: React.FC<IPanelProps> = ({ initialOpened, setIsOpened }) => {
  return (
    <Panel
      title="Charts"
      icon={RoundChartIcon}
      initialOpened={initialOpened}
      onChange={setIsOpened}
      className="_overflow-disable"
    >
      <ContentWrapper exchange={EExchange.cex} />
    </Panel>
  );
};

export { Charts };
