import React from 'react';

import { IDextWTOrganicVolumeTask } from 'types/bots';

interface ICloseBarCellProps {
  task: IDextWTOrganicVolumeTask;
}

const CloseBarCell: React.FC<ICloseBarCellProps> = ({ task }) => {
  return <div>{task.options.close_bar_before}</div>;
};

export { CloseBarCell };
