import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from 'store';
import { withApplicationLayout } from 'tools';

import { SessionLogoutModal } from 'ui';
import { Project, Wallets, DexPair, CexPair, Accounts, Authentication } from 'pages';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';
import { fetchGasPrice } from 'store/slices/user';

const Routing: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const userIsAuthenticated = useTypedSelector(store => Boolean(store.auth.jwt));
  const userRole = useTypedSelector(store => store.user.user?.role);

  useEffect(() => {
    if (userRole === 'admin' && location.pathname === '/') {
      navigate('/wallets');
    }
  }, [navigate, userRole, location]);

  useEffect(() => {
    if (userIsAuthenticated) {
      dispatch(fetchGasPrice({}));
    }
  }, [userIsAuthenticated, dispatch]);

  if (!userIsAuthenticated) {
    return (
      <Routes>
        <Route path="/login" element={<Authentication />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return withApplicationLayout(
    <>
      <SessionLogoutModal />
      <Routes>
        <Route
          path="wallets"
          element={
            <PrivateRoute roles={['admin']}>
              <Wallets />
            </PrivateRoute>
          }
        />
        <Route path="accounts" element={<Accounts />} />
        <Route path="project/:id" element={<Project />} />
        <Route path="project/:projectId/dex-pair/:pairId" element={<DexPair />} />
        <Route path="project/:projectId/cex-pair/:pairId" element={<CexPair />} />
        <Route index element={<></>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>,
  );
};

export { Routing };
