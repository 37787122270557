import React, { useContext, useMemo } from 'react';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import { ICexWTOrganicTask } from 'types/bots';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { calculateATRs } from 'utils/calculates';
import { normalizeNumber, formatToken } from 'utils/formats';
import { durationToMs } from 'utils/duration';
import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';
import { ATR_PERIOD } from 'constants/numbers';

interface IDailyVolumeProps {
  task: ICexWTOrganicTask;
}

const DailyVolume: React.FC<IDailyVolumeProps> = ({ task }) => {
  const { organicCandles } = useContext(CexWashAlgorithmContext);
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const timeFrame = useMemo(() => task.options.time_frame, [task.options.time_frame]);
  const baseVolume = useMemo(
    () => formatUnits(task.options.base_volume, 18),
    [task.options.base_volume],
  );

  const sequence = useMemo(() => {
    const find = timeFrame
      ? WT_TIMEFRAMES.find(el => durationToMs(el.value) === durationToMs(timeFrame))
      : undefined;

    return find || timeFrame ? organicCandles[find ? find.value : timeFrame] ?? [] : [];
  }, [timeFrame, organicCandles]);

  const ATRS = useMemo(() => calculateATRs({ candles: sequence, period: ATR_PERIOD }), [sequence]);

  const dailyVolume = useMemo(() => {
    if (!timeFrame) return undefined;

    const dailyVolumeNumber =
      ATRS.reduce((acc, val) => acc + val * Number(baseVolume ?? '0'), 0) *
      ((24 * 60 * 60 * 1000) / (durationToMs(timeFrame) * Math.abs(sequence.length - ATR_PERIOD)));

    const normalizedNumber = normalizeNumber(dailyVolumeNumber);

    return parseUnits(normalizedNumber, 18);
  }, [timeFrame, baseVolume, ATRS, sequence.length]);

  return dailyVolume ? (
    <div style={{ textAlign: 'left' }}>
      {formatToken(dailyVolume, 18)} {cexPair.token_base.symbol}
    </div>
  ) : (
    <div>-</div>
  );
};

export { DailyVolume };
