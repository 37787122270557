import React from 'react';
import { Table } from '@tanstack/react-table';
import { useTypedSelector } from 'store';

import { TableCheckbox } from 'ui';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface ICexAccountHeadHead {
  table: Table<ICexAccounts>;
}

const NoteAccountHead: React.FC<ICexAccountHeadHead> = ({ table }) => {
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  return (
    <div className="checkbox-area">
      {isAdmin && (
        <TableCheckbox
          id="select-all"
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      )}
      <span>Notes</span>
    </div>
  );
};

export { NoteAccountHead };
