import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import {
  StatisticTable,
  StatisticHeader,
  StatisticCell,
  StatisticTotalCell,
} from 'common/statistic';
import { ETransactionAction } from 'api/apiTransactions/models';
import { IStatisticToken, IStatisticTransaction } from 'api/apiStatistic/models';
import {
  addBignumbers,
  divideBignumbers,
  multiplyBignumbers,
  subtractBignumbers,
} from 'utils/formulas';
import { formatFiat, formatNatural, formatToken } from 'utils/formats';
import { IChartRange } from 'types/charts';

interface IWTAlgorithmTableProps {
  statisticTokens: IStatisticToken[];
  statisticTransactions: IStatisticTransaction[];
  selectedRange: IChartRange | undefined;
}

const WTAlgorithmTable: React.FC<IWTAlgorithmTableProps> = ({
  statisticTokens,
  statisticTransactions,
  selectedRange,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const afrActions = useMemo(
    () => [ETransactionAction.ActionBuy, ETransactionAction.ActionSell],
    [],
  );

  const baseToken = useMemo(
    () => statisticTokens.find(el => el.token.address === pair!.token_base.address),
    [pair, statisticTokens],
  );

  const volumes = useMemo(() => {
    if (!baseToken)
      return {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      };

    return baseToken.volumes.reduce(
      (acc, val) => {
        if (!afrActions.includes(val.action)) return acc;

        return {
          volumeTokens: addBignumbers(
            [acc.volumeTokens, 18],
            [BigNumber.from(val.amount), baseToken.token.decimals],
          ),
          volumeUsd: addBignumbers([acc.volumeUsd, 18], [BigNumber.from(val.amount_usd), 6]),
        };
      },
      {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      },
    );
  }, [statisticTokens, afrActions, baseToken]);

  const transactions = useMemo(() => {
    if (!baseToken)
      return {
        total: 0,
        failed: 0,
      };

    return statisticTransactions.reduce(
      (acc, val) => {
        if (!afrActions.includes(val.action)) return acc;

        return {
          total: acc.total + val.total,
          failed: acc.failed + val.failed,
        };
      },
      { total: 0, failed: 0 },
    );
  }, [statisticTransactions, afrActions]);

  const avgAmounts = useMemo(() => {
    const totalTransactions = transactions.total;

    if (totalTransactions <= 0)
      return { avgAmountTokens: BigNumber.from(0), avgAmountUsd: BigNumber.from(0) };

    return {
      avgAmountTokens: divideBignumbers(
        [volumes.volumeTokens, 18],
        [parseUnits(totalTransactions.toString(), 18), 18],
      ),
      avgAmountUsd: divideBignumbers(
        [volumes.volumeUsd, 18],
        [parseUnits(totalTransactions.toString(), 18), 18],
      ),
    };
  }, [transactions, volumes]);

  const feeSpent = useMemo(() => {
    const feeToken = statisticTokens.find(el => el.token.address === pair!.token_fee.address);

    if (!feeToken || !feeToken.spent_fees)
      return {
        feeTokens: BigNumber.from(0),
        feeUsd: BigNumber.from(0),
      };

    return feeToken.spent_fees
      .filter(el => afrActions.includes(el.action))
      .reduce(
        (acc, val) => {
          return {
            feeTokens: addBignumbers(
              [BigNumber.from(val.amount), feeToken.token.decimals],
              [acc.feeTokens, 18],
            ),
            feeUsd: addBignumbers([BigNumber.from(val.amount_usd), 6], [acc.feeUsd, 18]),
          };
        },
        {
          feeTokens: BigNumber.from(0),
          feeUsd: BigNumber.from(0),
        },
      );
  }, [statisticTokens, pair, afrActions]);

  const PNLS = useMemo(() => {
    return statisticTokens.map(el => {
      const buyVolume = el.volumes.find(el => el.action === ETransactionAction.ActionBuy);
      const sellVolume = el.volumes.find(el => el.action === ETransactionAction.ActionSell);
      const tokenQuoteSimbol = pair?.token_quote.symbol;

      const isQuotePair = el.token.symbol === tokenQuoteSimbol;
      if (el.token.address === pair!.token_fee.address)
        return {
          token: el.token,
          pnlTokens: subtractBignumbers([BigNumber.from(0), 18], [feeSpent.feeTokens, 18]),
          pnlUsd: subtractBignumbers([BigNumber.from(0), 18], [feeSpent.feeUsd, 18]),
        };

      if (!buyVolume && !sellVolume)
        return {
          token: el.token,
          pnlTokens: BigNumber.from(0),
          pnlUsd: BigNumber.from(0),
        };

      const diminutiveNumberToken = isQuotePair ? sellVolume?.amount : buyVolume?.amount;
      const numberSubtractToken = isQuotePair ? buyVolume?.amount : sellVolume?.amount;
      const diminutiveNumberUsd = isQuotePair ? sellVolume?.amount_usd : buyVolume?.amount_usd;
      const numberSubtractUsd = isQuotePair ? buyVolume?.amount_usd : sellVolume?.amount_usd;

      return {
        token: el.token,
        pnlTokens: subtractBignumbers(
          [BigNumber.from(diminutiveNumberToken ? diminutiveNumberToken : '0'), el.token.decimals],
          [BigNumber.from(numberSubtractToken ? numberSubtractToken : '0'), el.token.decimals],
        ),
        pnlUsd: subtractBignumbers(
          [BigNumber.from(diminutiveNumberUsd ? diminutiveNumberUsd : '0'), 6],
          [BigNumber.from(numberSubtractUsd ? numberSubtractUsd : '0'), 6],
        ),
      };
    });
  }, [statisticTokens, feeSpent]);

  const ROIS = useMemo(() => {
    return statisticTokens.map(el => {
      const pnl = PNLS.find(pnl => pnl.token.address === el.token.address);

      if (!pnl)
        return {
          token: el.token,
          roiTokens: BigNumber.from(0),
          roiUsd: BigNumber.from(0),
        };

      const startBalanceTokensBN = BigNumber.from(
        selectedRange === 'ALL' ? el.initial_balance ?? '0' : el.start_balance,
      );
      const startBalanceUsdBN = BigNumber.from(
        selectedRange === 'ALL' ? el.initial_balance_usd ?? '0' : el.start_balance_usd,
      );

      return {
        token: el.token,
        roiTokens: !startBalanceTokensBN.eq(BigNumber.from(0))
          ? multiplyBignumbers(
              [
                divideBignumbers([pnl.pnlTokens, 18], [startBalanceTokensBN, el.token.decimals]),
                18,
              ],
              [parseUnits('100', 18), 18],
            )
          : BigNumber.from(0),
        roiUsd: !startBalanceUsdBN.eq(BigNumber.from(0))
          ? multiplyBignumbers(
              [divideBignumbers([pnl.pnlUsd, 18], [startBalanceUsdBN, 6]), 18],
              [parseUnits('100', 18), 18],
            )
          : BigNumber.from(0),
      };
    });
  }, [statisticTokens, PNLS, selectedRange]);

  const TOTALS = useMemo(() => {
    const totalPnl = PNLS.reduce((acc, val) => {
      return addBignumbers([acc, 18], [val.pnlUsd, 18]);
    }, BigNumber.from(0));

    const totalStartBalanceUsd = statisticTokens.reduce((acc, val) => {
      const startBalanceUsd =
        selectedRange === 'ALL' ? val.initial_balance_usd ?? '0' : val.start_balance_usd;

      return addBignumbers([acc, 18], [BigNumber.from(startBalanceUsd), 6]);
    }, BigNumber.from(0));

    return {
      totalPnl: totalPnl,
      totalROI: totalStartBalanceUsd.eq(BigNumber.from(0))
        ? BigNumber.from(0)
        : multiplyBignumbers(
            [divideBignumbers([totalPnl, 18], [totalStartBalanceUsd, 18]), 18],
            [parseUnits('100', 18), 18],
          ),
    };
  }, [PNLS, statisticTokens, selectedRange]);

  return (
    <StatisticTable>
      <thead>
        <tr>
          <StatisticHeader title={'Volume'} />
          <StatisticHeader title={'Avg amount'} />
          <StatisticHeader title={'Transactions'} />
          <StatisticHeader title={'Fee spent'} />
          <StatisticHeader title={'PnL'} />
          <StatisticHeader title={'ROI'} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <StatisticCell
            title={formatToken(volumes.volumeTokens) + ' ' + pair!.token_base.symbol}
            subtitle={`(${formatFiat(volumes.volumeUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(avgAmounts.avgAmountTokens) + ' ' + pair!.token_base.symbol}
            subtitle={`(${formatFiat(avgAmounts.avgAmountUsd, 18)})`}
          />
          <StatisticCell
            title={formatNatural(transactions.total.toString())}
            subtitle={`(${formatNatural(transactions.failed.toString())} failed)`}
          />
          <StatisticCell
            title={formatToken(feeSpent.feeTokens) + ' ' + pair!.token_fee.symbol}
            subtitle={`(${formatFiat(feeSpent.feeUsd, 18)})`}
          />
          <StatisticCell
            title={
              formatToken(
                PNLS.find(el => el.token.address === pair!.token_base.address)?.pnlTokens ??
                  BigNumber.from(0),
              ) +
              ' ' +
              pair!.token_base.symbol
            }
            subtitle={`(${formatFiat(
              PNLS.find(el => el.token.address === pair!.token_base.address)?.pnlUsd ??
                BigNumber.from(0),
              18,
            )})`}
          />
          <StatisticCell
            title={
              formatFiat(
                ROIS.find(el => el.token.address === pair!.token_base.address)?.roiTokens ??
                  BigNumber.from(0),
                18,
                '',
                { zeroWithoutMinus: true },
              ) + '%'
            }
            subtitle={`/ ${formatFiat(
              ROIS.find(el => el.token.address === pair!.token_base.address)?.roiUsd ??
                BigNumber.from(0),
              18,
              '',
              { zeroWithoutMinus: true },
            )}%`}
          />
        </tr>
        {PNLS.filter(el => el.token.address !== pair!.token_base.address).map((el, index) => {
          const ROI = ROIS.find(roi => roi.token.address === el.token.address)!;

          return (
            <tr key={index}>
              <StatisticCell title={''} subtitle={''} />
              <StatisticCell title={''} subtitle={''} />
              <StatisticCell title={''} subtitle={''} />
              <StatisticCell title={''} subtitle={''} />
              <StatisticCell
                title={formatToken(el.pnlTokens) + ' ' + el.token.symbol}
                subtitle={`(${formatFiat(el.pnlUsd, 18)})`}
              />
              <StatisticCell
                title={formatFiat(ROI.roiTokens, 18, '', { zeroWithoutMinus: true }) + '%'}
                subtitle={`/ ${formatFiat(ROI.roiUsd, 18, '', { zeroWithoutMinus: true })}%`}
              />
            </tr>
          );
        })}
        <tr>
          <StatisticCell title={''} subtitle={''} />
          <StatisticCell title={''} subtitle={''} />
          <StatisticCell title={''} subtitle={''} />
          <StatisticCell title={''} subtitle={''} />
          <StatisticTotalCell title="Total" subtitle={formatFiat(TOTALS.totalPnl, 18)} />
          <StatisticTotalCell
            title="Total"
            subtitle={formatFiat(TOTALS.totalROI, 18, '', { zeroWithoutMinus: true }) + '%'}
          />
        </tr>
      </tbody>
    </StatisticTable>
  );
};

export default WTAlgorithmTable;
