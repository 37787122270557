import React, { useContext, useState, useCallback } from 'react';

import { Button } from 'ui';
import { BotSettingsModal } from 'modals';
import { PairAFRBotContext } from 'context/PairAFRBotContext';
import { EDexBot } from 'types/bots';

import { CogIcon } from 'assets/icons';
import './toolbar.scss';

const Toolbar: React.FC = () => {
  const { botSettings, handleLoadBotSettings } = useContext(PairAFRBotContext);

  const [botSettingsModalOpened, setBotSettingsModalOpened] = useState(false);

  const handleSettingBot = useCallback(() => {
    setBotSettingsModalOpened(true);
  }, []);

  return (
    <>
      <div className="mm-afr-bot-panel__toolbar">
        <div className="mm-afr-bot-panel__toolbar__right">
          <Button color="transparent" className="settings-button" onClick={handleSettingBot}>
            <CogIcon color={'#5932EA'} />
            <span>Settings</span>
          </Button>
        </div>
      </div>
      {botSettingsModalOpened && (
        <BotSettingsModal
          onSave={() => {
            handleLoadBotSettings();
          }}
          bot={EDexBot.anti_font_run}
          buySellBotSettings={botSettings}
          onOpen={() => setBotSettingsModalOpened(true)}
          onClose={() => setBotSettingsModalOpened(false)}
        />
      )}
    </>
  );
};

export default Toolbar;
