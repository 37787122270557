import React, { useContext } from 'react';

import { PanelLoading, PanelError } from 'ui';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';

import './contentWrapper.scss';

interface IContentWrapperProps {
  children?: React.ReactNode;
}

const ContentWrapper: React.FC<IContentWrapperProps> = ({ children }) => {
  const { loading, errorMessage } = useContext(PairWashTradingContext);

  if (loading.get) return <PanelLoading />;

  if (!loading.get && errorMessage) return <PanelError title={'Failed'} text={errorMessage} />;

  return <div className="mm-wash-trading-bot-content-wrapper">{children}</div>;
};

export default ContentWrapper;
