import { useCallback, useState, useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { parseUnits } from '@ethersproject/units';
import { useTypedDispatch } from 'store';

import { DepositWithdrawContext } from 'context/DepositWithdrawContext/DepositWithdrawContext';
import { ApiPairs } from 'api';
import { IDexPair } from 'types/pairs';
import { ITransfer } from 'types/transfers';
import { setAlertState, dropAlertState } from 'store/slices/ui';

interface IUseWithdrawProps {
  onClose: () => void;
  onRemountTable: () => void;
  pair: IDexPair;
  onWithdrawResult?: (transfers: ITransfer[]) => void;
}

const useWithdraw = ({ onClose, onRemountTable, pair, onWithdrawResult }: IUseWithdrawProps) => {
  const dispatch = useTypedDispatch();
  const { account } = useWeb3React();
  const [loading, setLoading] = useState(false);

  const { innerWalletsValid, formError, selectedToken, innerWallets } =
    useContext(DepositWithdrawContext);

  const handleWithdraw = useCallback(async () => {
    if (!account) {
      return onClose();
    }

    if (formError) return;

    if (!innerWalletsValid) return;

    try {
      setLoading(true);

      const { isSuccess, errorMessage, data } = await ApiPairs.withdraw({
        recipient_address: account,
        pairId: pair.id,
        transfers: innerWallets.get
          .filter(el => !el.disabled)
          .map(wallet => ({
            amount: wallet.max_amount
              ? undefined
              : parseUnits(wallet.balance, selectedToken.get.decimals).toString(),
            max_amount: wallet.max_amount,
            token_id: selectedToken.get.id,
            wallet_id: wallet.id,
          })),
      });

      if (!isSuccess && errorMessage) {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
      } else if (isSuccess && data) {
        if (onWithdrawResult) {
          onWithdrawResult(data.transfers);
        }
        onRemountTable();
      }
    } catch (error) {
      console.log(error);

      dispatch(
        setAlertState({
          type: 'failed',
          text: 'Something went wrong...',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => dispatch(dropAlertState()),
        }),
      );
    } finally {
      setLoading(false);
      onClose();
    }
  }, [
    innerWalletsValid,
    onClose,
    pair,
    formError,
    onRemountTable,
    selectedToken.get,
    innerWallets.get,
    dispatch,
    account,
    onWithdrawResult,
  ]);

  return {
    withdraw: handleWithdraw,
    loading,
  };
};

export default useWithdraw;
