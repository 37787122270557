import React, { memo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import cn from 'classnames';

import { ENetwork } from 'types';
import { ITransactionItem } from 'api/apiTransactions/models';
import { TableAddressChip } from 'ui';
import { ETransactionsStatuses } from 'api/apiTransactions/models/ITransactionItem';
import { capitalize } from 'lodash';

interface IHashProps {
  hash: string;
  network: ENetwork;
  status: ETransactionsStatuses;
}

export const Hash: React.FC<IHashProps> = memo(({ hash, network, status }) => {
  return (
    <div className="transaction-hash">
      <div data-tooltip-id={`${hash}-tooltip`} className={cn('indicator')}>
        <div className={cn('indicator-inner', `_${status}`)} />
      </div>
      <ReactTooltip
        place="top"
        id={`${hash}-tooltip`}
        openOnClick={false}
        className={cn('transaction-hash__tooltip')}
      >
        {capitalize(status)}
      </ReactTooltip>
      <TableAddressChip
        data-tooltip-id={`${hash}-tooltip`}
        address={hash}
        addressType={'transaction'}
        network={network}
        format={'short'}
      />
    </div>
  );
});

export const hashAccessor = (row: ITransactionItem) => ({
  hash: row.hash,
  network: row.network,
  status: row.status,
});
