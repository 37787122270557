import React, { createContext, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTypedSelector } from 'store';

import { ApiBot, ApiBuySellBot } from 'api';
import { EDexBot, IDexBotConfig, IDexBuySellBotTaskItem } from 'types/bots';
import { IBuySellTaskStatus, IBuySellBotTaskItemInner } from 'types/buy-sell-bot';

interface IPairBuySellBotContext {
  loading: { get: boolean; set: (v: boolean) => void };
  records: {
    get: IBuySellBotTaskItemInner[] | undefined;
    set: (v: IBuySellBotTaskItemInner[] | undefined) => void;
  };
  botSettings: IDexBotConfig<EDexBot.buy_sell_bot> | undefined;
  handleLoadRecords: () => void;
  handleLoadBotSettings: () => void;
  extendedRow: number | undefined;
  setExtendedRow: (v: number | undefined) => void;
  errorMessage: string | undefined;
}

export const PairBuySellBotContext = createContext<IPairBuySellBotContext>({
  loading: { get: false, set: () => {} },
  records: { get: undefined, set: () => {} },
  botSettings: undefined,
  handleLoadRecords: () => {},
  handleLoadBotSettings: () => {},
  extendedRow: undefined,
  setExtendedRow: () => {},
  errorMessage: undefined,
});

interface IPairBuySellBotContextProviderProps {
  children?: React.ReactNode;
}

export const PairBuySellBotContextProvider: React.FC<IPairBuySellBotContextProviderProps> = ({
  children,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const [loading, setLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<IBuySellBotTaskItemInner[] | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [extendedRow, setExtendedRow] = useState<number | undefined>(undefined);
  const [botSettings, setBotSettings] = useState<IDexBotConfig<EDexBot.buy_sell_bot> | undefined>(
    undefined,
  );

  const handleSetExtendedRow = useCallback((index: number | undefined) => {
    if (index == undefined) {
      setExtendedRow(undefined);
    } else {
      setExtendedRow(v => (v === index ? undefined : index));
    }
  }, []);

  const parseRecords = useCallback((records: IDexBuySellBotTaskItem[]) => {
    const inProgressRecord = records.find(el => el.is_current);

    if (!inProgressRecord)
      return records.map(el => ({ ...el, status: 'future' as IBuySellTaskStatus }));

    return records
      .map(el => {
        if (Number(el.schedule.amount_percent) === 100)
          return { ...el, status: 'past' as IBuySellTaskStatus };

        if (el.is_current) return { ...el, status: 'current' as IBuySellTaskStatus };

        if (
          !el.is_current &&
          dayjs(el.task.start_time).diff(dayjs(inProgressRecord.task.start_time)) <= 0
        )
          return {
            ...el,
            status: 'past' as IBuySellTaskStatus,
          };

        return {
          ...el,
          status: 'future' as IBuySellTaskStatus,
        };
      })
      .sort((a, b) => {
        if (dayjs(a.task.start_time).valueOf() > dayjs(b.task.start_time).valueOf()) return -1;

        if (dayjs(a.task.start_time).valueOf() === dayjs(b.task.start_time).valueOf()) return 0;

        return 1;
      })
      .sort((a, b) => {
        if (a.is_current && b.status === 'past') return -1;

        if (a.status === 'future' && b.status !== 'future') return -1;

        if (a.status === 'past' && b.is_current) return 1;

        if (a.status !== 'future' && b.status === 'future') return 1;

        return 0;
      });
  }, []);

  const handleLoadRecords = useCallback(async () => {
    setLoading(true);
    setExtendedRow(undefined);
    setErrorMessage(undefined);

    if (!pair) return;

    try {
      const { isSuccess, errorMessage, data } = await ApiBuySellBot.buySellStatistics({
        pairId: pair.id,
      });

      if (isSuccess && data) {
        setRecords(data.items ? parseRecords(data.items) : undefined);
        setErrorMessage(undefined);
      } else {
        setRecords(undefined);
        setErrorMessage(errorMessage ?? undefined);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        'You get an error while trying to get buy sell bot records. Try to refresh your page...',
      );
    } finally {
      setLoading(false);
    }
  }, [pair, parseRecords]);

  const handleLoadBotSettings = useCallback(async () => {
    setLoading(true);

    if (!pair) return;

    try {
      const { isSuccess, data, errorMessage } = await ApiBot.getDexBotConfig({
        pairId: pair.id,
        bot: EDexBot.buy_sell_bot,
      });

      if (isSuccess && data) {
        setErrorMessage(undefined);
        setBotSettings(data);
      } else if (!isSuccess) {
        setErrorMessage(errorMessage ?? 'Failed to load bot config. Try to refresh your page...');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed to load bot config. Try to refresh your page...');
    } finally {
      setLoading(false);
    }
  }, [pair]);

  useEffect(() => {
    handleLoadRecords();
  }, []);

  return (
    <PairBuySellBotContext.Provider
      value={{
        loading: { get: loading, set: setLoading },
        records: { get: records, set: setRecords },
        botSettings,
        handleLoadRecords,
        handleLoadBotSettings,
        errorMessage,
        extendedRow: extendedRow,
        setExtendedRow: handleSetExtendedRow,
      }}
    >
      {children}
    </PairBuySellBotContext.Provider>
  );
};
