import { useEffect, useState, useCallback } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useWeb3React } from '@web3-react/core';

import { useWalletContract } from 'hooks/useWalletContract/useWalletContract';
import { ERC20 } from 'abi';
import { getBalanceOf, isAddress } from 'utils';

export function useERC20Balance(
  tokenAddress: string | undefined,
): [BigNumber | null, boolean, string | null, () => void] {
  const { account, library, chainId } = useWeb3React();

  const [storedTokenAddress, setStoredTokenAddress] = useState('');
  const [balance, setBalance] = useState<BigNumber | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const contract = useERC20Contract(tokenAddress);

  const init = useCallback(() => {
    if (typeof storedTokenAddress !== 'string' || storedTokenAddress.length !== 42 || !contract) {
      setBalance(null);
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const balance = await getBalanceOf({
          tokenAddress: storedTokenAddress,
          library,
          contract,
          account,
          chainId,
        });

        setBalance(balance);
      } catch (e) {
        console.error(e);
        setError((e as string).toString());
      } finally {
        setLoading(false);
      }
    })();
  }, [account, chainId, library, storedTokenAddress, contract]);

  // check tokenAddress and save
  useEffect(() => {
    if (!tokenAddress || String(tokenAddress).toLocaleLowerCase() === storedTokenAddress) {
      return;
    }

    try {
      isAddress(tokenAddress);
      setStoredTokenAddress(String(tokenAddress).toLocaleLowerCase());
      setBalance(null);
    } catch (e) {}
  }, [tokenAddress, storedTokenAddress]);

  useEffect(() => {
    if (balance !== null) {
      setLoading(false);
      return;
    }

    init();
  }, [balance, init]);

  return [balance, loading, error, init];
}

export const useERC20Contract = (tokenAddress: string | undefined) => {
  const contract = useWalletContract(tokenAddress, ERC20, true);

  return contract;
};
