import { useMemo } from 'react';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { Network } from 'constants/common';
import { addBignumbers, divideBignumbers, multiplyBignumbers } from 'utils/formulas';
import { formatFiat, formatToken } from 'utils/formats';
import { calculateSwapsGasPrice } from 'utils/calculates';

export const useFeeCostsUiString = ({
  totalTokenAmount,
  minAmount,
  maxAmount,
}: {
  totalTokenAmount: string | undefined;
  minAmount: string | undefined;
  maxAmount: string | undefined;
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const gasPriceInUnit = useTypedSelector(
    store => store.user.gasPrices[dexPair.network].safeGasPrice,
  );
  const tokenPriceInUsd = dexPair.token_fee.price_usd;

  const { unit, symbol } = Network[dexPair.network];

  const totalTokenAmountValid = useMemo(
    () => totalTokenAmount && totalTokenAmount !== '' && Number(totalTokenAmount) > 0,
    [totalTokenAmount],
  );

  const minAmountValid = useMemo(
    () => minAmount && minAmount !== '' && Number(minAmount) > 0,
    [minAmount],
  );

  const maxAmountValid = useMemo(
    () => maxAmount && maxAmount !== '' && Number(maxAmount) > 0,
    [maxAmount],
  );

  const transactionFeeBN = useMemo(() => {
    return calculateSwapsGasPrice({
      swap_gas_limit: dexPair.swap_gas_limit,
      gas_unit: unit,
      gasPriceInUnit: gasPriceInUnit,
      swaps_count: 1,
    });
  }, [dexPair, gasPriceInUnit, unit]);

  const feeCostsBN = useMemo(() => {
    if (!totalTokenAmountValid || !minAmountValid || !maxAmountValid) return parseUnits('0', 18);

    const multiple1 = multiplyBignumbers(
      [transactionFeeBN, 18],
      [
        parseUnits(totalTokenAmount ?? '0', dexPair.token_base.decimals),
        dexPair.token_base.decimals,
      ],
    );

    const add1 = addBignumbers(
      [parseUnits(minAmount ?? '0', dexPair.token_base.decimals), dexPair.token_base.decimals],
      [parseUnits(maxAmount ?? '0', dexPair.token_base.decimals), dexPair.token_base.decimals],
    );

    const divide1 = divideBignumbers([add1, 18], [parseUnits('2', 18), 18]);

    return divideBignumbers([multiple1, 18], [divide1, 18]);
  }, [
    transactionFeeBN,
    totalTokenAmount,
    minAmount,
    maxAmount,
    totalTokenAmountValid,
    minAmountValid,
    maxAmountValid,
    dexPair,
  ]);

  const feeCostsUsd = useMemo(() => {
    return multiplyBignumbers([feeCostsBN, 18], [BigNumber.from(tokenPriceInUsd), 6]);
  }, [feeCostsBN, tokenPriceInUsd]);

  const result = useMemo(() => {
    if (!totalTokenAmountValid || !minAmountValid || !maxAmountValid) return null;

    return `${formatToken(feeCostsBN, 18)} ${symbol} (${formatFiat(feeCostsUsd, 18, '$')})`;
  }, [totalTokenAmountValid, minAmountValid, maxAmountValid, feeCostsBN, feeCostsUsd, symbol]);
  feeCostsUsd;

  return result;
};
