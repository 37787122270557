import React, { useMemo } from 'react';
import { Row } from '@tanstack/react-table';

import { IWallet } from 'api/apiWallets/models';
import { formatNatural } from 'utils/formats';

interface ITransactionsCellProps {
  row: Row<IWallet>;
}

const TransactionsCell: React.FC<ITransactionsCellProps> = React.memo(({ row }) => {
  const totalTransactions = useMemo(
    () =>
      row.original.tokens
        ? row.original.tokens.reduce((acc, val) => {
            return acc + val.transactions_count;
          }, 0)
        : 0,
    [row]
  );

  return <div>{formatNatural(totalTransactions.toString())}</div>;
});

export default TransactionsCell;
