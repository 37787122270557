import React, { useContext } from 'react';
import { PanelLoading, PanelError } from 'ui';
import { PairBalanceBotContext } from 'context/PairBalanceBotContext';

import './balanceContentWrapper.scss';

interface IContentWrapperProps {
  children?: React.ReactNode;
}

const BalanceContentWrapper: React.FC<IContentWrapperProps> = ({ children }) => {
  const { loading, errorMessage } = useContext(PairBalanceBotContext);

  if (loading.get) {
    return <PanelLoading />;
  }

  if (!loading.get && errorMessage) {
    return <PanelError title={'Error'} text={errorMessage} />;
  }

  return <div className="mm-balance-bot-panel__content-wrapper">{children}</div>;
};

export default BalanceContentWrapper;
