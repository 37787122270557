import { AppDispatch } from 'store';

import { ApiBot } from 'api';

import { ECexBot, ICexWTOrganicTask } from 'types/bots';
import { dropAlertState, setAlertState } from 'store/slices/ui';

const uniqIdStart = '00000000';

interface AddTaskProps {
  dispatch: AppDispatch;
  task?: ICexWTOrganicTask;
  newTask: ICexWTOrganicTask;
  handleLoadRecords: () => void;
  onClose?: () => void;
  onSuccess?: () => void;
  setLoading?: (v: boolean) => void;
  mode: 'create' | 'edit' | 'delete';
  pairId: number;
}

export const addOrganicTask = async ({
  dispatch,
  task,
  newTask,
  handleLoadRecords,
  pairId,
  onClose,
  onSuccess,
  setLoading,
  mode,
}: AddTaskProps) => {
  try {
    if (setLoading) {
      setLoading(true);
    }

    const {
      data,
      isSuccess: isSuccessFirst,
      errorMessage: errorMessageFirst,
    } = await ApiBot.getCexBotConfig({
      pairId,
      bot: ECexBot.wash_trading,
    });

    const botConfig = data!;

    if (!botConfig.wt_options) {
      botConfig.wt_options = {
        volumes_tasks: [],
        organic_volumes_tasks: [],
      };
    }

    if (!botConfig.wt_options.volumes_tasks) {
      botConfig.wt_options.volumes_tasks = [];
    }

    if (!botConfig.wt_options.organic_volumes_tasks) {
      botConfig.wt_options.organic_volumes_tasks = [];
    }

    const taskIdForEdit = task?.uniq_id;

    const prevTasks = botConfig.wt_options.organic_volumes_tasks.map((task: ICexWTOrganicTask) => {
      const taskId = task.uniq_id;
      if (taskIdForEdit === taskId) {
        return newTask;
      } else {
        return task;
      }
    });

    if (!isSuccessFirst) {
      onClose?.();
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: errorMessageFirst,
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
      return;
    }

    const newTasksList = [...prevTasks, newTask];
    const updatedTasks = taskIdForEdit ? prevTasks : newTasksList;

    if (mode === 'delete') {
      const deletedTaskIndex = updatedTasks.findIndex(
        (currentTask: ICexWTOrganicTask) => currentTask.uniq_id === task?.uniq_id,
      );
      updatedTasks.splice(deletedTaskIndex, 1);
    }

    botConfig.wt_options.organic_volumes_tasks = updatedTasks.map((task, index) => ({
      ...task,
      uniq_id: `${uniqIdStart}${index}`.slice(-8),
    }));

    const { isSuccess, errorMessage } = await ApiBot.saveCexBotConfig({
      pairId,
      bot: ECexBot.wash_trading,
      body: botConfig,
    });

    if (!isSuccess) {
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: errorMessage,
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
      return;
    }

    if (isSuccess) {
      if (onSuccess) {
        onSuccess();
      } else {
        handleLoadRecords();
        onClose?.();
        dispatch(
          setAlertState({
            type: 'success',
            text:
              mode === 'create'
                ? 'You successfully create new task!'
                : 'You successfully edit task!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    }
  } catch (e) {
    console.error(e);
    dispatch(
      setAlertState({
        type: 'failed-img',
        text: 'Something went wrong',
        onClose: () => dispatch(dropAlertState()),
        onSubmit: () => {
          dispatch(dropAlertState());
        },
      }),
    );
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};
