import React, { HTMLAttributes, useCallback } from 'react';
import cn from 'classnames';

import './rangeInput.scss';

interface IRangeInputProps extends HTMLAttributes<HTMLInputElement> {
  min: number;
  max: number;
  value: number;
  step?: number;
  setValue: (v: number) => void;
  disabled?: boolean;
}

const RangeInput: React.FC<IRangeInputProps> = ({
  min,
  max,
  step,
  value,
  setValue,
  className,
  disabled = false,
  ...rest
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(Number(event.currentTarget.value));
    },
    [setValue]
  );

  return (
    <input
      className={cn({ [className ?? '']: !!className }, 'mm-range-input')}
      type="range"
      min={min}
      max={max}
      disabled={disabled}
      step={step ?? undefined}
      value={value}
      onChange={(e) => handleChange(e)}
      {...rest}
    />
  );
};

export { RangeInput };
