import React, { useMemo } from 'react';
import { formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';
import { dailyVolume, dexDailyFees } from 'utils/daily';
import { durationToMs } from 'utils/duration';
import { formatFiat, formatToken } from 'utils/formats';

interface IDailyFeeCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const DailyFeeCell: React.FC<IDailyFeeCellProps> = ({ task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const _dailyVolume = useMemo(() => {
    const minPause =
      durationToMs(task.min_pause_between_series) + durationToMs(task.min_series_duration);

    const maxPause =
      durationToMs(task.max_pause_between_series) + durationToMs(task.max_series_duration);

    const { volume } = dailyVolume({
      delay_min: minPause,
      delay_max: maxPause,
      volume_max: formatUnits(BigNumber.from(task.max_volume), dexPair.token_base.decimals),
      volume_min: formatUnits(BigNumber.from(task.min_volume), dexPair.token_base.decimals),
      pair: dexPair,
    });

    return volume;
  }, [dexPair, task]);

  const dailyFee = useMemo(() => {
    const avgGroupTransactions = task.min_transactions_in_group + task.max_transactions_in_group;
    const dailyGroups = Math.floor(
      (24 * 60 * 60 * 1000) /
        ((durationToMs(task.max_pause_between_groups) +
          durationToMs(task.min_pause_between_groups)) /
          2),
    );

    const { base, fee, total } = dexDailyFees({
      daily_txs: dailyGroups * avgGroupTransactions,
      daily_volume: _dailyVolume,
      dexPair,
    });

    return { base, fee, total };
  }, [dexPair, task, _dailyVolume]);

  return (
    <div>
      {formatToken(dailyFee.base.amount, 18)} {dexPair.token_base.symbol}
      {formatToken(dailyFee.fee.amount, 18) === '0'
        ? ' '
        : '+ ' + formatToken(dailyFee.fee.amount, 18) + ' ' + dexPair.token_fee.symbol}{' '}
      / {formatFiat(dailyFee.total.usd, 18)}
    </div>
  );
};

export { DailyFeeCell };
