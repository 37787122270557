import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { Spinner } from 'ui';
import { cexPairBots, eBotMapping, IPairBot } from 'types/bots';
import { ICexPair } from 'types/pairs';
import { ApiCexAccount } from 'api/apiCexAccount';

import './style.scss';

interface IInnerConnectedBot extends IPairBot {
  is_connected: boolean;
}

interface IRowConnectedBotsProps {
  connected_bots?: IPairBot[];
  pair: ICexPair;
  accountId: number;
  onChangeConnectedBots?: (new_connected_bots: IPairBot[]) => void;
}

const PairCexTableConnectedBots: React.FC<IRowConnectedBotsProps> = React.memo(
  ({ connected_bots = [], pair, accountId, onChangeConnectedBots }) => {
    const [connectedBots, setConnectedBots] = useState<IPairBot[] | undefined>(undefined);
    const [innerBots, setInnerBots] = useState<IInnerConnectedBot[]>([]);
    const [loadingBotId, setLoadingBotId] = useState<number | undefined>(undefined);
    useEffect(() => {
      if (JSON.stringify(connectedBots) !== JSON.stringify(connected_bots)) {
        setConnectedBots(connected_bots);
        setInnerBots(
          new Array(7)
            .fill(0)
            .map((_, _idx) => _idx + 1)
            .map(el => {
              const connectedBotById = connected_bots.find(bot => bot.id === el);

              if (connectedBotById) return { ...connectedBotById, is_connected: true };

              const notConnectedBotById = cexPairBots.find(bot => bot.id === el);

              if (notConnectedBotById) return { ...notConnectedBotById, is_connected: false };

              return null;
            })
            .filter(el => el !== null) as IInnerConnectedBot[],
        );
      }
    }, [connected_bots, connectedBots]);

    const handleDisconnectBot = useCallback(
      async (botId: number) => {
        if (loadingBotId) return;

        const newInnerBots = innerBots.map(el =>
          el.id === botId ? { ...el, is_connected: false } : el,
        );
        const newConnectedBots = newInnerBots
          .filter(el => el.is_connected)
          .map(el => ({ ...el, is_connected: undefined }));

        const newAccounts = [
          {
            id: accountId,
            bots_ids: newInnerBots.filter(el => el.is_connected).map(el => el.id),
            is_disabled: false,
          },
        ];

        if (onChangeConnectedBots) {
          onChangeConnectedBots(newConnectedBots);
        }

        try {
          setInnerBots(newInnerBots);
          setLoadingBotId(botId);
          await ApiCexAccount.connectAccountToCexPair({ cexId: pair.id, accounts: newAccounts });
        } catch (error) {
        } finally {
          setLoadingBotId(undefined);
        }
      },
      [pair, innerBots, onChangeConnectedBots, loadingBotId],
    );

    const handleConnectBot = useCallback(
      async (botId: number) => {
        if (loadingBotId) return;

        const newInnerBots = innerBots.map(el =>
          el.id === botId ? { ...el, is_connected: true } : el,
        );
        const newConnectedBots = newInnerBots
          .filter(el => el.is_connected)
          .map(el => ({ ...el, is_connected: undefined }));

        const newAccounts = [
          {
            id: accountId,
            bots_ids: newInnerBots.filter(el => el.is_connected).map(el => el.id),
            is_disabled: false,
          },
        ];

        if (onChangeConnectedBots) {
          onChangeConnectedBots(newConnectedBots);
        }

        try {
          setInnerBots(newInnerBots);
          setLoadingBotId(botId);

          await ApiCexAccount.connectAccountToCexPair({
            cexId: pair.id,
            accounts: newAccounts,
          });
        } catch (error) {
        } finally {
          setLoadingBotId(undefined);
        }
      },
      [pair, innerBots, onChangeConnectedBots, accountId, loadingBotId],
    );
    return (
      <div className="mm-pair-table-connected-bots">
        {innerBots.map(bot => {
          const { id, is_connected } = bot;

          if (loadingBotId && loadingBotId === id) {
            return <Spinner key={id} size="mini" />;
          }

          return (
            <div
              key={id}
              className={cn('bot-circle', { _connected: is_connected })}
              onClick={() => (is_connected ? handleDisconnectBot(id) : handleConnectBot(id))}
            >
              {eBotMapping[id]}
            </div>
          );
        })}
      </div>
    );
  },
);

export { PairCexTableConnectedBots };
