import React, { useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import { CheckedIcon } from './CheckedIcon';

import './checkbox.scss';

interface ICheckboxProps {
  id?: string;
  checked: boolean;
  onChange?: () => void;
  className?: string | undefined;
  color?: 'primary' | 'secondary';
}

const Checkbox: React.FC<ICheckboxProps> = ({
  id,
  checked,
  onChange,
  className,
  color = 'primary',
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      if (onChange) onChange();
    },
    [onChange],
  );

  const checkedBgColor = useMemo(() => (color === 'primary' ? '#5932EA' : '#7F91BB'), [color]);

  const internalId = useMemo(() => id ?? uuid(), [id]);

  return (
    <>
      <input
        className="mm-checkbox-input"
        checked={checked}
        type="checkbox"
        id={internalId}
        onChange={handleChange}
        onClick={e => e.stopPropagation()}
      />
      <motion.label
        initial={{ borderColor: '#7F91BB', backgroundColor: '#fff' }}
        animate={{
          borderColor: !checked ? '#7F91BB' : checkedBgColor,
          backgroundColor: !checked ? '#fff' : checkedBgColor,
        }}
        className={cn('mm-checkbox', { [className ?? '']: !!className })}
        htmlFor={internalId}
        onClick={e => e.stopPropagation()}
      >
        <CheckedIcon />
      </motion.label>
    </>
  );
};

export { Checkbox };
