import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import { StatisticTable, StatisticHeader, StatisticCell } from 'common/statistic';
import { ETransactionAction } from 'api/apiTransactions/models';
import { IStatisticToken, IStatisticTransaction } from 'api/apiStatistic/models';
import { addBignumbers, divideBignumbers } from 'utils/formulas';
import { formatFiat, formatNatural, formatToken } from 'utils/formats';

interface IAfrAlgorithmTableProps {
  statisticTokens: IStatisticToken[];
  statisticTransactions: IStatisticTransaction[];
}

const AfrAlgorithmTable: React.FC<IAfrAlgorithmTableProps> = ({
  statisticTokens,
  statisticTransactions,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const afrActions = useMemo(
    () => [ETransactionAction.ActionAFRBuy, ETransactionAction.ActionAFRSell],
    [],
  );

  const baseToken = useMemo(
    () => statisticTokens.find(el => el.token.address === pair!.token_base.address),
    [pair, statisticTokens],
  );

  const volumes = useMemo(() => {
    if (!baseToken)
      return {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      };

    return baseToken.volumes.reduce(
      (acc, val) => {
        if (!afrActions.includes(val.action)) return acc;

        return {
          volumeTokens: addBignumbers(
            [acc.volumeTokens, 18],
            [BigNumber.from(val.amount), baseToken.token.decimals],
          ),
          volumeUsd: addBignumbers([acc.volumeUsd, 18], [BigNumber.from(val.amount_usd), 6]),
        };
      },
      {
        volumeTokens: BigNumber.from(0),
        volumeUsd: BigNumber.from(0),
      },
    );
  }, [afrActions, baseToken]);

  const transactions = useMemo(() => {
    if (!baseToken)
      return {
        total: 0,
        failed: 0,
      };

    return statisticTransactions.reduce(
      (acc, val) => {
        if (!afrActions.includes(val.action)) return acc;

        return {
          total: acc.total + val.total,
          failed: acc.failed + val.failed,
        };
      },
      { total: 0, failed: 0 },
    );
  }, [statisticTransactions, afrActions, baseToken]);

  const avgAmounts = useMemo(() => {
    const successfullTransactions = transactions.total - transactions.failed;

    if (successfullTransactions <= 0)
      return { avgAmountTokens: BigNumber.from(0), avgAmountUsd: BigNumber.from(0) };

    return {
      avgAmountTokens: divideBignumbers(
        [volumes.volumeTokens, 18],
        [parseUnits(successfullTransactions.toString(), 18), 18],
      ),
      avgAmountUsd: divideBignumbers(
        [volumes.volumeUsd, 18],
        [parseUnits(successfullTransactions.toString(), 18), 18],
      ),
    };
  }, [transactions, volumes]);

  const feeSpent = useMemo(() => {
    const feeToken = statisticTokens.find(el => el.token.address === pair!.token_fee.address);

    if (!feeToken || !feeToken.spent_fees)
      return {
        feeTokens: BigNumber.from(0),
        feeUsd: BigNumber.from(0),
      };

    return feeToken.spent_fees
      .filter(el => afrActions.includes(el.action))
      .reduce(
        (acc, val) => {
          return {
            feeTokens: addBignumbers(
              [BigNumber.from(val.amount), feeToken.token.decimals],
              [acc.feeTokens, 18],
            ),
            feeUsd: addBignumbers([BigNumber.from(val.amount_usd), 6], [acc.feeUsd, 18]),
          };
        },
        {
          feeTokens: BigNumber.from(0),
          feeUsd: BigNumber.from(0),
        },
      );
  }, [statisticTokens, pair, afrActions]);

  return (
    <StatisticTable>
      <thead>
        <tr>
          <StatisticHeader title={'Volume'} />
          <StatisticHeader title={'Avg amount'} />
          <StatisticHeader title={'Transactions'} />
          <StatisticHeader title={'Fee spent'} />
          <StatisticHeader title={''} />
          <StatisticHeader title={''} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <StatisticCell
            title={formatToken(volumes.volumeTokens) + ' ' + pair!.token_base.symbol}
            subtitle={`(${formatFiat(volumes.volumeUsd, 18)})`}
          />
          <StatisticCell
            title={formatToken(avgAmounts.avgAmountTokens) + ' ' + pair!.token_base.symbol}
            subtitle={`(${formatFiat(avgAmounts.avgAmountUsd, 18)})`}
          />
          <StatisticCell
            title={formatNatural(transactions.total.toString())}
            subtitle={`(${formatNatural(transactions.failed.toString())} failed)`}
          />
          <StatisticCell
            title={formatToken(feeSpent.feeTokens) + ' ' + pair!.token_fee.symbol}
            subtitle={`(${formatFiat(feeSpent.feeUsd, 18)})`}
          />
          <StatisticCell title={''} subtitle={''} />
          <StatisticCell title={''} subtitle={''} />
        </tr>
      </tbody>
    </StatisticTable>
  );
};

export default AfrAlgorithmTable;
