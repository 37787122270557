import { createSlice } from '@reduxjs/toolkit';
import { EDexBot, ECexBot } from 'types/bots';

type IConnectedBots = Record<EDexBot | ECexBot, boolean>;

interface IPairsConnectedBotsSliceState {
  pairsConnectedBots: Record<number, IConnectedBots | undefined>;
}
const sliceInitialState: IPairsConnectedBotsSliceState = {
  pairsConnectedBots: {},
};

const pairsSlice = createSlice({
  name: 'pairs-connected-bots',
  initialState: sliceInitialState as IPairsConnectedBotsSliceState,
  reducers: {
    setConnectedBots: (
      state,
      {
        payload: { pairId, connectedBots },
      }: { payload: { pairId: number; connectedBots: IConnectedBots | undefined } },
    ) => {
      state.pairsConnectedBots[pairId] = connectedBots;
    },
  },
});

export const { setConnectedBots } = pairsSlice.actions;

export default pairsSlice.reducer;
