import React, { useCallback, useMemo, useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import { useTypedSelector } from 'store';

import { TableToolbar, TableSelectedCaption, TableTotalCaption, Spinner, FiltersPopup } from 'ui';

import { PairWalletsContext } from 'context/PairWalletsContext/PairWalletsContext';
import { IPairWallet } from 'api/apiPairs/models';
import { EExchange } from 'types';
import { EDexPairFilters } from 'types/filters';

import {
  WalletAddressHead,
  TokenBaseHead,
  TokenQuoteHead,
  TokenFeeHead,
  TotalUsdHead,
  TransactionsHead,
  ConnectedBotsHead,
} from './headers';
import {
  WalletAddressCell,
  TokenBaseCell,
  TokenQuoteCell,
  TokenFeeCell,
  TotalUsdCell,
  TransactionsCell,
  ConnectedBotsCell,
} from './columns';
import Modals from './Modals';
import Actions from './Actions';

import './walletsTable.scss';

const WalletsTable: React.FC = () => {
  const location = useLocation();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair);
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const {
    records,
    virtualRecords,
    totalRecords,
    loading,
    hasMore,
    handleLoadMore,
    handleRemount,
    rowSelection,
    selectedRecords,
  } = useContext(PairWalletsContext);

  const columns = useMemo(
    () =>
      (
        [
          {
            id: 'select',
            accessorFn: row => row,
            header: ({ table }) => <WalletAddressHead table={table} />,
            cell: ({ row }) => {
              return <WalletAddressCell row={row} />;
            },
          },
          {
            id: 'token-base',
            header: () => <TokenBaseHead />,
            accessorFn: row => row,
            cell: ({ row }) => <TokenBaseCell row={row} />,
          },
          {
            id: 'token-quote',
            header: () => <TokenQuoteHead />,
            accessorFn: row => row,
            cell: ({ row }) => <TokenQuoteCell row={row} />,
          },
          {
            id: 'token-fee',
            header: () => <TokenFeeHead />,
            accessorFn: row => row,
            cell: ({ row }) => <TokenFeeCell row={row} />,
          },
          {
            id: 'total-usd',
            header: () => <TotalUsdHead />,
            cell: ({ row }) => <TotalUsdCell row={row} />,
          },
          {
            id: 'txs',
            header: () => <TransactionsHead />,
            cell: ({ row }) => <TransactionsCell row={row} />,
          },
          isAdmin
            ? {
                id: 'connected-to-bot',
                header: ({
                  table: {
                    options: { meta },
                  },
                }) => {
                  return <ConnectedBotsHead meta={meta} />;
                },
                cell: ({
                  row,
                  table: {
                    options: { meta },
                  },
                }) => <ConnectedBotsCell meta={meta} row={row} />,
              }
            : null,
        ] as ColumnDef<IPairWallet>[]
      ).filter(el => el !== null),
    [isAdmin],
  );

  useEffect(() => {
    handleRemount();
  }, [location, dexPair]);

  const table = useReactTable({
    data: useMemo(() => records.get ?? [], [records.get]),
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: rowSelection.set,
    state: {
      rowSelection: rowSelection.get,
    },
    meta: {
      selectedWallets: selectedRecords,
      records: records.get,
      setRecords: records.set,
    },
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: useMemo(() => rows.length, [rows]),
    overscan: 5,
    estimateSize: useCallback(() => 44, []),
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  useEffect(() => {
    if (rows) {
      virtualRecords.set(virtualRows.map(({ index }) => rows[index].original));
    }
  }, [virtualRows]);

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      handleLoadMore();
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasMore && !loading) {
      handleLoadMore();
    }
  }, [rowVirtualizer.virtualItems, hasMore, loading, handleLoadMore, rows.length]);

  const paddingTop = useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = useMemo(
    () =>
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    [virtualRows, totalSize],
  );

  return (
    <>
      <Modals />
      <TableToolbar
        additional={
          isAdmin ? (
            <TableSelectedCaption selected={selectedRecords.length} totalRecords={totalRecords} />
          ) : (
            <TableTotalCaption totalRecords={totalRecords} />
          )
        }
        customFilters={<FiltersPopup exchange={EExchange.dex} type={EDexPairFilters.wallets} />}
        customActions={<Actions />}
      />
      {!!loading && (records.get === undefined || records.get.length === 0) && (
        <div className="mm-pair-wallets-table-loading">
          <Spinner size="medium" />
        </div>
      )}
      {records.get === undefined && !loading && !hasMore && (
        <div className="mm-common-table-no-info">
          {'You get some error while loading pair wallets. Try to refresh the page'}
        </div>
      )}
      {records.get && records.get.length === 0 && !loading && (
        <div className="mm-common-table-no-info">Records not found</div>
      )}
      {records.get && records.get.length !== 0 && (
        <div className="pair-page-wallets-table-container scrollable" ref={tableContainerRef}>
          <table className="mm-common-table w-full">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: header.getSize() }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              {virtualRows.map(virtualRow => {
                const row = rows[virtualRow.index];

                return (
                  <tr key={virtualRow.index}>
                    {row ? (
                      row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id} style={{ height: virtualRow.size }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })
                    ) : (
                      <td style={{ height: virtualRow.size }}>-</td>
                    )}
                  </tr>
                );
              })}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export { WalletsTable };
