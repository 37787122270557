export const signMessage = async (message: string): Promise<string | undefined> => {
  try {
    const provider = window.ethereum;

    if (!provider) return undefined;

    const signerAddress = provider.selectedAddress;

    const params = [`0x${Buffer.from(message, 'utf8').toString('hex')}`, signerAddress];

    const signature = await provider.request({
      method: 'personal_sign',
      params: params,
    });

    return signature as string;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
