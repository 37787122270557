import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { Button, Modal, RadioBox } from 'ui';
import { EExchange } from 'types';

import './tradingPairModal.scss';

interface IChoseTradingPairs {
  onClose: () => void;
  openAddPairWindow: () => void;
  openSelectPairDex: () => void;
  typeTradingPair: EExchange;
  setTypeTradingPair: Dispatch<SetStateAction<EExchange>>;
}

const TradingPairModal: React.FC<IChoseTradingPairs> = ({
  onClose,
  openAddPairWindow,
  openSelectPairDex,
  typeTradingPair,
  setTypeTradingPair,
}) => {
  const handleNext = useCallback(() => {
    if (typeTradingPair === EExchange.cex) {
      openAddPairWindow();
    }
    if (typeTradingPair === EExchange.dex) {
      openSelectPairDex();
    }
  }, [typeTradingPair, openAddPairWindow, openSelectPairDex]);

  return (
    <Modal
      title={'New trading pair'}
      onClose={onClose}
      className={'mm-trading-pair-modal'}
      customButtons={<Button onClick={handleNext}>Next</Button>}
    >
      <div className="mm-trading-pair-modal__trading_pair_container">
        <RadioBox
          text={'CEX'}
          checked={typeTradingPair === EExchange.cex}
          onChange={() => setTypeTradingPair(EExchange.cex)}
        />
        <RadioBox
          text={'DEX'}
          checked={typeTradingPair === EExchange.dex}
          onChange={() => setTypeTradingPair(EExchange.dex)}
        />
      </div>
    </Modal>
  );
};

export { TradingPairModal };
