import React, { memo, useContext } from 'react';
import dayjs from 'dayjs';
import { useTypedSelector } from 'store';
import { capitalize } from 'lodash';

import { Button, Modal, ErrorText, RadioBox, Spinner } from 'ui';
import { CreateTaskForm } from 'ui/forms';
import { DateField, DurationField, InputField } from 'fields';
import { useCreateWashAlgorithmTaskModal } from './useCreateNewTaskModal/useCreateWashAlgorithmTaskModal';

import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';
import { dailyVolume } from 'utils/daily';
import { formatFiat, formatToken } from 'utils/formats';
import { ICexWTRegularTask } from 'types/bots';

import './style.scss';

type Props = {
  onClose: () => void;
  mode: 'create' | 'edit';
  task?: ICexWTRegularTask;
};

export const CexWTRegularTaskModal: React.FC<Props> = memo(({ onClose, mode, task }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const { taskLoading } = useContext(CexWashAlgorithmContext);

  const { values, handleSubmit, setFieldValue, formError, edited, errors, touched } =
    useCreateWashAlgorithmTaskModal({
      onClose,
      task,
      pairId: cexPair.id,
      mode,
    });

  const { volume, volumeUsd } = dailyVolume({
    delay_min: values.pause_between_groups_min ?? 0,
    delay_max: values.pause_between_groups_max ?? 0,
    volume_min: values.vol_per_group_min,
    volume_max: values.vol_per_group_max,
    pair: cexPair,
  });

  return (
    <Modal
      title={`${capitalize(mode)} task (regular volume)`}
      edited={edited}
      className="mm-cex-wt-bot-regular-task-modal"
      onClose={onClose}
    >
      <CreateTaskForm handleSubmit={handleSubmit}>
        <div className="form-inner scrollable">
          <div className="form-container">
            <div>
              <DateField
                label="Start time"
                date={values?.start_at ? values.start_at : null}
                setDate={value =>
                  setFieldValue(
                    'start_at',
                    value ? dayjs(value).second(0).millisecond(0).valueOf() : null,
                  )
                }
                errorMessage={errors.start_at && touched.start_at ? errors.start_at : undefined}
              />
              <DateField
                label="End time"
                date={values?.end_at ? values.end_at : null}
                setDate={value =>
                  setFieldValue(
                    'end_at',
                    value ? dayjs(value).second(0).millisecond(0).valueOf() : null,
                  )
                }
              />
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">Group volume</div>
              <div className="inputs-row">
                <InputField
                  label="Minimum"
                  type="decimal-number"
                  value={values.vol_per_group_min}
                  setValue={v => setFieldValue('vol_per_group_min', v ? v : '')}
                  errorMessage={
                    errors.vol_per_group_min && touched.vol_per_group_min
                      ? errors.vol_per_group_min
                      : undefined
                  }
                />
                <InputField
                  label="Maximum"
                  type="decimal-number"
                  value={values.vol_per_group_max}
                  setValue={v => setFieldValue('vol_per_group_max', v ? v : '')}
                  errorMessage={
                    errors.vol_per_group_max && touched.vol_per_group_max
                      ? errors.vol_per_group_max
                      : undefined
                  }
                />
              </div>
            </div>

            <div>
              <div className="text-main-purple font-bold text-sm">Delay between trade groups</div>
              <div className="inputs-row">
                <DurationField
                  value={values.pause_between_groups_min}
                  setValue={v => {
                    setFieldValue('pause_between_groups_min', v);
                  }}
                  label="Minimum"
                  id="pause_between_groups_min"
                  errorMessage={
                    errors.pause_between_groups_min && touched.pause_between_groups_min
                      ? errors.pause_between_groups_min
                      : undefined
                  }
                />
                <DurationField
                  value={values.pause_between_groups_max}
                  setValue={v => {
                    setFieldValue('pause_between_groups_max', v);
                  }}
                  label="Maximum"
                  id="pause_between_groups_max"
                  errorMessage={
                    errors.pause_between_groups_max && touched.pause_between_groups_max
                      ? errors.pause_between_groups_max
                      : undefined
                  }
                />
              </div>
            </div>

            <div>
              <div className="text-main-purple font-bold text-sm">Number of makers</div>
              <div className="inputs-row">
                <InputField
                  label="Minimum"
                  type="decimal-number"
                  value={values.makers_min}
                  setValue={v => setFieldValue('makers_min', v ? v : '')}
                  errorMessage={
                    errors.makers_min && touched.makers_min ? errors.makers_min : undefined
                  }
                />
                <InputField
                  label="Maximum"
                  type="decimal-number"
                  value={values.makers_max}
                  setValue={v => setFieldValue('makers_max', v ? v : '')}
                  errorMessage={
                    errors.makers_max && touched.makers_max ? errors.makers_max : undefined
                  }
                />
              </div>
            </div>

            <div>
              <div className="text-main-purple font-bold text-sm">Number of takers</div>
              <div className="inputs-row">
                <InputField
                  label="Minimum"
                  type="decimal-number"
                  value={values.takers_min}
                  setValue={v => setFieldValue('takers_min', v ? v : '')}
                  errorMessage={
                    errors.takers_min && touched.takers_min ? errors.takers_min : undefined
                  }
                />
                <InputField
                  label="Maximum"
                  type="decimal-number"
                  value={values.takers_max}
                  setValue={v => setFieldValue('takers_max', v ? v : '')}
                  errorMessage={
                    errors.takers_max && touched.takers_max ? errors.takers_max : undefined
                  }
                />
              </div>
            </div>

            <div>
              <div className="text-main-purple font-bold text-sm">Canceling delay</div>
              <div className="inputs-row">
                <DurationField
                  value={values.cancel_delay_min}
                  setValue={v => {
                    setFieldValue('cancel_delay_min', v);
                  }}
                  label="Minimum"
                  id="cancel_delay_min"
                  errorMessage={
                    errors.cancel_delay_min && touched.cancel_delay_min
                      ? errors.cancel_delay_min
                      : undefined
                  }
                />
                <DurationField
                  value={values.cancel_delay_max}
                  setValue={v => {
                    setFieldValue('cancel_delay_max', v);
                  }}
                  label="Maximum"
                  id="cancel_delay_max"
                  errorMessage={
                    errors.cancel_delay_max && touched.cancel_delay_max
                      ? errors.cancel_delay_max
                      : undefined
                  }
                />
              </div>
            </div>

            <div>
              <div className="text-main-purple font-bold text-sm">Pause between orders</div>
              <div className="inputs-row">
                <DurationField
                  value={values.pause_between_orders_min}
                  setValue={v => {
                    setFieldValue('pause_between_orders_min', v);
                  }}
                  label={'Minimum'}
                  id="pause_between_orders_min"
                  errorMessage={
                    errors.pause_between_orders_min && touched.pause_between_orders_min
                      ? errors.pause_between_orders_min
                      : undefined
                  }
                />
                <DurationField
                  value={values.pause_between_orders_max}
                  setValue={v => {
                    setFieldValue('pause_between_orders_max', v);
                  }}
                  label={'Maximum'}
                  id="pause_between_orders_max"
                  errorMessage={
                    errors.pause_between_orders_max && touched.pause_between_orders_max
                      ? errors.pause_between_orders_max
                      : undefined
                  }
                />
              </div>
            </div>

            <div>
              <div className="text-main-purple font-bold mb-3 text-sm">
                Price selection strategy
              </div>
              <div className="flex w-full justify-between">
                <RadioBox
                  checked={values.price_selection_strategy === 'random'}
                  text={'Random'}
                  onChange={() => setFieldValue('price_selection_strategy', 'random')}
                />
                <RadioBox
                  checked={values.price_selection_strategy === 'by_first_side'}
                  text={'By first side'}
                  onChange={() => setFieldValue('price_selection_strategy', 'by_first_side')}
                />
              </div>
              <div className="flex flex-col gap-1">
                <InputField
                  label="Round amount probability %"
                  placeholder="Enter %"
                  type="decimal-number"
                  value={values.rounding_probability}
                  setValue={v => setFieldValue('rounding_probability', v ? v : '')}
                  errorMessage={
                    errors.rounding_probability && touched.rounding_probability
                      ? errors.rounding_probability
                      : undefined
                  }
                />
                <InputField
                  label="Takers != makers amount probability %"
                  placeholder="Enter %"
                  type="decimal-number"
                  value={values.not_equal_amount_probability}
                  setValue={v => setFieldValue('not_equal_amount_probability', v ? v : '')}
                  errorMessage={
                    errors.not_equal_amount_probability && touched.not_equal_amount_probability
                      ? errors.not_equal_amount_probability
                      : undefined
                  }
                />
              </div>
            </div>
            <div>
              <div className="text-main-purple font-bold text-sm">
                Takers != makers amount percent %
              </div>
              <div className="inputs-row">
                <InputField
                  label="Minimum"
                  placeholder="Enter %"
                  type="decimal-number"
                  value={values.not_equal_amount_percent_min}
                  setValue={v => setFieldValue('not_equal_amount_percent_min', v ? v : '')}
                  errorMessage={
                    errors.not_equal_amount_percent_min && touched.not_equal_amount_percent_min
                      ? errors.not_equal_amount_percent_min
                      : undefined
                  }
                />
                <InputField
                  label="Maximum"
                  placeholder="Enter %"
                  type="decimal-number"
                  value={values.not_equal_amount_percent_max}
                  setValue={v => setFieldValue('not_equal_amount_percent_max', v ? v : '')}
                  errorMessage={
                    errors.not_equal_amount_percent_max && touched.not_equal_amount_percent_max
                      ? errors.not_equal_amount_percent_max
                      : undefined
                  }
                />
              </div>
              <InputField
                label="Ignore percent amount %"
                placeholder="Enter %"
                type="decimal-number"
                value={values.ignore_volume_percent}
                setValue={v => setFieldValue('ignore_volume_percent', v ? v : '')}
                errorMessage={
                  errors.ignore_volume_percent && touched.ignore_volume_percent
                    ? errors.ignore_volume_percent
                    : undefined
                }
              />
            </div>
          </div>
          <div className="daily-container">
            <div className="daily-container__daily-volume">
              <span>Daily volume:</span>
              <span>
                {formatToken(volume, 18)} {cexPair.token_base.symbol} /{formatFiat(volumeUsd, 18)}
              </span>
            </div>
            <div className="daily-container__daily-free-costs">
              <span>Daily fee costs: </span>
              <span>??? USDT</span>
            </div>
          </div>
        </div>
        <div className="mm-new-buy-sell-bot-task-actions">
          {formError && <ErrorText>{formError}</ErrorText>}
          {taskLoading.get && (
            <div className="spinner-container">
              <Spinner size="small" />
            </div>
          )}
          {!taskLoading.get && (
            <Button type="submit">{mode === 'create' ? 'Create task' : 'Save'}</Button>
          )}
        </div>
      </CreateTaskForm>
    </Modal>
  );
});
