import React from 'react';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import cn from 'classnames';

import { IBuySellTaskStatus, IBuySellBotTaskItemInner } from 'types/buy-sell-bot';

dayjs.extend(utcPlugin);

type TaskTimeIndicatorProps = {
  status: IBuySellTaskStatus;
};

export const TaskTimeIndicator: React.FC<TaskTimeIndicatorProps> = ({ status }) => {
  return (
    <div
      className={cn('w-2 h-2 rounded-full flex-shrink-0', {
        'bg-light-green-1': status === 'current',
        'bg-main-purple': status === 'future',
        'bg-gray-1': status === 'past',
      })}
    />
  );
};

interface ITimeProps {
  task: IBuySellBotTaskItemInner;
  status: IBuySellTaskStatus;
}

export const Time: React.FC<ITimeProps> = ({ task, status }) => {
  return (
    <div className="time-cell">
      <TaskTimeIndicator status={status} />
      <span>{dayjs(task.task.start_time).format('L LT')}</span>
    </div>
  );
};
