import { memo, FC } from 'react';
import { useTypedSelector } from 'store';

import { Button, Modal, Switcher, ButtonLoading, ErrorText } from 'ui';
import { InputField } from 'fields';
import { EditBuySellBotSettingsForm } from 'ui/forms/EditBuySellBotSettingsForm/EditBuySellBotSettingsForm';
import { useBotSettingsModal } from './useBotSettingsModal/useBotSettingsModal';

import { IDexPair } from 'types/pairs';
import { IAddPairArgs } from 'api/apiPairs/models';
import { IDexBotSettings, EDexBot } from 'types/bots';

import './botSettingsModal.scss';

interface IBotSettingsModalProps {
  onClose: () => void;
  onOpen: () => void;
  buySellBotSettings: IDexBotSettings;
  onSave: () => void;
  bot: EDexBot;
}

export const BotSettingsModal: FC<IBotSettingsModalProps> = memo(
  ({ onClose, buySellBotSettings, onSave, bot }) => {
    const pair = useTypedSelector(store => store.pairs.selectedDexPair);

    const { values, setFieldValue, loading, handleSubmit, formError, edited } = useBotSettingsModal(
      {
        pair: pair as IAddPairArgs & IDexPair,
        botSettings: buySellBotSettings,
        onSave,
        onClose,
        bot,
      },
    );

    return (
      <Modal title="Settings" onClose={onClose} edited={edited}>
        <EditBuySellBotSettingsForm handleSubmit={handleSubmit}>
          <div className="mm-bot-settings-modal">
            <InputField
              label="Slippage (%)"
              type="decimal-number"
              value={values.slipage}
              setValue={v => setFieldValue('slipage', v ? v : '', { triggerEdit: true })}
            />
            <div className="grid grid-flow-col justify-between">
              <span className="text-main-purple font-bold">Private transactions</span>
              <Switcher
                value={values.privateTx}
                onChange={v => setFieldValue('privateTx', v ? v : false, { triggerEdit: true })}
              />
            </div>
            <div className="grid grid-flow-col justify-between">
              <span className="text-main-purple font-bold">Enable bot</span>
              <Switcher
                value={values.enableBot}
                onChange={v => setFieldValue('enableBot', v ? v : false, { triggerEdit: true })}
              />
            </div>
            <div className="mm-bot-settings-modal__actions">
              {formError && <ErrorText>{formError}</ErrorText>}
              {loading ? <ButtonLoading /> : <Button type="submit">Save</Button>}
            </div>
          </div>
        </EditBuySellBotSettingsForm>
      </Modal>
    );
  },
);
