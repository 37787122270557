import React, { Dispatch, SetStateAction, createContext, useState } from 'react';

import { useDexWalletsRecords } from 'hooks/tables';

import { IPairWallet } from 'api/apiPairs/models';

interface IPairWalletsContext {
  records: {
    get: IPairWallet[] | undefined;
    set: Dispatch<SetStateAction<IPairWallet[] | undefined>>;
  };
  virtualRecords: {
    get: IPairWallet[];
    set: Dispatch<SetStateAction<IPairWallet[]>>;
  };
  totalRecords: number;
  selectedRecords: IPairWallet[];
  loading: boolean;
  hasMore: boolean;
  handleRemount: () => void;
  handleLoadMore: () => void;
  rowSelection: {
    get: Record<number, boolean>;
    set: Dispatch<SetStateAction<Record<number, boolean>>>;
  };
  modals: {
    connectWalletsOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    disconnectWalletsOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    depositOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    withdrawOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    whitelistWithdrawOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    manualTxsOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    changeNetworkOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
    withdrawStatusModalOpened: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
  };
}

export const PairWalletsContext = createContext<IPairWalletsContext>({
  records: { get: undefined, set: () => {} },
  virtualRecords: { get: [], set: () => {} },
  totalRecords: 0,
  selectedRecords: [],
  loading: false,
  hasMore: true,
  handleRemount: () => {},
  handleLoadMore: () => [],
  rowSelection: {
    get: {},
    set: () => {},
  },
  modals: {
    connectWalletsOpened: { get: false, set: () => {} },
    disconnectWalletsOpened: { get: false, set: () => {} },
    depositOpened: { get: false, set: () => {} },
    withdrawOpened: { get: false, set: () => {} },
    whitelistWithdrawOpened: { get: false, set: () => {} },
    manualTxsOpened: { get: false, set: () => {} },
    changeNetworkOpened: { get: false, set: () => {} },
    withdrawStatusModalOpened: { get: false, set: () => {} },
  },
});

interface IPairWalletsContextProviderProps {
  children?: React.ReactNode;
}

const PairWalletsContextProvider: React.FC<IPairWalletsContextProviderProps> = ({ children }) => {
  const {
    records,
    virtualRecords,
    totalRecords,
    loading,
    hasMore,
    handleRemount,
    handleLoadMore,
    rowSelection,
    selectedRecords,
  } = useDexWalletsRecords();

  const [connectWalletsOpened, setConnectWalletsOpened] = useState<boolean>(false);
  const [disconnectWalletsOpened, setDisconnectWalletsOpened] = useState<boolean>(false);
  const [depositOpened, setDepositOpened] = useState<boolean>(false);
  const [withdrawOpened, setWithdrawOpened] = useState<boolean>(false);
  const [whitelistWithdrawOpened, setWhitelistWithdrawOpened] = useState<boolean>(false);
  const [manualTxsOpened, setManualTxsOpened] = useState<boolean>(false);
  const [changeNetworkOpened, setChangeNetworkOpened] = useState<boolean>(false);
  const [withdrawStatusModalOpened, setWithdrawStatusModalOpened] = useState<boolean>(false);

  return (
    <PairWalletsContext.Provider
      value={{
        records,
        virtualRecords,
        totalRecords,
        selectedRecords,
        loading,
        hasMore,
        handleRemount,
        handleLoadMore,
        rowSelection,
        modals: {
          connectWalletsOpened: { get: connectWalletsOpened, set: setConnectWalletsOpened },
          disconnectWalletsOpened: {
            get: disconnectWalletsOpened,
            set: setDisconnectWalletsOpened,
          },
          depositOpened: { get: depositOpened, set: setDepositOpened },
          withdrawOpened: { get: withdrawOpened, set: setWithdrawOpened },
          whitelistWithdrawOpened: {
            get: whitelistWithdrawOpened,
            set: setWhitelistWithdrawOpened,
          },
          manualTxsOpened: { get: manualTxsOpened, set: setManualTxsOpened },
          changeNetworkOpened: { get: changeNetworkOpened, set: setChangeNetworkOpened },
          withdrawStatusModalOpened: {
            get: withdrawStatusModalOpened,
            set: setWithdrawStatusModalOpened,
          },
        },
      }}
    >
      {children}
    </PairWalletsContext.Provider>
  );
};

export default PairWalletsContextProvider;
