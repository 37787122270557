import React from 'react';
import { Row } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { IBalanceBotWallet } from 'api/apiBalanceBot/models';
import { formatToken } from 'utils/formats';

interface ITokenBaseCellProps {
  row: Row<IBalanceBotWallet>;
}

const TokenBaseCell: React.FC<ITokenBaseCellProps> = ({ row }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const baseToken = row.original.tokens.find(item => item.token.id === dexPair.token_base.id)!;

  return <div>{formatToken(BigNumber.from(baseToken.balance), baseToken.token.decimals)}</div>;
};

export { TokenBaseCell };
