import React, { useState, useCallback } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react';

import { IWithdrawWhitelistItem } from 'api/apiWithdrawWhitelist/models';
import { addressView } from 'utils';

import { SuccessIcon, PendingIcon } from 'assets/icons';

interface IWalletCellProps {
  wallet: IWithdrawWhitelistItem;
}

const WalletCell: React.FC<IWalletCellProps> = ({ wallet }) => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);

  const handleChangeListOpened = useCallback(
    (opened: boolean) => {
      if (!wallet.confirmations || wallet.confirmations.length === 0) return;

      setIsListOpen(opened);
    },
    [wallet],
  );

  const { x, y, refs, strategy, context } = useFloating({
    open: isListOpen,
    onOpenChange: handleChangeListOpened,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: 'end', padding: 5 }),
      shift({ padding: 5 }),
    ],
    whileElementsMounted: autoUpdate,
    placement: 'right',
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <div className="wallet-cell">
      <span>{addressView(wallet.wallet_address) ?? ''}</span>
      <div className="wallet-icon-container" ref={refs.setReference} {...getReferenceProps()}>
        {wallet.confirmed_at ? <SuccessIcon /> : <PendingIcon />}
        {isListOpen && (
          <FloatingPortal>
            <div
              className="withdrawal-whitelist-confirmations-list"
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              {...getFloatingProps()}
            >
              {wallet.confirmations.map(el => (
                <div className="confirmation-person" key={el.id}>
                  <span>{el.user.login}</span>
                  <SuccessIcon />
                </div>
              ))}
            </div>
          </FloatingPortal>
        )}
      </div>
    </div>
  );
};

export { WalletCell };
