import React, { useCallback } from 'react';
import cn from 'classnames';

import { EChartFormat } from 'types/charts';

import './chartFormat.scss';

interface IChartFormatProps {
  className?: string;
  supportedFormats: EChartFormat[];
  selectedFormat: EChartFormat;
  setSelectedFormat: (v: EChartFormat) => void;
  disabled?: boolean;
}

const ChartFormat: React.FC<IChartFormatProps> = ({
  className,
  supportedFormats,
  selectedFormat,
  setSelectedFormat,
  disabled = false,
}) => {
  const priceScaleModeToCaption = useCallback((priceMode: EChartFormat) => {
    if (priceMode === EChartFormat.Normal) {
      return 'Absolute';
    }
    if (priceMode === EChartFormat.Percentage) {
      return 'Percentage';
    }

    return undefined;
  }, []);

  return (
    <div className={cn({ [className ?? '']: !!className }, 'mm-chart-formats')}>
      {supportedFormats.map(el => {
        const caption = priceScaleModeToCaption(el);

        const isSelected = el === selectedFormat;
        const isDisabled = disabled;

        if (!caption) return null;

        return (
          <div
            key={caption}
            className={cn('chart-format-item', {
              '_is-selected': isSelected,
              '_is-disabled': isDisabled,
            })}
            onClick={() => !isDisabled && setSelectedFormat(el)}
          >
            {caption}
          </div>
        );
      })}
    </div>
  );
};

export default ChartFormat;
