import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { ApiUsers } from 'api';

import { IAuthUserMe } from 'api/apiUsers/models';
import { IUserMe } from './models';
import { Network } from 'constants/common';
import { CORS_ERROR_CODE } from 'constants/numbers';
import { ENetwork } from 'types';
import { setAlertState, dropAlertState } from '../ui';
import { Button } from 'ui';

type IGasPrices = Record<ENetwork, { safeGasPrice: string }>;

interface IUserSliceState {
  user: IUserMe | null;
  isAdmin: boolean;
  isUser: boolean;
  gasPrices: IGasPrices;
}

const defaultGasPrices: IGasPrices = {
  bsc: {
    safeGasPrice: '0',
  },
  eth: {
    safeGasPrice: '0',
  },
  polygon: {
    safeGasPrice: '0',
  },
  arbitrum_one: {
    safeGasPrice: '0',
  },
};

const userSliceInitialState: IUserSliceState = {
  user: null,
  isAdmin: false,
  isUser: false,
  gasPrices: defaultGasPrices,
};

export const fetchGasPrice = createAsyncThunk<any, any, any>(
  'user/get-gas-price',
  async (_, thunkApi) => {
    try {
      const networks = [
        Network['bsc'],
        Network['eth'],
        Network['polygon'],
        Network['arbitrum_one'],
      ];

      const results = await Promise.all(
        networks.map(network => {
          return (async () => {
            try {
              const result = await axios.get(network.gastrackerUrl);

              return { SafeGasPrice: result.data?.result?.SafeGasPrice ?? '0' };
            } catch (error) {
              return { SafeGasPrice: '0' };
            }
          })();
        }),
      );
      const gasPrices = networks.reduce(
        (acc, network, index) => ({
          ...acc,
          [network.network]: { safeGasPrice: results[index].SafeGasPrice ?? '0' },
        }),
        {} as IGasPrices,
      );

      return thunkApi.fulfillWithValue({ gasPrices });
    } catch (error) {
      console.log(error);
      return thunkApi.fulfillWithValue({ gasPrices: defaultGasPrices });
    }
  },
);

export const getMe = createAsyncThunk<any, any, any>('user/get-me', async (_, thunkApi) => {
  try {
    const { isSuccess, errorMessage, data, errorCode } = await ApiUsers.getMe();

    if (isSuccess) {
      return thunkApi.fulfillWithValue({ ...data });
    } else {
      if (errorCode === CORS_ERROR_CODE) {
        thunkApi.dispatch(
          setAlertState({
            type: 'failed',
            title: 'Unavaliable',
            text: 'Service is temporary unavailable',
            buttons: (
              <Button color="error" onClick={() => window.location.reload()}>
                Retry
              </Button>
            ),
            onSubmit: () => thunkApi.dispatch(dropAlertState()),
          }),
        );
      }

      return thunkApi.rejectWithValue(errorMessage);
    }
  } catch (error) {
    console.log(error);
  }
});

const authSlice = createSlice({
  name: 'user',
  initialState: userSliceInitialState as IUserSliceState,
  extraReducers: builder => {
    builder.addCase(getMe.fulfilled, (state, { payload }: { payload: IAuthUserMe }) => {
      state.user = payload;
      state.isAdmin = payload.role === 'admin';
      state.isUser = payload.role === 'user';
    });
    builder.addCase(getMe.rejected, (state, { payload }) => {
      //TODO
    });
    builder.addCase(
      fetchGasPrice.fulfilled,
      (
        state,
        {
          payload,
        }: {
          payload: {
            gasPrices: IGasPrices;
          };
        },
      ) => {
        state.gasPrices = payload.gasPrices;
      },
    );
  },
  reducers: {},
});

export default authSlice.reducer;
