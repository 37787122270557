import React, { useCallback, useContext, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import { useTypedDispatch, useTypedSelector } from 'store';

import { Fee, FinishIn, Progress, ReverseSwap, Status, TotalAmount, StartTime } from './columns';
import { HolderBalanceRowInfo } from '../RowInfo/HolderBalanceRowInfo';

import { PairBalanceBotContext } from 'context/PairBalanceBotContext';
import { IDexBalanceBotTask } from 'types/bots';
import { addBalanceTask } from 'modals/AddHolderModal/useAddHolderModal/addBalanceTask';
import { normalizeBigNumber } from 'utils/formulas';
import { durationToMs } from 'utils/duration';

import './style.scss';

const TableComponent: React.FC = () => {
  const dispatch = useTypedDispatch();
  const pair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const baseToken = pair?.token_base.symbol;
  const feeToken = pair?.token_fee.symbol;

  const {
    records: allRecords,
    handleLoadRecords,
    extendedRow,
    setExtendedRow,
    taskEnablingLoading,
  } = useContext(PairBalanceBotContext);

  const records = useMemo(
    () => allRecords.get?.filter(record => record.type === 'add_holder'),
    [allRecords.get],
  );

  const handleEnableTask = useCallback(
    async (task: IDexBalanceBotTask, is_enabled: boolean) => {
      taskEnablingLoading.set(task.id);

      addBalanceTask({
        task: {
          ...task.add_holder_options,
          min_amount: String(
            normalizeBigNumber(task.add_holder_options?.min_amount || 0, 18, 6, false),
          ),
          max_amount: String(
            normalizeBigNumber(task.add_holder_options?.max_amount || 0, 18, 6, false),
          ),
          min_pause: durationToMs(task.add_holder_options?.min_pause),
          max_pause: durationToMs(task.add_holder_options?.max_pause),
          enabled: is_enabled,
          buyback_min_pause: durationToMs(task.add_holder_options?.buyback_options?.min_pause),
          buyback_max_pause: durationToMs(task.add_holder_options?.buyback_options?.max_pause),
          buyback_min_swaps: String(task.add_holder_options?.buyback_options?.min_swaps),
          buyback_max_swaps: String(task.add_holder_options?.buyback_options?.max_swaps),
          dispatch,
          handleLoadRecords,
          pairId: pair.id,
          taskId: task.id,
          mode: 'edit',
          typeTask: 'add_holder',
        },
        onSuccess: () => {
          if (allRecords.get) {
            const newRecords = [...allRecords.get];

            const indexOfRecord = newRecords.findIndex(el => el.created_at === task.created_at);

            if (indexOfRecord !== -1 && !!newRecords[indexOfRecord].add_holder_options) {
              newRecords[indexOfRecord]!.add_holder_options!.enabled = is_enabled;
            }

            allRecords.set(newRecords);
          }
        },
      }).finally(() => {
        taskEnablingLoading.set(undefined);
      });
    },

    //eslint-disable-next-line
    [pair.id, dispatch, handleLoadRecords, allRecords.get],
  );
  const columns = useMemo<ColumnDef<IDexBalanceBotTask>[]>(
    () => [
      {
        header: () => <div>Created at</div>,
        id: 'start-time',
        cell: ({ row: { original } }) => <StartTime original={original} />,
      },
      {
        header: () => <div>Total amount</div>,
        id: 'holder-total-amount',
        cell: ({ row: { original } }) => <TotalAmount original={original} baseToken={baseToken} />,
      },
      {
        header: () => <div>Progress:</div>,
        id: 'holder-progress',
        cell: ({ row: { original } }) => (
          <Progress original={original} pair={pair} baseToken={baseToken} />
        ),
      },
      {
        header: () => <div>Reverse swap:</div>,
        id: 'holder-reverse-swap',
        cell: ({ row: { original } }) => <ReverseSwap original={original} />,
      },
      {
        header: () => <div>Fee</div>,
        id: 'holder-fee',
        cell: ({ row: { original } }) => (
          <Fee original={original} pair={pair} feeToken={feeToken} />
        ),
      },
      {
        header: () => <div>Finish in:</div>,
        id: 'finish-in',
        cell: ({ row: { original } }) => <FinishIn original={original} />,
      },
      {
        header: () => <div>Status</div>,
        id: 'holder-status',
        accessorFn: () => {},
        cell: ({
          table: {
            options: { meta },
          },
          row,
        }) => {
          return <Status meta={meta} row={row} handleEnableTask={handleEnableTask} />;
        },
      },
    ],
    [pair, handleEnableTask, baseToken, feeToken],
  );

  const table = useReactTable({
    data: records ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      extendedRow,
      taskEnablingLoading: taskEnablingLoading.get,
    },
  });

  const { rows } = table.getRowModel();

  return (
    <table className={cn('mm-static-table', 'mm-wash-algorithm-table')}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map(row => {
          return (
            <React.Fragment key={row.index}>
              <tr
                key={row.index}
                className={cn({
                  _selected: extendedRow === row.index,
                  'task-finished': !row.original.is_active,
                })}
                onClick={() => setExtendedRow(row.index)}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
              <AnimatePresence>
                {extendedRow === row.index && (
                  <tr
                    className={cn('mm-wash-algorithm-opened-task', {
                      'task-finished': !row.original.is_active,
                    })}
                  >
                    <td colSpan={9}>
                      {<HolderBalanceRowInfo task={row.original} rowIdx={row.index} />}
                    </td>
                  </tr>
                )}
              </AnimatePresence>
              <tr className="tr-separator" />
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
const HolderBalanceTable = React.memo(TableComponent);
export { HolderBalanceTable };
