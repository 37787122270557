import React from 'react';
import { useTypedSelector } from 'store';

const TokenQuoteHead: React.FC = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  return <div style={{ textAlign: 'left' }}>{dexPair.token_quote.symbol}</div>;
};

export { TokenQuoteHead };
