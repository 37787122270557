import { HTMLAttributes, useState, useRef, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  offset,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';

import { ArrowDropDown } from 'assets/icons';
import './style.scss';

interface IFilterSelectFieldProps<T> extends HTMLAttributes<HTMLDivElement> {
  items: T[];
  selected: T | undefined;
  onSelectItem: (item: T) => void;
  itemToString: (item: T) => string;
  placeholder: string;
}

function FilterSelectField<T>({
  items,
  selected,
  onSelectItem,
  itemToString,
  placeholder,
  ...rest
}: IFilterSelectFieldProps<T>) {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpened,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    dismiss,
    role,
    click,
  ]);

  const handleSelect = useCallback(
    (item: T) => {
      onSelectItem(item);
      setIsOpened(false);
    },
    [onSelectItem],
  );

  return (
    <div className="mm-filter-select-field" {...rest}>
      <div
        className="mm-filter-field-container"
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
      >
        {!selected && <span className="mm-filter-field-placeholder">{placeholder}</span>}
        {selected && <span className="mm-filter-field-value">{itemToString(selected)}</span>}
        <motion.div animate={isOpened ? { rotate: 180 } : undefined}>
          <ArrowDropDown color={'rgb(42, 46, 52)'} />
        </motion.div>
      </div>
      <AnimatePresence>
        {isOpened && (
          <FloatingPortal>
            <FloatingFocusManager context={context} modal={false}>
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="mm-filter-select-field__dropdown scrollable"
                {...getFloatingProps()}
              >
                {items.map((item, i) => (
                  <div
                    className="dropdown__item"
                    key={i}
                    ref={node => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={-1}
                    {...getItemProps({
                      onClick() {
                        handleSelect(item);
                      },
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(item);
                        }

                        if (event.key === ' ') {
                          event.preventDefault();
                          handleSelect(item);
                        }
                      },
                    })}
                  >
                    <span>{itemToString(item)}</span>
                  </div>
                ))}
              </div>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </div>
  );
}

export { FilterSelectField };
