import React, { ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import cn from 'classnames';

import './tooltip.scss';

interface ToolTipProps {
  children: ReactNode;
  id: string;
  item: string;
  tooltipEvent: 'hover' | 'click';
  cexTable?: boolean;
}

const Tooltip: React.FC<ToolTipProps> = ({ children, item, tooltipEvent, id, cexTable }) => {
  return (
    <div className={'tool-tip-wrapper'}>
      <div
        className={cn('indicator', cexTable && 'transparent-tooltip-indicator')}
        data-tooltip-id={`mm-table-token-tooltip-${id}`}
        data-tooltip-html={`<div style="display: flex; flex-wrap: wrap; align-items: center; text-align: center; max-width: 200px; color: white">${item}</div>`}
      >
        {children}
      </div>
      <ReactTooltip
        id={`mm-table-token-tooltip-${id}`}
        openOnClick={tooltipEvent === 'hover' ? false : true}
        className="mm-table-token-tooltip"
      />
    </div>
  );
};

export { Tooltip };
