import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { ApiAuth } from 'api';
import { LocalStorage } from 'tools';

interface IAuthSliceState {
  jwt: null | string;
  authLoading: boolean;
  authError: null | string;
}

const initialJWTFromCookies = LocalStorage.get<string>('jwt');

const authSliceInitialState: IAuthSliceState = {
  jwt: initialJWTFromCookies ? initialJWTFromCookies : null,
  authLoading: false,
  authError: null,
};

const onLogin = createAsyncThunk<any, { login: string; password: string }, any>(
  'auth/login',
  async ({ login, password }, thunkApi) => {
    try {
      const { isSuccess, data, errorMessage } = await ApiAuth.login({ login, password });

      if (isSuccess) {
        return thunkApi.fulfillWithValue({ jwt: data.jwt });
      } else {
        return thunkApi.rejectWithValue(errorMessage as string);
      }
    } catch (error) {
      console.log(error);
    }
  }
);

const onLogout = createAsyncThunk<any, any, any>('auth/logout', async (_, thunkApi) => {
  try {
    LocalStorage.remove('jwt');
    LocalStorage.remove('user');
    return thunkApi.fulfillWithValue('success');
  } catch (error) {
    console.log(error);
    return thunkApi.rejectWithValue('error');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: authSliceInitialState as IAuthSliceState,
  extraReducers: (builder) => {
    // login
    builder.addCase(onLogin.pending, (state) => {
      state.authLoading = true;
    });
    builder.addCase(onLogin.rejected, (state, action) => {
      state.authError = action.payload as string;
      state.authLoading = false;
    });
    builder.addCase(onLogin.fulfilled, (state, { payload }: { payload: { jwt: string } }) => {
      state.jwt = payload.jwt;
      state.authLoading = false;
      state.authError = null;

      LocalStorage.set('jwt', payload.jwt);
    });

    //logout
    builder.addCase(onLogout.rejected, (state) => {
      state.jwt = null;
      state.authError = null;
      LocalStorage.remove('jwt');
      LocalStorage.remove('user');
    });
    builder.addCase(onLogout.fulfilled, (state) => {
      state.jwt = null;
      state.authError = null;
      LocalStorage.remove('jwt');
      LocalStorage.remove('user');
    });
  },
  reducers: {
    setAuthError: (state, { payload }: { payload: string | null }) => {
      state.authError = payload;
    },
  },
});

export { onLogin, onLogout };
export const { setAuthError } = authSlice.actions;

export default authSlice.reducer;
