import React, { useCallback, useContext } from 'react';

import { Button, ButtonLoading } from 'ui';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';

import './reseedButton.scss';

const ReseedButton: React.FC = () => {
  const { chartLoading, currentConfig, triggerLoadChart } = useContext(PairWashTradingContext);

  const handleSetSeedToRandom = useCallback(() => {
    if (currentConfig.get) {
      currentConfig.set({ ...currentConfig.get, seed: -1 });
      triggerLoadChart.set(v => !v);
    }
  }, [currentConfig]);

  return (
    <>
      {!!chartLoading.get && <ButtonLoading className="mm-wt-bot-panel__reseed-button" />}
      {!chartLoading.get && (
        <Button className="mm-wt-bot-panel__reseed-button" onClick={handleSetSeedToRandom}>
          Re seed
        </Button>
      )}
    </>
  );
};

export default ReseedButton;
