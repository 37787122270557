import React, { useMemo } from 'react';
import cn from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { v4 as uuid } from 'uuid';

import { ITransfer } from 'types/transfers';

import { CircleWarningIcon } from 'assets/icons';

interface IStatusProps {
  transfer: ITransfer;
}

const Status = React.memo(({ transfer }: IStatusProps) => {
  const isSuccess = useMemo(() => !!transfer.hash, [transfer]);

  const id = useMemo(() => uuid(), []);

  return (
    <div className="status">
      <span className={cn({ 'success-status': isSuccess, 'error-status': !isSuccess })}>
        {isSuccess ? 'Success' : 'Error'}
      </span>
      {!isSuccess && (
        <>
          <ReactTooltip
            place="top"
            id={`${id}-status-tooltip`}
            openOnClick={false}
            className="status__tooltip"
          >
            {transfer.error}
          </ReactTooltip>
          <div data-tooltip-id={`${id}-status-tooltip`} className="status-icon-holder">
            <CircleWarningIcon />
          </div>
        </>
      )}
    </div>
  );
});

export { Status };
