import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import { ITransfer } from 'types/transfers';
import { trimDecimalZeroes } from 'utils/formats';

interface IAmountProps {
  transfer: ITransfer;
}

const Amount = React.memo(({ transfer }: IAmountProps) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const token = useMemo(() => {
    const findToken = [dexPair.token_base, dexPair.token_quote, dexPair.token_fee].find(
      el => el.id === transfer.token_id,
    );

    return findToken;
  }, [dexPair, transfer]);

  const decimals = useMemo(() => (token ? token.decimals : 18), [token]);

  const amountString = useMemo(
    () => trimDecimalZeroes(formatUnits(BigNumber.from(transfer.amount), decimals)),
    [transfer, decimals],
  );

  return <div>{amountString}</div>;
});

export { Amount };
