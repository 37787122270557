import { parseEther } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import store from 'store';

import { IDexPair } from 'types/pairs';
import { Network } from 'constants/common';
import { DEXES_FEES } from 'api/apiDictionary/models/IDex';
import { addBignumbers, multiplyBignumbers } from 'utils/formulas';
import { calculateSwapsGasPrice } from 'utils/calculates';

interface DexDailyFeesValues {
  daily_volume: BigNumber;
  daily_txs: number;
  dexPair: IDexPair;
}

export const dexDailyFees = ({ daily_txs, daily_volume, dexPair }: DexDailyFeesValues) => {
  const { unit } = Network[dexPair.network];
  const gasPriceInUnit = store.getState().user.gasPrices[dexPair.network].safeGasPrice;

  const pairFeePercentage = (DEXES_FEES[dexPair.dex].fee / 100).toString();

  const baseAmount = multiplyBignumbers(
    [daily_volume, dexPair.token_base.decimals],
    [parseEther(pairFeePercentage), 18],
  );
  const baseUsd = multiplyBignumbers(
    [baseAmount, 18],
    [BigNumber.from(dexPair.token_base.price_usd), 6],
  );

  const feeAmount = calculateSwapsGasPrice({
    swap_gas_limit: dexPair.swap_gas_limit,
    gas_unit: unit,
    gasPriceInUnit: gasPriceInUnit,
    swaps_count: daily_txs,
  });
  const feeUsd = multiplyBignumbers(
    [feeAmount, 18],
    [BigNumber.from(dexPair.token_fee.price_usd), 6],
  );

  const totalUsd = addBignumbers([baseUsd, 18], [feeUsd, 18]);

  return {
    base: {
      amount: baseAmount,
      usd: baseUsd,
    },
    fee: {
      amount: feeAmount,
      usd: feeUsd,
    },
    total: {
      usd: totalUsd,
    },
  };
};
