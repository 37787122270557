import React, { useContext } from 'react';
import cn from 'classnames';

import { ICexWTRegularTask } from 'types/bots';
import { Switcher, Spinner } from 'ui';
import { CexWashAlgorithmContext } from 'context/CexWashAlgorithmContext';

import { OpenIcon } from 'assets/icons/OpenIcon';

interface IStatusProps {
  task: ICexWTRegularTask;
  extendedRow?: number | undefined;
  rowIndex: number;
  onEnableTask: (task: ICexWTRegularTask, status: boolean) => void;
}

export const Status: React.FC<IStatusProps> = ({ task, rowIndex, extendedRow, onEnableTask }) => {
  const { regularEnableLoading } = useContext(CexWashAlgorithmContext);

  return (
    <div className={cn('switcher-cell', task.enabled && 'disabled-row-cell')}>
      <div onClick={e => e.stopPropagation()}>
        {regularEnableLoading.get === task.start_at ? (
          <Spinner size="mini" />
        ) : (
          <Switcher onChange={v => onEnableTask(task, v)} value={task.enabled} />
        )}
      </div>
      <OpenIcon opened={extendedRow !== undefined && extendedRow === rowIndex} />
    </div>
  );
};
