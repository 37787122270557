import React, { useContext, useMemo } from 'react';
import cn from 'classnames';

import { ChartFormat, ChartPriceFormat } from 'ui';
import { EChartFormat } from 'types/charts';
import { PairChartsContext } from 'context/PairChartsContext/PairChartsContext';
import { CHARTS_COLORS } from 'constants/charts';

import { ChartIcon } from 'assets/icons';

import './style.scss';

const ChartFooter: React.FC = () => {
  const { selectedChartFormat, priceFormat, loading, balancesShow } = useContext(PairChartsContext);

  const balancesCharts = useMemo(() => {
    let _balances = Object.keys(balancesShow.get).map(key => ({
      label: key,
      isShow: balancesShow.get[key],
      onClick: () => {
        balancesShow.set({ ...balancesShow.get, [key]: !balancesShow.get[key] });
      },
    }));

    if (priceFormat.get !== 'usd') {
      _balances = _balances.filter(el => el.label !== 'Total');
    }

    return _balances;
  }, [balancesShow, priceFormat.get]);

  return (
    <div className="mm-pair-chart-footer">
      <div className="footer-left">
        {balancesCharts.map((el, idx) => (
          <div
            className={cn('balance-cell', { inactive: !el.isShow })}
            key={el.label}
            onClick={el.onClick}
          >
            <ChartIcon color={!el.isShow ? '#aaa' : CHARTS_COLORS[idx]} />
            <span>{el.label}</span>
          </div>
        ))}
      </div>
      <div className="footer-right">
        <ChartPriceFormat
          supportedPriceFormats={['token', 'usd']}
          selectedPriceFormat={priceFormat.get}
          setSelectedPriceFormat={priceFormat.set}
          disabled={!!loading.get}
        />
        <ChartFormat
          supportedFormats={[EChartFormat.Normal, EChartFormat.Percentage]}
          selectedFormat={selectedChartFormat.get}
          setSelectedFormat={selectedChartFormat.set}
          disabled={!!loading.get}
        />
      </div>
    </div>
  );
};

export { ChartFooter };
