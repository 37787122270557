import React from 'react';
import cn from 'classnames';

import './style.scss';

interface IStatisticTable extends React.HTMLAttributes<HTMLTableElement> {
  children?: React.ReactNode;
}

const StatisticTable: React.FC<IStatisticTable> = ({ children, ...props }) => {
  return (
    <div className="mm-statistic-table-container scrollable">
      <table
        {...props}
        className={cn('mm-statistic-table', { [props.className ?? '']: !!props.className })}
      >
        {children}
      </table>
    </div>
  );
};

export default StatisticTable;
