import React from 'react';
import { Row } from '@tanstack/react-table';
import { BigNumber } from '@ethersproject/bignumber';

import { IPairWallet } from 'api/apiPairs/models';
import { addBignumbers } from 'utils/formulas';
import { formatFiat } from 'utils/formats';

interface IWalletAddressCell {
  row: Row<IPairWallet>;
}

const BalanceCell: React.FC<IWalletAddressCell> = ({ row }) => {
  const totalBalanceUsd = row.original.tokens
    ? row.original.tokens.reduce(
        (acc, val) =>
          addBignumbers(
            [acc, val.token.decimals],
            [BigNumber.from(val.balance_usd), val.token.decimals],
          ),
        BigNumber.from('0'),
      )
    : BigNumber.from('0');

  return <div>{formatFiat(totalBalanceUsd, 18, '$')}</div>;
};

export default BalanceCell;
