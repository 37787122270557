import { getAddress } from '@ethersproject/address';

const isAddress = (value: any): boolean => {
  if (!value || value.length !== 42 || value === '' || value === '0x') {
    return false;
  }

  try {
    const address = getAddress(value).toLowerCase();
    if (address.length !== 42) return false;

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export { isAddress };
