import React, { useContext, useMemo } from 'react';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { IDextWTOrganicVolumeTask } from 'types/bots';
import { PairWashTradingContext } from 'context/PairWashTradingBotContext';
import { ATR_PERIOD } from 'constants/numbers';
import { durationToMs } from 'utils/duration';
import { normalizeNumber, formatToken, formatFiat } from 'utils/formats';
import { calculateATRs } from 'utils/calculates';
import { multiplyBignumbers } from 'utils/formulas';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';

interface IDailyVolumeCellProps {
  task: IDextWTOrganicVolumeTask;
}

const DailyVolumeCell: React.FC<IDailyVolumeCellProps> = ({ task }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { organicCandles } = useContext(PairWashTradingContext);

  const timeframe =
    WT_TIMEFRAMES.find(el => durationToMs(el.value) === durationToMs(task.options.time_frame))
      ?.value ?? task.options.time_frame;

  const sequence = useMemo(() => organicCandles[timeframe] ?? [], [timeframe, organicCandles]);

  const ATRS = useMemo(() => calculateATRs({ candles: sequence, period: ATR_PERIOD }), [sequence]);

  const dailyVolume = useMemo(() => {
    const maxVolume = formatUnits(task.options.max_volume, dexPair.token_base.decimals);
    const minVolume = formatUnits(task.options.min_volume, dexPair.token_base.decimals);

    const avgVolume = (Number(maxVolume) + Number(minVolume)) / 2;

    const dailyVolumeNumber =
      ATRS.reduce((acc, val) => acc + val * avgVolume, 0) *
      ((24 * 60 * 60 * 1000) / (durationToMs(timeframe) * Math.abs(sequence.length - ATR_PERIOD)));

    const normalizedNumber = normalizeNumber(dailyVolumeNumber);

    return parseUnits(normalizedNumber, dexPair.token_base.decimals);
  }, [task, ATRS, sequence.length, dexPair, timeframe]);

  const dailyVolumeUsd = useMemo(() => {
    return multiplyBignumbers(
      [dailyVolume, dexPair.token_base.decimals],
      [BigNumber.from(dexPair.token_base.price_usd), 6],
    );
  }, [dailyVolume, dexPair]);

  return (
    <div>
      {formatToken(dailyVolume, dexPair.token_base.decimals)} {dexPair.token_base.symbol} /{' '}
      {formatFiat(dailyVolumeUsd, 18)}
    </div>
  );
};

export { DailyVolumeCell };
