import React from 'react';

import { IDextWTOrganicVolumeTask } from 'types/bots';

interface ITimeframeCellProps {
  task: IDextWTOrganicVolumeTask;
}

const TimeframeCell: React.FC<ITimeframeCellProps> = ({ task }) => {
  return <div>{task.options.time_frame}</div>;
};

export { TimeframeCell };
