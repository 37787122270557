import { mapResponse } from '../apiBase/mapResponse';
import { mapError } from '../apiBase/mapError';

import { AApiBase, IBaseResponse, Response } from '../apiBase';
import { IDexBuySellBotTaskItem } from 'types/bots';

interface IBuySellStatisticsResponse {
  items: IDexBuySellBotTaskItem[] | undefined | null;
  total_items: number;
}

class ApiBuySellBot extends AApiBase {
  protected baseUrl = '/api';
  protected withHeader = true;

  private static __instance: ApiBuySellBot | undefined;
  private static instance = (() => {
    if (ApiBuySellBot.__instance === undefined) {
      ApiBuySellBot.__instance = new ApiBuySellBot();
    }
    return ApiBuySellBot.__instance;
  })();

  public static buySellStatistics = async ({
    pairId,
  }: {
    pairId: number;
  }): Promise<Response<IBuySellStatisticsResponse>> => {
    let mappedResponseOrError: Response<IBuySellStatisticsResponse>;

    try {
      const response = await ApiBuySellBot.instance.get<IBaseResponse<IBuySellStatisticsResponse>>(
        `/pairs/${pairId}/buysell-statistics`,
      );

      mappedResponseOrError = mapResponse({
        data: response.data,
      });
    } catch (error) {
      console.log(error);
      mappedResponseOrError = mapError<IBuySellStatisticsResponse>({ error });
    }

    return mappedResponseOrError;
  };
}

export { ApiBuySellBot };
