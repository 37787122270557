import React, { useMemo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTypedDispatch } from 'store';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import { ICexOrder, ECexOrderStatus } from 'types/orders';
import { copyToClipboard } from 'utils/clipboard';
import { setCopyPopupShow } from 'store/slices/ui';

const statusString = (status: ECexOrderStatus) =>
  ({
    [ECexOrderStatus.active]: 'Active',
    [ECexOrderStatus.canceled]: 'Canceled',
    [ECexOrderStatus.error]: 'Error',
    [ECexOrderStatus.filled]: 'Filled',
    [ECexOrderStatus.partially_filled]: 'Partial',
  }[status]);

interface IOrderIdProps {
  order: ICexOrder;
}

const OrderId = React.memo(({ order }: IOrderIdProps) => {
  const orderId = useMemo(() => order.cex_order_id, [order]);
  const status = useMemo(() => order.status, [order]);

  const tooltipId = useMemo(() => orderId ?? uuid(), [orderId]);

  const dispatch = useTypedDispatch();

  const handleCopyOrderId = async () => {
    try {
      await copyToClipboard(orderId);
      dispatch(setCopyPopupShow(true));
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1px' }}>
        <ReactTooltip
          place="top"
          id={`${tooltipId}-status-tooltip`}
          openOnClick={false}
          className="status__tooltip"
        >
          {statusString(status)}
        </ReactTooltip>
        <div data-tooltip-id={`${tooltipId}-status-tooltip`} className="status-indicator">
          <div className={cn('indicator-inner', `_${status}`)} />
        </div>
        <div
          style={{
            textAlign: 'left',
            textOverflow: 'ellipsis',
            minWidth: 0,
            overflow: 'hidden',
            direction: 'rtl',
          }}
        >
          <span className="order-id-text" onClick={handleCopyOrderId}>
            {orderId}
          </span>
        </div>
      </div>
    </>
  );
});

OrderId.displayName = 'OrderId';

export { OrderId };
