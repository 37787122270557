import { useCallback } from 'react';
import { isNil } from 'lodash';
import { useTypedSelector } from 'store';

import { ApiBalanceBot } from 'api';
import { filtersToValues } from 'utils/filters';
import { IDexFilterValue } from 'types/filters/common';
import { dexBoostHoldersWalletsFiltersScheme } from 'types/filters/dex_pair';

const useDexBoostHoldersWallets = () => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const getter = useCallback(
    async ({
      limit,
      lastSeenId,
      filters,
      wallets,
      task_id,
    }: {
      limit: number;
      lastSeenId: number;
      filters: IDexFilterValue[];
      wallets: 'source' | 'destination';
      task_id: number;
    }) => {
      const filterValuesFlat = filtersToValues({
        filters: filters,
        scheme: dexBoostHoldersWalletsFiltersScheme(dexPair),
      });

      const filterValues = filterValuesFlat.reduce((acc, val) => ({ ...acc, ...val }), {});

      const params = {
        balance_bot_id: task_id,
        lastSeenId,
        limit,
        not_connected: isNil(filterValues.not_connected)
          ? 'false'
          : `${!(filterValues.not_connected === 'true')}`,
      };

      const { isSuccess, errorMessage, data } = await (wallets === 'source'
        ? ApiBalanceBot.getSourceBalanceBotTasksById(params)
        : ApiBalanceBot.getDestinationWalletsTasksById(params));

      return { isSuccess, errorMessage, data };
    },
    [dexPair],
  );

  return getter;
};

export { useDexBoostHoldersWallets };
