import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { BigNumber } from '@ethersproject/bignumber';
import { motion } from 'framer-motion';

import { ICexOrderTransfer } from 'types/orders';
import { useTypedSelector } from 'store';
import { formatFiat, formatToken } from 'utils/formats';
import { divideBignumbers } from 'utils/formulas';

interface IExtendedTrProps {
  transfer: ICexOrderTransfer;
}

const ExtendedTr: React.FC<IExtendedTrProps> = ({ transfer }) => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const decimals = useMemo(() => 18, []);

  const timeString = useMemo(() => {
    return dayjs(transfer.created_at).format('LTS L');
  }, [transfer]);

  const baseAmount = useMemo(() => {
    const baseTrades = transfer.tokens.filter(el => el.token.symbol === cexPair.token_base.symbol);

    return baseTrades.reduce(
      (acc, val) => acc.add(BigNumber.from(val.amount).abs()),
      BigNumber.from(0),
    );
  }, [transfer, cexPair]);

  const quoteAmount = useMemo(() => {
    const quoteTrades = transfer.tokens.filter(
      el => el.token.symbol === cexPair.token_quote.symbol,
    );

    return quoteTrades.reduce(
      (acc, val) => acc.add(BigNumber.from(val.amount).abs()),
      BigNumber.from(0),
    );
  }, [transfer, cexPair]);

  const feesString = useMemo(() => {
    const feeTrades = transfer.tokens.filter(el => !!el.is_fee);

    if (feeTrades.length === 0) return '0';

    return feeTrades
      .map(val => `${formatToken(BigNumber.from(val.amount).abs(), decimals)} ${val.token.symbol}`)
      .join(' + ');
  }, [transfer, decimals]);

  const price = useMemo(() => {
    if (baseAmount.eq(0)) return BigNumber.from(0);
    return divideBignumbers([quoteAmount, decimals], [baseAmount, decimals]);
  }, [baseAmount, quoteAmount, decimals]);

  const priceUsd = useMemo(() => {
    const baseTrade = transfer.tokens.filter(
      el => el.token.symbol === cexPair.token_base.symbol,
    )[0];

    if (!baseTrade) return BigNumber.from(0);

    return divideBignumbers(
      [BigNumber.from(baseTrade.amount_usd), 6],
      [BigNumber.from(baseTrade.amount), decimals],
    );
  }, [transfer, cexPair, decimals]);

  return (
    <motion.tr
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
      className="_is-extended-children"
    >
      <motion.td layout>{timeString}</motion.td>
      <motion.td layout></motion.td>
      {isAdmin && <motion.td layout></motion.td>}
      <motion.td
        layout
        style={{ textAlign: 'left', textOverflow: 'ellipsis', minWidth: 0, overflow: 'hidden' }}
      >
        <span>{transfer.cex_trade_id}</span>
      </motion.td>
      <motion.td layout></motion.td>
      <motion.td layout>
        {formatToken(baseAmount, 18)} {cexPair.token_base.symbol}
      </motion.td>
      <motion.td layout></motion.td>
      <motion.td layout>
        {formatToken(price, decimals)} / {formatFiat(priceUsd, decimals)}
      </motion.td>
      <motion.td layout>
        {formatToken(quoteAmount, 18)} {cexPair.token_quote.symbol}
      </motion.td>
      <motion.td
        layout
        style={{ textAlign: 'right', textOverflow: 'ellipsis', minWidth: 0, overflow: 'hidden' }}
      >
        <span> {feesString}</span>
      </motion.td>
    </motion.tr>
  );
};

export default ExtendedTr;
