import { BigNumber, FixedNumber, BigNumberish } from '@ethersproject/bignumber';
import { parseEther, formatUnits } from '@ethersproject/units';
import { ZERO } from 'tools/constants';
import { trimDecimalZeroes } from './formats';

export const convertBigToFixed = (recepient: BigNumber, decimals?: BigNumberish): FixedNumber =>
  FixedNumber.fromValue(recepient, decimals ?? 18);

export const multiplyBignumbers = (
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber => {
  const fn = FixedNumber.fromValue(bn1[0], bn1[1]).mulUnsafe(FixedNumber.fromValue(bn2[0], bn2[1]));
  return parseEther(fn._value);
};

export const divideBignumbers = (
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber => {
  if (bn2[0].isZero()) return ZERO;

  const fn = FixedNumber.fromValue(bn1[0], bn1[1]).divUnsafe(FixedNumber.fromValue(bn2[0], bn2[1]));
  return parseEther(fn._value);
};

export function addBignumbers(
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber {
  const fn = convertBigToFixed(bn1[0], bn1[1]).addUnsafe(convertBigToFixed(bn2[0], bn2[1]));
  return parseEther(fn._value);
}

export function subtractBignumbers(
  bn1: [BigNumber, BigNumberish],
  bn2: [BigNumber, BigNumberish],
): BigNumber {
  const fn = convertBigToFixed(bn1[0], bn1[1]).subUnsafe(convertBigToFixed(bn2[0], bn2[1]));
  return parseEther(fn._value);
}

const humanizeBigNumber = (amount: string | number, limit = 6, strict: boolean): string => {
  const numArr = `${amount}`.split('.');

  // no decimal places
  if (!numArr[1]) {
    return numArr[0];
  }

  // passes without formatting
  if (numArr[1].length < limit) {
    return `${numArr[0]}.${numArr[1]}`;
  }

  const firstMatch = strict ? numArr[1].match('[0-9]') : numArr[1].match('[1-9]');

  if (!!firstMatch && !!firstMatch.length && !!firstMatch.index && firstMatch.index > limit) {
    return Number(amount).toFixed(firstMatch.index + 2);
  }

  return Number(amount).toFixed(limit);
};

export const normalizeBigNumber = (
  value: BigNumberish,
  decimals: number,
  fix: number,
  strict = false,
) => {
  const amount = trimDecimalZeroes(formatUnits(value, decimals).toString());

  return humanizeBigNumber(amount, fix, strict);
};
