import React, { useCallback, useEffect, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react';
import { usePrevious } from 'react-use';
import cn from 'classnames';
import { uniqBy } from 'lodash';

import { Spinner, Switcher } from 'ui';
import { ICexPair } from 'types/pairs';
import { cexPairBots } from 'types/bots';

import { CogIcon } from 'assets/icons';
import './style.scss';
import { ApiCexAccount } from 'api/apiCexAccount';
import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface IBotSwitcher {
  botId: number;
  active: boolean;
  name: string;
}

interface IPairCexTableConnectedBotsHeadProps {
  isActive: boolean;
  selectedAccounts: ICexAccounts[];
  pair: ICexPair;
  onChangeSelectedAccounts?: (newSelectedAccounts: ICexAccounts[]) => void;
}

const PairCexTableConnectedBotsHead: React.FC<IPairCexTableConnectedBotsHeadProps> = ({
  isActive,
  selectedAccounts,
  pair,
  onChangeSelectedAccounts,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChangeOpened = useCallback(
    (opened: boolean) => {
      if (opened && !isActive) return;

      setIsOpen(opened);
    },
    [isActive],
  );

  const { x, y, refs, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: handleChangeOpened,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: 'end', padding: 5 }),
      shift({ padding: 5 }),
    ],
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const [botSwitchers, setBotSwitchers] = useState<IBotSwitcher[]>([]);
  const prevSelectedAccounts = usePrevious(selectedAccounts);

  const [loadingBotId, setLoadingBotId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (
      botSwitchers.length === 0 ||
      JSON.stringify(selectedAccounts) !== JSON.stringify(prevSelectedAccounts)
    ) {
      setBotSwitchers(
        cexPairBots.map(bot => ({
          botId: bot.id,
          active:
            selectedAccounts?.filter(
              el => el.connected_bots && !!el.connected_bots.find(el => el.id === bot.id),
            ).length === selectedAccounts.length,
          name: bot.description,
        })),
      );
    }
  }, [selectedAccounts]);

  const handleSwitchAccounts = useCallback(
    async (botId: number, isConnected: boolean) => {
      if (!pair) return;
      if (loadingBotId) return;
      try {
        setBotSwitchers(
          botSwitchers.map(el => (el.botId === botId ? { ...el, active: isConnected } : el)),
        );
        setLoadingBotId(botId);

        let extendedSelectedAccounts = selectedAccounts.map(el => ({
          ...el,
          connected_bots: el.connected_bots ? el.connected_bots.filter(el => el.id !== botId) : [],
        }));

        if (isConnected) {
          extendedSelectedAccounts = extendedSelectedAccounts.map(el => ({
            ...el,
            connected_bots: el.connected_bots.concat([
              cexPairBots.find(pairBot => pairBot.id === botId)!,
            ]),
          }));
        }

        if (onChangeSelectedAccounts) {
          onChangeSelectedAccounts(extendedSelectedAccounts);
        }

        await ApiCexAccount.connectAccountToCexPair({
          cexId: pair.id,
          accounts: extendedSelectedAccounts.map(el => ({
            id: el.id,
            bots_ids: uniqBy(
              el.connected_bots.map(el => el.id),
              el => el,
            ),
            //TODO check creating is_disabled
            is_disabled: false,
          })),
        });
      } catch (error) {
        console.log('error:', error);
      } finally {
        setLoadingBotId(undefined);
      }
    },
    [selectedAccounts, botSwitchers, loadingBotId, onChangeSelectedAccounts],
  );

  return (
    <>
      <div
        className={cn('mm-table-th-connected-bots', { _active: isActive })}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <button>
          <CogIcon color={isActive ? '#5932EA' : '#838DA3'} />
        </button>
        <span>Connected bots</span>
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            className="connected-bots-menu"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            <div className="connected-bots-menu__container">
              {botSwitchers.map(botSwitcher => (
                <div className="bot-switcher-item" key={botSwitcher.botId}>
                  {loadingBotId === botSwitcher.botId && (
                    <div className="bot-loading">
                      <Spinner size="mini" />
                    </div>
                  )}
                  {loadingBotId !== botSwitcher.botId && (
                    <Switcher
                      value={botSwitcher.active}
                      onChange={() => handleSwitchAccounts(botSwitcher.botId, !botSwitcher.active)}
                    />
                  )}
                  <span className={cn({ _active: botSwitcher.active })}>{botSwitcher.name}</span>
                </div>
              ))}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export { PairCexTableConnectedBotsHead };
