export const trimDecimalZeroes = (value: string): string => {
  const splittedString = value.split('.');

  if (splittedString[1] === '0') return splittedString[0];

  if (splittedString[1]) {
    const reversed = splittedString[1].split('').reverse();

    while (reversed[0] === '0') {
      reversed.shift();
    }

    if (reversed.length !== 0) return splittedString[0] + '.' + reversed.reverse().join('');

    return splittedString[0];
  }

  return value.replace('.', '');
};
