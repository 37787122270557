import React from 'react';

import './projectPageWrapper.scss';

interface IProjectPageWrapperProps {
  children: React.ReactNode;
}

const ProjectPageWrapper: React.FC<IProjectPageWrapperProps> = ({ children }) => {
  return <div className="mm-project-page-wrapper">{children}</div>;
};

export { ProjectPageWrapper };
