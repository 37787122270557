import React, { useCallback, useMemo } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { BigNumber } from '@ethersproject/bignumber';
import { useTypedSelector } from 'store';

import { useMetamaskAuth } from 'hooks';
import { injected } from 'web3/connector';
import { Modal } from 'ui/Modal/Modal';
import { ENetwork } from 'types';
import {
  BSC_CHAIN_ID,
  isTestnet,
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  ARBITRUM_CHAIN_ID,
} from 'types/web3';

import { Button } from '../Button/Button';

import { Metamask } from 'assets/images/wallets/metamask';
import './style.scss';

interface IChangePairNetworkProps {
  onClose: () => void;
}

const ChangePairNetwork: React.FC<IChangePairNetworkProps> = ({ onClose }) => {
  const { account, chainId, error } = useWeb3React();
  const { connect } = useMetamaskAuth();
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const isUnsupportedChainIdError = useMemo(
    () => error instanceof UnsupportedChainIdError,
    [error],
  );

  const changeNetworkToBsc = useCallback(async () => {
    const provider = await injected.getProvider();

    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BigNumber.from(BSC_CHAIN_ID).toHexString() }],
      });
    } catch (error) {
      console.log(error);

      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: BigNumber.from(BSC_CHAIN_ID).toHexString(),
            chainName: isTestnet ? 'BSC TESTNET' : 'Binance smart chain',
            rpcUrls: process.env.REACT_APP_BSC_RPC_URL,
            nativeCurrency: {
              name: 'Binance Coin',
              symbol: 'BNB',
              decimals: 18,
            },
          },
        ],
      });
    }
  }, []);

  const changeNetworkToEth = useCallback(async () => {
    const provider = await injected.getProvider();

    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BigNumber.from(ETH_CHAIN_ID).toHexString() }],
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const changeNetworkToPolygon = useCallback(async () => {
    const provider = await injected.getProvider();

    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BigNumber.from(POLYGON_CHAIN_ID).toHexString() }],
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const changeNetworkToArbitrum = useCallback(async () => {
    const provider = await injected.getProvider();

    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: BigNumber.from(ARBITRUM_CHAIN_ID).toHexString() }],
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeNetwork = useCallback(() => {
    onClose();

    if (!account && !isUnsupportedChainIdError) {
      return connect();
    }

    if (dexPair.network === ENetwork.bsc && (!chainId || chainId !== BSC_CHAIN_ID)) {
      return changeNetworkToBsc();
    }

    if (dexPair.network === ENetwork.eth && (!chainId || chainId !== ETH_CHAIN_ID)) {
      return changeNetworkToEth();
    }

    if (dexPair.network === ENetwork.polygon && (!chainId || chainId !== POLYGON_CHAIN_ID)) {
      return changeNetworkToPolygon();
    }

    if (dexPair.network === ENetwork.arbitrum && (!chainId || chainId !== ARBITRUM_CHAIN_ID)) {
      return changeNetworkToArbitrum();
    }
  }, [
    account,
    dexPair,
    connect,
    changeNetworkToBsc,
    changeNetworkToEth,
    changeNetworkToPolygon,
    changeNetworkToArbitrum,
    onClose,
    chainId,
    isUnsupportedChainIdError,
  ]);

  const buttonText = useMemo(() => {
    if (!account && !isUnsupportedChainIdError) {
      return 'To continue please connect metamask';
    }

    if (dexPair.network === ENetwork.bsc && chainId !== BSC_CHAIN_ID) {
      return 'To continue please switch network to BSC';
    }

    if (dexPair.network === ENetwork.eth && chainId !== ETH_CHAIN_ID) {
      return 'To continue please switch network to ETH';
    }

    if (dexPair.network === ENetwork.polygon && chainId !== POLYGON_CHAIN_ID) {
      return 'To continue please switch network to POLYGON';
    }

    if (dexPair.network === ENetwork.arbitrum && chainId !== ARBITRUM_CHAIN_ID) {
      return 'To continue please switch network to ARBITRUM';
    }

    return null;
  }, [account, dexPair, chainId, isUnsupportedChainIdError]);

  return (
    <Modal onClose={onClose} title={'Invalid metamask connection'}>
      {buttonText && (
        <Button color={'secondary'} size="small" onClick={handleChangeNetwork}>
          <Metamask />
          <span>{buttonText}</span>
        </Button>
      )}
    </Modal>
  );
};

export { ChangePairNetwork };
