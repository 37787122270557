import React from 'react';
import { Row } from '@tanstack/react-table';

import { ICexNotConnectedAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface IProxyCellProps {
  row: Row<ICexNotConnectedAccounts>;
}

const ProxyCell: React.FC<IProxyCellProps> = ({ row }) => {
  return <div>{row.original.proxy}</div>;
};

export { ProxyCell };
