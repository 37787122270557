import React from 'react';

import { IIconProps } from './types';

const ArrowDropDown: React.FC<IIconProps> = ({ className, color = '#7F91BB', onClick }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <path
        d="M12.5303 7.53033C12.8232 7.23744 12.8232 6.76256 12.5303 6.46967C12.2374 6.17678 11.7626 6.17678 11.4697 6.46967L12.5303 7.53033ZM8 11L7.46967 11.5303C7.76256 11.8232 8.23744 11.8232 8.53033 11.5303L8 11ZM4.53033 6.46967C4.23744 6.17678 3.76256 6.17678 3.46967 6.46967C3.17678 6.76256 3.17678 7.23744 3.46967 7.53033L4.53033 6.46967ZM11.4697 6.46967L7.46967 10.4697L8.53033 11.5303L12.5303 7.53033L11.4697 6.46967ZM8.53033 10.4697L4.53033 6.46967L3.46967 7.53033L7.46967 11.5303L8.53033 10.4697Z"
        fill={color}
      />
    </svg>
  );
};

export { ArrowDropDown };
