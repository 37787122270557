import React from 'react';
import { useTypedSelector } from 'store';

const TokenBaseHead: React.FC = React.memo(() => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  return <div style={{ textAlign: 'center' }}>{pair?.token_base.symbol}</div>;
});

export default TokenBaseHead;
