import React from 'react';
import { Row } from '@tanstack/react-table';

import { ICexNotConnectedAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface INotesCellProps {
  row: Row<ICexNotConnectedAccounts>;
}

const NotesCell: React.FC<INotesCellProps> = ({ row }) => {
  return <div>{row.original.notes}</div>;
};

export { NotesCell };
