import React, { useState, useCallback, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTypedSelector, useTypedDispatch } from 'store';
import { NavLink } from 'react-router-dom';

import { ApiPairs, ApiProjects } from 'api';
import { IProject } from 'types/project';
import {
  calculateScanLink,
  calculateScanImg,
  calculateKattanaTradePairLink,
} from 'utils/calculates';
import { UDPairModal } from 'modals';
import { CogIcon } from 'assets/icons';
import ProjectListIconPairs from '../../project/ProjectListWithPairs/PlatformIcon';
import { EExchange } from 'types';
import { setSelectedCexPair, setSelectedDexPair } from 'store/slices/pairs';
import { getPairLinkedBots } from 'utils/getPairLinkedBots';

import kattanaLogoImg from 'assets/images/kattana-logo.png';
import './headerPair.scss';

interface IDexPairHeaderProps {
  pairId: number;
  projectId: number;
}

const DexPairHeader: React.FC<IDexPairHeaderProps> = ({ pairId, projectId }) => {
  const dispatch = useTypedDispatch();

  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const projects = useTypedSelector(store => store.projects.projects);
  const isAdmin = useTypedSelector(store => store.user.isAdmin);

  const [project, setProject] = useState<IProject | null>(null);

  useEffect(() => {
    const setup = async () => {
      if (projects) {
        try {
          dispatch(setSelectedCexPair(null));
          dispatch(setSelectedDexPair(null));

          const [activeProject, activePair] = await Promise.all([
            ApiProjects.getProjectById(projectId),
            ApiPairs.getDexPairById(pairId),
          ]);

          if (activeProject.isSuccess && activePair.isSuccess) {
            setProject({
              created_at: activeProject.data.created_at,
              id: activeProject.data.id,
              name: activeProject.data.name,
              cexPairs: [],
              dexPairs: [],
              updated_at: '',
              notes: activeProject.data.notes,
            });

            if ('dex' in activePair.data) {
              dispatch(setSelectedDexPair(activePair.data));
            }

            await getPairLinkedBots({
              pairId: activePair.data.id,
              dispatch,
              exchange: EExchange.dex,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    setup();
  }, [dispatch, projects, pairId, projectId]);

  const [updateModalOpened, setUpdateModalOpened] = useState<boolean>(false);

  const onOpenUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(true);
  }, []);

  const onCloseUpdateModalOpened = useCallback(() => {
    setUpdateModalOpened(false);
  }, []);

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { duration: 0.4 } }}
      className="mm-header__header-pair"
    >
      {project && dexPair && (
        <>
          <div className="mm-header__header-pair__info">
            <div className="pair-name">
              <motion.a
                href={calculateScanLink({
                  address: dexPair.token_base.address,
                  network: dexPair.network,
                  addressType: 'token',
                })}
                target="_blank"
                rel="noreferrer"
                whileHover={{ scale: 1.1 }}
              >
                {dexPair.token_base.symbol}
              </motion.a>
              <span>/</span>
              <motion.a
                href={calculateScanLink({
                  address: dexPair.token_quote.address,
                  network: dexPair.network,
                  addressType: 'token',
                })}
                target="_blank"
                rel="noreferrer"
                whileHover={{ scale: 1.1 }}
              >
                {dexPair.token_quote.symbol}
              </motion.a>
            </div>
            {isAdmin && (
              <motion.div
                whileHover={{ rotate: 180, scale: 1.3 }}
                transition={{ duration: 0.3 }}
                className="pair-settings"
                onClick={onOpenUpdateModalOpened}
              >
                <CogIcon />
              </motion.div>
            )}
            <NavLink to={`/project/${project.id}`} className="project-name">
              {project.name}
            </NavLink>
            <div className="header-trading-pair-logo-header">
              <ProjectListIconPairs platform={dexPair.dex} />
            </div>
          </div>
          <div className="mm-header__header-pair__bottom">
            <a
              className="scan-link"
              target="_blank"
              rel="noreferref noreferrer"
              href={calculateScanLink({
                address: dexPair.address,
                addressType: 'address',
                network: dexPair.network,
              })}
            >
              <img src={calculateScanImg(dexPair.network)} alt="" />
            </a>
            <a
              className="trade-pair-link"
              target="_blank"
              rel="noreferrer"
              href={calculateKattanaTradePairLink({
                network: dexPair.network,
                pairAddress: dexPair.address,
              })}
            >
              <img src={kattanaLogoImg} alt="katana" />
            </a>
            {dexPair.notes && <span>{dexPair.notes}</span>}
          </div>
          {updateModalOpened && (
            <UDPairModal
              exchange={EExchange.dex}
              onClose={onCloseUpdateModalOpened}
              onOpen={onOpenUpdateModalOpened}
              pairId={dexPair.id}
              projectId={project.id}
              pairSymbol={dexPair.symbol}
              initialNotes={dexPair.notes ?? ''}
            />
          )}
        </>
      )}
    </motion.div>
  );
};

export default DexPairHeader;
