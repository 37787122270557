import React, { useContext, useMemo } from 'react';
import { parseUnits } from '@ethersproject/units';
import cn from 'classnames';
import { isNil } from 'lodash';

import { CHARTS_COLORS } from 'constants/charts';
import { chartFormatter } from 'utils/charts';
import { formatFiat, normalizeNumber } from 'utils/formats';
import { PairChartsContext } from 'context/PairChartsContext/PairChartsContext';

import './style.scss';

interface IBalanceTooltipProps {
  balances: { value: number; startValue?: number; label: string }[];
  totalBalance?: number;
  startTotalBalance?: number;
}

const BalanceTooltip: React.FC<IBalanceTooltipProps> = ({
  balances,
  totalBalance,
  startTotalBalance,
}) => {
  const { priceFormat } = useContext(PairChartsContext);

  const totalBalanceString = useMemo(() => {
    try {
      if (!totalBalance) return '';

      if (priceFormat.get === 'usd') {
        const bn = parseUnits(normalizeNumber(totalBalance), 18);

        return formatFiat(bn, 18);
      }

      return chartFormatter(totalBalance);
    } catch (e) {
      return '0';
    }
  }, [totalBalance, priceFormat.get]);

  const totalBalanceDiff = useMemo(
    () =>
      !isNil(totalBalance) && !isNil(startTotalBalance)
        ? totalBalance - startTotalBalance
        : undefined,
    [startTotalBalance, totalBalance],
  );

  const totalBalanceDiffString = useMemo(() => {
    try {
      if (!totalBalanceDiff) return '';

      if (priceFormat.get === 'usd') {
        const bn = parseUnits(normalizeNumber(totalBalanceDiff), 18);

        return formatFiat(bn, 18);
      }

      return chartFormatter(totalBalanceDiff);
    } catch (e) {
      return '';
    }
  }, [totalBalanceDiff, priceFormat.get]);

  const diffPercentage = useMemo(
    () =>
      !isNil(startTotalBalance) && startTotalBalance !== 0 && !isNil(totalBalanceDiff)
        ? Number(Math.abs((totalBalanceDiff / startTotalBalance) * 100).toFixed(2))
        : undefined,
    [totalBalanceDiff, startTotalBalance],
  );

  const _balances = useMemo(
    () =>
      balances.map(balance => {
        let balanceString = chartFormatter(balance.value);
        if (priceFormat.get === 'usd') {
          balanceString = formatFiat(parseUnits(normalizeNumber(balance.value), 18), 18);
        }

        const diff = !isNil(balance.startValue) ? balance.value - balance.startValue : undefined;

        const diffPercentage =
          !isNil(balance.startValue) && balance.startValue !== 0 && !isNil(diff)
            ? Number(Math.abs((diff / balance.startValue) * 100).toFixed(2))
            : undefined;

        let diffString = !isNil(diff) ? chartFormatter(diff) : '';
        if (priceFormat.get === 'usd' && !isNil(diff)) {
          diffString = formatFiat(parseUnits(normalizeNumber(diff), 18), 18);
        }

        return {
          label: balance.label,
          balanceString,
          diff,
          diffPercentage,
          diffString,
        };
      }),
    [balances, priceFormat.get],
  );

  return (
    <div className="mm-pair-balance-chart-tooltip">
      <div className="tooltip-header">
        <span className="tooltip-title">Balances</span>
      </div>
      {!isNil(totalBalance) && (
        <div className="tooltip-balance">
          <div className="total-balance-value">
            <div className="round" style={{ backgroundColor: CHARTS_COLORS[balances.length] }} />
            <span className="caption">Total:</span>
            <span className="amount">{totalBalanceString}</span>
            {totalBalanceDiff !== undefined && diffPercentage !== undefined && (
              <div className="value">
                <span
                  className={cn('amount', {
                    green: totalBalanceDiff >= 0,
                    red: totalBalanceDiff < 0,
                  })}
                >
                  {totalBalanceDiff > 0 ? '+' : ''}
                  {totalBalanceDiffString}
                </span>
                <span
                  className={cn('amount', {
                    green: totalBalanceDiff >= 0,
                    red: totalBalanceDiff < 0,
                  })}
                >
                  ({totalBalanceDiff >= 0 ? '+' : '-'}
                  {diffPercentage}%)
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {_balances.map((el, idx) => (
        <div className="tooltip-balance" key={idx}>
          <div className="total-balance-value">
            <div className="round" style={{ backgroundColor: CHARTS_COLORS[idx] }} />
            <span className="caption">{el.label}</span>
            <span className="amount">{el.balanceString}</span>
            {el.diff !== undefined && el.diffPercentage !== undefined && (
              <div className="value">
                <span
                  className={cn('amount', {
                    green: el.diff >= 0,
                    red: el.diff < 0,
                  })}
                >
                  {el.diff > 0 ? '+' : ''}
                  {el.diffString}
                </span>
                <span
                  className={cn('amount', {
                    green: el.diff >= 0,
                    red: el.diff < 0,
                  })}
                >
                  ({el.diff >= 0 ? '+' : '-'}
                  {el.diffPercentage}%)
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export { BalanceTooltip };
