import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { IDexWTBotVolumeStrategyTask } from 'types/bots';

interface IStartConditionCellProps {
  task: IDexWTBotVolumeStrategyTask;
}

const StartConditionCell: React.FC<IStartConditionCellProps> = ({ task }) => {
  const text = useMemo(() => {
    if (task.condition.type === 'start_at') {
      return `Time: ${dayjs(task.condition.condition_start_at?.start_at).format('L LT')}`;
    }

    if (!task.condition.condition_price_change) return '-';

    const { threshold_percentage, duration, direction } = task.condition.condition_price_change;

    return `Price ${threshold_percentage} ${
      direction === 'any' ? 'any' : direction === 'rise' ? 'up' : 'down'
    } in ${duration}`;
  }, [task]);

  return (
    <div className="start-condition-cell">
      <div className="circle" />
      <span>{text}</span>
    </div>
  );
};

export { StartConditionCell };
