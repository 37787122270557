import React from 'react';
import { Row } from '@tanstack/react-table';

import { ICexAccounts } from 'api/apiCexAccount/models/ICexAccount';

interface IApiKeyAccountCellProps {
  row: Row<ICexAccounts>;
}

const ApiKeyAccountCell: React.FC<IApiKeyAccountCellProps> = ({ row }) => {
  return <div>{row.original.api_key_masked}</div>;
};

export default ApiKeyAccountCell;
