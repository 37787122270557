import { FC, useCallback, useMemo, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import { useTypedDispatch, useTypedSelector } from 'store';
import { BigNumber } from '@ethersproject/bignumber';
import cn from 'classnames';

import { ApiBot, ApiBuySellBot } from 'api';
import { Button } from 'ui';
import { useFeeCostsUiString, useTaskTime } from 'hooks';
import { CreateBuySellTaskModal } from 'modals';
import { PairBuySellBotContext } from 'context/PairBuySellBotContext';

import { revertedMempoolConfigMap } from 'constants/common';
import { parseDurationToSeconds } from 'utils';
import { formatToken, toFixed, formatFiat } from 'utils/formats';
import { durationToMs } from 'utils/duration';
import { IBuySellBotTaskItemInner } from 'types/buy-sell-bot';
import { EDexBot } from 'types/bots';
import { setAlertState, dropAlertState } from 'store/slices/ui';

import './style.scss';

const formatStartTimeToUniqueId = (startTime: string) => new Date(startTime).getTime();

type Props = {
  task: IBuySellBotTaskItemInner;
  rowIdx: number;
};

export const RowInfo: FC<Props> = ({ task, rowIdx }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const { handleLoadRecords, handleLoadBotSettings, botSettings } =
    useContext(PairBuySellBotContext);
  const dispatch = useTypedDispatch();

  const [createNewTaskModalOpened, setCreateNewTaskModalOpened] = useState<boolean>(false);

  const handleDeleteTask = useCallback(async () => {
    try {
      dispatch(dropAlertState());

      const {
        data: dataStatistic,
        isSuccess: isSuccessFirst,
        errorMessage: errorMessageFirst,
      } = await ApiBuySellBot.buySellStatistics({ pairId: dexPair.id });

      const taskIdForEdit = task ? formatStartTimeToUniqueId(task.task.start_time) : undefined;

      const newTasks = dataStatistic?.items
        ?.filter(({ task }) => {
          const taskId = formatStartTimeToUniqueId(task.start_time);

          return taskIdForEdit !== taskId;
        })
        .map(el => el.task);

      if (!isSuccessFirst) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessageFirst,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
        return;
      }

      const { isSuccess, errorMessage } = await ApiBot.saveDexBotConfig({
        pairId: dexPair.id,
        bot: EDexBot.buy_sell_bot,
        body: {
          slippage_percent: botSettings?.slippage_percent ?? '0',
          send_private_transactions: botSettings?.send_private_transactions ?? false,
          is_enabled: botSettings?.is_enabled ?? false,
          buy_sell_options: {
            tasks: newTasks ?? [],
          },
        },
      });

      if (!isSuccess) {
        dispatch(
          setAlertState({
            type: 'failed-img',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
        return;
      }

      if (isSuccess) {
        handleLoadRecords();
        handleLoadBotSettings();
        dispatch(
          setAlertState({
            type: 'success',
            text: 'You successfully deleted task!',
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: 'Something went wrong',
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
          },
        }),
      );
    }
  }, [dexPair, task, botSettings, handleLoadRecords, handleLoadBotSettings, dispatch]);

  const handleOpenDeleteTaskModal = useCallback(() => {
    dispatch(
      setAlertState({
        type: 'sure',
        text: 'Are you sure you want to delete this task?',
        onClose: () => dispatch(dropAlertState()),
        onSubmit: handleDeleteTask,
      }),
    );
  }, [dispatch, handleDeleteTask]);

  const { finishTimeUiString } = useTaskTime({
    minAmount: task.task.min_amount,
    maxAmount: task.task.max_amount,
    minDelay: durationToMs(task.task.min_pause),
    maxDelay: durationToMs(task.task.max_pause),
    totalTokenAmount: task.task.total_token_amount,
  });

  const feeCostsUiString = useFeeCostsUiString({
    totalTokenAmount: task.task.total_token_amount,
    minAmount: task.task.min_amount,
    maxAmount: task.task.max_amount,
  });

  const minAmount = useMemo(
    () => formatToken(BigNumber.from(task.task.min_amount), dexPair.token_base.decimals),
    [dexPair, task],
  );

  const maxAmount = useMemo(
    () => formatToken(BigNumber.from(task.task.max_amount), dexPair.token_base.decimals),
    [dexPair, task],
  );

  const amountDisperseCoeffMin = useMemo(
    () => (task.task.amount_disperse_coeff_min * 100).toString() + '%',
    [task],
  );
  const amountDisperseCoeffMax = useMemo(
    () => (task.task.amount_disperse_coeff_max * 100).toString() + '%',
    [task],
  );

  const skipTransactionsMin = useMemo(() => task.task.skip_transactions_min, [task]);
  const skipTransactionsMax = useMemo(() => task.task.skip_transactions_max, [task]);

  const priceThreshold = useMemo(
    () => (task.task.price_threshold ? task.task.price_threshold : '-'),
    [task],
  );

  const lockedSpanClassName = useMemo(
    () => cn({ ['_locked']: task.task.use_mempool === 'off' }),
    [task],
  );

  return (
    <motion.div
      key={`extended-row-${rowIdx}`}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      className="mm-buy-sell-bot-row-extended-info"
    >
      <div className="line-separator" />
      <div className="sections">
        <span className="section-title">Settings</span>
        <span className="section-title">Statistics</span>
        <div className="section">
          <div className="grid content-start gap-2">
            <span>Min amount: {minAmount}</span>
            <span>Max amount: {maxAmount}</span>
          </div>
          <div className="grid content-start gap-2">
            <span>Min delay (sec): {parseDurationToSeconds(task.task.min_pause)}</span>
            <span>Max delay (sec): {parseDurationToSeconds(task.task.max_pause)}</span>
          </div>
          <div className="grid content-start gap-2">
            <span>Mempool: {revertedMempoolConfigMap[task.task.use_mempool]}</span>
            <span className={lockedSpanClassName}>
              Max mempool acceleration: {task.task.advance_coefficient * 100}%
            </span>
          </div>
          <div className="grid content-start gap-2">
            <span className={lockedSpanClassName}>
              Min amount percent: {amountDisperseCoeffMin}
            </span>
            <span className={lockedSpanClassName}>
              Max amount percent: {amountDisperseCoeffMax}
            </span>
          </div>
          <div className="grid content-start gap-2">
            <span className={lockedSpanClassName}>
              Min transactions delay: {skipTransactionsMin}
            </span>
            <span className={lockedSpanClassName}>
              Max transactions delay: {skipTransactionsMax}
            </span>
          </div>
          <div className="grid content-start gap-2">
            <span>Price threshold: {priceThreshold}</span>
          </div>
        </div>
        <div className="section">
          <div className="grid content-start gap-2">
            <span>
              {task.task.direction === 'buy' ? 'Scheduler bought' : 'Scheduler sold'}:{' '}
              {formatToken(BigNumber.from(task.schedule.amount), dexPair.token_base.decimals)} /{' '}
              {toFixed(Number(task.schedule.amount_percent), 2)}%
            </span>
            <span>
              {task.task.direction === 'buy' ? 'Mempool bought' : 'Mempool sold'}:{' '}
              {formatToken(BigNumber.from(task.mempool.amount), dexPair.token_base.decimals)} /{' '}
              {toFixed(Number(task.mempool.amount_percent), 2)}%
            </span>
          </div>
          <div className="grid content-start gap-2">
            <span>
              {task.task.direction === 'buy' ? 'Avg planner buy price' : 'Avg planner sell price'}:{' '}
              {formatToken(BigNumber.from(task.schedule.avg_price), dexPair.token_quote.decimals)} /{' '}
              {formatFiat(BigNumber.from(task.schedule.avg_price_usd ?? '0'), 6, '$')}
            </span>
            <span>
              {task.task.direction === 'buy' ? 'Avg mempool buy price' : 'Avg mempool sell price'}:{' '}
              {formatToken(BigNumber.from(task.mempool.avg_price), dexPair.token_quote.decimals)} /{' '}
              {formatFiat(BigNumber.from(task.mempool.avg_price_usd ?? '0'), 6, '$')}
            </span>
          </div>
          <div className="grid content-start gap-2">
            <span>Total scheduler transaction: {task.schedule.transactions_count}</span>
            <span>Total mempool transaction: {task.mempool.transactions_count}</span>
          </div>
        </div>
      </div>
      <div className="line-separator" />
      <div className="extended-row__footer">
        <div className="grid grid-flow-col justify-start gap-10 font-bold">
          {finishTimeUiString && (
            <span className="!text-gray-1">Finish time: {finishTimeUiString}</span>
          )}
          {feeCostsUiString && <span className="!text-gray-1">Fee costs: {feeCostsUiString}</span>}
        </div>
        <div className="flex flex-row items-center gap-3">
          {task.status !== 'past' && (
            <Button
              color="secondary"
              className="edit-task-button"
              onClick={() => setCreateNewTaskModalOpened(true)}
            >
              Edit task
            </Button>
          )}
          {task.status === 'future' && (
            <Button
              color="error-bordered"
              className="delete-task-button"
              onClick={handleOpenDeleteTaskModal}
            >
              Delete task
            </Button>
          )}
        </div>
      </div>
      {createNewTaskModalOpened && (
        <CreateBuySellTaskModal
          mode="edit"
          task={task}
          onOpen={() => setCreateNewTaskModalOpened(true)}
          onClose={() => setCreateNewTaskModalOpened(false)}
        />
      )}
    </motion.div>
  );
};
