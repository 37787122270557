import React from 'react';
import dayjs from 'dayjs';

import { IDextWTOrganicVolumeTask } from 'types/bots';

interface IExecutionTimeCellProps {
  task: IDextWTOrganicVolumeTask;
}

const ExecutionTimeCell: React.FC<IExecutionTimeCellProps> = ({ task }) => {
  return (
    <div>
      {dayjs(task.start_at).format('L LT')} -{' '}
      {task.end_at ? dayjs(task.end_at).format('L LT') : 'none'}
    </div>
  );
};

export { ExecutionTimeCell };
