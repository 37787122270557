import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { parseUnits } from '@ethersproject/units';
import { useTypedDispatch, useTypedSelector } from 'store';
import { capitalize, isNil } from 'lodash';
import { v4 as uuid } from 'uuid';

import { Button, Modal, ButtonLoading } from 'ui';
import { ConnectNewWalletsToPairForm } from 'ui/forms';
import { ConnectWalletBalanceTaskTable } from 'tables';
import { dropAlertState, setAlertState } from 'store/slices/ui';
import { ApiBalanceBot } from 'api';
import { multiplyBignumbers } from 'utils/formulas';
import { PairBalanceBotContext } from 'context/PairBalanceBotContext';
import { useDexBoostHoldersWalletsRecords } from 'hooks/tables/dex';
import { useDexPairFilters } from 'store/slices/dex_pair_filters/hooks';
import { filtersToValues } from 'utils/filters';
import { EDexPairFilters } from 'types/filters';
import { dexBoostHoldersWalletsFiltersScheme } from 'types/filters/dex_pair';
import { EFilterBooleanConnection, EFilterEntity } from 'types/filters/common';

import './style.scss';

interface IConnectWalletsToBoostHoldersModal {
  onClose: () => void;
  walletType: 'source' | 'destination';
  not_connected?: 'true' | 'false' | '';
  taskType: string;
  currentTaskValue?: any;
  readOnlyMode?: boolean;
  task?: any;
  taskId: number;
  activeWalletsStep?: number;
  setActiveWalletsStep?: Dispatch<SetStateAction<number>>;
  includeSteps?: boolean;
}

const ConnectWalletsToBoostHoldersModal: React.FC<IConnectWalletsToBoostHoldersModal> = ({
  onClose,
  walletType,
  readOnlyMode,
  taskId,
  taskType,
  task,
  currentTaskValue,
  activeWalletsStep,
  setActiveWalletsStep,
  includeSteps,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const dispatch = useTypedDispatch();

  const { settedPairFilters, addFilter, saveFilters } = useDexPairFilters({
    dexPair,
    type: EDexPairFilters.boostHoldersWallets,
  });

  const filtersValues = useMemo(() => {
    const filterValuesFlat = filtersToValues({
      filters: settedPairFilters,
      scheme: dexBoostHoldersWalletsFiltersScheme(dexPair),
    });

    const filterValues = filterValuesFlat.reduce((acc, val) => ({ ...acc, ...val }), {});

    return filterValues;
  }, [dexPair, settedPairFilters]);

  const mode = useMemo(
    () => (filtersValues.not_connected === 'true' ? 'disconnect' : 'connect'),
    [filtersValues],
  );

  useEffect(() => {
    if (readOnlyMode) {
      //TODO clear all filters and set connected = true
    } else {
      if (isNil(filtersValues.not_connected)) {
        addFilter({
          id: uuid(),
          name: 'not_connected',
          type: EFilterEntity.boolean,
          value: { value: true, connection: EFilterBooleanConnection.is },
        });
        setTimeout(() => saveFilters(), 0);
      }
    }
  }, [filtersValues, addFilter, saveFilters, readOnlyMode]);

  const { records, selectedRecords, rowSelection, hasMore, loading, handleLoadMore } =
    useDexBoostHoldersWalletsRecords({
      task_id: taskId,
      wallets: walletType,
    });

  const { handleLoadRecords } = useContext(PairBalanceBotContext);

  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const handleAppendAccounts = useCallback(async () => {
    try {
      if (!taskId) {
        return null;
      }

      setRequestLoading(true);

      const selectedWallets = selectedRecords.map(wallet => wallet.id);

      const updatedBody =
        walletType === 'source'
          ? {
              id: taskId,
              pair_id: dexPair.id,
              type: 'add_holder',
              ...(taskType === 'add_holder'
                ? {
                    add_holder_options: {
                      enabled: currentTaskValue.enabled,
                      buyback_enabled: currentTaskValue.buyback_enabled,
                      ...(currentTaskValue.buyback_enabled
                        ? {
                            buyback_options: {
                              interval: '10s',
                              min_pause: `${currentTaskValue.buyback_min_pause}ms`,
                              max_pause: `${currentTaskValue.buyback_max_pause}ms`,
                              min_swaps: +currentTaskValue.buyback_min_swaps,
                              max_swaps: +currentTaskValue.buyback_max_swaps,
                            },
                          }
                        : {}),
                      min_amount: String(
                        multiplyBignumbers(
                          [parseUnits(currentTaskValue.min_amount), 18],
                          [parseUnits('1'), 18],
                        ),
                      ),
                      max_amount: String(
                        multiplyBignumbers(
                          [parseUnits(currentTaskValue.max_amount), 18],
                          [parseUnits('1'), 18],
                        ),
                      ),
                      min_pause: `${currentTaskValue.min_pause}ms`,
                      max_pause: `${currentTaskValue.max_pause}ms`,
                      source_wallet_ids: [
                        ...currentTaskValue.source_wallet_ids,
                        ...selectedWallets,
                      ],
                    },
                  }
                : {
                    maintain_fee_balance_options: {
                      ...currentTaskValue,
                      interval: `${currentTaskValue.interval}ms`,
                      source_wallet_ids: [
                        ...(mode === 'connect'
                          ? (task.maintain_fee_balance_options.source_wallet_ids || []).concat(
                              selectedWallets,
                            )
                          : (task.maintain_fee_balance_options.source_wallet_ids || []).filter(
                              (el: number) => !selectedWallets.find(wallet => wallet === el),
                            )),
                      ],
                    },
                  }),
            }
          : undefined;

      const { isSuccess, errorMessage } = await (walletType === 'source'
        ? ApiBalanceBot.updateSourceWalletsTasksById({
            balance_bot_id: taskId,
            body: updatedBody,
          })
        : mode === 'connect'
        ? ApiBalanceBot.updateDestinationWalletsTasksById({
            balance_bot_id: taskId,
            body: {
              wallet_ids: selectedWallets,
            },
          })
        : ApiBalanceBot.deleteDestinationWalletsTasksById({
            balance_bot_id: taskId,
            body: {
              wallet_ids: selectedWallets,
            },
          }));

      if (isSuccess) {
        if (activeWalletsStep !== 1) {
          onClose();
          handleLoadRecords();
          dispatch(
            setAlertState({
              type: 'success',
              text: `You have successfully ${mode === 'connect' ? 'connected' : 'disconnected'} ${
                selectedRecords.length
              } wallets to pair`,
              onClose: () => {
                dispatch(dropAlertState());
              },
              onSubmit: () => {
                onClose();
                dispatch(dropAlertState());
              },
            }),
          );
        }
      } else {
        onClose();

        dispatch(
          setAlertState({
            type: 'failed-img',
            text: String(errorMessage),
            onClose: () => {
              dispatch(dropAlertState());
            },
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestLoading(false);
    }
  }, [
    dexPair,
    selectedRecords,
    task,
    taskId,
    mode,
    taskType,
    activeWalletsStep,
    currentTaskValue,
    walletType,
    dispatch,
    handleLoadRecords,
    onClose,
  ]);

  return (
    <Modal
      title={
        readOnlyMode
          ? `Wallets ${walletType === 'source' ? 'from' : 'to'}`
          : `Choose wallets ${walletType === 'source' ? 'from' : 'to'}`
      }
      className="mm-cex-account-modal"
      onClose={onClose}
    >
      <ConnectNewWalletsToPairForm handleSubmit={handleAppendAccounts}>
        <div className="connect-new-wallets-to-pair-modal-content">
          {!readOnlyMode && (
            <span className="text-sm">
              {mode === 'disconnect' ? 'Connected: ' : 'Not connected: '}
            </span>
          )}
          <ConnectWalletBalanceTaskTable
            mode={mode}
            readOnlyMode={readOnlyMode}
            records={records}
            rowSelection={rowSelection}
            hasMore={hasMore}
            loading={loading}
            handleLoadMore={handleLoadMore}
            selectedRecords={selectedRecords}
          />
        </div>
        {includeSteps ? (
          <div style={{ display: 'flex', gap: '40px' }}>
            <Button
              type="button"
              disabled={activeWalletsStep === 1}
              onClick={() => {
                setActiveWalletsStep?.(v => v - 1);
              }}
            >
              Prev
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setTimeout(() => {
                  if (activeWalletsStep === 2) {
                    onClose();
                  } else {
                    setActiveWalletsStep?.(v => v + 1);
                  }
                });
              }}
            >
              {activeWalletsStep === 2 ? capitalize(mode) : 'Next'}
            </Button>
          </div>
        ) : (
          !requestLoading &&
          !readOnlyMode && (
            <Button type="submit" disabled={selectedRecords.length === 0}>
              {capitalize(mode)}
            </Button>
          )
        )}
        {requestLoading && <ButtonLoading />}
      </ConnectNewWalletsToPairForm>
    </Modal>
  );
};

export { ConnectWalletsToBoostHoldersModal };
