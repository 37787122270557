import React from 'react';

import { IIconProps } from './types';

const EyeHidden: React.FC<IIconProps> = ({ className, color = '#7F91BB', onClick }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
      className={className ?? undefined}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.75C5.25 3.75 2.0475 6.0825 0.75 9.375C2.0475 12.6675 5.25 15 9 15C12.75 15 15.9525 12.6675 17.25 9.375C15.9525 6.0825 12.75 3.75 9 3.75ZM9 13.125C6.93 13.125 5.25 11.445 5.25 9.375C5.25 7.305 6.93 5.625 9 5.625C11.07 5.625 12.75 7.305 12.75 9.375C12.75 11.445 11.07 13.125 9 13.125ZM9 7.125C7.755 7.125 6.75 8.13 6.75 9.375C6.75 10.62 7.755 11.625 9 11.625C10.245 11.625 11.25 10.62 11.25 9.375C11.25 8.13 10.245 7.125 9 7.125Z"
        fill={color}
      />
    </svg>
  );
};

export { EyeHidden };
