import { IDexWTBotRandomStrategy, IDexWTBotVolumesStrategy } from 'types/bots';

export type IWTBotTabs = 'quick-start' | 'advanced';

export const mapWTBotTabToName = (tab: IWTBotTabs) => {
  return { 'quick-start': 'Quick start', advanced: 'Advanced' }[tab];
};

export const mapWTBotNameToTab = (tabName: string) => {
  return { 'Quick start': 'quick-start' as IWTBotTabs, Advanced: 'advanced' as IWTBotTabs }[
    tabName
  ] as IWTBotTabs;
};

export interface IChartFormInfo {
  tokenPrice: string;
  pooledBaseToken: string;
  poolLiquidity: string;
  pooledFeeToken: string;
}

export interface IWTShortPreset {
  created_at: string;
  id: number;
  name: string;
}

export interface IPreset {
  random_strategy: IDexWTBotRandomStrategy;
  volumes_strategy: IDexWTBotVolumesStrategy;
}

export interface IWTConfigClientOptions {
  trade_size_coefficient?: number;
  volatility_coefficient?: number;
  trade_frequency_coefficient?: number;
}

export const PERIOD_DEFAULT = '1440h';
export const INTERVAL_DEFAULT = '15m';
export const SEED_DEFAULT = -1;

export const MIN_TRADE_FREQUENCY = 0.001;
export const MAX_TRADE_FREQUENCY = 5;
export const DEFAULT_TRADE_FREQUENCY = 1;

export const MIN_VOLATILITY = 0.001;
export const MAX_VOLATILITY = 2;
export const DEFAULT_VOLATILITY = 1;

export const MIN_TRADE_SIZE = 0.2;
export const MAX_TRADE_SIZE = 5;
export const DEFAULT_TRADE_SIZE = 1;

export const WT_TIMEFRAMES = [
  {
    label: '1 minute',
    value: '1m',
  },
  {
    label: '5 minutes',
    value: '5m',
  },
  {
    label: '15 minutes',
    value: '15m',
  },
  {
    label: '30 minutes',
    value: '30m',
  },
  {
    label: '1 hour',
    value: '1h',
  },
  {
    label: '4 hours',
    value: '4h',
  },
  {
    label: '12 hours',
    value: '12h',
  },
  {
    label: '1 day',
    value: '24h',
  },
  {
    label: '1 week',
    value: '168h',
  },
];

export const FG_TIMEFRAMES = [
  {
    label: '1 minute',
    value: '1m',
  },
  {
    label: '3 minutes',
    value: '3m',
  },
  {
    label: '5 minutes',
    value: '5m',
  },
  {
    label: '10 minutes',
    value: '10m',
  },
  {
    label: '15 minutes',
    value: '15m',
  },
  {
    label: '30 minutes',
    value: '30m',
  },
  {
    label: '1 hour',
    value: '1h',
  },
  {
    label: '4 hours',
    value: '4h',
  },
  {
    label: '12 hours',
    value: '12h',
  },
];
