import React, { useCallback, useContext } from 'react';

import { ChartRangeController, ChartConvertedFormat } from 'ui';
import { EExchange } from 'types';
import { EChartConvertedFormat } from 'types/charts';
import { PairChartsContext } from 'context/PairChartsContext/PairChartsContext';
import { CHART_RANGES } from 'constants/charts';

import './style.scss';

const ChartToolbar: React.FC = () => {
  const {
    convertedFormat,
    disabledFormats,
    selectedRange,
    startDate,
    endDate,
    loading,
    exchange,
    handleLoadOnlyPairChart,
  } = useContext(PairChartsContext);

  const handleSelectConvertedFormat = useCallback(
    (v: EChartConvertedFormat) => {
      convertedFormat.set(v);

      setTimeout(() => {
        handleLoadOnlyPairChart();
      }, 0);
    },

    //eslint-disable-next-line
    [convertedFormat.set, handleLoadOnlyPairChart],
  );

  return (
    <div className="mm-pair-chart-toolbar">
      {exchange === EExchange.dex ? (
        <ChartConvertedFormat
          supportedFormats={[EChartConvertedFormat.Original, EChartConvertedFormat.Converted]}
          selectedFormat={convertedFormat.get}
          disabledFormats={disabledFormats}
          setSelectedFormat={handleSelectConvertedFormat}
          disabled={!!loading.get}
        />
      ) : (
        <div />
      )}
      <ChartRangeController
        supportedRanges={CHART_RANGES.map(el => el.label)}
        selectedRange={selectedRange.get}
        setSelectedRange={range => {
          selectedRange.set(range);
        }}
        startDate={startDate.get}
        setStartDate={newStartDate => {
          startDate.set(newStartDate);
        }}
        endDate={endDate.get}
        setEndDate={newEndDate => {
          endDate.set(newEndDate);
        }}
        disabled={!!loading.get}
      />
    </div>
  );
};

export { ChartToolbar };
