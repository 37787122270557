import React from 'react';

import './pairForm.scss';

interface IPairFormProps {
  handleSubmit: () => void;
  children: React.ReactNode;
}

const PairForm: React.FC<IPairFormProps> = ({ handleSubmit, children }) => {
  return (
    <form className="mm-pair-form" onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export { PairForm };
