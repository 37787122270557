import React, {
  createContext,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useTypedSelector } from 'store';
import axios, { CancelTokenSource } from 'axios';
import { uniq } from 'lodash';

import { ApiBot, ApiWTBot } from 'api';
import { parseDurationToSeconds } from 'utils';
import { useDexCandlesByTimeframe } from 'hooks';
import { EDexBot, IDexWTBotConfig, IDexWTSimulationOptions } from 'types/bots';
import { ATR_PERIOD } from 'constants/numbers';
import { WT_TIMEFRAMES } from 'types/wash-trading-bot';
import { durationToMs } from 'utils/duration';
import { IKattanaCexChartResponse } from 'api/apiCharts/models';
import {
  IWTBotTabs,
  IWTConfigClientOptions,
  IChartFormInfo,
  IPreset,
  IWTShortPreset,
  INTERVAL_DEFAULT,
  PERIOD_DEFAULT,
  SEED_DEFAULT,
  DEFAULT_TRADE_SIZE,
  DEFAULT_TRADE_FREQUENCY,
  DEFAULT_VOLATILITY,
} from 'types/wash-trading-bot';

const initialChartFormInfo = {
  pooledBaseToken: '',
  pooledFeeToken: '',
  poolLiquidity: '',
  tokenPrice: '',
};

interface IPairWashTradingBotContext {
  selectedTab: { get: IWTBotTabs; set: (tab: IWTBotTabs) => void };
  loading: { get: boolean; set: (v: boolean) => void };
  chartLoading: { get: boolean; set: (v: boolean) => void };
  triggerLoadChart: { get: boolean; set: Dispatch<SetStateAction<boolean>> };
  presetLoading: { get: boolean; set: (v: boolean) => void };
  chartFormInfo: { get: IChartFormInfo; set: (v: IChartFormInfo) => void };
  botSettings: IDexWTBotConfig | undefined;
  initialConfig: {
    get: IDexWTSimulationOptions | undefined;
    set: (v: IDexWTSimulationOptions | undefined) => void;
  };
  initialOptions: {
    get: IWTConfigClientOptions | undefined;
    set: (v: IWTConfigClientOptions | undefined) => void;
  };
  currentConfig: {
    get: IDexWTSimulationOptions | undefined;
    set: (v: IDexWTSimulationOptions | undefined) => void;
  };
  presetsShort: IWTShortPreset[] | undefined;
  presets: Record<number, IPreset> | undefined;
  selectedPresetId: { get: number | undefined; set: (v: number | undefined) => void };
  handleLoadBotSettings: () => Promise<void>;
  handleLoadPresets: () => Promise<void>;
  handleLoadPreset: (id: number) => Promise<IPreset | undefined>;
  errorMessage: string | undefined;
  chartErrorMessage: { get: string | undefined; set: (v: string | undefined) => void };

  //quick start
  tradeFrequencyCoefficient: { get: number; set: (v: number) => void };
  tradeSizeCoefficient: { get: number; set: (v: number) => void };
  volatilityCoefficient: { get: number; set: (v: number) => void };
  useAdditionalReserves: { get: boolean; set: (v: boolean) => void };
  manualPooledBase: { get: string; set: (v: string) => void };
  manualPooledQuote: { get: string; set: (v: string) => void };
  CancelTokenRef: React.MutableRefObject<CancelTokenSource | undefined>;
  organicCandles: Record<string, IKattanaCexChartResponse>;
}

export const PairWashTradingContext = createContext<IPairWashTradingBotContext>({
  selectedTab: { get: 'quick-start', set: () => {} },
  loading: { get: false, set: () => {} },
  chartLoading: { get: false, set: () => {} },
  triggerLoadChart: { get: false, set: () => {} },
  presetLoading: { get: false, set: () => {} },
  chartFormInfo: { get: initialChartFormInfo, set: () => {} },
  botSettings: undefined,
  initialConfig: { get: undefined, set: () => {} },
  initialOptions: { get: undefined, set: () => {} },
  currentConfig: { get: undefined, set: () => {} },
  presetsShort: undefined,
  presets: undefined,
  selectedPresetId: { get: undefined, set: () => {} },
  handleLoadBotSettings: async () => {},
  handleLoadPresets: async () => {},
  handleLoadPreset: async () => undefined,
  errorMessage: undefined,
  chartErrorMessage: { get: undefined, set: () => {} },
  //quick start
  tradeFrequencyCoefficient: { get: 0, set: () => {} },
  tradeSizeCoefficient: { get: 0, set: () => {} },
  volatilityCoefficient: { get: 0, set: () => {} },
  useAdditionalReserves: { get: false, set: () => {} },
  manualPooledBase: { get: '', set: () => {} },
  manualPooledQuote: { get: '', set: () => {} },
  CancelTokenRef: {} as React.MutableRefObject<CancelTokenSource | undefined>,
  organicCandles: {},
});

interface IPairWashTradingBotContextProviderProps {
  children?: React.ReactNode;
}

export const PairWashTradingBotContextProvider: React.FC<
  IPairWashTradingBotContextProviderProps
> = ({ children }) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;
  const CancelTokenRef = useRef<CancelTokenSource>();

  const [organicTimeframes, setOrganicTimeframes] = useState<string[]>([]);

  useEffect(() => {
    CancelTokenRef.current = axios.CancelToken.source();
  }, []);

  const [botSettings, setBotSettings] = useState<IDexWTBotConfig | undefined>(undefined);
  const [initialConfig, setInitialConfig] = useState<IDexWTSimulationOptions | undefined>(
    undefined,
  );
  const [initialOptions, setInitialOptions] = useState<IWTConfigClientOptions | undefined>(
    undefined,
  );
  const [currentConfig, setCurrentConfig] = useState<IDexWTSimulationOptions | undefined>(
    undefined,
  );
  const [presetsShort, setPresetsShort] = useState<IWTShortPreset[] | undefined>(undefined);
  const [selectedPresetId, setSelectedPresetId] = useState<number | undefined>(undefined);
  const [presets, setPresets] = useState<Record<number, IPreset> | undefined>(undefined);

  const [selectedTab, setSelectedTab] = useState<IWTBotTabs>('quick-start');
  const [loading, setLoading] = useState<boolean>(true);
  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const [triggerLoadChart, setTriggerLoadChart] = useState<boolean>(false);
  const [presetLoading, setPresetLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [chartErrorMessage, setChartErrorMessage] = useState<string | undefined>(undefined);

  const [tradeFrequencyCoefficient, setTradeFrequencyCoefficient] =
    useState<number>(DEFAULT_TRADE_FREQUENCY);
  const [tradeSizeCoefficient, setTradeSizeCoefficient] = useState<number>(DEFAULT_TRADE_SIZE);
  const [volatilityCoefficient, setVolatilityCoefficient] = useState<number>(DEFAULT_VOLATILITY);

  //additional reserves
  const [useAdditionalReserves, setUseAdditionalReserves] = useState<boolean>(false);
  const [manualPooledBase, setManualPooledBase] = useState<string>('');
  const [manualPooledQuote, setManualPooledQuote] = useState<string>('');

  //chart info for form
  const [chartFormInfo, setChartFormInfo] = useState<IChartFormInfo>({
    pooledBaseToken: '',
    pooledFeeToken: '',
    poolLiquidity: '',
    tokenPrice: '',
  });

  const handleLoadBotSettings = useCallback(async () => {
    setLoading(true);

    try {
      const { isSuccess, errorCode, errorMessage, data } = await ApiBot.getDexBotConfig({
        pairId: dexPair.id,
        bot: EDexBot.wash_trading,
      });

      setErrorMessage(undefined);

      if (isSuccess && data) {
        setBotSettings(data);

        if (data.wt_options) {
          data.wt_options.client_options = undefined;
        }

        setInitialConfig(
          data.wt_options
            ? {
                config: {
                  ...data.wt_options,
                },
                period: PERIOD_DEFAULT,
                interval: INTERVAL_DEFAULT,
                seed: SEED_DEFAULT,
              }
            : undefined,
        );
        setCurrentConfig(
          data.wt_options
            ? {
                config: {
                  ...data.wt_options,
                },
                period: PERIOD_DEFAULT,
                interval: INTERVAL_DEFAULT,
                seed: SEED_DEFAULT,
              }
            : undefined,
        );
        setInitialOptions(data?.wt_options?.client_options ?? undefined);

        const _organicTimeframes = uniq(
          [...(data.wt_options?.organic_volumes_tasks ?? [])].map(el => el.options.time_frame),
        );
        setOrganicTimeframes(
          _organicTimeframes
            .map(timeframe => {
              const find = WT_TIMEFRAMES.find(
                el => durationToMs(el.value) === durationToMs(timeframe),
              );

              if (find) {
                return find.value;
              } else return null;
            })
            .filter(el => el !== null) as string[],
        );
      } else if (!isSuccess) {
        setErrorMessage(errorMessage ?? 'Failed to load bot config. Try to refresh your page...');

        setInitialConfig(undefined);
        setInitialOptions(undefined);
        setCurrentConfig(undefined);

        if (errorCode === 14002) {
          setBotSettings({
            is_enabled: false,
            send_private_transactions: false,
            slippage_percent: '0.3',
            wt_options: undefined,
          });
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed to load bot config. Try to refresh your page...');
    } finally {
      setLoading(false);
    }
  }, [dexPair]);

  const handleLoadPresets = useCallback(async () => {
    try {
      const { isSuccess, errorMessage, data } = await ApiWTBot.getWTPresets();

      if (isSuccess && data) {
        setPresetsShort(data.items);
        setPresets(undefined);
      } else {
        setErrorMessage(errorMessage ?? undefined);
        setPresetsShort(undefined);
        setPresets(undefined);
      }

      setSelectedPresetId(-1);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleLoadPreset = useCallback(async (id: number) => {
    if (presets && presets[id]) return presets[id];

    try {
      setPresetLoading(true);

      const { errorMessage, isSuccess, data } = await ApiWTBot.getWTPreset(id);

      setPresetLoading(false);

      if (isSuccess && data) {
        setPresets(presets => {
          return {
            ...presets,
            [id]: {
              random_strategy: data.options.random_strategy,
              volumes_strategy: data.options.volumes_strategy,
            },
          };
        });
        setErrorMessage(undefined);

        return {
          random_strategy: data.options.random_strategy,
          volumes_strategy: data.options.volumes_strategy,
        };
      } else {
        setErrorMessage(errorMessage ?? undefined);
        return undefined;
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed to download single preset...');
      setPresetLoading(false);
    }
  }, []);

  const handleChangeConfig = useCallback(
    (newConfig: IDexWTSimulationOptions | undefined) => {
      if (newConfig === undefined) return setCurrentConfig(undefined);

      const newConfigResult = newConfig;

      if (selectedTab === 'quick-start') {
        try {
          const maxPause = parseDurationToSeconds(
            newConfigResult.config.random_strategy.max_pause_between_trades,
          );
          const minPause = parseDurationToSeconds(
            newConfigResult.config.random_strategy.min_pause_between_trades,
          );

          const averagePause = (minPause + maxPause) / 2;

          let newPeriod = newConfigResult.period;
          if (averagePause < 20) {
            newPeriod = '72h';
          } else if (averagePause < 60) {
            newPeriod = '336h';
          } else if (averagePause < 300) {
            newPeriod = '672h';
          }

          newConfigResult.period = newPeriod;
        } catch (error) {}
      }

      setCurrentConfig(newConfigResult);
    },
    [selectedTab],
  );

  const { candles } = useDexCandlesByTimeframe({
    dexPair: useMemo(() => dexPair, [dexPair]),
    period: ATR_PERIOD,
    timeframes: useMemo(() => organicTimeframes, [organicTimeframes]),
  });

  return (
    <PairWashTradingContext.Provider
      value={{
        selectedTab: { get: selectedTab, set: setSelectedTab },
        loading: { get: loading, set: setLoading },
        chartLoading: { get: chartLoading, set: setChartLoading },
        triggerLoadChart: { get: triggerLoadChart, set: setTriggerLoadChart },
        presetLoading: { get: presetLoading, set: setPresetLoading },
        chartFormInfo: { get: chartFormInfo, set: setChartFormInfo },
        botSettings,
        initialConfig: { get: initialConfig, set: setInitialConfig },
        initialOptions: { get: initialOptions, set: setInitialOptions },
        currentConfig: { get: currentConfig, set: handleChangeConfig },
        presetsShort,
        presets,
        selectedPresetId: { get: selectedPresetId, set: setSelectedPresetId },
        handleLoadBotSettings,
        handleLoadPresets,
        handleLoadPreset,
        errorMessage,
        chartErrorMessage: { get: chartErrorMessage, set: setChartErrorMessage },

        //quick start
        tradeFrequencyCoefficient: {
          get: tradeFrequencyCoefficient,
          set: setTradeFrequencyCoefficient,
        },
        tradeSizeCoefficient: { get: tradeSizeCoefficient, set: setTradeSizeCoefficient },
        volatilityCoefficient: { get: volatilityCoefficient, set: setVolatilityCoefficient },
        useAdditionalReserves: { get: useAdditionalReserves, set: setUseAdditionalReserves },
        manualPooledBase: { get: manualPooledBase, set: setManualPooledBase },
        manualPooledQuote: { get: manualPooledQuote, set: setManualPooledQuote },
        CancelTokenRef,
        organicCandles: candles,
      }}
    >
      {children}
    </PairWashTradingContext.Provider>
  );
};
