import React, { useMemo } from 'react';

import { Modal, Button, ButtonLoading, ErrorText } from 'ui';
import { InputField, MultiSelectField } from 'fields';
import { ProjectForm } from 'ui/forms';

import { useProjectModal } from './useProjectModal/useProjectModal';
import { IUser } from './useProjectModal/models';

interface IProjectModalProps {
  type: 'create' | 'update';
  onClose: () => void;
  onOpen: () => void;
  projectId?: number;
  initialTitle: string | undefined;
  initialNotes: string | undefined;
  initialUsers: { id: number }[];
}

const ProjectModal: React.FC<IProjectModalProps> = ({
  type,
  onClose,
  onOpen,
  projectId,
  initialTitle,
  initialNotes,
  initialUsers,
}) => {
  const { users, loading, values, handleSubmit, touched, errors, setFieldValue, onDeleteProject } =
    useProjectModal({
      type,
      onClose,
      onOpen,
      projectId,
      title: initialTitle,
      notes: initialNotes,
      users: initialUsers,
    });

  const projectModalTitle = useMemo(
    () => (type === 'create' ? 'Create new project' : 'Change project settings'),
    [type],
  );

  let error: string | undefined = undefined;
  if (errors.title && touched.title) {
    error = errors.title;
  } else if (errors.notes && touched.notes) {
    error = errors.notes;
  }

  const onSelectUsers = (newSelectedUsers: IUser[]) => {
    return setFieldValue('users', newSelectedUsers);
  };

  return (
    <Modal title={projectModalTitle} onClose={onClose}>
      <ProjectForm handleSubmit={handleSubmit}>
        <InputField
          label="Name"
          type="text"
          value={values.title ?? ''}
          disabled={loading}
          setValue={newTitle => setFieldValue('title', newTitle)}
        />
        <MultiSelectField
          label="Users"
          placeholder="Add users"
          items={users ?? []}
          itemToString={user => user.login}
          onSelectItems={onSelectUsers}
          selectedItems={values.users}
          disabled={loading}
        />
        <InputField
          label="Notes"
          type="text"
          value={values.notes ?? ''}
          disabled={loading}
          setValue={newNotes => setFieldValue('notes', newNotes)}
        />
        <ErrorText>{error}</ErrorText>
        <div className="mt-2">
          {type === 'create' && (
            <>
              {!loading && <Button type="submit">Create</Button>}
              {loading && <ButtonLoading />}
            </>
          )}
          {type === 'update' && (
            <>
              {!loading && (
                <>
                  <Button type="submit">Confirm changing</Button>
                  <Button color="error-secondary" type="button" onClick={onDeleteProject}>
                    Delete project
                  </Button>
                </>
              )}
              {loading && <ButtonLoading />}
            </>
          )}
        </div>
      </ProjectForm>
    </Modal>
  );
};

export { ProjectModal };
