import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDebounce } from 'react-use';
import { useTypedSelector, useTypedDispatch } from 'store';

import { IForm } from './models';
import { FEES } from 'tools/constants';
import { getCexs } from 'store/slices/dictionary';
import { useProviderContract } from 'hooks';
import { PancakePair, UniswapV3Pool, QuickSwapPair } from 'abi';
import { ApiCexPairs, ApiPairs } from 'api';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { addNewPairToProject } from 'store/slices/projects';
import { EExchange, ENetwork } from 'types/common';
import { IDex } from 'api/apiDictionary/models';
import { isAddress } from 'utils';

const initialForm: IForm = {
  notes: null,
  cex: null,
  fee: FEES[0].value,
  baseToken: null,
  quoteToken: null,
  pairAddress: undefined,
};

const initialTouched: { [key in keyof IForm]: boolean } = {
  notes: false,
  cex: false,
  fee: false,
  baseToken: false,
  quoteToken: false,
  pairAddress: false,
};

const useAddPairModal = ({
  projectId,
  onClose,
  onOpen,
  tradingType,
  dex,
}: {
  projectId: number;
  onClose: () => void;
  onOpen: () => void;
  tradingType: EExchange;
  dex?: IDex;
}) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const cexs = useTypedSelector(store => store.dictionary.cexs);
  const [form, setForm] = useState<IForm>(initialForm);
  const [edited, setEdited] = useState<boolean>(false);
  const [touched, setTouched] = useState<{ [key in keyof IForm]: boolean }>(initialTouched);
  const [formError, setFormError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [pairParsingLoading, setPairParsingLoading] = useState<boolean>(false);

  const [pairAddressError, setPairAddressError] = useState<boolean>(false);

  const bscPairContract = useProviderContract(form.pairAddress, PancakePair, {
    network: ENetwork.bsc,
  });
  const ethPairContract = useProviderContract(form.pairAddress, UniswapV3Pool, {
    network: ENetwork.eth,
  });
  const polygonPairContract = useProviderContract(form.pairAddress, QuickSwapPair, {
    network: ENetwork.polygon,
  });
  const arbitrumPairContract = useProviderContract(form.pairAddress, QuickSwapPair, {
    network: ENetwork.arbitrum,
  });

  const setFormField = useCallback(
    function <T extends keyof typeof form>(
      field: T,
      value: (typeof form)[T],
      options: { triggerEdit?: boolean } = { triggerEdit: false },
    ): void {
      if (loading) return;

      if (options?.triggerEdit) {
        setEdited(true);
      }

      setForm(form => ({ ...form, [field]: value }));
      setTouched(touched => ({ ...touched, [field]: true }));
    },
    [loading],
  );

  useDebounce(
    async () => {
      let token_base = '';
      let token_quote = '';

      setPairParsingLoading(true);

      if (!isAddress(form.pairAddress)) {
        setPairParsingLoading(false);
        setPairAddressError(false);
      }

      try {
        if (dex?.network === ENetwork.bsc) {
          if (bscPairContract) {
            token_base = (await bscPairContract.token0()).toLowerCase();
            token_quote = (await bscPairContract.token1()).toLowerCase();
          }
        } else if (dex?.network === ENetwork.eth) {
          if (ethPairContract) {
            token_base = (await ethPairContract.token0()).toLowerCase();
            token_quote = (await ethPairContract.token1()).toLowerCase();
          }
        } else if (dex?.network === ENetwork.polygon) {
          if (polygonPairContract) {
            token_base = (await polygonPairContract.token0()).toLowerCase();
            token_quote = (await polygonPairContract.token1()).toLowerCase();
          }
        } else if (dex?.network === ENetwork.arbitrum) {
          if (arbitrumPairContract) {
            token_base = (await arbitrumPairContract.token0()).toLowerCase();
            token_quote = (await arbitrumPairContract.token1()).toLowerCase();
          }
        }

        if (token_base !== '') {
          setFormField('baseToken', token_base);
        }

        if (token_quote !== '') {
          setFormField('quoteToken', token_quote);
        }

        setPairAddressError(false);
      } catch (error) {
        console.log('error: ', error);
        setPairAddressError(true);
      } finally {
        setPairParsingLoading(false);
      }
    },
    300,
    [form.pairAddress],
  );

  const validateForm = useCallback(
    (form: IForm, config?: { isAccept?: boolean }): string | null => {
      if (
        (touched.baseToken || config?.isAccept) &&
        (form.baseToken === undefined || form.baseToken === '')
      ) {
        setFormError('Base token is required');
        return 'Base token is required';
      }

      if (
        (touched.quoteToken || config?.isAccept) &&
        (form.quoteToken === undefined || form.quoteToken === '')
      ) {
        setFormError('Quote token is required');
        return 'Quote token is required';
      }

      setFormError(null);
      return null;
    },
    [touched],
  );

  const handleAddPair = useCallback(async () => {
    const validationResult = validateForm(form, { isAccept: true });

    if (validationResult) return;

    setLoading(true);

    const cex = form.cex?.cex ?? 'gate';
    const fee =
      dex?.dex === 'uniswap_v3' || dex?.dex === 'uniswap_v3:arbitrum_one' ? form.fee : undefined;
    const notes = form.notes ? form.notes : '';

    const token_base = form.baseToken ?? '';
    const token_quote = form.quoteToken ?? '';

    const { isSuccess, data, errorMessage } = await ApiPairs.addPair({
      id: projectId,
      dex: dex ? dex.dex : 'uniswap_v2',
      cex,
      tradingType,
      fee,
      notes,
      token_base,
      token_quote,
      swap_gas_limit: 1000000,
    });

    if (!isSuccess) {
      onClose();
      setLoading(false);
      dispatch(
        setAlertState({
          type: 'failed-img',
          text: errorMessage,
          onClose: () => dispatch(dropAlertState()),
          onSubmit: () => {
            dispatch(dropAlertState());
            onOpen();
          },
        }),
      );
      return;
    }

    if (isSuccess) {
      const isDex = tradingType === EExchange.dex;

      const { isSuccess, data: pairData } = isDex
        ? await ApiPairs.getDexPairById(Number(data.id))
        : await ApiCexPairs.getCexPairById(Number(data.id));

      onClose();
      setLoading(false);

      if (isSuccess) {
        dispatch(
          addNewPairToProject({
            pair: pairData,
            type: tradingType,
          }),
        );
        dispatch(
          setAlertState({
            type: 'success-img',
            text: `Created new pair: ${pairData.symbol}`,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
            },
          }),
        );
        const isDexRoute = isDex ? 'dex-pair' : 'cex-pair';
        navigate(`/project/${projectId}/${isDexRoute}/${pairData.id}`);
      }
    }
  }, [form, navigate, projectId, dispatch, onClose, onOpen, validateForm, tradingType, dex]);

  useEffect(() => {
    if (tradingType === EExchange.cex) {
      if (cexs) {
        setFormField('cex', cexs[0]);
      }
      if (!cexs) {
        dispatch(getCexs());
      }
    }
  }, [tradingType, cexs]);

  return {
    cexs,
    form,
    setFormField,
    handleAddPair,
    formError,
    loading,
    pairParsingLoading,
    pairAddressError,
    edited,
  };
};

export { useAddPairModal };
