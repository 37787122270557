import React, { useCallback, useMemo } from 'react';
import { useTypedSelector } from 'store';

import { Button, Switcher, PanelLoading } from 'ui';
import { SelectTimeframeField, InputField } from 'fields';
import { FG_TIMEFRAMES } from 'types/wash-trading-bot';
import { parseDurationToSeconds } from 'utils';

import { useCreateFillTheGaps } from '../useCreateFillTheGaps';

const Content: React.FC = () => {
  const cexPair = useTypedSelector(store => store.pairs.selectedCexPair)!;

  const { initialLoading, handleSubmit, values, setFieldValue, touched, errors } =
    useCreateFillTheGaps();

  const handleChangeTradeAmount = useCallback(
    ({ inputValue, field }: { inputValue: string; field: string }) => {
      setFieldValue(field, inputValue, true);
    },
    [setFieldValue],
  );

  const handleChangeMinSizeTrades = useCallback(
    (v: boolean) => {
      setFieldValue('trade_min_amounts', v);

      if (v) {
        setFieldValue('min_amount', '', true);
        setFieldValue('max_amount', '', true);
      }
    },
    [setFieldValue],
  );

  const isShowDailyFeesVolume = useMemo(() => !!values.min_amount && !!values.max_amount, [values]);

  const dailyFeesVolume = useMemo(() => {
    const timeFrameParseToSeconds = Number(parseDurationToSeconds(values.time_frame.value));

    return `${
      ((24 * 60 * 60) / timeFrameParseToSeconds) * ((+values.min_amount + +values.max_amount) / 2)
    }`;
  }, [values.min_amount, values.max_amount, values.time_frame]);

  if (initialLoading) return <PanelLoading />;

  return (
    <div className="fill-the-gaps">
      <div className="fill-the-gaps__inputs">
        <div className="fill-the-gaps__inputs__time-frame-container">
          <SelectTimeframeField
            label="Timeframe"
            placeholder="Select timeframe"
            items={FG_TIMEFRAMES}
            selectedItem={values.time_frame}
            onSelectItem={time_frame => setFieldValue('time_frame', time_frame, true)}
          />
        </div>

        <div className="fill-the-gaps__inputs__amount-container">
          <div className="fill-the-gaps__inputs__amount-container__checkbox">
            <div>Trade min amounts</div>
            <Switcher
              value={values.trade_min_amounts}
              onChange={v => handleChangeMinSizeTrades(v)}
            />
          </div>
          <div className="fill-the-gaps__inputs__amount-container__amounts-fields-container">
            <InputField
              type="decimal-number"
              label={'Min amount'}
              disabled={values.trade_min_amounts}
              setValue={value =>
                handleChangeTradeAmount({ inputValue: value, field: 'min_amount' })
              }
              value={values.min_amount}
              errorMessage={touched.min_amount && errors.min_amount ? errors.min_amount : undefined}
            />
            <InputField
              type="decimal-number"
              label={'Max amount'}
              disabled={values.trade_min_amounts}
              setValue={value =>
                handleChangeTradeAmount({ inputValue: value, field: 'max_amount' })
              }
              value={values.max_amount}
              errorMessage={touched.max_amount && errors.max_amount ? errors.max_amount : undefined}
            />
          </div>
        </div>
      </div>

      <div className="fill-the-gaps__daily-container">
        <div className="daily-items-gap ">
          {isShowDailyFeesVolume && (
            <div className="daily-item">
              <span>Daily volume:</span>{' '}
              <span>
                {dailyFeesVolume} {cexPair.token_base.symbol}
              </span>
            </div>
          )}
        </div>
        <Button
          className="fill-the-gaps__save-button"
          color="secondary"
          size="small"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Content;
