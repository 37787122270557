import React, { useMemo } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useTypedSelector } from 'store';
import { formatFiat, formatToken } from 'utils/formats';

export const AvgPrice: React.FC<{ price: { avgPrice: string; avgPriceUsd: string } }> = ({
  price,
}) => {
  const pair = useTypedSelector(store => store.pairs.selectedDexPair);

  const avgPriceUsdString = useMemo(
    () => formatFiat(BigNumber.from(price.avgPriceUsd ?? '0'), 6, '$'),
    [price],
  );

  const avgPriceTokenString = useMemo(
    () => (pair ? formatToken(BigNumber.from(price.avgPrice), pair.token_quote.decimals) : ''),
    [price],
  );

  return (
    <>
      {avgPriceTokenString} / {avgPriceUsdString}
    </>
  );
};
