import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';

import { ENetwork } from 'types';
import { isAddress, getContract } from 'utils';
import { BscProvider, EthProvider, PolygonProvider, ArbitrumProvider } from 'web3/provider';

const useProviderContract = (
  address: string | undefined,
  ABI: any,
  config: { withSignerIfPossible?: boolean; network: ENetwork } = {
    withSignerIfPossible: true,
    network: ENetwork.eth,
  },
): Contract | null => {
  const { account } = useWeb3React();
  const { withSignerIfPossible, network } = config;
  return useMemo(() => {
    if (!address || !ABI || !isAddress(address)) return null;

    const provider = {
      bsc: BscProvider,
      eth: EthProvider,
      polygon: PolygonProvider,
      arbitrum_one: ArbitrumProvider,
    }[network] as Web3Provider;

    try {
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined,
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, network, withSignerIfPossible, account]);
};

export { useProviderContract };
