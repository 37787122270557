import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected } from 'web3/connector';

interface IUseMetamaskAuthReturn {
  connect: () => Promise<void>;
  disconnect: () => void;
}

const useMetamaskAuth = (): IUseMetamaskAuthReturn => {
  const { activate, deactivate } = useWeb3React();

  const connect = useCallback(async () => {
    setTimeout(() => {
      localStorage.setItem('market-maker/web3-auth-method', 'metamask');
      activate(injected, undefined, true)
        .then(() => {})
        .catch((e) => {
          if (e) {
            console.log(e);
            setTimeout(() => {
              activate(injected);
            }, 500);
          }
        });
    }, 500);
  }, [activate]);

  const disconnect = useCallback(() => {
    localStorage.removeItem('market-maker/web3-auth-method');
    deactivate();
  }, [deactivate]);

  return { connect, disconnect };
};

export { useMetamaskAuth };
