export const fieldLabelBase = {
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '16px',
  letterSpacing: '-0.078px',
};

export const fieldLabelFocused = (error: boolean) => ({
  ...fieldLabelBase,
  color: error ? '#CE4966' : '#5932EA',
  top: -16,
  left: 0,
  transform: 'translateY(0%)',
});

export const fieldLabelNotFocusedFilled = (error: boolean) => ({
  ...fieldLabelBase,
  color: error ? '#CE4966' : '#7F91BB',
  top: -16,
  left: 0,
  transform: 'translateY(0%)',
});

export const fieldLabelNotFocusedNotFilled = {
  ...fieldLabelBase,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#7F91BB',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
};
