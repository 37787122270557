import React from 'react';

import { parseDurationToSeconds } from 'utils';
import { ICexWTRegularTask } from 'types/bots';
import { formatFiat } from 'utils/formats';
import { parseUnits } from '@ethersproject/units';

interface IDailyGroupsTradesProps {
  task: ICexWTRegularTask;
}

export const DailyGroupsTrades: React.FC<IDailyGroupsTradesProps> = ({ task }) => {
  const minDelay = parseDurationToSeconds(task.options.pause_between_groups_min);
  const maxDelay = parseDurationToSeconds(task.options.pause_between_groups_max);

  const groupsCount = Math.floor((24 * 60 * 60) / ((minDelay + maxDelay) / 2));

  const avgMakers = (task.options.makers_min + task.options.makers_max) / 2;
  const avgTakers = (task.options.takers_min + task.options.takers_max) / 2;

  const tradesCount = Math.ceil(Math.max(avgMakers, avgTakers)) * groupsCount;

  const groupCountBN = parseUnits(groupsCount.toString(), 6);
  const tradesCountBN = parseUnits(tradesCount.toString(), 6);

  return (
    <div>
      {formatFiat(groupCountBN, 6, '')} / {formatFiat(tradesCountBN, 6, '')}
    </div>
  );
};
