import React from 'react';

import { IIconProps } from './types';

const CreateIcon: React.FC<IIconProps> = ({ className, color = '#7F91BB', onClick }) => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      onClick={handleClick}
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 11.25H15.3161C15.6938 11.25 16 11.5858 16 12C16 12.4142 15.6938 12.75 15.3161 12.75H12.75V15.3161C12.75 15.6938 12.4142 16 12 16C11.5858 16 11.25 15.6938 11.25 15.3161V12.75H8.68394C8.30621 12.75 8 12.4142 8 12C8 11.5858 8.30621 11.25 8.68394 11.25H11.25V8.68394C11.25 8.30621 11.5858 8 12 8C12.4142 8 12.75 8.30621 12.75 8.68394V11.25Z"
        fill={color}
      />
    </svg>
  );
};

export { CreateIcon };
