import React, { useCallback } from 'react';
import cn from 'classnames';

import { IPriceFormat } from 'types/charts';

import './chartPriceFormat.scss';

interface IChartPriceFormatProps {
  className?: string;
  supportedPriceFormats: IPriceFormat[];
  selectedPriceFormat: IPriceFormat;
  setSelectedPriceFormat: (v: IPriceFormat) => void;
  disabled?: boolean;
}

const ChartPriceFormat: React.FC<IChartPriceFormatProps> = ({
  className,
  supportedPriceFormats,
  selectedPriceFormat,
  setSelectedPriceFormat,
  disabled = false,
}) => {
  const priceScaleModeToCaption = useCallback((priceMode: IPriceFormat) => {
    if (priceMode === 'usd') {
      return 'USD';
    }
    if (priceMode === 'token') {
      return 'Tokens';
    }

    return undefined;
  }, []);

  return (
    <div className={cn({ [className ?? '']: !!className }, 'mm-chart-price-formats')}>
      {supportedPriceFormats.map((el) => {
        const caption = priceScaleModeToCaption(el);

        const isSelected = el === selectedPriceFormat;
        const isDisabled = disabled;

        if (!caption) return null;

        return (
          <div
            key={caption}
            className={cn('chart-price-format-item', {
              '_is-selected': isSelected,
              '_is-disabled': isDisabled,
            })}
            onClick={() => !isDisabled && setSelectedPriceFormat(el)}
          >
            {caption}
          </div>
        );
      })}
    </div>
  );
};

export default ChartPriceFormat;
