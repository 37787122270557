import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorage } from 'tools';
import { IDexPairFilters, IDexPairsFilters } from 'types/filters';
import { DEX_PAIR_FILTERS_KEY } from 'constants/cache';

export const setupInitialDexFilterTypeReducer: CaseReducer<
  IDexPairsFilters,
  PayloadAction<{ pairId: number; filters: IDexPairFilters }>
> = (state, { payload: { filters, pairId } }) => {
  if (!state[pairId]) return;

  state[pairId]!.filters = { ...state[pairId]!.filters, ...filters };

  LocalStorage.set(DEX_PAIR_FILTERS_KEY, state);
};
