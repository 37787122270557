import { FC, useCallback, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { BigNumber } from '@ethersproject/bignumber';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import cn from 'classnames';
import { useTypedSelector } from 'store';

import { Button } from 'ui';
import { IDexBalanceBotTask } from 'types/bots';
import { HolderTaskBalanceModal } from 'modals/AddHolderModal/HolderTaskBalanceModal';
import { ETransactionAction } from 'api/apiTransactions/models';
import {
  divideBignumbers,
  multiplyBignumbers,
  normalizeBigNumber,
  subtractBignumbers,
} from 'utils/formulas';
import { formatFiat, formatToken } from 'utils/formats';
import { durationToMs } from 'utils/duration';
import { ConnectWalletsToBoostHoldersModal } from 'modals/ConnectWalletsToBoostHoldersModal';
import { useHolderBalanceTask } from 'modals/AddHolderModal/useAddHolderModal/useHolderBalanceTask';

import './style.scss';

dayjs.extend(duration);

type RowInfoProps = {
  task: IDexBalanceBotTask;
  rowIdx: number;
};

export const HolderBalanceRowInfo: FC<RowInfoProps> = ({ task, rowIdx }) => {
  const [createNewTaskModalOpened, setCreateNewTaskModalOpened] = useState<boolean>(false);
  const [isOpenWalletsModal, setIsOpenWalletsModal] = useState<'source' | 'destination' | ''>('');

  const handleEditTask = useCallback((): void => {
    setCreateNewTaskModalOpened(true);
  }, []);

  const { estimateFee, values } = useHolderBalanceTask({ task, mode: 'edit' });

  const pair = useTypedSelector(store => store.pairs.selectedDexPair);
  const baseTokenSymbol = pair?.token_base.symbol;
  const decimals = pair?.token_base.decimals || 18;
  const { statistic, buy_eth_holder_swaps, sell_eth_swaps, destination_wallets_count } = task;

  const token = statistic?.tokens?.find(item => {
    return item?.token?.symbol === baseTokenSymbol;
  });
  const buyEthHolderAction = token?.volumes.find(
    volume => volume.action === ETransactionAction.ActionBuyEthHolder,
  );
  const progressCountBuyEthAction = buyEthHolderAction?.amount || '0';
  const progressPercentageBuyEthAction =
    (buy_eth_holder_swaps / destination_wallets_count) * 100 || 0;

  const sellEthHoldersAction = token?.volumes.find(
    volume => volume.action === ETransactionAction.ActionSellEth,
  );
  const progressCountSellEthAction = sellEthHoldersAction?.amount || '0';
  const progressPercentageSellEthAction = (sell_eth_swaps / destination_wallets_count) * 100 || 0;

  const buyEthBuyAction = statistic?.transactions?.find(
    transaction => transaction.action === ETransactionAction.ActionBuyEthHolder,
  );
  const buyEthSellAction = statistic?.transactions?.find(
    transaction => transaction.action === ETransactionAction.ActionSellEth,
  );

  const convertedIn: any = useMemo(() => {
    const feeTokens = subtractBignumbers(
      [BigNumber.from(buyEthHolderAction?.amount || '0'), decimals],
      [BigNumber.from(sellEthHoldersAction?.amount || '0'), decimals],
    );

    const feeUsd = subtractBignumbers(
      [BigNumber.from(buyEthHolderAction?.amount_usd || '0'), 6],
      [BigNumber.from(sellEthHoldersAction?.amount_usd || '0'), 6],
    );

    return {
      feeTokens: !formatToken(feeTokens, decimals).includes('-')
        ? formatToken(feeTokens, decimals)
        : 0,
      feeUsd: !formatFiat(feeUsd, decimals).includes('-') ? formatFiat(feeUsd, decimals) : 0,
    };
  }, [buyEthHolderAction, sellEthHoldersAction, decimals]);

  const requiredTime =
    ((durationToMs(task?.add_holder_options?.min_pause) +
      durationToMs(task?.add_holder_options?.max_pause)) /
      2) *
    (task.destination_wallets_count || 0);

  const currentSwapPriceUsd =
    estimateFee?.current_pair?.transactions_count &&
    estimateFee.current_pair?.transactions_count >= 5
      ? estimateFee?.current_pair.avg_amount_usd
      : estimateFee?.all_pairs.avg_amount_usd;

  const currentSwapPriceToken = divideBignumbers(
    [BigNumber.from(currentSwapPriceUsd || 0), decimals],
    [BigNumber.from(pair?.token_fee.price_usd || 0), decimals],
  );

  const multipliedFeeCostUsd = multiplyBignumbers(
    [BigNumber.from(currentSwapPriceUsd || 0), decimals],
    [BigNumber.from(task.destination_wallets_count || 0), 1],
  );

  const feeCostUsd = task.add_holder_options?.buyback_enabled
    ? multiplyBignumbers(
        [BigNumber.from(multipliedFeeCostUsd || 0), decimals],
        [BigNumber.from('2'), 1],
      )
    : multipliedFeeCostUsd;

  const multipliedFeeCostToken = multiplyBignumbers(
    [BigNumber.from(currentSwapPriceToken || 0), decimals],
    [BigNumber.from(task.destination_wallets_count || 0), 1],
  );

  const feeCostToken = !task.add_holder_options?.buyback_enabled
    ? multiplyBignumbers(
        [BigNumber.from(multipliedFeeCostToken || 0), decimals],
        [BigNumber.from('2'), 0],
      )
    : multipliedFeeCostToken;

  const walletsCount = useMemo(
    () => task?.add_holder_options?.source_wallet_ids?.length ?? 0,
    [task],
  );

  return (
    <motion.div
      key={`extended-row-${rowIdx}`}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      className="mm-balance-bot-row-extended-info"
    >
      <div className="line-separator" />
      <div className="rowInfo-container scrollable">
        <div className="sections">
          <span className="section-title">Settings</span>
          <div className="section ">
            <div className="grid gap-2">
              <div className="section-value">
                <span>Min/Max amount: </span>
                <span>
                  {`${normalizeBigNumber(
                    task?.add_holder_options?.min_amount || 0,
                    decimals,
                    3,
                    false,
                  )} /
                    
                  ${normalizeBigNumber(
                    task?.add_holder_options?.max_amount || 0,
                    decimals,
                    3,
                    false,
                  )} ${baseTokenSymbol} `}
                </span>
              </div>
              <div className="section-value">
                <span> Min/Max pause: </span>
                <span>
                  {task?.add_holder_options?.min_pause} / {task?.add_holder_options?.max_pause}
                </span>
              </div>
            </div>
            <div className="grid gap-2">
              <div className="section-value">
                <span>Reverse swap: </span>
                <span>{task?.add_holder_options?.buyback_enabled ? 'Enabled' : 'Disabled'} </span>
              </div>

              <div className="section-value">
                <span> Min/Max prev pause: </span>
                <span>
                  {task?.add_holder_options?.buyback_options?.min_pause} /
                  {task?.add_holder_options?.buyback_options?.max_pause}
                </span>
              </div>
            </div>
            <div className="grid gap-2">
              <div
                onClick={
                  walletsCount === 0
                    ? undefined
                    : () => {
                        setIsOpenWalletsModal('source');
                      }
                }
                className={cn('wallets-section-value', {
                  _not_active: walletsCount === 0,
                })}
              >
                <span>From: </span>
                <span>
                  {walletsCount === 0
                    ? 'All Wallets'
                    : `${walletsCount} ${walletsCount === 1 ? 'wallet' : 'wallets'}`}{' '}
                </span>
              </div>
              <div
                onClick={
                  !task?.destination_wallets_count
                    ? undefined
                    : () => {
                        setIsOpenWalletsModal('destination');
                      }
                }
                className={cn('wallets-section-value', {
                  _not_active: !task?.destination_wallets_count,
                })}
              >
                <span>To: </span>
                <span>
                  {!task?.destination_wallets_count
                    ? 'All wallets'
                    : `${task?.destination_wallets_count} ${
                        task?.destination_wallets_count === 1 ? 'wallet' : 'wallets'
                      }`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="sections">
          <span className="section-title">Statistics</span>
          <div className="section ">
            <div className="grid gap-2">
              <div className="section-value">
                <span>Swap “type1”: </span>
                <span>
                  <>
                    {normalizeBigNumber(progressCountBuyEthAction, decimals, 0, false)} &#47;{' '}
                    {progressPercentageBuyEthAction} &#37;
                  </>
                </span>
              </div>
              <div className="section-value">
                <span> Swap “type2”: </span>
                <span>
                  <>
                    {progressCountBuyEthAction
                      ? normalizeBigNumber(progressCountSellEthAction, decimals, 0, false)
                      : 0}{' '}
                    &#47; {progressPercentageSellEthAction.toFixed(2)} &#37;
                  </>{' '}
                </span>
              </div>
            </div>
            <div className="grid gap-2">
              <div className="section-value">
                <span>Transactions “type1”: </span>
                <span>
                  {buyEthBuyAction
                    ? `${buyEthBuyAction.total} (${buyEthBuyAction.failed} failed)`
                    : `0 (0 failed)`}
                </span>
              </div>
              <div className="section-value">
                <span> Transactions “type2”: </span>
                <span>
                  {buyEthSellAction
                    ? `${buyEthSellAction.total} (${buyEthSellAction.failed} failed)`
                    : `0 (0 failed)`}
                </span>
              </div>
            </div>
            <div className="grid gap-2">
              <div className="section-value">
                <span>Converted in {pair?.token_base?.symbol}: </span>

                <span>
                  {convertedIn.feeTokens} / {convertedIn.feeUsd}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-separator" />
      <div className="rowInfo-bottom-container">
        <div className="bottom-sections">
          <div className="bottom-sections-content">
            <span>Required time: </span>
            <span>{dayjs.duration(requiredTime, 'milliseconds').humanize()}</span>
          </div>
          <div className="bottom-sections-content">
            <span>Fee costs: </span>
            <span>
              {formatToken(feeCostToken, decimals)} {pair?.token_fee?.symbol} /{' '}
              {formatFiat(feeCostUsd)}
            </span>
          </div>
        </div>

        <div className="extended-row__footer">
          <Button color="secondary" className="edit-task-button" onClick={handleEditTask}>
            Edit task
          </Button>
        </div>
      </div>

      {createNewTaskModalOpened && (
        <HolderTaskBalanceModal
          mode="edit"
          task={task}
          onClose={() => setCreateNewTaskModalOpened(false)}
        />
      )}
      {isOpenWalletsModal && (
        <ConnectWalletsToBoostHoldersModal
          onClose={() => {
            setIsOpenWalletsModal('');
          }}
          walletType={isOpenWalletsModal}
          readOnlyMode
          taskId={task.id}
          taskType={'add_holder'}
          not_connected={'false'}
          currentTaskValue={values}
        />
      )}
    </motion.div>
  );
};
