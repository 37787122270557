import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';

import { getLibrary } from 'web3/getLibrary';
import store from 'store';

import { ErrorBoundary, AlertProvider } from './components';
import { Routing } from './Routing/Routing';

const MainApp: React.FC = () => {
  return (
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <AlertProvider>
            <Routing />
          </AlertProvider>
        </Provider>
      </Web3ReactProvider>
    </BrowserRouter>
  );
};

export { MainApp };
