import { useState, useMemo, useCallback } from 'react';
import { useTypedDispatch } from 'store';
import { useNavigate } from 'react-router';

import { ApiPairs, ApiCexPairs } from 'api';
import { setAlertState, dropAlertState } from 'store/slices/ui';
import { deletePairFromProject, updatePairInProject } from 'store/slices/projects';
import { EExchange } from 'types';

interface IUseUdPairModalProps {
  onClose: () => void;
  onOpen: () => void;
  pairId: number;
  exchange: EExchange;
  projectId: number;
  pairSymbol: string;
  initialNotes: string;
}

interface IReturnUseUdPairModal {
  loading: boolean;
  initialValues: { notes: string };
  onUpdatePair: ({ notes }: { notes: string }) => void;
  onDeletePair: () => void;
}

const useUDPairModal = ({
  onClose,
  onOpen,
  pairId,
  exchange,
  projectId,
  pairSymbol,
  initialNotes,
}: IUseUdPairModalProps): IReturnUseUdPairModal => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = useMemo(() => ({ notes: initialNotes ?? '' }), [initialNotes]);

  const onUpdatePair = useCallback(
    async ({ notes }: { notes: string }) => {
      setLoading(true);

      try {
        const { isSuccess, errorMessage } =
          exchange === EExchange.dex
            ? await ApiPairs.updateDexPair({ id: pairId, notes })
            : await ApiCexPairs.updateCexPair({ id: pairId, notes });

        if (!isSuccess) {
          onClose();
          setLoading(false);
          dispatch(
            setAlertState({
              type: 'failed',
              text: errorMessage,
              onClose: () => dispatch(dropAlertState()),
              onSubmit: () => {
                dispatch(dropAlertState());
                onOpen();
              },
            }),
          );
          return;
        }

        if (isSuccess) {
          const { isSuccess, errorMessage } =
            exchange === EExchange.dex
              ? await ApiPairs.getDexPairById(pairId)
              : await ApiCexPairs.getCexPairById(pairId);

          onClose();
          setLoading(false);

          if (!isSuccess) {
            dispatch(
              setAlertState({
                type: 'failed',
                text: errorMessage,
                onClose: () => dispatch(dropAlertState()),
                onSubmit: () => {
                  dispatch(dropAlertState());
                  onOpen();
                },
              }),
            );
            return;
          }

          if (isSuccess) {
            dispatch(updatePairInProject({ notes, pairId, projectId, type: exchange }));
            dispatch(
              setAlertState({
                type: 'success',
                text: `Updated traiding pair: ${pairSymbol}`,
                onClose: () => dispatch(dropAlertState()),
                onSubmit: () => dispatch(dropAlertState()),
              }),
            );
            return;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [pairId, pairSymbol, onClose, onOpen, dispatch, exchange, projectId],
  );

  const onDeletePair = useCallback(async () => {
    try {
      setLoading(true);

      const { isSuccess, errorMessage } =
        exchange === EExchange.dex
          ? await ApiPairs.deleteDexPair(pairId)
          : await ApiCexPairs.deleteCexPair(pairId);

      onClose();
      setLoading(false);

      if (!isSuccess) {
        dispatch(
          setAlertState({
            type: 'failed',
            text: errorMessage,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => {
              dispatch(dropAlertState());
              onOpen();
            },
          }),
        );
        return;
      }

      if (isSuccess) {
        navigate(`/project/${projectId}`);
        dispatch(deletePairFromProject({ pairId, projectId, type: exchange }));
        dispatch(
          setAlertState({
            type: 'success',
            text: `Deleted pair: ${pairSymbol}`,
            onClose: () => dispatch(dropAlertState()),
            onSubmit: () => dispatch(dropAlertState()),
          }),
        );
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }, [pairId, onOpen, onClose, projectId, dispatch, exchange, navigate, pairSymbol]);

  return { loading, initialValues, onUpdatePair, onDeletePair };
};

export { useUDPairModal };
