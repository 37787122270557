import { useState, useEffect, useCallback } from 'react';

import { useDebounce } from 'react-use';
import { getScrollPositionFromValue, getValueFromScrollPosition } from './helpers';

interface IUseScrollArgs {
  coefficient: number;
  setCoefficient: (v: number) => void;
  points: {
    initial: number;
    min: number;
    max: number;
  };
}

const useScroll = ({ coefficient, setCoefficient, points }: IUseScrollArgs) => {
  const [innerCoefficient, setInnerCoefficient] = useState<number>(coefficient);
  const [scrollPos, setScrollPos] = useState<number>(
    getScrollPositionFromValue({ value: coefficient, points })
  );

  useEffect(() => {
    setInnerCoefficient(coefficient);
    setScrollPos(getScrollPositionFromValue({ value: coefficient, points }));
  }, [coefficient]);

  const onChangeScrollPos = useCallback(
    (newScrollPos: number) => {
      setInnerCoefficient(getValueFromScrollPosition({ scrollPosition: newScrollPos, points }));
      setScrollPos(newScrollPos);
    },
    [points]
  );

  useDebounce(
    () => {
      if (innerCoefficient !== coefficient) {
        setCoefficient(innerCoefficient);
      }
    },
    300,
    [innerCoefficient]
  );

  return {
    scrollPos,
    setScrollPos: onChangeScrollPos,
    innerCoefficient,
  };
};

export default useScroll;
