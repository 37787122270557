import React, { useMemo } from 'react';
import cn from 'classnames';
import { parseUnits } from '@ethersproject/units';
import { useTypedSelector } from 'store';

import { chartFormatter } from 'utils/charts';
import { formatFiat } from 'utils/formats';
import { chartVolumeBarColor } from 'constants/charts';

interface IPairTooltipProps {
  open: number;
  close: number;
  high: number;
  low: number;
  volume: number;
  startPrice: number | undefined;
}

const PairTooltip: React.FC<IPairTooltipProps> = ({
  close,
  high,
  low,
  open,
  volume,
  startPrice,
}) => {
  const dexPair = useTypedSelector(store => store.pairs.selectedDexPair)!;

  const pairSymbol = useMemo(() => dexPair.symbol, [dexPair]);

  const pairChartTitle = useMemo(() => pairSymbol, [pairSymbol]);

  const isGreen = useMemo(() => close >= open, [close, open]);

  const amountCN = useMemo(() => cn('amount', { green: isGreen, red: !isGreen }), [isGreen]);

  const diff = useMemo(
    () => (startPrice !== undefined ? close - startPrice : undefined),
    [close, startPrice],
  );

  const diffPercentage = useMemo(
    () =>
      startPrice !== undefined && startPrice !== 0 && diff !== undefined
        ? Number(Math.abs((diff / startPrice) * 100).toFixed(2))
        : undefined,
    [startPrice, diff],
  );

  const volumeString = useMemo(() => {
    const number = chartFormatter(volume);

    const bn = parseUnits(number.toString(), 18);

    return formatFiat(bn, 18, '');
  }, [volume]);

  return (
    <div className="chart-tooltip">
      <div className="tooltip-header">
        <span className="tooltip-title">{pairChartTitle}</span>
      </div>
      <div className="pair-values">
        <div className="value">
          <span className="caption">O</span>
          <span className={amountCN}>{chartFormatter(open)}</span>
        </div>
        <div className="value">
          <span className="caption">H</span>
          <span className={amountCN}>{chartFormatter(high)}</span>
        </div>
        <div className="value">
          <span className="caption">L</span>
          <span className={amountCN}>{chartFormatter(low)}</span>
        </div>
        <div className="value">
          <span className="caption">C</span>
          <span className={amountCN}>{chartFormatter(close)}</span>
        </div>
        {diff !== undefined && diffPercentage !== undefined && (
          <div className="value">
            <span className={cn('amount', { green: diff >= 0, red: diff < 0 })}>
              {diff > 0 ? '+' : ''}
              {chartFormatter(diff)}
            </span>
            <span className={cn('amount', { green: diff >= 0, red: diff < 0 })}>
              ({diff >= 0 ? '+' : '-'}
              {diffPercentage}%)
            </span>
          </div>
        )}
      </div>
      <div className="pair-values">
        <div className="volume-value">
          <div className="round" style={{ backgroundColor: chartVolumeBarColor }} />
          <span className="caption">Volume:</span>
          <span className="amount">{volumeString}</span>
        </div>
      </div>
    </div>
  );
};

export { PairTooltip };
